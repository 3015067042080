import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import KakaoLogin from "react-kakao-login";
import axios from "axios";
import { useRecoilState, useSetRecoilState } from "recoil";
import { useGoogleLogin } from "@react-oauth/google";

import {
  Container,
  MainWrap,
  Title,
  KakaoButtonWrap,
  ButtonText,
  ModalTitle,
  ModalContent,
  Content,
  RowWrap,
  GoogleButtonWrap,
} from "./style";
import {
  PopupBackground,
  PopupContainer,
  CloseButtonWrap,
} from "../../components/Popup";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Button from "../../components/Button";
import TextInput from "../../components/TextInput";
import Modal from "../../components/Modal";

import { ReactComponent as KaKaoIcon } from "../../assets/icons/kakao.svg";
import { ReactComponent as CloseIcon } from "../../assets/icons/cancle_44px.svg";
import GoogleIcon from "../../assets/icons/google.svg";

import { API_URL } from "../../API/index";
import snsTokenState from "../../states/snsTokenState";
import tokenState from "../../states/tokenState";
import { Desktop, Mobile } from "../../utils/mediaQuery";
import LoginMobile from "../login/mobile/login_mobile";
import joinModalState from "../../states/joinModalState";

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [joinModal, setJoinModal] = useRecoilState(joinModalState);
  const [errorModal, setErrorModal] = useState(false);
  const setSNSTokenValue = useSetRecoilState(snsTokenState);
  const setToken = useSetRecoilState(tokenState);
  const [errorMessage, setErrorMessage] = useState("");

  const googleLogin = useGoogleLogin({
    flow: "auth-code",
    onSuccess: (response) => {
      console.log(response);
      // socialLogin("GOOGLE", response.);
    },
  });

  const socialLogin = (type, token) => {
    if (type !== "" && token !== "") {
      axios({
        method: "POST",
        url: `${API_URL}/user/login`,
        data: {
          socialType: type,
          socialToken: token,
        },
      })
        .then((result) => {
          if (result.data.success === true) {
            if (result.data.result.signuped === true) {
              setToken(result.data.result.token);
              navigate("/");
            } else {
              setJoinModal(true);
              setSNSTokenValue({
                id: token,
                type: type,
              });
            }
          }

          if (result.data.success === false) {
            setErrorMessage(result.data.error.message);
            setErrorModal(true);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleLogin = () => {
    if (email !== "" && password !== "") {
      axios({
        method: "POST",
        url: `${API_URL}/user/login`,
        data: {
          email: email,
          password: password,
        },
      })
        .then((result) => {
          if (result.data.success === true) {
            setToken(result.data.result.token);
            navigate("/");
          }

          if (result.data.success === false) {
            setErrorMessage(result.data.error.message);
            setErrorModal(true);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <>
      <Desktop>
        <Container>
          <Header activeTab={"Login"} />
          <MainWrap>
            <Title>이메일 로그인</Title>
            <TextInput
              value={email}
              onChange={(value) => {
                setEmail(value);
              }}
              placeholder={"이메일 입력"}
              style={{ width: "100%" }}
            />
            <TextInput
              value={password}
              onChange={(value) => {
                setPassword(value);
              }}
              placeholder={"비밀번호 입력"}
              style={{ width: "100%" }}
              type={"password"}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  handleLogin();
                }
              }}
            />
            <Button
              active={email !== "" && password !== ""}
              disabled={email === "" || password === ""}
              onClick={() => {
                handleLogin();
              }}
            >
              로그인
            </Button>
            <RowWrap>
              <Content
                onClick={() => {
                  navigate("/findpwid");
                }}
              >
                아이디/비밀번호 찾기
              </Content>
              <Content
                onClick={() => {
                  setSNSTokenValue({
                    id: "",
                    type: "",
                  });
                  setJoinModal(true);
                }}
              >
                회원가입
              </Content>
            </RowWrap>
            <Title>간편 로그인</Title>
            <KakaoLogin
              token={process.env.REACT_APP_KAKAO_CLIENT_ID}
              onSuccess={(value) => {
                socialLogin("KAKAO", value.profile.id);
              }}
              onFail={console.error}
              onLogout={console.info}
              render={({ onClick }) => {
                return (
                  <KakaoButtonWrap
                    onClick={() => {
                      onClick();
                    }}
                  >
                    <KaKaoIcon />
                    <ButtonText>카카오 아이디로 시작하기</ButtonText>
                  </KakaoButtonWrap>
                );
              }}
            />
            <GoogleButtonWrap onClick={googleLogin}>
              <img src={GoogleIcon} />
              <ButtonText>구글 아이디로 시작하기</ButtonText>
            </GoogleButtonWrap>
          </MainWrap>
        </Container>
        <Footer />
        {joinModal && (
          <PopupBackground
            onClick={() => {
              setJoinModal(false);
            }}
          >
            <PopupContainer
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <CloseButtonWrap
                onClick={() => {
                  setJoinModal(false);
                }}
              >
                <CloseIcon />
              </CloseButtonWrap>
              <ModalTitle>회원가입</ModalTitle>
              <ModalContent>
                가입하고자 하는 회원 유형을 선택해주세요.
              </ModalContent>
              <Button
                active
                style={{ marginBottom: 16 }}
                onClick={() => {
                  navigate(`/signUp/student`);
                }}
              >
                저는 학생/학부모 입니다.
              </Button>
              <Button
                active
                style={{ marginBottom: 32 }}
                onClick={() => {
                  navigate(`/signUp/teacher/notice`);
                }}
              >
                저는 강사 입니다.
              </Button>
            </PopupContainer>
          </PopupBackground>
        )}
        {errorModal && (
          <Modal
            padding
            title={"로그인 실패"}
            content={errorMessage}
            button={[
              {
                title: "확인",
                type: "active",
              },
            ]}
            onPress={() => {
              setErrorModal(false);
              document.body.style.overflow = "auto";
            }}
          />
        )}
      </Desktop>
      <Mobile>
        <LoginMobile />
      </Mobile>
    </>
  );
};

export default Login;
