import React, { useEffect } from "react";
import styled from "styled-components";

const Background = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(33, 37, 41, 0.8);
  z-index: 1000;
  position: fixed;
  top: 0px;
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ModalContainer = styled.div`
  width: ${(props) => (props.width ? props.width : "342px")};
  height: ${(props) => (props.height ? props.height : "auto")};
  background-color: #ffffff;
  overflow-x: hidden;

  border-radius: 12px;
  position: relative;
  padding: 24px 16px;
  box-sizing: border-box;
  text-align: center;
`;

const ModalTitle = styled.div`
  color: #212529;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  text-align: center;
  margin-bottom: 12px;
`;

const ModalContent = styled.div`
  text-align: center;
  color: #212529;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  white-space: pre-line;
`;

const ModalButtonWrap = styled.div`
  width: 310px;
  height: auto;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
`;

const ModalButton = styled.button`
  border-radius: 4px;
  margin-top: 20px;
  width: ${(props) => (props.btncnt === 2 ? "150px" : "100%")};
  height: 100%;
  border: none;
  text-decoration: none;
  cursor: pointer;
  background-color: ${(props) =>
    props.type === "cancel" ? "#DDE2EA" : "#021633"};
  color: ${(props) => (props.type === "cancel" ? "#021633" : "#ffffff")};
  font-size: 20px;
  font-weight: 500;
  line-height: 50px;
`;

const MobileModal = ({
  title,
  content,
  button,
  onClose,
  onPress,
  width,
  height,
  htmlContnet,
  padding,
  btncnt,
}) => {
  useEffect(() => {
    document.body.style.cssText = `
      position: fixed; 
      top: -${window.scrollY}px;
      overflow-y: hidden;
      width: 100%;`;
    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = "";
      window.scrollTo(0, parseInt(scrollY || "0", 10) * -1);
    };
  }, []);

  return (
    <Background
      onClick={(event) => {
        if (event.target === event.currentTarget) {
          if (onClose) {
            onClose(false);
          }
        }
      }}
    >
      <ModalContainer width={width} height={height} padding={padding}>
        <ModalTitle>{title}</ModalTitle>
        {htmlContnet !== undefined ? (
          htmlContnet
        ) : (
          <ModalContent>{content}</ModalContent>
        )}
        <ModalButtonWrap>
          {button &&
            button.map((item, idx) => {
              if (item.type === "cancel") {
                return (
                  <ModalButton
                    btncnt={btncnt}
                    key={idx}
                    type={item.type}
                    onClick={(event) => {
                      if (event.target === event.currentTarget) {
                        if (onClose) {
                          onClose(false);
                          document.body.style.overflow = "auto";
                        }
                      }
                    }}
                  >
                    {item.title}
                  </ModalButton>
                );
              }
              return (
                <ModalButton
                  btncnt={btncnt}
                  key={idx}
                  type={item.type}
                  onClick={onPress}
                >
                  {item.title}
                </ModalButton>
              );
            })}
        </ModalButtonWrap>
      </ModalContainer>
    </Background>
  );
};

export default MobileModal;
