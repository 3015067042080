const formatSchoolType = (type) => {
  switch (type) {
    case 'ACADEMY':
      return '영재원';
    case 'HIGHSCHOOL':
      return '고등학교';
    case 'UNIVERSITY':
      return '대학교';
    default:
      return '';
  }
};

export default formatSchoolType;