import styled from "styled-components";

export const MobileMetoPickContainer = styled.div`
  width: 390px;
  height: 844px;
  margin: 0 auto;
  > ul {
    padding-bottom: 70px;
    li {
      :not(:last-of-type) {
        > div {
          border-bottom: 1px solid #e9ecef;
        }
      }
    }
  }
`;
export const MobileMentoCard = styled.div`
  padding: 16px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  > button {
    padding: 0;
    background-color: transparent;
    width: 108px;
    height: 134px;
    border-radius: 4px;
    box-sizing: border-box;
    border: 1px solid #dee2e6;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  > div {
    width: 218px;
    height: 131px;
    h2 {
      font-weight: 700;
      font-size: 16px;
      line-height: 23px;
      color: #212529;
      margin-bottom: 8px;
    }
  }
`;
export const MentoInfo = styled.div`
  .swiper {
    position: relative;
    height: 25px;
    left: 50%;
    transform: translateX(-50%);
  }
  .swiper-slide {
    padding: 4px 12px;
    width: auto;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    text-align: center;
    color: #021633;
    background: #ffffff;
    border: 1px solid #021633;
    border-radius: 4px;
  }
`;
export const RecordsList = styled.ul`
  margin-top: 12px;
  height: 58px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  > li {
    display: flex;

    div {
      padding: 4px 12px;
      width: 47px;
      height: 25px;
      background: #e9ecef;
      border-radius: 13px;
      font-weight: 500;
      font-size: 12px;
      line-height: 17px;
      color: #021633;
      margin-right: 12px;
    }
    p {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #212529;
    }
  }
`;

/***********프로필 상세보기***************/

export const MobileProfileContainer = styled(MobileMetoPickContainer)``;

export const MobileProfileImgWrapper = styled.figure`
  width: 100%;
  height: 360px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const MobileProfileInfo = styled.div`
  padding: 24px 24px 0px 24px;
  .swiper {
    width: 100%;
    margin: 0;
  }

  .swiper-slide {
    padding: 4px 12px;
    margin: 0;
    width: auto;
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    color: #021633;
    background: #ffffff;
    border: 1px solid #021633;
    border-radius: 4px;
  }
  > div {
    display: flex;
    margin-bottom: 24px;
    > h2 {
      display: inline;
      font-weight: 700;
      font-size: 20px;
      white-space: pre;
      margin-right: 12px;
      line-height: 29px;
      color: #212529;
    }
    div {
      width: 100%;
    }
  }
`;

export const MobileProfileDetailRecoder = styled(RecordsList)`
  li {
    p {
      text-overflow: ellipsis;
      overflow: hidden;
      width: 283px;
      white-space: nowrap;
    }
    :first-of-type {
      margin-bottom: 12px;
    }
  }
`;

export const MobileProfileTab = styled.div`
  > ul {
    display: flex;
    width: 342px;
    justify-content: space-between;
    margin-top: 42px;
    li {
      button {
        padding-bottom: 10px;
        background-color: transparent;
        border: none;

        font-weight: 400;
        font-size: 16px;
        line-height: 23px;
        text-align: center;
        color: #868e96;
      }
    }
  }
  > hr {
    margin: 0;
    border: none;
    width: 390px;
    height: 2px;
    z-index: -1;
    margin-top: 76px;
    background-color: #e9ecef;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
`;

export const MobileOtContainer = styled.div`
  width: 390px;
  padding: 80px 24px 100px 24px;
  margin: 0 auto;

  .selectTime {
    margin-left: 0 !important;
    margin-top: 24px;
    > div {
      height: auto;
      margin-bottom: 24px;
      div {
        font-weight: 500;
        font-size: 16px;
        line-height: 23px;
        height: auto;
        padding: 16px 0;
        margin-bottom: 0;
        :last-of-type {
          border: 1px solid #dde2ea;
        }
      }
    }
    > button {
      border: 1px solid #dde2ea;
    }
  }
`;
