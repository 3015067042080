import React, { useEffect, useState } from "react";
import styled from "styled-components";
import moment from "moment";
import "react-calendar/dist/Calendar.css";
import Calendar from "react-calendar";
import "./style/datePicker.css";
import axios from "axios";

import RadioBox from "./RadioBox";

import { ReactComponent as ArrowIcon } from "./../assets/icons/arrow_top.svg";

import { API_URL } from "../API";
import dayjs from "dayjs";
import useWindowSize from "../hooks/useWindowSize";

const TimeSlotGrid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr, 1fr;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(4, 1fr);
  grid-gap: 16px;
  margin-bottom: 16px;
`;

const PossibleDateCircle = styled.div`
  height: 32px;
  width: 32px;
  position: absolute;
  margin-top: 5px;
  margin-left: 8px;
  border: 1px solid #ffad2e;
  border-radius: 100%;
  margin-bottom: 10px;
  top: -3px;
  right: 6px;
`;

const DataDateCircle = styled.div`
  width: 4px;
  height: 4px;
  position: absolute;
  background: red;
  border-radius: 4px;
  top: 8px;
  right: 8px;
`;

const RowWrap = styled.div`
  display: flex;
  width: 100%;
`;

const AMPMButton = styled.div`
  background-color: ${(props) => (props.active ? "#021633" : "#ffffff")};
  color: ${(props) => (props.active ? "#ffffff" : "#061b3b")};
  border: 1px solid #021633;
  border-radius: 8px;
  width: calc(50% - 8px);
  height: 40px;
  display: inline-block;
  margin-bottom: 32px;
  text-align: center;
  padding: 12px 0px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  transition: 0.5s;
`;

const TimeButton = styled.button`
  background-color: ${(props) => (props.active ? "#021633" : "#ffffff")};
  color: ${(props) => (props.active ? "#ffffff" : "#021633")};
  border: 1px solid #021633;
  border-radius: 8px;
  width: 100%;
  height: 52px;
  display: inline-block;
  margin-bottom: 16px;
  text-align: center;
  padding: 12px 0px;
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  transition: 0.5s;
  position: relative;
  box-shadow: ${(props) =>
    props.active ? "0px 10px 10px rgba(0, 0, 0, 0.25)" : "none"};
  text-shadow: ${(props) => (props.active ? "1px 1px 20px #ffffff" : "none")};

  :disabled {
    background-color: #f4f5f7;
    color: #adb5bd;
    border: 1px solid #f4f5f7;
  }
`;

const TimeButtonLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DatePicker = ({
  userType,
  selectedDay,
  onClickDay,
  onClickTime,
  className,
  possibleDate,
  otDates,
  style,
}) => {
  const [value, onChange] = useState(new Date());
  const [AMPM, setAMPM] = useState("오전");
  const { width } = useWindowSize();
  const isMobile = width <= 1080;
  const [timeList, setTimeList] = useState([
    { label: "12시", value: "00:00", disabled: false },
    { label: "1시", value: "01:00", disabled: false },
    { label: "2시", value: "02:00", disabled: false },
    { label: "3시", value: "03:00", disabled: false },
    { label: "4시", value: "04:00", disabled: false },
    { label: "5시", value: "05:00", disabled: false },
    { label: "6시", value: "06:00", disabled: false },
    { label: "7시", value: "07:00", disabled: false },
    { label: "8시", value: "08:00", disabled: false },
    { label: "9시", value: "09:00", disabled: false },
    { label: "10시", value: "10:00", disabled: false },
    { label: "11시", value: "11:00", disabled: false },
    { label: "12시", value: "12:00", disabled: false },
    { label: "1시", value: "13:00", disabled: false },
    { label: "2시", value: "14:00", disabled: false },
    { label: "3시", value: "15:00", disabled: false },
    { label: "4시", value: "16:00", disabled: false },
    { label: "5시", value: "17:00", disabled: false },
    { label: "6시", value: "18:00", disabled: false },
    { label: "7시", value: "19:00", disabled: false },
    { label: "8시", value: "20:00", disabled: false },
    { label: "9시", value: "21:00", disabled: false },
    { label: "10시", value: "22:00", disabled: false },
    { label: "11시", value: "23:00", disabled: false },
  ]);
  const [selectTime, setSelectTime] = useState("");

  useEffect(() => {
    if (onClickTime) {
      onClickTime(selectTime);
    }
  }, [selectTime]);

  const setPossibleTime = (date) => {
    console.log("PossibleDate: ", possibleDate);
    if (possibleDate.find((item) => item.date === date)) {
      const selectDate = possibleDate.find((item) => item.date === date).times;
      console.log("selectDate: ", selectDate);
      setTimeList(
        timeList.map((list) => {
          if (selectDate.find((item) => item.time === list.value)) {
            return {
              ...list,
              disabled: selectDate.findLast((item) => item.time === list.value)
                .canJoin,
            };
          } else {
            return {
              ...list,
              disabled: false,
            };
          }
        })
      );
    } else {
      setTimeList(
        timeList.map((list) => {
          return {
            ...list,
            disabled: false,
          };
        })
      );
    }
    console.log("TimeList: ", timeList);
  };

  return (
    <RowWrap style={style}>
      <div>
        <Calendar
          className={"my_page_picker"}
          locale={"ko"}
          formatDay={(_, date) => moment(date).format("D")}
          minDetail={"month"}
          showNeighboringMonth={false}
          prev2Label={null}
          next2Label={null}
          prevLabel={""}
          nextLabel={""}
          calendarType="US"
          tileClassName={({ date }) => {
            if (date > moment().endOf("day")) {
              return "black";
            }
          }}
          tileDisabled={({ date }) => {
            return date < moment().endOf("day").subtract(1, "d");
          }}
          tileContent={({ date }) => {
            if (
              date > moment().endOf("day") &&
              possibleDate.find((x) =>
                moment(x.date).isSame(moment(date).format("YYYY-MM-DD"))
              )
            ) {
              return (
                <>
                  <PossibleDateCircle />
                  {otDates &&
                    otDates.find((item) =>
                      dayjs(item.date).isSame(dayjs(date), "day")
                    ) && <DataDateCircle />}
                </>
              );
            }
          }}
          onClickDay={(date) => {
            if (onClickDay) {
              onClickDay(dayjs(date).format("YYYY-MM-DD"));
              setPossibleTime(dayjs(date).format("YYYY-MM-DD"));
              setSelectTime("");
            }
          }}
          value={value}
          onChange={onChange}
        />
      </div>
      <div
        className={className}
        style={{
          width: "100%",
          marginLeft: isMobile ? 0 : 16,
          marginTop: isMobile ? 16 : 0,
        }}
      >
        <div>
          <AMPMButton
            style={{ marginRight: 16 }}
            active={AMPM === "오전"}
            onClick={() => {
              setAMPM("오전");
            }}
          >
            오전
          </AMPMButton>
          <AMPMButton
            active={AMPM === "오후"}
            onClick={() => {
              setAMPM("오후");
            }}
          >
            오후
          </AMPMButton>
        </div>
        <TimeSlotGrid>
          {timeList.map((item, idx) =>
            AMPM === "오전"
              ? idx < 12 && (
                  <TimeButton
                    key={idx}
                    style={{
                      marginRight: (idx + 1) % 3 === 0 ? 0 : 16,
                    }}
                    active={item.value === selectTime}
                    disabled={userType === "TEACHER" ? false : !item.disabled}
                    onClick={() => {
                      setSelectTime(item.value);
                    }}
                  >
                    <TimeButtonLabel>{item.label}</TimeButtonLabel>
                    {
                      otDates?.map((otDate) => {
                        if (selectedDay === otDate.date) {
                          const comp = otDate.times.map((time) => {
                            if (time.time === item.value) {
                              return (
                                <TimeButtonLabel
                                  style={{
                                    fontSize: "10px",
                                    color: time.canJoin ? "#2e74ff" : "#be2b06",
                                  }}
                                  key={otDate.id + time}
                                >
                                  {time.canJoin ? "수업가능" : "마감완료"}
                                </TimeButtonLabel>
                              );
                            }
                          });
                          return comp;
                        }
                      })

                      /* {otDates &&
                    otDates.find((otDate) =>
                      dayjs(otDate.date).isSame(
                        dayjs(
                          `${dayjs(value).format("YYYY-MM-DD")} ${item.value}`
                        )
                      )
                    ) && (
                      <TimeButtonLabel>
                        {otDates.find((otDate) =>
                          dayjs(otDate.date).isSame(
                            dayjs(
                              `${dayjs(value).format("YYYY-MM-DD")} ${
                                item.value
                              }`
                            )
                          )
                        ).canJoin
                          ? "수업가능"
                          : "마감완료"}
                      </TimeButtonLabel>
                    )} */
                    }
                  </TimeButton>
                )
              : idx > 11 && (
                  <TimeButton
                    key={idx}
                    style={{ marginRight: (idx + 1) % 3 === 0 ? 0 : 16 }}
                    active={item.value === selectTime}
                    disabled={userType === "TEACHER" ? false : !item.disabled}
                    onClick={() => {
                      setSelectTime(item.value);
                    }}
                  >
                    <TimeButtonLabel>{item.label}</TimeButtonLabel>
                    {
                      otDates?.map((otDate) => {
                        if (selectedDay === otDate.date) {
                          const comp = otDate.times.map((time) => {
                            if (time.time === item.value) {
                              return (
                                <TimeButtonLabel
                                  style={{
                                    fontSize: "10px",
                                    color: time.canJoin ? "#2e74ff" : "#be2b06",
                                  }}
                                  key={otDate.id + time}
                                >
                                  {time.canJoin ? "수업가능" : "마감완료"}
                                </TimeButtonLabel>
                              );
                            }
                          });
                          return comp;
                        }
                      })

                      /* {otDates &&
                    otDates.find((otDate) =>
                      dayjs(otDate.date).isSame(
                        dayjs(
                          `${dayjs(value).format("YYYY-MM-DD")} ${item.value}`
                        )
                      )
                    ) && (
                      <TimeButtonLabel>
                        {otDates.find((otDate) =>
                          dayjs(otDate.date).isSame(
                            dayjs(
                              `${dayjs(value).format("YYYY-MM-DD")} ${
                                item.value
                              }`
                            )
                          )
                        ).canJoin
                          ? "수업가능"
                          : "마감완료"}
                      </TimeButtonLabel>
                    )} */
                    }
                  </TimeButton>
                )
          )}
        </TimeSlotGrid>
      </div>
    </RowWrap>
  );
};

export default DatePicker;
