import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { useRecoilValue, useSetRecoilState } from "recoil";

import { API_URL } from "../../../API";
import { runBootpaySubscription } from "../../../components/BootPay";
import prev_btn from "../../../assets/images/prev.png";
import snsTokenState from "../../../states/snsTokenState";
import {
  Container,
  MainWrap,
  PageTypeText,
  JoinTypeText,
  JoinNoticeText,
  Title,
  RowWrap,
  InfoTitle,
  RegexText,
  Border,
  SubjectChoiceWrap,
  ChoiceBox,
  ChoiceText,
  CancleWrap,
  MemberTypeButtonWrap,
  MemberTypeText,
  MemberTypePriceText,
  MemberTypeInfoText,
  MemberTypeInfoWrap,
  MemberTypeInfoTextWrap,
  MemberTypeWhether,
  CheckBoxWrap,
  CheckBoxBorder,
  ButtonWrap,
  RegexCheckText,
  UrlText,
} from "./style";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import TextInput from "../../../components/TextInput";
import Button from "../../../components/Button";
import Select from "../../../components/Select";
import RadioBox from "../../../components/RadioBox";
import CheckBox from "../../../components/CheckBox";
import Modal from "../../../components/Modal";
import { ReactComponent as CheckIcon } from "../../../assets/icons/checkbox.svg";
import { Desktop, Mobile } from "../../../utils/mediaQuery";
import {
  MobileSelectMemberType,
  MobileSignUpContainer,
  MobileSignUpHeader,
  MobileTypeNotice,
} from "./mobile.style";
import { MobileContainer } from "../../login/mobile/login_mobile.style";
import joinModalState from "../../../states/joinModalState";
import { MobileDontKnowBtn } from "../teacher/mobile.style";
import MobileModal from "../../../components/MobileModal";
import styled from "styled-components";

const ValidationWarning = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: #ff3838;
  margin: 10px 0px;
  min-width: fit-content;
`;

const StudentSignUp = (props) => {
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);
  const [email, setEmail] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordCheck, setPasswordCheck] = useState("");
  const [emailLabel, setEmailLabel] = useState("");
  const [numbersCheck, setNumbersCheck] = useState(null);
  const [englishCheck, setEnglishCheck] = useState(null);
  const [firstEnglishCheck, setFirstEnglishCheck] = useState(null);
  const [specialCharCheck, setSpeialCharCheck] = useState(null);
  const [nickName, setNickName] = useState("");
  const [telOption, setTelOption] = useState([
    { value: "KT", label: "KT" },
    { value: "SKT", label: "SKT" },
    { value: "LG U+", label: "LG U+" },
    { value: "알뜰폰 KT", label: "알뜰폰 KT" },
    { value: "알뜰폰 SKT", label: "알뜰폰 SKT" },
    { value: "알뜰폰 LG U+", label: "알뜰폰 LG U+" },
  ]);
  const [telType, setTelType] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [birthDay, setBirthDay] = useState("");
  const [province, setProvince] = useState("");
  const [city, setCity] = useState("");
  const [dong, setDong] = useState("");
  const [memeberType, setMemberType] = useState("무료");
  const [allAgreed, setAllAgreed] = useState(false);
  const [checkedTerms, setCheckedTerms] = useState([]);
  const [phoneNumberAuth, setPhoneNumberAuth] = useState(false);
  const [phoneNumberAuthCheck, setPhoneNumberAuthCheck] = useState("");
  const [unitFocus, setUnitFocus] = useState(false);
  // 인증번호 타이머
  const [min, setMin] = useState(4);
  const [sec, setSec] = useState(0);
  const time = useRef(240);
  const timerId = useRef(null);
  const [countDown, setCountDown] = useState(false);
  const [authModal, setAuthModal] = useState(false);
  const [nickNameCheck, setNickNameCheck] = useState(null);
  // 지역선택
  const [cities, setCities] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [towns, setTowns] = useState([]);
  const [error, setError] = useState(false);
  // 휴대폰인증
  const [authCompleteModal, setAuthCompleteModal] = useState(false);
  const [authErrorModal, setAuthErrorModal] = useState(false);
  const [authComplete, setAuthComplete] = useState(false);
  const [isDuplicateNumber, setIsDuplicateNumber] = useState(false);
  const [wrongPhoneNumber, setWrongPhoneNumber] = useState(false);
  // 과목선택
  const [subjectType, setSubjectType] = useState([]);
  const [mobileTypes, setMobileTypes] = useState([]);
  const [selectSubjectType, setSelectSubjectType] = useState("");
  const [subjectLevel, setSubjectLevel] = useState([]);
  const [selectSubjectLevel, setSelectSubjectLevel] = useState("");
  const [subjectMajors, setSubjectMajors] = useState([]);
  const [selectSubjectMajor, setSelectSubjectMajor] = useState("");
  const [subjectItems, setSubjectItems] = useState([]);
  const [subject, setSubject] = useState([]);
  const [selectSubject, setSelectSubject] = useState([]);
  const [subjectDisabled, setSubjectDisabled] = useState(false);
  const [isDontKnowBtn, setIsDontKnowBtn] = useState(false);
  // 회원가입 값
  const [userRole, setUserRole] = useState("");
  const [gender, setGender] = useState("");
  const [subjectId, setSubjectId] = useState([]);
  const [termsId, setTermsId] = useState([]);
  // 가입버튼 활성화 검사
  const [joinNickName, setJoinNickName] = useState(false);
  // const [joinPhoneCheck, setJoinPhoneCheck] = useState(false);
  const [joinBtn, setJoinBtn] = useState(false);
  const snstokenValue = useRecoilValue(snsTokenState);
  const setJoinModal = useSetRecoilState(joinModalState);

  // 회원가입 버튼 활성화
  useEffect(() => {
    const termCheck = termsId.includes(undefined);
    const emailSignupCheck =
      isEmailValid &&
      englishCheck &&
      firstEnglishCheck &&
      numbersCheck &&
      specialCharCheck &&
      password === passwordCheck;

    if (
      joinNickName &&
      authComplete === true &&
      userRole !== "" &&
      gender !== "" &&
      birthDay.length === 8 &&
      dong.id !== undefined &&
      subjectId.length > 0 &&
      termCheck === false &&
      memeberType !== ""
    ) {
      if (snstokenValue.id === "" && snstokenValue.type === "") {
        setJoinBtn(emailSignupCheck);
      } else {
        setJoinBtn(true);
      }
    } else {
      setJoinBtn(false);
    }
  }, [
    joinNickName,
    authComplete,
    userRole,
    gender,
    birthDay,
    dong,
    subjectId,
    termsId,
    memeberType,
    email,
    password,
    emailLabel,
    englishCheck,
    numbersCheck,
    firstEnglishCheck,
    specialCharCheck,
    passwordCheck,
    snstokenValue.id,
    snstokenValue.type,
  ]);

  useEffect(() => {
    setIsEmailValid(false);
  }, [email]);

  useEffect(() => {
    const regex = /[~!@#$%^&*()_+|<>?:{}]/;
    if (nickName) {
      setNickName(nickName.replace(regex, ""));
    } else {
      setNickNameCheck(null);
    }
  }, [nickName]);

  useEffect(() => {
    setAllAgreed(
      checkedTerms.filter((term) => term.value).length === checkedTerms.length
    );
  }, [checkedTerms]);

  const agreedAll = () => {
    if (allAgreed) {
      setCheckedTerms((currentCheckedItems) =>
        currentCheckedItems.map((term) => ({ ...term, value: false }))
      );
      setTermsId([]);
    } else {
      setCheckedTerms((currentCheckedItems) =>
        currentCheckedItems.map((term) => ({ ...term, value: true }))
      );
    }
  };

  // 휴대폰인증 타이머 스타트
  const start = () => {
    timerId.current = setInterval(() => {
      time.current -= 1;
      setMin(parseInt(time.current / 60));
      setSec(time.current % 60);
      if (time.current < 61) {
        setCountDown(true);
      } else {
        setCountDown(false);
      }
    }, 1000);
  };

  useEffect(() => {
    if (time.current === 0) {
      clearInterval(timerId.current);
      setCountDown(false);
      time.current = 240;
      setAuthModal(true);
    }
  }, [sec]);

  //이메일 중복 체크
  const emailCheck = () => {
    axios({
      method: "POST",
      url: `${API_URL}/user/check/email?email=${email}`,
    })
      .then((result) => {
        if (result.data.success === true) {
          if (result.data.result.isDuplicate === true) {
            setEmailLabel("이미 가입되어있는 이메일 입니다.");
            setIsEmailValid(false);
            setError(true);
          } else {
            setEmailLabel("사용가능한 이메일입니다.");
            setError(false);
            setIsEmailValid(true);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    setEmailLabel(null);
  }, [email]);

  // 닉네임 중복확인
  const nickNameAuthHandler = () => {
    if (nickName) {
      axios({
        url: `${API_URL}/user/check/nickname?nickname=${nickName}`,
        method: "POST",
      })
        .then((result) => {
          if (result.data.success) {
            if (result.data.result.isDuplicate) {
              setNickNameCheck("이미 사용 중인 닉네임 입니다.");
              setError(true);
              setJoinNickName(false);
            } else {
              setNickNameCheck("사용가능한 닉네임 입니다.");
              setError(false);
              setJoinNickName(true);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  // 휴대폰 인증하기
  const phoneNumberAuthHandler = () => {
    console.log(phoneNumber.length);
    if (phoneNumber !== "" && phoneNumber.length === 11) {
      axios({
        url: `${API_URL}/user/sms/send`,
        method: "POST",
        data: {
          phoneNumber: phoneNumber,
        },
      })
        .then((result) => {
          console.log(result.data);
          if (result.data.success) {
            setPhoneNumberAuth(true);
            window.alert("인증번호가 전송되었습니다.");
            if (time.current < 240) {
              clearInterval(timerId.current);
              time.current = 240;
              start();
            } else {
              clearInterval(timerId.current);
              start();
            }
          } else if (
            result.data.error.message === "이미 가입된 휴대전화번호입니다."
          ) {
            setIsDuplicateNumber(true);
          } else {
            setWrongPhoneNumber(true);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setWrongPhoneNumber(true);
    }
  };

  const phoneNumberAuthCheckHandler = () => {
    if (time.current < 240) {
      clearInterval(timerId.current);
      time.current = 240;
    } else {
      clearInterval(timerId.current);
    }

    axios({
      url: `${API_URL}/user/sms/verify`,
      method: "POST",
      data: {
        phoneNumber: phoneNumber,
        authCode: phoneNumberAuthCheck,
      },
    })
      .then((result) => {
        if (result.data.success) {
          setAuthCompleteModal(true);
          setAuthComplete(true);
        } else {
          setAuthErrorModal(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // 지역선택
  useEffect(() => {
    axios({
      url: `${API_URL}/area/cities`,
      method: "GET",
    })
      .then((result) => {
        if (result.data.success) {
          setCities(
            result.data.result.cities.map((item) => {
              return {
                label: item,
                value: item,
              };
            })
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const citySelectHandler = (value) => {
    axios({
      url: `${API_URL}/area/districts?cityName=${value.value}`,
      method: "GET",
    })
      .then((result) => {
        if (result.data.success) {
          setDistricts(
            result.data.result.districts.map((item) => {
              return {
                label: item,
                value: item,
              };
            })
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const districtsSelectHandler = (value) => {
    axios({
      url: `${API_URL}/area/towns?cityName=${province.value}&districtName=${value.value}`,
      method: "GET",
    })
      .then((result) => {
        if (result.data.success) {
          setTowns(
            result.data.result.towns.map((item) => {
              return {
                label: item.town,
                value: item.town,
                id: item.id,
              };
            })
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // 과목선택
  useEffect(() => {
    axios({
      url: `${API_URL}/subject/types`,
      method: "GET",
    })
      .then((result) => {
        setMobileTypes(
          result.data.result.types.map((item) => {
            return {
              label: item.type,
              name: "kind",
              value: item.type,
              id: item.type,
            };
          })
        );
        setSubjectType(
          result.data.result.types
            .map((item) => {
              return {
                label: item.type,
                name: "kind",
                value: item.type,
                id: item.type,
              };
            })
            .concat({
              label: "아직 잘 모르겠어요",
              name: "kind",
              value: "아직 잘 모르겠어요",
              id: "아직 잘 모르겠어요",
              disabled: subjectDisabled,
            })
        );
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const subjectTypeHandler = (value) => {
    selectSubject.length > 0
      ? setSubjectDisabled(true)
      : setSubjectDisabled(false);
    if (value !== "아직 잘 모르겠어요") {
      axios({
        url: `${API_URL}/subject/levels?type=${value}`,
        method: "GET",
      })
        .then((result) => {
          setSubjectLevel(
            result.data.result.levels.map((item) => {
              return {
                label: item.level,
                name: "level",
                value: item.level,
                id: item.level,
              };
            })
          );
          setSubject([
            ...subject,
            ...result.data.result.levels.map((item) => {
              return item;
            }),
          ]);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setSubjectLevel([]);
      setSubjectId(value);
    }
  };

  const subjectLevelHandler = (value) => {
    axios({
      url: `${API_URL}/subject/majors?type=${selectSubjectType}&level=${value}`,
      method: "GET",
    })
      .then((result) => {
        setSubjectMajors(
          result.data.result.majors.map((item) => {
            return {
              label: item.name,
              name: "major",
              value: item.name,
              id: item.name,
            };
          })
        );
        setSubject([
          ...subject,
          ...result.data.result.majors.map((item) => {
            return item;
          }),
        ]);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const subjectMajorsHandler = (value) => {
    axios({
      url: `${API_URL}/subject/items?type=${selectSubjectType}&level=${selectSubjectLevel}&major=${value}`,
      method: "GET",
    })
      .then((result) => {
        setSubjectItems(
          result.data.result.items.map((item) => {
            return {
              label: item.progress,
              name: "items",
              value: item.progress,
              id: item.progress,
            };
          })
        );
        setSubject([
          ...subject,
          ...result.data.result.items.map((item) => {
            return item;
          }),
        ]);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    axios({
      url: `${API_URL}/terms`,
      method: "GET",
    })
      .then((result) => {
        if (result.data.success) {
          setCheckedTerms(result.data.result.terms, { value: false });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  // 회원가입
  const signUp = (billingKey) => {
    axios({
      method: "POST",
      url: `${API_URL}/user`,
      data: {
        userType: "USER",
        email: email,
        password: password,
        socialType: snstokenValue.type,
        socialToken: snstokenValue.id.toString(),
        nickname: nickName,
        phoneNumber: phoneNumber,
        phoneAuthCode: phoneNumberAuthCheck,
        userRole: userRole,
        gender: gender,
        birthday: moment(birthDay).format("YYYY-MM-DD"),
        areaId: dong.id,
        subjectIds: subjectId,
        agreeTerms: termsId,
        billingKey: billingKey,
      },
    })
      .then((result) => {
        if (result.data.success) {
          setModal(true);
        } else {
          alert(result.data.error.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    setTermsId(
      checkedTerms.map((term) => {
        if (term.value) {
          return term.id;
        }
      })
    );
  }, [checkedTerms]);

  useEffect(() => {
    const numbersCheck = /[0-9]/.test(password);
    const englishCheck = /^[a-zA-Z]/.test(password);
    const firstEnglishCheck = /^[a-zA-Z]/.test(password.at(0));
    const specialCharCheck = /(?=.*[$@$!%*#?&])/.test(password);

    if (password) {
      if (numbersCheck === true) {
        setNumbersCheck(true);
      } else {
        setNumbersCheck(false);
      }

      if (englishCheck === true) {
        setEnglishCheck(true);
      } else {
        setEnglishCheck(false);
      }

      if (specialCharCheck === true) {
        setSpeialCharCheck(true);
      } else {
        setSpeialCharCheck(false);
      }

      setFirstEnglishCheck(firstEnglishCheck);
    } else {
      setNumbersCheck(null);
      setEnglishCheck(null);
      setSpeialCharCheck(null);
    }
  }, [
    password,
    firstEnglishCheck,
    numbersCheck,
    englishCheck,
    specialCharCheck,
  ]);

  return (
    <>
      <Desktop>
        <Header activeTab={"Login"} />
        <Container>
          <MainWrap>
            <PageTypeText>회원가입</PageTypeText>
            <JoinTypeText>학생 / 학부모</JoinTypeText>
            <JoinNoticeText>
              회원가입을 위해 몇가지의 정보가 필요합니다.
            </JoinNoticeText>
            <Title>가입 정보</Title>
            {snstokenValue.id === "" && snstokenValue.type === "" && (
              <>
                <InfoTitle>이메일을 입력해주세요.</InfoTitle>
                <RowWrap
                  style={{
                    marginBottom: emailLabel === null ? "80px" : "48px",
                  }}
                >
                  <TextInput
                    style={{
                      width: 636,
                    }}
                    value={email}
                    placeholder={"이메일 입력"}
                    onChange={(value) => {
                      setEmail(value);
                    }}
                    marginBottom={"0px"}
                    label={emailLabel}
                    error={
                      emailLabel === "사용가능한 이메일입니다." ? false : true
                    }
                  />
                  <Button
                    active
                    style={{ width: 160, marginLeft: 16 }}
                    onClick={emailCheck}
                  >
                    중복확인
                  </Button>
                </RowWrap>
                <InfoTitle>비밀번호를 입력해주세요.</InfoTitle>
                <RowWrap>
                  <div>
                    <TextInput
                      style={{
                        width: 398,
                        marginRight: 16,
                        marginBottom: 8,
                      }}
                      value={password}
                      placeholder={"8~20자 입력"}
                      onChange={(value) => {
                        setPassword(value);
                      }}
                      maxLength={20}
                      marginBottom={"0px"}
                      type={"password"}
                    />
                    <RowWrap marginBottom={"0px"}>
                      <CheckIcon
                        style={{ width: 18, heigth: 18 }}
                        color={
                          firstEnglishCheck === null
                            ? "#ADB5BD"
                            : firstEnglishCheck === true
                            ? "#0081F5"
                            : "#FF383B"
                        }
                      />
                      <RegexCheckText error={firstEnglishCheck}>
                        첫 글자 영문
                      </RegexCheckText>
                      <CheckIcon
                        style={{ width: 18, heigth: 18 }}
                        color={
                          firstEnglishCheck === null
                            ? "#ADB5BD"
                            : firstEnglishCheck === true
                            ? "#0081F5"
                            : "#FF383B"
                        }
                      />
                      <RegexCheckText error={englishCheck}>영문</RegexCheckText>
                      <CheckIcon
                        style={{ width: 18, heigth: 18 }}
                        color={
                          numbersCheck === null
                            ? "#ADB5BD"
                            : numbersCheck === true
                            ? "#0081F5"
                            : "#FF383B"
                        }
                      />
                      <RegexCheckText error={numbersCheck}>숫자</RegexCheckText>
                      <CheckIcon
                        style={{ width: 18, heigth: 18 }}
                        color={
                          specialCharCheck === null
                            ? "#ADB5BD"
                            : specialCharCheck === true
                            ? "#0081F5"
                            : "#FF383B"
                        }
                      />
                      <RegexCheckText error={specialCharCheck}>
                        특수문자
                      </RegexCheckText>
                    </RowWrap>
                  </div>
                  <TextInput
                    style={{
                      width: 398,
                    }}
                    value={passwordCheck}
                    placeholder={"비밀번호 확인"}
                    onChange={(value) => {
                      setPasswordCheck(value);
                    }}
                    maxLength={20}
                    marginBottom={"0px"}
                    type={"password"}
                    label={
                      passwordCheck !== ""
                        ? password === passwordCheck
                          ? "비밀번호가 일치합니다."
                          : "비밀번호가 일치하지 않습니다."
                        : undefined
                    }
                    error={password !== passwordCheck}
                  />
                </RowWrap>
              </>
            )}
            <InfoTitle>닉네임 또는 실명을 입력해주세요.</InfoTitle>
            <RegexText>특수문자 제외, 10글자 이하로 입력해주세요.</RegexText>
            <RowWrap
              style={{ marginBottom: nickNameCheck === null ? "80px" : "48px" }}
            >
              <TextInput
                style={{
                  width: 636,
                }}
                value={nickName}
                placeholder={"닉네임 또는 실명 입력"}
                onChange={(value) => {
                  setNickName(value);
                }}
                maxLength={10}
                label={nickNameCheck}
                error={error}
                marginBottom={"0px"}
              />
              <Button
                active
                style={{ width: 160, marginLeft: 16 }}
                onClick={nickNameAuthHandler}
              >
                중복확인
              </Button>
            </RowWrap>
            <InfoTitle>회원님의 휴대폰 인증이 필요합니다.</InfoTitle>
            <RowWrap marginBottom={phoneNumberAuth ? "20px" : "80px"}>
              {/* <Select
              value={telType}
              placeholder={"통신사 선택"}
              width={260}
              option={telOption}
              onChange={(value) => {
                setTelType(value);
              }}
            /> */}
              <TextInput
                disabled={authComplete}
                style={{
                  width: 636,
                  border: authComplete && "1px solid #dde2ea",
                }}
                value={phoneNumber}
                onChange={(value) => {
                  setPhoneNumber(value.replace(/[^0-9]/g, ""));
                }}
                placeholder={"(-) 제외한 숫자만 입력하세요."}
              />
              <Button
                grey={phoneNumberAuth}
                active={!phoneNumberAuth}
                style={{
                  marginLeft: 16,
                  width: 160,
                  pointerEvents: authComplete && "none",
                }}
                onClick={phoneNumberAuthHandler}
                disabled={authComplete}
              >
                {phoneNumberAuth ? "재요청" : "인증요청"}
              </Button>
            </RowWrap>
            {phoneNumberAuth ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "start",
                  alignItems: "start",
                  marginBottom: "80px",
                }}
              >
                <RowWrap style={{ marginBottom: "0" }}>
                  <TextInput
                    type="numberic"
                    style={{
                      width: 636,
                      border: authComplete && "1px solid #dde2ea",
                      marginBottom: "8px",
                    }}
                    unitAuth={
                      authComplete ? null : (
                        <div>
                          {min}:{sec < 10 ? `0${sec}` : sec}
                        </div>
                      )
                    }
                    countDown={countDown}
                    unitFocus={unitFocus}
                    onFocus={() => {
                      setUnitFocus(true);
                    }}
                    onBlur={() => {
                      setUnitFocus(false);
                    }}
                    value={phoneNumberAuthCheck}
                    onChange={(value) => {
                      setPhoneNumberAuthCheck(value);
                    }}
                    disabled={authComplete}
                  />
                  <Button
                    active={!authComplete}
                    disabled={authComplete}
                    style={{
                      marginLeft: 16,
                      width: 160,
                      pointerEvents: authComplete && "none",
                    }}
                    onClick={phoneNumberAuthCheckHandler}
                  >
                    인증확인
                  </Button>
                </RowWrap>
                {authComplete && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: "16px",
                      color: "#0081F5",
                    }}
                  >
                    <CheckIcon
                      style={{ width: 18, heigth: 18 }}
                      color={
                        firstEnglishCheck === null
                          ? "#ADB5BD"
                          : firstEnglishCheck === true
                          ? "#0081F5"
                          : "#FF383B"
                      }
                    />
                    <p style={{ marginLeft: "4px" }}>인증되었습니다.</p>
                  </div>
                )}
              </div>
            ) : null}

            <Border />
            <Title>세부 정보</Title>
            <InfoTitle>회원님은 학생이신가요?</InfoTitle>
            <RowWrap>
              <RadioBox
                option={[
                  {
                    label: "네 학생입니다",
                    name: "type",
                    value: "STUDENT",
                    id: "student",
                  },
                  {
                    label: "아니요 학부모입니다",
                    name: "type",
                    value: "PARENTS",
                    id: "parent",
                  },
                ]}
                showLine={2}
                onChange={(value) => {
                  setUserRole(value);
                }}
              />
            </RowWrap>
            <InfoTitle>학생의 성별을 선택해주세요.</InfoTitle>
            <RowWrap>
              <RadioBox
                option={[
                  {
                    label: "남자입니다.",
                    name: "gender",
                    value: "MALE",
                    id: "man",
                  },
                  {
                    label: "여자입니다",
                    name: "gender",
                    value: "FEMALE",
                    id: "woman",
                  },
                ]}
                showLine={2}
                onChange={(value) => {
                  setGender(value);
                }}
              />
            </RowWrap>
            <InfoTitle>학생의 생년월일을 입력해주세요.</InfoTitle>
            <TextInput
              placeholder={"YYYYMMDD"}
              value={birthDay}
              onChange={(value) => {
                setBirthDay(value);
              }}
              maxLength={8}
              style={{ width: "100%", marginBottom: "48px" }}
            />
            <InfoTitle>회원님의 거주지를 선택해주세요.</InfoTitle>
            <RowWrap>
              <Select
                option={cities}
                placeholder={"시/도"}
                value={province}
                onChange={(value) => {
                  setProvince(value);
                  setDistricts([]);
                  setTowns([]);
                  setCity("");
                  setDong("");
                  citySelectHandler(value);
                }}
              />
              <Select
                option={districts}
                placeholder={"시/군/구"}
                value={city}
                onChange={(value) => {
                  setCity(value);
                  setTowns([]);
                  setDong("");
                  districtsSelectHandler(value);
                }}
              />
              <Select
                option={towns}
                placeholder={"읍/면/동"}
                value={dong}
                onChange={(value) => {
                  setDong(value);
                }}
                marginRight={"0px"}
              />
            </RowWrap>
            <RowWrap
              style={{ justifyContent: "space-between" }}
              marginBottom={"0px"}
            >
              <InfoTitle marginBottom={"20px"} marginTop={"0px"}>
                회원님의 필요한 과목을 선택해주세요.
              </InfoTitle>
              <RegexText>*복수 선택이 가능합니다.</RegexText>
            </RowWrap>
            <SubjectChoiceWrap>
              {selectSubject.length > 0 &&
                selectSubject.map((item, idx) => {
                  if (item.type === "국내") {
                    return (
                      <ChoiceBox key={idx}>
                        <ChoiceText>{`${item.type}(${item.level}) - ${item.name}(${item.progress})`}</ChoiceText>
                        <CancleWrap
                          onClick={() => {
                            setSelectSubject(
                              selectSubject.filter(
                                (selectItem) => item !== selectItem
                              )
                            );
                            setSubjectId(
                              subjectId.filter((itemId) => itemId !== item.id)
                            );
                          }}
                        />
                      </ChoiceBox>
                    );
                  } else {
                    return (
                      <ChoiceBox key={idx}>
                        <ChoiceText>{`${item.type} - ${item.level}`}</ChoiceText>
                        <CancleWrap
                          onClick={() => {
                            setSelectSubject(
                              selectSubject.filter(
                                (selectItem) => item !== selectItem
                              )
                            );
                            setSubjectId(
                              subjectId.filter((itemId) => itemId !== item.id)
                            );
                          }}
                        />
                      </ChoiceBox>
                    );
                  }
                })}
            </SubjectChoiceWrap>
            <RowWrap marginBottom={subjectLevel.length > 0 ? "12px" : "80px"}>
              <RadioBox
                disabled={selectSubject.length > 0}
                marginBottom="0"
                option={subjectType}
                showLine={4}
                onChange={(value) => {
                  setSubjectLevel([]);
                  setSubjectMajors([]);
                  setSubjectItems([]);
                  setSelectSubjectType(value);
                  subjectTypeHandler(value);
                }}
              />
            </RowWrap>
            {subjectLevel.length > 0 ? (
              <>
                <Border
                  marginBottom={subjectLevel.length > 0 ? "32px" : "80px"}
                />
                <RowWrap marginBottom={"12px"}>
                  <RadioBox
                    marginBottom="0"
                    option={subjectLevel}
                    showLine={4}
                    onChange={(value) => {
                      if (selectSubjectType === "국내") {
                        setSubjectMajors([]);
                        setSubjectItems([]);
                        setSelectSubjectLevel(value);
                        subjectLevelHandler(value);
                      } else {
                        subject.forEach((item) => {
                          if (
                            item.level === value &&
                            item.type === selectSubjectType
                          ) {
                            if (
                              selectSubject.filter(
                                (subItem) => item.id === subItem.id
                              ).length < 1
                            ) {
                              setSelectSubject([...selectSubject, item]);
                              setSubjectId([...subjectId, item.id]);
                            }
                          }
                        });
                      }

                      setSelectSubjectLevel(value);
                    }}
                  />
                </RowWrap>
              </>
            ) : null}

            {subjectMajors.length > 0 ? (
              <>
                <Border
                  marginBottom={subjectMajors.length > 0 ? "32px" : "80px"}
                />
                <RowWrap marginBottom={"12px"}>
                  <RadioBox
                    marginBottom="0"
                    option={subjectMajors}
                    showLine={4}
                    onChange={(value) => {
                      setSubjectItems([]);
                      subjectMajorsHandler(value);
                    }}
                  />
                </RowWrap>
              </>
            ) : null}

            {subjectItems.length > 0 ? (
              <>
                <Border
                  marginBottom={subjectItems.length > 0 ? "32px" : "80px"}
                />
                <RowWrap marginBottom={"80px"}>
                  <RadioBox
                    marginBottom="0"
                    option={subjectItems}
                    showLine={4}
                    onChange={(value) => {
                      if (value) {
                        subject.map((item) => {
                          if (item.progress === value) {
                            setSelectSubject([...selectSubject, item]);
                            setSubjectId([...subjectId, item.id]);
                          }
                        });
                      }
                    }}
                  />
                </RowWrap>
              </>
            ) : null}
            <div style={{ position: "relative" }}>
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  position: "absolute",
                  top: 0,
                  left: 0,
                  background: "rgba(255, 255, 255, 0.9)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <h1
                  style={{
                    textAlign: "center",
                    fontSize: 32,
                    fontWeight: "bold",
                  }}
                >
                  이벤트 진행 중
                </h1>
                <h2
                  style={{
                    textAlign: "center",
                    fontSize: 24,
                    fontWeight: 500,
                    marginTop: 32,
                  }}
                >
                  <span style={{ color: "#FFAD2E" }}>6개월 간</span>{" "}
                  <span style={{ color: "#FFAD2E" }}>무료</span>로 모든 서비스{" "}
                  <span style={{ color: "#FFAD2E" }}>이용 가능</span>
                </h2>
              </div>
              <InfoTitle>회원 유형을 선택해주세요.</InfoTitle>
              <RegexText>
                {
                  " *유료회원의 경우 중도해지 시 환불은 불가합니다. 자세한 내용은 "
                }
                <UrlText
                  onClick={() => {
                    window.open("", "_blank");
                  }}
                >
                  이용약관
                </UrlText>
                을 확인해주세요.
              </RegexText>
              <RowWrap marginBottom={"20px"}>
                <MemberTypeButtonWrap
                  active={memeberType === "무료"}
                  style={{ marginRight: 16 }}
                  onClick={() => {
                    setMemberType("무료");
                  }}
                >
                  <div style={{ pointerEvents: "none" }}>
                    <MemberTypeText active={memeberType === "무료"}>
                      무료 회원
                    </MemberTypeText>
                    <MemberTypePriceText active={memeberType === "무료"}>
                      0원
                    </MemberTypePriceText>
                  </div>
                </MemberTypeButtonWrap>
                <MemberTypeButtonWrap
                  active={memeberType === "유료"}
                  onClick={() => {
                    setMemberType("유료");
                  }}
                >
                  <div style={{ pointerEvents: "none" }}>
                    <MemberTypeText active={memeberType === "유료"}>
                      유료 회원
                    </MemberTypeText>
                    <MemberTypePriceText active={memeberType === "유료"}>
                      월 11,000원
                    </MemberTypePriceText>
                  </div>
                </MemberTypeButtonWrap>
              </RowWrap>
              <RowWrap>
                <MemberTypeInfoWrap style={{ marginRight: 16 }}>
                  <MemberTypeInfoTextWrap>
                    <MemberTypeInfoText>소개페이지 보기</MemberTypeInfoText>
                    <MemberTypeWhether>가능</MemberTypeWhether>
                  </MemberTypeInfoTextWrap>
                  <MemberTypeInfoTextWrap>
                    <MemberTypeInfoText>
                      강사 리스트 및 상세내역 확인
                    </MemberTypeInfoText>
                    <MemberTypeWhether>가능</MemberTypeWhether>
                  </MemberTypeInfoTextWrap>
                  <MemberTypeInfoTextWrap>
                    <MemberTypeInfoText>OT 신청 / 수업 예약</MemberTypeInfoText>
                    <MemberTypeWhether impossibility>불가능</MemberTypeWhether>
                  </MemberTypeInfoTextWrap>
                  <MemberTypeInfoTextWrap>
                    <MemberTypeInfoText>자료실 이용</MemberTypeInfoText>
                    <MemberTypeWhether impossibility>불가능</MemberTypeWhether>
                  </MemberTypeInfoTextWrap>
                  <MemberTypeInfoTextWrap>
                    <MemberTypeInfoText>
                      질의응답 페이지 상세내용 및 댓글 확인
                    </MemberTypeInfoText>
                    <MemberTypeWhether impossibility>불가능</MemberTypeWhether>
                  </MemberTypeInfoTextWrap>
                </MemberTypeInfoWrap>
                <MemberTypeInfoWrap>
                  <MemberTypeInfoTextWrap>
                    <MemberTypeInfoText>소개페이지 보기</MemberTypeInfoText>
                    <MemberTypeWhether>가능</MemberTypeWhether>
                  </MemberTypeInfoTextWrap>
                  <MemberTypeInfoTextWrap>
                    <MemberTypeInfoText>
                      강사 리스트 및 상세내역 확인
                    </MemberTypeInfoText>
                    <MemberTypeWhether>가능</MemberTypeWhether>
                  </MemberTypeInfoTextWrap>
                  <MemberTypeInfoTextWrap>
                    <MemberTypeInfoText>OT 신청 / 수업 예약</MemberTypeInfoText>
                    <MemberTypeWhether>가능</MemberTypeWhether>
                  </MemberTypeInfoTextWrap>
                  <MemberTypeInfoTextWrap>
                    <MemberTypeInfoText>자료실 이용</MemberTypeInfoText>
                    <MemberTypeWhether>가능</MemberTypeWhether>
                  </MemberTypeInfoTextWrap>
                  <MemberTypeInfoTextWrap>
                    <MemberTypeInfoText>
                      질의응답 페이지 상세내용 및 댓글 확인
                    </MemberTypeInfoText>
                    <MemberTypeWhether>가능</MemberTypeWhether>
                  </MemberTypeInfoTextWrap>
                </MemberTypeInfoWrap>
              </RowWrap>
            </div>
            <CheckBoxWrap>
              <CheckBox
                all
                label={"전체 동의"}
                value={allAgreed}
                onChange={(value) => {
                  agreedAll();
                }}
              />
              <CheckBoxBorder />
              {checkedTerms.map((item, idx) => (
                <CheckBox
                  showDetailOnPress={() => {
                    window.open(`${item.url}`, "_blank");
                  }}
                  key={idx}
                  label={item.title}
                  mandatory={item.required}
                  value={item.value}
                  onChange={(value) => {
                    setCheckedTerms(
                      checkedTerms.map((term) => {
                        if (item.id === term.id) {
                          return {
                            ...term,
                            value: value,
                          };
                        }
                        return term;
                      })
                    );
                  }}
                />
              ))}
            </CheckBoxWrap>
            <ButtonWrap style={{ padding: "0" }}>
              {!joinBtn && (
                <ValidationWarning>
                  필수 입력 정보 및 중복확인/인증 여부를 확인해주세요.
                </ValidationWarning>
              )}
              <Button
                style={{ width: "300px" }}
                disabled={!joinBtn}
                onClick={() => {
                  if (!joinBtn) {
                    if (!joinNickName) {
                      alert("닉네임 또는 실명이 입력되지 않았습니다!");
                      return;
                    }

                    if (!authComplete) {
                      alert("휴대폰 인증이 완료되지 않았습니다!");
                      return;
                    }

                    if (userRole === "") {
                      alert("학생 여부가 선택되지 않았습니다!");
                      return;
                    }

                    if (gender === "") {
                      alert("성별이 선택되지 않았습니다!");
                      return;
                    }

                    if (birthDay.length !== 8) {
                      alert("생년월일이 입력되지 않았습니다!");
                      return;
                    }

                    if (subjectId.length <= 0) {
                      alert("과목이 선택되지 않았습니다!");
                      return;
                    }
                  } else {
                    if (memeberType === "유료") {
                      runBootpaySubscription(
                        "영재멘토 유료회원",
                        11000,
                        nickName,
                        phoneNumber,
                        "",
                        () => {},
                        (billingKey) => {
                          signUp(billingKey);
                        }
                      );
                    } else {
                      signUp();
                    }
                  }
                }}
              >
                가입하기
              </Button>
            </ButtonWrap>
          </MainWrap>
          <Footer />
        </Container>
        {modal && (
          <Modal
            title={"회원가입이 완료되었습니다."}
            content={"영재멘토의 다양한 서비스를 이용해보세요."}
            button={[
              {
                title: "로그인하기",
                type: "active",
              },
            ]}
            onPress={() => {
              navigate("/login");
            }}
          />
        )}
        {authModal ? (
          <Modal
            padding
            title={"입력시간 초과"}
            content={"인증번호 입력시간이 초과되었습니다. 다시 입력해주세요."}
            button={[
              {
                title: "재전송",
                type: "active",
              },
            ]}
            onPress={() => {
              setAuthModal(false);
              start();
            }}
          />
        ) : null}
        {authCompleteModal ? (
          <Modal
            padding
            title={"인증완료"}
            content={"인증이완료되었습니다"}
            button={[
              {
                title: "확인",
                type: "active",
              },
            ]}
            onPress={() => {
              setAuthCompleteModal(false);
            }}
          />
        ) : null}
        {authErrorModal ? (
          <Modal
            padding
            title={"인증 실패"}
            content={"입력한 인증번호가 일치하지 않습니다. 다시 입력해주세요."}
            button={[
              {
                title: "확인",
                type: "active",
              },
            ]}
            onPress={() => {
              setAuthErrorModal(false);
            }}
          />
        ) : null}
        {isDuplicateNumber ? (
          <Modal
            padding
            title={"오류"}
            content={"이미 가입된 휴대전화번호입니다."}
            button={[
              {
                title: "확인",
                type: "active",
              },
            ]}
            onPress={() => {
              setIsDuplicateNumber(false);
            }}
          />
        ) : null}
        {wrongPhoneNumber ? (
          <Modal
            padding
            title={"오류"}
            content={"잘못된 휴대전화번호입니다."}
            button={[
              {
                title: "확인",
                type: "active",
              },
            ]}
            onPress={() => {
              setWrongPhoneNumber(false);
            }}
          />
        ) : null}
      </Desktop>
      <Mobile>
        <MobileContainer>
          <MobileSignUpHeader>
            <button
              onClick={() => {
                setJoinModal(true);
                navigate("/login");
              }}
            >
              <img src={prev_btn} alt="prev" />
            </button>
            <h2>학생/학부모 회원가입</h2>
          </MobileSignUpHeader>
          <MobileSignUpContainer>
            <h1>가입 정보</h1>
            {snstokenValue.id === "" && snstokenValue.type === "" && (
              <>
                <label>
                  <InfoTitle
                    style={{
                      fontSize: 16,
                      fontWeight: 700,
                      lineHeight: "23px",
                    }}
                  >
                    이메일을 입력해주세요.
                  </InfoTitle>
                  <TextInput
                    style={{
                      width: 342,
                      height: 56,
                    }}
                    value={email}
                    placeholder={"이메일 입력"}
                    onChange={(value) => {
                      setEmail(value);
                    }}
                    marginBottom={"0px"}
                    label={emailLabel}
                    error={
                      emailLabel === "사용가능한 이메일입니다." ? false : true
                    }
                  />
                  <Button
                    active
                    style={{ width: 342, marginTop: 16 }}
                    onClick={emailCheck}
                  >
                    중복확인
                  </Button>
                </label>
                <label>
                  <InfoTitle
                    style={{
                      fontSize: 16,
                      fontWeight: 700,
                      lineHeight: "23px",
                    }}
                  >
                    비밀번호를 입력해주세요
                  </InfoTitle>
                  <TextInput
                    style={{
                      width: 342,
                      marginBottom: 6,
                    }}
                    value={password}
                    placeholder={"8~20자 입력"}
                    onChange={(value) => {
                      setPassword(value);
                    }}
                    maxLength={20}
                    marginBottom={"0px"}
                    type={"password"}
                  />
                  <RowWrap marginBottom={"16px"}>
                    <CheckIcon
                      style={{ width: 18, heigth: 18 }}
                      color={
                        firstEnglishCheck === null
                          ? "#ADB5BD"
                          : firstEnglishCheck === true
                          ? "#0081F5"
                          : "#FF383B"
                      }
                    />
                    <RegexCheckText error={firstEnglishCheck}>
                      첫 글자 영문
                    </RegexCheckText>
                    <CheckIcon
                      style={{ width: 18, heigth: 18 }}
                      color={
                        firstEnglishCheck === null
                          ? "#ADB5BD"
                          : firstEnglishCheck === true
                          ? "#0081F5"
                          : "#FF383B"
                      }
                    />
                    <RegexCheckText error={englishCheck}>영문</RegexCheckText>
                    <CheckIcon
                      style={{ width: 18, heigth: 18 }}
                      color={
                        numbersCheck === null
                          ? "#ADB5BD"
                          : numbersCheck === true
                          ? "#0081F5"
                          : "#FF383B"
                      }
                    />
                    <RegexCheckText error={numbersCheck}>숫자</RegexCheckText>
                    <CheckIcon
                      style={{ width: 18, heigth: 18 }}
                      color={
                        specialCharCheck === null
                          ? "#ADB5BD"
                          : specialCharCheck === true
                          ? "#0081F5"
                          : "#FF383B"
                      }
                    />
                    <RegexCheckText error={specialCharCheck}>
                      특수문자
                    </RegexCheckText>
                  </RowWrap>
                  <TextInput
                    style={{
                      width: 342,
                    }}
                    value={passwordCheck}
                    placeholder={"비밀번호 확인"}
                    onChange={(value) => {
                      setPasswordCheck(value);
                    }}
                    maxLength={20}
                    marginBottom={"0px"}
                    type={"password"}
                    label={
                      passwordCheck !== ""
                        ? password === passwordCheck
                          ? "비밀번호가 일치합니다."
                          : "비밀번호가 일치하지 않습니다."
                        : undefined
                    }
                    error={password !== passwordCheck}
                  />
                </label>
              </>
            )}

            <label>
              <InfoTitle
                style={{
                  marginBottom: 8,
                  fontSize: 16,
                  fontWeight: 700,
                  lineHeight: "23px",
                }}
              >
                닉네임을 입력해주세요
              </InfoTitle>
              <RegexText
                style={{ lineHeight: "20px", fontSize: 14, marginBottom: 16 }}
              >
                특수문자 제외, 10글자 이하로 입력해주세요.
              </RegexText>
              <TextInput
                style={{
                  width: 342,
                }}
                value={nickName}
                placeholder={"닉네임 또는 실명 입력"}
                onChange={(value) => {
                  setNickName(value);
                }}
                maxLength={10}
                label={nickNameCheck}
                error={error}
                marginBottom={"0px"}
              />
              <Button
                active
                style={{ width: 342, marginTop: 16 }}
                onClick={nickNameAuthHandler}
              >
                중복확인
              </Button>
            </label>
            <label>
              <InfoTitle
                style={{
                  marginBottom: 8,
                  fontSize: 16,
                  fontWeight: 700,
                  lineHeight: "23px",
                }}
              >
                휴대전화 인증이 필요합니다.
              </InfoTitle>

              <TextInput
                style={{
                  width: 342,
                  border: authComplete && "1px solid #dde2ea",
                }}
                value={phoneNumber}
                onChange={(value) => {
                  setPhoneNumber(value.replace(/[^0-9]/g, ""));
                }}
                placeholder={"(-) 제외한 숫자만 입력허세요."}
                maxLength={11}
                disabled={authComplete}
              />
              <Button
                grey={phoneNumberAuth}
                active={!phoneNumberAuth}
                style={{ width: 342, pointerEvents: authComplete && "none" }}
                onClick={phoneNumberAuthHandler}
                disabled={authComplete}
              >
                {phoneNumberAuth ? "재요청" : "인증요청"}
              </Button>
              {phoneNumberAuth ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "start",
                    alignItems: "start",
                    marginBottom: "40px",
                  }}
                >
                  <TextInput
                    type="numberic"
                    style={{
                      marginBottom: 16,
                      marginTop: 30,
                      width: 342,
                      border: authComplete && "1px solid #dde2ea",
                      marginBottom: "8px",
                    }}
                    unitAuth={
                      authComplete ? null : (
                        <div style={{ color: "#FF383B", marginTop: 15 }}>
                          남은시간 : {min}:{sec < 10 ? `0${sec}` : sec}
                        </div>
                      )
                    }
                    countDown={countDown}
                    unitFocus={unitFocus}
                    onFocus={() => {
                      setUnitFocus(true);
                    }}
                    onBlur={() => {
                      setUnitFocus(false);
                    }}
                    value={phoneNumberAuthCheck}
                    onChange={(value) => {
                      setPhoneNumberAuthCheck(value);
                    }}
                    disabled={authComplete}
                  />
                  <Button
                    active={!authComplete}
                    style={{
                      width: 342,
                      pointerEvents: authComplete && "none",
                    }}
                    onClick={phoneNumberAuthCheckHandler}
                    disabled={authComplete}
                  >
                    인증확인
                  </Button>
                  {authComplete && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "16px",
                        color: "#0081F5",
                      }}
                    >
                      <CheckIcon
                        style={{ width: 18, heigth: 18 }}
                        color={
                          firstEnglishCheck === null
                            ? "#ADB5BD"
                            : firstEnglishCheck === true
                            ? "#0081F5"
                            : "#FF383B"
                        }
                      />
                      <p style={{ marginLeft: "4px" }}>인증되었습니다.</p>
                    </div>
                  )}
                </div>
              ) : null}
            </label>

            <h1>세부 정보</h1>
            <label>
              <InfoTitle
                style={{
                  marginBottom: 17,
                  fontSize: 16,
                  fontWeight: 700,
                  lineHeight: "23px",
                }}
              >
                회원님의 학생이신가요?
              </InfoTitle>
              <RowWrap style={{ width: 342, height: 56, marginBottom: 24 }}>
                <RadioBox
                  option={[
                    {
                      label: "네 학생입니다",
                      name: "type",
                      value: "STUDENT",
                      id: "student",
                    },
                    {
                      label: "아니요 학부모입니다",
                      name: "type",
                      value: "PARENTS",
                      id: "parent",
                    },
                  ]}
                  showLine={2}
                  onChange={(value) => {
                    setUserRole(value);
                  }}
                />
              </RowWrap>
            </label>
            <label>
              <InfoTitle
                style={{
                  marginBottom: 17,
                  fontSize: 16,
                  fontWeight: 700,
                  lineHeight: "23px",
                }}
              >
                회원님의 성별을 선택해주세요.
              </InfoTitle>
              <RowWrap style={{ width: 342, height: 56, marginBottom: 24 }}>
                <RadioBox
                  option={[
                    {
                      label: "남자입니다.",
                      name: "gender",
                      value: "MALE",
                      id: "MALE",
                    },
                    {
                      label: "여자입니다",
                      name: "gender",
                      value: "FEMALE",
                      id: "FEMALE",
                    },
                  ]}
                  showLine={2}
                  onChange={(value) => {
                    setGender(value);
                  }}
                />
              </RowWrap>
            </label>
            <label>
              <InfoTitle
                style={{
                  marginBottom: 17,
                  fontSize: 16,
                  fontWeight: 700,
                  lineHeight: "23px",
                }}
              >
                생년월일을 입력해주세요.
              </InfoTitle>

              <TextInput
                placeholder={"YYYYMMDD"}
                value={birthDay}
                onChange={(value) => {
                  setBirthDay(value);
                }}
                maxLength={8}
                style={{ width: 342, marginBottom: 25 }}
              />
            </label>
            <div>
              <InfoTitle
                style={{
                  marginTop: 0,
                  marginBottom: 17,
                  fontSize: 16,
                  fontWeight: 700,
                  lineHeight: "23px",
                }}
              >
                회원님의 거주지를 선택해주세요.
              </InfoTitle>

              <RowWrap style={{ marginBottom: 16 }}>
                <Select
                  type={1}
                  width={"163px"}
                  float={true}
                  placeholder={"시/도"}
                  value={province}
                  onChange={(value) => {
                    setProvince(value);
                    citySelectHandler(value);
                  }}
                  option={cities}
                />

                <Select
                  type={1}
                  width={"163px"}
                  float={true}
                  placeholder={"시/군/구"}
                  value={city}
                  option={districts}
                  onChange={(value) => {
                    setCity(value);
                    districtsSelectHandler(value);
                  }}
                />
              </RowWrap>

              <Select
                type={2}
                width={"342px"}
                float={true}
                placeholder={"읍/면/동"}
                value={dong}
                option={towns}
                onChange={(value) => {
                  setDong(value);
                }}
                marginRight={"0px"}
              />
            </div>
            <div>
              <InfoTitle
                style={{
                  marginBottom: 9,
                  fontSize: 16,
                  fontWeight: 700,
                  lineHeight: "23px",
                }}
              >
                회원님의 필요한 과목을 선택해주세요.
              </InfoTitle>
              <RegexText
                style={{ lineHeight: "20px", fontSize: 14, marginBottom: 16 }}
              >
                * 복수 선택이 가능합니다.
              </RegexText>
              <SubjectChoiceWrap>
                {selectSubject.length > 0 &&
                  selectSubject.map((item, idx) => {
                    if (item.type === "국내") {
                      return (
                        <ChoiceBox key={idx}>
                          <ChoiceText>{`${item.type}(${item.level}) - ${item.name}(${item.progress})`}</ChoiceText>
                          <CancleWrap
                            onClick={() => {
                              setSelectSubject(
                                selectSubject.filter(
                                  (selectItem) => item !== selectItem
                                )
                              );
                            }}
                          />
                        </ChoiceBox>
                      );
                    } else {
                      return (
                        <ChoiceBox key={idx}>
                          <ChoiceText>{`${item.type} - ${item.level}`}</ChoiceText>
                          <CancleWrap
                            onClick={() => {
                              setSelectSubject(
                                selectSubject.filter(
                                  (selectItem) => item !== selectItem
                                )
                              );
                            }}
                          />
                        </ChoiceBox>
                      );
                    }
                  })}
              </SubjectChoiceWrap>

              <div>
                <MobileDontKnowBtn
                  style={{
                    backgroundColor: isDontKnowBtn ? "#021633" : "",
                    color: isDontKnowBtn ? "white" : "",
                  }}
                  onClick={() => {
                    if (selectSubject.length > 0) setIsDontKnowBtn(false);
                    else setIsDontKnowBtn(true);
                    subjectTypeHandler("아직 잘 모르겠어요");
                  }}
                >
                  아직 잘 모르겠어요
                </MobileDontKnowBtn>
                <RowWrap style={{ marginBottom: 16 }}>
                  <Select
                    type={1}
                    width={"163px"}
                    float={true}
                    placeholder={"과목 형태"}
                    value={selectSubjectType}
                    onChange={(value) => {
                      console.log(value);
                      subjectTypeHandler(value);
                      setSelectSubjectLevel("");
                      setSelectSubjectMajor("");
                      setSelectSubjectType(value);
                      setIsDontKnowBtn(false);
                    }}
                    option={mobileTypes}
                  />

                  <Select
                    type={1}
                    width={"163px"}
                    float={true}
                    placeholder={"과목 레벨"}
                    value={selectSubjectLevel}
                    option={subjectLevel}
                    onChange={(value) => {
                      setSelectSubjectLevel(value);
                      if (selectSubjectType === "국내") {
                        subjectLevelHandler(value);
                      } else {
                        subject.map((item) => {
                          if (
                            item.level === value &&
                            item.type === selectSubjectType
                          ) {
                            if (
                              selectSubject.filter(
                                (subItem) => item.id === subItem.id
                              ).length < 1
                            ) {
                              setSelectSubject([...selectSubject, item]);
                            }
                          }
                        });
                      }
                    }}
                  />
                </RowWrap>
                <Select
                  type={2}
                  width={"342px"}
                  float={true}
                  placeholder={"과목명"}
                  value={selectSubjectMajor}
                  option={subjectMajors}
                  onChange={(value) => {
                    subjectMajorsHandler(value);
                    setSelectSubjectMajor(value);
                  }}
                  marginRight={"0px"}
                />
                {subjectItems.length > 0 && (
                  <>
                    <RowWrap
                      style={{
                        marginBottom: 0,
                        marginTop: "16px",
                        width: "342px",
                      }}
                    >
                      <RadioBox
                        option={subjectItems}
                        showLine={2}
                        onChange={(value) => {
                          if (value) {
                            subject.map((item) => {
                              if (item.progress === value) {
                                if (
                                  selectSubject.filter(
                                    (subItem) => item.id === subItem.id
                                  ).length < 1
                                ) {
                                  setSelectSubject([...selectSubject, item]);
                                }
                              }
                            });
                          }
                        }}
                      />
                    </RowWrap>
                  </>
                )}
              </div>
            </div>
            <div style={{ position: "relative", display: "block" }}>
              <div
                style={{
                  opacity: "0.08",
                  width: "342px",
                  pointerEvents: "none",
                }}
              >
                <InfoTitle
                  style={{
                    width: "342px",
                    marginBottom: 8,
                    fontSize: 16,
                    fontWeight: 700,
                    lineHeight: "23px",
                  }}
                >
                  회원 유형을 선택해주세요.
                </InfoTitle>
                <RegexText
                  style={{
                    width: "342px",
                    lineHeight: "20px",
                    fontSize: 14,
                    marginBottom: 16,
                  }}
                >
                  * 유료회원의 경우 중도해지 시 환불은 불가합니다. 자세한
                  <br /> 내용은 이용약관을 확인해주세요.
                </RegexText>
                <MobileSelectMemberType>
                  <li>
                    <div>
                      <input disabled={true} type={"checkbox"}></input>
                      <div>
                        <p>무료 회원</p>
                        <h3>0원</h3>
                      </div>
                    </div>
                    <ul>
                      <li>
                        <figure></figure>
                        <p>소개 페이지 보기</p>
                      </li>
                      <li>
                        <figure></figure>
                        <p>
                          강사 리스트 및 상<br />
                          세내역 확인
                        </p>
                      </li>
                      <li>
                        <figure style={{ background: "#FFEBEB" }}></figure>
                        <p>
                          OT신청 / 수업
                          <br />
                          예약
                        </p>
                      </li>
                      <li>
                        <figure style={{ background: "#FFEBEB" }}></figure>
                        <p>
                          자료실 이용
                          <br />
                          질의응답 페이지
                        </p>
                      </li>
                      <li>
                        <figure style={{ background: "#FFEBEB" }}></figure>
                        <p>
                          상세 내용 및 댓글
                          <br />
                          확인
                        </p>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <div>
                      <input type={"checkbox"}></input>
                      <div>
                        <p>유료 회원</p>
                        <h3>연 11만원</h3>
                      </div>
                    </div>
                    <ul>
                      <li>
                        <figure></figure>
                        <p>소개 페이지 보기</p>
                      </li>
                      <li>
                        <figure></figure>
                        <p>
                          강사 리스트 및 상<br />
                          세내역 확인
                        </p>
                      </li>
                      <li>
                        <figure></figure>
                        <p>
                          OT신청 / 수업
                          <br />
                          예약
                        </p>
                      </li>
                      <li>
                        <figure></figure>
                        <p>
                          자료실 이용
                          <br />
                          질의응답 페이지
                        </p>
                      </li>
                      <li>
                        <figure></figure>
                        <p>
                          상세 내용 및 댓글
                          <br />
                          확인
                        </p>
                      </li>
                    </ul>
                  </li>
                </MobileSelectMemberType>
              </div>
              <MobileTypeNotice>
                <h2>이벤트 진행중</h2>
                <p>
                  6개월 간 무료{" "}
                  <span style={{ color: "black" }}>로 모든 서비스</span> 이용
                  가능
                </p>
              </MobileTypeNotice>
            </div>

            <CheckBoxWrap
              style={{ marginBottom: 48, marginTop: 24, width: "342px" }}
            >
              <CheckBox
                all
                label={"전체 동의"}
                value={allAgreed}
                onChange={(value) => {
                  agreedAll();
                }}
              />
              <CheckBoxBorder />
              {checkedTerms.map((item, idx) => (
                <CheckBox
                  showDetailOnPress={() => {
                    window.open(`${item.url}`, "_blank");
                  }}
                  key={idx}
                  label={item.title}
                  mandatory={item.required}
                  value={item.value}
                  onChange={(value) => {
                    setCheckedTerms(
                      checkedTerms.map((term) => {
                        if (item.id === term.id) {
                          return {
                            ...term,
                            value: value,
                          };
                        }
                        return term;
                      })
                    );
                  }}
                />
              ))}
            </CheckBoxWrap>
            <ButtonWrap style={{ widows: "342px", padding: "0px 0px" }}>
              {!joinBtn && (
                <ValidationWarning>
                  필수 입력 정보 및 중복확인/인증 여부를 확인해주세요.
                </ValidationWarning>
              )}
              <Button
                disabled={!joinBtn}
                onClick={() => {
                  if (!joinBtn) {
                    if (!joinNickName) {
                      alert("닉네임 또는 실명이 입력되지 않았습니다!");
                      return;
                    }

                    if (!authComplete) {
                      alert("휴대폰 인증이 완료되지 않았습니다!");
                      return;
                    }

                    if (userRole === "") {
                      alert("학생 여부가 선택되지 않았습니다!");
                      return;
                    }

                    if (gender === "") {
                      alert("성별이 선택되지 않았습니다!");
                      return;
                    }

                    if (birthDay.length !== 8) {
                      alert("생년월일이 입력되지 않았습니다!");
                      return;
                    }

                    if (subjectId.length <= 0) {
                      alert("과목이 선택되지 않았습니다!");
                      return;
                    }
                  } else {
                    if (memeberType === "유료") {
                      runBootpaySubscription(
                        "영재멘토 유료회원",
                        11000,
                        nickName,
                        phoneNumber,
                        "",
                        () => {},
                        (billingKey) => {
                          signUp(billingKey);
                        }
                      );
                    } else {
                      signUp();
                    }
                  }
                }}
              >
                가입하기
              </Button>
            </ButtonWrap>
          </MobileSignUpContainer>
        </MobileContainer>
        {modal && (
          <MobileModal
            title={"회원가입이 완료되었습니다."}
            content={"영재멘토의 다양한 서비스를 이용해보세요."}
            button={[
              {
                title: "로그인하기",
                type: "active",
              },
            ]}
            onPress={() => {
              navigate("/login");
            }}
          />
        )}
        {authModal && (
          <MobileModal
            padding
            title={"입력시간 초과"}
            content={`인증번호 입력시간이 초과되었습니다.
            다시 입력해주세요.`}
            button={[
              {
                title: "재전송",
                type: "active",
              },
            ]}
            onPress={() => {
              setAuthModal(false);
              start();
            }}
          />
        )}
        {authErrorModal && (
          <MobileModal
            style={{ padding: 0, width: "342px", height: "179px" }}
            padding
            title={"인증 실패"}
            content={`입력한 인증번호가 일치하지 않습니다.
            다시 입력해주세요.`}
            button={[
              {
                title: "확인",
                type: "active",
              },
            ]}
            onPress={() => {
              setAuthErrorModal(false);
            }}
          />
        )}
        {authCompleteModal && (
          <MobileModal
            padding
            title={"인증완료"}
            content={"인증이완료되었습니다"}
            button={[
              {
                title: "확인",
                type: "active",
              },
            ]}
            onPress={() => {
              setAuthCompleteModal(false);
            }}
          />
        )}
        {isDuplicateNumber && (
          <MobileModal
            padding
            title={"오류"}
            content={"이미 가입된 휴대전화번호입니다."}
            button={[
              {
                title: "확인",
                type: "active",
              },
            ]}
            onPress={() => {
              setIsDuplicateNumber(false);
            }}
          />
        )}
        {wrongPhoneNumber && (
          <MobileModal
            padding
            title={"오류"}
            content={"잘못된 휴대전화번호입니다."}
            button={[
              {
                title: "확인",
                type: "active",
              },
            ]}
            onPress={() => {
              setWrongPhoneNumber(false);
            }}
          />
        )}
      </Mobile>
    </>
  );
};

export default StudentSignUp;
