import React, { useEffect, useState } from "react";
import styled from "styled-components";
const InputContainer = styled.div`
  width: ${(props) => (props.width ? props.width : "50%")};
  height: 60px;
  border-radius: 8px;
  border: 1px solid #021633;
  position: relative;
  overflow: hidden;
  padding: 18px 0px;
  float: left;
  margin-right: ${(props) => (props.last ? "0px" : "16px")};
  margin-bottom: 20px;
`;
const Input = styled.input`
  visibility: hidden;
  appearance: auto;
`;
const Label = styled.label`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => (props.active ? "#021633" : "#FFFFFF")};
  transition: 0.5s;
  cursor: pointer;
`;
const LabelText = styled.div`
  color: ${(props) => (props.active ? "#FFFFFF" : "#021633")};
  font-size: 16px;
  font-weight: 500;
  line-height: 32px;
  transition: 0.5s;
`;
/*
  <RadioBox
    option={[
      {
        label: "네 학생입니다",
        name: "type",
        value: "student",
        id: "student",
      },
      {
        label: "아니요 학부모입니다",
        name: "type",
        value: "parent",
        id: "parent",
      },
    ]}
    showLine={4}
    onChange={(value) => {
      console.log(value);
    }}
  />
*/
const RadioBox = ({ id, option, showLine, onChange, disabled, value: initialValue }) => {
  const [value, setValue] = useState("");
  
  const radioHandle = (e) => {
    setValue(e.target.value);
    onChange(e.target.value);
  };

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    if (id) {
      setValue(initialValue);
    }
  }, [id, initialValue]);

  return (
    <div id={id} style={{ display: "inline-block", width: "100%" }}>
      {option &&
        option.map((item, idx) => (
          <InputContainer
            key={idx}
            last={(idx + 1) % showLine === 0}
            width={`calc(${100 / showLine}% - ${
              showLine - 1
            }*16px/${showLine})`}
          >
            <Input
              type="radio"
              name={item.name}
              value={item.value}
              id={item.value}
              onChange={radioHandle}
              checked={value === item.value}
              disabled={item.label === "아직 잘 모르겠어요" && disabled}
            />
            <Label htmlFor={item.value} active={value === item.value}>
              <LabelText active={value === item.value}>{item.label}</LabelText>
            </Label>
          </InputContainer>
        ))}
    </div>
  );
};
export default RadioBox;