import React, {useEffect, useState} from "react";
import styled from "styled-components";
import "swiper/css";
import Header from "./components/header";
import "./components/style.scss"
import {Desktop, Mobile} from "../../utils/mediaQuery";

import YoutubeEmbed from "./components/YoutubeVideo";
import mainLogo from "./images/mainLogo.png";
import mainTitleLogo from "./images/mainTitleLogo.png";
import desktopImage1 from "./images/desktop1.png";
import desktopImage2 from "./images/desktop2.png";
import desktopImage3 from "./images/desktop3.png";
import desktopImage4 from "./images/desktop4.png";
import desktopImage5 from "./images/desktop5.png";
import desktopImage6 from "./images/desktop6.png";
import mobileImage1 from "./images/mobile1.png";
import host1 from "./images/partners/host1.png";
import host2 from "./images/partners/host2.png";
import partner1 from "./images/partners/partner1.png";
import partner2 from "./images/partners/partner2.png";
import partner3 from "./images/partners/partner3.png";
import partner4 from "./images/partners/partner4.png";
import partner5 from "./images/partners/partner5.png";
import partner6 from "./images/partners/partner6.png";
import partner7 from "./images/partners/partner7.png";
import partner8 from "./images/partners/partner8.png";
import partner9 from "./images/partners/partner9.png";
import partner10 from "./images/partners/partner10.png";
import partner11 from "./images/partners/partner11.png";
import Footer from "../../components/Footer";
import MobileFooter from "./MobileFooter";

const Container = styled.div`
  margin: 0 0 0 0;
  padding: 0;
  width: 100%;
  //min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
`;

const ImageContainer = styled.div`
  margin: 0 0 0 0;
  padding: 0;
  width: 100%;
  min-height: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

const ImageDesktop = styled.img`
  width: 100%;
  height: auto;
  object-fit: contain;
`;

const YoutubeContainer = styled.div`
  margin: 0 0 0 0;
  padding: 0;
  width: 100%;
  height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: #021c41;
`;

const YoutubeContainerMobile = styled.div`
  margin: 0 0 0 0;
  padding: 0;
  width: 100%;
  height: 28vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: #021c41;
`;

const ApplicationContainer = styled.div`
  margin: 0 0 0 0;
  padding: 0;
  width: 100%;
  height: 180vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: #05d9b1;
  color: #FFFFFF;
`;

const ApplicationContainerMobile = styled.div`
  margin: 0 0 0 0;
  padding: 0;
  width: 100%;
  height: 46vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: #05d9b1;
  color: #FFFFFF;
`;

const ApplicationBoxContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  font-size: 40px;
  font-family: 'Roboto', sans-serif;
  font-weight: 900;
  margin-top: 0vh;
`;

const ApplicationBoxContainerMobile = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  font-size: 12px;
  font-family: 'Roboto', sans-serif;
  font-weight: 900;
  margin-top: 6vh;
`;

const ApplicationBox = styled.div`
  height: 40vh;
  margin-top: 36vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  //padding: 4vw;
`;

const ApplicationBoxMobile = styled.div`
  height: 20vh;
  //margin-top: 10vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  //padding: 4vw;
`;

const ApplicationCard = styled.div`
  //margin-top: 4vh;
  border-radius: 30px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 500px;
  height: 360px;
`;

const ApplicationCardMobile = styled.div`
  //margin-top: 4vh;
  border-radius: 16px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 36vw;
  height: 16vh;
`;

const ApplicationText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: flex-start;
  width: 500px;
  height: 400px;
  color: #000000;
  padding: 60px;
`;

const ApplicationTextMobile = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: flex-start;
  //width: 500px;
  //height: 400px;
  color: #000000;
  padding: 1.2rem;
`;

const ApplicationDownloadButton = styled.button`
  border-radius: 16px;
  background-color: #000000;
  color: #FFFFFF;
  width: 300px;
  height: 60px;
  border: 0;
  bottom: 20px;
  margin: auto;
  font-family: "Noto Sans KR", sans-serif;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0.1rem;

  &:hover {
    background-color: #FFAD2E;
    color: #021633;
  }
`;

const ApplicationDownloadButtonMobile = styled.button`
  border-radius: 8px;
  background-color: #000000;
  color: #FFFFFF;
  width: 30vw;
  height: 3.6vh;
  //bottom: 20px;
  margin: auto;
  font-family: "Noto Sans KR", sans-serif;
  font-size: 10px;
  font-weight: 700;
  letter-spacing: 0.1rem;

  &:hover {
    background-color: #FFAD2E;
    color: #021633;
  }
`;

const PartnersContainer = styled.div`
  margin: 0 0 0 0;
  padding: 0;
  width: 100%;
  height: 170vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: #021c41;
  color: #FFFFFF;
`;

const PartnersContainerMobile = styled.div`
  margin: 0 0 0 0;
  padding: 0;
  width: 100%;
  height: 210vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: #021c41;
  color: #FFFFFF;
`;

const HostContainer = styled.div`
  width: 100%;
  margin-top: 14vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  font-size: 40px;
  font-family: 'Roboto', sans-serif;
  font-weight: 900;
`;

const HostContainerMobile = styled.div`
  width: 100%;
  height: 75vh;
  margin-top: 4vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 30px;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
`;

const HostBox = styled.div`
  width: 100%;
  height: 40vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  //padding: 4vw;
`;

const HostBoxMobile = styled.div`
  width: 100%;
  height: 40vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  //padding: 4vw;
`;

const HostCard = styled.div`
  margin-top: 4vh;
  border-radius: 16px;
  border: 2px solid #05d9b1;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 240px;
  height: 200px;
`;

const HostCardMobile = styled.div`
  margin-top: 4vh;
  border-radius: 16px;
  border: 2px solid #05d9b1;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 240px;
  height: 200px;
`;

const PartnersImageContainer = styled.div`
  margin-top: 4vh;
  padding: 20px 120px 20px 120px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  background-color: #021c41;
  color: #FFFFFF;
`;

const PartnersImageContainerMobile = styled.div`
  //margin-top: 2vh;
  padding-left: 1rem;
  padding-right: 1rem;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-around;
  background-color: #021c41;
  color: #FFFFFF;
`;

const PartnerCard = styled.div`
  border-radius: 16px;
  border: 2px solid #05d9b1;
  margin: 24px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 240px;
  height: 200px;
`;

const PartnerCardMobile = styled.div`
  border-radius: 16px;
  border: 2px solid #05d9b1;
  margin: 1rem;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 34vw;
  height: 12vh;
`;

const PartnerImage = styled.img`
  width: 75%;
  height: 100%;
  align-items: center;
  object-fit: contain;
`;

const PartnerImageMobile = styled.img`
  width: 75%;
  height: 100%;
  align-items: center;
  object-fit: contain;
`;

const ApplyButton = styled.button`
  position: fixed;
  top: 85vh;
  border-radius: 60px;
  background-color: #05d9b1;
  width: 300px;
  height: 60px;
  border: 0;
  font-family: "Noto Sans KR", sans-serif;
  font-size: 20px;
  font-weight: 700;
  color: #000000;
  letter-spacing: 0.1rem;

  &:hover {
    background-color: #FFAD2E;
    color: #021633;
  }
`;

const ApplyButtonMobile = styled.button`
  position: fixed;
  top: 88vh;
  border-radius: 60px;
  background-color: #05d9b1;
  width: 200px;
  height: 50px;
  border: 0;
  font-family: "Noto Sans KR", sans-serif;
  font-size: 20px;
  font-weight: 700;
  color: #000000;
  letter-spacing: 0.1rem;

  //&:hover {
  //  background-color: #FFAD2E;
  //  color: #021633;
  //}
`;

const DreamUpChallenge = () => {
  const partnerImages = [partner1, partner2, partner3, partner4, partner5, partner6, partner7, partner8, partner9, partner10, partner11]
  const [imgLoaded, setImgLoaded] = useState(false);

  useEffect(() => {
    setImgLoaded(true);
  }, []);
  const image = new Image();

  const title = 'Kesia - Dream Up Challenge'
  useEffect(() => {
    document.title = title;
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.getElementsByTagName('head')[0].appendChild(link);
    }
    link.href = mainTitleLogo;
  }, [title]);

  useEffect(() => {
    image.src = desktopImage1;
    image.id = "desktop_image1";
    image.alt = "image";

    image.onload = () => {
      setImgLoaded(false);
      console.log(imgLoaded)
    };
  }, []);

  return (
    <>
      <Desktop>
        {imgLoaded && (
          <div className="loader-container">
            <ImageDesktop
              style={{
                width: "12rem",
                height: "auto",
                objectFit: "contain",
              }}
              src={mainLogo}
              id="desktop_image1"
              className="spinner"
              alt="image"
            />
          </div>
        )}
        <Container id="desktop_image0">
          <Header/>
          <ImageContainer>
            <ImageDesktop
              style={{
                width: "100%",
                height: "auto",
                objectFit: "contain",
              }}
              src={desktopImage1}
              id="desktop_image1"
              alt="image"
            />
            <YoutubeContainer>
              <YoutubeEmbed/>
            </YoutubeContainer>
            <ImageDesktop
              style={{
                width: "100%",
                height: "auto",
                objectFit: "contain",
              }}
              src={desktopImage2}
              id="desktop_image2"
              alt="image"
            />
            <ImageDesktop
              style={{
                width: "100%",
                height: "auto",
                objectFit: "contain",
              }}
              src={desktopImage3}
              id="desktop_image3"
              alt="image"
            />
            <ImageDesktop
              style={{
                width: "100%",
                height: "auto",
                objectFit: "contain",
              }}
              src={desktopImage4}
              id="desktop_image4"
              alt="image"
            />
            <ImageDesktop
              style={{
                width: "100%",
                height: "auto",
                objectFit: "contain",
              }}
              src={desktopImage5}
              id="desktop_image5"
              alt="image"
            />
            <ApplicationContainer
              style={{
                backgroundImage: `url(${desktopImage6})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center center",
              }}>
              <ApplicationBoxContainer>
                <ApplicationBox style={{paddingLeft: "12vw"}}>
                  <ApplicationCard>
                    <ApplicationText>
                      <p>공고문 다운로드</p>
                      <p style={{paddingTop: "40px", fontSize: "22px", color: "#5B5B5B"}}>아래의 양식을 다운로드하고 작성하여
                        ~으로 제출해주세요</p><br/>
                      <ApplicationDownloadButton>공고문 다운로드</ApplicationDownloadButton>
                    </ApplicationText>
                  </ApplicationCard>
                </ApplicationBox>
                <ApplicationBox style={{paddingRight: "12vw"}}>
                  <ApplicationCard style={{backgroundColor: "#D9D9D9"}}>
                    <ApplicationText>
                      <p>문의처</p>
                      <p style={{paddingTop: "40px", fontSize: "22px", color: "#000B1C"}}>경진대회 운영처</p>
                      <p style={{paddingTop: "20px", fontSize: "22px", color: "#5B5B5B"}}>Email:</p>
                      <p style={{paddingTop: "8px", fontSize: "22px", color: "#000B1C"}}>contact@giftedmentor.com</p>
                      <p style={{paddingTop: "20px", fontSize: "22px", color: "#5B5B5B"}}>Tel:</p>
                      <p style={{paddingTop: "8px", fontSize: "22px", color: "#000B1C"}}>010-4374-7934</p>
                    </ApplicationText>
                  </ApplicationCard>
                </ApplicationBox>
              </ApplicationBoxContainer>
              {/*<ApplicationInnerContainer>*/}
              {/*  */}
              {/*</ApplicationInnerContainer>*/}
            </ApplicationContainer>
          </ImageContainer>
          <PartnersContainer
            id="desktop_image6"
          >
            <HostContainer>
              <HostBox style={{paddingLeft: "16vw"}}>
                <p>주관사</p>
                <HostCard>
                  <PartnerImage
                    src={host2}
                    alt="image"
                  />
                </HostCard>
              </HostBox>
              <HostBox style={{paddingRight: "16vw"}}>
                <p>주최사</p>
                <HostCard>
                  <PartnerImage
                    src={host1}
                    alt="image"
                  />
                </HostCard>
              </HostBox>
            </HostContainer>
            <p style={{
              textAlign: 'center',
              color: '#05D9B1',
              fontSize: 20,
              fontFamily: 'Open Sans',
              fontWeight: '400',
              wordWrap: 'break-word'
            }}>Co-operator</p>
            <p style={{
              textAlign: 'center',
              color: 'white',
              fontSize: 40,
              fontFamily: 'Roboto',
              fontWeight: '500',
              wordWrap: 'break-word',
              marginTop: '8px'
            }}>PARTNERSHIP</p>
            <PartnersImageContainer>
              {partnerImages.map(image =>
                <PartnerCard>
                  <PartnerImage
                    src={image}
                    alt="image"
                  />
                </PartnerCard>
              )}
            </PartnersImageContainer>
          </PartnersContainer>
          <ApplyButton>신청하기</ApplyButton>
        </Container>
      </Desktop>
      <Mobile>
        {imgLoaded && (
          <div className="loader-container">
            <ImageDesktop
              style={{
                width: "12rem",
                height: "auto",
                objectFit: "contain",
              }}
              src={mainLogo}
              id="desktop_image1"
              className="spinner"
              alt="image"
            />
          </div>
        )}
        <Container id="desktop_image0">
          <Header/>
          <ImageContainer>
            <ImageDesktop
              style={{
                width: "100%",
                height: "auto",
                objectFit: "contain",
              }}
              src={desktopImage1}
              id="desktop_image1"
              alt="image"
            />
            <YoutubeContainerMobile>
              <YoutubeEmbed/>
            </YoutubeContainerMobile>
            <ImageDesktop
              style={{
                width: "100%",
                height: "auto",
                objectFit: "contain",
              }}
              src={desktopImage2}
              id="desktop_image2"
              alt="image"
            />
            <ImageDesktop
              style={{
                width: "100%",
                height: "auto",
                objectFit: "contain",
              }}
              src={desktopImage3}
              id="desktop_image3"
              alt="image"
            />
            <ImageDesktop
              style={{
                width: "100%",
                height: "auto",
                objectFit: "contain",
              }}
              src={desktopImage4}
              id="desktop_image4"
              alt="image"
            />
            <ImageDesktop
              style={{
                width: "100%",
                height: "auto",
                objectFit: "contain",
              }}
              src={desktopImage5}
              id="desktop_image5"
              alt="image"
            />
            <ApplicationContainerMobile
              style={{
                backgroundImage: `url(${desktopImage6})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center center",
              }}>
              <ApplicationBoxContainerMobile>
                <ApplicationBoxMobile style={{paddingLeft: "6vw"}}>
                  <ApplicationCardMobile>
                    <ApplicationTextMobile>
                      <p>공고문 다운로드</p>
                      <p style={{paddingTop: "1rem", paddingBottom: "0.6rem", fontSize: "10px", color: "#5B5B5B"}}>아래의
                        양식을 다운로드하고 작성하여
                        ~으로 제출해주세요</p><br/>
                      <ApplicationDownloadButtonMobile>다운로드</ApplicationDownloadButtonMobile>
                    </ApplicationTextMobile>
                  </ApplicationCardMobile>
                </ApplicationBoxMobile>
                <ApplicationBoxMobile style={{paddingRight: "6vw"}}>
                  <ApplicationCardMobile style={{backgroundColor: "#D9D9D9"}}>
                    <ApplicationTextMobile>
                      <p>문의처</p>
                      <p style={{paddingTop: "0.4rem", fontSize: "10px", color: "#000B1C"}}>경진대회 운영처</p>
                      <p style={{paddingTop: "1rem", fontSize: "10px", color: "#5B5B5B"}}>Email:</p>
                      <p style={{paddingTop: "0.1rem", fontSize: "10px", color: "#000B1C"}}>contact@giftedmentor.com</p>
                      <p style={{paddingTop: "0.4rem", fontSize: "10px", color: "#5B5B5B"}}>Tel:</p>
                      <p style={{paddingTop: "0.1rem", fontSize: "10px", color: "#000B1C"}}>010-4374-7934</p>
                    </ApplicationTextMobile>
                  </ApplicationCardMobile>
                </ApplicationBoxMobile>
              </ApplicationBoxContainerMobile>
            </ApplicationContainerMobile>
          </ImageContainer>
          <PartnersContainerMobile
            id="desktop_image6"
          >
            <HostContainerMobile>
              <HostBoxMobile>
                <p>주관사</p>
                <HostCardMobile>
                  <PartnerImageMobile
                    src={host2}
                    alt="image"
                  />
                </HostCardMobile>
              </HostBoxMobile>
              <HostBoxMobile>
                <p>주최사</p>
                <HostCardMobile>
                  <PartnerImageMobile
                    src={host1}
                    alt="image"
                  />
                </HostCardMobile>
              </HostBoxMobile>
            </HostContainerMobile>
            <p style={{
              textAlign: 'center',
              color: '#05D9B1',
              fontSize: 20,
              fontFamily: 'Open Sans',
              fontWeight: '400',
              wordWrap: 'break-word',
              marginTop: "3rem"
            }}>Co-operator</p>
            <p style={{
              textAlign: 'center',
              color: 'white',
              fontSize: 30,
              fontFamily: 'Roboto',
              fontWeight: '500',
              wordWrap: 'break-word',
              marginTop: '8px',
              marginBottom: '30px'
            }}>PARTNERSHIP</p>
            <PartnersImageContainerMobile>
              {partnerImages.map(image =>
                <PartnerCardMobile>
                  <PartnerImageMobile
                    src={image}
                    alt="image"
                  />
                </PartnerCardMobile>
              )}
            </PartnersImageContainerMobile>
          </PartnersContainerMobile>
          <ApplyButtonMobile>신청하기</ApplyButtonMobile>
        </Container>
        {/*<MobileFooter/>*/}
      </Mobile>
    </>
  );
};

export default DreamUpChallenge;
