import React, { useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";

import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import Button from "../../../components/Button";
import Modal from "../../../components/Modal";
import tokenState from "../../../states/tokenState";

const Container = styled.div`
  width: 536px;
  padding: 240px 0 260px;
  margin: 0 auto;
`;
const Contents = styled.div`
  width: 536px;
  height: 510px;
  background-color: #fffaf1;
  border-radius: 12px;
  padding: 48px;
`;
const Title = styled.span`
  display: inline-block;
  font-size: 32px;
  font-weight: ${(props) =>
    props.bold ? props.theme.fontWeight.bold : props.theme.fontWeight.medium};
  color: ${(props) =>
    props.bold ? props.theme.color.orange : props.theme.color.textColor};
  line-height: 44px;
  margin-bottom: 31px;
`;
const DescWrap = styled.div`
  margin-bottom: 80px;
`;
const Badge = styled.div`
  width: 80px;
  height: 32px;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  background-color: ${(props) => props.theme.color.primary};
  margin-bottom: 20px;
`;
const Desc = styled.span`
  font-size: 20px;
  font-weight: ${(props) => props.theme.fontWeight.medium};
  color: ${(props) =>
    props.orange ? props.theme.color.orange : props.theme.color.textColor};
  line-height: 32px;
`;

const CareerSearchGuide = () => {
  const navigate = useNavigate();
  const [loginModal, setLoginModal] = useState(false);
  const [paymentModal, setPaymentModal] = useState(false);
  const token = useRecoilValue(tokenState);

  return (
    <>
      <Header />
      <Container>
        <Title>
          진로탐색 전<br />
          <Title bold>안내사항</Title>을 확인해주세요!
        </Title>
        <Contents>
          <DescWrap>
            <Badge>첫번째</Badge>
            <Desc>
              무료 회원의 경우 진로탐색 시,<br /><Desc orange>10,000원</Desc>의 금액이 청구됩니다.
            </Desc>
          </DescWrap>
          <DescWrap>
            <Badge>두번째</Badge>
            <Desc>
              간단한 검사 후 <Desc orange>세부탐색</Desc>은 이후 채팅으로
              <br />
              상담사가 연락할 예정입니다.
            </Desc>
          </DescWrap>
          <Button
            active
            onClick={() => {
              if (token !== null) {
                navigate("/careerSearch");
              } else {
                setLoginModal(true);
              }
            }}
          >
            다음
          </Button>
        </Contents>
      </Container>
      <Footer />
      {loginModal ? (
        <Modal
          title={"로그인 안내"}
          content={"진로탐색은 로그인 상태에서 가능합니다."}
          onClose={() => {
            setLoginModal(false);
          }}
          onPress={() => {
            setLoginModal(false);
            navigate("/login");
          }}
          button={[
            {
              title: "닫기",
              type: "cancel",
            },
            {
              title: "로그인하기",
              type: "active",
            },
          ]}
        />
      ) : null}
      {paymentModal ? (
        <Modal
          title={"결제 완료"}
          content={"결제가 완료되었습니다."}
          onPress={() => {
            setPaymentModal(false);
            navigate("/careerSearch");
          }}
          button={[
            {
              title: "완료",
              type: "active",
            },
          ]}
        />
      ) : null}
    </>
  );
};

export default CareerSearchGuide;
