import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import { useRecoilState, useRecoilValue } from "recoil";
import { Desktop, Mobile } from "../../../utils/mediaQuery";
import { API_URL } from "../../../API";
import Pagination from "../../../components/Pagination";
import Modal from "../../../components/Modal";
import tokenState from "../../../states/tokenState";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode } from "swiper";
import { Pagination as Pag } from "swiper";
import "swiper/css";
import {
  ProfileBlock,
  ProfileWrap,
  ProfileImageWrap,
  ProfileImage,
  ProfileBadgeWrap,
  ProfileBadge,
  ProfileText,
  ProfileName,
  EmptyDataText,
  PaginationWrap,
} from "../style";
import {
  MentoInfo,
  MobileMentoCard,
  MobileMetoPickContainer,
  RecordsList,
} from "../mobile.style";
import MobileModal from "../../../components/MobileModal";
import { InView } from "react-intersection-observer";
import { mentoListState, mentoListType } from "../../../states/mentoPickState";

const MentoList = (props) => {
  const [tabName, setTabName] = useState(0);
  const searchResult = props.searchResult;
  const [teacher, setTeacher] = useState([]);
  const [mobileTeachers, setMobileTeachers] = useRecoilState(mentoListState);
  const [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useState(
    searchParams.get("page") ? parseInt(searchParams.get("page"), 10) : 1
  );
  const [search, setSearch] = useState(searchParams.get("query") || "");
  const [totalPage, setTotalPage] = useState("");
  const [loginModal, setLoginModal] = useState(false);
  const token = useRecoilValue(tokenState);
  const navigate = useNavigate();

  useEffect(() => {
    setSearch(searchResult);
  }, [searchResult]);

  useEffect(() => {
    // if (window.innerWidth < 1099) {
    //   setPage(1);
    // }
    setPage(1);
    setSearch("");
    setTabName(props.tabName);
  }, [props.tabName]);

  const getMentoPick = async () => {
    axios({
      url: `${API_URL}/mentopick?page=${page}&type=${tabName}&query=${search}`,
      method: "GET",
    })
      .then(async (result) => {
        if (result.data.success) {
          console.log(result.data.result.mentopicks);
          //Sorting and filtering duplicates of subjects
          const temp = await result.data?.result.mentopicks.map((item) => {
            let arr = item?.subjects?.replaceAll(" ", "").split(",");
            return {
              ...item,
              subjects: [...new Set(arr)]
                .sort(function (a, b) {
                  // 한글 오름차순
                  return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
                })
                .join(", "),
            };
          });
          if (page === 1) {
            setMobileTeachers(temp);
          } else {
            setMobileTeachers([...mobileTeachers, ...temp]);
          }
          setTeacher(temp);
          setTotalPage(result.data.result.totalPage);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getPage = (page) => {
    setPage(page);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    getMentoPick();
  }, [tabName, search, page]);

  useEffect(() => {
    const params = new URLSearchParams();

    params.set("type", tabName);
    params.set("page", page);
    params.set("query", search);

    setSearchParams(params);
  }, [tabName, search, page]);

  return (
    <>
      <Desktop>
        <ProfileWrap>
          {teacher.length > 0 ? (
            <>
              {teacher.map((arr) => {
                return (
                  <ProfileBlock
                    key={arr.id}
                    onClick={() => {
                      if (token !== null) {
                        navigate(`profile/${arr.id}`);
                        setLoginModal(false);
                      } else {
                        setLoginModal(true);
                      }
                    }}
                  >
                    {!arr.types ? (
                      <div
                        style={{
                          position: "absolute",
                          top: 0,
                          right: 0,
                          background: "#021633",
                          borderBottomLeftRadius: 8,
                          padding: "8px 16px",
                          color: "#ffffff",
                        }}
                      >
                        멘토
                      </div>
                    ) : (
                      <>
                        {arr.types.includes("전문강사관") && (
                          <div
                            style={{
                              position: "absolute",
                              top: 0,
                              right: 0,
                              background: "#021633",
                              borderBottomLeftRadius: 8,
                              padding: "8px 16px",
                              color: "#ffffff",
                            }}
                          >
                            전문강사
                          </div>
                        )}
                        {!arr.types.includes("전문강사관") &&
                          arr.types.includes("인생멘토") && (
                            <div
                              style={{
                                position: "absolute",
                                top: 0,
                                right: 0,
                                background: "#021633",
                                borderBottomLeftRadius: 8,
                                padding: "8px 16px",
                                color: "#ffffff",
                              }}
                            >
                              인생멘토
                            </div>
                          )}
                        {!arr.types.includes("인생멘토") &&
                          !arr.types.includes("전문강사관") && (
                            <div
                              style={{
                                position: "absolute",
                                top: 0,
                                right: 0,
                                background: "#021633",
                                borderBottomLeftRadius: 8,
                                padding: "8px 16px",
                                color: "#ffffff",
                              }}
                            >
                              멘토
                            </div>
                          )}
                      </>
                    )}
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: 20,
                        marginBottom: 20,
                      }}
                    >
                      <ProfileImageWrap>
                        <ProfileImage
                          src={
                            arr.profile
                              ? arr.profile
                              : "/images/default_profile.png"
                          }
                        />
                      </ProfileImageWrap>
                      <ProfileName>{arr.name}</ProfileName>
                    </div>
                    {arr.subjects !== undefined &&
                    !arr.types.includes("인생멘토") ? (
                      <ProfileBadgeWrap>
                        <ProfileBadge>과목</ProfileBadge>
                        <ProfileText>{arr.subjects}</ProfileText>
                      </ProfileBadgeWrap>
                    ) : null}
                    {!arr.types.includes("전문강사관") && (
                      <ProfileBadgeWrap>
                        <ProfileBadge>학력</ProfileBadge>
                        <ProfileText>{arr.education}</ProfileText>
                      </ProfileBadgeWrap>
                    )}
                    <ProfileBadgeWrap marginBottom>
                      <ProfileBadge>이력</ProfileBadge>
                      <ProfileText
                        multiline={
                          arr.types.includes("인생멘토") ||
                          arr.types.includes("전문강사관")
                        }
                      >
                        {arr.career}
                      </ProfileText>
                    </ProfileBadgeWrap>
                  </ProfileBlock>
                );
              })}
            </>
          ) : (
            <EmptyDataText>찾으시는 정보가 없습니다.</EmptyDataText>
          )}
        </ProfileWrap>
        {teacher.length > 0 ? (
          <PaginationWrap>
            <Pagination
              tabName={tabName}
              totalItemsCount={teacher !== null ? totalPage * 15 : 0}
              showItemsCount={15}
              nowPage={getPage}
              page={page}
            />
          </PaginationWrap>
        ) : null}
        {loginModal ? (
          <Modal
            title={"로그인 안내"}
            content={"상세페이지 확인은 로그인 상태에서 가능합니다."}
            onClose={() => {
              setLoginModal(false);
            }}
            onPress={() => {
              setLoginModal(false);
              navigate("/login");
            }}
            button={[
              {
                title: "닫기",
                type: "cancel",
              },
              {
                title: "로그인하기",
                type: "active",
              },
            ]}
          />
        ) : null}
      </Desktop>
      <Mobile>
        <MobileMetoPickContainer>
          <ul>
            {mobileTeachers.length > 0 &&
              mobileTeachers?.map((el, id) => (
                <InView
                  triggerOnce
                  threshold={0.1}
                  onChange={(inview, entry) => {
                    if (inview && id === mobileTeachers.length - 5) {
                      if (page !== totalPage) setPage((prev) => prev + 1);
                    }
                  }}
                  as={"li"}
                >
                  <MobileMentoCard>
                    <button
                      onClick={() => {
                        if (token !== null) {
                          navigate(`/mentoPick/profile/${el.id}`);
                          setLoginModal(false);
                        } else {
                          setLoginModal(true);
                        }
                      }}
                    >
                      <img
                        src={
                          el.profile
                            ? el.profile
                            : "/images/default_profile.png"
                        }
                        alt="프로필_이미지"
                      />
                    </button>

                    <MentoInfo style={{ height: "auto" }}>
                      <h2>{el.name}</h2>

                      <Swiper
                        style={{
                          visibility: el.subjects !== "" ? "visible" : "hidden",
                        }}
                        spaceBetween={8}
                        modules={[FreeMode, Pag]}
                        slidesPerView={"auto"}
                        freeMode={true}
                      >
                        {el.subjects.split(",").map((subject) => {
                          return <SwiperSlide>{subject}</SwiperSlide>;
                        })}
                      </Swiper>

                      <RecordsList style={{ height: "auto" }}>
                        <li>
                          <div>학력</div>
                          <p style={{ flex: 1 }}>{el.education}</p>
                        </li>
                        <li>
                          <div>이력</div>
                          <p style={{ flex: 1 }}>{el.career}</p>
                        </li>
                      </RecordsList>
                    </MentoInfo>
                  </MobileMentoCard>
                </InView>
              ))}
          </ul>
        </MobileMetoPickContainer>
        {loginModal ? (
          <MobileModal
            title={"로그인 안내"}
            content={`상세페이지 확인은 
            로그인 상태에서 가능합니다.`}
            onClose={() => {
              setLoginModal(false);
            }}
            onPress={() => {
              setLoginModal(false);
              navigate("/login");
            }}
            btncnt={2}
            button={[
              {
                title: "닫기",
                type: "cancel",
              },
              {
                title: "로그인하기",
                type: "active",
              },
            ]}
          />
        ) : null}
      </Mobile>
    </>
  );
};

export default MentoList;
