import react, { useState, useEffect } from "react";
import styled from "styled-components";
import { ReactComponent as Timer } from "../../../assets/icons/timer.svg";
import Pagination from "../../../components/Pagination";
import calendar from "../../../assets/images/calendar.png";
import people from "../../../assets/images/people.png";
import Cat from "../../../assets/images/cat.jpeg";
import axios from "axios";
import { API_URL } from "../../../API";
import { Desktop, Mobile } from "../../../utils/mediaQuery";
import {
  MobileClassInfo,
  MobileConferenceBtn,
  MobileInstructorInfo,
  MobileSeminarCardItem,
  MobileSeminarDetailContainer,
  MobileSeminarDetailInfo,
  MobileSeminarTabBox,
} from "./Seminar";
import Button from "../../../components/Button";
import MobileModal from "../../../components/MobileModal";
import dayjs from "dayjs";
import tokenState from "../../../states/tokenState";
import { useRecoilValue } from "recoil";
import { useNavigate } from "react-router-dom";

const TabWrap = styled.div`
  display: flex;
  font-size: 20px;
  margin-top: 48px;
  margin-bottom: 32px;
  align-items: center;
`;
const Tab = styled.div`
  font-weight: ${(props) =>
    props.active ? props.theme.fontWeight.bold : props.theme.fontWeight.medium};
  color: ${(props) => (props.active ? props.theme.color.primary : "#ADB5BD")};
  cursor: pointer;
`;

const Divide = styled.div`
  width: 1px;
  height: 16px;
  background-color: #adb5bd;
  margin: 2px 20px 0px;
`;
const LectureContainer = styled.div`
  width: 950px;
  display: flex;
  flex-wrap: wrap;
  @media screen and (max-width: 1280px) {
    width: 820px;
  }
`;
const LectureBlock = styled.div`
  width: 467px;
  margin-bottom: 80px;
  :nth-child(2n) {
    margin-left: 16px;
  }
  @media screen and (max-width: 1280px) {
    width: 402px;
  }
`;
const LectureThumbnail = styled.div`
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #021633;
  background-position: center;
  width: 467px;
  height: 350px;
  border-radius: 12px;
  cursor: pointer;
  @media screen and (max-width: 1280px) {
    width: 402px;
  }
`;
const Title = styled.div`
  font-size: 24px;
  font-weight: ${(props) => props.theme.fontWeight.bold};
  height: 36px;
  color: #212529;
  margin-bottom: 7px;
  width: 467px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
const SubTitle = styled.div`
  font-size: 20px;
  font-weight: ${(props) => props.theme.fontWeight.medium};
  height: 29px;
  color: #868e96;
`;

const BadgeWrap = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${(props) =>
    props.marginBottom ? props.marginBottom + "px" : "16px"};
  margin-top: ${(props) => (props.marginTop ? props.marginTop + "px" : "20px")};
`;
const Badge = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  min-width: ${(props) => (props.minWidth ? "121px" : "0px")};
  height: ${(props) => (props.height ? "40px" : "32px")};
  border-radius: ${(props) => (props.borderRadius ? "12px" : "8px")};
  padding: 6px 20px;
  background-color: ${(props) =>
    props.red
      ? "#FF383B"
      : props.black
      ? "#021633"
      : props.pink
      ? "rgba(255, 56, 59, 0.1)"
      : props.grey
      ? "#DDE2EA"
      : "#ffffff"};
  border: 1px solid
    ${(props) =>
      props.red
        ? "#FF383B"
        : props.black
        ? "#021633"
        : props.pink
        ? "none"
        : props.white
        ? "#021633"
        : props.purple
        ? "#6337FC"
        : props.grey
        ? "#DDE2EA"
        : "#ffffff"};
  color: ${(props) =>
    props.white
      ? "#021633"
      : props.purple
      ? "#6337FC"
      : props.grey
      ? "#021633"
      : props.pink
      ? "#FF383B"
      : "#ffffff"};
  font-size: 14px;
  font-weight: ${(props) => props.theme.fontWeight.medium};
  margin-right: 12px;
`;

const PaginationWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 160px;
`;

const OpenLecture = (props) => {
  const tabName = props.tabName;
  const [activeTab, setActiveTab] = useState("DUE");
  const [page, setPage] = useState(1);
  const [conferenceRooms, setConferenceRooms] = useState([]);
  const [conferenceRoomItem, setConferenceRoomItem] = useState();
  const [totalPage, setTotalPage] = useState(0);
  const [loginModal, setLoginModal] = useState(false);
  const token = useRecoilValue(tokenState);
  const [isReservation, setIsReservation] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    axios({
      url: `${API_URL}/conferenceroom?type=OPEN_LECTURE&timeType=${activeTab}&page=${page}`,
      method: "GET",
    })
      .then((res) => {
        if (res.data.success) {
          setConferenceRooms(
            res.data.result.conferenceRooms.map((item) => {
              return {
                ...item,
              };
            })
          );
          setTotalPage(res.data.result.totalPage * 15);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [activeTab, page]);

  const getPage = (page) => {
    setPage(page);
    window.scrollTo(0, 0);
  };

  return (
    <>
      <Desktop>
        <div>
          <TabWrap>
            <Tab
              active={activeTab === "REALTIME"}
              onClick={() => {
                setActiveTab("REALTIME");
              }}
            >
              실시간 강의
            </Tab>
            <Divide />
            <Tab
              active={activeTab === "DUE"}
              onClick={() => {
                setActiveTab("DUE");
              }}
            >
              예정된 강의
            </Tab>
          </TabWrap>
          <LectureContainer>
            {conferenceRooms.map((item) => (
              <LectureBlock key={item.id}>
                <LectureThumbnail src={item.image} />
                {/* <BadgeWrap>
              <Badge red>실시간</Badge>
              <Badge black minWidth>
                <Timer style={{ marginRight: "8px" }} />
                0: 00: 00
              </Badge>
            </BadgeWrap> */}
                <Title>{item.title}</Title>
                <SubTitle>{item.instructorName}</SubTitle>
              </LectureBlock>
            ))}
          </LectureContainer>

          <PaginationWrap>
            <Pagination
              page={page}
              tabName={tabName}
              totalItemsCount={totalPage}
              showItemsCount={15}
              nowPage={getPage}
            />
          </PaginationWrap>
        </div>
      </Desktop>
      <Mobile>
        {!props.openInfoPage ? (
          //오픈강의리스트보기
          <>
            <MobileSeminarTabBox>
              <li>
                <button
                  onClick={() => {
                    setActiveTab("DUE");
                  }}
                  style={{
                    color: activeTab === "DUE" ? "" : " #ADB5BD",
                  }}
                >
                  예정된 세미나
                </button>
              </li>
              <li>
                <button
                  onClick={() => {
                    setActiveTab("REALTIME");
                  }}
                  style={{
                    color: activeTab === "REALTIME" ? "" : " #ADB5BD",
                  }}
                >
                  실시간 세미나
                </button>
              </li>
            </MobileSeminarTabBox>
            {/** 세미나 리스트 */}
            <ul
              style={{
                display: "flex",
                flexWrap: "wrap",
                columnGap: 16,
                rowGap: 20,
              }}
            >
              {conferenceRooms.map((item, id) => (
                <MobileSeminarCardItem>
                  <button
                    onClick={() => {
                      if (token !== null) {
                        setConferenceRoomItem(item);
                        props.setOpenInfoPage(true);
                      } else {
                        setLoginModal(true);
                      }
                    }}
                  >
                    <img src={item.image} alt="세미니" />
                  </button>
                  <div>
                    <span>{item.maxPeople}명</span>
                    <span>마감임박</span>
                  </div>
                  <div>
                    <h2>{item.title}</h2>
                    <p>{dayjs(item.updatedAt).fromNow()}</p>
                    <h3>{Number(item.price)?.toLocaleString()}원</h3>
                  </div>
                </MobileSeminarCardItem>
              ))}
            </ul>
          </>
        ) : (
          //세미나상세보기
          <>
            <MobileSeminarDetailContainer>
              <MobileSeminarDetailInfo>
                <figure>
                  <img alt="세미나" src={conferenceRoomItem.image} />
                </figure>
                <div>
                  <div>
                    <span>실시간</span>
                    <span>
                      <Timer
                        style={{ width: 14, height: 14, marginRight: 4 }}
                      />
                      <span>00:00:00</span>
                    </span>
                  </div>
                  <div>
                    <h2>{conferenceRoomItem.title}</h2>
                    <p>{conferenceRoomItem.instructorName}</p>
                    <p>
                      <img src={people} alt="maxpeople" />
                      <span>{conferenceRoomItem.maxPeople}명</span>
                    </p>
                    <p>
                      <img src={calendar} alt="startDate" />
                      <span>
                        {dayjs(conferenceRoomItem.startAt).format(
                          "YYYY년 MM월 DD일 HH:mm"
                        )}
                      </span>
                    </p>
                  </div>
                </div>
              </MobileSeminarDetailInfo>
              <MobileInstructorInfo>
                <h2>강사 정보</h2>
                <ul>
                  {conferenceRoomItem.instructorDescription
                    .split("\n")
                    .map((el) => (
                      <li>{el}</li>
                    ))}
                </ul>
              </MobileInstructorInfo>
              <MobileClassInfo>
                <h2>강연 정보</h2>
                <div>{conferenceRoomItem.description}</div>
              </MobileClassInfo>
            </MobileSeminarDetailContainer>
            <MobileConferenceBtn>
              <Button
                active
                disabled={
                  conferenceRoomItem.reservationEnd ||
                  conferenceRoomItem.reserved
                }
                onClick={() => {}}
              >
                {conferenceRoomItem.reservationEnd && "예약마감"}
                {conferenceRoomItem.reservationEnd === false &&
                  conferenceRoomItem.reserved &&
                  "예약완료"}
                {conferenceRoomItem.reservationEnd === false &&
                  !conferenceRoomItem.reserved &&
                  conferenceRoomItem.price !== 0 &&
                  `${conferenceRoomItem.price}원 결제하기`}
                {conferenceRoomItem.reservationEnd === false &&
                  !conferenceRoomItem.reserved &&
                  conferenceRoomItem.price === 0 &&
                  `예약하기`}
              </Button>
            </MobileConferenceBtn>
          </>
        )}
        {loginModal && (
          <MobileModal
            onClose={() => {
              setLoginModal(false);
            }}
            onPress={() => {
              navigate("/login");
            }}
            title={"로그인 안내"}
            content={"상세페이지 확인은 로그인 상태에서 가능합니다."}
            button={[
              {
                title: "닫기",
                type: "cancel",
              },
              {
                title: "로그인하기",
                type: "active",
              },
            ]}
          />
        )}
        {isReservation && (
          <MobileModal
            onClose={() => {
              setIsReservation(false);
            }}
            onPress={() => {
              setIsReservation(false);
            }}
            btncnt={1}
            title={"예약완료"}
            content={"세미나 예약이 완료되었습니다"}
            button={[
              {
                title: "확인",
                type: "active",
              },
            ]}
          />
        )}
      </Mobile>
    </>
  );
};

export default OpenLecture;
