import React, {useState, useEffect} from "react";
import styled from "styled-components";

import NaverBlog from '../../assets/icons/naver_blog.svg';
import Band from "../../assets/icons/naver_band.svg";
import Youtube from "../../assets/icons/youtube.svg";
import KaKao from "../../assets/icons/kakao.svg";
import Phone from "./images/phone.png";
import PlayStore from "../../assets/icons/play_store.svg";
import AppStore from "../../assets/icons/app_store.svg";
import axios from "axios";
import {API_URL} from "../../API";

const Container = styled.div`
  width: 100%;
  height: 20rem;
  background-color: ${(props) => props.theme.color.primary};
  padding: 40px 20px 0px 20px;
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
`;
const InnerContainer = styled.div`
  //width: 100%;
  //height: 16rem;
  background-color: ${(props) => props.theme.color.primary};
  //padding: 1.5rem 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  //justify-content: space-evenly;
  //@media screen and (max-width: 1280px) {
  //  padding: 60px 100px;
  //}
`;
const ContentsWrap = styled.div`
  width: ${(props) =>
          props.shotFooter ? "300px" : props.longFooter ? "700px" : "400px"};
  // min-width: 400px;
    //margin-right: ${(props) => (props.marginRight ? "20px" : null)};
  border-top: solid 2px #ffffff;
  color: ${(props) => props.theme.color.white};
  padding-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
`;
const FooterTitle = styled.div`
  font-size: 16px;
  font-weight: ${(props) => props.theme.fontWeight.bold};
  padding-top: 8px;
  padding-left: 20px;
  margin-bottom: 16px;
`;
const KaKaoWrap = styled.a`
  width: 12rem;
  height: 36px;
  background-color: #fee500;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-bottom: 12px;
  text-decoration: none;

  span {
    color: ${(props) => props.theme.color.textColor};
    font-size: 12px;
    font-weight: ${(props) => props.theme.fontWeight.bold};
    margin-left: 8px;
  }
`;
const IconImgWrap = styled.img`
  cursor: pointer;
  margin-right: ${(props) => (props.marginRight ? "16px" : null)};
  margin-left: ${(props) => (props.marginLeft ? "20px" : null)};
`;

const MobileFooter = () => {
  const [footerTerms, setFooterTerms] = useState([]);
  useEffect(() => {
    axios({
      url: `${API_URL}/terms`,
      method: "GET",
    })
      .then((result) => {
        if (result.data.success) {
          setFooterTerms(result.data.result.terms.slice(0, 2));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <Container>
      <ContentsWrap marginRight>
        <FooterTitle>고객센터</FooterTitle>
        <InnerContainer>
          <KaKaoWrap
            target="_blank"
            href="http://pf.kakao.com/_bxdWgb/chat"
          >
            <IconImgWrap src={KaKao} width="17px" height="17px"/>
            <span>카카오톡 문의하기</span>
          </KaKaoWrap>
          <KaKaoWrap
            style={{backgroundColor: "#0cc938"}}
            href='tel:02-536-5862'
          >
            <IconImgWrap src={Phone} width="17px" height="17px"/>
            <span style={{marginRight: "6px"}}>전화 문의하기</span>
          </KaKaoWrap>
        </InnerContainer>
      </ContentsWrap>

    </Container>
  );
};

export default MobileFooter;
