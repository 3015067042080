import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import {
  Container,
  HistoryCard,
  ConditionText,
  RowWrap,
  VerticalBorder,
  NameText,
  HistoryCardTtitle,
  HistoryCardContent,
  PopupTitle,
  PopupCloseButton,
  ReviewName,
  StarWrap,
  StarNoticeText,
  StarNumberText,
  EmptyText,
} from "../information/style";
import { PopupBackground, PopupContainer } from "../../../../components/Popup";
import CategoryTab from "../../../../components/CategoryTab";
import Button from "../../../../components/Button";
import Textarea from "../../../../components/Textarea";
import { ReactComponent as CloseIcon } from "../../../../assets/icons/cancle_44px.svg";
import { ReactComponent as BlankStarIcon } from "../../../../assets/icons/blank_star.svg";
import { ReactComponent as StarIcon } from "../../../../assets/icons/star.svg";
import { API_URL } from "../../../../API";
import dayjs from "dayjs";
import runBootPay from "../../../../components/BootPay";
import { useRecoilValue } from "recoil";
import userInfoState from "../../../../states/userInfoState";

const LessonHistory = ({ type, histories, refetch }) => {
  const [activeTab, setActiveTab] = useState("all");
  const navigate = useNavigate();
  const [star, setStar] = useState([
    { id: 1, value: false },
    { id: 2, value: false },
    { id: 3, value: false },
    { id: 4, value: false },
    { id: 5, value: false },
    { id: 6, value: false },
    { id: 7, value: false },
    { id: 8, value: false },
    { id: 9, value: false },
    { id: 10, value: false },
  ]);
  const [reviewClassId, setReviewClassId] = useState("");
  const [reviewContents, setReviewContents] = useState("");

  const writeReview = () => {
    axios({
      method: "POST",
      url: `${API_URL}/mypage/class/${reviewClassId}/review`,
      data: {
        score: star.filter((item) => item.value === true).length,
        contents: reviewContents,
      },
    }).then((res) => {
      if (res.data.success) {
        alert("후기가 작성되었습니다!");
        refetch();
        setReviewClassId("");
        setReviewContents("");
        setStar([
          { id: 1, value: false },
          { id: 2, value: false },
          { id: 3, value: false },
          { id: 4, value: false },
          { id: 5, value: false },
          { id: 6, value: false },
          { id: 7, value: false },
          { id: 8, value: false },
          { id: 9, value: false },
          { id: 10, value: false },
        ]);
      } else {
        alert(res.data.error.message);
      }
    });
  };

  if (type === "TEACHER") {
    return (
      <div>
        <RowWrap
          style={{ alignItems: "center", marginTop: 48, marginBottom: 32 }}
        >
          <ConditionText
            onClick={() => {
              setActiveTab("all");
            }}
            active={activeTab === "all"}
          >
            전체
          </ConditionText>
          <VerticalBorder />
          <ConditionText
            onClick={() => {
              setActiveTab("end");
            }}
            active={activeTab === "end"}
          >
            수업종료
          </ConditionText>
          <VerticalBorder />
          <ConditionText
            onClick={() => {
              setActiveTab("ing");
            }}
            active={activeTab === "ing"}
          >
            수업중
          </ConditionText>
        </RowWrap>
        <div>
          {histories.length > 0 && activeTab !== "end" ? (
            histories.map((item, idx) => (
              <HistoryCard margin={(idx + 1) % 2 !== 0} key={idx}>
                <RowWrap
                  style={{
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginBottom: 32,
                  }}
                >
                  <RowWrap
                    style={{
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <NameText>{item.user.nickname}님</NameText>
                    {/* <ArrowIcon style={{ transform: "rotate(90deg)" }} /> */}
                  </RowWrap>
                  {/* <FinishBox>수강종료</FinishBox> */}
                </RowWrap>
                {item.startDay !== null && item.endDay !== null && (
                  <RowWrap>
                    <HistoryCardTtitle>기간</HistoryCardTtitle>
                    <HistoryCardContent>
                      {dayjs(item.startDay).format("YYYY년 MM월 DD일")} ~{" "}
                      {dayjs(item.endDay).format("YYYY년 MM월 DD일")}
                    </HistoryCardContent>
                  </RowWrap>
                )}
                {item.studyStartTime !== null && item.studyTime !== null && (
                  <RowWrap>
                    <HistoryCardTtitle>시간</HistoryCardTtitle>
                    <HistoryCardContent>
                      {item.studyStartTime}부터 {item.studyTime}시간
                    </HistoryCardContent>
                  </RowWrap>
                )}
                <RowWrap>
                  <HistoryCardTtitle>요일</HistoryCardTtitle>
                  <HistoryCardContent>
                    {item.classDays.join(",")}
                  </HistoryCardContent>
                </RowWrap>
                <Button
                  style={{ borderRadius: 12, marginTop: 32 }}
                  active={!item.paymentRequested}
                  onClick={() => {
                    axios({
                      method: "POST",
                      url: `${API_URL}/mypage/class/${item.id}/additional`,
                    }).then((res) => {
                      if (res.data.success) {
                        navigate(
                          `/mypage/teacher/requestPayment/${res.data.result.id}`
                        );
                      }
                    });
                  }}
                >
                  추가 결제 요청하기
                </Button>
              </HistoryCard>
            ))
          ) : (
            <EmptyText>수업내역이 없습니다.</EmptyText>
          )}
        </div>
      </div>
    );
  }

  return (
    <div>
      <RowWrap
        style={{ alignItems: "center", marginTop: 48, marginBottom: 32 }}
      >
        <ConditionText
          onClick={() => {
            setActiveTab("all");
          }}
          active={activeTab === "all"}
        >
          전체
        </ConditionText>
        <VerticalBorder />
        <ConditionText
          onClick={() => {
            setActiveTab("end");
          }}
          active={activeTab === "end"}
        >
          수강종료
        </ConditionText>
        <VerticalBorder />
        <ConditionText
          onClick={() => {
            setActiveTab("ing");
          }}
          active={activeTab === "ing"}
        >
          수강중
        </ConditionText>
      </RowWrap>
      <div>
        {histories.length > 0 && activeTab !== "end" ? (
          histories.map((item, idx) => (
            <HistoryCard margin={(idx + 1) % 2 !== 0} key={idx}>
              <RowWrap
                style={{
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginBottom: 32,
                }}
              >
                <RowWrap
                  style={{
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <NameText>{item.mentopick.teacher.nickname}님</NameText>
                  {/* <ArrowIcon style={{ transform: "rotate(90deg)" }} /> */}
                </RowWrap>
                {/* <FinishBox>수강종료</FinishBox> */}
              </RowWrap>
              {item.startDay !== null && item.endDay !== null && (
                <RowWrap>
                  <HistoryCardTtitle>기간</HistoryCardTtitle>
                  <HistoryCardContent>
                    {dayjs(item.startDay).format("YYYY년 MM월 DD일")} ~{" "}
                    {dayjs(item.endDay).format("YYYY년 MM월 DD일")}
                  </HistoryCardContent>
                </RowWrap>
              )}
              {item.studyStartTime !== null && item.studyTime !== null && (
                <RowWrap>
                  <HistoryCardTtitle>시간</HistoryCardTtitle>
                  <HistoryCardContent>
                    {item.studyStartTime}부터 {item.studyTime}시간
                  </HistoryCardContent>
                </RowWrap>
              )}
              <RowWrap>
                <HistoryCardTtitle>요일</HistoryCardTtitle>
                <HistoryCardContent>
                  {item.classDays.join(",")}
                </HistoryCardContent>
              </RowWrap>
              {item.reviewWrited === false && (
                <Button
                  style={{ borderRadius: 12, marginTop: 32 }}
                  active
                  onClick={() => {
                    setReviewClassId(item.id);
                  }}
                >
                  후기쓰기
                </Button>
              )}
            </HistoryCard>
          ))
        ) : (
          <EmptyText>수강내역이 없습니다.</EmptyText>
        )}
      </div>
      {reviewClassId !== "" && (
        <PopupBackground>
          <PopupContainer width="812px">
            <PopupCloseButton
              onClick={() => {
                setReviewClassId("");
                setReviewContents("");
                setStar([
                  { id: 1, value: false },
                  { id: 2, value: false },
                  { id: 3, value: false },
                  { id: 4, value: false },
                  { id: 5, value: false },
                  { id: 6, value: false },
                  { id: 7, value: false },
                  { id: 8, value: false },
                  { id: 9, value: false },
                  { id: 10, value: false },
                ]);
              }}
            >
              <CloseIcon />
            </PopupCloseButton>
            <PopupTitle>후기쓰기</PopupTitle>
            <ReviewName>영재멘토님</ReviewName>
            <StarWrap>
              <div>
                <StarNoticeText>
                  선생님에 대한 객관적인 평점을 선택해주세요.
                </StarNoticeText>
                <RowWrap style={{ alignItems: "center" }}>
                  {star.map((item, idx) => {
                    if (item.value === true) {
                      return (
                        <StarIcon
                          key={idx}
                          style={{ cursor: "pointer", marginRight: 8 }}
                          onClick={() => {
                            setStar(
                              star.map((starItem, starIdx) => {
                                if (starIdx <= idx) {
                                  return {
                                    id: starItem.id,
                                    value: true,
                                  };
                                }
                                return {
                                  id: starItem.id,
                                  value: false,
                                };
                              })
                            );
                          }}
                        />
                      );
                    }
                    return (
                      <BlankStarIcon
                        key={idx}
                        style={{ cursor: "pointer", marginRight: 8 }}
                        onClick={() => {
                          setStar(
                            star.map((starItem, starIdx) => {
                              if (starIdx <= idx) {
                                return {
                                  id: starItem.id,
                                  value: true,
                                };
                              }
                              return {
                                id: starItem.id,
                                value: false,
                              };
                            })
                          );
                        }}
                      />
                    );
                  })}
                  <StarNumberText>
                    / {star.filter((value) => value.value).length}점
                  </StarNumberText>
                </RowWrap>
              </div>
            </StarWrap>
            <Textarea
              value={reviewContents}
              placeholder={"후기를 입력해주세요."}
              height={"260px"}
              onChange={setReviewContents}
            />
            <div style={{ paddingLeft: 245, paddingRight: 245, marginTop: 32 }}>
              <Button active onClick={writeReview}>
                후기쓰기
              </Button>
            </div>
          </PopupContainer>
        </PopupBackground>
      )}
    </div>
  );
};

const OTHistory = ({ histories, type, refetch }) => {
  const navigate = useNavigate();
  const userInfo = useRecoilValue(userInfoState);

  const payment = (id, receiptId) => {
    axios({
      method: "POST",
      url: `${API_URL}/mypage/otRequest/${id}/payment?receiptId=${receiptId}`,
    }).then((res) => {
      if (res.data.success) {
        alert("결제가 완료되었습니다!");
        refetch();
      } else {
        alert(res.data.error.message);
      }
    });
  };

  const cancelOtRequest = (id) => {
    axios({
      method: "DELETE",
      url: `${API_URL}/mypage/otRequest/${id}`,
    }).then((res) => {
      if (res.data.success) {
        alert("취소가 완료되었습니다!");
        refetch();
      } else {
        alert(res.data.error.message);
      }
    });
  };

  if (type === "TEACHER") {
    return (
      <div style={{ marginTop: 60 }}>
        {histories.length > 0 ? (
          histories.map((item, idx) => (
            <HistoryCard margin={(idx + 1) % 2 !== 0} key={idx}>
              <RowWrap
                key={item.id}
                style={{
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <RowWrap
                  style={{
                    alignItems: "center",
                    cursor: "pointer",
                    marginBottom: 32,
                  }}
                >
                  <NameText>{item.user.nickname}님</NameText>
                </RowWrap>
              </RowWrap>
              <RowWrap>
                <HistoryCardTtitle>시간</HistoryCardTtitle>
                <HistoryCardContent>
                  {item.date
                    ? dayjs(item.date).format("YYYY-MM-DD HH:mm:ss")
                    : "추가 결제요청"}
                </HistoryCardContent>
              </RowWrap>
              <Button
                style={{ borderRadius: 12, marginTop: 32 }}
                active={!item.paymentRequested}
                onClick={() => {
                  if (!item.paymentRequested) {
                    navigate(`/mypage/teacher/requestPayment/${item.id}`);
                  }
                }}
              >
                결제요청하기
              </Button>
              {item.cancelable && (
                <Button
                  style={{ borderRadius: 12, marginTop: 32 }}
                  active={item.cancelable}
                  onClick={() => {
                    if (item.cancelable) {
                      cancelOtRequest(item.id);
                    }
                  }}
                >
                  취소하기
                </Button>
              )}
            </HistoryCard>
          ))
        ) : (
          <EmptyText>OT 신청내역이 없습니다.</EmptyText>
        )}
      </div>
    );
  }

  return (
    <div style={{ marginTop: 60 }}>
      {histories.length > 0 ? (
        histories.map((item, idx) => (
          <HistoryCard margin={(idx + 1) % 2 !== 0} key={idx}>
            <RowWrap
              style={{
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <RowWrap
                style={{
                  alignItems: "center",
                  cursor: "pointer",
                  marginBottom: 32,
                }}
              >
                <NameText>{item.mentopick.teacher.nickname}님</NameText>
                {/* <ArrowIcon style={{ transform: "rotate(90deg)" }} /> */}
              </RowWrap>
            </RowWrap>
            {/* <RowWrap>
              <HistoryCardTtitle>기간</HistoryCardTtitle>
              <HistoryCardContent>
                2022년 2월 20일 ~ 2022년 4월 20일
              </HistoryCardContent>
            </RowWrap> */}
            <RowWrap>
              <HistoryCardTtitle>시간</HistoryCardTtitle>
              <HistoryCardContent>
                {item.date
                  ? dayjs(item.date).format("YYYY-MM-DD HH:mm:ss")
                  : "추가 결제요청"}
              </HistoryCardContent>
            </RowWrap>
            {/* <RowWrap>
              <HistoryCardTtitle>요일</HistoryCardTtitle>
              <HistoryCardContent>화, 목</HistoryCardContent>
            </RowWrap> */}
            {item.paymentRequested === true && item.paymented === false && (
              <Button
                style={{ borderRadius: 12, marginTop: 32 }}
                active
                onClick={() => {
                  runBootPay(
                    item.mentopickClass.price,
                    `영재멘토 수업료 (${item.mentopick.teacher.nickname})`,
                    `영재멘토 수업료 (${item.mentopick.teacher.nickname})`,
                    userInfo.nickname,
                    userInfo.email,
                    "",
                    userInfo.phoneNumber,
                    () => {},
                    (receiptId) => {
                      payment(item.id, receiptId);
                    }
                  );
                }}
              >
                결제하기
              </Button>
            )}
            {item.cancelable && (
              <Button
                style={{ borderRadius: 12, marginTop: 32 }}
                active={item.cancelable}
                onClick={() => {
                  if (item.cancelable) {
                    cancelOtRequest(item.id);
                  }
                }}
              >
                취소하기
              </Button>
            )}
          </HistoryCard>
        ))
      ) : (
        <EmptyText>OT 신청내역이 없습니다.</EmptyText>
      )}
    </div>
  );
};

const HistoryManagement = ({ type }) => {
  const [mentopickHistories, setMentopickHistories] = useState([]);
  const [classHistories, setClassHistories] = useState([]);

  const getData = () => {
    axios({
      method: "GET",
      url: `${API_URL}/mypage/otRequests`,
    }).then((res) => {
      if (res.data.success) {
        console.log("result: ", res.data);
        setMentopickHistories(res.data.result.otRequests);
      }
    });

    axios({
      method: "GET",
      url: `${API_URL}/mypage/classes`,
    }).then((res) => {
      if (res.data.success) {
        setClassHistories(res.data.result.classes);
      }
    });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Container>
      <CategoryTab
        array={[type === "TEACHER" ? "수업내역" : "수강내역", "OT 신청내역"]}
        contents={[
          <LessonHistory
            type={type}
            histories={classHistories}
            refetch={getData}
          />,
          <OTHistory
            type={type}
            histories={mentopickHistories}
            refetch={getData}
          />,
        ]}
      />
    </Container>
  );
};

export default HistoryManagement;
