import React, { useState, useEffect } from "react";
import { useRecoilState } from "recoil";
import axios from "axios";

import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Modal from "../../components/Modal";
import { Container, BannerTitle } from "./style";
import SideBar from "../../components/SideBar";
import searchValueState from "../../states/searchValueState";
import ContentMarketList from "./list";

import { API_URL } from "../../API";
import { Desktop, Mobile } from "../../utils/mediaQuery";
import MobileHeader from "../../components/MobileHeader";
import MobileBottomBar from "../../components/MobileBottomBar";
import MobileModal from "../../components/MobileModal";

const ContentsMarket = () => {
  const [userType, setUserType] = useState("");
  const [isPaid, setIsPaid] = useState(false);
  const [tabName, setTabName] = useState("");
  const [currentTab, setCurrentTab] = useState("");
  const [searchResult, setSearchResult] = useState("");
  const [searchValue, setSearchValue] = useRecoilState(searchValueState);
  const [teacherModal, setTeacherModal] = useState(false);
  const [activeTab, setActiveTab] = useState(null);
  const [allCategories, setAllCategories] = useState([]);
  const [teacherCategories, setTeacherCategories] = useState([]);
  const [type, setType] = useState("ALL");
  const [categoryId, setCategoryId] = useState(null);
  const [items, setItems] = useState([]);
  const [paidType, setPaidType] = useState("ALL");
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [subTabName, setSubTabName] = useState("전체");

  useEffect(() => {
    axios({
      url: `${API_URL}/mypage`,
      method: "GET",
    })
      .then((result) => {
        if (result.data.success === true) {
          setUserType(result.data.result.type);
          setIsPaid(
            result.data.result.type === "TEACHER"
              ? true
              : result.data.result.isPaid
          );
        } else {
          setUserType("non-login");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    axios({
      method: "GET",
      url: `${API_URL}/contentsMarket/categories`,
    }).then((res) => {
      if (res.data.success) {
        setAllCategories(
          res.data.result.categories.filter(
            (category) => category.uploaderType === "ALL"
          )
        );
        setTeacherCategories(
          res.data.result.categories.filter(
            (category) => category.uploaderType === "TEACHER"
          )
        );
      }
    });
  }, []);

  useEffect(() => {
    axios({
      method: "GET",
      url: `${API_URL}/contentsMarket`,
      params: {
        type,
        category: categoryId,
        paidType,
        page,
      },
    }).then((res) => {
      if (res.data.success) {
        setItems(res.data.result.items);
        setTotalPage(res.data.result.totalPage);
      }
    });
  }, [type, categoryId, paidType, page]);

  return (
    <>
      <Desktop>
        <Header activeTab={"contentsMarket"} />
        <Container>
          <SideBar
            tabName={(value) => {
              setTabName(value);

              if (value === 1) {
                if (userType !== "TEACHER") {
                  setTeacherModal(true);
                  document.body.style.overflow = "hidden";
                  setActiveTab(0);
                } else {
                  setType("TEACHER");
                }
              } else {
                setType("ALL");
              }
            }}
            activeTab={activeTab}
            tabIndex={(value) => {
              setCurrentTab(value);
            }}
            subTabName={() => {}}
            subTabIndex={() => {}}
            subTabId={(id) => {
              if (id === "all") {
                setCategoryId(null);
              } else {
                setCategoryId(id);
              }
            }}
            onClick={() => {
              setSearchResult(searchValue);
              setSearchValue("");
            }}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                setSearchResult(searchValue);
                setSearchValue("");
              }
            }}
            contents={
              <ContentMarketList
                isPaid={isPaid}
                login={userType !== "non-login"}
                items={items}
                onPaidTypeChange={setPaidType}
                totalPage={totalPage}
                onPageChange={setPage}
              />
            }
            array={[
              {
                id: 0,
                name: "전체 자료실",
                sub: [
                  {
                    id: "all",
                    name: "전체",
                  },
                  ...allCategories,
                ],
              },
              {
                id: 1,
                name: "선생님 자료실",
                sub: [
                  {
                    id: "all",
                    name: "전체",
                  },
                  ...teacherCategories,
                ],
              },
            ]}
            bannerTitle={
              <BannerTitle>
                나에게 필요한 <BannerTitle orange>모든 자료</BannerTitle>를
                공유해보세요.
              </BannerTitle>
            }
          >
            컨텐츠 마켓
          </SideBar>
        </Container>
        <Footer />
        {teacherModal && (
          <Modal
            padding
            title={"죄송합니다."}
            content={"학생/학부모 회원은 해당 자료실 이용이 불가합니다."}
            button={[
              {
                title: "확인",
                type: "active",
              },
            ]}
            onPress={() => {
              setTeacherModal(false);
              // window.location.reload();
              document.body.style.overflow = "auto";
            }}
          />
        )}
      </Desktop>
      <Mobile>
        <MobileHeader name={"컨텐츠마켓"} type="main" />
        <SideBar
          page={"contentsMarket"}
          setSubTabName={setSubTabName}
          setTeacherModal={setTeacherModal}
          userType={userType}
          array={[
            {
              id: 0,
              name: "전체 자료실",
              sub: [
                {
                  id: "all",
                  name: "전체",
                },
                ...allCategories,
              ],
            },
            {
              id: 1,
              name: "선생님 자료실",
              sub: [
                {
                  id: "all",
                  name: "전체",
                },
                ...teacherCategories,
              ],
            },
          ]}
          bannerTitle={
            <>
              <h2>
                나에게 <span>모든 자료</span>를 공유해보세요
              </h2>
            </>
          }
        />
        <ContentMarketList
          isPaid={isPaid}
          subTabName={subTabName}
          login={userType !== "non-login"}
          items={items}
          onPaidTypeChange={setPaidType}
          totalPage={totalPage}
          onPageChange={setPage}
        />
        <MobileBottomBar activeTab={"contentsMarket"} />
        {teacherModal && (
          <MobileModal
            padding
            title={"죄송합니다."}
            content={"학생/학부모 회원은 해당 자료실 이용이 불가합니다."}
            button={[
              {
                title: "확인",
                type: "active",
              },
            ]}
            onPress={() => {
              setTeacherModal(false);
            }}
          />
        )}
      </Mobile>
    </>
  );
};

export default ContentsMarket;
