import React, { useEffect, useState } from "react";
import axios from "axios";
import { API_URL } from "../../API";
import dayjs from "dayjs";

import {
  Container,
  PageTitle,
  PageSubTitle,
  ConditionText,
  VerticalBorder,
  BenefitWrap,
  ImageWrap,
  Image,
  BoardTitle,
  DateText,
  RowWrap,
  PaginationWrap,
} from "./style";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Pagination from "../../components/Pagination";
import { useNavigate } from "react-router-dom";

const Benefit = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);
  const [order, setOrder] = useState("DESC");
  const [benefit, setBenefit] = useState(null);
  const [page, setPage] = useState("");

  const getBenefit = () => {
    axios({
      url: `${API_URL}/benefit?page=${page}&order=${order}`,
      method: "GET",
    })
      .then((result) => {
        if (result.data.success) {
          console.log(result.data.result);
          setBenefit(result.data.result);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getBenefit();
  }, []);

  const getPage = (page) => {
    setPage(page);
    window.scrollTo(0, 0);
  };

  return (
    <>
      <Header activeTab={"Benefit"} />
      <Container>
        <PageTitle>공지사항</PageTitle>
        <PageSubTitle>영재멘토의 다양한 공지사항을 확인해보세요.</PageSubTitle>
        <RowWrap style={{ marginBottom: 32 }}>
          <ConditionText
            onClick={() => {
              setActiveTab(0);
              setOrder("DESC");
              getBenefit();
            }}
            active={activeTab === 0}
          >
            최신순
          </ConditionText>
          <VerticalBorder />
          <ConditionText
            onClick={() => {
              setActiveTab(1);
              setOrder("ASC");
              getBenefit();
            }}
            active={activeTab === 1}
          >
            오래된 순
          </ConditionText>
        </RowWrap>
        {benefit !== null ? (
          <>
            {benefit.benefits.map((item, index) => {
              const dateFormat = dayjs(item.createdAt).format("YYYY.MM.DD");

              return (
                <BenefitWrap key={index} onClick={() => { navigate(`/benefit/${item.id}`); }}>
                  <ImageWrap>
                    <Image src={item.thumbnail} />
                  </ImageWrap>
                  <BoardTitle>{item.title}</BoardTitle>
                  <DateText>{dateFormat}</DateText>
                </BenefitWrap>
              );
            })}
          </>
        ) : null}
        <PaginationWrap>
          <Pagination
            page={page}
            totalItemsCount={benefit !== null ? benefit.totalPage * 5 : 0}
            showItemsCount={5}
            nowPage={getPage}
          />
        </PaginationWrap>
      </Container>
      <Footer />
    </>
  );
};

export default Benefit;
