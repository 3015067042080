import React from "react";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  position: relative;
`;

const RowWrap = styled.div`
  display: flex;
`;

const MainWrap = styled.div`
  width: 1364px;
  padding: 200px 0px 260px;
  margin: 0 auto;

  @media screen and (max-width: 1280px) {
    width: 1200px;
  }
`;

const ProfileContainer = styled.div`
  width: 398px;
  margin-left: 154px;
  @media screen and (max-width: 1280px) {
    margin-left: 54px;
  }
`;

const ProfileWrap = styled.div`
  width: 398px;
  min-height: 725px;
  height: auto;
  padding: 32px;
  flex: none;
  border-radius: 12px;
  border: 1px solid #dde2ea;
  background-color: #ffffff;
  position: -webkit-sticky;
  position: sticky;
  top: 100px;
  /* bottom: ${(props) => (props.fix ? "500px" : "0px")}; */
`;

const UserName = styled.div`
  color: #212529;
  font-size: 28px;
  font-weight: 700;
  line-height: 32px;
  margin-top: 8px;
`;

const UserType = styled.div`
  background-color: #021633;
  width: 66px;
  height: 32px;
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
`;

const UserPayment = styled.div`
  background-color: rgba(0, 129, 245, 0.1);
  width: 92px;
  height: 32px;
  color: #0081f5;
  font-size: 14px;
  font-weight: 500;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const JoinDateText = styled.div`
  color: #212529;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
`;

const VerticalBorder = styled.div`
  background-color: #212529;
  width: 1px;
  height: 16px;
  margin: 0px 8px;
`;

const ButtonWrap = styled.div`
  border-top: 2px solid #e9ecef;
  margin-top: 32px;
  padding-top: 32px;
`;

const PopupTitle = styled.div`
  color: #212529;
  font-size: 24px;
  font-weight: 700;
  line-height: 50px;
  padding-bottom: 8px;
  border-bottom: 2px solid #212529;
  display: inline-block;
  margin-bottom: 32px;
`;
const PopupCloseButton = styled.div`
  position: absolute;
  top: 24px;
  right: 24px;
  cursor: pointer;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='44' height='44' viewBox='0 0 44 44'%3E%3Cdefs%3E%3CclipPath id='clip-path'%3E%3Crect id='사각형_807' data-name='사각형 807' width='44' height='44' transform='translate(1160 324)' fill='%23adb5bd'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg id='마스크_그룹_50' data-name='마스크 그룹 50' transform='translate(-1160 -324)' clip-path='url(%23clip-path)'%3E%3Cg id='b' transform='translate(1160 324)'%3E%3Cg id='c'%3E%3Cpath id='패스_243' data-name='패스 243' d='M0,0H44V44H0Z' fill='%23adb5bd' opacity='0' style='isolation: isolate'/%3E%3Cline id='선_4' data-name='선 4' x2='25.667' y2='25.667' transform='translate(9.167 9.167)' fill='none' stroke='%23212529' stroke-linecap='round' stroke-miterlimit='10' stroke-width='4'/%3E%3Cline id='선_5' data-name='선 5' y1='25.667' x2='25.667' transform='translate(9.167 9.167)' fill='none' stroke='%23212529' stroke-linecap='round' stroke-miterlimit='10' stroke-width='4'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
  width: 44px;
  height: 44px;
`;

const ProfileImgWrap = styled.div`
  width: 334px;
  height: 334px;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
`;
const ProfileImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const ChangeImageContainer = styled.div`
  background-color: rgba(33, 37, 41, 0.5);
  border-radius: 8px;
  width: 92px;
  height: 32px;
  position: absolute;
  bottom: 12px;
  left: 12px;
  padding: 6px 0px;
  text-align: center;
`;

const ImageInput = styled.input`
  position: absolute;
  width: 0;
  height: 0;
  padding: 0;
  overflow: hidden;
  border: 0;
`;

const ImageLabel = styled.label`
  width: 100%;
  height: 100%;
  cursor: pointer;
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
`;

export {
  Container,
  RowWrap,
  MainWrap,
  ProfileWrap,
  UserName,
  UserType,
  UserPayment,
  JoinDateText,
  VerticalBorder,
  ButtonWrap,
  PopupTitle,
  PopupCloseButton,
  ProfileContainer,
  ProfileImgWrap,
  ProfileImg,
  ChangeImageContainer,
  ImageInput,
  ImageLabel,
};
