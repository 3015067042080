import React from "react";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  overflow: auto;
`;

const MainWrap = styled.div`
  width: 812px;
  padding: 200px 0px 260px;
  margin: 0 auto;
`;

const PageTypeText = styled.div`
  color: #ffad2e;
  font-size: 20px;
  font-weight: 500;
  line-height: 50px;
  margin-bottom: 4px;
`;

const JoinTypeText = styled.div`
  color: #212529;
  font-size: 40px;
  font-weight: 700;
  line-height: 50px;
  margin-bottom: 32px;
`;

const JoinNoticeText = styled.div`
  color: #212529;
  font-size: 20px;
  font-weight: 400;
  line-height: 50px;
  padding-bottom: 20px;
  border-bottom: 1px solid #e9ecef;
  margin-bottom: 80px;
`;

const Title = styled.span`
  color: #212529;
  font-size: 28px;
  font-weight: 700;
  line-height: 50px;
  padding-bottom: 8px;
  border-bottom: 2px solid #212529;
`;

const RowWrap = styled.div`
  display: flex;
  margin-bottom: ${(props) =>
    props.marginBottom ? props.marginBottom : "80px"};
`;

const InfoTitle = styled.div`
  color: #212529;
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  margin-top: ${(props) => (props.marginTop ? props.marginTop : "32px")};
  margin-bottom: ${(props) =>
    props.marginBottom ? props.marginBottom : "20px"};
`;

const RegexText = styled.div`
  color: #868e96;
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
  margin-bottom: 20px;
  white-space: pre;
`;

const UrlText = styled.a`
  color: #868e96;
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
  margin-bottom: 20px;
  cursor: pointer;
  text-decoration: underline;
  text-decoration-color: #868e96;
`;

const Border = styled.div`
  width: 100%;
  height: 2px;
  background-color: #e9ecef;
  margin-bottom: ${(props) =>
    props.marginBottom ? props.marginBottom : "80px"};
`;

const SubjectChoiceWrap = styled.div`
  width: 100%;
  min-height: 84px;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23333' stroke-width='4' stroke-dasharray='4%2c10 ' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e");
  border-radius: 8px;
  margin-bottom: 20px;
  padding: 20px 20px 8px 20px;
`;

const ChoiceBox = styled.div`
  border: 1px solid #021633;
  border-radius: 6px;
  padding: 6px 16px;
  box-sizing: border-box;
  position: relative;
  display: inline-block;
  margin-right: 16px;
  margin-bottom: 12px;
  height: 44px;
`;

const ChoiceText = styled.div`
  color: #021633;
  font-size: 14px;
  font-weight: 500;
  line-height: 32px;
  margin-right: 12px;
  display: inline-block;
  vertical-align: middle;
`;

const CancleWrap = styled.div`
  display: inline-block;
  cursor: pointer;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='18' height='18' viewBox='0 0 18 18'%3E%3Cdefs%3E%3CclipPath id='clip-path'%3E%3Crect id='사각형_2337' data-name='사각형 2337' width='18' height='18' transform='translate(743 2674)' fill='%23868e96'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg id='마스크_그룹_87' data-name='마스크 그룹 87' transform='translate(-743 -2674)' clip-path='url(%23clip-path)'%3E%3Cg id='b' transform='translate(743 2674)'%3E%3Cg id='c'%3E%3Cpath id='패스_326' data-name='패스 326' d='M0,0H18V18H0Z' fill='%23adb5bd' opacity='0' style='isolation: isolate'/%3E%3Cline id='선_11' data-name='선 11' x2='10.5' y2='10.5' transform='translate(3.75 3.75)' fill='none' stroke='%23212529' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2'/%3E%3Cline id='선_12' data-name='선 12' y1='10.5' x2='10.5' transform='translate(3.75 3.75)' fill='none' stroke='%23212529' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  width: 18px;
  height: 18px;
  vertical-align: middle;
  margin-top: 2px;
`;

const MemberTypeButtonWrap = styled.div`
  width: 50%;
  height: 101px;
  box-sizing: border-box;
  background-color: ${(props) => (props.active ? "#021633" : "#ffffff")};
  border-radius: 8px;
  border: 1px solid #021633;
  padding: 20px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
  cursor: pointer;
`;

const MemberTypeText = styled.div`
  color: ${(props) => (props.active ? "#ffffff" : "#021633")};
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 32px;
  transition: 0.5s;
`;

const MemberTypePriceText = styled.div`
  color: ${(props) => (props.active ? "#ffffff" : "#021633")};
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  line-height: 32px;
  transition: 0.5s;
`;

const MemberTypeInfoWrap = styled.div`
  width: 50%;
  height: 204px;
  padding: 20px;
  box-sizing: border-box;
  border: 1px solid #dde2ea;
  border-radius: 8px;
`;

const MemberTypeInfoTextWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const MemberTypeInfoText = styled.div`
  color: #212529;
  font-size: 14px;
  font-weight: 500;
  line-height: 32px;
`;

const MemberTypeWhether = styled.div`
  background-color: ${(props) =>
    props.impossibility ? "#EEF0F4" : "rgba(51, 216, 78, 0.1)"};
  width: 52px;
  height: 20px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => (props.impossibility ? "#868E96" : "#33d84e")};
  font-size: 12px;
  font-weight: 500;
  line-height: 32px;
`;

const CheckBoxWrap = styled.div`
  width: 100%;
  background-color: #f6f9ff;
  border-radius: 8px;
  padding: 20px 20px 4px 20px;
  margin-bottom: 80px;
`;

const CheckBoxBorder = styled.div`
  width: 100%;
  height: 2px;
  background-color: #dde2ea;
  margin: 4px 0px 20px;
`;

const ButtonWrap = styled.div`
  padding: 0px 275px;
  min-width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const RegexCheckText = styled.div`
  color: ${(props) =>
    props.error === null
      ? "#ADB5BD"
      : props.error === true
      ? "#0081F5"
      : "#FF383B"};
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-right: 12px;
  margin-left: 4px;
`;

export {
  Container,
  MainWrap,
  PageTypeText,
  JoinTypeText,
  JoinNoticeText,
  Title,
  RowWrap,
  InfoTitle,
  RegexText,
  Border,
  SubjectChoiceWrap,
  ChoiceBox,
  ChoiceText,
  CancleWrap,
  MemberTypeButtonWrap,
  MemberTypeText,
  MemberTypePriceText,
  MemberTypeInfoText,
  MemberTypeInfoWrap,
  MemberTypeInfoTextWrap,
  MemberTypeWhether,
  CheckBoxWrap,
  CheckBoxBorder,
  ButtonWrap,
  RegexCheckText,
  UrlText,
};
