import styled from "styled-components";
import { ReactComponent as Conference } from "../assets/images/conference.svg";
import { ReactComponent as Qna } from "../assets/images/qna.svg";
import { ReactComponent as Market } from "../assets/images/market.svg";
import { ReactComponent as Mypage } from "../assets/images/mypage.svg";
import { ReactComponent as Mentor } from "../assets/images/mentor.svg";
import { useNavigate } from "react-router-dom";
export const MobileBottomBarContainer = styled.div`
  width: 100%;
  height: 60px;
  position: fixed;
  bottom: 0px;
  z-index: 10;
  border-top: 1px solid #dde2ea;
  background-color: white;
  > div {
    display: flex;
    justify-content: space-around;

    height: 47px;
    figure {
      width: 78px;
      height: 100%;
      text-align: center;
      > img {
        margin-top: 6px;
      }
      > p {
        font-weight: 500;
        font-size: 10px;
        line-height: 17px;
        text-align: center;
        color: #868e96;
      }
    }
  }
`;

export const MobileBottomBarItemBox = styled.figure`
  width: 78px;
  height: 100%;
  text-align: center;
  > button {
    background-color: transparent;
    border: none;
    padding-top: 6px;
    > svg {
      path {
        fill: ${(props) => (props.focus ? "rgb(2, 22, 51)" : "")};
      }
    }
    > p {
      font-weight: 500;
      font-size: 10px;
      line-height: 17px;
      text-align: center;
      color: ${(props) => (props.focus ? "#021633" : "#868e96")};
    }
  }
`;

const MobileBottomBar = ({ activeTab }) => {
  const navigate = useNavigate();
  const menus = [
    { eng: "mentoPick", kor: "나의 멘토픽", tag: <Mentor /> },
    { eng: "conferenceRoom", kor: "컨퍼런스 룸", tag: <Conference /> },
    // { eng: "QnA", kor: "질의응답", tag: <Qna /> },
    { eng: "contentsMarket", kor: "컨텐츠 마켓", tag: <Market /> },
    { eng: "Mypage", kor: "마이페이지", tag: <Mypage /> },
  ];

  return (
    <MobileBottomBarContainer>
      <div>
        {menus.map((menu) => (
          <MobileBottomBarItemBox focus={activeTab === menu.eng}>
            <button
              onClick={() => {
                navigate(`/${menu.eng}`);
              }}
            >
              {menu.tag}
              <p>{menu.kor}</p>
            </button>
          </MobileBottomBarItemBox>
        ))}
      </div>
    </MobileBottomBarContainer>
  );
};

export default MobileBottomBar;
