import React from "react";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  overflow: auto;
`;

const MainWrap = styled.div`
  width: 536px;
  padding: 240px 0px;
  margin: 0 auto;
`;

const Title = styled.div`
  color: #212529;
  font-size: 28px;
  font-weight: 700;
  line-height: 32px;
  margin-bottom: 32px;
`;

const KakaoButtonWrap = styled.div`
  width: 100%;
  background-color: #fee500;
  padding: 18px 0px;
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const GoogleButtonWrap = styled.div`
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #adb5bd;
  padding: 18px 0px;
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-top: 16px;
`;

const ButtonText = styled.div`
  color: #212529;
  font-size: 16px;
  font-weight: 500;
  margin-left: 12px;
`;

const ModalTitle = styled.div`
  color: #212529;
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  margin: 32px 0px 12px;
  line-height: 50px;
`;

const ModalContent = styled.div`
  color: #212529;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  margin-bottom: 34px;
`;

const Content = styled.div`
  color: #212529;
  font-size: 14px;
  font-weight: 500;
  line-height: 32px;
  cursor: pointer;
`;

const RowWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
  padding-bottom: 32px;
  margin-bottom: 23px;
  border-bottom: 2px solid #e9ecef;
`;

export {
  Container,
  MainWrap,
  Title,
  KakaoButtonWrap,
  ButtonText,
  ModalTitle,
  ModalContent,
  Content,
  RowWrap,
  GoogleButtonWrap,
};
