import styled from "styled-components";
import { MobileContainer } from "../../login/mobile/login_mobile.style";

export const MobileSignUpHeader = styled.figure`
  position: fixed;
  display: flex;
  background-color: white;
  align-items: center;
  box-sizing: border-box;
  left: 50%;
  z-index: 1;
  transform: translateX(-50%);
  width: 100%;
  height: 56px;
  padding: 0 24px;
  border-bottom: 1px solid #dde2ea;
  > button {
    width: 24px;
    height: 24px;
    margin-right: 12px;
    padding: 0;
    border: none;
    background-color: transparent;
    img {
      width: 100%;
      height: 100%;
    }
  }

  > h2 {
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
  }
`;

export const MobileSignUpContainer = styled.div`
  padding-top: 56px;
  padding-bottom: 56px;
  > h1 {
    font-size: 20px;
    line-height: 30px;
    font-weight: 700;
    margin-top: 24px;
    color: #212529;
  }
`;

export const MobileDontKnowBtn = styled.button`
  padding: 16px;
  margin-bottom: 16px;
  width: 342px;
  height: 56px;
  background: #ffffff;
  border: 1px solid #dde2ea;
  border-radius: 4px;

  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  text-align: center;
  color: #212529;
`;

export const MobileShoolTilte = styled.div`
  box-sizing: border-box;
  background-color: #dde2ea;
  border-radius: 13px;
  padding: 4px 12px;
  display: inline-block;
  color: #021633;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  margin-bottom: 20px;
`;

export const HighScoolSearchText = styled.p`
  text-align: right;
  font-size: 13px;
  margin-right: 10px;
  margin-top: 20px;
  margin-bottom: 40px;
  text-overflow: ellipsis;
`;

export const UniverSearchText = styled(HighScoolSearchText)``;

export const MobileDocumentIdentity = styled.div`
  position: relative;
  width: 342px;
  box-sizing: border-box;
  background: #f8f9fa;
  border-radius: 4px;
  padding: 16px 16px 21px 16px;

  margin-top: 19px;
  > h2 {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: #212529;
  }
  > hr {
    margin: 0;
    margin-top: 12px;
    margin-bottom: 16px;
    border: none;
    width: 310px;
    height: 1px;
    background: #e9ecef;
  }
  > ul {
    li {
      color: #212529;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      list-style-position: inside;
      text-indent: -8px;
      padding-left: 10px;
      margin-bottom: 12px;
    }
  }
`;

export const MobileBtnWrapper = styled.div`
  width: 342px;
  height: 56px;
  position: fixed;
  bottom: 16px;
  left: 50%;
  transform: translateX(-50%);
  button {
    border: none;
    border-radius: 4px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 16px;
    background-color: #021633;
    color: white;
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
  }
`;

export const MobileTeacherNoticeContainer = styled(MobileContainer)`
  padding-top: 56px;

  h2 {
    margin-top: 24px;
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 26px;
  }

  > ul {
    li {
      width: 342px;
      border: 1px solid #dde2ea;
      padding: 16px;
      box-sizing: border-box;
      > div {
        margin-bottom: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 58px;
        height: 26px;
        background: #021633;
        border-radius: 13px;
        color: white;
        font-weight: 400;
        font-size: 12px;
        line-height: 17px;
      }
      > p {
        font-weight: 500;
        font-size: 16px;
        line-height: 23px;
        color: #212529;
      }

      :first-of-type {
        margin-bottom: 18px;
      }
    }
  }
`;
