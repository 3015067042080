import styled from "styled-components";

const Container = styled.div`
  width: 721px;
  margin: 0 auto;
  padding: 200px 0 264px 0;
  min-height: calc(100vh - 330px);
`;

const PageTitle = styled.div`
  color: #212529;
  font-size: 40px;
  font-weight: 700;
  line-height: 58px;
  margin-bottom: 12px;
`;

const PageSubTitle = styled.div`
  color: #212529;
  font-size: 20px;
  font-weight: 400;
  line-height: 29px;
  padding-bottom: 32px;
  margin-bottom: 80px;
  border-bottom: 2px solid #e9ecef;
`;

const ConditionText = styled.div`
  color: ${(props) => (props.active ? "#021633" : "#ADB5BD")};
  font-size: 20px;
  font-weight: ${(props) => (props.active ? 700 : 500)};
  line-height: 50px;
  cursor: pointer;
`;

const VerticalBorder = styled.div`
  background-color: #adb5bd;
  width: 1px;
  height: 16px;
  margin: 0px 20px;
`;

const BenefitWrap = styled.div`
  margin-bottom: 80px;
  cursor: pointer;
`;

const ImageWrap = styled.div`
  width: 100%;
  border-radius: 12px;
  background-color: #abc;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const BoardTitle = styled.div`
  color: #212529;
  margin-top: 20px;
  margin-bottom: 12px;
  line-height: 35px;
  font-size: 24px;
  font-weight: 700;
`;

const DateText = styled.div`
  color: #adb5bd;
  font-size: 20px;
  font-weight: 500;
  line-height: 29px;
`;

const RowWrap = styled.div`
  display: flex;
  align-items: center;
`;

const PaginationWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 160px;
`;

//notice detail

const DetailContainer = styled.div`
  width: 60vw;
  margin: 0 auto;
  padding: 200px 0 240px 0;
`;

const DetailWrap = styled.div`
  width: 100%;
`;

const DetailTitle = styled.div`
  color: #212529;
  font-size: 32px;
  font-weight: 700;
  line-height: 46px;
  margin: 12px 0px;
`;

const ContentMarketWriter = styled.div`
  color: #868e96;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.7rem;
  margin-bottom: 1rem;
`;

const DetailBody = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  padding-bottom: 2rem;
  margin-bottom: 2rem;
  border-bottom: 2px solid #e9ecef;
`;

const DetailImageWrap = styled.div`
  width: 50%;
  padding: 1rem;
  align-items: center;
  justify-content: center;
`;

const DetailContent = styled.div`
  width: 50%;
  height: 50vh;
  color: #212529;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  padding: 1.5rem 2rem;
  margin-left: 2rem;
  word-break: keep-all;
  border-left: 1px solid #e9ecef;
  white-space: pre-wrap;
`;


const Button = styled.div`
  min-width: 30%;
  height: 3rem;
  background-color: ${(props) => (props.gray ? "#DDE2EA" : "#021633")};
  color: ${(props) => (props.gray ? "#021633" : "#ffffff")};
  margin-right: ${(props) => (props.gray ? "16px" : "0px")};
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const MarketItemPrice = styled.div`
  background-color: "rgba(0,129,245,0.1)";
  color: "#33D84E";
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  height: 28px;
`;

export {
  Container,
  PageTitle,
  PageSubTitle,
  ConditionText,
  VerticalBorder,
  BenefitWrap,
  ImageWrap,
  Image,
  BoardTitle,
  DateText,
  RowWrap,
  PaginationWrap,
  DetailContainer,
  MarketItemPrice,
  DetailWrap,
  DetailTitle,
  DetailBody,
  DetailImageWrap,
  DetailContent,
  Button,
  ContentMarketWriter,
};
