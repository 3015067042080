import { useNavigate } from "react-router-dom";
import {useEffect, useState} from "react";

import headerLogo from "../assets/images/symbol.png";
import message from "../assets/images/message.png";
import icon from "../assets/images/icon.png";
import notification from "../assets/images/notification.png";
import styled from "styled-components";
import { ReactComponent as Back } from "../assets/images/prev.svg";
import { ReactComponent as Dot } from "../assets/images/dot.svg";
import Notification from "./Notification";
import Chat from "./Chat";
import axios from "axios";
import {API_URL} from "../API";
import {useRecoilState} from "recoil";
import userInfoState from "../states/userInfoState";

export const MobileMainHeader = styled.div`
  display: flex;
  position: fixed;
  justify-content: space-between;
  top: 0;
  width: 100%;
  height: 56px;
  background-color: white;
  z-index: 10;
  padding: 12px 24px;
  border-bottom: 1px solid #dde2ea;

  > figure {
    display: flex;

    img {
      width: 24px;
      height: 24px;
      margin-right: 12px;
      align-self: center;
    }
    h2 {
      width: 174px;
      font-weight: 700;
      font-size: 18px;
      line-height: 30px;

      color: #212529;
    }
  }

  > ul {
    display: flex;
    li {
      > button {
        background-color: transparent;
        border: none;
        padding: 0;
        width: 32px;
        height: 32px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      :not(:last-of-type) {
        margin-right: 12px;
      }
    }
  }
`;

export const MobileSubHeader = styled(MobileMainHeader)`
  figure {
    align-items: center;
    :first-of-type {
      > h2 {
        margin-left: 12px;
      }
    }
    > button {
      padding: 0;
      padding-top: 5px;
      border: none;
      background-color: transparent;
    }
    svg {
      path {
        fill: black;
      }
    }
  }
`;

const MobileHeader = ({ isDot, name, type, isModal, setModal, hideIcon = false }) => {
  const icons = [message, notification];
  const navigate = useNavigate();
  const [notificationShow, setNotificationShow] = useState(false);
  const [chatShow, setChatShow] = useState(false);
  const [userType, setUserType] = useState("");
  const [userInfo, setUserInfo] = useRecoilState(userInfoState);

  useEffect(() => {
    axios({
      url: `${API_URL}/mypage`,
      method: "GET",
    })
      .then((result) => {
        if (result.data.success === true) {
          setUserType(result.data.result.type);
          setUserInfo(result.data.result);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <>
      {type === "main" && (
        <MobileMainHeader>
          <figure>
            <img src={headerLogo} alt="mobile_main_header_logo" onClick={() => { navigate('/') }} />
            <h2>{name}</h2>
          </figure>
          <ul>
            {!hideIcon && icons.map((icon, id) => (
              <li
                style={{
                  display: id === 0 && name === "마이페이지" ? "none" : "",
                }}
                onClick={() => {
                  if (id === 0) {
                    setChatShow(!chatShow);
                  }

                  if (id === 1) {
                    setNotificationShow(!notificationShow);
                  }
                }}
              >
                <button>
                  <img src={icon} alt={`header_icon${id}`} />
                </button>
              </li>
            ))}
          </ul>
        </MobileMainHeader>
      )}
      {type === "sub" && (
        <MobileSubHeader>
          <figure>
            <button
              onClick={() => {
                if (isModal) setModal(!isModal);
                else navigate(-1);
              }}
            >
              <Back />
            </button>
            <h2>{name}</h2>
          </figure>
          {isDot && (
            <figure>
              <Dot />
            </figure>
          )}
        </MobileSubHeader>
      )}
      { notificationShow && <Notification /> }
      { chatShow && <Chat isMobile onMobileClosed={() => { setChatShow(false); }} /> }
    </>
  );
};

export default MobileHeader;
