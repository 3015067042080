import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  position: relative;
`;

const RowWrap = styled.div`
  display: flex;
`;

const MainWrap = styled.div`
  padding: 200px 0px 260px;
  width: 536px;
  margin: 0 auto;
`;

const FormWrap = styled.div`
  padding-bottom: 32px;
  margin-bottom: 32px;
  border-bottom: 2px solid #e9ecef;
`;

const Title = styled.div`
  color: #212529;
  font-size: 28px;
  font-weight: 700;
  line-height: 40px;
  margin-bottom: 32px;
`;

const PopupTitle = styled.div`
  color: #212529;
  font-size: 24px;
  line-height: 35px;
  font-weight: 700;
  margin-bottom: 12px;
`;

const PopupContent = styled.div`
  color: #212529;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 32px;
`;

const RegexCheckText = styled.div`
  color: ${(props) =>
    props.error === null
      ? "#ADB5BD"
      : props.error === true
      ? "#0081F5"
      : "#FF383B"};
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-right: 12px;
  margin-left: 4px;
`;

const PopupButton = styled.div`
  width: 100%;
  height: 80px;
  background-color: ${(props) => (props.active ? "#021633" : "#E9ECEF")};
  color: ${(props) => (props.active ? "#ffffff" : "#868E96")};
  font-size: 20px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const FindIdText = styled.span`
  color: ${(props) => (props.yellow ? "#FFAD2E" : "#212529")};
  font-size: 16px;
  font-weight: 400;
`;

export {
  Container,
  RowWrap,
  MainWrap,
  FormWrap,
  Title,
  PopupTitle,
  PopupContent,
  RegexCheckText,
  PopupButton,
  FindIdText,
};
