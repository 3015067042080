import React from "react";
import styled from "styled-components";

const Container = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60px;
  background-color: ${(props) =>
    // 회색
    props.disabled
      ? props.theme.color.grey
      : props.grey
      ? props.theme.color.grey
      : // 오렌지
      props.orange
      ? props.theme.color.orange
      : // 네이비
      props.active
      ? props.theme.color.primary
      : // 기본 흰색
        props.theme.color.white};
  border-radius: 8px;
  border-style: solid;
  border-width: ${(props) =>
    props.disabled
      ? "0px"
      : props.grey
      ? "0px"
      : // 오렌지
      props.orange
      ? "0px"
      : // 네이비
      props.active
      ? "0px"
      : // 기본 흰색
        "1px"};
  border-color: ${(props) =>
    props.borderColorRed ? props.theme.color.red : props.theme.color.primary};
  transition: 0.5s;
`;
const Title = styled.span`
  font-size: 16px;
  font-weight: 500;
  line-height: 32px;
  color: ${(props) =>
    props.disabled
      ? "#868E96"
      : props.grey
      ? props.theme.color.grey
      : props.active
      ? props.theme.color.white
      : props.orange
      ? props.theme.color.white
      : props.borderColorRed
      ? props.theme.color.red
      : props.theme.color.primary};
  transition: 0.5s;
`;

const Button = (props) => {
  return (
    <Container
      grey={props.grey}
      disabled={props.disabled}
      orange={props.orange}
      active={props.active}
      borderColorRed={props.borderColorRed}
      border={props.border}
      onClick={props.onClick}
      style={props.style}
    >
      <Title
        disabled={props.disabled}
        orange={props.orange}
        active={props.active}
        borderColorRed={props.borderColorRed}
      >
        {props.children}
      </Title>
    </Container>
  );
};

export default Button;
