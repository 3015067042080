import React, { useEffect, useState } from "react";

import {
  Container,
  RowWrap,
  ConditionText,
  VerticalBorder,
  PaymentListWrap,
  PaymentDateText,
  PaymentType,
  PaymentTitle,
  AccountText,
  PaymentPrice,
  EmptyText,
} from "../information/style";
import Button from "../../../../components/Button";
import CategoryTab from "../../../../components/CategoryTab";
import Pagination from "../../../../components/Pagination";
import runBootPay from "../../../../components/BootPay";
import axios from "axios";
import { API_URL } from "../../../../API";
import dayjs from "dayjs";
import { useRecoilValue } from "recoil";
import userInfoState from "../../../../states/userInfoState";

const PaymentList = ({
  order,
  setOrder,
  histories,
  page,
  setPage,
  totalPage,
  refetch,
}) => {
  const userInfo = useRecoilValue(userInfoState);

  useEffect(() => {
    if (order === "desc") {
      histories.sort((a, b) => {
        return dayjs(b.createdAt).diff(dayjs(a.createdAt));
      });
    } else {
      histories.sort((a, b) => {
        return dayjs(a.createdAt).diff(dayjs(b.createdAt));
      });
    }
    console.log("HISTORIES: ", order, histories);
  }, [order]);

  const payment = (id, receiptId) => {
    axios({
      method: "POST",
      url: `${API_URL}/mypage/otRequest/${id}/payment?receiptId=${receiptId}`,
    }).then((res) => {
      if (res.data.success) {
        alert("결제가 완료되었습니다!");
        refetch();
      } else {
        alert(res.data.error.message);
      }
    });
  };
  return (
    <>
      <RowWrap
        style={{ alignItems: "center", marginTop: 48, marginBottom: 32 }}
      >
        <ConditionText
          onClick={() => {
            setOrder("desc");
          }}
          active={order === "desc"}
        >
          최신순
        </ConditionText>
        <VerticalBorder />
        <ConditionText
          onClick={() => {
            setOrder("asc");
          }}
          active={order === "asc"}
        >
          오래된 순
        </ConditionText>
      </RowWrap>
      {histories.length > 0 ? (
        <>
          {histories.map((item, idx) => (
            <PaymentListWrap key={item.id} last={histories.length === idx + 1}>
              <RowWrap>
                <PaymentDateText>
                  {dayjs(item.createdAt).format("YYYY년 MM월 DD일")}
                </PaymentDateText>
                <div>
                  <PaymentType>{item.type}</PaymentType>
                  <PaymentTitle>{item.title}</PaymentTitle>
                  <AccountText>{item.description}</AccountText>
                </div>
              </RowWrap>
              <div>
                <PaymentPrice>{item.amount}원</PaymentPrice>
                {item.teacher && (
                  <Button
                    style={{ borderRadius: 12, marginTop: 20, height: 40 }}
                    active
                    onClick={() => {
                      runBootPay(
                        item.amount,
                        `영재멘토 수업료 (${item.teacher})`,
                        `영재멘토 수업료 (${item.teacher})`,
                        userInfo.nickname,
                        userInfo.email,
                        "",
                        userInfo.phoneNumber,
                        () => {},
                        (receiptId) => {
                          payment(item.id, receiptId);
                        }
                      );
                    }}
                  >
                    결제하기
                  </Button>
                )}
              </div>
            </PaymentListWrap>
          ))}
          <div style={{ marginTop: 128 }}>
            <Pagination
              page={page}
              totalItemsCount={totalPage * 10}
              showItemsCount={10}
              nowPage={setPage}
            />
          </div>
        </>
      ) : (
        <EmptyText>내역이 없습니다.</EmptyText>
      )}
    </>
  );
};

const StudentPayment = () => {
  const [order, setOrder] = useState("desc");
  const [histories, setHistories] = useState([]);
  const [totalHistories, setTotalHistories] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [otRequests, setOtRequests] = useState([]);

  const getData = () => {
    axios({
      method: "GET",
      url: `${API_URL}/mypage/payments?order=${order}&page=${page}`,
    }).then((res) => {
      if (res.data.success) {
        setHistories(res.data.result.paymentHistories);
        setTotalPage(res.data.result.totalPage);
      }
    });

    axios({
      method: "GET",
      url: `${API_URL}/mypage/otRequests`,
    }).then(async (res) => {
      if (res.data.success) {
        let results = await res.data.result.otRequests
          .filter(
            (item) => item.paymented === false && item.paymentRequested === true
          )
          .sort((a, b) => b.updatedAt - a.updatedAt);
        setOtRequests(
          results.map((item) => {
            return {
              id: item.id,
              title: `수업료 결제 요청`,
              description: `${item.mentopick.teacher.nickname}님의 수업료 결제 요청`,
              amount: item.mentopickClass.price,
              type: "결제 요청",
              createdAt: item.createdAt,
              updatedAt: item.updatedAt,
              teacher: item.mentopick.teacher.nickname,
            };
          })
        );
      }
    });
  };

  useEffect(() => {
    setTotalHistories([...otRequests, ...histories]);
  }, [otRequests, histories]);

  useEffect(() => {
    if (order && page) {
      window.scrollTo(0, 0);
      getData();
    }
  }, [order, page]);
  return (
    <Container>
      <CategoryTab
        array={["전체 내역", "결제 완료 내역", "받은 결제 요청"]}
        contents={[
          <PaymentList
            histories={totalHistories}
            page={page}
            totalPage={totalPage}
            order={order}
            setOrder={setOrder}
            refetch={getData}
          />,

          <PaymentList
            histories={histories}
            page={page}
            totalPage={totalPage}
            order={order}
            setOrder={setOrder}
            refetch={getData}
          />,
          <PaymentList
            histories={otRequests}
            page={page}
            totalPage={totalPage}
            order={order}
            setOrder={setOrder}
            isRequest={true}
            refetch={getData}
          />,
        ]}
      />
    </Container>
  );
};

export default StudentPayment;
