import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Pagination } from "swiper";
import "swiper/css";
import { API_URL } from "../../../API";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import { Desktop, Mobile } from "../../../utils/mediaQuery";

const Container = styled.div`
  width: 100%;
  padding-top: 20px;
`;

const ContentsTitle = styled.h4`
  font-size: 24px;
  font-weight: ${(props) => props.theme.fontWeight.bold};
  color: ${(props) => props.theme.color.textColor};
  margin-bottom: ${(props) => (props.marinBottom ? "3px" : "19px")};
  height: 36px;
`;
const ContentsTitleWrap = styled.div`
  display: flex;
`;
const ContentsText = styled.span`
  font-size: ${(props) => (props.small ? "16px" : "20px")};
  font-weight: ${(props) => props.theme.fontWeight.medium};
  color: ${(props) => props.theme.color.textColor};
  line-height: 29px;
  max-width: 260px;
  white-space: pre-wrap;
`;

const ContentsWrap = styled.div`
  margin-bottom: 80px;
`;
const ProfileBadge = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 98px;
  height: 32px;
  border-radius: 8px;
  background-color: ${(props) =>
    props.school ? "#DDE2EA" : "rgba(51, 216, 78, 0.1)"};
  color: ${(props) => (props.school ? props.theme.color.primary : "#33D84E")};
`;

const IntroduceBlock = styled.div`
  width: 100%;
  min-height: 93px;
  padding: 32px;
  border-radius: 12px;
  border: 1px solid #dde2ea;
`;

const EducationWrap = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;
const EducationBlock = styled.div`
  width: 398px;
  height: 177px;
  padding: 32px;
  border-radius: 12px;
  border: 1px solid #dde2ea;
  margin-right: 16px;
  margin-top: 16px;
  :nth-child(2n) {
    margin-right: 0px;
  }
`;
const EducationBadgeWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
`;
const EducationTextWrap = styled.div`
  display: flex;
  margin-bottom: 10px;
  align-items: center;
`;
const EducationBadge = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 46px;
  height: 24px;
  border-radius: 4px;
  background-color: rgba(99, 55, 252, 0.1);
  color: #6337fc;
  padding: 0 12px;
  margin-left: 8px;
`;

const RecordWrap = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;
const RecordBlock = styled.div`
  width: 398px;
  min-height: 131px;
  padding: 32px;
  border-radius: 12px;
  border: 1px solid #dde2ea;
  margin-right: 16px;
  margin-top: 16px;
  :nth-child(2n) {
    margin-right: 0px;
  }
`;

const AbledTutoringBadge = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  background-color: rgba(99, 55, 252, 0.1);
  color: #6337fc;
  height: 32px;
  padding: 0 20px;
  margin-left: 12px;
`;
const AbledTutoringBlcok = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 32px 32px 16px 32px;
  border-radius: 12px;
  border: 1px solid #dde2ea;
`;
const TutoringBadge = styled.div`
  width: fit-content;
  min-height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eef0f4;
  font-size: 16px;
  font-weight: ${(props) => props.theme.fontWeight.medium};
  color: ${(props) => props.theme.color.primary};
  border-radius: 8px;
  padding: 9px 20px 11px 20px;
  margin-right: 16px;
  margin-bottom: 16px;
`;

const ClassMethodWrap = styled(IntroduceBlock)``;

const ClassMethodBadgeWrap = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 16px;
`;
const ClassMethodBadge = styled.button`
  width: fit-content;
  height: 44px;
  padding: 9px 20px 11px 20px;
  border: none;
  border-radius: 22px;
  background-color: ${(props) =>
    props.active ? props.theme.color.primary : "#ffffff"};
  color: ${(props) => (props.active ? "#ffffff" : props.theme.color.primary)};
  border: 1px solid ${(props) => props.theme.color.primary};
  margin-right: 16px;
  margin-bottom: 16px;
  box-sizing: border-box;
  transition: 0.5s;
`;
const ClassDescText = styled.p`
  font-size: 16px;
  font-weight: ${(props) => props.theme.fontWeight.regular};
  color: ${(props) => props.theme.color.textColor};
  line-height: 28px;
`;

/****************모바일스타일**************/

const MobileProfileDetailContainer = styled.div`
  display: block !important;
  padding-bottom: 120px;
  > label {
    margin-bottom: 25px;
    display: block;
    > h2 {
      font-size: 16px;
    }
    > div {
      padding: 24px 16px;
      background: #ffffff;
      border: 1px solid #dde2ea;
      border-radius: 4px;
      margin-top: 16px;
    }
    :nth-of-type(1) {
      > div {
        > p {
          font-weight: 500;
          font-size: 16px;
          line-height: 23px;
          color: #212529;
        }
      }
    }
  }
`;
const EducationWrapper = styled.label`
  > div {
    div {
      background: #e9ecef;
      padding: 4px 12px;
      width: max-content;
      height: 26px;
      border-radius: 13px;
      font-weight: 500;
      font-size: 12px;
      margin-bottom: 12px;
      line-height: 17px;
    }
    h3 {
      display: flex;
      p {
        font-weight: 500;
        font-size: 16px;
        line-height: 23px;
        color: #212529;
      }
      span {
        display: block;
        background: #efebff;
        border-radius: 13px;
        padding: 4px 12px;
        margin-left: 8px;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 17px;
        color: #6337fc;
      }
    }
    > p {
      font-size: 14px;
      line-height: 20px;
      color: #505860;
      margin-top: 10px;
    }
  }
`;
const CareersWrapper = styled.label`
  > div {
    display: flex;
    div {
      :nth-of-type(1) {
        color: #212529;
        h2 {
          font-weight: 500;
          font-size: 16px;
          line-height: 23px;
        }
        p {
          margin-top: 10px;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
        }
      }
      :nth-of-type(2) {
        white-space: pre;
        width: auto;
        height: 26px;
        padding: 4px 12px;
        background: #ebfbed;
        border-radius: 13px;
        font-weight: 500;
        font-size: 12px;
        line-height: 17px;
        color: #33d84e;
      }
    }
  }
`;
const TutoringBadgeMobile = styled(TutoringBadge)`
  font-size: 14px;
  width: max-content !important;
  padding: 8px 12px;
  min-height: 38px;
`;
const ClassMethodMobile = styled.div`
  > figure {
    margin: 16px 0;

    .swiper {
      position: relative;
      left: 50%;
      transform: translateX(-50%);
    }
    .swiper-slide {
      width: max-content !important;

      padding: 0;
      button {
        border: none;
        background-color: transparent;
        padding: 8px 12px;
        font-size: 16px;
        font-weight: 500;
        line-height: 23px;
        color: #212529;
      }
    }
  }
  > div {
    padding: 24px 16px;
    background: #ffffff;
    border: 1px solid #dde2ea;
    border-radius: 4px;
    margin-top: 16px;
    margin-bottom: 24px;
  }
`;

/***************************************/

const ProfileDetail = () => {
  const { profileId } = useParams();
  const [toggleClassDesc, setToggleClassDesc] = useState([]);
  const [data, setData] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [ClassDesc, setClassDesc] = useState(null);
  const [selectClassId, setSelectClassId] = useState(0);

  const toggleClass = (id, desc) => {
    setToggleClassDesc([]);
    setToggleClassDesc([id]);
    setClassDesc(desc);
  };

  useEffect(() => {
    axios({
      url: `${API_URL}/mentopick/${profileId}/profile`,
      method: "GET",
    })
      .then((result) => {
        if (result.data.success === true) {
          setData(result.data.result);

          if (result.data.result.subjects[0]) {
            setToggleClassDesc([result.data.result.subjects[0].id]);
          }

          setSubjects(
            result.data.result.subjects.map((item) => {
              return {
                id: item.id,
                class: `${item.subject.type}(${item.subject.level})${
                  item.subject.name !== null && item.subject.progress !== null
                    ? ` - ${item.subject.name}(${item.subject.progress})`
                    : ""
                }`,
                desc: item.contents,
              };
            })
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (subjects[0]) {
      setClassDesc(subjects[0].desc);
    }
  }, [subjects]);

  const formatType = (type) => {
    switch (type) {
      case "UNIVERSITY":
        return "대학교";
      case "HIGHSCHOOL":
        return "고등학교";
      default:
        return "영재원";
    }
  };

  return (
    <>
      <Desktop>
        <Container>
          <ContentsWrap>
            <ContentsTitle>선생님 소개</ContentsTitle>
            <IntroduceBlock>
              <ContentsText>{data.description}</ContentsText>
            </IntroduceBlock>
          </ContentsWrap>

          <ContentsWrap>
            <ContentsTitle marinBottom>학력</ContentsTitle>

            <EducationWrap>
              {data.educations &&
                data.educations.map((item) =>
                  item.institute.type === "UNIVERSITY" ? (
                    <EducationBlock key={item.id}>
                      <EducationBadgeWrap>
                        <ProfileBadge school>대학교</ProfileBadge>
                        <ProfileBadge>인증완료</ProfileBadge>
                      </EducationBadgeWrap>

                      <EducationTextWrap>
                        <ContentsText>{item.institute.name}</ContentsText>
                        <EducationBadge>{item.status}</EducationBadge>
                      </EducationTextWrap>
                      <ContentsText
                        small
                      >{`${item.undergrad} ${item.institute.major}`}</ContentsText>
                    </EducationBlock>
                  ) : (
                    <EducationBlock key={item.id}>
                      <EducationBadgeWrap>
                        <ProfileBadge school>
                          {item.institute.type === "ACADEMY"
                            ? "영재원"
                            : "고등학교"}
                        </ProfileBadge>
                        <ProfileBadge>인증완료</ProfileBadge>
                      </EducationBadgeWrap>

                      <EducationTextWrap>
                        <ContentsText>{item.institute.name}</ContentsText>
                        <EducationBadge>졸업</EducationBadge>
                      </EducationTextWrap>
                    </EducationBlock>
                  )
                )}
            </EducationWrap>
          </ContentsWrap>

          <ContentsWrap>
            <ContentsTitle marinBottom>이력</ContentsTitle>
            <RecordWrap>
              {data.careers &&
                data.careers.map((item) => (
                  <RecordBlock key={item.id}>
                    <EducationBadgeWrap>
                      <ContentsText>{item.name}</ContentsText>
                      <ProfileBadge>인증완료</ProfileBadge>
                    </EducationBadgeWrap>
                    <ContentsText small>
                      {item.endDate === null
                        ? dayjs(item.startDate).format("YYYY.MM")
                        : `${dayjs(item.startDate).format("YYYY.MM")} ~ ${dayjs(
                            item.endDate
                          ).format("YYYY.MM")}`}
                    </ContentsText>
                  </RecordBlock>
                ))}
            </RecordWrap>
          </ContentsWrap>

          <ContentsWrap>
            <ContentsTitleWrap>
              <ContentsTitle>과외 가능 과목</ContentsTitle>
              <AbledTutoringBadge>
                {data.subjects && data.subjects.length}개
              </AbledTutoringBadge>
            </ContentsTitleWrap>

            <AbledTutoringBlcok>
              {data.subjects &&
                data.subjects.map((item) =>
                  item.subject.type === "국내" ? (
                    <TutoringBadge
                      key={item.id}
                    >{`${item.subject.type}(${item.subject.level}) - ${item.subject.name}(${item.subject.progress})`}</TutoringBadge>
                  ) : (
                    <TutoringBadge
                      key={item.id}
                    >{`${item.subject.type} - ${item.subject.level}`}</TutoringBadge>
                  )
                )}
            </AbledTutoringBlcok>
          </ContentsWrap>

          <ContentsWrap>
            <ContentsTitle>과목별 수업방식</ContentsTitle>
            <ClassMethodBadgeWrap>
              {subjects.map((arr, index) => {
                return (
                  <div key={index}>
                    <ClassMethodBadge
                      active={toggleClassDesc.includes(arr.id) ? true : false}
                      onClick={() => {
                        toggleClass(arr.id, arr.desc);
                      }}
                    >
                      {arr.class}
                    </ClassMethodBadge>
                  </div>
                );
              })}
            </ClassMethodBadgeWrap>
            <ClassMethodWrap>
              <ClassDescText>{ClassDesc}</ClassDescText>
            </ClassMethodWrap>
          </ContentsWrap>

          <ContentsWrap>
            <ContentsTitle>거주지</ContentsTitle>
            <IntroduceBlock>
              <ContentsText>{data.location}</ContentsText>
            </IntroduceBlock>
          </ContentsWrap>
        </Container>
      </Desktop>
      <Mobile>
        <MobileProfileDetailContainer>
          <label>
            <h2>선생님 소개</h2>
            <div>
              <p>{data.description}</p>
            </div>
          </label>

          <EducationWrapper>
            <h2>학력</h2>{" "}
            {data.educations &&
              data.educations
                .slice(0)
                .reverse()
                .map((el) => (
                  <div>
                    <div>{formatType(el.institute.type)}</div>
                    <h3>
                      <p>{el.institute.name}</p>
                      {el.status !== null && <span>{el.status}</span>}
                    </h3>
                    {el.institute.type === "UNIVERSITY" && (
                      <p>{el.institute.major}</p>
                    )}
                  </div>
                ))}
          </EducationWrapper>
          <CareersWrapper>
            <h2>이력</h2>
            {data.careers &&
              data.careers?.map((el) => (
                <div>
                  <div>
                    <h2>{el.name}</h2>
                    <p>
                      {el.endDate === null
                        ? dayjs(el.startDate).format("YYYY.MM")
                        : `${dayjs(el.startDate).format("YYYY.MM")} ~ ${dayjs(
                            el.endDate
                          ).format("YYYY.MM")}`}
                    </p>
                  </div>
                  <div>{el.verified ? "인증완료" : "인증필요"}</div>
                </div>
              ))}
          </CareersWrapper>
          <label>
            <h2>과외 가능 과목</h2>
            <p
              style={{
                fontSize: 14,
                fontWeight: 400,
                color: "#868E96",
                lineHeight: "20px",
                marginTop: 8,
              }}
            >
              총{data.subjects && data.subjects.length}개
            </p>
            <div style={{ padding: "16px 16px 0px 16px" }}>
              {data.subjects &&
                data.subjects.map((item) =>
                  item.subject.type === "국내" ? (
                    <TutoringBadgeMobile
                      key={item.id}
                    >{`${item.subject.type}(${item.subject.level}) - ${item.subject.name}(${item.subject.progress})`}</TutoringBadgeMobile>
                  ) : (
                    <TutoringBadgeMobile
                      key={item.id}
                    >{`${item.subject.type} - ${item.subject.level}`}</TutoringBadgeMobile>
                  )
                )}
            </div>
          </label>
          <ClassMethodMobile>
            <h2>과목별 수업 방식</h2>
            <figure>
              <Swiper
                spaceBetween={8}
                modules={[FreeMode, Pagination]}
                slidesPerView={"auto"}
                freeMode={true}
              >
                {data.subjects &&
                  data.subjects.map((item, idx) =>
                    item.subject.type === "국내" ? (
                      <SwiperSlide key={item.id}>
                        <button
                          style={{
                            color: idx === selectClassId ? "white" : "",
                            backgroundColor:
                              idx === selectClassId ? "#021633" : "",
                          }}
                          onClick={() => {
                            setSelectClassId(idx);
                          }}
                        >
                          {`${item.subject.type}(${item.subject.level}) - ${item.subject.name}(${item.subject.progress})`}
                        </button>
                      </SwiperSlide>
                    ) : (
                      <SwiperSlide key={item.id}>
                        <button>
                          {`${item.subject.type} - ${item.subject.level}`}
                        </button>
                      </SwiperSlide>
                    )
                  )}
              </Swiper>
            </figure>
            <div>
              <p>test</p>
            </div>
          </ClassMethodMobile>
          <label>
            <h2>거주지</h2>
            <div>
              <p>현재 {data.location} 거주 중 입니다.</p>
            </div>
          </label>
        </MobileProfileDetailContainer>
      </Mobile>
    </>
  );
};

export default ProfileDetail;
