// import {DefalutTheme} from "styled-components"

const theme = {
  color: {
    primary: "#021633",
    white: "#ffffff",
    orange: "#FFAD2E",
    orangeOpacity: "rgba(255, 173, 46, 01)",
    textColor: "#212529",
    blue: "#0081F5",
    red: "#FF383B",
    grey: "#DDE2EA",
    lightGrey: "#E9ECEF",
  },
  fontWeight: {
    regular: "400",
    medium: "500",
    bold: "700",
  },
  shadow: {
    shadow: "rgba(2, 22, 51, 0.3)",
    // offset: [0, 4],
    // distance: 20
  },
};

export default theme;
