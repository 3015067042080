import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import { API_URL } from "../API";

const InputContainer = styled.div`
  width: 100%;
  height: 60px;
  border-radius: 8px;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23333' stroke-width='4' stroke-dasharray='4%2c10 ' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e");
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
`;

const FileInput = styled.input`
  position: absolute;
  width: 0;
  height: 0;
  padding: 0;
  overflow: hidden;
  border: 0;
`;

const FileLabel = styled.label`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
`;

const PlusIcon = styled.div`
  width: 32px;
  height: 32px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='32' height='32' viewBox='0 0 32 32'%3E%3Cdefs%3E%3CclipPath id='clip-path'%3E%3Crect id='사각형_273' data-name='사각형 273' width='32' height='32' transform='translate(944 4070)' fill='%23dee2e6'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg id='마스크_그룹_83' data-name='마스크 그룹 83' transform='translate(-944 -4070)' clip-path='url(%23clip-path)'%3E%3Cg id='b' transform='translate(944 4070)'%3E%3Cg id='c'%3E%3Cpath id='패스_317' data-name='패스 317' d='M0,0H32V32H0Z' fill='%23adb5bd' opacity='0' style='isolation: isolate'/%3E%3Cline id='선_9' data-name='선 9' y2='21.333' transform='translate(16 5.333)' fill='none' stroke='%23021633' stroke-linecap='round' stroke-miterlimit='10' stroke-width='3'/%3E%3Cline id='선_10' data-name='선 10' x2='21.333' transform='translate(5.333 16)' fill='none' stroke='%23021633' stroke-linecap='round' stroke-miterlimit='10' stroke-width='3'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
`;

const FileList = styled.div`
  width: 100%;
  height: 60px;
  padding: 0px 20px;
  box-sizing: border-box;
  border-radius: 8px;
  border: 1px solid #021633;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`;

const CancelButton = styled.div`
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='24' height='24' viewBox='0 0 24 24'%3E%3Cdefs%3E%3CclipPath id='clip-path'%3E%3Crect id='사각형_3803' data-name='사각형 3803' width='24' height='24' transform='translate(1050 1250)' fill='%23fff'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg id='마스크_그룹_175' data-name='마스크 그룹 175' transform='translate(-1050 -1250)' clip-path='url(%23clip-path)'%3E%3Cg id='b' transform='translate(1050 1250)'%3E%3Cg id='c'%3E%3Cpath id='패스_598' data-name='패스 598' d='M0,0H24V24H0Z' fill='%23adb5bd' opacity='0' style='isolation: isolate'/%3E%3Cpath id='선_23' data-name='선 23' d='M14,15a1,1,0,0,1-.707-.293l-14-14a1,1,0,0,1,0-1.414,1,1,0,0,1,1.414,0l14,14A1,1,0,0,1,14,15Z' transform='translate(5 5)' fill='%23212529'/%3E%3Cpath id='선_24' data-name='선 24' d='M0,15a1,1,0,0,1-.707-.293,1,1,0,0,1,0-1.414l14-14a1,1,0,0,1,1.414,0,1,1,0,0,1,0,1.414l-14,14A1,1,0,0,1,0,15Z' transform='translate(5 5)' fill='%23212529'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
  width: 24px;
  height: 24px;
  cursor: pointer;
`;
const FileUploadBox = ({ onChange, style }) => {
  const [files, setFiles] = useState([]);
  const [originalFiles, setOriginalFiles] = useState([]);

  useEffect(() => {
    if (originalFiles && originalFiles.length) {
      Promise.all(
        Array.from(originalFiles).map(async (item) => {
          const formData = new FormData();
          formData.append("file", item);
          const result = await axios.post(`${API_URL}/file/upload`, formData);
          if (result) {
            return {
              url: result.data.result.url,
              name: item.name,
            };
          }
        })
      ).then((res) => {
        setFiles([...files, ...res]);
      });
    }
  }, [originalFiles]);

  useEffect(() => {
    onChange(files);
  }, [files]);

  return (
    <>
      <InputContainer>
        <FileLabel htmlFor="file" />
        <FileInput
          type="file"
          id="file"
          multiple="multiple"
          accept=".jpg, .png, .pdf"
          onChange={(e) => {
            setOriginalFiles(e.target.files);
          }}
        />
        <PlusIcon />
      </InputContainer>
      {files.length > 0 &&
        files.map((item, idx) => (
          <FileList key={idx}>
            <p style={style}>{item.name}</p>
            <CancelButton
              onClick={() => {
                setFiles(files.filter((fileItem) => item !== fileItem));
              }}
            />
          </FileList>
        ))}
    </>
  );
};

export default FileUploadBox;
