import styled from "styled-components";

const PopupBackground = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(33, 37, 41, 0.8);
  z-index: 900;
  position: fixed;
  top: 0px;
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PopupContainer = styled.div`
  width: ${(props) => (props.width ? props.width : "536px")};
  height:${(props) => (props.height ? props.height : "auto")};
  background-color: #ffffff;
  overflow-x: hidden;
  border-radius: 12px;
  position: relative;
  padding: 48px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  @media screen and (max-width: 1280px) {
    overflow-y: scroll;
    max-height: 700px;
  }

  @media (max-width: 1080px) {
    overflow-y: scroll;
    height: 100vh;
    max-height: 100vh;
    border-radius: 0;
  }
`;

const CloseButtonWrap = styled.div`
  position: absolute;
  top: 24px;
  right: 24px;
  cursor: pointer;
  width: 44px;
  height: 44px;
`;

export { PopupBackground, PopupContainer, CloseButtonWrap };
