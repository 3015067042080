import { atom } from "recoil";

import { localStorageEffect } from "./tokenState";

const userInfoState = atom({
  key: "userInfoState",
  default: null,
  effects_UNSTABLE: [localStorageEffect("userInfoState")],
});

export default userInfoState;
