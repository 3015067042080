import styled from "styled-components";
import { MobileContainer } from "../login/mobile/login_mobile.style";
import { FormWrap, Title } from "./style";

export const FindIdPwContainer = styled(MobileContainer)`
  padding-top: 56px;
`;

export const MobileFormWrap = styled(FormWrap)`
  margin-top: 24px;
`;
export const MobileTitle = styled(Title)`
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 25px;
`;
