import React, { useState, useEffect } from "react";
import styled from "styled-components";

import NaverBlog from '../assets/icons/naver_blog.svg';
import Band from "../assets/icons/naver_band.svg";
import Youtube from "../assets/icons/youtube.svg";
import KaKao from "../assets/icons/kakao.svg";
import PlayStore from "../assets/icons/play_store.svg";
import AppStore from "../assets/icons/app_store.svg";
import axios from "axios";
import { API_URL } from "../API";

const Container = styled.div`
  width: 100%;
  min-width: 1260px;
  height: 374px;
  background-color: ${(props) => props.theme.color.primary};
  padding: 80px 140px;
  display: flex;
  @media screen and (max-width: 1280px) {
    padding: 60px 100px;
  }
`;
const ContentsWrap = styled.div`
  width: ${(props) =>
    props.shotFooter ? "300px" : props.longFooter ? "700px" : "400px"};
  // min-width: 400px;
  margin-right: ${(props) => (props.marginRight ? "20px" : null)};
  border-top: solid 2px #ffffff;
  color: ${(props) => props.theme.color.white};
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props.flexEnd ? "flex-end" : "flex-start")};
  justify-content: ${(props) => (props.flexEnd ? "flex-end" : "flex-start")};
`;
const FooterTitle = styled.div`
  font-size: 16px;
  font-weight: ${(props) => props.theme.fontWeight.bold};
  margin-bottom: 16px;
`;
const FooterContents = styled.div`
font-size: 16px;
font-weight: ${(props) => props.theme.fontWeight.regular}
  margin-bottom: ${(props) => (props.marginBottom ? "20px" : null)};
  line-height: 28px;

  @media screen and (max-width: 1280px) {
    font-size: 13px;
  }
`;
const IconWrap = styled.div`
  display: flex;
  margin-bottom: ${(props) => (props.marginBottom ? "20px" : null)};
  align-items: center;

  span {
    display: block;
    margin-left: 8px;
    cursor: pointer;
  }
`;
const KaKaoWrap = styled.a`
  width: 178px;
  height: 36px;
  background-color: #fee500;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-top: 20px;
  text-decoration: none;

  span {
    color: ${(props) => props.theme.color.textColor};
    font-size: 12px;
    font-weight: ${(props) => props.theme.fontWeight.bold};
    margin-left: 8px;
  }
`;
const IconImgWrap = styled.img`
  cursor: pointer;
  margin-right: ${(props) => (props.marginRight ? "16px" : null)};
  margin-left: ${(props) => (props.marginLeft ? "20px" : null)};
`;

const LinkWrap = styled.a`
  display: flex;
  color: #ffffff;
  text-decoration: none;
  align-items: center;
`;

const TermsWrap = styled.div`
  display: flex;
  margin-top: 20px;
  height: 24px;
`;
const Terms = styled.div`
  font-size: 20px;
  font-weight: ${(props) => props.theme.fontWeight.bold};
  color: #ffffff;
  cursor: pointer;
  :first-child {
    margin-right: 40px;
  }
  @media screen and (max-width: 1280px) {
    font-size: 19px;
  }
`;

const Footer = () => {
  const [footerTerms, setFooterTerms] = useState([]);
  useEffect(() => {
    axios({
      url: `${API_URL}/terms`,
      method: "GET",
    })
      .then((result) => {
        if (result.data.success) {
          setFooterTerms(result.data.result.terms.slice(0, 2));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <Container>
      <ContentsWrap marginRight>
        <FooterTitle>고객센터</FooterTitle>
        <FooterContents>
          알바트로스에듀는 카카오톡 플러스친구를 통하여
          <br />
          고객센터를 운영하고 있습니다.
        </FooterContents>
        <KaKaoWrap
          target="_blank"
          href="http://pf.kakao.com/_bxdWgb/chat"
        >
          <IconImgWrap src={KaKao} width="17px" height="17px" />
          <span>카카오톡으로 문의하기</span>
        </KaKaoWrap>
      </ContentsWrap>

      {/* <ContentsWrap marginRight>
        <FooterTitle>모바일 앱</FooterTitle>
        <IconWrap>
          <LinkWrap target="_blank" href="https://alba-edu.s3.ap-northeast-2.amazonaws.com/giftedmentor.apk">
            <IconImgWrap src={PlayStore} />
            <span>안드로이드 APK</span>
          </LinkWrap>
          <LinkWrap target="_blank" href="https://testflight.apple.com/join/vZRmwoz2">
            <IconImgWrap src={AppStore} marginLeft />
            <span>iOS TestFlight</span>
          </LinkWrap>
        </IconWrap>
        <IconWrap style={{ marginTop: 16 }}>
          <LinkWrap target="_blank" href="https://alba-edu.s3.ap-northeast-2.amazonaws.com/%E1%84%8B%E1%85%A7%E1%86%BC%E1%84%8C%E1%85%A2%E1%84%86%E1%85%A6%E1%86%AB%E1%84%90%E1%85%A9+%E1%84%8B%E1%85%B5%E1%84%8B%E1%85%AD%E1%86%BC%E1%84%8B%E1%85%A3%E1%86%A8%E1%84%80%E1%85%AA%E1%86%AB.pdf" style={{ marginRight: 16 }}>
            이용약관
          </LinkWrap>
          <LinkWrap target="_blank" href="https://alba-edu.s3.ap-northeast-2.amazonaws.com/%E1%84%8B%E1%85%A7%E1%86%BC%E1%84%8C%E1%85%A2%E1%84%86%E1%85%A6%E1%86%AB%E1%84%90%E1%85%A9+%E1%84%80%E1%85%A2%E1%84%8B%E1%85%B5%E1%86%AB%E1%84%8C%E1%85%A5%E1%86%BC%E1%84%87%E1%85%A9%E1%84%8E%E1%85%A5%E1%84%85%E1%85%B5%E1%84%87%E1%85%A1%E1%86%BC%E1%84%8E%E1%85%B5%E1%86%B7.pdf">
            개인정보처리방침
          </LinkWrap>
        </IconWrap>
      </ContentsWrap> */}

      <ContentsWrap longFooter>
        <FooterTitle>(주) 알바트로스에듀</FooterTitle>
        <FooterContents>
          사업자등록번호 : 335-88-02519 | 대표 이동희 | 통신판매업신고번호: 2022-서울종로-1120호
          <br />
          서울특별시 종로구 구기동 비봉2길 86-1 2층 | 02-536-5862
        </FooterContents>
        <IconWrap style={{ marginTop: 16 }}>
          <LinkWrap target="_blank" href="https://alba-edu.s3.ap-northeast-2.amazonaws.com/%E1%84%8B%E1%85%A7%E1%86%BC%E1%84%8C%E1%85%A2%E1%84%86%E1%85%A6%E1%86%AB%E1%84%90%E1%85%A9+%E1%84%8B%E1%85%B5%E1%84%8B%E1%85%AD%E1%86%BC%E1%84%8B%E1%85%A3%E1%86%A8%E1%84%80%E1%85%AA%E1%86%AB.pdf" style={{ marginRight: 16 }}>
            이용약관
          </LinkWrap>
          <LinkWrap target="_blank" href="https://alba-edu.s3.ap-northeast-2.amazonaws.com/%E1%84%8B%E1%85%A7%E1%86%BC%E1%84%8C%E1%85%A2%E1%84%86%E1%85%A6%E1%86%AB%E1%84%90%E1%85%A9+%E1%84%80%E1%85%A2%E1%84%8B%E1%85%B5%E1%86%AB%E1%84%8C%E1%85%A5%E1%86%BC%E1%84%87%E1%85%A9%E1%84%8E%E1%85%A5%E1%84%85%E1%85%B5%E1%84%87%E1%85%A1%E1%86%BC%E1%84%8E%E1%85%B5%E1%86%B7.pdf">
            개인정보처리방침
          </LinkWrap>
        </IconWrap>
      </ContentsWrap>
      <ContentsWrap flexEnd shotFooter>
        <IconWrap marginBottom>
          <LinkWrap target="_blank" href="https://blog.naver.com/albatross0701">
            <div 
              style={{ 
                width: 36, 
                height: 36, 
                background: '#ffffff', 
                borderRadius: 36, 
                marginRight: 16,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <IconImgWrap src={NaverBlog} />
            </div>
          </LinkWrap>
          <LinkWrap target="_blank" href="https://band.us/@apbos">
            <IconImgWrap src={Band} marginRight />
          </LinkWrap>
          <LinkWrap target="_blank" href="https://www.youtube.com/channel/UCzkNWiR6Z_b7rmchjMXvuIw/featured">
            <IconImgWrap src={Youtube} />
          </LinkWrap>
        </IconWrap>
        <FooterContents>Copyright © 2022. AlbatrossEdu</FooterContents>
      </ContentsWrap>
    </Container>
  );
};

export default Footer;
