import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  Container,
  LinkText,
  ThumbnailWrap,
  FileWrap,
  VideoTitle,
  VideoLastTime,
  RowWrap,
  VideoWrap,
} from "./style";
import CategoryTab from "../../../../components/CategoryTab";

import { ReactComponent as ArrowIcon } from "../../../..//assets/icons/arrow_top.svg";
import axios from "axios";
import { API_URL } from "../../../../API";
import dayjs from "dayjs";

const VideoList = ({ videos }) => {
  const navigate = useNavigate();
  const [detailShow, setDetailShow] = useState(false);

  return (
    <div style={{ marginTop: 48 }}>
      <LinkText
        style={{ position: "absolute", top: 90, right: 0, color: "#212529" }}
        onClick={() => {
          navigate("/mypage/teacher/videoUpload");
        }}
      >
        업로드하기
      </LinkText>
      {detailShow ? (
        <div>
          <RowWrap
            style={{ alignItems: "center", justifyContent: "space-between" }}
          >
            <RowWrap style={{ alignItems: "center" }}>
              <ArrowIcon
                style={{
                  transform: "rotate(-90deg)",
                  marginRight: 4,
                  cursor: "pointer",
                }}
                onClick={() => {
                  setDetailShow(false);
                }}
              />
              <VideoTitle style={{ margin: 0 }}>
                이곳은 세미나 타이틀 영역입니다.
              </VideoTitle>
            </RowWrap>
            <LinkText
              style={{
                color: "#ADB5BD",
                textDecorationColor: "#ADB5BD",
              }}
              onClick={() => {
                navigate("/mypage/teacher/videoModify");
              }}
            >
              수정하기
            </LinkText>
          </RowWrap>
          <VideoWrap></VideoWrap>
        </div>
      ) : (
        <>
          {videos.map((item, idx) => (
            <FileWrap
              key={idx}
              style={{ marginRight: (idx + 1) % 2 === 0 ? 0 : 16 }}
              // onClick={() => {
              //   setDetailShow(true);
              // }}
            >
              <ThumbnailWrap src={item.thumbnail}>
                {/* <VideoTime>1:20:34</VideoTime> */}
              </ThumbnailWrap>
              <VideoTitle>{ item.title }</VideoTitle>
              <VideoLastTime>{dayjs(item.createdAt).fromNow()}</VideoLastTime>
            </FileWrap>
          ))}
        </>
      )}
    </div>
  );
};

const BuyContentsList = ({ contents }) => {
  const navigate = useNavigate();

  return (
    <div style={{ marginTop: 48 }}>
      {contents.map((item, idx) => (
        <FileWrap
          key={idx}
          style={{ marginRight: (idx + 1) % 2 === 0 ? 0 : 16 }}
          // onClick={() => {
          //   setDetailShow(true);
          // }}
        >
          <ThumbnailWrap src={item.thumbnail}>
          </ThumbnailWrap>
          <VideoTitle>{ item.title }</VideoTitle>
          <VideoLastTime>{dayjs(item.createdAt).fromNow()}</VideoLastTime>
        </FileWrap>
      ))}
    </div>
  );
};

const MyContentsList = ({ contents }) => {
  const navigate = useNavigate();

  return (
    <div style={{ marginTop: 48 }}>
      <LinkText
        style={{ position: "absolute", top: 90, right: 0, color: "#212529" }}
        onClick={() => {
          navigate("/mypage/teacher/videoUpload");
        }}
      >
        업로드하기
      </LinkText>
      {contents.map((item, idx) => (
        <FileWrap
          key={idx}
          style={{ marginRight: (idx + 1) % 2 === 0 ? 0 : 16 }}
          // onClick={() => {
          //   setDetailShow(true);
          // }}
        >
          <ThumbnailWrap src={item.thumbnail}>
          </ThumbnailWrap>
          <VideoTitle>{ item.title }</VideoTitle>
          <VideoLastTime>{dayjs(item.createdAt).fromNow()}</VideoLastTime>
        </FileWrap>
      ))}
    </div>
  );
};

const FileManagement = ({ type }) => {
  const [mentopickVideos, setMentopickVideos] = useState([]);

  const getData = () => {
    axios({
      method: 'GET',
      url: `${API_URL}/mypage/mentopick/videos`
    }).then((res) => {
      if (res.data.success) {
        setMentopickVideos(res.data.result.videos);
      } else {
        alert(res.data.error.message);
      }
    })
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <Container style={{ position: "relative" }}>
      <CategoryTab
        array={type === 'TEACHER' ? ["영상 자료", /*'구매한 자료', '업로드한 자료'*/] : [/*'구매한 자료', '업로드한 자료'*/]}
        contents={type === 'TEACHER' ? [<VideoList videos={mentopickVideos} />, <BuyContentsList contents={[]} />, <MyContentsList contents={[]} />] : [<BuyContentsList contents={[]} />, <MyContentsList contents={[]} />]} 
      />
    </Container>
  );
};

export default FileManagement;
