import styled from "styled-components";

const Container = styled.div`
  width: 1364px;
  margin: 0 auto;
  padding: 200px 0 260px 0;
  min-height: 991px;

  @media screen and (max-width: 1280px) {
    width: 1280px;
    overflow-x: hidden;
  }
`;

const BannerTitle = styled.span`
  font-size: 28px;
  font-weight: ${(props) => props.theme.fontWeight.medium};
  color: ${(props) =>
    props.orange ? props.theme.color.orange : props.theme.color.primary};
  line-height: 41px;
`;

const RowWrap = styled.div`
  display: flex;
`;

//ContentsMarketList

const ConditionText = styled.div`
  color: ${(props) => (props.active ? "#021633" : "#ADB5BD")};
  font-size: 20px;
  font-weight: ${(props) => (props.active ? 700 : 500)};
  line-height: 50px;
  cursor: pointer;
`;

const VerticalBorder = styled.div`
  background-color: #adb5bd;
  width: 1px;
  height: 16px;
  margin: 0px 20px;
`;

const ItemListWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const MarketItemsWrap = styled.div`
  width: calc((100% - 32px) / 3);
  margin-right: ${(props) => (props.noMargin ? "0px" : "16px")};
  margin-bottom: 80px;
  cursor: pointer;

  @media screen and (max-width: 1280px) {
    margin-right: 8px;
    margin-bottom: 40px;
  }
`;

const MarkteItemsImageWrap = styled.div`
  /* background-color: #021633; */
  border-radius: 12px;
  width: 100%;
  height: 230px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 80%;
  }
`;

const MarketItemTitle = styled.div`
  color: #212529;
  font-size: 20px;
  font-weight: 700;
  line-height: 29px;
  margin: 20px 0px 12px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const MarketItemType = styled.div`
  border: 1px solid #021633;
  border-radius: 4px;
  color: #021633;
  font-size: 14px;
  margin-left: 10px;
  display: flex;
  align-items: center;
  padding: 0px 14px;
  height: 28px;
`;

const MarketItemPrice = styled.div`
  background-color: ${(props) =>
    props.price === "무료" ? "rgba(0,129,245,0.1)" : "rgba(51,216,78,0.1)"};
  color: ${(props) => (props.price === "무료" ? "#0081F5" : "#33D84E")};
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 0px 16px;
  height: 28px;
`;

const PaginationWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 80px;
`;

//contentsMarket detail

const DetailContainer = styled.div`
  width: 85vw;
  margin: 0 auto;
  padding: 200px 0 240px 0;
`;

const DetailWrap = styled.div`
  width: 100%;
`;

const DetailTitle = styled.div`
  color: #212529;
  font-size: 32px;
  font-weight: 700;
  line-height: 46px;
  margin: 12px 0px;
`;

const ContentMarketWriter = styled.div`
  color: #868e96;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.7rem;
  margin-bottom: 1rem;
`;

const DetailBody = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  padding-bottom: 2rem;
  margin-bottom: 2rem;
  border-bottom: 2px solid #e9ecef;
`;

const DetailImageWrap = styled.div`
  width: 65%;
  height: 50vh;
  padding: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DetailContent = styled.div`
  width: 35%;
  height: 50vh;
  color: #212529;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  padding: 1.5rem 2rem;
  margin-left: 2rem;
  word-break: keep-all;
  border-left: 1px solid #e9ecef;
`;

const NoticeWrap = styled.div`
  background-color: #eef0f4;
  border-radius: 12px;
  width: 100%;
  padding: 1.5rem;
  margin-bottom: 2rem;
`;

const NoticeText = styled.div`
  color: #021633;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  white-space: pre;
`;

const Button = styled.div`
  min-width: 30%;
  height: 3rem;
  background-color: ${(props) => (props.gray ? "#DDE2EA" : "#021633")};
  color: ${(props) => (props.gray ? "#021633" : "#ffffff")};
  margin-right: ${(props) => (props.gray ? "16px" : "0px")};
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const SliderContentsWrap = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin: 0 8px;
`;

const SliderWrap = styled.div`
  width: 812px;
  height: 609px;
  border-radius: 12px;
  overflow: hidden;
  background-color: #212529;
`;

const Image = styled.img`
  object-fit: contain;
  width: 100%;
  height: 100%;
`;

export {
  Container,
  BannerTitle,
  RowWrap,
  ConditionText,
  VerticalBorder,
  ItemListWrap,
  MarketItemsWrap,
  MarkteItemsImageWrap,
  MarketItemTitle,
  MarketItemType,
  MarketItemPrice,
  PaginationWrap,
  DetailContainer,
  DetailWrap,
  DetailTitle,
  DetailBody,
  ContentMarketWriter,
  DetailImageWrap,
  DetailContent,
  NoticeWrap,
  NoticeText,
  Button,
  SliderContentsWrap,
  SliderWrap,
  Image,
};
