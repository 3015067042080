import React, { useState, useEffect } from "react";
import axios from "axios";
import dayjs from "dayjs";
import { useRecoilState, useSetRecoilState } from "recoil";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode } from "swiper";
import { Pagination as Pag } from "swiper";
import "swiper/css";
import {
  Container,
  RowWrap,
  MainWrap,
  ProfileContainer,
  ProfileWrap,
  UserName,
  UserType,
  UserPayment,
  JoinDateText,
  VerticalBorder,
  ButtonWrap,
  PopupTitle,
  PopupCloseButton,
  ProfileImgWrap,
  ProfileImg,
  ChangeImageContainer,
  ImageInput,
  ImageLabel,
  CalendarTitle,
} from "./style";
import {
  MemberTypeButtonWrap,
  MemberTypeText,
  MemberTypePriceText,
  MemberTypeInfoText,
  MemberTypeInfoWrap,
  MemberTypeInfoTextWrap,
  MemberTypeWhether,
} from "../signup/student/style";
import {
  PopupBackground,
  PopupContainer,
  CloseButtonWrap,
} from "../../components/Popup";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Tab from "../../components/Tab";
import HistoryManagement from "./components/historyManagement";
import Payment from "./components/payment";
import Button from "../../components/Button";
import { API_URL } from "../../API";
import formatUserRole from "../../utils/formatUserRole";
import tokenState from "../../states/tokenState";
import Information from "./components/information";
import DatePicker from "../../components/DatePicker";
import RadioBox from "../../components/RadioBox";
import { ReactComponent as CloseIcon } from "../../assets/icons/cancle_44px.svg";
import { runBootpaySubscription } from "../../components/BootPay";
import FileManagement from "./teacher/tab/fileManagement";
import { Desktop, Mobile } from "../../utils/mediaQuery";
import MobileHeader from "../../components/MobileHeader";
import MobileBottomBar from "../../components/MobileBottomBar";
import {
  MobileMypageContainer,
  MobileMypageProfileBox,
  MobileMypageTab,
} from "./mobile.style";

import next from "../../assets/images/next.png";
import StudentMypage from "./student";
import useWindowSize from "../../hooks/useWindowSize";
import userInfoState from "../../states/userInfoState";

const Mypage = () => {
  const [paymentModal, setPaymentModal] = useState(false);
  const [data, setData] = useState(null);
  const [otModal, setOTModal] = useState(false);
  const setToken = useSetRecoilState(tokenState);
  const navigate = useNavigate("/");
  const [possibleDates, setPossibleDates] = useState([]);
  const [selectedDay, setSelectedDay] = useState("");
  const [selectedTime, setSelectedTime] = useState("");
  const [initialOtDates, setInitialOtDates] = useState(
    data?.mentopick?.dates || []
  );
  const [currentOtItem, setCurrentOtItem] = useState(null);
  const [otDates, setOtDates] = useState([]);
  const [otDataLoading, setOTDataLoading] = useState(true);
  const [userInfo, setUserInfo] = useRecoilState(userInfoState);
  const [userType, setUserType] = useState(userInfo?.type);
  const { width } = useWindowSize();
  const isMobile = width <= 1080;
  const today = dayjs().format("YYYY-MM-DD");
  const mypageTabs = ["기본정보", "내역관리", "입금/결제/포인트", "파일관리"];
  const [currentTab, setCurrentTab] = useState("기본정보");

  const getData = () => {
    setOTDataLoading(true);

    axios({
      method: "GET",
      url: `${API_URL}/mypage`,
    }).then(async (res) => {
      if (res.data.success) {
        setData(res.data.result);
        await getOtData(res.data.result);
        setOTDataLoading(false);
        console.log(res.data.result);
      }
    });
  };

  const getOtData = (data) => {
    let temp = [];
    let ot = [];
    data.mentopick.dates.map((item) => {
      let date = item.date.split("T")[0];
      let time = parseInt(item.date.split("T")[1].split(":")[0]) + 9;
      if (time >= 24) {
        time -= 24;
        date = dayjs(date).add(1, "day").format("YYYY-MM-DD");
      }
      time = time.toString().padStart(2, "0");
      const findIdx = temp.findIndex((tempItem) => tempItem.date === date);
      if (findIdx !== -1) {
        const findTimeIdx = temp[findIdx].times.findIndex(
          (timeItem) => timeItem.time === `${time}:00`
        );
        if (findTimeIdx !== -1) {
          temp[findIdx].times[findTimeIdx].canJoin = item.canJoin;
        } else {
          temp[findIdx].times.push({
            time: `${time}:00`,
            canJoin: item.canJoin,
          });
        }
      } else {
        temp.push({
          date: date,
          times: [
            {
              time: `${time}:00`,
              canJoin: item.canJoin,
            },
          ],
          id: item.id,
        });
      }
    });
    temp.sort((a, b) => {
      return a.date > b.date ? 1 : -1;
    });
    // .map((item) => {
    //   let findedIdx = ot.findIndex((otItem) => otItem.date === item.date);
    //   if (findedIdx !== -1) {
    //     let findedTimeIdx = ot[findedIdx].times.findIndex(
    //       (timeItem) => timeItem.time === item.times[0].time
    //     );
    //     if (findedTimeIdx !== -1) {
    //       ot[findedIdx].times[findedTimeIdx].canJoin = item.times[0].canJoin;
    //     } else {
    //       ot[findedIdx].times.push(item.times[0]);
    //     }
    //   } else {
    //     ot.push(item);
    //   }
    // });
    setInitialOtDates(temp);
  };

  // useEffect(() => {
  //   console.log("otDates", otDates);
  // }, [otDates]);

  const logout = async () => {
    await processLogout();
    navigate("/");
  };

  const processLogout = () => {
    setTimeout(() => {
      setToken(null);
      setUserType(null);
      setUserInfo(null);
      axios.defaults.headers["Authorization"] = "";
      localStorage.setItem("userInfoState", null);
    }, 500);
  };

  const uploadProfile = (file) => {
    const formData = new FormData();

    formData.append("file", file);

    axios({
      method: "POST",
      url: `${API_URL}/file/upload`,
      data: formData,
    }).then((uploadRes) => {
      const imageUrl = uploadRes.data.result.url;

      axios({
        method: "POST",
        url: `${API_URL}/mypage/userInfo`,
        data: {
          profile: imageUrl,
        },
      }).then((res) => {
        if (res.data.success) {
          getData();
        }
      });
    });
  };

  const payment = () => {
    runBootpaySubscription(
      "영재멘토 유료회원",
      11000,
      data.name,
      data.phoneNumber,
      data.email,
      () => {},
      (billingKey) => {
        axios({
          method: "POST",
          url: `${API_URL}/mypage/pay?billingKey=${billingKey}`,
        }).then((res) => {
          if (res.data.success) {
            alert("유료 회원으로 전환되었습니다!");
            setPaymentModal(false);
            getData();
          } else {
            alert(res.data.error.message);
          }
        });
      }
    );
  };

  const getPossibleDates = () => {
    const possibleDays = [];

    for (let i = 0; i < 365; i++) {
      const possibleTimes = [];

      for (let j = 0; j < 25; j++) {
        possibleTimes.push({
          time: `${j.toString().padStart(2, "0")}:00`,
          canJoin: true,
        });
      }

      possibleDays.push({
        date: dayjs().add(i, "day").format("YYYY-MM-DD"),
        times: possibleTimes,
      });
    }
    initialOtDates.map((item) => {
      let findedIdx = possibleDays.findIndex(
        (possibleDay) => possibleDay.date === item.date
      );
      if (findedIdx !== -1) {
        possibleDays[findedIdx].times = item.times;
      }
    });
    setPossibleDates(possibleDays);
  };

  const modifyOtDate = () => {
    console.log(otDates);
    axios({
      method: "POST",
      url: `${API_URL}/mypage/mentopick/date`,
      data: {
        dates: otDates,
      },
    }).then((res) => {
      if (res.data.success) {
        alert("OT 상담 시간이 설정되었습니다.");
        setCurrentOtItem(null);
        setOtDates([]);
        setOTModal(false);
        setSelectedDay("");
        setSelectedTime("");
        getData();
      }
    });
  };

  const isClassPossible = (selectDate, selectedTime) => {
    if (currentOtItem) {
      const findedTimeIdx = currentOtItem.times.findIndex(
        (item) => item.time === selectedTime
      );

      if (findedTimeIdx !== -1) {
        return currentOtItem.times[findedTimeIdx].canJoin
          ? "possible"
          : "impossible";
      }
    } else return "";
  };

  useEffect(() => {
    getData();
    getPossibleDates();
  }, []);

  useEffect(() => {
    // if (otDates && otDates.length) {
    //   setInitialOtDates((prevInitialOtDates) => [
    //     ...prevInitialOtDates,
    //     ...otDates,
    //   ]);
    // }
    console.log("currentOT Item", currentOtItem);
  }, [otDates, currentOtItem]);

  useEffect(() => {
    setCurrentOtItem(
      initialOtDates.find((item) =>
        dayjs(item.date).isSame(dayjs(`${selectedDay}`))
      )
    );
    console.log("initialOtDates", initialOtDates);
  }, [initialOtDates, selectedDay, selectedTime]);

  if (!data) {
    return (
      <>
        <Header activeTab={"MyPage"} />
        <Footer />
      </>
    );
  }

  return (
    <>
      <Desktop>
        <Header activeTab={"MyPage"} />
        <Container>
          <MainWrap>
            <RowWrap>
              <Tab
                array={
                  data.type === "TEACHER"
                    ? ["기본정보", "내역관리" /*,"결제"*/, "파일관리"]
                    : ["기본정보", "내역관리", "결제" /*'파일관리'*/]
                }
                contents={
                  data.type === "TEACHER"
                    ? [
                        <Information data={data} getData={getData} />,
                        <HistoryManagement type={data.type} />,
                        //<Payment type={data.type} />,
                        <FileManagement type={data.type} />,
                      ]
                    : [
                        <Information data={data} getData={getData} />,
                        <HistoryManagement type={data.type} />,
                        <Payment type={data.type} />,
                        // <FileManagement type={data.type} />,
                      ]
                }
              />
              <ProfileContainer>
                <ProfileWrap>
                  <ProfileImgWrap>
                    <ProfileImg
                      src={data.profileImage || "/images/default_profile.png"}
                    />
                    <ChangeImageContainer>
                      <ImageInput
                        type="file"
                        id="file"
                        accept=".jpg, .png"
                        onChange={(e) => {
                          if (e.target.files.length) {
                            uploadProfile(e.target.files[0]);
                          }
                        }}
                      />
                      <ImageLabel htmlFor="file">사진 변경</ImageLabel>
                    </ChangeImageContainer>
                  </ProfileImgWrap>
                  <RowWrap
                    style={{
                      flexDirection: "column",
                      marginTop: 32,
                    }}
                  >
                    {data.type === "USER" && (
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <UserType>{formatUserRole(data.role)}</UserType>
                        <UserPayment>
                          {data.isPaid ? "유료" : "무료"}회원
                        </UserPayment>
                      </div>
                    )}
                    {data.type === "TEACHER" && (
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <UserType>강사</UserType>
                      </div>
                    )}
                    <UserName>{data.nickname}님</UserName>
                  </RowWrap>
                  <RowWrap style={{ alignItems: "center", marginTop: 8 }}>
                    <JoinDateText>가입일</JoinDateText>
                    <VerticalBorder />
                    <JoinDateText>
                      {dayjs(data.createdAt).format("YYYY. MM. DD")}
                    </JoinDateText>
                  </RowWrap>
                  <ButtonWrap>
                    {data.isPaid === false && data.type === "USER" && (
                      <Button
                        active
                        style={{ borderRadius: 12, marginBottom: 20 }}
                        onClick={() => {
                          setPaymentModal(true);
                        }}
                      >
                        유료회원으로 전환하기
                      </Button>
                    )}
                    {data.type === "TEACHER" && (
                      <Button
                        active={!otDataLoading}
                        disabled={otDataLoading}
                        style={{
                          marginBottom: 20,
                          borderRadius: 12,
                        }}
                        onClick={() => {
                          setOTModal(true);
                          document.body.style.overflow = "hidden";
                        }}
                      >
                        OT 상담관리
                      </Button>
                    )}
                    {/* <Button
                    style={{
                      backgroundColor: "#DDE2EA",
                      border: "none",
                      borderRadius: 12,
                      marginBottom: 20,
                    }}
                    onClick={() => {
                      navigate("/mypage/teacher/videoUpload");
                    }}
                  >
                    오픈 강의 신청하기
                  </Button> */}
                    <Button
                      onClick={logout}
                      style={{
                        backgroundColor: "#DDE2EA",
                        border: "none",
                        borderRadius: 12,
                      }}
                    >
                      로그아웃
                    </Button>
                  </ButtonWrap>
                </ProfileWrap>
              </ProfileContainer>
            </RowWrap>
          </MainWrap>
          <Footer />
        </Container>
        {otModal && (
          <PopupBackground>
            <PopupContainer width="812px" maxHeight="800px" overflow-y="scroll">
              <CloseButtonWrap
                onClick={() => {
                  document.body.style.overflow = "auto";
                  setOtDates([]);
                  setCurrentOtItem(null);
                  setOTModal(false);
                  setSelectedDay("");
                  setSelectedTime("");
                }}
              >
                <CloseIcon />
              </CloseButtonWrap>
              <PopupTitle>OT 상담관리</PopupTitle>
              <CalendarTitle style={{ marginBottom: 20 }}>
                날짜 / 시간 선택
              </CalendarTitle>
              {/* <div style={{ marginBottom: 20 }}>
                {initialOtDates.map((otDate) => {
                  if (otDate.date >= today) {
                    return (
                      <p key={`ot_${otDate.date}`} style={{ marginBottom: 12 }}>
                        {otDate.date}
                        {"\t"}
                        {otDate.times
                          .map(
                            (time) =>
                              `${time.time}: ${
                                time.canJoin ? "수업가능" : "마감완료"
                              }`
                          )
                          .join(", ")}
                      </p>
                    );
                  }
                })}
              </div> */}
              <DatePicker
                userType={userType}
                selectedDay={selectedDay}
                possibleDate={possibleDates}
                otDates={initialOtDates}
                onClickDay={(value) => {
                  setSelectedDay(value);
                  setSelectedTime("");
                }}
                onClickTime={(value) => {
                  setSelectedTime(value);
                }}
              />
              {selectedDay && selectedTime && (
                <>
                  <CalendarTitle
                    style={{ marginTop: 48, width: 91, marginBottom: 20 }}
                  >
                    마감여부
                  </CalendarTitle>
                  <RadioBox
                    id={`radio_${selectedDay}_${selectedTime}`}
                    value={isClassPossible(selectedDay, selectedTime)}
                    option={[
                      {
                        label: "수업가능",
                        name: "class",
                        value: "possible",
                        id: "possible",
                      },
                      {
                        label: "마감완료",
                        name: "class",
                        value: "impossible",
                        id: "impossible",
                      },
                    ]}
                    showLine={2}
                    onChange={(value) => {
                      const findedIdx = otDates.findIndex(
                        (item) => item.date === selectedDay
                      );

                      if (findedIdx !== -1) {
                        const findedItem = otDates[findedIdx];
                        const findedTimeIdx = findedItem.times.findIndex(
                          (item) => item.time === selectedTime
                        );

                        setOtDates(
                          otDates.map((item) => {
                            if (item.date === selectedDay) {
                              if (findedTimeIdx !== -1) {
                                return {
                                  ...item,
                                  times: item.times.map((time) => {
                                    if (time.time === selectedTime) {
                                      return {
                                        ...time,
                                        canJoin: value === "possible",
                                      };
                                    }

                                    return time;
                                  }),
                                };
                              }

                              return {
                                ...item,
                                times: [
                                  ...item.times,
                                  {
                                    time: selectedTime,
                                    canJoin: value === "possible",
                                  },
                                ],
                              };
                            }

                            return item;
                          })
                        );
                      } else {
                        setOtDates([
                          ...otDates,
                          {
                            date: selectedDay,
                            times: [
                              {
                                time: selectedTime,
                                canJoin: value === "possible",
                              },
                            ],
                          },
                        ]);
                      }
                    }}
                  />
                </>
              )}
              <div style={{ padding: "0px 245px", marginTop: 12 }}>
                <Button active onClick={modifyOtDate}>
                  등록하기
                </Button>
              </div>
            </PopupContainer>
          </PopupBackground>
        )}
        {paymentModal && (
          <PopupBackground>
            <PopupContainer>
              <PopupCloseButton
                onClick={() => {
                  setPaymentModal(false);
                }}
              />
              <PopupTitle>유료회원 전환</PopupTitle>
              <MemberTypeButtonWrap
                active={"유료"}
                style={{ width: "100%", marginBottom: 16, cursor: "auto" }}
              >
                <div>
                  <MemberTypeText active={"유료"}>유료 회원</MemberTypeText>
                  <MemberTypePriceText active={"유료"}>
                    월 11,000원
                  </MemberTypePriceText>
                </div>
              </MemberTypeButtonWrap>
              <MemberTypeInfoWrap style={{ width: "100%" }}>
                <MemberTypeInfoTextWrap>
                  <MemberTypeInfoText>소개페이지 보기</MemberTypeInfoText>
                  <MemberTypeWhether>가능</MemberTypeWhether>
                </MemberTypeInfoTextWrap>
                <MemberTypeInfoTextWrap>
                  <MemberTypeInfoText>
                    강사 리스트 및 상세내역 확인
                  </MemberTypeInfoText>
                  <MemberTypeWhether>가능</MemberTypeWhether>
                </MemberTypeInfoTextWrap>
                <MemberTypeInfoTextWrap>
                  <MemberTypeInfoText>OT 신청 / 수업 예약</MemberTypeInfoText>
                  <MemberTypeWhether>가능</MemberTypeWhether>
                </MemberTypeInfoTextWrap>
                <MemberTypeInfoTextWrap>
                  <MemberTypeInfoText>자료실 이용</MemberTypeInfoText>
                  <MemberTypeWhether>가능</MemberTypeWhether>
                </MemberTypeInfoTextWrap>
                <MemberTypeInfoTextWrap>
                  <MemberTypeInfoText>
                    질의응답 페이지 상세내용 및 댓글 확인
                  </MemberTypeInfoText>
                  <MemberTypeWhether>가능</MemberTypeWhether>
                </MemberTypeInfoTextWrap>
              </MemberTypeInfoWrap>
              <div
                style={{
                  paddingLeft: 114,
                  paddingRight: 114,
                  marginTop: 32,
                }}
              >
                <Button active style={{ borderRadius: 12 }} onClick={payment}>
                  결제하기
                </Button>
              </div>
            </PopupContainer>
          </PopupBackground>
        )}
      </Desktop>
      <Mobile>
        <MobileHeader name={"마이페이지"} type={"main"} />
        <MobileBottomBar activeTab={"Mypage"} />
        <MobileMypageContainer>
          <MobileMypageProfileBox>
            <div>
              <figure>
                <div>
                  <img
                    width={72}
                    src={data.profileImage || "/images/default_profile.png"}
                  />
                </div>
              </figure>
              <div>
                <div>
                  <span>
                    {data.type === "TEACHER"
                      ? "강사"
                      : formatUserRole(data.role)}
                  </span>
                  {/* <span>무료회원</span> */}
                </div>
                <h2>{data.nickname}</h2>
                <p>가입일 | {dayjs(data.createdAt).format("YYYY.MM.DD")}</p>
              </div>
            </div>
            {/* <button>유료회원 전환하기</button> */}
            <div>
              {/* <button>
                <p>공지사항</p>
                <img src={next} alt="" />
              </button> */}
              <button
                onClick={() => {
                  setOTModal(true);
                  document.body.style.overflow = "hidden";
                }}
              >
                <p>OT 상담관리</p>
                <img src={next} alt="ot" />
              </button>
              <button onClick={logout}>
                <p>로그아웃</p>
                <img src={next} alt="logout" />
              </button>
            </div>
          </MobileMypageProfileBox>
          <Tab
            array={
              data.type === "TEACHER"
                ? ["기본정보", "내역관리", "결제", "파일관리"]
                : ["기본정보", "내역관리", "결제" /*'파일관리'*/]
            }
            contents={
              data.type === "TEACHER"
                ? [
                    <Information data={data} getData={getData} />,
                    <HistoryManagement type={data.type} />,
                    <Payment type={data.type} />,
                    <FileManagement type={data.type} />,
                  ]
                : [
                    <Information data={data} getData={getData} />,
                    <HistoryManagement type={data.type} />,
                    <Payment type={data.type} />,
                    <FileManagement type={data.type} />,
                  ]
            }
          />
        </MobileMypageContainer>
        {otModal && (
          <PopupBackground>
            <PopupContainer width="100%">
              <CloseButtonWrap
                onClick={() => {
                  document.body.style.overflow = "auto";
                  setOtDates([]);
                  setCurrentOtItem(null);
                  setOTModal(false);
                  setSelectedDay("");
                  setSelectedTime("");
                }}
              >
                <CloseIcon />
              </CloseButtonWrap>
              <PopupTitle>OT 상담관리</PopupTitle>
              <CalendarTitle style={{ marginBottom: 20 }}>
                날짜 / 시간 선택
              </CalendarTitle>
              {/* <div style={{ marginBottom: 20 }}>
                {initialOtDates.map((otDate) => {
                  if (otDate.date >= today) {
                    return (
                      <p key={`ot_${otDate.date}`} style={{ marginBottom: 12 }}>
                        {otDate.date}
                        {"\t"}
                        {otDate.times
                          .map(
                            (time) =>
                              `${time.time}: ${
                                time.canJoin ? "수업가능" : "마감완료"
                              }`
                          )
                          .join(", ")}
                      </p>
                    );
                  }
                })}
              </div> */}
              <DatePicker
                selectedDay={selectedDay}
                possibleDate={possibleDates}
                otDates={initialOtDates}
                onClickDay={(value) => {
                  setSelectedDay(value);
                  setSelectedTime("");
                }}
                onClickTime={(value) => {
                  setSelectedTime(value);
                }}
                style={{ flexDirection: "column" }}
              />
              {selectedDay && selectedTime && (
                <>
                  <CalendarTitle
                    style={{ marginTop: 48, width: 91, marginBottom: 20 }}
                  >
                    마감여부
                  </CalendarTitle>
                  <RadioBox
                    id={`radio_${selectedDay}_${selectedTime}`}
                    value={
                      currentOtItem
                        ? currentOtItem.canJoin
                          ? "possible"
                          : "impossible"
                        : ""
                    }
                    option={[
                      {
                        label: "수업가능",
                        name: "class",
                        value: "possible",
                        id: "possible",
                      },
                      {
                        label: "마감완료",
                        name: "class",
                        value: "impossible",
                        id: "impossible",
                      },
                    ]}
                    showLine={2}
                    onChange={(value) => {
                      const findedIdx = otDates.findIndex(
                        (item) => item.date === selectedDay
                      );

                      if (findedIdx !== -1) {
                        const findedItem = otDates[findedIdx];
                        const findedTimeIdx = findedItem.times.findIndex(
                          (item) => item.time === selectedTime
                        );

                        setOtDates(
                          otDates.map((item) => {
                            if (item.date === selectedDay) {
                              if (findedTimeIdx !== -1) {
                                return {
                                  ...item,
                                  times: item.times.map((time) => {
                                    if (time.time === selectedTime) {
                                      return {
                                        ...time,
                                        canJoin: value === "possible",
                                      };
                                    }

                                    return time;
                                  }),
                                };
                              }

                              return {
                                ...item,
                                times: [
                                  ...item.times,
                                  {
                                    time: selectedTime,
                                    canJoin: value === "possible",
                                  },
                                ],
                              };
                            }

                            return item;
                          })
                        );
                      } else {
                        setOtDates([
                          ...otDates,
                          {
                            date: selectedDay,
                            times: [
                              {
                                time: selectedTime,
                                canJoin: value === "possible",
                              },
                            ],
                          },
                        ]);
                      }
                    }}
                  />
                </>
              )}
              <div
                style={{ padding: isMobile ? "" : "0px 245px", marginTop: 12 }}
              >
                <Button active onClick={modifyOtDate}>
                  등록하기
                </Button>
              </div>
            </PopupContainer>
          </PopupBackground>
        )}
      </Mobile>
    </>
  );
};

export default Mypage;
