import React from "react";
import "./style/pagination.css";
import ReactPaginate from "react-js-pagination";

const Pagination = ({ totalItemsCount, showItemsCount, nowPage, tabName, page }) => {
  const handlePageChange = (page) => {
    nowPage(page);
  };

  return (
    <ReactPaginate
      activePage={page}
      itemsCountPerPage={showItemsCount}
      totalItemsCount={totalItemsCount}
      pageRangeDisplayed={5}
      onChange={handlePageChange}
    />
  );
};

export default Pagination;
