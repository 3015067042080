import React from "react";
import styled from "styled-components";

import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { useNavigate } from "react-router-dom";

import Web1 from "../../assets/images/inquery/web1.png";
import Web2 from "../../assets/images/inquery/web2.png";
import Web3 from "../../assets/images/inquery/web3.png";
import Web4 from "../../assets/images/inquery/web4.png";
import Web5 from "../../assets/images/inquery/web5.png";
import Mobile1 from "../../assets/images/inquery/mobile1.png";
import Mobile2 from "../../assets/images/inquery/mobile2.png";
import Mobile3 from "../../assets/images/inquery/mobile3.png";
import Mobile4 from "../../assets/images/inquery/mobile4.png";
import Mobile5 from "../../assets/images/inquery/mobile5.png";
import MobileHeader from "../../components/MobileHeader";

import { Desktop, Mobile } from "../../utils/mediaQuery";

const CareerSearchBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 400px;
  height: 100px;
  border-radius: 12px;
  box-shadow: 0px 2px 12px rgba(2, 22, 51, 0.3);
  position: sticky;
  margin-bottom: 80px;
  left: 50%;
  transform: translate(-50%);
  bottom: 80px;
  background-color: #ffffff;
  padding: 20px;
`;

const MobileCareerSearchBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: auto;
  border-radius: 12px;
  box-shadow: 0px 2px 12px rgba(2, 22, 51, 0.3);
  position: sticky;
  margin-bottom: 0px;
  bottom: 0px;
  background-color: #ffffff;
  padding: 20px;
`;
const CareerSearchTextWrap = styled.div`
  display: flex;
  flex-direction: column;
`;
const CareerTitle = styled.div`
  font-size: 20px;
  font-weight: ${(props) => props.theme.fontWeight.bold};
  color: #212529;
  height: 29px;
  margin-bottom: 7px;
  display: flex;
  align-items: center;
`;
const CareerSubTitle = styled.div`
  font-size: 16px;
  font-weight: ${(props) => props.theme.fontWeight.medium};
  color: #adb5bd;
  height: 24px;
  display: flex;
  align-items: center;
`;
const CareerBtn = styled.button`
  width: 121px;
  height: 40px;
  border-radius: 8px;
  background-color: #ffad2e;
  border: none;
  color: #ffffff;
  font-size: 16px;
  font-weight: ${(props) => props.theme.fontWeight.medium};
`;
const MobileCareerBtn = styled.button`
  width: 100%;
  height: 40px;
  border-radius: 8px;
  background-color: #ffad2e;
  border: none;
  color: #ffffff;
  font-size: 16px;
  font-weight: ${(props) => props.theme.fontWeight.medium};
`;
const Container = styled.div`
  width: 100%;
  padding-top: 100px;
`;
const MobileContainer = styled.div`
  width: 100%;
  padding-top: 50px;
`;
const VipWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 47px;
`;
const VipImage = styled.img`
  width: 800px;
  height: auto;
`;
const VipImageMobile = styled.img`
  width: 100%;
  height: auto;
`;

const VipApplication = () => {
  const navigate = useNavigate();

  return (
    <div>
      <Desktop>
        <Header />
        <Container>
          <VipWrap>
            <VipImage src={Web1} />
            <VipImage src={Web2} />
            <VipImage src={Web3} />
            <VipImage src={Web4} />
            <VipImage src={Web5} />
          </VipWrap>
          <CareerSearchBlock>
            <CareerSearchTextWrap>
              <CareerTitle>학생부 관리</CareerTitle>
              <CareerSubTitle>세상에 둘도 없는 학생부 탄생 !</CareerSubTitle>
            </CareerSearchTextWrap>
            <CareerBtn
              onClick={() => {
                navigate("/inquery");
              }}
            >
              문의하기
            </CareerBtn>
          </CareerSearchBlock>
          <Footer />
        </Container>
      </Desktop>
      <Mobile>
        <MobileHeader name={"학생부관리"} type={"main"} hideIcon={true} />
        <MobileContainer>
          <VipWrap>
            <VipImageMobile src={Mobile1} />
            <VipImageMobile src={Mobile2} />
            <VipImageMobile src={Mobile3} />
            <VipImageMobile src={Mobile4} />
            <VipImageMobile src={Mobile5} />
          </VipWrap>
          <MobileCareerSearchBlock>
            <MobileCareerBtn
              onClick={() => {
                navigate("/inquery");
              }}
            >
              문의하기
            </MobileCareerBtn>
          </MobileCareerSearchBlock>
        </MobileContainer>
      </Mobile>
    </div>
  );
};

export default VipApplication;
