import React from "react";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  padding-top: 20px;
`;

//information

const InfoTitleWrap = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;

const InfoTitle = styled.div`
  color: #212529;
  font-size: 24px;
  font-weight: 700;
  line-height: 50px;

  @media (max-width: 1080px) {
    font-size: 16px;
  }
`;

const LinkText = styled.div`
  color: #868e96;
  font-size: 20px;
  font-weight: 500;
  line-height: 50px;
  text-decoration: underline;
  text-decoration-color: #868e96;
  cursor: pointer;

  @media (max-width: 1080px) {
    font-size: 16px;
    line-height: 50px;
  }
`;

const InfoWrap = styled.div`
  width: 100%;
  padding: 32px 16px 32px 32px;
  border: 1px solid #dde2ea;
  border-radius: 12px;
  margin-top: 20px;
`;

const InfoListTitle = styled.div`
  color: #868e96;
  font-size: 16px;
  font-weight: 500;
  line-height: 50px;
  min-width: 110px;
  text-align: left;
`;

const InfoListContent = styled.div`
  color: #212529;
  font-size: 16px;
  font-weight: 500;
  line-height: 50px;
`;

const RowWrap = styled.div`
  display: flex;
`;

const SubjectText = styled.div`
  background-color: #eef0f4;
  color: #021633;
  font-size: 16px;
  font-weight: 500;
  padding: 8px 20px;
  border-radius: 8px;
  height: 32px;
  display: inline-block;
  flex: none;
  margin-top: 16px;
  margin-right: 16px;
`;

//history

const ConditionText = styled.div`
  color: ${(props) => (props.active ? "#021633" : "#ADB5BD")};
  font-size: 20px;
  font-weight: ${(props) => (props.active ? 700 : 500)};
  line-height: 50px;
  cursor: pointer;
`;

const VerticalBorder = styled.div`
  background-color: #adb5bd;
  width: 1px;
  height: 16px;
  margin: 0px 20px;
`;

const HistoryCard = styled.div`
  width: 49%;
  padding: 32px;
  border: 1px solid #dde2ea;
  border-radius: 12px;
  margin-right: ${(props) => (props.margin ? "16px" : "0px")};
  margin-bottom: 32px;
  display: inline-block;

  @media screen and (max-width: 1280px) {
    margin-right: 7px;
    margin-bottom: 14px;
  }

  @media (max-width: 1080px) {
    width: 100%;
  }
`;

const NameText = styled.div`
  color: #021633;
  font-size: 24px;
  font-weight: 700;
  line-height: 50px;
  margin-right: 4px;
`;

const FinishBox = styled.div`
  background-color: rgba(99, 55, 252, 0.1);
  border-radius: 4px;
  width: 80px;
  height: 28px;
  color: #6337fc;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const HistoryCardTtitle = styled.div`
  color: #868e96;
  font-size: 14px;
  font-weight: 500;
  line-height: 30px;
  margin-right: 12px;
`;

const HistoryCardContent = styled.div`
  color: #021633;
  font-size: 14px;
  font-weight: 500;
  line-height: 30px;
`;

const PopupTitle = styled.div`
  color: #212529;
  font-size: 24px;
  font-weight: 700;
  line-height: 50px;
  padding-bottom: 8px;
  border-bottom: 2px solid #212529;
  display: inline-block;
  margin-bottom: 32px;
`;

const PopupCloseButton = styled.div`
  position: absolute;
  top: 24px;
  right: 24px;
  cursor: pointer;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='44' height='44' viewBox='0 0 44 44'%3E%3Cdefs%3E%3CclipPath id='clip-path'%3E%3Crect id='사각형_807' data-name='사각형 807' width='44' height='44' transform='translate(1160 324)' fill='%23adb5bd'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg id='마스크_그룹_50' data-name='마스크 그룹 50' transform='translate(-1160 -324)' clip-path='url(%23clip-path)'%3E%3Cg id='b' transform='translate(1160 324)'%3E%3Cg id='c'%3E%3Cpath id='패스_243' data-name='패스 243' d='M0,0H44V44H0Z' fill='%23adb5bd' opacity='0' style='isolation: isolate'/%3E%3Cline id='선_4' data-name='선 4' x2='25.667' y2='25.667' transform='translate(9.167 9.167)' fill='none' stroke='%23212529' stroke-linecap='round' stroke-miterlimit='10' stroke-width='4'/%3E%3Cline id='선_5' data-name='선 5' y1='25.667' x2='25.667' transform='translate(9.167 9.167)' fill='none' stroke='%23212529' stroke-linecap='round' stroke-miterlimit='10' stroke-width='4'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
`;

const ReviewName = styled.div`
  color: #212529;
  font-size: 20px;
  font-weight: 700;
  line-height: 32px;
  margin-bottom: 20px;
`;

const StarWrap = styled.div`
  background-color: #021633;
  padding: 32px 0px;
  height: 134px;
  border-radius: 12px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
`;

const StarNoticeText = styled.div`
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  line-height: 50px;
`;

const StarNumberText = styled.div`
  color: #ffffff;
  font-size: 24px;
  font-weight: 700;
  line-height: 50px;
`;

//payment

const PaymentListWrap = styled.div`
  width: 100%;
  padding-bottom: 32px;
  margin-bottom: 32px;
  border-bottom: ${(props) => (props.last ? "0px" : "2px")} solid #e9ecef;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const PaymentDateText = styled.div`
  color: #212529;
  font-size: 16px;
  font-weight: 500;
  margin-right: 48px;
`;

const PaymentType = styled.div`
  background-color: #eef0f4;
  padding: 8px 20px;
  border-radius: 8px;
  display: inline-block;
  color: #021633;
  font-size: 14px;
  font-weight: 500;
  height: 32px;
  margin-bottom: 8px;
`;

const PaymentTitle = styled.div`
  color: #212529;
  font-size: 20px;
  font-weight: 700;
  margin: 8px 0px;
`;

const AccountText = styled.div`
  color: #868e96;
  font-size: 14px;
  font-weight: 500;
  line-height: 30px;
`;

const PaymentPrice = styled.div`
  color: #212529;
  font-size: 32px;
  font-weight: 700;
`;

const EmptyText = styled.div`
  color: #adb5bd;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  margin-top: 88px;
`;

const InfoCarWrap = styled.div`
  border: 1px solid #dde2ea;
  border-radius: 12px;
  padding: 32px;
  width: calc(50% - 16px);
  margin-right: ${(props) => (props.margin ? "16px" : "0px")};
  margin-bottom: 16px;
  height: ${(props) => (props.height ? props.height : "177px")};
  box-sizing: border-box;
  display: inline-block;
  vertical-align: top;
`;

const SchoolTypeBox = styled.div`
  background-color: #dde2ea;
  border-radius: 8px;
  padding: 4px 20px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-items: center;
  color: #021633;
  font-size: 16px;
  font-weight: 500;
`;

const CertifiedBox = styled.div`
  background-color: rgba(51, 216, 78, 0.1);
  border-radius: 8px;
  padding: 4px 20px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-items: center;
  color: #33d84e;
`;

const InfoCardTitle = styled.div`
  color: #212529;
  font-size: 20px;
  font-weight: 500;
  margin-right: 8px;
`;

const InfoCardContent = styled.div`
  color: #212529;
  font-size: 16px;
  font-weight: 500;
`;

const StateBox = styled.div`
  background-color: rgba(99, 55, 252, 0.1);
  padding: 3px 12px;
  border-radius: 4px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #6337fc;
`;

const ModifyText = styled.div`
  color: #868e96;
  font-size: 16px;
  font-weight: 500;
  text-decoration: underline;
  text-decoration-color: #868e96;
  cursor: pointer;
`;

const PopupContent = styled.div`
  color: ${(props) => (props.yellow ? "#FFAD2E" : "#212529")};
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8px;
  display: inline-block;
`;

const IntroduceBox = styled.div`
  width: 100%;
  border: 1px solid #dde2ea;
  border-radius: 12px;
  padding: 32px;
  color: ${(props) => (props.blank ? "#adb5bd" : "#212529")};
  font-size: ${(props) => (props.small ? "16px" : "20px")};
  font-weight: ${(props) => (props.small ? 400 : 500)};
  margin: 20px 0px 80px;
  white-space: pre-wrap;
`;

const SubjectBox = styled.div`
  background-color: ${(props) => (props.active ? "#021633" : "#FFFFFF")};
  color: ${(props) => (props.active ? "#ffffff" : "#021633")};
  border: 1px solid #021633;
  display: inline-block;
  padding: 9px 20px;
  border-radius: 22px;
  margin-right: 16px;
  margin-bottom: 16px;
  cursor: pointer;
`;

export {
  Container,
  InfoTitleWrap,
  InfoTitle,
  LinkText,
  InfoWrap,
  InfoListTitle,
  InfoListContent,
  RowWrap,
  SubjectText,
  HistoryCard,
  ConditionText,
  VerticalBorder,
  NameText,
  FinishBox,
  HistoryCardTtitle,
  HistoryCardContent,
  PopupTitle,
  PopupCloseButton,
  ReviewName,
  StarWrap,
  StarNoticeText,
  StarNumberText,
  PaymentListWrap,
  PaymentDateText,
  PaymentType,
  PaymentTitle,
  AccountText,
  PaymentPrice,
  EmptyText,
  InfoCarWrap,
  SchoolTypeBox,
  CertifiedBox,
  InfoCardTitle,
  InfoCardContent,
  StateBox,
  ModifyText,
  PopupContent,
  IntroduceBox,
  SubjectBox
};
