import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import { useRecoilState, useResetRecoilState, useSetRecoilState } from "recoil";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Pagination } from "swiper";
import "swiper/css";
import TextInput from "./TextInput";
import Search from "../assets/icons/search.svg";
import Button from "../components/Button";
import Modal from "../components/Modal";
import searchValueState from "../states/searchValueState";
import { Desktop, Mobile } from "../utils/mediaQuery";

const Wrap = styled.div`
  display: flex;
  overflow-x: hidden;

  @media screen and (max-width: 1280px) {
    width: 1200px;
    padding: 0px 40px;
  }
`;
const Container = styled.div`
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  min-width: 260px;
  margin-right: 154px;

  @media screen and (max-width: 1280px) {
    margin-right: 102px;
    min-width: 200px;
  }
`;
const TitleWrap = styled.div`
  height: 60px;
  font-size: 40px;
  font-weight: ${(props) => props.theme.fontWeight.bold};
  color: #021633;
  margin-bottom: 33px;
`;
const BtnWrap = styled.div`
  padding-top: 20px;
  border-top: 2px solid #e9ecef;
  display: flex;
  flex-direction: column;
`;
const SideBtn = styled.button`
  width: fit-content;
  height: 60px;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: ${(props) => props.theme.fontWeight.medium};
  margin-bottom: 20px;
  padding: 16px 40px 15px 40px;
  border-radius: 30px;
  color: ${(props) =>
    props.active ? props.theme.color.white : props.theme.color.primary};
  background-color: ${(props) =>
    props.active ? props.theme.color.orange : props.theme.color.white};
  border: 1px solid;
  border-color: ${(props) =>
    props.active ? "transparent" : props.theme.color.primary};
  transition: 0.1s;

  &:hover {
    color: ${(props) => props.theme.color.white};
    background-color: ${(props) => props.theme.color.orange};
    border-color: transparent;
  }

  @media screen and (max-width: 1280px) {
    font-size: 16px;
    padding: 14px 32px 14px 32px;
  }
`;

const Contents = styled.div`
  width: 100%;

  @media screen and (max-width: 1280px) {
    width: 820px;
  }
`;
const TopWrap = styled.div`
  width: 100%;
  margin-bottom: 12px;
`;
const SeachContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
const SearchInputWrap = styled.div`
  width: 466px;
  position: relative;
`;
const SearchIcon = styled.img.attrs({ src: `${Search}` })`
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
`;
const BannerWrap = styled.div`
  border-top: 2px solid #e9ecef;
  margin-top: 32px;
  padding-top: 20px;
  min-width: 950px;

  @media screen and (max-width: 1280px) {
    min-width: auto;
  }
`;
const Banner = styled.div`
  width: 100%;
  padding: 32px;
  background-color: #f6f9ff;
  border-radius: 12px;
`;

const ModalContent = styled.span`
  text-align: center;
  color: ${(props) =>
    props.orange ? props.theme.color.orange : props.theme.color.textColor};
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  white-space: pre-line;
`;

const SubSideBtn = styled.div`
  color: ${(props) => (props.active ? "#021633" : "#adb5bd")};
  font-size: 20px;
  font-weight: ${(props) => (props.active ? 700 : 500)};
  line-height: 29px;
  margin-bottom: 20px;
  margin-left: 40px;
  cursor: pointer;
  transition: 0.5s;
`;

const SideBtnWrap = styled.div`
  overflow: hidden;
  transition: max-height 1s ease-out;
  opacity: ${(props) => (props.mainTabActive ? 1 : 0)};
  visibility: ${(props) => (props.mainTabActive ? "visible" : "hidden")};
  max-height: ${(props) => (props.mainTabActive ? "500px" : "0px")};
`;

/*********** 모바일 관련 스타일 *************/
const TabContainer = styled.div`
  padding: 56px 24px 0px 24px;
  .swiper {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
  .swiper-slide {
    width: auto;
  }
`;
const TabSubContainer = styled.div`
  padding: 0px 24px 0px 24px;
  .swiper {
    position: relative;

    left: 50%;
    transform: translateX(-50%);
  }
  .swiper-slide {
    width: auto !important;
  }
`;

const MobileTypesBox = styled.button`
  border: 1px solid #dde2ea;
  height: 40px;
  width: auto;
  box-sizing: border-box;
  border-radius: 4px;
  font-weight: 500;
  font-size: 16px;
  line-height: 17px;
  background-color: white;
  text-align: center;

  padding: 8px 12px;
  color: ${(props) =>
    props.active ? props.theme.color.white : props.theme.color.primary};
  background-color: ${(props) =>
    props.active ? props.theme.color.orange : props.theme.color.white};
  transition: 0.5s;
`;

const MobileSubTypesBox = styled.button`
  border: 1px solid #021633;
  height: 29px;
  width: auto;
  box-sizing: border-box;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  background-color: white;
  text-align: center;
  padding: 4px 12px;
  color: ${(props) =>
    props.active ? props.theme.color.white : props.theme.color.primary};
  background-color: ${(props) =>
    props.active ? "#021633" : props.theme.color.white};
  transition: 0.5s;
`;

export const InfoBanner = styled.div`
  width: calc(100% - 48px);
  min-width: 342px;

  background: #f6f9ff;
  border-radius: 4px;
  margin: 16px 24px;
  padding: 16px;
  > h2 {
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
    color: #021633;
    span {
      color: #ffad2e;
    }
  }

  > p {
    white-space: pre-line;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #212529;
    margin-top: 9px;
  }
`;
/*****************************************/

const SideBar = (props) => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);
  const [subActiveTab, setSubActiveTab] = useState(0);
  const [currentTab, setCurrentTab] = useState(false);
  const [searchValue, setSearchValue] = useRecoilState(searchValueState);
  const [btnVisible, setBtnVisible] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [searchResult, setSearchResult] = useState();
  const [seminar, setSeminar] = useState(false);
  const [qna, setQna] = useState(false);
  const tabClickHandler = (idx) => {
    setActiveTab(idx);
    // props.tabIndex(idx);
    if (idx !== activeTab) {
      setSearchValue("");
    }
  };

  const subTabClickHandler = (idx) => {
    setSubActiveTab(idx);
    props.subTabIndex(idx);
    if (idx !== subActiveTab) {
      setSearchValue("");
    }
  };

  useEffect(() => {
    if (props.activeTab !== null && props.activeTab !== undefined) {
      setActiveTab(props.activeTab);
    }
  }, [props]);

  useEffect(() => {
    if (props.seminar !== undefined) {
      setSeminar(true);
    }
    if (props.qna !== undefined) {
      setQna(true);
    }
  }, []);

  const contents = props.contents;
  const array = props.array;
  const currentTabName = props.currentTabName;
  const page = props.page;

  const handleMobileSidebarClick = (data, idx) => {
    setActiveTab(idx);
    switch (page) {
      case "mentoPick": {
        props.setTabName(data.id);
        break;
      }
      case "conferenceRoom": {
        props.setTabName(data.name);
        break;
      }
      case "contentsMarket": {
        if (data.id === 1) {
          if (props.userType !== "TEACHER") {
            props.setTeacherModal(true);
            setActiveTab(0);
          }
        }
        break;
      }
      case "QnA": {
        props.setTabName(data.id);
        break;
      }
      default: {
        break;
      }
    }
  };

  return (
    <>
      <Desktop>
        <Wrap>
          <Container>
            <TitleWrap>{props.children}</TitleWrap>
            <BtnWrap>
              {array.map((data, idx) => (
                <div key={idx}>
                  <SideBtn
                    onClick={() => {
                      props.tabName(data.id);
                      setSubActiveTab(0);
                      setSearchValue("");
                      tabClickHandler(idx);
                      if (idx === 8) {
                        setBtnVisible(true);
                      } else {
                        setBtnVisible(false);
                      }
                    }}
                    active={idx === activeTab ? true : false}
                  >
                    {data.name}
                  </SideBtn>
                  {array[activeTab].sub && (
                    <SideBtnWrap
                      mainTabActive={idx === activeTab ? true : false}
                    >
                      {data.id === activeTab &&
                        array[activeTab].sub.map((item, subIdx) => (
                          <SubSideBtn
                            key={subIdx}
                            onClick={() => {
                              props.subTabName(item.name);
                              setSearchValue("");
                              subTabClickHandler(subIdx);

                              if (props.subTabId) {
                                props.subTabId(item.id);
                              }
                            }}
                            active={subIdx === subActiveTab ? true : false}
                          >
                            {item.name}
                          </SubSideBtn>
                        ))}
                    </SideBtnWrap>
                  )}
                </div>
              ))}
            </BtnWrap>
          </Container>

          <Contents>
            <TopWrap>
              <SeachContainer>
                {!props.disableSearch && (
                  <SearchInputWrap>
                    <TextInput
                      placeholder="찾으시는 정보를 입력해주세요."
                      value={searchValue}
                      onChange={(value) => {
                        setSearchValue(value);
                      }}
                      style={{ width: 466, paddingRight: 50 }}
                      marginBottom={"0px"}
                      onKeyPress={props.onKeyPress}
                    />
                    <SearchIcon onClick={props.onClick} />
                  </SearchInputWrap>
                )}
                {btnVisible ? (
                  <Button
                    orange
                    style={{ width: 260 }}
                    onClick={() => {
                      setOpenModal(true);
                    }}
                  >
                    상담하기
                  </Button>
                ) : null}
                {qna ? (
                  <Button
                    orange
                    style={{ width: 260 }}
                    onClick={() => {
                      navigate("/QnaRegister");
                    }}
                  >
                    질문하기
                  </Button>
                ) : null}
              </SeachContainer>
              <BannerWrap>
                <Banner>
                  {props.bannerTitle}
                  {props.bannerDesc}
                </Banner>
              </BannerWrap>
            </TopWrap>
            {seminar ? contents[activeTab] : contents}
          </Contents>
          {openModal ? (
            <Modal
              title={"전화상담"}
              htmlContnet={
                <ModalContent>
                  {" 고객센터 "}
                  <ModalContent orange>02-536-5862</ModalContent>로 문의
                  가능합니다.
                </ModalContent>
              }
              button={[
                {
                  title: "확인",
                  type: "active",
                },
              ]}
              onPress={() => {
                setOpenModal(false);
              }}
            />
          ) : null}
        </Wrap>
      </Desktop>
      <Mobile>
        <TabContainer>
          <Swiper
            style={{ marginTop: 16 }}
            spaceBetween={8}
            modules={[FreeMode, Pagination]}
            slidesPerView={"auto"}
            freeMode={true}
          >
            {array.map((data, idx) => (
              <SwiperSlide>
                <MobileTypesBox
                  onClick={() => {
                    handleMobileSidebarClick(data, idx);
                  }}
                  active={idx === activeTab ? true : false}
                >
                  {data.name}
                </MobileTypesBox>
              </SwiperSlide>
            ))}
          </Swiper>
        </TabContainer>
        {page === "contentsMarket" && (
          <TabSubContainer>
            <Swiper
              style={{ marginTop: 16 }}
              spaceBetween={8}
              modules={[FreeMode, Pagination]}
              slidesPerView={"auto"}
              freeMode={true}
            >
              {array[activeTab].sub.map((el, subIdx) => (
                <SwiperSlide>
                  <MobileSubTypesBox
                    onClick={() => {
                      console.log(el.name);
                      setSubActiveTab(subIdx);
                      props.setSubTabName(el.name);
                    }}
                    active={subIdx === subActiveTab ? true : false}
                  >
                    {el.name}
                  </MobileSubTypesBox>
                </SwiperSlide>
              ))}
            </Swiper>
          </TabSubContainer>
        )}
        <InfoBanner>
          {props.bannerTitle}
          {props.bannerDesc}
        </InfoBanner>
        {/* <InfoBanner>
          <h2>영재멘토 앱 다운로드</h2>
          <br />
          <a
            target="_blank"
            href="https://alba-edu.s3.ap-northeast-2.amazonaws.com/giftedmentor.apk"
          >
            <span>안드로이드 APK</span>
          </a>
          <br />
          <br />
          <a target="_blank" href="https://testflight.apple.com/join/vZRmwoz2">
            <span>iOS TestFlight</span>
          </a>
        </InfoBanner> */}
      </Mobile>
    </>
  );
};

export default SideBar;
