import styled from "styled-components";
import {
  AccountNotRegisterBox,
  InfoListContent,
  InfoListTitle,
  InfoTitle,
  InfoWrap,
  LinkText,
  RowWrap,
} from "../teacher/tab/style";

export const MobileMypageTab = styled.div`
  padding-left: 24px;
  .swiper {
    width: 100%;
  }

  .swiper-slide {
    width: auto;
    button {
      padding: 0;
      padding-bottom: 10px;
      background-color: transparent;
      border: none;
      font-weight: 400;
      font-size: 16px;
      line-height: 23px;
      text-align: center;
      color: #868e96;
    }
  }

  > hr {
    margin: 0;
    border: none;
    width: 390px;
    height: 2px;
    z-index: -1;
    margin-top: -2px;
    background-color: #e9ecef;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
`;

export const MobileInformationContainer = styled.div`
  padding: 24px 24px 100px 24px;
`;

export const MobileInfoTitle = styled(InfoTitle)`
  font-weight: 700;
  font-size: 16px;
  line-height: 23px;
`;

export const MobileLinkText = styled(LinkText)`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
`;

export const MobileInfoWrap = styled(InfoWrap)`
  padding: 8px 16px;
  border-radius: 4px;
`;

export const MobileInfoListTitle = styled(InfoListTitle)`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  padding: 8px 0px;
`;

export const MobileInfoListContents = styled(InfoListContent)`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #212529;
  padding: 8px 0px;
`;

export const MobileRowWrap = styled(RowWrap)`
  justify-content: space-between;
`;

export const MobileAccountNotRegistBox = styled(AccountNotRegisterBox)`
  width: 342px;
  height: auto;
  padding: 16px 131px;
  > div {
    button {
      display: flex;
      align-items: center;
      padding: 0;
      border: none;
      background-color: transparent;
      > img {
        width: 24px;
        height: 24px;
        margin-right: 4px;
      }
      > p {
        font-weight: 500;
        white-space: nowrap;
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
`;
