import React, { useState, useEffect } from "react";
import styled from "styled-components";

import { ReactComponent as CheckIcon } from "../assets/icons/checkbox.svg";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  margin-bottom: 16px;
`;

const TrueCheckBoxContainer = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='24' height='24' viewBox='0 0 24 24'%3E%3Cdefs%3E%3CclipPath id='clip-path'%3E%3Crect id='사각형_145' data-name='사각형 145' width='24' height='24' transform='translate(575 3121)' fill='%23fff' stroke='%23dee2e6' stroke-width='1'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg id='마스크_그룹_59' data-name='마스크 그룹 59' transform='translate(-575 -3121)' clip-path='url(%23clip-path)'%3E%3Cg id='b' transform='translate(575 3121)'%3E%3Cg id='c'%3E%3Cpath id='패스_262' data-name='패스 262' d='M0,0H24V24H0Z' fill='%23adb5bd' opacity='0' style='isolation: isolate'/%3E%3Cpath id='패스_270' data-name='패스 270' d='M9,0A9,9,0,1,1,0,9,9,9,0,0,1,9,0Z' transform='translate(3 3)' fill='%23021633' stroke='%23021633' stroke-width='1.6'/%3E%3Cpath id='패스_263' data-name='패스 263' d='M16.115,8.865,10.75,14.23,7.885,11.365a.9.9,0,0,0-1.27,1.275l3.5,3.5a.893.893,0,0,0,1.27,0l6-6a.9.9,0,1,0-1.27-1.275Z' fill='%23fff'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
`;

const FalseCheckBoxContainer = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='24' height='24' viewBox='0 0 24 24'%3E%3Cdefs%3E%3CclipPath id='clip-path'%3E%3Crect id='사각형_145' data-name='사각형 145' width='24' height='24' transform='translate(575 3121)' fill='%23fff' stroke='%23dee2e6' stroke-width='1'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg id='마스크_그룹_59' data-name='마스크 그룹 59' transform='translate(-575 -3121)' clip-path='url(%23clip-path)'%3E%3Cg id='b' transform='translate(575 3121)'%3E%3Cg id='c'%3E%3Cpath id='패스_262' data-name='패스 262' d='M0,0H24V24H0Z' fill='%23adb5bd' opacity='0' style='isolation: isolate'/%3E%3Cpath id='패스_270' data-name='패스 270' d='M9,0A9,9,0,1,1,0,9,9,9,0,0,1,9,0Z' transform='translate(3 3)' fill='%23adb5bd' stroke='%23adb5bd' stroke-width='1.6'/%3E%3Cpath id='패스_263' data-name='패스 263' d='M16.115,8.865,10.75,14.23,7.885,11.365a.9.9,0,0,0-1.27,1.275l3.5,3.5a.893.893,0,0,0,1.27,0l6-6a.9.9,0,1,0-1.27-1.275Z' fill='%23fff'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
`;

const Label = styled.div`
  color: #212529;
  font-size: 16px;
  font-weight: 500;
  margin-left: 12px;
  line-height: 32px;
`;

const ShowDetails = styled.div`
  color: #0081f5;
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
`;

const CheckBox = ({
  value,
  onChange,
  label,
  textStyle,
  style,
  mandatory,
  showDetailOnPress,
  all,
}) => {
  return (
    <Container
      onClick={() => {
        if (onChange) {
          onChange(!value);
        }
        return !value;
      }}
      style={style}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        {value ? (
          <TrueCheckBoxContainer active={value} />
        ) : (
          <FalseCheckBoxContainer />
        )}
        {label &&
          (all ? (
            <Label active={value} style={textStyle}>
              {label}
            </Label>
          ) : (
            <Label active={value} style={textStyle}>
              {mandatory ? `${label}(필수)` : `${label}(선택)`}
            </Label>
          ))}
      </div>
      {all === undefined && (
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <ShowDetails onClick={showDetailOnPress}>내용보기</ShowDetails>
        </div>
      )}
    </Container>
  );
};

export default CheckBox;
