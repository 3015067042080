import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import {
  RowWrap,
  ConditionText,
  VerticalBorder,
  MarketItemsWrap,
  ItemListWrap,
  MarkteItemsImageWrap,
  MarketItemTitle,
  MarketItemType,
  MarketItemPrice,
  PaginationWrap,
} from "./style";

import Pagination from "../../components/Pagination";
import Modal from "../../components/Modal";
import { Desktop, Mobile } from "../../utils/mediaQuery";
import {
  MobileMarketListContainer,
  MobileMarketTabContainer,
  MobileMarketListBox,
  IsPaid,
} from "./mobile.style";
import { InView } from "react-intersection-observer";
import MobileModal from "../../components/MobileModal";

const ContentMarketList = (props) => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("ALL");
  const [paidModal, setPaidModal] = useState(false);
  const [isPaid, setIsPaid] = useState(false);
  const [login, setLogin] = useState(false);
  const [loginModal, setLoginModal] = useState(false);
  const [page, setPage] = useState(1);

  const tabs = [
    { kor: "전체", eng: "ALL" },
    { kor: "무료", eng: "FREE" },
    { kor: "유료", eng: "PAID" },
  ];

  useEffect(() => {
    setIsPaid(props.isPaid);
    setLogin(props.login);
  }, [props]);

  useEffect(() => {
    if (props.onPaidTypeChange) {
      props.onPaidTypeChange(activeTab);
    }
  }, [activeTab]);

  useEffect(() => {
    props.onPageChange(page);
    window.scrollTo(0, 0);
  }, [page]);

  return (
    <>
      <Desktop>
        <RowWrap
          style={{ alignItems: "center", marginTop: 48, marginBottom: 32 }}
        >
          <ConditionText
            onClick={() => {
              setActiveTab("ALL");
            }}
            active={activeTab === "ALL"}
          >
            전체
          </ConditionText>
          <VerticalBorder />
          <ConditionText
            onClick={() => {
              setActiveTab("PAID");
            }}
            active={activeTab === "PAID"}
          >
            유료
          </ConditionText>
          <VerticalBorder />
          <ConditionText
            onClick={() => {
              setActiveTab("FREE");
            }}
            active={activeTab === "FREE"}
          >
            무료
          </ConditionText>
        </RowWrap>
        <ItemListWrap>
          {props.items.map((item, idx) => (
            <MarketItemsWrap
              noMargin={idx % 3 === 0}
              key={item.id}
              onClick={() => {
                if (login === false) {
                  setLoginModal(true);
                  document.body.style.overflow = "hidden";
                } else {
                  if (isPaid === true) {
                    navigate(`/contentsMarket/${item.id}`);
                  } else {
                    setPaidModal(true);
                    document.body.style.overflow = "hidden";
                  }
                }
              }}
            >
              <MarkteItemsImageWrap>
                <img
                  alt=""
                  src={
                    item.previewImage
                      ? item.previewImage
                      : require("../../assets/logos/logo_symbol_type.png")
                  }
                />
              </MarkteItemsImageWrap>
              <MarketItemTitle>{item.title}</MarketItemTitle>
              <RowWrap>
                <MarketItemPrice
                  price={item.price === 0 ? "무료" : `${item.price}원`}
                >
                  {item.price === 0 ? "무료" : `${item.price}원`}
                </MarketItemPrice>
                <MarketItemType>{item.category.name}</MarketItemType>
              </RowWrap>
            </MarketItemsWrap>
          ))}
          {/* <MarketItemsWrap
          onClick={() => {
            if (login === false) {
              setLoginModal(true);
              document.body.style.overflow = "hidden";
            } else {
              if (isPaid === true) {
                navigate("/contentsMarket/:id");
              } else {
                setPaidModal(true);
                document.body.style.overflow = "hidden";
              }
            }
          }}
        >
          <MarkteItemsImageWrap>
            <img src={require("../../assets/logos/logo_symbol_type.png")} />
          </MarkteItemsImageWrap>
          <MarketItemTitle>KMO1차 대비 문제모음집</MarketItemTitle>
          <RowWrap>
            <MarketItemPrice price={"무료"}>무료</MarketItemPrice>
            <MarketItemType>필기정보</MarketItemType>
          </RowWrap>
        </MarketItemsWrap>
        <MarketItemsWrap
          onClick={() => {
            if (login === false) {
              setLoginModal(true);
              document.body.style.overflow = "hidden";
            } else {
              if (isPaid === true) {
                navigate("/contentsMarket/:id");
              } else {
                setPaidModal(true);
                document.body.style.overflow = "hidden";
              }
            }
          }}
        >
          <MarkteItemsImageWrap>
            <img src={require("../../assets/logos/logo_symbol_type.png")} />
          </MarkteItemsImageWrap>
          <MarketItemTitle>휘문중 탐구보고서 대회 수상작</MarketItemTitle>
          <RowWrap>
            <MarketItemPrice price={"무료"}>무료</MarketItemPrice>
            <MarketItemType>필기정보</MarketItemType>
          </RowWrap>
        </MarketItemsWrap> */}
        </ItemListWrap>
        <PaginationWrap>
          <Pagination
            page={page}
            totalItemsCount={props.totalPage * 15}
            showItemsCount={15}
            nowPage={setPage}
          />
        </PaginationWrap>
        {paidModal && (
          <Modal
            padding
            title={"죄송합니다."}
            content={"해당 서비스는 유료회원만 이용 가능합니다."}
            button={[
              {
                title: "유료회원 결제하기",
                type: "active",
              },
              {
                title: "닫기",
                type: "cancel",
              },
            ]}
            onPress={() => {
              setPaidModal(false);
              document.body.style.overflow = "auto";
            }}
            onClose={(state) => {
              setPaidModal(state);
            }}
          />
        )}
        {loginModal && (
          <Modal
            padding
            title={"로그인 안내"}
            content={"상세페이지 확인은 로그인 상태에서 가능합니다."}
            button={[
              {
                title: "로그인하기",
                type: "active",
              },
              {
                title: "닫기",
                type: "cancel",
              },
            ]}
            onPress={() => {
              setLoginModal(false);
              navigate("/login");
              document.body.style.overflow = "auto";
            }}
            onClose={(state) => {
              setLoginModal(state);
            }}
          />
        )}
      </Desktop>
      <Mobile>
        <MobileMarketListContainer>
          <MobileMarketTabContainer>
            {tabs.map((tab, id) => (
              <li>
                <button
                  style={{ color: activeTab === tab.eng ? "" : " #ADB5BD" }}
                  onClick={() => {
                    setActiveTab(tab.eng);
                  }}
                >
                  {tab.kor}
                </button>
              </li>
            ))}
          </MobileMarketTabContainer>
          <MobileMarketListBox>
            {props.items.map(
              (item) =>
                (item.category.name === props.subTabName ||
                  props.subTabName === "전체") && (
                  <InView as="li">
                    <button
                      style={{
                        background: item.previewImage ? `url(${item.previewImage})`
                          : "#eeeeee",
                        backgroundSize: "cover",
                      }}
                      onClick={() => {
                        if (login === false) {
                          setLoginModal(true);
                        } else {
                          if (isPaid === true) {
                            navigate(`/contentsMarket/${item.id}`);
                          } else {
                            setPaidModal(true);
                          }
                        }
                      }}
                    ></button>
                    <h2>{item.title}</h2>
                    <IsPaid price={item.price}>
                      <p>{item.price !== 0 ? "유료" : "무료"}</p>
                      <p>{item.category.name}</p>
                    </IsPaid>
                  </InView>
                )
            )}
          </MobileMarketListBox>
        </MobileMarketListContainer>
        {paidModal && (
          <MobileModal
            padding
            title={"죄송합니다."}
            content={"해당 서비스는 유료회원만 이용 가능합니다."}
            button={[
              {
                title: "유료회원 결제하기",
                type: "active",
              },
              {
                title: "닫기",
                type: "cancel",
              },
            ]}
            onPress={() => {
              setPaidModal(false);
              document.body.style.overflow = "auto";
            }}
            onClose={(state) => {
              setPaidModal(state);
            }}
          />
        )}
        {loginModal && (
          <MobileModal
            padding
            title={"로그인 안내"}
            content={"상세페이지 확인은 로그인 상태에서 가능합니다."}
            button={[
              {
                title: "로그인하기",
                type: "active",
              },
              {
                title: "닫기",
                type: "cancel",
              },
            ]}
            onPress={() => {
              setLoginModal(false);
              navigate("/login");
              document.body.style.overflow = "auto";
            }}
            onClose={(state) => {
              setLoginModal(state);
            }}
          />
        )}
      </Mobile>
    </>
  );
};

export default ContentMarketList;
