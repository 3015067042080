import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {useRecoilState} from "recoil";
import "swiper/css";
import Header from "../../components/Header";
import {Desktop, Mobile} from "../../utils/mediaQuery";
// import SideBar from "../../components/SideBar";
// import Footer from "../../components/Footer";
import searchValueState from "../../states/searchValueState";
import axios from "axios";
import {API_URL} from "../../API";
import Button from "../../components/Button";
import Ticker from "react-ticker";
import {PopupTitle} from "./style.js";
import {PopupBackground, CloseButtonWrap} from "../../components/Popup";
import {ReactComponent as CloseIcon} from "../../assets/icons/cancle_44px.svg";
import MobileBottomBar from "../../components/MobileBottomBar";
import MobileHeader from "../../components/MobileHeader";
import albaLogo from "../../assets/logos/logo_symbol_type.png";
import {Form} from "react-router-dom";
import KakaoMap from "./location";
import MobileKakaoMap from "./mobile-location";

import DropDown from "./dropdown/DropDown";
import closePopUpIcon from "./images/close-icon-grey.png";
import image1 from "./images/1.png";
import image2 from "./images/2.png";
import image3 from "./images/3.png";
import image4 from "./images/4.png";
import image5 from "./images/5.png";
import image6 from "./images/6.png";
import image7 from "./images/7.png";
import desktopImage1 from "./images/desktop1.png";
import mobileImage1 from "./images/mobile1.png";
import partner1 from "./images/partners/partner1.png";
import partner2 from "./images/partners/partner2.png";
import partner3 from "./images/partners/partner3.png";
import partner4 from "./images/partners/partner4.png";
import partner5 from "./images/partners/partner5.png";
import partner6 from "./images/partners/partner6.png";
import partner7 from "./images/partners/partner7.png";
import partner8 from "./images/partners/partner8.jpeg";
import partner9 from "./images/partners/partner9.jpeg";
import partner10 from "./images/partners/partner10.png";
import partner11 from "./images/partners/partner11.png";
import partner12 from "./images/partners/partner12.png";
import partner13 from "./images/partners/partner13.png";
import partner14 from "./images/partners/partner14.png";
import Footer from "../../components/Footer";
import MobileFooter from "./MobileFooter";

const Container = styled.div`
  margin: 0 0 0 0;
  padding: 0;
  width: 100%;
  //min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

const ImageContainer = styled.div`
  margin: 0 0 0 0;
  padding: 0;
  width: 100%;
  min-height: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

const BoxContainer = styled.div`
  width: 90%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding-top: 2rem;
  padding-bottom: 3rem;
  border-top: 4px solid #eeeeee;

  .ticker__element {
    display: flex;
    flex-direction: row;
    @media (min-width: 721px) {
      width: 215rem;
    }
  }
`;

const LogoTitle = styled.p`
  font-size: 20px;
  color: #212529;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

const LogoContainer = styled.div`
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
  margin-bottom: 1rem;
  overflow-x: auto;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const PopupContainer = styled.div`
  width: ${(props) => (props.width ? props.width : "536px")};
  height: ${(props) => (props.height ? props.height : "auto")};
  background-color: #ffffff;
  overflow-x: hidden;
  border-radius: 12px;
  position: relative;
  padding: 48px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
`;

const FormContainer = styled.div`
  margin-top: 2rem;
  display: flex;
  width: 100%;
  height: auto;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  color: white;
  margin-bottom: 1rem;

  @media (max-width: 721px) {
    margin-top: 0;
  }
`;

const FormButton = styled.button`
  position: fixed;
  bottom: 0;
  width: 14rem;
  height: 3.6rem;
  background-color: #ffad2e;
  color: #23395d;
  font-size: 1.2rem;
  font-weight: 700;
  border: none;
  margin-bottom: 4rem;
  border-radius: 8px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.20);
`;

const FormInput = styled.input`
  width: 100%;
  height: 2.6rem;
  border: none;
  border-radius: 0.3rem;
  margin-top: 0.6rem;
  margin-bottom: 1.5rem;
  padding: 0 0 0 1rem;
  background-color: #dde2ea;
  required: true;

  @media (max-width: 721px) {
    height: 2rem;
  }
`;

const FormSelect = styled.select`
  width: 105%;
  height: 2.6rem;
  border: none;
  border-radius: 0.3rem;
  margin-top: 0.6rem;
  margin-bottom: 1.5rem;
  padding: 0 0 0 1rem;
  background-color: #dde2ea;
  @media (max-width: 721px) {
    height: 2rem;
  }
`;

const FormSubmitButton = styled.button`
  width: 12rem;
  height: 3.6rem;
  background-color: #ffad2e;
  color: #23395d;
  font-size: 1.3rem;
  font-weight: 700;
  border: none;
  border-radius: 8px;
  margin-top: 0.3rem;
  margin-bottom: 1rem;
  @media (max-width: 721px) {
    width: 10rem;
    height: 3rem;
    margin-bottom: 1rem;
  }
`;

const FormRowContainer = styled.div`
  display: inline-flex;
  justify-content: space-between;
  //width: fit-content;
  @media (max-width: 721px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }
`;

const FormRowItem = styled.div`
  width: 45%;
  @media (max-width: 721px) {
    width: 43%;
    margin-right: 0.5rem;
  }
`;

const FormPageSpan = styled.span`
  font-size: 1.2rem;
  font-weight: 700;
  padding: 0.5rem;
  margin: 0 0.5rem 0 0.5rem;

  :active {
    color: #ffad2e;
  }

  :hover {
    color: #ffad2e;
    cursor: pointer;
  }
`;

const LocationContainer = styled.div`
  margin: 0 0 0 0;
  padding: 0;
  width: 100%;
  height: 34rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: lightgray;
`;

const KakaoMapContainer = styled.div`
  margin: auto;
  height: 20rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: lightgray;
`;

const MobileLocationContainer = styled.div`
  margin: 0 0 0 0;
  padding: 0;
  width: 100%;
  //height: 40rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: lightgray;
`;

const MobileKakaoMapContainer = styled.div`
  margin: 0 0 0 0;
  //width: 10rem;
  height: 70rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: lightgray;
`;

const DreamUpCamp = () => {
  const [modalOn, setModalOn] = useState(false); // 모달창 on/off
  const [isInputFocused, setInputFocused] = useState(false);
  const [formValues, setFormValues] = useState({
    parentName: "",
    school: "",
    studentName: "",
    grade: "",
    phone: "",
    applicationType: "",
    email: "",
    applicantType: "",
    groupType: "",
  });
  const [modalPageNum, setModalPageNum] = useState(1);
  const [invalid, setInvalid] = useState({});
  const [readyToSubmit, setReadyToSubmit] = useState(false);
  const [applicantTypeValue, setApplicantTypeValue] = useState("");
  const [applicationTypeValue, setApplicationTypeValue] = useState("");
  const [groupTypeValue, setGroupTypeValue] = useState("");

  const handleInputFocus = (id) => {
    setInputFocused((prevState) => ({
      ...prevState,
      [id]: true,
    }));
  };

  const handleInputChange = async (id, e) => {
    const phone_rule = new RegExp("[0-9]{2,3}-[0-9]{3,4}-[0-9]{3,4}");
    console.log("ready?", readyToSubmit);

    const inputValue = e.target.value;
    setFormValues((prev) => ({
      ...prev,
      [id]: inputValue,
    }));
    if (id === "phone" && inputValue.length !== 0) {
      if (phone_rule.test(inputValue)) {
        console.log("PHONE OK");
        setInvalid((prevState) => ({
          ...prevState,
          [id]: false,
        }));
      } else {
        setInvalid((prevState) => ({
          ...prevState,
          [id]: true,
        }));
        console.log(invalid);
        console.log("전화번호를 확인해 주세요.");
      }
    } else if (id === "email" && inputValue) {
      if (
        !inputValue?.includes(" ") &&
        inputValue?.includes("@") &&
        inputValue?.split("@")[1]?.includes(".")
      ) {
        setInvalid((prevState) => ({
          ...prevState,
          [id]: false,
        }));
        console.log("EMAIL OK");
      } else {
        console.log("이메일 주소를 확인해 주세요.");
        setInvalid((prevState) => ({
          ...prevState,
          [id]: true,
        }));
      }
    }

    setInputFocused((prevState) => ({
      ...prevState,
      [id]: inputValue !== "",
    }));
  };

  const handleInputBlur = (id) => {
    setInputFocused((prevState) => ({
      ...prevState,
      [id]: false,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("FORM: ", formValues, Object.keys(formValues).length);
    if (Object.values(formValues).filter((item) => item === "").length === 0) {
      axios({
        url: `${API_URL}/contentsMarket/dream-up-camp`,
        method: "POST",
        data: formValues,
      })
        .then((result) => {
          if (result.data.success) {
            console.log(result.data);
            setModalOn(false);
            setFormValues({
              parentName: "",
              school: "",
              studentName: "",
              grade: "",
              phone: "",
              applicationType: "",
              email: "",
              applicantType: "",
              groupType: "",
            });
            setInvalid({});
            setReadyToSubmit(false);
            window.alert("신청 해주셔서 감사합니다.");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else window.alert("모든 항목을 정확히 입력해주세요.");
  };

  useEffect(() => {
    if (
      Object.values(formValues).filter((item) => item === "").length === 0 &&
      Object.values(invalid).every((item) => item === false)
    ) {
      console.log("READY TO SUBMIT");
      setReadyToSubmit(true);
    } else {
      console.log(readyToSubmit);
      setReadyToSubmit(false);
    }
    console.log(formValues);
  }, [formValues]);
  useEffect(() => {
    setFormValues((prev) => ({
      ...prev,
      applicantType: applicantTypeValue,
      applicationType: applicationTypeValue,
      groupType: groupTypeValue,
    }));
  }, [applicantTypeValue, applicationTypeValue, groupTypeValue]);

  return (
    <>
      <Desktop>
        <ImageContainer>
          <img
            style={{
              width: "100%",
              height: "auto",
              objectFit: "contain",
            }}
            src={desktopImage1}
            alt="image"
          />
        </ImageContainer>
        <LocationContainer>
          <KakaoMapContainer>
            <div style={{marginRight: "6rem"}}>
              <p style={{fontSize: "34px", lineBreak: "anywhere", fontWeight: 700, color: "#707070"}}>사전 세미나 일정</p><br/><br/><br/><br/>
              <p style={{fontSize: "24px", fontWeight: 500, color: "#808080"}}>일시: 7월 22일(토) 11:00 ~ 13:00</p><br/><br/>
              <p style={{fontSize: "24px", fontWeight: 500, color: "#808080"}}>장소: 서울시 성동구 왕십리로 83-21</p><br/>
              <p style={{fontSize: "24px", fontWeight: 500, color: "#808080", marginLeft: "3.6rem"}}>아크로 서울포레스트 D타워 8층
                퓨처플레이</p><br/><br/>
              <p style={{fontSize: "24px", fontWeight: 500, color: "#808080"}}>대중교통 이용: 수인분당선 서울숲역 5번 출구</p><br/><br/>
              <p style={{fontSize: "18px", fontWeight: 500, color: "#808080", paddingBottom: "8px"}}> • 방문 시 1층 안내데스크에
                출입명부 확인 (예약필수)</p>
              <p style={{fontSize: "18px", fontWeight: 500, color: "#808080"}}>• 주차비 지원은 1시간까지 가능</p><br/><br/><br/>
              <a
                href="https://forms.gle/Cd59f714rrytKedL6"
                target="_blank"
                rel="noreferrer"
                style={{
                  textDecorationLine: "none",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center"
                }}>
                <FormButton style={{
                  position: "initial",
                  margin: "0px",
                  padding: "0px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center"
                }}>
                  <p
                    style={{
                      fontSize: "24px", fontWeight: 500, color: "#23395d",
                      margin: "auto",
                      padding: "auto"
                    }}>세미나 신청</p></FormButton></a>
            </div>
            <KakaoMap/>
          </KakaoMapContainer>
        </LocationContainer>
        <Container>
          <Container style={{flexDirection: "row"}}>
            <Container style={{paddingLeft: "16rem"}}>
              <LogoTitle
                style={{fontSize: "32px", marginTop: "6rem", marginBottom: "0"}}
              >
                주관사
              </LogoTitle>
              <LogoContainer>
                <img
                  style={{
                    width: "15rem",
                    height: "auto",
                    objectFit: "contain",
                    marginBottom: "1rem",
                  }}
                  src={albaLogo}
                  alt="logo"
                />
              </LogoContainer>
              <LogoTitle
                style={{fontSize: "18px", marginTop: "0", marginBottom: "6rem"}}
              >
                (주)알바트로스에듀
              </LogoTitle>
            </Container>
            <Container style={{paddingRight: "16rem"}}>
              <LogoTitle
                style={{fontSize: "32px", marginTop: "6rem", marginBottom: "0"}}
              >
                주최사
              </LogoTitle>
              <LogoContainer>
                <img
                  style={{
                    width: "12rem",
                    height: "auto",
                    objectFit: "contain",
                    marginBottom: "1rem",
                  }}
                  src={partner1}
                  alt="logo"
                />
              </LogoContainer>
              <LogoTitle
                style={{fontSize: "18px", marginTop: "0", marginBottom: "6rem"}}
              >
                한국초기투자기관협회
              </LogoTitle>
            </Container>
          </Container>
          <BoxContainer style={{width: "100%", borderBottom: "0px"}}>
            <LogoTitle style={{fontSize: "32px", marginTop: "6rem"}}>파트너사</LogoTitle>
            <LogoContainer style={{justifyContent: "center", marginBottom: "10rem"}}>
              <div style={{width: "100%"}}>
                <Ticker width={5000} height={80} speed={15}>
                  {() => (
                    <>
                      {/*<img*/}
                      {/*  style={{*/}
                      {/*    width: "10rem",*/}
                      {/*    height: "5rem",*/}
                      {/*    marginRight: "5rem",*/}
                      {/*    objectFit: "contain",*/}
                      {/*  }}*/}
                      {/*  src={partner1}*/}
                      {/*  alt="logo"*/}
                      {/*/>*/}
                      <img
                        style={{
                          width: "8rem",
                          height: "5rem",
                          marginRight: "5rem",
                          objectFit: "contain",
                        }}
                        src={partner2}
                        alt="logo"
                      />
                      <img
                        style={{
                          width: "12rem",
                          height: "5rem",
                          marginRight: "5rem",
                          objectFit: "contain",
                        }}
                        src={partner3}
                        alt="logo"
                      />
                      <img
                        style={{
                          width: "10rem",
                          height: "5rem",
                          marginRight: "5rem",
                          objectFit: "contain",
                        }}
                        src={partner4}
                        alt="logo"
                      />
                      <img
                        style={{
                          width: "12remㅇ",
                          height: "5rem",
                          marginRight: "5rem",
                          objectFit: "contain",
                        }}
                        src={partner5}
                        alt="logo"
                      />
                      <img
                        style={{
                          width: "12rem",
                          height: "5rem",
                          marginRight: "5rem",
                          objectFit: "contain",
                        }}
                        src={partner6}
                        alt="logo"
                      />
                      <img
                        style={{
                          width: "12rem",
                          height: "5rem",
                          marginRight: "5rem",
                          objectFit: "contain",
                        }}
                        src={partner7}
                        alt="logo"
                      />
                      <img
                        style={{
                          width: "12rem",
                          height: "5rem",
                          marginRight: "5rem",
                          objectFit: "contain",
                        }}
                        src={partner8}
                        alt="logo"
                      />
                      <img
                        style={{
                          width: "12rem",
                          height: "5rem",
                          marginRight: "5rem",
                          objectFit: "contain",
                        }}
                        src={partner9}
                        alt="logo"
                      />
                      <img
                        style={{
                          width: "12rem",
                          height: "5rem",
                          marginRight: "5rem",
                          objectFit: "contain",
                        }}
                        src={partner10}
                        alt="logo"
                      />
                      <img
                        style={{
                          width: "12rem",
                          height: "5rem",
                          marginRight: "5rem",
                          objectFit: "contain",
                        }}
                        src={partner11}
                        alt="logo"
                      />
                      <img
                        style={{
                          width: "12rem",
                          height: "5rem",
                          marginRight: "5rem",
                          objectFit: "contain",
                        }}
                        src={partner12}
                        alt="logo"
                      />
                      <img
                        style={{
                          width: "12rem",
                          height: "5rem",
                          marginRight: "5rem",
                          objectFit: "contain",
                        }}
                        src={partner13}
                        alt="logo"
                      />
                      <img
                        style={{
                          width: "12rem",
                          height: "5rem",
                          marginRight: "5rem",
                          objectFit: "contain",
                        }}
                        src={partner14}
                        alt="logo"
                      />
                    </>
                  )}
                </Ticker>
              </div>
            </LogoContainer>
          </BoxContainer>
          <FormButton onClick={() => setModalOn(true)}>
            {" "}
            신청서 제출하기{" "}
          </FormButton>
          {modalOn && (
            <PopupBackground>
              <PopupContainer
                width="50rem"
                style={{
                  backgroundColor: "#23395d",
                  color: "white",
                  padding: "2.5rem 0.5rem 0.5rem 0.5rem",
                  minHeight: "60vh",
                }}
              >
                <CloseButtonWrap
                  onClick={() => {
                    document.body.style.overflow = "auto";
                    setModalOn(false);
                    setFormValues({
                      parentName: "",
                      school: "",
                      studentName: "",
                      grade: "",
                      phone: "",
                      applicationType: "",
                      email: "",
                      applicantType: "",
                      groupType: "",
                    });
                    setInvalid({});
                    setReadyToSubmit(false);
                    setApplicantTypeValue("");
                    setApplicationTypeValue("");
                    setGroupTypeValue("");
                  }}
                >
                  <img
                    style={{
                      width: "2.5rem",
                      height: "auto",
                      objectFit: "contain",
                    }}
                    src={closePopUpIcon}
                    alt="logo"
                  />
                </CloseButtonWrap>
                <PopupTitle style={{color: "white", borderColor: "white"}}>
                  Dream Up 창업캠프 신청하기
                </PopupTitle>
                <FormContainer>
                  <form
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div
                      style={{
                        alignSelf: "center",
                        width: "90%",
                        display: "flex",
                        flexDirection: "column",
                        alightItems: "flex-start",
                        justifyContent: "center",
                      }}
                    >
                      <FormRowContainer>
                        <FormRowItem>
                          <label htmlFor="parentName">학부모 성함</label>
                          <FormInput
                            type="text"
                            id="parentName"
                            name="parentName"
                            onFocus={() => handleInputFocus("parentName")}
                            onBlur={() => handleInputBlur("parentName")}
                            onChange={(e) => handleInputChange("parentName", e)}
                            value={formValues["parentName"]}
                            // placeholder={
                            //   !isInputFocused["parentName"]
                            //     ? "학부모 성함을 입력해주세요."
                            //     : ""
                            // }
                            required
                          />
                        </FormRowItem>
                        <FormRowItem>
                          <label htmlFor="school">학교</label>
                          <FormInput
                            type="text"
                            id="school"
                            name="school"
                            onFocus={() => handleInputFocus("school")}
                            onBlur={() => handleInputBlur("school")}
                            onChange={(e) => handleInputChange("school", e)}
                            value={formValues["school"]}
                            // placeholder={
                            //   !isInputFocused["school"]
                            //     ? "학교명을 입력해주세요."
                            //     : ""
                            // }
                            required
                          />
                        </FormRowItem>
                      </FormRowContainer>
                      <FormRowContainer>
                        <FormRowItem>
                          <label htmlFor="studentName">학생 이름</label>
                          <FormInput
                            type="text"
                            id="studentName"
                            name="studentName"
                            onFocus={() => handleInputFocus("studentName")}
                            onBlur={() => handleInputBlur("studentName")}
                            onChange={(e) =>
                              handleInputChange("studentName", e)
                            }
                            value={formValues["studentName"]}
                            // placeholder={
                            //   !isInputFocused["studentName"]
                            //     ? "학생 이름을 입력해주세요."
                            //     : ""
                            // }
                            required
                          />
                        </FormRowItem>
                        <FormRowItem>
                          <label htmlFor="grade">학년</label>
                          <FormInput
                            type="text"
                            id="grade"
                            name="grade"
                            onFocus={() => handleInputFocus("grade")}
                            onBlur={() => handleInputBlur("grade")}
                            onChange={(e) => handleInputChange("grade", e)}
                            value={formValues["grade"]}
                            // placeholder={
                            //   !isInputFocused["grade"] ? "ex) 1학년" : ""
                            // }
                            required
                          />
                        </FormRowItem>
                      </FormRowContainer>
                      <FormRowContainer>
                        <FormRowItem style={{marginBottom: "1rem"}}>
                          <label htmlFor="phone">연락처</label>
                          <FormInput
                            type="phone"
                            id="phone"
                            name="phone"
                            onFocus={() => handleInputFocus("phone")}
                            onBlur={() => handleInputBlur("phone")}
                            onChange={(e) => handleInputChange("phone", e)}
                            value={formValues["phone"]}
                            placeholder={
                              !isInputFocused["phone"]
                                ? "ex) 010-xxxx-xxxx"
                                : ""
                            }
                            required
                            style={{marginBottom: "0.2rem"}}
                          />
                          {formValues["phone"] && invalid.phone && (
                            <p
                              style={{
                                margin: "0.2rem 0 0 0",
                                fontSize: "small",
                                color: "red",
                              }}
                            >
                              {" "}
                              연락처를 확인해 주세요.
                            </p>
                          )}
                        </FormRowItem>
                        <FormRowItem>
                          <label htmlFor="applicationType">신청 분야</label>
                          {/* <FormSelect
                            name="applicationType"
                            onChange={(e) =>
                              handleInputChange("applicationType", e)
                            }
                            value={formValues["applicationType"]}
                            required
                          >
                            <option value="" hidden>
                              선택
                            </option>
                            <option value="robotics">Robotics</option>
                            <option value="avionics">Avionics</option>
                            <option value="healthcare">Health Care</option>
                            <option value="environment">Environment</option>
                            <option value="genetic_ai">Genetic AI</option>
                          </FormSelect> */}
                          <DropDown
                            list={[
                              "robotics",
                              "avionics",
                              "healthcare",
                              "environment",
                              "genetic_ai",
                            ]}
                            handleClick={setApplicationTypeValue}
                          />
                        </FormRowItem>
                      </FormRowContainer>
                      <FormRowContainer style={{marginBottom: "0.5rem"}}>
                        <FormRowItem>
                          <label htmlFor="email">Email</label>
                          <FormInput
                            type="email"
                            id="email"
                            name="email"
                            onFocus={() => handleInputFocus("email")}
                            onBlur={() => handleInputBlur("email")}
                            onChange={(e) => handleInputChange("email", e)}
                            value={formValues["email"]}
                            placeholder={
                              !isInputFocused["email"]
                                ? "ex) dreamup@camp.com"
                                : ""
                            }
                            style={{marginBottom: "0.2rem"}}
                          />
                          {formValues["email"] && invalid.email && (
                            <p
                              style={{
                                margin: "0.2rem 0 0 0",
                                fontSize: "small",
                                color: "red",
                              }}
                            >
                              {" "}
                              Email을 확인해 주세요.
                            </p>
                          )}
                        </FormRowItem>
                        <FormRowItem>
                          <label htmlFor="applicantType">학부모 / 학생</label>
                          {/* <FormSelect
                            name="applicantType"
                            onChange={(e) =>
                              handleInputChange("applicantType", e)
                            }
                            value={formValues["applicantType"]}
                            required
                          >
                            <option value="" hidden>
                              선택
                            </option>
                            <option value="parent">학부모</option>
                            <option value="student">학생</option>
                          </FormSelect> */}
                          <DropDown
                            list={["student", "parent"]}
                            handleClick={setApplicantTypeValue}
                          />
                        </FormRowItem>
                      </FormRowContainer>
                      <FormRowContainer>
                        <FormRowItem>
                        </FormRowItem>
                        <FormRowItem>
                          <label htmlFor="groupType">개인 / 팀</label>
                          <DropDown
                            list={["개인", "팀"]}
                            handleClick={setGroupTypeValue}
                          />
                        </FormRowItem>
                      </FormRowContainer>
                    </div>
                  </form>
                </FormContainer>
                <FormSubmitButton
                  style={{marginTop: "4rem", opacity: readyToSubmit ? "1" : "0.5"}}
                  onClick={(e) => {
                    handleSubmit(e);
                  }}
                  disabled={!readyToSubmit}
                >
                  제출하기
                </FormSubmitButton>
              </PopupContainer>
            </PopupBackground>
          )}
        </Container>
        <Footer/>
      </Desktop>
      <Mobile>
        <Container>
          <img
            style={{
              width: "100%",
              height: "auto",
              objectFit: "contain",
            }}
            src={mobileImage1}
            alt="image"
          />
          <MobileLocationContainer>
            <div style={{marginBottom: "2rem"}}>
              <br/><br/><br/>
              <p style={{fontSize: "20px", lineBreak: "anywhere", fontWeight: 700, color: "#363636"}}>사전 세미나 일정</p>
              <br/><br/><br/>
              <p style={{fontSize: "16px", fontWeight: 500, color: "#808080"}}>일시: 7월 22일(토) 11:00 ~ 13:00</p>
              <br/>
              <p style={{fontSize: "16px", fontWeight: 500, color: "#808080", marginBottom: "4px"}}>장소: 서울시 성동구 왕십리로
                83-21</p>
              <p style={{fontSize: "16px", fontWeight: 500, color: "#808080", marginLeft: "2.1rem"}}>아크로 서울포레스트 D타워 8층
                퓨처플레이</p><br/>
              <p style={{fontSize: "16px", fontWeight: 500, color: "#808080"}}>대중교통 이용: 수인분당선 서울숲역 5번 출구</p><br/>
              <p style={{fontSize: "12px", fontWeight: 500, color: "#808080", paddingBottom: "8px"}}> • 방문 시 1층 안내데스크에
                출입명부 확인 (예약필수)</p>
              <p style={{fontSize: "12px", fontWeight: 500, color: "#808080"}}>• 주차비 지원은 1시간까지 가능</p>
            </div>
            <div>
              <a
                href="https://forms.gle/Cd59f714rrytKedL6"
                target="_blank"
                rel="noreferrer"
                style={{
                  textDecorationLine: "none",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center"
                }}>
                <FormButton style={{
                  position: "initial",
                  width: "10rem",
                  height: "2.6rem",
                  margin: "0 0 12px 0",
                  padding: "0px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}>
                  <p
                    style={{
                      fontSize: "18px", fontWeight: 500, color: "#23395d",
                      margin: "auto",
                      padding: "auto"
                    }}>세미나 신청</p></FormButton></a>
            </div>
            <MobileKakaoMapContainer style={{height: "16rem", marginBottom: "2rem"}}>
              <MobileKakaoMap/>
            </MobileKakaoMapContainer>
          </MobileLocationContainer>
          <LogoTitle
            style={{fontSize: "32px", marginTop: "6rem", marginBottom: "0"}}
          >
            주관사
          </LogoTitle>
          <LogoContainer>
            <img
              style={{
                width: "15rem",
                height: "auto",
                objectFit: "contain",
                marginBottom: "1rem",
              }}
              src={albaLogo}
              alt="logo"
            />
          </LogoContainer>
          <LogoTitle
            style={{fontSize: "18px", marginTop: "0", marginBottom: "6rem"}}
          >
            (주)알바트로스에듀
          </LogoTitle>
          <LogoTitle
            style={{fontSize: "32px", marginTop: "2rem", marginBottom: "0"}}
          >
            주최사
          </LogoTitle>
          <LogoContainer>
            <img
              style={{
                width: "14rem",
                height: "auto",
                objectFit: "contain",
                marginBottom: "1rem",
              }}
              src={partner1}
              alt="logo"
            />
          </LogoContainer>
          <LogoTitle
            style={{fontSize: "18px", marginTop: "0", marginBottom: "6rem"}}
          >
            한국초기투자기관협회
          </LogoTitle>
          <BoxContainer style={{borderBottom: "0px"}}>
            <LogoTitle>파트너사</LogoTitle>
            <LogoContainer
              style={{justifyContent: "flex-start", marginBottom: "2rem"}}
            >
              <div style={{width: "100%"}}>
                <Ticker height={80} speed={10}>
                  {() => (
                    <>
                      {/*<img*/}
                      {/*  style={{*/}
                      {/*    width: "5rem",*/}
                      {/*    height: "2.5rem",*/}
                      {/*    marginRight: "2.5rem",*/}
                      {/*    objectFit: "contain",*/}
                      {/*  }}*/}
                      {/*  src={partner1}*/}
                      {/*  alt="logo"*/}
                      {/*/>*/}
                      <img
                        style={{
                          width: "4rem",
                          height: "2.5rem",
                          marginRight: "2.5rem",
                          objectFit: "contain",
                        }}
                        src={partner2}
                        alt="logo"
                      />
                      <img
                        style={{
                          width: "6rem",
                          height: "2.5rem",
                          marginRight: "2.5rem",
                          objectFit: "contain",
                        }}
                        src={partner3}
                        alt="logo"
                      />
                      <img
                        style={{
                          width: "5rem",
                          height: "2.5rem",
                          marginRight: "2.5rem",
                          objectFit: "contain",
                        }}
                        src={partner4}
                        alt="logo"
                      />
                      <img
                        style={{
                          width: "6rem",
                          height: "2.5rem",
                          marginRight: "2.5rem",
                          objectFit: "contain",
                        }}
                        src={partner5}
                        alt="logo"
                      />
                      <img
                        style={{
                          width: "6rem",
                          height: "2.5rem",
                          marginRight: "2.5rem",
                          objectFit: "contain",
                        }}
                        src={partner6}
                        alt="logo"
                      />
                      <img
                        style={{
                          width: "6rem",
                          height: "2.5rem",
                          marginRight: "2.5rem",
                          objectFit: "contain",
                        }}
                        src={partner7}
                        alt="logo"
                      />
                      <img
                        style={{
                          width: "6rem",
                          height: "2.5rem",
                          marginRight: "2.5rem",
                          objectFit: "contain",
                        }}
                        src={partner8}
                        alt="logo"
                      />
                      <img
                        style={{
                          width: "6rem",
                          height: "2.5rem",
                          marginRight: "2.5rem",
                          objectFit: "contain",
                        }}
                        src={partner9}
                        alt="logo"
                      />
                      <img
                        style={{
                          width: "6rem",
                          height: "2.5rem",
                          marginRight: "2.5rem",
                          objectFit: "contain",
                        }}
                        src={partner10}
                        alt="logo"
                      />
                      <img
                        style={{
                          width: "6rem",
                          height: "2.5rem",
                          marginRight: "2.5rem",
                          objectFit: "contain",
                        }}
                        src={partner11}
                        alt="logo"
                      />
                      <img
                        style={{
                          width: "6rem",
                          height: "2.5rem",
                          marginRight: "2.5rem",
                          objectFit: "contain",
                        }}
                        src={partner12}
                        alt="logo"
                      />
                      <img
                        style={{
                          width: "6rem",
                          height: "2.5rem",
                          marginRight: "2.5rem",
                          objectFit: "contain",
                        }}
                        src={partner13}
                        alt="logo"
                      />
                      <img
                        style={{
                          width: "6rem",
                          height: "2.5rem",
                          marginRight: "2.5rem",
                          objectFit: "contain",
                        }}
                        src={partner14}
                        alt="logo"
                      />
                    </>
                  )}
                </Ticker>
              </div>
            </LogoContainer>
          </BoxContainer>
          <FormButton
            onClick={() => setModalOn(true)}
            style={{
              width: "12rem",
              height: "3rem",
              fontSize: "1rem",
              fontWeight: 700,
              marginBottom: "2rem",
              borderRadius: "8px",
            }}
          >
            {" "}
            신청서 제출하기{" "}
          </FormButton>
          {modalOn && (
            <PopupBackground>
              <PopupContainer
                width="90vw"
                height="60vh"
                style={{
                  backgroundColor: "#23395d",
                  color: "white",
                  padding: "2.5rem 0.5rem 0.5rem 0.5rem",
                  minWidth: "380px",
                  minHeight: "600px",
                }}
              >
                <CloseButtonWrap
                  onClick={() => {
                    document.body.style.overflow = "auto";
                    setModalOn(false);
                    setFormValues({
                      parentName: "",
                      school: "",
                      studentName: "",
                      grade: "",
                      phone: "",
                      applicationType: "",
                      email: "",
                      applicantType: "",
                      groupType: "",
                    });
                    setInvalid({});
                    setReadyToSubmit(false);
                    setApplicantTypeValue("");
                    setApplicationTypeValue("");
                  }}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alightItems: "flex-start",
                    top: "10px",
                    right: "24px",
                  }}
                >
                  <img
                    style={{
                      width: "1.5rem",
                      height: "auto",
                      objectFit: "contain",
                    }}
                    src={closePopUpIcon}
                    alt="logo"
                  />
                </CloseButtonWrap>
                {/* <PopupTitle
                  style={{
                    color: "white",
                    borderColor: "white",
                    fontSize: "1.4rem",
                    margin: "0.5rem",
                  }}
                >
                  Dream Up <br /> 창업캠프 신청하기
                </PopupTitle> */}
                <FormContainer style={{marginTop: "2rem", width: "100%"}}>
                  <form
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div
                      style={{
                        alignSelf: "center",
                        width: "90%",
                        display: "flex",
                        flexDirection: "column",
                        alightItems: "flex-start",
                        justifyContent: "center",
                      }}
                    >
                      {/* {modalPageNum === 1 ? ( */}
                      <div>
                        <FormRowContainer
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            width: "100%",
                          }}
                        >
                          <FormRowItem>
                            <label htmlFor="parentName">학부모 성함</label>
                            <FormInput
                              type="text"
                              id="parentName"
                              name="parentName"
                              onFocus={() => handleInputFocus("parentName")}
                              onBlur={() => handleInputBlur("parentName")}
                              onChange={(e) =>
                                handleInputChange("parentName", e)
                              }
                              value={formValues["parentName"]}
                              // placeholder={
                              //   !isInputFocused["parentName"]
                              //     ? "학부모 성함을 입력해주세요."
                              //     : ""
                              // }
                            />
                          </FormRowItem>
                          <FormRowItem>
                            <label htmlFor="studentName">학생 성함</label>
                            <FormInput
                              type="text"
                              id="studentName"
                              name="studentName"
                              onFocus={() => handleInputFocus("studentName")}
                              onBlur={() => handleInputBlur("studentName")}
                              onChange={(e) =>
                                handleInputChange("studentName", e)
                              }
                              value={formValues["studentName"]}
                              // placeholder={
                              //   !isInputFocused["studentName"]
                              //     ? "학생 이름을 입력해주세요."
                              //     : ""
                              // }
                            />
                          </FormRowItem>
                        </FormRowContainer>
                        <FormRowContainer
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            width: "100%",
                          }}
                        >
                          <FormRowItem>
                            <label htmlFor="school">학교</label>
                            <FormInput
                              type="text"
                              id="school"
                              name="school"
                              onFocus={() => handleInputFocus("school")}
                              onBlur={() => handleInputBlur("school")}
                              onChange={(e) => handleInputChange("school", e)}
                              value={formValues["school"]}
                              // placeholder={
                              //   !isInputFocused["school"]
                              //     ? "학교명을 입력해주세요."
                              //     : ""
                              // }
                            />
                          </FormRowItem>
                          <FormRowItem>
                            <label htmlFor="grade">학년</label>
                            <FormInput
                              type="text"
                              id="grade"
                              name="grade"
                              onFocus={() => handleInputFocus("grade")}
                              onBlur={() => handleInputBlur("grade")}
                              onChange={(e) => handleInputChange("grade", e)}
                              value={formValues["grade"]}
                              // placeholder={
                              //   !isInputFocused["grade"]
                              //     ? "학년을 입력해주세요."
                              //     : ""
                              // }
                            />
                          </FormRowItem>
                        </FormRowContainer>
                        {/* </div>
                      ) : (
                        <div> */}
                        <FormRowItem
                          style={{marginBottom: "1rem", width: "97%"}}
                        >
                          <label htmlFor="email">Email</label>
                          <FormInput
                            type="email"
                            id="email"
                            name="email"
                            onFocus={() => handleInputFocus("email")}
                            onBlur={() => handleInputBlur("email")}
                            onChange={(e) => handleInputChange("email", e)}
                            value={formValues["email"]}
                            placeholder={
                              !isInputFocused["email"]
                                ? "ex) dreamup@camp.com"
                                : ""
                            }
                            style={{marginBottom: "0.2rem"}}
                          />
                          <p
                            style={{
                              margin: "0.1rem 0 0 2px",
                              fontSize: "10px",
                              color: "red",
                              height: "3px",
                            }}
                          >
                            {formValues["email"] &&
                              invalid.email &&
                              " 이메일을 확인해 주세요."}
                          </p>
                        </FormRowItem>
                        <FormRowItem
                          style={{marginBottom: "1rem", width: "97%"}}
                        >
                          <label htmlFor="phone">연락처</label>
                          <FormInput
                            type="text"
                            id="phone"
                            name="phone"
                            onFocus={() => handleInputFocus("phone")}
                            onBlur={() => handleInputBlur("phone")}
                            onChange={(e) => handleInputChange("phone", e)}
                            value={formValues["phone"]}
                            placeholder={
                              !isInputFocused["phone"]
                                ? "ex) 010-xxxx-xxxx"
                                : ""
                            }
                            style={{marginBottom: "0.2rem"}}
                          />

                          <p
                            style={{
                              margin: "0.1rem 0 0 2px",
                              fontSize: "10px",
                              color: "red",
                              height: "3px",
                            }}
                          >
                            {formValues["phone"] &&
                              invalid.phone &&
                              " 연락처를 확인해 주세요."}
                          </p>
                        </FormRowItem>
                        <FormRowContainer
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            width: "102%",
                          }}
                        >
                          <FormRowItem>
                            <label htmlFor="applicationType">신청 분야</label>
                            {/* <FormSelect
                              name="applicationType"
                              onChange={(e) =>
                                handleInputChange("applicationType", e)
                              }
                              value={formValues["applicationType"]}
                              style={{ width: "110%" }}
                            >
                              <option value="" hidden>
                                선택
                              </option>
                              <option value="robotics">Robotics</option>
                              <option value="avionics">Avionics</option>
                              <option value="healthcare">Health Care</option>
                              <option value="environment">Environment</option>
                              <option value="genetic_ai">Genetic AI</option>
                            </FormSelect> */}
                            <DropDown
                              list={[
                                "robotics",
                                "avionics",
                                "healthcare",
                                "environment",
                                "genetic_ai",
                              ]}
                              handleClick={setApplicationTypeValue}
                            />
                          </FormRowItem>
                          <FormRowItem>
                            <label htmlFor="applicantType">신청자</label>
                            {/* <FormSelect
                              name="applicantType"
                              onChange={(e) =>
                                handleInputChange("applicantType", e)
                              }
                              value={formValues["applicantType"]}
                              style={{ width: "110%" }}
                            >
                              <option value="" hidden>
                                선택
                              </option>
                              <option value="parent">학부모</option>
                              <option value="student">학생</option>
                            </FormSelect> */}
                            <DropDown
                              list={["student", "parent"]}
                              handleClick={setApplicantTypeValue}
                              which={"applicantType"}
                            />
                          </FormRowItem>
                          <FormRowItem>
                            <label htmlFor="groupType">개인 / 팀</label>
                            <DropDown
                              list={["개인", "팀"]}
                              handleClick={setGroupTypeValue}
                            />
                          </FormRowItem>
                        </FormRowContainer>
                      </div>
                      {/* )} */}
                      {/* <div style={{ margin: "auto", zIndex: "999" }}>
                        {`< `}
                        <FormPageSpan
                          isClicked={modalPageNum === 1}
                          onClick={() => setModalPageNum(1)}
                        >
                          {` 1 `}{" "}
                        </FormPageSpan>
                        /
                        <FormPageSpan
                          isClicked={modalPageNum === 1}
                          onClick={() => setModalPageNum(2)}
                        >{` 2 `}</FormPageSpan>
                        {` >`}
                      </div> */}
                    </div>
                  </form>
                </FormContainer>
                <FormSubmitButton
                  onClick={(e) => {
                    handleSubmit(e);
                  }}
                  disabled={!readyToSubmit}
                  style={{marginTop: "2rem", opacity: readyToSubmit ? "1" : "0.5"}}
                >
                  제출하기
                </FormSubmitButton>
              </PopupContainer>
            </PopupBackground>
          )}
        </Container>
        <MobileFooter/>
      </Mobile>
    </>
  );
};

export default DreamUpCamp;
