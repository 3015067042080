import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { RecoilRoot, useRecoilValue } from "recoil";
import axios from "axios";
import { GoogleOAuthProvider } from "@react-oauth/google";

import GlobalStyle from "./components/GlobalStyle";
import tokenState from "./states/tokenState";

import Chat from "./components/Chat";
import ScrollToTop from "./components/ScrollToTop";

import theme from "./themes/theme";
import Home from "./screens/home";
import Login from "./screens/login";
import StudentSignUp from "./screens/signup/student";
import TeacherSignUp from "./screens/signup/teacher";
import TeacherJoinNotice from "./screens/signup/teacher/teacherJoinNotice";
import TeacherModifyInfo from "./screens/mypage/teacher/modify";
import PaymentRequest from "./screens/mypage/teacher/tab/paymentRequest";
import TeacherVideoUpload from "./screens/mypage/teacher/tab/videoUpload";
import TeacherVideoModify from "./screens/mypage/teacher/tab/videoModify";
import VipApplication1 from "./screens/home/VipApplication1";
import VipApplication2 from "./screens/home/vipApplication2";
import MentoPick from "./screens/mentoPick";
import MentoProfile from "./screens/mentoPick/MentoProfile";
import LectureVideoDetail from "./screens/mentoPick/components/LectureVideoDetail";
import CareerSearchGuide from "./screens/home/careerSearch/CareerSearchGuide";
import CareerSearch from "./screens/home/careerSearch/CareerSearch";
import ConferenceRoom from "./screens/conferenceRoom";
import FindIDPW from "./screens/findIdPw";
import Page from "./screens/home/page";
import ContentsMarket from "./screens/contentsMarket";
import ContentsMarketDetail from "./screens/contentsMarket/detail";
import QnA from "./screens/qna";
import QnaRegister from "./screens/qna/components/QnaRegister";
import QnaDetail from "./screens/qna/components/QnaDetail";
import Benefit from "./screens/benefit";
import BenefitDetail from "./screens/benefit/detail";
import Mypage from "./screens/mypage";
import MypageModifyInfo from "./screens/mypage/modifyInfo";
import DreamUpCamp from "./screens/dreamUpCamp";
import DreamUpChallenge from "./screens/dreamUpChallege";
import { useMediaQuery } from "react-responsive";
import Inquery from "./screens/inquery/inquery";

const SetHeader = () => {
  const tokenValue = useRecoilValue(tokenState);

  useEffect(() => {
    if (tokenValue !== null) {
      axios.defaults.headers["Authorization"] = `Bearer ${tokenValue}`;
    }
  }, [tokenValue]);

  return <GlobalStyle />;
};

const App = () => {
  const isDesktop = useMediaQuery({
    query: "(min-width:1024px)",
  });

  const currentHost = `${window.location.protocol}//${window.location.hostname}`;
  const isKesia = currentHost === "https://kesia.vercel.app";

  console.log("isKesia??     ", isKesia);

  return (
    <RecoilRoot>
      <GoogleOAuthProvider clientId="438298112293-15491ai6k5a73jucg07k8mdh749rlp07.apps.googleusercontent.com">
        <ThemeProvider theme={theme}>
          <SetHeader />
          <BrowserRouter>
            <ScrollToTop />
            {isKesia ? (
              <Routes>
                <Route path="/" element={<DreamUpChallenge />} />
              </Routes>
            ) : (
              <Routes>
                <Route
                  path="/dream-up-challenge"
                  element={<DreamUpChallenge />}
                />
                <Route path="/" element={<Home />} />
                <Route path="/login" element={<Login />} />
                <Route path="/findpwid" element={<FindIDPW />} />
                <Route path="/signUp/student" element={<StudentSignUp />} />
                <Route path="/signUp/teacher" element={<TeacherSignUp />} />
                <Route
                  path="/signUp/teacher/notice"
                  element={<TeacherJoinNotice />}
                />
                <Route path="/vipApplication1" element={<VipApplication1 />} />
                <Route path="/vipApplication2" element={<VipApplication2 />} />
                <Route path="/mentoPick" element={<MentoPick />} />
                <Route
                  path="/mentoPick/profile/:profileId"
                  element={<MentoProfile />}
                >
                  <Route path="lecture/:id" element={<LectureVideoDetail />} />
                </Route>
                <Route path="/mypage" element={<Mypage />} />
                <Route
                  path="/mypage/modifyInfo"
                  element={<MypageModifyInfo />}
                />
                <Route
                  path="/mypage/teacher/modifyInfo"
                  element={<TeacherModifyInfo />}
                />
                <Route
                  path="/mypage/teacher/modifyInfo"
                  element={<TeacherModifyInfo />}
                />
                <Route
                  path="/mypage/teacher/requestPayment/:id"
                  element={<PaymentRequest />}
                />
                <Route
                  path="/mypage/teacher/videoUpload"
                  element={<TeacherVideoUpload />}
                />
                <Route
                  path="/mypage/teacher/videoModify"
                  element={<TeacherVideoModify />}
                />
                <Route
                  path="/careerSearchGuide"
                  element={<CareerSearchGuide />}
                />
                <Route path="/inquery" element={<Inquery />} />
                <Route path="/careerSearch" element={<CareerSearch />} />
                <Route path="/conferenceRoom" element={<ConferenceRoom />} />
                <Route path="/page/:type" element={<Page />} />
                <Route path="/contentsMarket" element={<ContentsMarket />} />
                <Route
                  path="/contentsMarket/:id"
                  element={<ContentsMarketDetail />}
                />
                <Route path="/QnA" element={<QnA />} />
                <Route path="/QnaRegister" element={<QnaRegister />} />
                <Route path="/QnA/:id" element={<QnaDetail />} />
                <Route path="/benefit" element={<Benefit />} />
                <Route path="/benefit/:id" element={<BenefitDetail />} />
              </Routes>
            )}
          </BrowserRouter>
          {isDesktop && window.location.pathname !== "/dream-up-challenge" && (
            <Chat />
          )}
        </ThemeProvider>
      </GoogleOAuthProvider>
    </RecoilRoot>
  );
};

export default App;
