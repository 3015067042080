import React, { useEffect, useState } from "react";
import Selects, { components } from "react-select";

import { ReactComponent as ArrowImg } from "../assets/icons/arrow_top.svg";

const DropdownIndicator = (props) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(props.selectProps.menuIsOpen);
  }, [props]);

  return (
    <components.DropdownIndicator {...props}>
      {open ? (
        <ArrowImg />
      ) : (
        <ArrowImg style={{ transform: " rotate( 180deg )" }} />
      )}
    </components.DropdownIndicator>
  );
};

const Select = ({
  option,
  width,
  height,
  placeholder,
  onChange,
  value,
  marginRight,
  float,
  type,
}) => {
  const styles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "#FFAD2E" : "#212529",
      backgroundColor:
        state.isSelected || state.isFocused
          ? "rgba(255,173,46,0.1)"
          : "#ffffff",
      fontSize: "16px",
      padding: "17px 20px",
      borderBottom: state.isSelected
        ? "1px solid #FFAD2E"
        : "1px solid #E9ECEF",
      borderTop: state.isSelected ? "1px solid #FFAD2E" : "0px solid #FFAD2E",
      boxSizing: "border-box",
    }),
    control: (provided, state) => ({
      ...provided,
      width: width ? width : "260px",
      height: height ? height : "60px",
      marginBottom: 0,
      border: state.isFocused
        ? "1px solid #FFAD2E"
        : value !== ""
        ? "1px solid #021633"
        : "1px solid #dde2ea",
      boxShadow: "none",
      padding: "0px 12px",
      borderRadius: "8px",
      "&:hover": {
        borderColor: "FFAD2E",
      },
      marginRight: marginRight ? marginRight : "16px",
      float: float ? float : "none",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "#212529",
      fontSize: "16px",
      fontWeight: 400,
    }),
    menu: (provided, state) => ({
      ...provided,
      width: width ? width : "260px",
      border: "1px solid #FFAD2E",
      padding: 0,
      margin: 0,
      overflow: "hidden",
      position: float ? "absolute" : "static",
      top: 60,
      right: type === 1 ? 15 : type === 2 ? 7 : 0,
    }),
    menuList: (provided, state) => ({
      ...provided,
      padding: 0,
      height: 210,
    }),
    input: (provided) => ({
      ...provided,
      outline: "none !important",
      border: "none",
      disabled: true,
    }),
    indicatorSeparator: () => {},
    placeholder: (provided) => ({
      ...provided,
      color: "#ADB5BD",
      fontWeight: 500,
      fontSize: 16,
    }),
  };

  return (
    <Selects
      options={option}
      styles={styles}
      components={{ DropdownIndicator }}
      placeholder={placeholder}
      onChange={(value) => {
        onChange(value);
      }}
      value={value}
      isSearchable={false}
    />
  );
};

export default Select;
