import React, { useEffect, useState, useRef, useCallback } from "react";
import styled from "styled-components";

import Cancel from "../assets/icons/cancle_24px.svg";
import Exit from "../assets/icons/exit.svg";
import ArrowLeft from "../assets/icons/arrow_left.svg";
import Attach from "../assets/icons/attach.svg";
import { ReactComponent as Send } from "../assets/icons/send.svg";
import Close from "../assets/icons/cancle_44px.svg";
import { WEBSOCKET_URL } from "../API";
import dayjs from "dayjs";
import NoneProfile from "../assets/icons/profile_image.svg";
import formatMessage from "../utils/formatMessage";

const Container = styled.div`
  width: 398px;
  height: 800px;
  background-color: ${(props) => props.theme.color.primary};
  border-radius: 12px;
  position: ${(props) => (props.fixedScroll ? "absolute" : "fixed")};
  right: 140px;
  bottom: ${(props) => (props.fixedScroll ? null : "60px")};
  top: ${(props) =>
    props.fixedScroll
      ? `${document.body.clientHeight - 800 - 330 - 60}px`
      : null};
  box-shadow: 0 4px 20px #021633;
  z-index: 10;

  @media (max-width: 1080px) {
    width: 100%;
    right: 0;
    top: 58px;
  }

  @media (max-height: 960px) {
    height: 620px;
  }
`;
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 64px;
  background-color: #ffffff;
  border-radius: 12px 12px 0 0;
  padding: 20px;
`;
const HeaderContensWrap = styled.div`
  display: flex;
  align-itmes: center;
`;
const HeaderTitle = styled.div`
  font-size: 16px;
  font-weight: ${(props) => props.theme.fontWeight.medium};
  color: ${(props) => props.theme.color.textColor};
  line-height: 24px;
`;
const HeaderBack = styled.img.attrs({
  src: `${ArrowLeft}`,
})`
  width: 24px;
  height: 24px;
  cursor: pointer;
  margin-right: 8px;
`;
const HeaderExit = styled.img.attrs({
  src: `${Exit}`,
})`
  width: 24px;
  height: 24px;
  cursor: pointer;
  margin-right: 8px;
`;
const HeaderCancel = styled.img.attrs({
  src: `${Cancel}`,
})`
  width: 24px;
  height: 24px;
  cursor: pointer;
`;
const ChatAttach = styled.img.attrs({
  src: `${Attach}`,
})`
  cursor: pointer;
  position: absolute;
  right: 56px;
  bottom: 12px;
`;
const ContentsWrap = styled.div`
  height: 632px;
  padding: 32px 28px 0 16px;
  overflow: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  ::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }

  @media (max-height: 960px) {
    height: 452px;
  }
`;

const InputContainer = styled.div`
  width: 100%;
  padding: 24px 16px;
  background-color: #ffffff;
  height: auto;
  max-height: 189px;
  border-radius: 12px;
  display: flex;
  align-items: flex-end;
  z-index: 20;
`;
const InputWrap = styled.div`
  position: relative;
  height: 56px;
  width: 100%;
  display: flex;
  align-items: flex-end;
`;
const Input = styled.textarea`
  background-color: #f4f5f7;
  padding: 20px 100px 12px 16px;
  border: none;
  width: 100%;
  height: 56px;
  box-sizing: border-box;
  border-radius: 10px;
  outline: none;
  resize: none;
  font-size: 12px;
  font-weight: ${(props) => props.theme.fontWeight.regular};
  line-height: 18px;
  max-height: 141px;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  ::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }

  ::placeholder {
    font-size: 12px;
    font-weight: ${(props) => props.theme.fontWeight.medium};
    color: #868e96;
  }
`;

const ChatInputWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  height: 104px;
  max-height: 189px;
`;
// 채팅창
const UserProfileWrap = styled.div`
  display: flex;
  align-items: flex-start;
`;
const UserPrifleImg = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 32px;
  background-color: #ffffff;
  margin-right: 12px;
`;
const UserName = styled.span`
  font-size: 14px;
  font-weight: ${(props) => props.theme.fontWeight.medium};
  color: #ffffff;
  vertical-align: top;
`;
const ChatContainer = styled.div`
  display: flex;
  margin-bottom: 24px;
`;
const OpponentChatWrap = styled.div`
  display: flex;
  align-items: flex-end;
  position: relative;
`;
const OpponentChatBlock = styled.div`
  display: flex;
  flex-direction: column;
`;
const OpponentChat = styled.p`
  width: 204px;
  height: auto;
  border-radius: 10px;
  background-color: #ffffff;
  font-size: 12px;
  font-weight: ${(props) => props.theme.fontWeight.medium};
  line-height: 20px;
  color: ${(props) => props.theme.color.primary};
  padding: 8px;
  margin-top: 4px;
  margin-left: 12px;
  box-sizing: border-box;
  white-space: pre-wrap;
  word-break: break-all;
`;
const OpponentChatTriangle = styled.div`
  width: 0;
  height: 0;
  border-bottom: 7px solid transparent;
  border-top: 7px solid #ffffff;
  border-left: 7px solid transparent;
  border-right: 7px solid #ffffff;
  position: absolute;
  top: 16px;
  left: 0;
`;

const OpponentChatDate = styled.span`
  font-size: 10px;
  font-weight: ${(props) => props.theme.fontWeight.medium};
  color: #dee2e6;
  margin-left: 8px;
`;
const MyChatContainer = styled.div`
  display: flex;
  justify-content: right;
  margin-bottom: 24px;
`;
const MyChatWrap = styled.div`
  display: flex;
  align-items: flex-end;
  position: relative;
  flex-direction: row-reverse;
`;
const MyChat = styled.p`
  width: 204px;
  height: auto;
  border-radius: 10px;
  background-color: #e8f1fe;
  font-size: 12px;
  font-weight: ${(props) => props.theme.fontWeight.medium};
  line-height: 20px;
  color: ${(props) => props.theme.color.primary};
  padding: 8px;
  box-sizing: border-box;
  white-space: pre-wrap;
  word-break: break-all;
`;
const MyChatTriangle = styled.div`
  width: 0;
  height: 0;
  border-bottom: 7px solid transparent;
  border-top: 7px solid #e8f1fe;
  border-left: 7px solid #e8f1fe;
  border-right: 7px solid transparent;
  position: absolute;
  right: -12px;
  top: 16px;
`;
const MyChatDate = styled.span`
  font-size: 10px;
  font-weight: ${(props) => props.theme.fontWeight.medium};
  color: #dee2e6;
  margin-right: 8px;
`;

const ChatImageWrap = styled.div``;

// 모달

const Background = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${(props) =>
    props.openModal ? "rgba(33, 37, 41, 0.8)" : null};
  z-index: ${(props) => (props.openModal ? "900" : "-1")};
  float: right;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 12px;
`;

const ModalContainer = styled.div`
  width: 334px;
  height: 165px;
  background-color: #ffffff;
  overflow-x: hidden;
  border-radius: 12px;
  padding: 24px 0;
  box-sizing: border-box;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 991;
`;

const ModalTitle = styled.div`
  color: #212529;
  font-size: 20px;
  font-weight: 700;
  line-height: 29px;
  text-align: center;
  margin-bottom: 8px;
`;

const ModalContent = styled.div`
  text-align: center;
  color: #212529;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  white-space: pre-line;
`;

const ModalButtonWrap = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
`;

const ModalButton = styled.button`
  width: 100%;
  height: 60px;
  border: none;
  text-decoration: none;
  cursor: pointer;
  background-color: ${(props) =>
    props.type === "cancel" ? "#DDE2EA" : "#021633"};
  color: ${(props) => (props.type === "cancel" ? "#021633" : "#ffffff")};
  font-size: 20px;
  font-weight: 500;
  line-height: 50px;
`;

const ChatOpponentImgWrap = styled.div`
  width: 204px;
  display: flex;
  align-items: flex-end;
  position: relative;
  flex-direction: row;
`;
const ChatMyImgWrap = styled.div`
  display: flex;
  align-items: flex-end;
  position: relative;
  flex-direction: row-reverse;
`;

const ChatImgWrap = styled.div`
  width: 204px;
  display: flex;
  flex-wrap: wrap;
`;
const ChatImg = styled.img`
  width: 204px;
  height: 204px;
  // width: 94px;
  // height: 94px;
  border-radius: 10px;
  background-color: #dee2e6;
  margin-left: 16px;
  margin-bottom: 16px;
  :nth-child(odd) {
    margin-left: 0px;
  }
  cursor: pointer;
`;

const ModalBackground = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(33, 37, 41, 0.8);
  z-index: 900;
  position: fixed;
  top: 0px;
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ImgPreviewModal = styled.div`
  width: 812px;
  height: 812px;
  border-radius: 12px;
  background-color: #dee2e6;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 901;
`;

const CloseIcon = styled.div`
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='44' height='44' viewBox='0 0 44 44'%3E%3Cdefs%3E%3CclipPath id='clip-path'%3E%3Crect id='사각형_807' data-name='사각형 807' width='44' height='44' transform='translate(1160 324)' fill='%23adb5bd'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg id='마스크_그룹_50' data-name='마스크 그룹 50' transform='translate(-1160 -324)' clip-path='url(%23clip-path)'%3E%3Cg id='b' transform='translate(1160 324)'%3E%3Cg id='c'%3E%3Cpath id='패스_243' data-name='패스 243' d='M0,0H44V44H0Z' fill='%23adb5bd' opacity='0' style='isolation: isolate'/%3E%3Cline id='선_4' data-name='선 4' x2='25.667' y2='25.667' transform='translate(9.167 9.167)' fill='none' stroke='%23212529' stroke-linecap='round' stroke-miterlimit='10' stroke-width='4'/%3E%3Cline id='선_5' data-name='선 5' y1='25.667' x2='25.667' transform='translate(9.167 9.167)' fill='none' stroke='%23212529' stroke-linecap='round' stroke-miterlimit='10' stroke-width='4'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
  position: absolute;
  width: 44px;
  height: 44px;
  top: 24px;
  right: 24px;
  cursor: pointer;
`;
const ModalPreviewImg = styled.div``;

const imageArray = [
  { id: 1, url: "../assets/images/main_subimage_1.png" },
  { id: 2, url: "../assets/images/main_subimage_2.png" },
  { id: 3, url: "../assets/images/main_subimage_3.png" },
];

const ChatModal = (props) => {
  return (
    <ModalContainer>
      <ModalTitle>{props.title}</ModalTitle>
      <ModalContent>{props.content}</ModalContent>
      <ModalButtonWrap>
        {props.button &&
          props.button.map((item, idx) => {
            if (item.type === "cancel") {
              return (
                <ModalButton key={idx} type={item.type} onClick={props.onClose}>
                  {item.title}
                </ModalButton>
              );
            }
            return (
              <ModalButton key={idx} type={item.type} onClick={props.onPress}>
                {item.title}
              </ModalButton>
            );
          })}
      </ModalButtonWrap>
    </ModalContainer>
  );
};

const ChatRoom = (props) => {
  const [sendActive, setSendActive] = useState(false);
  const [chatText, setChatText] = useState("");
  const [textareaHeight, setTextareaHeight] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [files, setFiles] = useState([]);
  const chatRef = useRef();
  const chatWrapRef = useRef();
  const fileRef = useRef();
  const contentsRef = useRef(null);
  const innerRef = useRef(null);
  const [imgPreview, setImgPreview] = useState(false);
  const [chatSocket, setChatSocket] = useState(null);
  const [messages, setMessages] = useState([]);

  const fileUploadClickhandler = (e) => {
    e.preventDefault();
    fileRef.current.click();
  };

  const onLoadHandler = (e) => {
    const file = e.target.files;
    setFiles(file);
  };

  useEffect(() => {
    const formData = new FormData();
    formData.append("uploadImage", files);
  }, [files]);

  useEffect(() => {
    if (chatText === "") {
      setSendActive(false);
    } else {
      setSendActive(true);
    }
  }, [chatText]);

  // 채팅창 스크롤 아래로
  const scrollToBottom = () => {
    setTimeout(() => {
      if (contentsRef.current) {
        contentsRef.current.scroll({ top: 9999, behavior: 'smooth' });
      }
    }, 500);
  };

  // 새로운 메시지가 왔을때만 실행되도록
  useEffect(() => {
    if (props.chatRoomInfo) {
      const newChatSocket = new WebSocket(WEBSOCKET_URL);

      newChatSocket.onmessage = (event) => {
        const socketData = JSON.parse(event.data);

        if (socketData.type === 'firstChat') {
          setMessages([...socketData.messages.filter(messageItem => messageItem.type === 'TEXT')]);
          scrollToBottom();
        }

        if (socketData.type === 'chat') {
          if (socketData.message.type === 'TEXT') {
            setMessages((prevMessages) => [...prevMessages, socketData.message]);
          }

          scrollToBottom();
        }
      };

      newChatSocket.onopen = () => {
        const connectData = {
          token: JSON.parse(window.localStorage.getItem('tokenState')) || undefined,
          chatRoomId: props.chatRoomInfo.id,
        };

        newChatSocket.send(JSON.stringify({ event: 'connectChat', data: JSON.stringify(connectData) }));
      };

      setChatSocket(newChatSocket);
    }

    return () => {
      if (chatSocket) {
        chatSocket.close();
        setChatSocket(null);
      }
    }
  }, [props.chatRoomInfo]);

  const autoResizeTextarea = (e) => {
    if (chatRef) {
      chatRef.current.style.height = "50px";
      chatWrapRef.current.style.height = "104px";
      const height = chatRef.current.scrollHeight;
      chatRef.current.style.height = `${height + 6}px`;
      chatWrapRef.current.style.height = `${height + 54}px`;
    }
  };

  const sendMessage = () => {
    if (chatSocket) {
      const connectData = {
        message: chatText,
      };

      chatSocket.send(JSON.stringify({ event: 'sendChat', data: JSON.stringify(connectData) }));
      setChatText('');
    }
  };

  return (
    <>
      {imgPreview ? <ModalBackground /> : null}
      <Container fixedScroll={props.fixedScroll}>
        <Background openModal={openModal}></Background>
        <Header>
          <HeaderContensWrap>
            <HeaderBack onClick={props.onClick} />
            <HeaderTitle>{ props.chatRoomInfo.title }</HeaderTitle>
          </HeaderContensWrap>
          <HeaderContensWrap>
            <HeaderExit
              onClick={() => {
                setOpenModal(true);
              }}
            />
            <HeaderCancel onClick={props.onCancel} />
          </HeaderContensWrap>
        </Header>
        {/* 채팅창 */}
        <ContentsWrap ref={contentsRef}>
          <div ref={innerRef}>
            {
              messages.filter(message => message.message !== null).map(message => message.isMine ? (
                <MyChatContainer>
                  <MyChatWrap>
                    <MyChat dangerouslySetInnerHTML={{ __html: formatMessage(message.message) }} />
                    <MyChatTriangle />
                    <MyChatDate>{ dayjs(message.createdAt).format('YYYY-MM-DD HH:mm:ss') }</MyChatDate>
                  </MyChatWrap>
                </MyChatContainer>
              ) : (
                <ChatContainer>
                  <UserPrifleImg src={message.user.profileImage || NoneProfile} />
                  <OpponentChatBlock>
                    <UserName>{ message.user.nickname }</UserName>
                    <OpponentChatWrap>
                      <OpponentChat dangerouslySetInnerHTML={{ __html: formatMessage(message.message) }} />
                      <OpponentChatTriangle />
                      <OpponentChatDate>{ dayjs(message.createdAt).format('YYYY-MM-DD HH:mm:ss') }</OpponentChatDate>
                    </OpponentChatWrap>
                  </OpponentChatBlock>
                </ChatContainer>
              ))
            }
          </div>
        </ContentsWrap>
        {/* 채팅입력창 */}
        <ChatInputWrapper>
          <InputContainer ref={chatWrapRef}>
            <InputWrap>
              <Input
                ref={chatRef}
                onInput={autoResizeTextarea}
                value={chatText}
                placeholder="내용을 입력해주세요."
                onChange={(e) => {
                  setTextareaHeight(e.target.value.split("\n").length - 1);
                  setChatText(e.target.value);
                }}
                onKeyDown={(event) => {
                  if (event.key === 'Enter' && event.nativeEvent.isComposing === false) {
                    event.preventDefault();
                    sendMessage();
                  }
                }}
              />
              <ChatAttach
                currentColor="#ffffff"
                onClick={fileUploadClickhandler}
              />
              <input
                type="file"
                accept="image/*"
                ref={fileRef}
                multiple
                style={{ display: "none" }}
                onChange={onLoadHandler}
              />
              <Send
                onClick={sendMessage}
                color={sendActive ? "#FFAD2E" : "#868E96"}
                style={{
                  cursor: "pointer",
                  position: "absolute",
                  right: "16px",
                  bottom: "12px",
                }}
              />
            </InputWrap>
          </InputContainer>
        </ChatInputWrapper>
        {openModal ? (
          errorModal ? (
            <ChatModal
              onPress={() => {
                setOpenModal(false);
                setErrorModal(false);
              }}
              title="죄송합니다."
              content="PDF 공유는 앱에서만 이용 가능합니다."
              button={[
                {
                  title: "확인",
                  type: "active",
                },
              ]}
            />
          ) : (
            <ChatModal
              onPress={() => {
                setOpenModal(false);
                props.backChatRoom(false);
              }}
              onClose={() => {
                setOpenModal(false);
              }}
              title="대화방 나가기"
              content="한번 나간 대화방은 복구할 수 없습니다."
              button={[
                {
                  title: "취소",
                  type: "cancel",
                },
                {
                  title: "나가기",
                  type: "active",
                },
              ]}
            />
          )
        ) : null}
      </Container>
      {imgPreview ? (
        <ImgPreviewModal>
          <CloseIcon
            onClick={() => {
              setImgPreview(false);
            }}
          />
        </ImgPreviewModal>
      ) : null}
    </>
  );
};

export default ChatRoom;
