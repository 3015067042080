import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../../../API";
import { useRecoilValue } from "recoil";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import updateLocale from "dayjs/plugin/updateLocale";
import { Desktop, Mobile } from "../../../utils/mediaQuery";
import Button from "../../../components/Button";
import Pagination from "../../../components/Pagination";
import Modal from "../../../components/Modal";
import { ReactComponent as Timer } from "../../../assets/icons/timer.svg";
import { ReactComponent as Close } from "../../../assets/icons/cancle_44px.svg";
import runBootPay from "../../../components/BootPay";
import tokenState from "../../../states/tokenState";
import SeminarImg from "../../../assets/images/conference_1.jpeg";
import formatPrice from "../../../utils/formatPrice";
import userInfoState from "../../../states/userInfoState";
import { tab } from "@testing-library/user-event/dist/tab";
import MobileModal from "../../../components/MobileModal";
import { OtBtn } from "../../mentoPick/MentoProfile";
import calendar from "../../../assets/images/calendar.png";
import people from "../../../assets/images/people.png";
const TabWrap = styled.div`
  display: flex;
  font-size: 20px;
  margin-top: 48px;
  margin-bottom: 32px;
  align-items: center;
`;
const Tab = styled.div`
  font-weight: ${(props) =>
    props.active ? props.theme.fontWeight.bold : props.theme.fontWeight.medium};
  color: ${(props) => (props.active ? props.theme.color.primary : "#ADB5BD")};
  cursor: pointer;
`;
const Divide = styled.div`
  width: 1px;
  height: 16px;
  background-color: #adb5bd;
  margin: 2px 20px 0px;
`;
const SeminarContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 950px;
  @media screen and (max-width: 1280px) {
    width: 820px;
  }
`;
const SeminarBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 79px;
  :nth-child(2n + 0) {
    margin-left: 9px;
  }
`;
const SeminarWrap = styled.div`
  width: 467px;
  overflow: hidden;
  border-radius: 12px;
  position: relative;
  @media screen and (max-width: 1280px) {
    width: 402px;
  }
`;
const SeminarThumbnail = styled.div`
  width: 467px;
  height: 625px;
  background-image: url(${(props) => (props.src ? props.src : SeminarImg)});
  background-color: ${(props) => (props.isShown ? "#dee2e6" : null)};
  filter: ${(props) => (props.isShown ? "blur(8px) brightness(0.5)" : null)};
  opacity: ${(props) => (props.isShown ? "90%" : null)};
  @media screen and (max-width: 1280px) {
    width: 402px;
  }
`;
const ButtonWrap = styled.div`
  width: 300px;
  position: absolute;
  top: 246px;
  left: 50%;
  transform: translateX(-50%);
`;
const BadgeWrap = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${(props) =>
    props.marginBottom ? props.marginBottom + "px" : "16px"};
  margin-top: ${(props) => (props.marginTop ? props.marginTop + "px" : "20px")};
`;
const Badge = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  min-width: ${(props) => (props.minWidth ? "121px" : "0px")};
  height: ${(props) => (props.height ? "40px" : "32px")};
  border-radius: ${(props) => (props.borderRadius ? "12px" : "8px")};
  padding: 6px 20px;
  background-color: ${(props) =>
    props.red
      ? "#FF383B"
      : props.black
      ? "#021633"
      : props.pink
      ? "rgba(255, 56, 59, 0.1)"
      : props.grey
      ? "#DDE2EA"
      : "#ffffff"};
  border: 1px solid
    ${(props) =>
      props.red
        ? "#FF383B"
        : props.black
        ? "#021633"
        : props.pink
        ? "none"
        : props.white
        ? "#021633"
        : props.purple
        ? "#6337FC"
        : props.grey
        ? "#DDE2EA"
        : "#ffffff"};
  color: ${(props) =>
    props.white
      ? "#021633"
      : props.purple
      ? "#6337FC"
      : props.grey
      ? "#021633"
      : props.pink
      ? "#FF383B"
      : "#ffffff"};
  font-size: 14px;
  font-weight: ${(props) => props.theme.fontWeight.medium};
  margin-right: 12px;
  :last-child {
    margin-right: 0px;
  }
  @media screen and (max-width: 1280px) {
    margin-right: 9px;
  }
`;

const SeminarTitle = styled.div`
  font-size: 24px;
  font-weight: ${(props) => props.theme.fontWeight.bold};
  color: #212529;
  height: 36px;
  margin-bottom: 7px;
`;
const SeminarSubTitle = styled.div`
  font-size: 20px;
  font-weight: ${(props) => props.theme.fontWeight.medium};
  color: #868e96;
  height: 29px;
  margin-bottom: 8px;
`;
const SeminarStatus = styled.div`
  font-size: 24px;
  font-weight: ${(props) => props.theme.fontWeight.bold};
  height: 36px;
  color: ${(props) =>
    props.reserved ? "#0081F5" : props.reservationEnd ? "#868E96" : "#212529"};
`;

const PaginationWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 160px;
`;
const ModalBackground = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(33, 37, 41, 0.8);
  z-index: 10;
  position: fixed;
  left: 0;
  top: 0;
`;
const InfoModal = styled.div`
  width: 812px;
  height: 798px;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  z-index: 11;
  border-radius: 16px;
  padding: 48px;
`;
const ModalTitle = styled.div`
  font-size: 28px;
  font-weight: ${(props) => props.theme.fontWeight.bold};
  height: 41px;
  color: #212529;
  margin-bottom: 7px;
`;
const ModalSubTitle = styled.span`
  display: inline-block;
  width: 100%;
  font-size: 20px;
  font-weight: ${(props) => props.theme.fontWeight.medium};
  height: 29px;
  color: #868e96;
  padding-bottom: 32px;
  margin-bottom: 32px;
  border-bottom: 2px solid #e9ecef;
`;
const ModalContentsWrap = styled.div`
  display: flex;
  margin-bottom: 32px;
`;
const ModalContentsBlock = styled.div`
  :first-child {
    margin-right: 16px;
  }
`;
const ModalContents = styled.div`
  background-color: #f8f9fa;
  border-radius: 12px;
  width: 350px;
  height: 355px;
  margin-top: 20px;
  overflow-y: auto;
  font-size: 14px;
  color: #212529;
  line-height: 28px;
  padding: 20px;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  ::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
`;
const Dot = styled.div`
  min-width: 4px;
  height: 4px;
  border-radius: 4px;
  background-color: #212529;
  margin-top: 14px;
  margin-right: 8px;
`;
const ModalTextWrap = styled.div`
  display: flex;
  margin-bottom: 20px;
`;
const ModalText = styled.span`
  font-size: 14px;
  color: #212529;
  line-height: 28px;
  white-space: pre-wrap;
`;

/********모바일스타일*********/
export const MobileSeminarTabBox = styled.ul`
  list-style: none;
  display: flex;
  margin-bottom: 17px;
  > li {
    button {
      padding: 0;
      font-weight: 700;
      font-size: 16px;
      line-height: 23px;
      color: #021633;
      background-color: transparent;
      border: none;
      margin-right: 12px;
    }
  }
`;
export const MobileSeminarCardItem = styled.li`
  width: 163px;
  > button {
    padding: 0;
    background-color: transparent;
    border: none;
    width: 163px;
    overflow: hidden;
    height: 223px;
    background-color: black;
    border-radius: 4px;
    margin-bottom: 12px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  > div {
    :nth-of-type(1) {
      display: flex;
      margin-bottom: 12px;
      span {
        display: block;
        font-weight: 500;
        font-size: 12px;
        line-height: 17px;
        border: 1px solid #021633;
        border-radius: 13px;
        padding: 4px 12px;
        color: #021633;
        margin-right: 8px;
      }
    }
    :nth-of-type(2) {
      > h2 {
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 23px;
        color: #212529;
        margin-bottom: 4px;
      }
      > p {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #868e96;
        margin-bottom: 4px;
      }
      > h3 {
        font-weight: 700;
        font-size: 16px;
        line-height: 23px;
        color: #212529;
      }
    }
  }
`;
export const MobileConferenceBtn = styled.div`
  position: fixed;
  bottom: 0;
  z-index: 999;
  margin: 0 !important;
  width: 100%;
  text-align: center;
  height: 87px;
  left: 50%;
  background-color: white;
  transform: translateX(-50%);
  padding: 16px 24px;
  > button {
    width: 342px;
    margin: 0 auto;
  }
`;

export const MobileSeminarDetailContainer = styled.div`
  padding: 80px 0px 100px 0px;
`;
export const MobileSeminarDetailInfo = styled.div`
  display: flex;
  justify-content: space-between;
  > figure {
    width: 133px;
    height: 182px;
    border-radius: 4px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  > div {
    > div {
      :nth-of-type(1) {
        display: flex;
        margin-bottom: 8px;
        > span {
          display: block;
          height: 26px;
          box-sizing: border-box;
          padding: 4px 12px;
          border-radius: 13px;
          line-height: 17px;
          font-size: 12px;
          color: white;
          font-weight: 500;
          :nth-of-type(1) {
            background: #ff383b;
            line-height: 15px;
            margin-right: 8px;
          }
          :nth-of-type(2) {
            background: #021633;
            display: flex;
            align-items: center;
            > span {
              padding-bottom: 4px;
            }
          }
        }
      }
      :nth-of-type(2) {
        > h2 {
          font-weight: 700;
          font-size: 18px;
          line-height: 26px;
          color: #212529;
          margin-bottom: 10px;
        }
        > p {
          color: #505860;
          font-weight: 400;
          :nth-of-type(1) {
            font-size: 16px;
            line-height: 23px;
            margin-bottom: 10px;
          }
          :nth-of-type(2),
          :nth-of-type(3) {
            display: flex;
            img {
              width: 24px;
              height: 24px;
              margin-right: 8px;
            }
            font-size: 14px;
            line-height: 20px;
          }
        }
      }
    }
  }
`;

export const MobileInstructorInfo = styled.div`
  margin: 24px 0px;

  > h2 {
    font-weight: 700;
    font-size: 16px;
    line-height: 23px;
    color: #212529;
    margin-bottom: 16px;
  }
  > ul {
    width: 342px;
    left: 24px;
    top: 373px;
    background: #f8f9fa;
    border-radius: 4px;
    padding: 16px;
    box-sizing: border-box;

    li {
      list-style-type: disc;
      list-style-position: inside;
      margin-bottom: 12px;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #212529;
      padding-left: 30px;
      text-indent: -20px;
    }
  }
`;
export const MobileClassInfo = styled.div`
  > h2 {
    font-weight: 700;
    font-size: 16px;
    line-height: 23px;
    color: #212529;
    margin-bottom: 16px;
  }
  > div {
    width: 342px;
    left: 24px;
    top: 373px;
    background: #f8f9fa;
    border-radius: 4px;
    padding: 16px;
    box-sizing: border-box;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #212529;
  }
`;

/**************************/

const Seminar = (props) => {
  const navigate = useNavigate();
  const tabName = props.tabName;
  const [activeTab, setActiveTab] = useState("DUE");
  const [isShown, setIsShown] = useState([]);
  const [page, setPage] = useState(1);
  const [openInfoModal, setOpenInfoModal] = useState(false);
  const [loginModal, setLoginModal] = useState(false);
  const [paymentCheckModal, setPaymentCheckModal] = useState(false);
  const token = useRecoilValue(tokenState);
  const [conferenceRooms, setConferenceRooms] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [conferenceRoomItem, setConferenceRoomItem] = useState(null);
  const userInfo = useRecoilValue(userInfoState);
  const [isReservation, setIsReservation] = useState(false);
  
  const getData = () => {
    console.log('activeTab', activeTab);

    axios({
      url: `${API_URL}/conferenceroom?type=SEMINAR&timeType=${activeTab}&page=${page}`,
      method: "GET",
    })
      .then((res) => {
        console.log(res.data);

        if (res.data.success) {
          setConferenceRooms(res.data.result.conferenceRooms);
          setTotalPage(res.data.result.totalPage * 15);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getData();
  }, [activeTab, page]);

  const reservation = (id, receiptId) => {
    setOpenInfoModal(false);

    axios({
      method: "POST",
      url: `${API_URL}/conferenceRoom/reservation/${id}`,
      data: receiptId ? { receiptId } : null,
    }).then((res) => {
      if (res.data.success) {
        if (window.innerWidth > 1080) alert("예약이 완료되었습니다!");
        setIsReservation(true);
        getData();
      } else {
        alert(res.data.error.message);
      }
    });
  };

  const bootpay = useCallback(() => {
    runBootPay(
      conferenceRoomItem.price,
      `영재멘토 세미나 (${conferenceRoomItem.title})`,
      `영재멘토 세미나 (${conferenceRoomItem.title})`,
      userInfo.nickname,
      userInfo.email,
      "",
      userInfo.phoneNumber,
      () => {},
      (receiptId) => {
        reservation(conferenceRoomItem.id, receiptId);
      }
    );
  }, [conferenceRoomItem, userInfo]);

  const getPage = (page) => {
    setPage(page);
    window.scrollTo(0, 0);
  };

  dayjs.extend(updateLocale);
  dayjs.extend(relativeTime);
  dayjs.updateLocale("en", {
    relativeTime: {
      future: "in %s",
      past: "%s 전",
      s: "1초",
      m: "1분",
      mm: "%d분",
      h: "1시간",
      hh: "%d시간",
      d: "1일",
      dd: "%d일",
      M: "1달",
      MM: "%d개월",
      y: "1년",
      yy: "%d년",
    },
  });

  return (
    <>
      <Desktop>
        <div>
          {openInfoModal ? (
            <>
              <ModalBackground />
              {conferenceRoomItem !== null && (
                <InfoModal>
                  <Close
                    onClick={() => {
                      setOpenInfoModal(false);
                    }}
                    style={{
                      position: "absolute",
                      top: "24px",
                      right: "24px",
                      cursor: "pointer",
                    }}
                  />
                  <BadgeWrap marginTop="0" marginBottom="20">
                    <Badge purple height="true">
                      {dayjs(conferenceRoomItem.startAt).format(
                        "YYYY년 MM월 DD일 HH시 mm분"
                      )}
                    </Badge>
                    <Badge white height="true">
                      {conferenceRoomItem.maxPeople}명
                    </Badge>
                  </BadgeWrap>
                  <ModalTitle>{conferenceRoomItem.title}</ModalTitle>
                  <ModalSubTitle>
                    {conferenceRoomItem.instructorName}
                  </ModalSubTitle>
                  <ModalContentsWrap>
                    <ModalContentsBlock>
                      <Badge grey>강사 정보</Badge>
                      <ModalContents>
                        <ModalTextWrap>
                          <ModalText>
                            {conferenceRoomItem.instructorDescription}
                          </ModalText>
                        </ModalTextWrap>
                      </ModalContents>
                    </ModalContentsBlock>
                    <ModalContentsBlock>
                      <Badge grey>강연 정보</Badge>
                      <ModalContents>
                        <ModalTextWrap>
                          <ModalText>
                            {conferenceRoomItem.description}
                          </ModalText>
                        </ModalTextWrap>
                      </ModalContents>
                    </ModalContentsBlock>
                  </ModalContentsWrap>
                  <Button
                    active
                    disabled={
                      conferenceRoomItem.reservationEnd ||
                      conferenceRoomItem.reserved
                    }
                    style={{ width: "226px", margin: "0 auto" }}
                    onClick={() => {
                      if (conferenceRoomItem.price !== 0) {
                        bootpay();
                      } else {
                        reservation(conferenceRoomItem.id);
                      }
                    }}
                  >
                    {conferenceRoomItem.reservationEnd && "예약마감"}
                    {conferenceRoomItem.reservationEnd === false &&
                      conferenceRoomItem.reserved &&
                      "예약완료"}
                    {conferenceRoomItem.reservationEnd === false &&
                      !conferenceRoomItem.reserved &&
                      "예약하기"}
                  </Button>
                </InfoModal>
              )}
            </>
          ) : null}
          <TabWrap>
            <Tab
              active={activeTab === "DUE"}
              onClick={() => {
                setActiveTab("DUE");
                setPage(1);
              }}
            >
              예정된 세미나
            </Tab>
            <Divide />
            <Tab
              active={activeTab === "REALTIME"}
              onClick={() => {
                setActiveTab("REALTIME");
                setPage(1);
              }}
            >
              실시간 세미나
            </Tab>
            <Divide />
            <Tab
              active={activeTab === "PAST"}
              onClick={() => {
                setActiveTab("PAST");
                setPage(1);
              }}
            >
              지나간 세미나
            </Tab>
          </TabWrap>
          <SeminarContainer>
            {conferenceRooms.map((item) => {
              return (
                <SeminarBlock key={item.id}>
                  <SeminarWrap
                    onMouseEnter={() => {
                      setIsShown((preShown) => ({
                        ...preShown,
                        [item.id]: !preShown[item.id],
                      }));
                    }}
                    onMouseLeave={(id) => setIsShown(false)}
                  >
                    <SeminarThumbnail
                      src={item.image}
                      isShown={isShown[item.id]}
                    />
                    {isShown[item.id] ? (
                      <ButtonWrap>
                        <Button
                          onClick={() => {
                            if (token !== null) {
                              setOpenInfoModal(true);
                              setConferenceRoomItem(item);
                            } else {
                              setLoginModal(true);
                            }
                          }}
                          borderWhite
                          style={{
                            marginBottom: "13px",
                            borderColor: "#ffffff",
                          }}
                        >
                          상세정보
                        </Button>

                        <Button
                          onClick={() => {
                            setConferenceRoomItem(item);

                            setTimeout(() => {
                              if (
                                item.price !== 0 &&
                                !item.reservationEnd &&
                                !item.reserved
                              ) {
                                bootpay();
                              } else {
                                reservation(item.id);
                              }
                            }, 500);
                          }}
                          active={!item.reservationEnd}
                          orange={!item.reservationEnd}
                          disabled={item.reservationEnd || item.reserved}
                        >
                          {item.reservationEnd && "예약마감"}
                          {item.reservationEnd === false &&
                            item.reserved &&
                            "예약완료"}
                          {item.reservationEnd === false &&
                            item.reserved === false &&
                            "에약하기"}
                        </Button>
                      </ButtonWrap>
                    ) : null}
                  </SeminarWrap>
                  <BadgeWrap>
                    {/* <Badge red>실시간</Badge> */}
                    {/* <Badge black minWidth>
                  <Timer style={{ marginRight: "8px" }} />
                  0: 00: 00
                </Badge> */}
                    <Badge white>{item.maxPeople}명</Badge>
                    {/* <Badge pink>마감임박</Badge> */}
                  </BadgeWrap>
                  <SeminarTitle>{item.title}</SeminarTitle>
                  <SeminarSubTitle>{item.instructorName}</SeminarSubTitle>
                  <SeminarStatus
                    reservationEnd={item.reservationEnd}
                    reserved={item.reservationEnd === false && item.reserved}
                  >
                    {item.reservationEnd && "예약마감"}
                    {item.reservationEnd === false &&
                      item.reserved &&
                      "예약완료"}
                    {item.reservationEnd === false &&
                      !item.reserved &&
                      item.price !== 0 &&
                      `${formatPrice(item.price)}원`}
                  </SeminarStatus>
                </SeminarBlock>
              );
            })}
          </SeminarContainer>
          {/* 마감임박 모달 */}
          {paymentCheckModal && (
            <Modal
              onClose={() => {
                setPaymentCheckModal(false);
              }}
              onPress={() => {
                bootpay();
              }}
              title={"결제 안내"}
              content={
                "세미나 시간이 얼마 남지 않았습니다. 그래도 결제하시겠습니까?"
              }
              button={[
                {
                  title: "닫기",
                  type: "cancel",
                },
                {
                  title: "결제하기",
                  type: "active",
                },
              ]}
            />
          )}
          {loginModal && (
            <Modal
              onClose={() => {
                setLoginModal(false);
              }}
              onPress={() => {
                navigate("/login");
              }}
              title={"로그인 안내"}
              content={"상세페이지 확인은 로그인 상태에서 가능합니다."}
              button={[
                {
                  title: "닫기",
                  type: "cancel",
                },
                {
                  title: "로그인하기",
                  type: "active",
                },
              ]}
            />
          )}
          <PaginationWrap>
            <Pagination
              page={page}
              tabName={tabName}
              totalItemsCount={totalPage}
              showItemsCount={15}
              nowPage={getPage}
            />
          </PaginationWrap>
        </div>
      </Desktop>
      <Mobile>
        {!props.openInfoPage ? (
          //세미나리스트보기
          <>
            <MobileSeminarTabBox>
              <li>
                <button
                  onClick={() => {
                    setActiveTab("DUE");
                  }}
                  style={{
                    color: activeTab === "DUE" ? "" : " #ADB5BD",
                  }}
                >
                  예정된 세미나
                </button>
              </li>
              <li>
                <button
                  onClick={() => {
                    setActiveTab("REALTIME");
                  }}
                  style={{
                    color: activeTab === "REALTIME" ? "" : " #ADB5BD",
                  }}
                >
                  실시간 세미나
                </button>
              </li>
              <li>
                <button
                  onClick={() => {
                    setActiveTab("PAST");
                  }}
                  style={{
                    color: activeTab === "PAST" ? "" : " #ADB5BD",
                  }}
                >
                  지나간 세미나
                </button>
              </li>
            </MobileSeminarTabBox>
            {/** 세미나 리스트 */}
            <ul
              style={{
                display: "flex",
                flexWrap: "wrap",
                columnGap: 16,
                rowGap: 20,
              }}
            >
              {conferenceRooms.map((item, id) => (
                <MobileSeminarCardItem>
                  <button
                    onClick={() => {
                      if (token !== null) {
                        setConferenceRoomItem(item);
                        props.setOpenInfoPage(true);
                      } else {
                        setLoginModal(true);
                      }
                    }}
                  >
                    <img src={item.image} alt="세미니" />
                  </button>
                  <div>
                    <span>{item.maxPeople}명</span>
                    <span>마감임박</span>
                  </div>
                  <div>
                    <h2>{item.title}</h2>
                    <p>{dayjs(item.updatedAt).fromNow()}</p>
                    <h3>{Number(item.price)?.toLocaleString()}원</h3>
                  </div>
                </MobileSeminarCardItem>
              ))}
            </ul>
          </>
        ) : (
          //세미나상세보기
          <>
            <MobileSeminarDetailContainer>
              <MobileSeminarDetailInfo>
                <figure>
                  <img alt="세미나" src={conferenceRoomItem.image} />
                </figure>
                <div>
                  <div>
                    <span>실시간</span>
                    <span>
                      <Timer
                        style={{ width: 14, height: 14, marginRight: 4 }}
                      />
                      <span>00:00:00</span>
                    </span>
                  </div>
                  <div>
                    <h2>{conferenceRoomItem.title}</h2>
                    <p>{conferenceRoomItem.instructorName}</p>
                    <p>
                      <img src={people} alt="maxpeople" />
                      <span>{conferenceRoomItem.maxPeople}명</span>
                    </p>
                    <p>
                      <img src={calendar} alt="startDate" />
                      <span>
                        {dayjs(conferenceRoomItem.startAt).format(
                          "YYYY년 MM월 DD일 HH:mm"
                        )}
                      </span>
                    </p>
                  </div>
                </div>
              </MobileSeminarDetailInfo>
              <MobileInstructorInfo>
                <h2>강사 정보</h2>
                <ul>
                  {conferenceRoomItem.instructorDescription
                    .split("\n")
                    .map((el) => (
                      <li>{el}</li>
                    ))}
                </ul>
              </MobileInstructorInfo>
              <MobileClassInfo>
                <h2>강연 정보</h2>
                <div>{conferenceRoomItem.description}</div>
              </MobileClassInfo>
            </MobileSeminarDetailContainer>
            <MobileConferenceBtn>
              <Button
                active
                disabled={
                  conferenceRoomItem.reservationEnd ||
                  conferenceRoomItem.reserved
                }
                onClick={() => {
                  if (conferenceRoomItem.price !== 0) {
                    bootpay();
                  } else {
                    reservation(conferenceRoomItem.id);
                  }
                }}
              >
                {conferenceRoomItem.reservationEnd && "예약마감"}
                {conferenceRoomItem.reservationEnd === false &&
                  conferenceRoomItem.reserved &&
                  "예약완료"}
                {conferenceRoomItem.reservationEnd === false &&
                  !conferenceRoomItem.reserved &&
                  conferenceRoomItem.price !== 0 &&
                  `${conferenceRoomItem.price}원 결제하기`}
                {conferenceRoomItem.reservationEnd === false &&
                  !conferenceRoomItem.reserved &&
                  conferenceRoomItem.price === 0 &&
                  `예약하기`}
              </Button>
            </MobileConferenceBtn>
          </>
        )}
        {loginModal && (
          <MobileModal
            onClose={() => {
              setLoginModal(false);
            }}
            onPress={() => {
              navigate("/login");
            }}
            title={"로그인 안내"}
            content={"상세페이지 확인은 로그인 상태에서 가능합니다."}
            button={[
              {
                title: "닫기",
                type: "cancel",
              },
              {
                title: "로그인하기",
                type: "active",
              },
            ]}
          />
        )}
        {isReservation && (
          <MobileModal
            onClose={() => {
              setIsReservation(false);
            }}
            onPress={() => {
              setIsReservation(false);
            }}
            btncnt={1}
            title={"예약완료"}
            content={"세미나 예약이 완료되었습니다"}
            button={[
              {
                title: "확인",
                type: "active",
              },
            ]}
          />
        )}
      </Mobile>
    </>
  );
};

export default Seminar;
