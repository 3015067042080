import styled from "styled-components";

const Container = styled.div`
  width: 1364px;
  margin: 0 auto;
  padding: 200px 0 260px 0;
  font-family: 'Noto Sans KR';

  @media screen and (max-width: 1280px) {
    width: 1200px;
  }
`;

const ProfileWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 950px;
`;

const ProfileBlock = styled.div`
  width: 306px;
  height: auto;
  border-radius: 12px;
  border: 1px solid #dde2ea;
  margin-top: 32px;
  margin-right: 16px;
  &:nth-child(3n) {
    margin-right: 0px;
  }
  cursor: pointer;
  position: relative;

  @media screen and (max-width: 1280px) {
    width: 262px;
  }
`;

const ProfileImageWrap = styled.div`
  border-radius: 12px;
  width: 30%;
  height: auto;
  overflow: hidden;
  margin-left: 20px;
`;

const ProfileImage = styled.img`
  width: 100%;
  height: auto;
  object-fit: contain;
`;

const ProfileName = styled.span`
  display: inline-block;
  font-size: 20px;
  font-weight: ${(props) => props.theme.fontWeight.bold};
  color: ${(props) => props.theme.color.textColor};
  margin: 20px 0 20px 20px;
`;

const ProfileBadgeWrap = styled.div`
  display: flex;
  justify-content: center;
  margin-left: 20px;
  flex-direction: column;
  margin-bottom: ${(props) => (props.marginBottom ? "20px" : "16px")};
`;

const ProfileBadge = styled.div`
  width: 40px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.color.primary};
  color: ${(props) => props.theme.color.white};
  font-size: 12px;
  font-weight: ${(props) => props.theme.fontWeight.medium};
  border-radius: 4px;
  margin-right: 12px;
`;

const ProfileText = styled.p`
  font-size: 12px;
  font-weight: ${(props) => props.theme.fontWeight.medium};
  color: ${(props) => props.theme.color.textColor};
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  width: calc(100% - 40px);
  margin-top: 12px;

  ${props => props.multiline && `
    word-break: keep-all;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    white-space: normal;
    line-height: 16px;
  `}
`;

const EmptyDataText = styled.div`
  font-size: 20px;
  font-weight: ${(props) => props.theme.fontWeight.medium};
  color: #adb5bd;
  margin: 0 auto;
  margin-top: 80px;
`;

const PaginationWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 160px;
`;

export {
  Container,
  ProfileBlock,
  ProfileWrap,
  ProfileImageWrap,
  ProfileImage,
  ProfileBadgeWrap,
  ProfileBadge,
  ProfileText,
  ProfileName,
  EmptyDataText,
  PaginationWrap,
};
