import React from "react";
import styled from "styled-components";
import GlobalStyle from "./GlobalStyle";

const StyledTextarea = styled.textarea`
  width: 100%;
  min-height: 120px;
  height: ${(props) => (props.height ? props.height : "120px")};
  padding: 20px;
  border-radius: 12px;
  border: 1px solid ${(props) => (props.value !== "" ? "#021633" : "#dde2ea")};
  font-size: 14px;
  color: #212529;
  line-height: 24px;
  font-family: "Noto Sans KR";
  font-weight: 400;
  resize: none;
  background-color: #ffffff;
  box-sizing: border-box;

  &::placeholder {
    color: #adb5bd;
    font-family: "Noto Sans KR";
  }

  &:focus {
    outline: none;
  }

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  ::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
`;

const Textarea = ({
  value,
  placeholder,
  onChange,
  maxLength,
  height,
  style,
}) => {
  return (
    <StyledTextarea
      value={value}
      placeholder={placeholder}
      onChange={(e) => {
        onChange(e.target.value);
      }}
      maxLength={maxLength}
      height={height}
      style={style}
    />
  );
};

export default Textarea;
