import styled from "styled-components";
import Arrow from "../../assets/icons/right_arrow_purple.svg";

const BannerTitle = styled.span`
  font-size: 28px;
  font-weight: ${(props) => props.theme.fontWeight.medium};
  color: ${(props) =>
    props.orange ? props.theme.color.orange : props.theme.color.primary};
  line-height: 41px;
`;
const BannerDesc = styled.p`
  font-size: 16px;
  font-weight: ${(props) => props.theme.fontWeight.regular};
  color: ${(props) => props.theme.color.primary};
  margin-top: 7px;
`;
const Container = styled.div`
  width: 1364px;
  padding: 200px 0px 278px 0px;
  margin: 0 auto;
  @media screen and (max-width: 1280px) {
    width: 1200px;
  }
`;
const BannerText = styled.div`
  font-size: 20px;
  font-weight: ${(props) => props.theme.fontWeight.medium};
  line-height: 29px;
  color: #021633;
  display: flex;
  align-items: center;
  margin-top: 8px;
`;
const BannerLink = styled.a`
  font-size: 20px;
  font-weight: ${(props) => props.theme.fontWeight.medium};
  line-height: 29px;
  color: #6337fc;
  text-decoration: none;
  position: relative;
  margin-left: 12px;
  cursor: pointer;

  :after {
    content: "";
    display: inline-block;
    width: 18px;
    height: 18px;
    background-image: url(${Arrow});
    position: absolute;
    top: 50%;
    transform: translate(0px, -50%);
    margin-left: 5px;
  }
`;

export { BannerTitle, BannerDesc, Container, BannerText, BannerLink };
