import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import closePopUpIcon from "./images/close-icon-grey.png";
import {
  PopupBackground,
  PopupContainer,
  CloseButtonWrap,
  PopupTitle,
  Container,
  MainImgWrap,
  MainImgTitle,
  MainPageBtn,
  MainTextWrap,
  MainContentsWrap,
  MainContents01,
  ContentsTitle,
  ContentsSubTitle,
  ContentsDesc,
  MainContents02,
  SubImage01,
  SubTextWrap,
  SubImage02,
  MainContents03,
  ContentsWrap,
  ContentsBlock,
  DottedBorder,
  AbledBadge,
  DisabledBadge,
  AccountText,
  AccountPriceText,
  ContentsContainer,
  TabBarWrap,
  TabContents,
  TabContentsTitle,
  SliderWrap,
  CareerSearchBlock,
  CareerSearchTextWrap,
  CareerTitle,
  CareerSubTitle,
  CareerBtn,
  SeminarContainer,
  SeminarBlock,
  SeminarWrap,
  BadgeWrap,
  Badge,
  SeminarTitle,
  SeminarSubTitle,
  SeminarStatus,
  ModalBackground,
  InfoModal,
  ModalTitle,
  ModalSubTitle,
  ModalContentsWrap,
  ModalContentsBlock,
  ModalContents,
  Dot,
  ModalTextWrap,
  ModalText,
  SeminarTitleWrap,
  SeminarMainTitle,
  SeminarMainSubTitle,
  SeminarThumbnail,
  PopupContent,
  PopupGoneButton,
} from "./style";

import Tab from "../../components/Tab";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Carousel from "../../components/Slider";
import Button from "../../components/Button";
import { ReactComponent as Timer } from "../../assets/icons/timer.svg";
import { ReactComponent as Close } from "../../assets/icons/cancle_44px.svg";
import { ReactComponent as ArrowRight } from "../../assets/icons/arrow_right.svg";
import MainGuideImage from "../../assets/images/main_guide.png";
import runBootPay from "../../components/BootPay";

import axios from "axios";
import { API_URL } from "../../API";
import formatPrice from "../../utils/formatPrice";
import useWindowSize from "../../hooks/useWindowSize";
import MobilePopupImage from "../../assets/images/mobile_popup.jpeg";
import { Desktop, Mobile } from "../../utils/mediaQuery";
import HomeMobile from "./home_mobile";
import { useRecoilValue } from "recoil";
import tokenState from "../../states/tokenState";
import MentoPick from "../mentoPick";
import Modal from "../../components/Modal";
import LoginMobile from "../login/mobile/login_mobile";

const Home = (props) => {
  const navigate = useNavigate();
  const [isShown, setIsShown] = useState([]);
  const [openInfoModal, setOpenInfoModal] = useState(false);
  const [loginModal, setLoginModal] = useState(false);
  const [seminars, setSeminars] = useState([]);
  const [mainCategories, setMainCategories] = useState([]);
  const [mainCategoryId, setMainCategoryId] = useState(null);
  const [mainVideos, setMainVideos] = useState([]);
  const [popupNotice, setPopupNotice] = useState();
  const { width } = useWindowSize();
  const [mobilePopupShow, setMobilePopupShow] = useState(false);
  const token = useRecoilValue(tokenState);
  const [popupNoticeModal, setPopupNoticeModal] = useState(false);

  const bootpay = () => {
    runBootPay(
      5000,
      "영재멘토",
      "영재멘토 유료회원",
      "ㅇㅇ",
      "dd@dd.com",
      "ㅇㅇ",
      "01050477437",
      () => {},
      (onFinish) => {
        console.log(onFinish);
        setOpenInfoModal(false);
        navigate("/conferenceRoom");
      }
    );
  };

  const getSeminar = () => {
    axios({
      url: `${API_URL}/conferenceroom?type=SEMINAR&timeType=DUE&page=1`,
      method: "GET",
    })
      .then((res) => {
        if (res.data.success) {
          setSeminars(
            res.data.result.conferenceRooms.map((item) => {
              return {
                ...item,
              };
            })
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getMainCategories = () => {
    axios({
      method: "GET",
      url: `${API_URL}/main/categories`,
    }).then((res) => {
      if (res.data.success) {
        setMainCategories(res.data.result.categories);

        if (res.data.result.categories.length) {
          setMainCategoryId(res.data.result.categories[0].id);
        }
      }
    });
  };

  const getMainVideos = () => {
    axios({
      url: `${API_URL}/main/videos/${mainCategoryId}`,
      method: "GET",
    })
      .then((res) => {
        if (res.data.success) {
          setMainVideos(res.data.result.videos);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getNotices = () => {
    const todayHidden = localStorage.getItem("popupTodayHidden");
    if (todayHidden > new Date().getTime()) {
      return;
    }
    axios({
      url: `${API_URL}/benefit/popup`,
      method: "GET",
    })
      .then((res) => {
        if (res.data.success && res.data.result.item) {
          setPopupNotice(res.data.result.item);
          setPopupNoticeModal(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getSeminar();
    getMainCategories();
    getNotices();
  }, []);

  useEffect(() => {
    if (mainCategoryId) {
      getMainVideos();
    }
  }, [mainCategoryId]);

  useEffect(() => {
    setMobilePopupShow(width < 720);
  }, [width]);

  // 탭 컨텐츠
  const contents = Object.fromEntries(
    mainCategories.map((category, idx) => [
      idx,
      <TabContents>
        <SliderWrap>
          <Carousel videos={mainVideos} />
        </SliderWrap>
      </TabContents>,
    ])
  );
  const array = mainCategories.map((category) => category.name);

  return (
    <>
      <Desktop>
        <Header main />
        <Container>
          <MainImgWrap>
            <MainTextWrap>
              <MainImgTitle>
                상위 <MainImgTitle orange>1% </MainImgTitle>
                멘토와 함께하는
                <br />
                온라인 렉처링 룸
              </MainImgTitle>
              <MainPageBtn
                onClick={() => {
                  navigate("/mentoPick");
                }}
              >
                나의 멘토픽
              </MainPageBtn>
            </MainTextWrap>
          </MainImgWrap>

          {seminars && seminars.length > 0 && (
            <MainContentsWrap>
              <SeminarTitleWrap>
                <SeminarMainTitle>예정된 세미나</SeminarMainTitle>
                <SeminarMainSubTitle
                  onClick={() => {
                    navigate("/conferenceRoom");
                  }}
                >
                  세미나 더보기{" "}
                  <ArrowRight
                    style={{
                      width: "32px",
                      height: "32px",
                      marginLeft: "12px",
                    }}
                  />
                </SeminarMainSubTitle>
              </SeminarTitleWrap>
              <SeminarContainer>
                {seminars.map((item) => {
                  return (
                    <SeminarBlock
                      key={item.id}
                      onClick={() => {
                        navigate("/conferenceRoom");
                      }}
                    >
                      <SeminarWrap>
                        <SeminarThumbnail seminarImg={item.image} />
                      </SeminarWrap>
                      <BadgeWrap>
                        <Badge white>{item.maxPeople}명</Badge>
                      </BadgeWrap>
                      <SeminarTitle>{item.title}</SeminarTitle>
                      <SeminarSubTitle>{item.instructorName}</SeminarSubTitle>
                      <SeminarStatus
                        reservationEnd={item.reservationEnd}
                        reserved={
                          item.reservationEnd === false && item.reserved
                        }
                      >
                        {item.reservationEnd && "예약마감"}
                        {item.reservationEnd === false &&
                          item.reserved &&
                          "예약완료"}
                        {item.reservationEnd === false &&
                          !item.reserved &&
                          item.price !== 0 &&
                          `${formatPrice(item.price)}원`}
                      </SeminarStatus>
                    </SeminarBlock>
                  );
                })}
              </SeminarContainer>
            </MainContentsWrap>
          )}

          <MainContentsWrap>
            <MainContents01>
              <ContentsSubTitle>상위 1%의 성공 입시가이드</ContentsSubTitle>
              <ContentsTitle white>상위 1% 멘토 스크롤</ContentsTitle>
              <ContentsDesc white>
                대한민국 중학생의 상위 1%는 특목고에 진학합니다.
                <br />
                대한민국 고등학생의 상위 1%는 메이저 이공계와 의대에 진학합니다.
                <br />
                가 본 사람과 함께 가세요, 해 본 사람과 함께 하세요,
                <br />
                성공한 사람과 함께 성공하세요.
              </ContentsDesc>
            </MainContents01>
          </MainContentsWrap>

          <MainContentsWrap>
            <div style={{ width: 1364, margin: "0 auto" }}>
              <img
                src={MainGuideImage}
                width={780}
                height={"auto"}
                alt="이용 가이드"
                style={{
                  margin: "0 auto",
                  display: "inline-block",
                  borderRadius: 16,
                }}
              />
              <iframe
                style={{ marginLeft: 32, borderRadius: 16 }}
                width="552"
                height="438"
                src="https://www.youtube.com/embed/1s7d_iuauc8"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
            {/* <p
              style={{
                marginTop: 48,
                fontSize: 24,
                fontWeight: 500,
                textAlign: "center",
              }}
            >
              * 앱 다운로드는 최하단 링크를 이용해주세요. *
            </p> */}
          </MainContentsWrap>

          {openInfoModal ? (
            <>
              <ModalBackground />
              <InfoModal>
                <Close
                  onClick={() => {
                    setOpenInfoModal(false);
                  }}
                  style={{
                    position: "absolute",
                    top: "24px",
                    right: "24px",
                    cursor: "pointer",
                  }}
                />
                <BadgeWrap marginTop="0" marginBottom="20">
                  <Badge purple height="true">
                    2022년 4월 21일 오전 11시 30분
                  </Badge>
                  <Badge white height="true">
                    100명
                  </Badge>
                </BadgeWrap>
                <ModalTitle>여기는 강연 타이틀 영역입니다.</ModalTitle>
                <ModalSubTitle>영재멘토님</ModalSubTitle>
                <ModalContentsWrap>
                  <ModalContentsBlock>
                    <Badge grey>강사 정보</Badge>
                    <ModalContents>
                      <ModalTextWrap>
                        <Dot />
                        <ModalText>
                          신분증 앞면 사진을 찍어서 올려주세요.
                        </ModalText>
                      </ModalTextWrap>
                      <ModalTextWrap>
                        <Dot />
                        <ModalText>
                          주민등록번호 뒷 6자리와 주소를 꼭 가려주세요.
                        </ModalText>
                      </ModalTextWrap>
                      <ModalTextWrap>
                        <Dot />
                        <ModalText>
                          정보 확인이 어렵거나 훼손/ 유효하지 않은 신분증은
                          승인처리가 늦어질 수 있습니다.
                        </ModalText>
                      </ModalTextWrap>
                    </ModalContents>
                  </ModalContentsBlock>
                  <ModalContentsBlock>
                    <Badge grey>강연 정보</Badge>
                    <ModalContents>
                      많이 평화스러운 긴지라 대한 것이다. 역사를 풀이 그들 의
                      일월과 많이 청춘의 봄바람이다. 부패를 굳세게 속에 서
                      듣는다. 그들은 할지라도 굳세게 용감 많이 평화스러 운
                      긴지라 대한 것이다. 역사를 풀이 그들의 일월과 많 이 청춘의
                      봄바람이다. 부패를 많이 평화스러운 긴지라 대한 것이다.
                      역사를 풀이 그들의 일월과 많이 청춘의 봄바람이다. 부패를
                      많이 평화스러운 긴지라 대한 것이 다.
                    </ModalContents>
                  </ModalContentsBlock>
                </ModalContentsWrap>
                <Button
                  active
                  style={{ width: "226px", margin: "0 auto" }}
                  onClick={() => {
                    bootpay();
                  }}
                >
                  {/* {"5,000원 결제하기" "참여하기" "예약마감"} */}
                  5,000원 결제하기
                </Button>
              </InfoModal>
            </>
          ) : null}

          <MainContentsWrap>
            <MainContents02>
              <SubTextWrap>
                <ContentsSubTitle>상위 1%의 기록이란</ContentsSubTitle>
                <ContentsTitle>세상에 둘도 없는 학생부 탄생</ContentsTitle>
                <ContentsDesc marginBottom>
                  미래지향적 진로설계, 스토리텔링적 학업관리, <br />
                  창의적 역량관리, 효율적 시기관리, 차별적 개인화로 <br />
                  세상에 둘도 없는 학생부를 만들어 드립니다. <br />
                </ContentsDesc>
                <MainPageBtn
                  onClick={() => {
                    navigate("/vipApplication1");
                  }}
                >
                  바로가기
                </MainPageBtn>
              </SubTextWrap>
              <SubImage01 />
            </MainContents02>
          </MainContentsWrap>

          <MainContentsWrap>
            <MainContents02>
              <SubImage02 />
              <SubTextWrap>
                <ContentsSubTitle>신혜인 소장과 함께하는</ContentsSubTitle>
                <ContentsTitle>A to Z 스페셜 관리</ContentsTitle>
                <ContentsDesc marginBottom>
                  진로 / 교과학습 / 비교과 / 동기부여 / 진학 / 기록,
                  <br />
                  모든 것을 관리해드립니다.
                </ContentsDesc>
                <MainPageBtn
                  onClick={() => {
                    navigate("/vipApplication2");
                  }}
                >
                  바로가기
                </MainPageBtn>
              </SubTextWrap>
            </MainContents02>
          </MainContentsWrap>

          <MainContentsWrap backgroundColor marginBottom widthAuto>
            <SubTextWrap padding textAlign>
              <ContentsTitle white>회원형태</ContentsTitle>
              <ContentsDesc white>
                유료회원으로 가입하고 더 많은 혜택을 받아보세요!
              </ContentsDesc>

              <MainContents03 style={{ position: "relative" }}>
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    position: "absolute",
                    top: 0,
                    left: 0,
                    background: "rgba(255, 255, 255, 0.9)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <h1
                    style={{
                      textAlign: "center",
                      fontSize: 32,
                      fontWeight: "bold",
                    }}
                  >
                    이벤트 진행 중
                  </h1>
                  <h2
                    style={{
                      textAlign: "center",
                      fontSize: 24,
                      fontWeight: 500,
                      marginTop: 32,
                    }}
                  >
                    <span style={{ color: "#FFAD2E" }}>6개월 간</span>{" "}
                    <span style={{ color: "#FFAD2E" }}>무료</span>로 모든 서비스{" "}
                    <span style={{ color: "#FFAD2E" }}>이용 가능</span>
                  </h2>
                </div>
                <ContentsContainer marginRight>
                  <ContentsWrap marginBottom>
                    <AccountText>무료회원</AccountText>
                    <AccountPriceText>0원</AccountPriceText>
                  </ContentsWrap>

                  <ContentsWrap>
                    <ContentsBlock>
                      <ContentsDesc>소개페이지 보기</ContentsDesc>
                      <DottedBorder></DottedBorder>
                      <AbledBadge>가능</AbledBadge>
                    </ContentsBlock>

                    <ContentsBlock>
                      <ContentsDesc>강사 리스트 및 상세내역 확인</ContentsDesc>
                      <DottedBorder></DottedBorder>
                      <AbledBadge>가능</AbledBadge>
                    </ContentsBlock>

                    <ContentsBlock>
                      <ContentsDesc>OT 신청 / 수업 예약</ContentsDesc>
                      <DottedBorder></DottedBorder>
                      <DisabledBadge>불가능</DisabledBadge>
                    </ContentsBlock>

                    <ContentsBlock>
                      <ContentsDesc>자료실 이용</ContentsDesc>
                      <DottedBorder></DottedBorder>
                      <DisabledBadge>불가능</DisabledBadge>
                    </ContentsBlock>

                    <ContentsBlock noMargin>
                      <ContentsDesc>
                        질의응답 페이지 상세내용 및 댓글 확인
                      </ContentsDesc>
                      <DottedBorder></DottedBorder>
                      <DisabledBadge>불가능</DisabledBadge>
                    </ContentsBlock>
                  </ContentsWrap>
                </ContentsContainer>

                <ContentsContainer>
                  <ContentsWrap marginBottom>
                    <AccountText>유료회원</AccountText>
                    <AccountPriceText>월 11,000원</AccountPriceText>
                  </ContentsWrap>

                  <ContentsWrap>
                    <ContentsBlock>
                      <ContentsDesc>소개페이지 보기</ContentsDesc>
                      <DottedBorder></DottedBorder>
                      <AbledBadge>가능</AbledBadge>
                    </ContentsBlock>

                    <ContentsBlock>
                      <ContentsDesc>강사 리스트 및 상세내역 확인</ContentsDesc>
                      <DottedBorder></DottedBorder>
                      <AbledBadge>가능</AbledBadge>
                    </ContentsBlock>

                    <ContentsBlock>
                      <ContentsDesc>OT 신청 / 수업 예약</ContentsDesc>
                      <DottedBorder></DottedBorder>
                      <AbledBadge>가능</AbledBadge>
                    </ContentsBlock>

                    <ContentsBlock>
                      <ContentsDesc>자료실 이용</ContentsDesc>
                      <DottedBorder></DottedBorder>
                      <AbledBadge>가능</AbledBadge>
                    </ContentsBlock>

                    <ContentsBlock noMargin>
                      <ContentsDesc>
                        질의응답 페이지 상세내용 및 댓글 확인
                      </ContentsDesc>
                      <DottedBorder></DottedBorder>
                      <AbledBadge>가능</AbledBadge>
                    </ContentsBlock>
                  </ContentsWrap>
                </ContentsContainer>
              </MainContents03>
            </SubTextWrap>
          </MainContentsWrap>

          <TabBarWrap>
            <Tab
              contents={contents}
              array={array}
              margin
              onActiveTabChanged={(idx) => {
                setMainCategoryId(mainCategories[idx]?.id);
              }}
            />
          </TabBarWrap>

          {/* <MainContents04>
          <ContentsTitle>영재멘토와 함께합니다.</ContentsTitle>
          <ContentsDesc>
            대한민국 상위 1% 교육을 실현하는 파트너를 소개합니다.
          </ContentsDesc>
          <PartnerWrap>
            <PartnerBlock></PartnerBlock>
            <PartnerBlock></PartnerBlock>
            <PartnerBlock></PartnerBlock>
            <PartnerBlock></PartnerBlock>
            <PartnerBlock></PartnerBlock>
            <PartnerBlock></PartnerBlock>
            <PartnerBlock></PartnerBlock>
            <PartnerBlock></PartnerBlock>
          </PartnerWrap>
        </MainContents04> */}
          <CareerSearchBlock>
            <CareerSearchTextWrap>
              <CareerTitle>학습로드맵 컨설팅</CareerTitle>
              <CareerSubTitle>가입하고 컨설팅을 받아보세요!</CareerSubTitle>
            </CareerSearchTextWrap>
            <CareerBtn
              onClick={() => {
                // navigate("/careerSearchGuide");
                if (token !== null) {
                  navigate("/careerSearch");
                } else {
                  setLoginModal(true);
                }
              }}
            >
              진로탐색
            </CareerBtn>
          </CareerSearchBlock>
          <Footer />
          {loginModal ? (
            <Modal
              title={"로그인 안내"}
              content={"진로탐색은 로그인 상태에서 가능합니다."}
              onClose={() => {
                setLoginModal(false);
              }}
              onPress={() => {
                setLoginModal(false);
                navigate("/login");
              }}
              button={[
                {
                  title: "닫기",
                  type: "cancel",
                },
                {
                  title: "로그인하기",
                  type: "active",
                },
              ]}
            />
          ) : null}
          {mobilePopupShow && (
            <div
              style={{
                width: "100vw",
                height: "100vh",
                position: "fixed",
                top: 0,
                left: 0,
                background: "rgba(0, 0, 0, 0.5)",
                zIndex: 99,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
              onClick={() => {
                setMobilePopupShow(false);
              }}
            >
              <p
                style={{
                  color: "#ffffff",
                  textAlign: "right",
                  width: "100%",
                  marginRight: "calc(100vw - 320px)",
                  marginBottom: 16,
                }}
              >
                닫기
              </p>
              <img src={MobilePopupImage} style={{ width: 320 }} alt="mobile" />
            </div>
          )}
        </Container>
        {popupNotice && popupNoticeModal && (
          <PopupBackground>
            <PopupContainer>
              <CloseButtonWrap
                onClick={() => {
                  setPopupNoticeModal(false);
                }}
              >
                <img
                  style={{
                    width: "2rem",
                    height: "auto",
                    objectFit: "contain",
                  }}
                  src={closePopUpIcon}
                  alt="logo"
                />
              </CloseButtonWrap>
              <PopupContent
                onClick={() => {
                  navigate(`/benefit/${popupNotice.id}`);
                }}
              >
                <img src={popupNotice.thumbnail} alt="popup" width="100%" />
              </PopupContent>
              <div style={{ margin: 18 }}>
                <PopupGoneButton
                  onClick={() => {
                    var date = new Date();
                    date = date.setTime(date.getTime() + 24 * 60 * 60 * 1000);
                    localStorage.setItem("popupTodayHidden", date);
                    setPopupNoticeModal(false);
                  }}
                >
                  오늘 하루 보지 않기{" "}
                </PopupGoneButton>
              </div>
            </PopupContainer>
          </PopupBackground>
        )}
      </Desktop>

      {/**로그인하지 않았을 경우 모바일 홈화면 */}
      <Mobile>{token === null ? <LoginMobile /> : <MentoPick />}</Mobile>
    </>
  );
};

export default Home;
