import { useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import searchValueState from "../../states/searchValueState";
import { BannerTitle, Container, BannerText, BannerLink } from "./style";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import SideBar from "../../components/SideBar";
import Seminar from "./components/Seminar";
import OpenLecture from "./components/OpenLecture";
import { Desktop, Mobile } from "../../utils/mediaQuery";
import MobileBottomBar from "../../components/MobileBottomBar";
import MobileHeader from "../../components/MobileHeader";
import {
  MobileConferenceContainer,
  MobileConferenceList,
} from "./mobile.style";
import tokenState from "../../states/tokenState";
import { useNavigate } from "react-router-dom";

const ConferenceRoom = () => {
  const setSearchValue = useSetRecoilState(searchValueState);
  const [tabName, setTabName] = useState("세미나");
  const [openInfoPage, setOpenInfoPage] = useState(false);
  const contents = [
    <Seminar tabName={tabName} key="seminar" />,
  ];
  const token = useRecoilValue(tokenState);
  const navigate = useNavigate();

  const array = [
    {
      name: "세미나",
    },
  ];

  const seminar = true;

  return (
    <>
      <Desktop>
        <Header activeTab={"conferenceRoom"} />
        <Container>
          <SideBar
            seminar={seminar}
            tabName={(state) => {
              setTabName(state);
            }}
            onClick={() => {
              setSearchValue("");
            }}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                setSearchValue("");
              }
            }}
            contents={contents}
            array={array}
            bannerTitle={
              <>  
                <BannerTitle>
                  분야별 전문가와 함께하는{" "}
                  <BannerTitle orange>다양한 세미나</BannerTitle>를 만나보세요.
                </BannerTitle>
                <BannerText>
                  지난 영상이 궁금하다면?{" "}
                  <BannerLink
                    onClick={() => {
                      if (token) {
                        window.open('https://youtube.com/playlist?list=PLHSxCPIXcNMgssOMNMqdNUfWKrgvPErtA');
                      } else {
                        navigate('/login');
                      }
                    }}
                  >
                    유튜브에서 지난 영상 바로보기
                  </BannerLink>
                </BannerText>
              </>
            }
          >
            컨퍼런스 룸
          </SideBar>
        </Container>
        <Footer />
      </Desktop>
      <Mobile>
        <MobileConferenceContainer>
          <MobileHeader
            isModal={openInfoPage}
            setModal={setOpenInfoPage}
            type={openInfoPage ? "sub" : "main"}
            name={"컨퍼런스 룸"}
          />
          {!openInfoPage && (
            <SideBar
              page="conferenceRoom"
              setTabName={setTabName}
              array={array}
              bannerTitle={
                <>
                  <p>분야별 전문가와 함께하는</p>
                  <h2>
                    <span>다양한 세미나</span>를 만나보세요.
                  </h2>
                  <div>
                    지난 영상이 궁금하다면?
                    <a
                      target="_blank"
                      href="https://youtube.com/playlist?list=PLHSxCPIXcNMgssOMNMqdNUfWKrgvPErtA"
                      rel="noreferrer"
                    >
                      지난 영상 바로보기 {`>`}
                    </a>
                  </div>
                </>
              }
            />
          )}
          <MobileConferenceList>
            <Seminar
              setOpenInfoPage={setOpenInfoPage}
              openInfoPage={openInfoPage}
            />
          </MobileConferenceList>
          <MobileBottomBar activeTab={"conferenceRoom"} />
        </MobileConferenceContainer>
      </Mobile>
    </>
  );
};

export default ConferenceRoom;
