import styled from "styled-components";

export const MobileSignUpHeader = styled.figure`
  position: fixed;
  display: flex;
  background-color: white;
  align-items: center;
  box-sizing: border-box;
  left: 50%;
  z-index: 1;
  transform: translateX(-50%);
  width: 100%;
  height: 56px;
  padding: 0 24px;
  border-bottom: 1px solid #dde2ea;
  > button {
    width: 24px;
    height: 24px;
    margin-right: 12px;
    padding: 0;
    border: none;
    background-color: transparent;
    img {
      width: 100%;
      height: 100%;
    }
  }

  > h2 {
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
  }
`;

export const MobileSignUpContainer = styled.div`
  padding-top: 56px;
  padding-bottom: 56px;
  > h1 {
    font-size: 20px;
    line-height: 30px;
    font-weight: 700;
    margin-top: 24px;
    color: #212529;
  }
`;

export const MobileSelectMemberType = styled.ul`
  display: flex;
  width: 342px;

  > li {
    box-sizing: border-box;
    padding: 16px;
    border-radius: 4px;

    > div {
      display: flex;
      align-items: center;
      margin-bottom: 16px;
      input {
        margin: 0;
        background-color: white;
        position: relative;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        border: 3px solid #dde2ea;
        margin-right: 12px;
        :checked {
          ::after {
            border-radius: 50%;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            content: "";
            display: block;
            background: #021633;
            width: 12px;
            height: 12px;
            position: absolute;
          }
        }
      }
      p {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
      }
      h3 {
        font-weight: 700;
        font-size: 18px;
        line-height: 26px;
      }
    }
    > ul {
      li {
        display: flex;
        align-items: center;
        height: auto;
        margin-bottom: 8px;
        > figure {
          width: 24px;
          height: 24px;
          background: #ebfbed;
          border-radius: 50%;
          margin-right: 8px;
        }
        > p {
          font-size: 14px;
          line-height: 20px;
        }
      }
    }

    :first-of-type {
      background-color: #021633;
      color: white;
      margin-right: 12px;
    }
    :last-of-type {
      border: 1px solid #dde2ea;
    }
  }
`;

export const MobileTypeNotice = styled.div`
  position: absolute;
  top: 40%;
  transform: translateY(-50%);
  text-align: center;
  h2 {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 16px;
  }
  p {
    color: rgb(255, 173, 46);
    font-weight: 500;
    font-size: 20px;
  }
`;
