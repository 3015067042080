import React, { useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import Modal from "../../../../components/Modal";
import { Desktop, Mobile } from "../../../../utils/mediaQuery";
import add from "../../../../assets/images/add.png";
import {
  AccountInfoText,
  AccountNotRegisterBox,
  AccountRegisterBox,
  BankNameText,
  Container,
  EmptyAccountText,
  InfoListContent,
  InfoListTitle,
  InfoTitle,
  InfoTitleWrap,
  InfoWrap,
  LinkText,
  RegisterAccountText,
  RowWrap,
  SubjectText,
} from "../../teacher/tab/style";
import {
  MobileAccountNotRegistBox,
  MobileInfoListContents,
  MobileInfoListTitle,
  MobileInformationContainer,
  MobileInfoTitle,
  MobileInfoWrap,
  MobileLinkText,
  MobileRowWrap,
} from "../mobile.style";

const ModalContent = styled.div`
  text-align: center;
  color: ${(props) => (props.red ? "#FF383B" : "#212529")};
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  white-space: pre-line;
  display: inline-block;
`;

const StudentInformation = ({ data }) => {
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);
  const [finishModal, setFinishModal] = useState(false);

  return (
    <>
      <Desktop>
        <Container>
          <InfoTitleWrap>
            <InfoTitle>회원정보</InfoTitle>
            <LinkText
              onClick={() => {
                navigate("/mypage/student/modifyInfo");
              }}
            >
              수정하기
            </LinkText>
          </InfoTitleWrap>
          <InfoWrap>
            <RowWrap>
              <InfoListTitle>휴대폰 번호</InfoListTitle>
              <InfoListContent>{data.userInfo.phoneNumber}</InfoListContent>
            </RowWrap>
            <RowWrap>
              <InfoListTitle>거주지</InfoListTitle>
              <InfoListContent>{data.userInfo.area}</InfoListContent>
            </RowWrap>
            <RowWrap>
              <InfoListTitle>생년월일</InfoListTitle>
              <InfoListContent>{data.userInfo.birthday}</InfoListContent>
            </RowWrap>
            <RowWrap style={{ alignItems: "flex-start" }}>
              <InfoListTitle>희망 과목</InfoListTitle>
              <RowWrap style={{ flexWrap: "wrap" }}>
                {data.userInfo.subjects.map((subject, idx) => (
                  <SubjectText key={idx}>{subject}</SubjectText>
                ))}
              </RowWrap>
            </RowWrap>
          </InfoWrap>
          <InfoTitleWrap style={{ marginTop: 80 }}>
            <InfoTitle />
            <LinkText
              onClick={() => {
                setModal(true);
              }}
            >
              회원탈퇴하기
            </LinkText>
          </InfoTitleWrap>
        </Container>
        {modal && (
          <Modal
            title={"회원탈퇴"}
            button={[
              {
                title: "취소",
                type: "cancel",
              },
              {
                title: "확인",
                type: "active",
              },
            ]}
            onPress={() => {
              setModal(false);
              setFinishModal(true);
            }}
            onClose={(value) => {
              setModal(value);
            }}
            height="367px"
            htmlContnet={
              <ModalContent>
                {" 회원탈퇴를 할 경우 현재 진행 중인\n수업에 관한 금액 환불은 "}
                <ModalContent red>불가</ModalContent>합니다.
                {"\n그래도 탈퇴하시겠습니까?"}
              </ModalContent>
            }
          />
        )}
        {finishModal && (
          <Modal
            title={"탈퇴완료"}
            content={"탈퇴처리가 완료되었습니다. 감사합니다."}
            button={[
              {
                title: "확인",
                type: "active",
              },
            ]}
            onPress={() => {
              navigate("/");
            }}
            onClose={(value) => {
              setFinishModal(value);
            }}
          />
        )}
      </Desktop>
      <Mobile>
        <MobileInformationContainer>
          <div>
            <InfoTitleWrap>
              <MobileInfoTitle>회원정보</MobileInfoTitle>
              <MobileLinkText>수정하기</MobileLinkText>
            </InfoTitleWrap>
            <MobileInfoWrap>
              <MobileRowWrap>
                <MobileInfoListTitle>휴대폰 번호</MobileInfoListTitle>
                <MobileInfoListContents>
                  {data.userInfo.phoneNumber}
                </MobileInfoListContents>
              </MobileRowWrap>
              <MobileRowWrap>
                <MobileInfoListTitle>거주지</MobileInfoListTitle>
                <MobileInfoListContents>
                  {data.userInfo.area}
                </MobileInfoListContents>
              </MobileRowWrap>
              <MobileRowWrap>
                <MobileInfoListTitle>생년월일</MobileInfoListTitle>
                <MobileInfoListContents>
                  {data.userInfo.birthday}
                </MobileInfoListContents>
              </MobileRowWrap>
              <MobileRowWrap style={{ alignItems: "flex-start" }}>
                <MobileInfoListTitle>희망 과목</MobileInfoListTitle>
                <RowWrap style={{ flexWrap: "wrap" }}>
                  {data.userInfo.subjects.map((subject, idx) => (
                    <SubjectText key={idx}>{subject}</SubjectText>
                  ))}
                </RowWrap>
              </MobileRowWrap>
            </MobileInfoWrap>
          </div>
          <div>
            <InfoTitleWrap>
              <MobileInfoTitle>포인트 정보</MobileInfoTitle>
              <MobileLinkText>내역 확인하기</MobileLinkText>
            </InfoTitleWrap>
          </div>
          <div>
            <InfoTitleWrap style={{ marginTop: 80, marginBottom: 20 }}>
              <MobileInfoTitle>입금계좌정보</MobileInfoTitle>
              {data.accounts.length !== 0 && (
                <LinkText onClick={() => {}}>삭제하기</LinkText>
              )}
            </InfoTitleWrap>
            {data.accounts.length ? (
              data.accounts.map((account) => (
                <AccountRegisterBox key={account.id}>
                  <RowWrap style={{ alignItems: "center" }}>
                    <div>
                      <BankNameText>{account.bank.name}</BankNameText>
                      <AccountInfoText>
                        {account.holder} {account.accountNumber}
                      </AccountInfoText>
                    </div>
                  </RowWrap>
                </AccountRegisterBox>
              ))
            ) : (
              <MobileAccountNotRegistBox>
                <div>
                  <EmptyAccountText>
                    {data.type === "TEACHER"
                      ? "정산받으실 계좌 정보가 없습니다."
                      : ""}
                  </EmptyAccountText>
                  <button onClick={() => {}}>
                    <img src={add} alt="add" />
                    <p> 등록하기</p>
                  </button>
                </div>
              </MobileAccountNotRegistBox>
            )}
          </div>
        </MobileInformationContainer>
      </Mobile>
    </>
  );
};

export default StudentInformation;
