import react, { useState, useEffect } from "react";
import styled from "styled-components";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import RadioBox from "../../../components/RadioBox";
import TextInput from "../../../components/TextInput";
import Textarea from "../../../components/Textarea";
import Button from "../../../components/Button";

import { ReactComponent as Plus } from "../../../assets/icons/plus.svg";
import Close from "../../../assets/icons/cancle_24px.svg";

import axios from "axios";
import { API_URL } from "../../../API";
import { useNavigate } from "react-router-dom";

const Container = styled.div`
  padding: 200px 0 260px 0;
  width: 812px;
  margin: 0 auto;
`;
const Title = styled.div`
  font-size: 40px;
  font-weight: ${(props) => props.theme.fontWeight.bold};
  color: #212529;
  line-height: 59px;
  margin-bottom: 19px;
`;
const SubTitle = styled.div`
  font-size: 20px;
  color: ${(props) => (props.mainTitle ? "#212529" : "#868E96")};
  padding-bottom: 20px;
  border-bottom: ${(props) => (props.mainTitle ? "2px solid #e9ecef" : null)};
  margin-bottom: ${(props) => (props.mainTitle ? "80px" : "0px")};
  line-height: 29px;
`;
const ContentsWrap = styled.div`
  margin-bottom: 80px;
  width: 812px;
`;
const ContentsTitle = styled.div`
  font-size: 24px;
  font-weight: ${(props) => props.theme.fontWeight.bold};
  color: #212529;
  line-height: 36px;
  margin-bottom: ${(props) => (props.marginBottom ? "7px" : "19px")};
`;
const CurrentPoint = styled.span`
  font-size: 16px;
  font-weight: ${(props) => props.theme.fontWeight.medium};
  color: ${(props) => (props.grey ? "#ADB5BD" : "#021633")};
  :first-child {
  }
  text-align: right;
`;
const PointWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;
const AddImage = styled.div`
  width: 260px;
  height: 196px;
  background-color: #ffffff;
  border-radius: 8px;
  // border: 1px dashed #021633;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23333' stroke-width='4' stroke-dasharray='4%2c10 ' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e");
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-bottom: 16px;
  margin-right: 16px;
`;
const FileInput = styled.input`
  position: absolute;
  width: 0;
  height: 0;
  padding: 0;
  overflow: hidden;
  border: 0;
`;

const FileLabel = styled.label`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
`;
const ImagePreviewWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
const ImagePreviewBlock = styled.div`
  width: 260px;
  height: 196px;
  position: relative;
  margin-bottom: 16px;
  margin-right: 16px;

  :nth-child(3n) {
    margin-right: 0px;
  }
`;
const ImagePreview = styled.img`
  width: 260px;
  height: 196px;
  border-radius: 8px;
  background-image: ${(props) => (props.image ? props.image : null)};
`;
const CloseBtn = styled.img`
  width: 24px;
  height: 24px;
  position: absolute;
  right: 16px;
  top: 16px;
  cursor: pointer;
`;

const QnaRegister = () => {
  const [category, setCategory] = useState("");
  const [title, setTitle] = useState("");
  const [desc, setDesc] = useState("");
  const [point, setPoint] = useState("");
  const [files, setFiles] = useState([]);
  const [originalFiles, setOriginalFiles] = useState([]);
  const [userPoint, setUserPoint] = useState(0);
  const [qnaCategories, setQNACategories] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (originalFiles && originalFiles.length) {
      Promise.all(
        Array.from(originalFiles).map(async (item) => {
          const formData = new FormData();
          formData.append("file", item);
          const result = await axios.post(`${API_URL}/file/upload`, formData);
          if (result) {
            return {
              url: result.data.result.url,
              name: item.name,
            };
          }
        })
      ).then((res) => {
        setFiles([...files, ...res]);
      });
    }
  }, [originalFiles]);

  const createQnA = () => {
    axios({
      url: `${API_URL}/qna`,
      method: "POST",
      data: {
        title: title,
        categoryId: parseInt(category, 10),
        contents: desc,
        attachmentUrls: files.map(file => file.url),
        point: point,
      },
    }).then((res) => {
      if (res.data.success) {
        alert('질의응답이 등록되었습니다!');
        navigate('/QnA');
      }
    });
  };

  const getCategories = () => {
    axios({
      method: 'GET',
      url: `${API_URL}/qna/categories`
    }).then((res) => {
      if (res.data.success) {
        setQNACategories(res.data.result.qnaCategories.map(item => {
          return {
            label: item.name,
            name: 'category',
            value: `${item.id}`,
            id: item.id,
          }
        }));
      }
    })
  };

  useEffect(() => {
    getCategories();
  }, []);

  return (
    <div>
      <Header activeTab={"QnA"} />
      <Container>
        <Title>질문하기</Title>
        <SubTitle mainTitle>
          질문이 있다면 언제든지 영재멘토에게 물어봐주세요!
        </SubTitle>
        <ContentsWrap>
          <ContentsTitle>질문할 카테고리를 선택해주세요.</ContentsTitle>
          <RadioBox
            option={qnaCategories}
            showLine={3}
            onChange={(value) => {
              setCategory(value);
            }}
          />
        </ContentsWrap>

        <ContentsWrap>
          <ContentsTitle>질문제목을 입력해주세요.</ContentsTitle>
          <TextInput
            style={{ fontWeight: 400, width: "100%" }}
            placeholder={"제목입력"}
            value={title}
            onChange={(value) => {
              setTitle(value);
            }}
          />
        </ContentsWrap>

        <ContentsWrap>
          <ContentsTitle>질문내용을 입력해주세요.</ContentsTitle>
          <Textarea
            style={{ fontSize: "16px" }}
            placeholder={"내용 입력"}
            value={desc}
            onChange={(value) => {
              setDesc(value);
            }}
          />
        </ContentsWrap>

        <ContentsWrap>
          <ContentsTitle marginBottom>
            첨부할 파일이 있다면 등록해주세요.
          </ContentsTitle>
          <SubTitle>첨부파일은 20MB 이하로 등록해주세요.</SubTitle>

          <ImagePreviewWrap>
            <AddImage>
              <FileLabel htmlFor="file" />
              <FileInput
                type="file"
                id="file"
                multiple="multiple"
                accept=".jpg, .png"
                onChange={(e) => {
                  setOriginalFiles(e.target.files);
                }}
              />
              <Plus />
            </AddImage>
            {files.map((image) => {
              return (
                <ImagePreviewBlock key={image.url}>
                  <ImagePreview src={image.url} />
                  <CloseBtn
                    src={Close}
                    onClick={() => {
                      setFiles(
                        files.filter((item) => {
                          return item.url !== image.url;
                        })
                      );
                    }}
                  />
                </ImagePreviewBlock>
              );
            })}
          </ImagePreviewWrap>
        </ContentsWrap>

        <ContentsWrap>
          <ContentsTitle marginBottom>
            지급할 포인트를 입력해주세요 (선택)
          </ContentsTitle>
          <SubTitle>채택한 답변자에게 지급될 포인트입니다.</SubTitle>
          <TextInput
            type="number"
            pointUnit="전부사용"
            onClick={() => {
              setPoint(500);
            }}
            usePoint={point >= userPoint ? true : false}
            style={{ fontWeight: 400, width: "100%" }}
            placeholder="포인트 입력"
            value={point}
            onChange={(value) => {
              if (value > userPoint) {
                value = userPoint;
                setPoint(value);
              } else {
                setPoint(value);
              }
            }}
          />
          <PointWrap>
            <CurrentPoint grey>보유포인트 </CurrentPoint>
            <CurrentPoint>&nbsp;{userPoint} 포인트</CurrentPoint>
          </PointWrap>
        </ContentsWrap>

        <Button
          active
          style={{ width: "260px", margin: "0 auto" }}
          onClick={() => {
            createQnA();
          }}
        >
          완료
        </Button>
      </Container>
    </div>
  );
};

export default QnaRegister;
