import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import {
  Container,
  MainWrap,
  PageTypeText,
  JoinTypeText,
  JoinNoticeText,
  Title,
  RowWrap,
  InfoTitle,
  RegexText,
  Border,
  SubjectChoiceWrap,
  ChoiceBox,
  ChoiceText,
  CancleWrap,
  ButtonWrap,
} from "../../signup/student/style";
import {
  SchoolTitle,
  DocumentInfoWrap,
  DocumentInfoNotice,
  DocumentKindWrap,
  DocumentKindTitle,
  DocumnetKindContent,
  ExampleBox,
  ExampleWrap,
  ExampleContent,
  RadioBoxBorder,
} from "../../signup/teacher/style";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import TextInput from "../../../components/TextInput";
import Button from "../../../components/Button";
import Select from "../../../components/Select";
import RadioBox from "../../../components/RadioBox";
import CheckBox from "../../../components/CheckBox";
import Modal from "../../../components/Modal";
import SearchBar from "../../../components/SearchBar";
import FileUploadBox from "../../../components/FileUploadBox";

import { ReactComponent as DotLineIcon } from "../../../assets/icons/dot_line.svg";

import { API_URL } from "../../../API";

const TeacherModifyInfo = () => {
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);
  const [nickName, setNickName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [birthDay, setBirthDay] = useState("");
  const [province, setProvince] = useState("");
  const [city, setCity] = useState("");
  const [dong, setDong] = useState("");
  const [gender, setGender] = useState("");
  const [phoneNumberAuth, setPhoneNumberAuth] = useState(false);
  const [phoneNumberAuthCheck, setPhoneNumberAuthCheck] = useState("");
  const [phoenNumberEditDiasbled, setPhoneNumberEditDisabled] = useState(false);
  const [unitFocus, setUnitFocus] = useState(false);
  const [min, setMin] = useState(4);
  const [sec, setSec] = useState(0);
  const time = useRef(240);
  const timerId = useRef(null);
  const [countDown, setCountDown] = useState(false);
  const [authModal, setAuthModal] = useState(false);
  const [authSuccessModal, setAuthSuccessMoal] = useState(false);
  const [authFailModal, setAuthFailModal] = useState(false);
  const [cities, setCities] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [towns, setTowns] = useState([]);
  const [nicknameLabel, setNicknameLabel] = useState(null);
  const [types, setTypes] = useState([]);
  const [levels, setLevels] = useState([]);
  const [majors, setMajors] = useState([]);
  const [items, setItems] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [selectSubjects, setSelectSubjects] = useState([]);
  const [type, setType] = useState("");
  const [level, setLevel] = useState("");
  const [major, setMajor] = useState("");
  const [files, setFiles] = useState([]);

  //닉네임 중복 체크
  const nicknameCheck = () => {
    axios({
      method: "POST",
      url: `${API_URL}/user/check/nickname?nickname=${nickName}`,
    })
      .then((result) => {
        if (result.data.success === true) {
          if (result.data.result.isDuplicate === true) {
            setNicknameLabel("이미 사용 중인 닉네임 입니다.");
          } else {
            setNicknameLabel("사용 가능한 닉네임 입니다.");
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //닉네임 특수문자 확인
  useEffect(() => {
    const regex = /[~!@#$%^&*()_+|<>?:{}]/;
    if (nickName) {
      setNickName(nickName.replace(regex, ""));
    }
    setNicknameLabel(null);
  }, [nickName]);

  const start = () => {
    timerId.current = setInterval(() => {
      time.current -= 1;
      setMin(parseInt(time.current / 60));
      setSec(time.current % 60);
      if (time.current < 61) {
        setCountDown(true);
      } else {
        setCountDown(false);
      }
    }, 1000);
  };

  useEffect(() => {
    if (time.current === 0) {
      clearInterval(timerId.current);
      setCountDown(false);
      time.current = 240;
      setAuthModal(true);
    }
  }, [sec]);

  //문자 전송
  const sendSMS = () => {
    axios({
      method: "POST",
      url: `${API_URL}/user/sms/send`,
      data: {
        phoneNumber: phoneNumber,
      },
    })
      .then((result) => {
        console.log(result);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const phoneNumberAuthHandler = () => {
    setPhoneNumberAuth(true);
    if (time.current < 240) {
      clearInterval(timerId.current);
      time.current = 240;
      start();
    } else {
      clearInterval(timerId.current);
      start();
    }
  };

  //인증번호 확인
  const authentication = () => {
    axios({
      method: "POST",
      url: `${API_URL}/user/sms/verify`,
      data: {
        phoneNumber: phoneNumber,
        authCode: phoneNumberAuthCheck,
      },
    })
      .then((result) => {
        if (result.data.success === true) {
          setCountDown(false);
          clearInterval(timerId.current);
          setAuthSuccessMoal(true);
          setPhoneNumberEditDisabled(true);
        } else {
          setAuthFailModal(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    axios({
      method: "GET",
      url: `${API_URL}/area/cities`,
    })
      .then((result) => {
        setCities(
          result.data.result.cities.map((item) => {
            return {
              label: item,
              value: item,
            };
          })
        );
      })
      .catch((err) => {
        console.log(err);
      });

    axios({
      method: "GET",
      url: `${API_URL}/subject/types`,
    })
      .then((result) => {
        if (result.data.success === true) {
          setTypes([
            ...result.data.result.types.map((item) => {
              return {
                label: item.type,
                name: "kind",
                value: item.type,
                id: item.id,
              };
            }),
            {
              label: "아직 잘 모르겠어요",
              name: "kind",
              value: "아직 잘 모르겠어요",
              id: "아직 잘 모르겠어요",
            },
          ]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    axios({
      method: "GET",
      url: `${API_URL}/area/districts?cityName=${province.value}`,
    })
      .then((result) => {
        setDistricts(
          result.data.result.districts.map((item) => {
            return {
              label: item,
              value: item,
            };
          })
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }, [province]);

  useEffect(() => {
    axios({
      method: "GET",
      url: `${API_URL}/area/towns?cityName=${province.value}&districtName=${city.value}`,
    })
      .then((result) => {
        setTowns(
          result.data.result.towns.map((item) => {
            return {
              label: item.town,
              value: item.town,
              id: item.id,
            };
          })
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }, [city]);

  const getSubjectLevels = (type) => {
    axios({
      method: "GET",
      url: `${API_URL}/subject/levels?type=${type}`,
    })
      .then((result) => {
        if (result.data.success === true) {
          setLevels([
            ...result.data.result.levels.map((item) => {
              return {
                label: item.level,
                name: "level",
                value: item.level,
                id: item.id,
              };
            }),
          ]);
          setSubjects([
            ...subjects,
            ...result.data.result.levels.map((item) => {
              return item;
            }),
          ]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getSubjectMajors = (type, level) => {
    axios({
      method: "GET",
      url: `${API_URL}/subject/majors?type=${type}&level=${level}`,
    })
      .then((result) => {
        if (result.data.success === true) {
          setMajors([
            ...result.data.result.majors.map((item) => {
              return {
                label: item.name,
                name: "major",
                value: item.name,
                id: item.id,
              };
            }),
          ]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getSubjectItems = (type, level, major) => {
    axios({
      method: "GET",
      url: `${API_URL}/subject/items?type=${type}&level=${level}&major=${major}`,
    })
      .then((result) => {
        if (result.data.success === true) {
          setItems([
            ...result.data.result.items.map((item) => {
              return {
                label: item.progress,
                name: "major",
                value: item.progress,
                id: item.id,
              };
            }),
          ]);
          setSubjects([
            ...subjects,
            ...result.data.result.items.map((item) => {
              return item;
            }),
          ]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    setLevels([]);
    setMajors([]);
    setItems([]);
  }, [type]);

  useEffect(() => {
    setMajors([]);
    setItems([]);
  }, [level]);

  useEffect(() => {
    setItems([]);
  }, [major]);

  return (
    <>
      <Header activeTab={"Login"} />
      <Container>
        <MainWrap>
          <PageTypeText>회원정보수정</PageTypeText>
          <JoinTypeText>선생님</JoinTypeText>
          <JoinNoticeText />
          <Title>가입 정보</Title>
          <InfoTitle>닉네임</InfoTitle>
          <RegexText>특수문자 제외, 10글자 이하로 입력해주세요.</RegexText>
          <RowWrap
            style={{ marginBottom: nicknameLabel === null ? "80px" : "48px" }}
          >
            <TextInput
              style={{ width: 636 }}
              value={nickName}
              placeholder={"닉네임 입력"}
              onChange={(value) => {
                setNickName(value);
              }}
              maxLength={10}
              marginBottom={"0px"}
              label={nicknameLabel}
              error={
                nicknameLabel === "사용 가능한 닉네임 입니다." ? false : true
              }
            />
            <Button
              active
              style={{ width: 160, marginLeft: 16 }}
              onClick={() => {
                nicknameCheck();
              }}
            >
              중복확인
            </Button>
          </RowWrap>
          <InfoTitle>휴대폰 번호</InfoTitle>
          <RowWrap marginBottom={phoneNumberAuth ? "20px" : "80px"}>
            {/* <Select
              value={telType}
              placeholder={"통신사 선택"}
              width={260}
              option={telOption}
              onChange={(value) => {
                setTelType(value);
              }}
            /> */}
            <TextInput
              style={{ width: 636 }}
              value={phoneNumber}
              onChange={(value) => {
                setPhoneNumber(value);
              }}
              placeholder={"(-) 제외한 숫자만 입력"}
              maxLength={11}
              disabled={phoenNumberEditDiasbled}
            />
            <Button
              grey={phoneNumberAuth}
              active={!phoneNumberAuth}
              style={{ marginLeft: 16, width: 160 }}
              onClick={() => {
                if (phoneNumberAuth) {
                  setMin(4);
                  setSec(0);
                }
                phoneNumberAuthHandler();
                sendSMS();
              }}
              disabled={phoenNumberEditDiasbled}
            >
              {phoneNumberAuth ? "재요청" : "인증요청"}
            </Button>
          </RowWrap>
          {phoneNumberAuth ? (
            <RowWrap>
              <TextInput
                type="numberic"
                style={{ width: 636 }}
                unitAuth={
                  <div>
                    {min}:{sec < 10 ? `0${sec}` : sec}
                  </div>
                }
                countDown={countDown}
                unitFocus={unitFocus}
                onFocus={() => {
                  setUnitFocus(true);
                }}
                onBlur={() => {
                  setUnitFocus(false);
                }}
                value={phoneNumberAuthCheck}
                onChange={(value) => {
                  setPhoneNumberAuthCheck(value);
                }}
              />
              <Button
                active
                style={{ marginLeft: 16, width: 160 }}
                onClick={() => {
                  phoneNumberAuthHandler();
                  authentication();
                }}
                disabled={phoenNumberEditDiasbled}
              >
                인증확인
              </Button>
            </RowWrap>
          ) : null}
          <Border />
          <Title>세부 정보</Title>
          <InfoTitle>회원님의 성별을 선택해주세요.</InfoTitle>
          <RowWrap>
            <RadioBox
              option={[
                {
                  label: "남자입니다.",
                  name: "gender",
                  value: "man",
                  id: "man",
                },
                {
                  label: "여자입니다",
                  name: "gender",
                  value: "woman",
                  id: "woman",
                },
              ]}
              showLine={2}
              onChange={(value) => {
                setGender(value);
              }}
            />
          </RowWrap>
          <InfoTitle>회원님의 생년월일을 입력해주세요.</InfoTitle>
          <TextInput
            placeholder={"YYYYMMDD"}
            value={birthDay}
            onChange={(value) => {
              setBirthDay(value);
            }}
            maxLength={8}
            style={{ width: "100%", marginBottom: "48px" }}
            disabled
          />
          <InfoTitle>회원님의 거주지를 선택해주세요.</InfoTitle>
          <RowWrap>
            <Select
              placeholder={"시/도"}
              value={province}
              onChange={(value) => {
                setProvince(value);
              }}
              option={cities}
            />
            <Select
              placeholder={"시/군/구"}
              value={city}
              option={districts}
              onChange={(value) => {
                setCity(value);
              }}
            />
            <Select
              placeholder={"읍/면/동"}
              value={dong}
              option={towns}
              onChange={(value) => {
                setDong(value);
              }}
              marginRight={"0px"}
            />
          </RowWrap>
          <RowWrap
            style={{ justifyContent: "space-between" }}
            marginBottom={"0px"}
          >
            <InfoTitle marginBottom={"20px"} marginTop={"0px"}>
              회원님의 필요한 과목을 선택해주세요.
            </InfoTitle>
            <RegexText>*복수 선택이 가능합니다.</RegexText>
          </RowWrap>
          <SubjectChoiceWrap>
            {selectSubjects.length > 0 &&
              selectSubjects.map((item, idx) => {
                if (item.type === "국내") {
                  return (
                    <ChoiceBox key={idx}>
                      <ChoiceText>{`${item.type}(${item.level}) - ${item.name}(${item.progress})`}</ChoiceText>
                      <CancleWrap
                        onClick={() => {
                          setSelectSubjects(
                            selectSubjects.filter(
                              (selectItem) => item !== selectItem
                            )
                          );
                        }}
                      />
                    </ChoiceBox>
                  );
                } else {
                  return (
                    <ChoiceBox key={idx}>
                      <ChoiceText>{`${item.type} - ${item.level}`}</ChoiceText>
                      <CancleWrap
                        onClick={() => {
                          setSelectSubjects(
                            selectSubjects.filter(
                              (selectItem) => item !== selectItem
                            )
                          );
                        }}
                      />
                    </ChoiceBox>
                  );
                }
              })}
          </SubjectChoiceWrap>
          <RowWrap marginBottom={"0px"}>
            <RadioBox
              option={types}
              showLine={4}
              onChange={(value) => {
                getSubjectLevels(value);
                setType(value);
              }}
              disabled={selectSubjects.length > 0}
            />
          </RowWrap>
          <RadioBoxBorder />
          {levels.length > 0 && (
            <>
              <RowWrap marginBottom={"0px"}>
                <RadioBox
                  option={levels}
                  showLine={type === "국내" ? 2 : 4}
                  onChange={(value) => {
                    if (type === "국내") {
                      getSubjectMajors(type, value);
                    } else {
                      subjects.map((item) => {
                        if (item.level === value && item.type === type) {
                          if (
                            selectSubjects.filter(
                              (subItem) => item.id === subItem.id
                            ).length < 1
                          ) {
                            setSelectSubjects([...selectSubjects, item]);
                          }
                        }
                      });
                    }
                    setLevel(value);
                  }}
                />
              </RowWrap>
              <RadioBoxBorder />
            </>
          )}
          {majors.length > 0 && (
            <>
              <RowWrap marginBottom={"0px"}>
                <RadioBox
                  option={majors}
                  showLine={4}
                  onChange={(value) => {
                    getSubjectItems(type, level, value);
                    setMajor(value);
                  }}
                />
              </RowWrap>
              <RadioBoxBorder />
            </>
          )}
          {items.length > 0 && (
            <>
              <RowWrap marginBottom={"0px"}>
                <RadioBox
                  option={items}
                  showLine={4}
                  onChange={(value) => {
                    if (value) {
                      subjects.map((item) => {
                        if (item.progress === value) {
                          if (
                            selectSubjects.filter(
                              (subItem) => item.id === subItem.id
                            ).length < 1
                          ) {
                            setSelectSubjects([...selectSubjects, item]);
                          }
                        }
                      });
                    }
                  }}
                />
              </RowWrap>
            </>
          )}
          <InfoTitle>인증 서류를 등록해주세요.</InfoTitle>
          <DocumentInfoWrap>
            <div style={{ marginBottom: 80, width: "100%" }}>
              <DocumentKindWrap
                style={{
                  marginRight: 16,
                  minHeight: 167,
                  maxWidth: "100%",
                }}
              >
                <DocumentKindTitle>과외 가능 과목 인증</DocumentKindTitle>
                <DocumnetKindContent>
                  과외 가능 과목에 대한 증빙서류가 필요합니다.
                </DocumnetKindContent>
                <ExampleWrap>
                  <ExampleBox>예시</ExampleBox>
                  <ExampleContent>
                    내신 과목의 경우 성적증명서가 필요하며, 대회 과목의
                    <br /> 경우 대회활동기록과 대학교 증명서가 필요합니다.
                  </ExampleContent>
                </ExampleWrap>
              </DocumentKindWrap>
            </div>
            <SchoolTitle>첨부하기</SchoolTitle>
            <DocumentInfoNotice>
              첨부파일은 20MB 이하의 JPG, PNG, PDF 파일로만 업로드 가능합니다.
            </DocumentInfoNotice>
            <FileUploadBox
              onChange={(value) => {
                setFiles(value);
              }}
            />
          </DocumentInfoWrap>
          <ButtonWrap>
            <Button
              active
              onClick={() => {
                setModal(true);
              }}
            >
              완료
            </Button>
          </ButtonWrap>
        </MainWrap>
        <Footer />
      </Container>
      {modal && (
        <Modal
          title={"변경완료"}
          content={"변경한 내용대로 정보가 수정되었습니다."}
          button={[
            {
              title: "확인",
              type: "active",
            },
          ]}
          onPress={() => {
            navigate("/mypage/teacher");
          }}
        />
      )}
      {authModal ? (
        <Modal
          padding
          title={"입력시간 초과"}
          content={"인증번호 입력시간이 초과되었습니다. 다시 입력해주세요."}
          button={[
            {
              title: "재전송",
              type: "active",
            },
          ]}
          onPress={() => {
            setAuthModal(false);
            start();
          }}
        />
      ) : null}
      {authFailModal && (
        <Modal
          padding
          title={"인증실패"}
          content={"입력한 인증번호가 일치하지 않습니다. 다시 입력해주세요."}
          button={[
            {
              title: "확인",
              type: "active",
            },
          ]}
          onPress={() => {
            setAuthFailModal(false);
          }}
        />
      )}
      {authSuccessModal && (
        <Modal
          padding
          title={"인증완료"}
          content={"인증이 완료되었습니다."}
          button={[
            {
              title: "확인",
              type: "active",
            },
          ]}
          onPress={() => {
            setAuthSuccessMoal(false);
          }}
        />
      )}
    </>
  );
};

export default TeacherModifyInfo;
