import React, { useState, useEffect } from "react";

import {
  InfoTitle,
  RegexText,
  SubjectChoiceWrap,
  ChoiceBox,
  ChoiceText,
  CancleWrap,
  Border,
} from "../../../signup/student/style";
import {
  PageTitle,
  Container,
  MainWrap,
  RequestTitle,
  RowWrap,
  RequestContent,
} from "./style";
import Header from "../../../../components/Header";
import Footer from "../../../../components/Footer";
import TextInput from "../../../../components/TextInput";
import Button from "../../../../components/Button";
import RadioBox from "../../../../components/RadioBox";
import Select from "../../../../components/Select";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../../../../API";
import formatSubject from "../../../../utils/formatSubject";

const PaymentRequest = () => {
  const [allDays, setAllDays] = useState(false);
  const [week, setWeek] = useState([
    { label: "월", value: false },
    { label: "화", value: false },
    { label: "수", value: false },
    { label: "목", value: false },
    { label: "금", value: false },
    { label: "토", value: false },
    { label: "일", value: false },
  ]);
  const [subjectType, setSubjectType] = useState("");
  const [price, setPrice] = useState("");
  const [otRequest, setOTRequest] = useState(null);
  const [subject, setSubject] = useState(null);
  const [selectSubjects, setSelectSubjects] = useState([]);
  const [startDay, setStartDay] = useState('');
  const [endDay, setEndDay] = useState('');
  const [timeType, setTimeType] = useState({ value: '오전', label: '오전' });
  const [time, setTime] = useState({ value: '12시', label: '12시' });
  const [studyCount, setStudyCount] = useState('');
  const [studyTime, setStudyTime] = useState('');
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    setAllDays(
      week.filter((weekItems) => weekItems.value).length === week.length
    );
  }, [week]);

  const selectAll = () => {
    if (allDays) {
      setWeek((weekItems) =>
        weekItems.map((term) => ({ ...term, value: false }))
      );
    } else {
      setWeek((weekItems) =>
        weekItems.map((term) => ({ ...term, value: true }))
      );
    }
  };

  const uncomma = (str) => {
    str = String(str);
    return str.replace(/[^\d]+/g, "");
  };

  const inputPriceFormat = (str) => {
    const comma = (str) => {
      str = String(str);
      return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, "$1,");
    };

    return comma(uncomma(str));
  };

  const getData = () => {
    axios({
      method: 'GET',
      url: `${API_URL}/mypage/otRequest/${params.id}`
    }).then((res) => {
      if (res.data.success) {
        setOTRequest(res.data.result.otRequest);
      } else {
        alert(res.data.error.message);
        navigate(-1);
      }
    })
  };

  const requestPayment = () => {
    const isSubject = subjectType === 'subject';

    axios({
      method: 'POST',
      url: `${API_URL}/mypage/otRequest/${params.id}/paymentRequest`,
      data: {
        isSubject,
        subjectIds: isSubject ? selectSubjects.map(selectSubject => selectSubject.id) : [],
        startDay: isSubject ? startDay : null,
        endDay: isSubject ? endDay : null,
        classDays: isSubject ? week.filter(weekItem => weekItem.value === true).map(weekItem => weekItem.label) : [],
        studyStartTime: isSubject ? `${timeType.value} ${time.value}` : null,
        studyTime: isSubject ? studyTime : null,
        studyCount: isSubject ? null : parseInt(studyCount, 10),
        price: parseInt(uncomma(price), 10)
      }
    }).then((res) => {
      if (res.data.success) {
        alert('결제 요청이 완료되었습니다!');
        navigate('/mypage');
      } else {
        alert(res.data.error.message);
      }
    })
  };

  useEffect(() => {
    if (params.id) {
      getData();
    }
  }, [params]);

  useEffect(() => {
    if (subject) {
      const selectedSubject = otRequest
        .mentopick
        .subjects
        .map(subjectItem => subjectItem.subject)
        .find(subjectItem => subjectItem.id === subject.value);

      setSelectSubjects(prevSelectSubjects => [...prevSelectSubjects, selectedSubject]);
      setSubject(null);
    }
  }, [subject, otRequest]);

  return (
    <>
      <Header activeTab={"MyPage"} />
      <Container>
        {
          otRequest !== null && (
            <MainWrap>
              <PageTitle>결제요청하기</PageTitle>
              <RequestTitle>수업 학생</RequestTitle>
              <TextInput disabled value={otRequest.user.nickname} style={{ width: "100%" }} />
              <RequestTitle>수업과목을 선택해주세요.</RequestTitle>
              <RowWrap>
                <RadioBox
                  option={[
                    {
                      label: "교과",
                      name: "kind",
                      value: "subject",
                      id: "subject",
                    },
                    {
                      label: "비교과",
                      name: "kind",
                      value: "non_subject",
                      id: "non_subject",
                    },
                  ]}
                  showLine={2}
                  onChange={(value) => {
                    setSubjectType(value);
                  }}
                />
              </RowWrap>
              <Border style={{ marginTop: 32 }} />
              {subjectType === "subject" && (
                <>
                  <RowWrap
                    style={{ justifyContent: "space-between" }}
                    marginBottom={"0px"}
                  >
                    <InfoTitle />
                    <RegexText>* 복수 선택이 가능합니다.</RegexText>
                  </RowWrap>
                  <SubjectChoiceWrap>
                    <RowWrap style={{ flexWrap: "wrap" }}>
                      {
                        selectSubjects.map(selectSubject => (
                          <ChoiceBox>
                            <ChoiceText>{ formatSubject(selectSubject) }</ChoiceText>
                            <CancleWrap onClick={() => { setSelectSubjects(selectSubjects.filter(subjectItem => subjectItem.id !== selectSubject.id)) }} />
                          </ChoiceBox>
                        ))
                      }
                    </RowWrap>
                  </SubjectChoiceWrap>
                  <Select
                    width={"100%"} 
                    value={subject} 
                    placeholder={"과목 선택"}
                    onChange={(value) => {
                      setSubject(value);
                    }}
                    option={
                      otRequest
                        .mentopick
                        .subjects
                        .map(subject => subject.subject)
                        .filter(subject => subject.type !== '비교과')
                        .filter(subject => !selectSubjects.map(subjectItem => subjectItem.id).includes(subject.id))
                        .map(subject => {
                          return {
                            value: subject.id,
                            label: formatSubject(subject)
                          }
                        })
                    }
                  />
                  <RequestTitle>수업 기간을 입력해주세요.</RequestTitle>
                  <RowWrap>
                    <div style={{ width: "50%", marginRight: 16 }}>
                      <RequestContent>수업시작일</RequestContent>
                      <TextInput
                        placeholder={"YYYYMMDD"}
                        value={startDay}
                        onChange={setStartDay}
                        style={{ width: "100%" }}
                      />
                    </div>
                    <div style={{ width: "50%" }}>
                      <RequestContent>수업종료일</RequestContent>
                      <TextInput
                        placeholder={"YYYYMMDD"}
                        value={endDay}
                        onChange={setEndDay}
                        style={{ width: "100%" }}
                      />
                    </div>
                  </RowWrap>
                  <RequestTitle>수업 요일을 선택해주세요.</RequestTitle>
                  <Button
                    style={{
                      width: "calc(25% - 3 *16px/4)",
                      display: "inline-block",
                      marginRight: 16,
                      marginBottom: 16,
                      transition: "0.5s",
                    }}
                    onClick={() => {
                      selectAll();
                    }}
                    active={allDays}
                  >
                    매일
                  </Button>
                  {week.map((item, idx) => (
                    <Button
                      key={idx}
                      style={{
                        width: "calc(25% - 3 *16px/4)",
                        display: "inline-block",
                        marginRight: idx === 2 || idx === 6 ? "0px" : "16px",
                        marginBottom: 16,
                        transition: "0.5s",
                      }}
                      active={item.value}
                      onClick={() => {
                        setWeek(
                          week.map((weekItem) => {
                            if (item.label === weekItem.label) {
                              return {
                                ...weekItem,
                                value: !item.value,
                              };
                            }
                            return weekItem;
                          })
                        );
                      }}
                    >
                      {item.label}
                    </Button>
                  ))}
                  <RequestTitle>수업 시간을 입력해주세요.</RequestTitle>
                  <RowWrap>
                    <div style={{ width: "50%" }}>
                      <RequestContent>수업 시작시간</RequestContent>
                      <RowWrap>
                        <Select
                          width={"150px"} 
                          value={timeType}
                          onChange={(value) => {
                            setTimeType(value);
                          }}
                          placeholder={"오전"} 
                          option={[
                            { value: '오전', label: '오전' },
                            { value: '오후', label: '오후' }
                          ]}
                        />
                        <Select
                          width={"150px"}
                          value={time}
                          onChange={(value) => {
                            setTime(value);
                          }}
                          placeholder={"시간"}
                          option={[
                            { value: '12시', label: '12시' },
                            { value: '1시', label: '1시' },
                            { value: '2시', label: '2시' },
                            { value: '3시', label: '3시' },
                            { value: '4시', label: '4시' },
                            { value: '5시', label: '5시' },
                            { value: '6시', label: '6시' },
                            { value: '7시', label: '7시' },
                            { value: '8시', label: '8시' },
                            { value: '9시', label: '9시' },
                            { value: '10시', label: '10시' },
                            { value: '11시', label: '11시' },
                          ]}
                        />
                      </RowWrap>
                    </div>
                    <div style={{ width: "50%" }}>
                      <RequestContent>수업시간</RequestContent>
                      <TextInput
                        style={{ width: "100%" }}
                        unit={"시간"}
                        maxLength={2}
                        value={studyTime}
                        onChange={setStudyTime}
                      />
                    </div>
                  </RowWrap>
                  <RequestTitle>수업료를 입력해주세요.</RequestTitle>
                  <TextInput
                    style={{ width: "100%" }}
                    unit={"원"}
                    maxLength={70}
                    value={price}
                    onChange={(value) => {
                      setPrice(inputPriceFormat(value));
                    }}
                    won
                  />
                  <div style={{ padding: "0px 276px", marginTop: 64 }}>
                    <Button onClick={requestPayment} active>결제요청하기</Button>
                  </div>
                </>
              )}
              {subjectType === "non_subject" && (
                <>
                  <RequestTitle>수업 횟수를 입력해주세요.</RequestTitle>
                  <TextInput value={studyCount} onChange={setStudyCount} style={{ width: "100%" }} />
                  <RequestTitle>수업료를 입력해주세요.</RequestTitle>
                  <TextInput
                    style={{ width: "100%" }}
                    unit={"원"}
                    maxLength={70}
                    value={price}
                    onChange={(value) => {
                      setPrice(inputPriceFormat(value));
                    }}
                    won
                  />
                  <div style={{ padding: "0px 276px", marginTop: 64 }}>
                    <Button onClick={requestPayment} active>결제요청하기</Button>
                  </div>
                </>
              )}
            </MainWrap>
          )
        }
        <Footer />
      </Container>
    </>
  );
};

export default PaymentRequest;
