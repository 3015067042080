import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import axios from "axios";

import GlobalStyle from "./GlobalStyle";

import tokenState from "../states/tokenState";

import Notification from "./Notification";

import NavyLogo from "../assets/logos/logo_text_type_navy.png";
import WhiteLogo from "../assets/logos/logo_text_type_white.png";

import { API_URL } from "../API";
import userInfoState from "../states/userInfoState";

const Container = styled.div`
  width: 100%;
  min-height: 100px;
  padding: 20px 140px 18px;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  display: flex;

  transition: 0.5s;
  background: rgba(2, 22, 51, 0);
  backdrop-filter: blur(20px);
  z-index: 10;

  @media screen and (max-width: 1280px) {
    width: 100%;
    padding: 20px 40px 18px;
  }
`;

const LogoWrap = styled.div`
  width: 128px;
  height: 38px;
  margin-right: 145px;
  z-index: 10;
  cursor: pointer;
  flex: none;

  img {
    object-fit: contain;
  }
`;

const MenuWrap = styled.div`
  margin-top: 5px;
  z-index: 10;
  background-color: transparent;
  width: 100%;
  position: relative;
`;

const MenuListWrap = styled.button`
  margin-right: 60px;
  background-color: transparent;
  border: none;
  text-decoration: none;
  cursor: pointer;
  @media screen and (max-width: 1280px) {
    margin-right: 40px;
  }
`;

const MenuListText = styled.div`
  color: ${(props) =>
    props.scrollOnTop ? props.theme.color.textColor : "#fff"};
  font-size: 16px;
  font-weight: 700;
  border-bottom: ${(props) => (props.focus ? "2px" : "0px")} solid
    ${(props) => (props.scrollActive ? "#fff" : "#000")};
  padding-bottom: 4px;
  line-height: 24px;
  text-align: ${(props) => (props.right ? "right" : null)};
  transition: 0.5s;
`;

const MyWrap = styled.div`
  position: absolute;
  right: 0;
  top: 0;
`;

const LoginWrap = styled.button`
  background-color: transparent;
  border: none;
  text-decoration: none;
  cursor: pointer;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
`;

const Header = ({ activeTab, main }) => {
  const navigate = useNavigate();
  const tokenValue = useRecoilValue(tokenState);
  const [scroll, setScroll] = useState(false);
  const [openNotification, setOpenNotification] = useState(false);
  const [userType, setUserType] = useState("");
  const [userInfo, setUserInfo] = useRecoilState(userInfoState);

  const updateScroll = () => {
    if (window.scrollY > 720) {
      setScroll(true);
    } else {
      setScroll(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", updateScroll);
    return () => {
      window.removeEventListener("scroll", updateScroll);
    };
  }, []);

  useEffect(() => {
    axios({
      url: `${API_URL}/mypage`,
      method: "GET",
    })
      .then((result) => {
        if (result.data.success === true) {
          setUserType(result.data.result.type);
          setUserInfo(result.data.result);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <Container>
      <GlobalStyle />
      <LogoWrap
        onClick={() => {
          navigate("/");
        }}
      >
        {main ? (
          scroll ? (
            <Image src={NavyLogo} />
          ) : (
            <Image src={WhiteLogo} />
          )
        ) : (
          <Image src={NavyLogo} />
        )}
      </LogoWrap>
      <MenuWrap>
        <MenuListWrap
          onClick={() => {
            navigate("/mentoPick");
          }}
        >
          <MenuListText
            scrollOnTop={main ? scroll : true}
            focus={activeTab === "myMentoPick"}
          >
            나의 멘토픽
          </MenuListText>
        </MenuListWrap>
        <MenuListWrap
          onClick={() => {
            navigate("/conferenceRoom");
          }}
        >
          <MenuListText
            scrollOnTop={main ? scroll : true}
            focus={activeTab === "conferenceRoom"}
          >
            컨퍼런스 룸
          </MenuListText>
        </MenuListWrap>
        <MenuListWrap
          onClick={() => {
            navigate("/contentsMarket");
          }}
        >
          <MenuListText
            scrollOnTop={main ? scroll : true}
            focus={activeTab === "contentsMarket"}
          >
            컨텐츠 마켓
          </MenuListText>
        </MenuListWrap>
        <MenuListWrap
          onClick={() => {
            navigate("/benefit");
          }}
        >
          <MenuListText
            scrollOnTop={main ? scroll : true}
            focus={activeTab === "Benefit"}
          >
            공지사항
          </MenuListText>
        </MenuListWrap>
        {tokenValue !== null ? (
          <>
            <LoginWrap
              style={{ marginRight: 45 }}
              onClick={() => {
                // navigate("/");
              }}
            >
              <MenuListText
                scrollOnTop={main ? scroll : true}
                scrollActive={main ? !scroll : false}
                focus={openNotification}
                onClick={() => {
                  setOpenNotification(!openNotification);
                }}
                right
              >
                알림
              </MenuListText>
              {openNotification ? (
                <Notification
                  onClick={() => {
                    setOpenNotification(false);
                  }}
                />
              ) : null}
            </LoginWrap>
            <LoginWrap
              onClick={() => {
                navigate("/mypage");
              }}
            >
              <MenuListText
                scrollOnTop={main ? scroll : true}
                focus={activeTab === "MyPage"}
              >
                마이페이지
              </MenuListText>
            </LoginWrap>
          </>
        ) : (
          <LoginWrap
            onClick={() => {
              navigate("/login");
            }}
          >
            <MenuListText
              scrollOnTop={main ? scroll : true}
              focus={activeTab === "Login"}
            >
              로그인
            </MenuListText>
          </LoginWrap>
        )}
      </MenuWrap>
    </Container>
  );
};

export default Header;
