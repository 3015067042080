import React from "react";
import styled from "styled-components";

const Background = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(33, 37, 41, 0.8);
  z-index: 900;
  position: fixed;
  top: 0px;
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ModalContainer = styled.div`
  width: ${(props) => (props.width ? props.width : "536px")};
  height: ${(props) => (props.height ? props.height : "311px")};
  background-color: #ffffff;
  overflow-x: hidden;
  border-radius: 12px;
  position: relative;
  padding: 80px ${(props) => (props.padding ? "0px" : "99px")} 0px;
  box-sizing: border-box;
  text-align: center;
`;

const ModalTitle = styled.div`
  color: #212529;
  font-size: 24px;
  font-weight: 700;
  line-height: 50px;
  text-align: center;
  margin-bottom: 12px;
`;

const ModalContent = styled.div`
  text-align: center;
  color: #212529;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  white-space: pre-line;
`;

const ModalButtonWrap = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
`;

const ModalButton = styled.button`
  width: 100%;
  height: 80px;
  border: none;
  text-decoration: none;
  cursor: pointer;
  background-color: ${(props) =>
    props.type === "cancel" ? "#DDE2EA" : "#021633"};
  color: ${(props) => (props.type === "cancel" ? "#021633" : "#ffffff")};
  font-size: 20px;
  font-weight: 500;
  line-height: 50px;
`;

/*
  modal button params
  ex)
    <Modal
      title={"로그인 실패"}
      content={"아이디와 비밀번호를 확인하세요"}
      button={[
        {
          title: "닫기",
          type: "cancel",
        },
        {
          title: "로그인하기",
          type: "active",
        },
      ]}
    />
*/

const Modal = ({
  title,
  content,
  button,
  onClose,
  onPress,
  width,
  height,
  htmlContnet,
  padding,
  style,
  buttonStyle,
}) => {
  return (
    <Background
      onClick={(event) => {
        if (event.target === event.currentTarget) {
          if (onClose) {
            onClose(false);
          }
        }
      }}
    >
      <ModalContainer
        style={style}
        width={width}
        height={height}
        padding={padding}
      >
        <ModalTitle>{title}</ModalTitle>
        {htmlContnet !== undefined ? (
          htmlContnet
        ) : (
          <ModalContent>{content}</ModalContent>
        )}
        <ModalButtonWrap style={buttonStyle}>
          {button &&
            button.map((item, idx) => {
              if (item.type === "cancel") {
                return (
                  <ModalButton
                    key={idx}
                    type={item.type}
                    onClick={(event) => {
                      if (event.target === event.currentTarget) {
                        if (onClose) {
                          onClose(false);
                          document.body.style.overflow = "auto";
                        }
                      }
                    }}
                  >
                    {item.title}
                  </ModalButton>
                );
              }
              return (
                <ModalButton key={idx} type={item.type} onClick={onPress}>
                  {item.title}
                </ModalButton>
              );
            })}
        </ModalButtonWrap>
      </ModalContainer>
    </Background>
  );
};

export default Modal;
