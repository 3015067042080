import { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";

import Pagination from "../../../components/Pagination";
import Modal from "../../../components/Modal";
import tokenState from "../../../states/tokenState";
import { API_URL } from "../../../API";
import searchValueState from "../../../states/searchValueState";
import formatTimeFromNow from "../../../utils/formatTimeFromNow";
import { Desktop, Mobile } from "../../../utils/mediaQuery";
import { InView } from "react-intersection-observer";
import MobileModal from "../../../components/MobileModal";

const QnaWrap = styled.div`
  margin-top: 36px;
  display: flex;
  flex-wrap: wrap;
  width: 950px;

  @media screen and (max-width: 1280px) {
    width: 820px;
  }
`;
const QnaBlock = styled.div`
  width: 467px;
  height: 241px;
  border-radius: 12px;
  border: 1px solid #dde2ea;
  padding: 32px;
  margin-bottom: 32px;
  cursor: pointer;

  :nth-child(2n) {
    margin-left: 16px;
  }

  @media screen and (max-width: 1280px) {
    width: 402px;
  }
`;
const QnaBadge = styled.div`
  width: fit-content;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eef0f4;
  border-radius: 8px;
  font-size: 16px;
  font-weight: ${(props) => props.theme.fontWeight.medium};
  color: #021633;
  margin-bottom: 12px;
  padding: 4px 20px;
`;
const QnaTitle = styled.span`
  display: inline-block;
  font-size: 24px;
  font-weight: ${(props) => props.theme.fontWeight.bold};
  line-height: 36px;
  color: #212529;
  margin-bottom: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 427px;
`;
const QnaSubTitleWrap = styled.div`
  display: flex;
  border-bottom: 1px solid #dde2ea;
  margin-bottom: 20px;
  padding-bottom: 20px;
`;
const QnaSubTitle = styled.span`
  font-size: 16px;
  font-weight: ${(props) => props.theme.fontWeight.medium};
  line-height: 24px;
  color: ${(props) => (props.grey ? "#ADB5BD" : "#212529")};
  margin-right: 8px;
`;
const RowWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const CommentText = styled.span`
  display: inline-block;
  font-size: 16px;
  height: 24px;
  color: #212529;
`;
const PointBadge = styled.div`
  width: fit-content;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background-color: rgba(51, 216, 78, 0.1);
  color: #33d84e;
  font-size: 12px;
  font-weight: ${(props) => props.theme.fontWeight.medium};
  padding: 3px 12px;
`;
const PaginationWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 160px;
`;

/********모바일스타일*********/

const MobileQnaContainer = styled.ul`
  width: 390px;
  margin: 0 auto;
  padding: 0 24px;
  box-sizing: border-box;
  > li {
    margin-bottom: 18px;
    > button {
      background-color: transparent;
      border: none;
      text-align: left;
      width: 342px;
      left: 24px;
      background: #ffffff;
      border: 1px solid #dde2ea;
      border-radius: 4px;
      padding: 16px;
      box-sizing: border-box;
      > span {
        padding: 4px 12px;
        margin-bottom: 10px;
        background: #e9ecef;
        border-radius: 13px;
        font-weight: 500;
        font-size: 12px;
        display: block;
        width: max-content;
        line-height: 17px;
        color: #021633;
      }
      > h2 {
        font-weight: 700;
        font-size: 16px;
        line-height: 23px;
        color: #212529;
        margin-bottom: 10px;
      }
      > p {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #505860;

        span {
          margin-left: 8px;
          font-weight: 400;
          color: #868e96;
        }
      }

      > hr {
        border: none;
        margin: 12px 0;
        width: 310px;
        height: 1px;
        background: #e9ecef;
      }
      > div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        p {
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: #212529;
        }
        div {
          height: 26px;
          padding: 4px 12px;
          font-weight: 500;
          font-size: 12px;
          line-height: 17px;
          color: #33d84e;
          background: #ebfbed;
          border-radius: 13px;
        }
      }
    }
  }
`;

/**************************/

const QnAList = (props) => {
  const navigate = useNavigate();
  const tabName = props.tabName;
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [openModal, setOpenModal] = useState(false);
  const [qnas, setQnas] = useState([]);
  const token = useRecoilValue(tokenState);
  const searchValue = useRecoilValue(searchValueState);

  useEffect(() => {
    const searchData = {
      category: tabName ? tabName : undefined,
      query: searchValue ? searchValue : undefined,
      page: page,
    };

    axios({
      method: "GET",
      url: `${API_URL}/qna`,
      params: searchData,
    }).then((res) => {
      if (res.data.success) {
        setQnas(res.data.result.qnas);
        setTotalPage(res.data.result.totalPage);
      }
    });
  }, [tabName, searchValue, page]);

  return (
    <>
      <Desktop>
        <QnaWrap>
          {qnas.map((item) => {
            return (
              <QnaBlock
                key={item.id}
                onClick={() => {
                  if (token !== null) {
                    navigate(`${item.id}`);
                  } else {
                    setOpenModal(true);
                  }
                }}
              >
                <QnaBadge>{item.category.name}</QnaBadge>
                <QnaTitle>{item.title}</QnaTitle>
                <QnaSubTitleWrap>
                  <QnaSubTitle>{item.writer}</QnaSubTitle>
                  <QnaSubTitle grey>
                    {formatTimeFromNow(item.createdAt)}
                  </QnaSubTitle>
                </QnaSubTitleWrap>
                <RowWrap>
                  <CommentText>댓글 {item.answerCount}</CommentText>
                  {item.point !== 0 && (
                    <PointBadge>{item.point} 포인트</PointBadge>
                  )}
                </RowWrap>
              </QnaBlock>
            );
          })}
        </QnaWrap>
        <PaginationWrap>
          <Pagination
            tabName={tabName}
            totalItemsCount={totalPage * 10}
            showItemsCount={10}
            page={page}
            nowPage={(page) => {
              setPage(page);
              window.scrollTo(0, 0);
            }}
          />
        </PaginationWrap>
        {openModal ? (
          <Modal
            onClose={() => {
              setOpenModal(false);
            }}
            onPress={() => {
              if (token) {
                navigate("/mypage");
              } else {
                navigate("/login");
              }
            }}
            title={"죄송합니다."}
            content={
              token
                ? "해당 서비스는 유료회원만 이용 가능합니다."
                : "해당 서비스는 로그인 후 이용 가능합니다."
            }
            button={[
              {
                title: "닫기",
                type: "cancel",
              },
              {
                title: token ? "유료회원 결제하기" : "로그인하기",
                type: "active",
              },
            ]}
          />
        ) : null}
      </Desktop>
      <Mobile>
        <MobileQnaContainer>
          {qnas.map((item) => (
            <InView as={"li"}>
              <button
                onClick={() => {
                  if (token !== null) {
                    navigate(`${item.id}`);
                  } else {
                    setOpenModal(true);
                  }
                }}
              >
                <span>{item.category.name}</span>
                <h2>{item.title}</h2>
                <p>
                  {item.writer}{" "}
                  <span> {formatTimeFromNow(item.createdAt)}</span>
                </p>
                <hr />
                <div>
                  <p>댓글 {item.answerCount}</p>
                  {item.point !== 0 && <div>{item.point} 포인트</div>}
                </div>
              </button>
            </InView>
          ))}
        </MobileQnaContainer>
        {openModal ? (
          <MobileModal
            onClose={() => {
              setOpenModal(false);
            }}
            onPress={() => {
              if (token) {
                navigate("/mypage");
              } else {
                navigate("/login");
              }
            }}
            title={"죄송합니다."}
            content={
              token
                ? "해당 서비스는 유료회원만 이용 가능합니다."
                : "해당 서비스는 로그인 후 이용 가능합니다."
            }
            button={[
              {
                title: "닫기",
                type: "cancel",
              },
              {
                title: token ? "유료회원 결제하기" : "로그인하기",
                type: "active",
              },
            ]}
          />
        ) : null}
      </Mobile>
    </>
  );
};

export default QnAList;
