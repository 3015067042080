import React, { useState } from "react";

import TextInput from "../../../../components/TextInput";
import Button from "../../../../components/Button";
import {
  Container,
  MainWrap,
  Border,
  JoinTypeText,
  PageTypeText,
} from "../../../signup/student/style";
import { RowWrap, UploadNoticeTitle, UploadTitle } from "./style";
import Header from "../../../../components/Header";
import Footer from "../../../../components/Footer";
const VideoModify = () => {
  const [videoTitle, setVideoTitle] = useState("");
  const [videoURL, setVideoURL] = useState("");
  return (
    <>
      <Header />
      <Container>
        <MainWrap>
          <PageTypeText>영상자료관련</PageTypeText>
          <JoinTypeText style={{ marginBottom: 20 }}>
            파일 수정하기
          </JoinTypeText>
          <Border />
          <UploadTitle>올리실 영상의 제목을 입력해주세요.</UploadTitle>
          <TextInput
            style={{ width: "100%" }}
            value={videoTitle}
            onChange={(value) => {
              setVideoTitle(value);
            }}
          />
          <UploadTitle style={{ marginBottom: 8 }}>
            업로드할 영상의 URL을 등록해주세요.
          </UploadTitle>
          <UploadNoticeTitle>유튜브 URL만 등록 가능합니다.</UploadNoticeTitle>
          <TextInput
            style={{ width: "100%" }}
            value={videoURL}
            onChange={(value) => {
              setVideoURL(value);
            }}
          />
          <RowWrap style={{ padding: "0px 138px", marginTop: 64 }}>
            <Button style={{ marginRight: 16 }} borderColorRed>
              삭제하기
            </Button>
            <Button active>수정하기</Button>
          </RowWrap>
        </MainWrap>
        <Footer />
      </Container>
    </>
  );
};

export default VideoModify;
