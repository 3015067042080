import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import dayjs from "dayjs";
import { useSetRecoilState } from "recoil";

import {
  Container,
  InfoTitleWrap,
  InfoTitle,
  LinkText,
  InfoWrap,
  InfoListTitle,
  InfoListContent,
  RowWrap,
  SubjectText,
  InfoCarWrap,
  SchoolTypeBox,
  CertifiedBox,
  InfoCardTitle,
  InfoCardContent,
  StateBox,
  ModifyText,
  PopupTitle,
  PopupContent,
  IntroduceBox,
  SubjectBox,
} from "./style";
import Modal from "../../../../components/Modal";
import Button from "../../../../components/Button";
import TextInput from "../../../../components/TextInput";
import FileUploadBox from "../../../../components/FileUploadBox";
import Select from "../../../../components/Select";
import {
  CloseButtonWrap,
  PopupBackground,
  PopupContainer,
} from "../../../../components/Popup";
import { ReactComponent as CloseIcon } from "../../../../assets/icons/cancle_44px.svg";
import formatSchoolType from "../../../../utils/formatSchoolType";
import CategoryTab from "../../../../components/CategoryTab";
import Textarea from "../../../../components/Textarea";
import { API_URL } from "../../../../API";
import formatSubject from "../../../../utils/formatSubject";
import {
  AccountInfoText,
  AccountNotRegisterBox,
  AccountRegisterBox,
  BankNameText,
  EmptyAccountText,
  RegisterAccountText,
} from "../../teacher/tab/style";
import tokenState from "../../../../states/tokenState";
import useWindowSize from "../../../../hooks/useWindowSize";

const ModalContent = styled.div`
  text-align: center;
  color: ${(props) => (props.red ? "#FF383B" : "#212529")};
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  white-space: pre-line;
  display: inline-block;
`;

const BankRegisterModal = ({ onModalClose, onModalFinish }) => {
  const [bank, setBank] = useState(null);
  const [banks, setBanks] = useState([]);
  const [holder, setHolder] = useState("");
  const [accountNumber, setAccountNumber] = useState("");

  const register = () => {
    axios({
      method: "POST",
      url: `${API_URL}/mypage/account`,
      data: {
        bankId: bank.value,
        holder,
        accountNumber,
      },
    }).then((res) => {
      if (res.data.success) {
        onModalFinish();
      }
    });
  };

  useEffect(() => {
    axios({
      method: "GET",
      url: `${API_URL}/mypage/account/banks`,
    }).then((res) => {
      if (res.data.success) {
        setBanks(res.data.result.banks);
      }
    });
  }, []);

  return (
    <PopupBackground>
      <PopupContainer>
        <CloseButtonWrap
          onClick={() => {
            onModalClose();
          }}
        >
          <CloseIcon />
        </CloseButtonWrap>
        <PopupTitle>입금계좌 등록</PopupTitle>
        <div>
          <PopupContent>은행을 선택해주세요.</PopupContent>
        </div>
        <Select
          width={"100%"}
          value={bank}
          placeholder={"은행 선택"}
          option={banks.map((bank) => {
            return {
              value: bank.id,
              label: bank.name,
            };
          })}
          onChange={setBank}
        />
        <PopupContent style={{ marginTop: 32 }}>
          예금주를 입력해주세요.
        </PopupContent>
        <TextInput
          placeholder={"예금주 입력"}
          value={holder}
          onChange={setHolder}
          marginBottom={"0"}
          style={{ width: "100%" }}
        />
        <PopupContent style={{ marginTop: 32 }}>
          계좌를 입력해주세요.
        </PopupContent>
        <TextInput
          placeholder={"계좌 입력"}
          value={accountNumber}
          onChange={setAccountNumber}
          marginBottom={"0"}
          style={{ width: "100%" }}
        />
        <div style={{ padding: "0px 114px", marginTop: 80 }}>
          <Button active style={{ borderRadius: 12 }} onClick={register}>
            등록하기
          </Button>
        </div>
      </PopupContainer>
    </PopupBackground>
  );
};

const UserInfo = ({ data, getData }) => {
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);
  const [finishModal, setFinishModal] = useState(false);
  const [modifyModal, setModifyModal] = useState(false);
  const [registerModal, setRegisterModal] = useState(false);
  const [educationId, setEducationId] = useState(null);
  const [educationText, setEducationText] = useState("");
  const [educationOption, setEducationOption] = useState("");
  const educationOptions = [
    {
      label: "졸업",
      value: "졸업",
    },
    {
      label: "재학",
      value: "재학",
    },
    {
      label: "휴학",
      value: "휴학",
    },
    {
      label: "퇴학",
      value: "퇴학",
    },
  ];
  const [educationCertificate, setEducationCertificate] = useState(null);
  const [careerName, setCareerName] = useState("");
  const [careerStartDate, setCareerStartDate] = useState("");
  const [careerEndDate, setCareerEndDate] = useState("");
  const [careerCertificates, setCareerCertificates] = useState([]);
  const [bankModal, setBankModal] = useState(false);
  const [registerFinishModal, setRegisterFinishMdodal] = useState(false);
  const setToken = useSetRecoilState(tokenState);

  const modifyEducation = async () => {
    if (!educationCertificate || !educationCertificate.length) {
      alert("증명 서류를 첨부해주세요.");
      return;
    }

    const res = await axios({
      method: "POST",
      url: `${API_URL}/mypage/mentopick/education/${educationId}`,
      data: {
        status: educationOption.value,
        certificateUrls: educationCertificate.map(
          (certificate) => certificate.url
        ),
      },
    });

    if (res.data.success) {
      getData();
      alert("수정되었습니다.");
      setModifyModal(false);
    }
  };

  const secession = () => {
    axios({
      method: "DELETE",
      url: `${API_URL}/mypage`,
    }).then((res) => {
      if (res.data.success) {
        setModal(false);
        setFinishModal(true);
        setToken(null);
      }
    });
  };

  const addCareer = async () => {
    if (!careerName) {
      alert("이력명을 입력해주세요.");
      return;
    }

    if (!careerStartDate) {
      alert("시작일자를 입력해주세요.");
      return;
    }

    if (!careerCertificates.length) {
      alert("증명 서류를 첨부해주세요.");
      return;
    }

    const res = await axios({
      method: "POST",
      url: `${API_URL}/mypage/mentopick/career`,
      data: {
        name: careerName,
        startDate: dayjs(careerStartDate).format("YYYY-MM-DD"),
        endDate: careerEndDate
          ? dayjs(careerEndDate).format("YYYY-MM-DD")
          : null,
        certificateUrls: careerCertificates.map(
          (certificate) => certificate.url
        ),
      },
    });

    if (res.data.success) {
      getData();
      alert("등록되었습니다.");
      setRegisterModal(false);
    }
  };

  const removeAccount = (id) => {
    axios({
      method: "DELETE",
      url: `${API_URL}/mypage/account/${id}`,
    }).then((res) => {
      if (res.data.success) {
        alert("삭제되었습니다!");
        getData();
      }
    });
  };

  return (
    <>
      <Container>
        <InfoTitleWrap>
          <InfoTitle>회원정보</InfoTitle>
          <LinkText
            onClick={() => {
              navigate("/mypage/modifyInfo");
            }}
          >
            수정하기
          </LinkText>
        </InfoTitleWrap>
        <InfoWrap>
          <RowWrap>
            <InfoListTitle>휴대폰 번호</InfoListTitle>
            <InfoListContent>{data.userInfo.phoneNumber}</InfoListContent>
          </RowWrap>
          <RowWrap>
            <InfoListTitle>거주지</InfoListTitle>
            <InfoListContent>{data.userInfo.area}</InfoListContent>
          </RowWrap>
          {data.type === "USER" && (
            <RowWrap>
              <InfoListTitle>생년월일</InfoListTitle>
              <InfoListContent>{data.userInfo.birthday}</InfoListContent>
            </RowWrap>
          )}
          <RowWrap style={{ alignItems: "flex-start" }}>
            <InfoListTitle>
              {data.type === "USER" && "희망 과목"}
              {data.type === "TEACHER" && "과외 가능 과목"}
            </InfoListTitle>
            <RowWrap style={{ flexWrap: "wrap" }}>
              {data.userInfo.subjects.map((subject, idx) => (
                <SubjectText key={idx}>{subject}</SubjectText>
              ))}
            </RowWrap>
          </RowWrap>
        </InfoWrap>
        {data.type === "TEACHER" && (
          <>
            <InfoTitle style={{ marginBottom: 20, marginTop: 20 }}>
              학력
            </InfoTitle>
            <RowWrap style={{ flexWrap: "wrap" }}>
              {data.mentopick.educations.map((education) => (
                <InfoCarWrap margin={true} key={education.id}>
                  <RowWrap
                    style={{
                      justifyContent: "space-between",
                      marginBottom: 20,
                    }}
                  >
                    <SchoolTypeBox>
                      {formatSchoolType(education.institute.type)}
                    </SchoolTypeBox>
                    <CertifiedBox>인증완료</CertifiedBox>
                  </RowWrap>
                  <RowWrap style={{ alignItems: "center", marginBottom: 10 }}>
                    <InfoCardTitle>{education.institute.name}</InfoCardTitle>
                    {education.status !== null && (
                      <StateBox>{education.status}</StateBox>
                    )}
                  </RowWrap>
                  <RowWrap style={{ justifyContent: "space-between" }}>
                    {education.undergrad !== null &&
                      education.institute.major !== null && (
                        <InfoCardContent>
                          {education.undergrad} {education.institute.major}
                        </InfoCardContent>
                      )}
                    {education.institute.type === "UNIVERSITY" && (
                      <ModifyText
                        onClick={() => {
                          setEducationId(education.id);
                          setEducationText(
                            `${education.institute.name} ${education.undergrad} ${education.institute.major}`
                          );
                          setEducationOption({
                            label: education.status,
                            value: education.status,
                          });
                          setModifyModal(true);
                        }}
                      >
                        수정
                      </ModifyText>
                    )}
                  </RowWrap>
                </InfoCarWrap>
              ))}
            </RowWrap>
            <InfoTitleWrap style={{ marginTop: 80, marginBottom: 20 }}>
              <InfoTitle>이력</InfoTitle>
              <LinkText
                onClick={() => {
                  setRegisterModal(true);
                  setCareerName("");
                  setCareerStartDate("");
                  setCareerEndDate("");
                  setCareerCertificates([]);
                }}
              >
                등록하기
              </LinkText>
            </InfoTitleWrap>
            <RowWrap style={{ flexWrap: "wrap" }}>
              {data.mentopick.careers.map((career) => (
                <InfoCarWrap margin={true} height={"131px"}>
                  <RowWrap
                    style={{
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: 20,
                    }}
                  >
                    <InfoCardTitle>{career.name}</InfoCardTitle>
                    {career.verified && <CertifiedBox>인증완료</CertifiedBox>}
                  </RowWrap>
                  <InfoCardContent>
                    {career.endDate === null
                      ? dayjs(career.startDate).format("YYYY.MM")
                      : `${dayjs(career.startDate).format("YYYY.MM")} ~ ${dayjs(
                          career.endDate
                        ).format("YYYY.MM")}`}
                  </InfoCardContent>
                </InfoCarWrap>
              ))}
            </RowWrap>
          </>
        )}
        <InfoTitleWrap style={{ marginTop: 80, marginBottom: 20 }}>
          <InfoTitle>입금계좌정보</InfoTitle>
          {data.accounts.length !== 0 && (
            <LinkText
              onClick={() => {
                removeAccount(data.accounts[0].id);
              }}
            >
              삭제하기
            </LinkText>
          )}
        </InfoTitleWrap>
        {data.accounts.length ? (
          data.accounts.map((account) => (
            <AccountRegisterBox key={account.id}>
              <RowWrap style={{ alignItems: "center" }}>
                <div>
                  <BankNameText>{account.bank.name}</BankNameText>
                  <AccountInfoText>
                    {account.holder} {account.accountNumber}
                  </AccountInfoText>
                </div>
              </RowWrap>
            </AccountRegisterBox>
          ))
        ) : (
          <AccountNotRegisterBox>
            <div>
              <EmptyAccountText>
                {data.type === "TEACHER"
                  ? "정산받으실 계좌 정보가 없습니다."
                  : "등록된 계좌정보가 없습니다."}
              </EmptyAccountText>
              <RegisterAccountText
                onClick={() => {
                  setBankModal(true);
                }}
              >
                등록하기
              </RegisterAccountText>
            </div>
          </AccountNotRegisterBox>
        )}
        <InfoTitleWrap style={{ marginTop: 80 }}>
          <InfoTitle />
          <LinkText
            onClick={() => {
              setModal(true);
            }}
          >
            회원탈퇴하기
          </LinkText>
        </InfoTitleWrap>
      </Container>
      {modal && (
        <Modal
          title={"회원탈퇴"}
          button={[
            {
              title: "취소",
              type: "cancel",
            },
            {
              title: "확인",
              type: "active",
            },
          ]}
          onPress={() => {
            secession();
          }}
          onClose={(value) => {
            setModal(value);
          }}
          height="367px"
          htmlContnet={
            <ModalContent>
              {" 회원탈퇴를 할 경우 현재 진행 중인\n수업에 관한 금액 환불은 "}
              <ModalContent red>불가</ModalContent>합니다.
              {"\n그래도 탈퇴하시겠습니까?"}
            </ModalContent>
          }
        />
      )}
      {bankModal && (
        <BankRegisterModal
          onModalClose={() => {
            setBankModal(false);
          }}
          onModalFinish={() => {
            setBankModal(false);
            setRegisterFinishMdodal(true);
            getData();
          }}
        />
      )}
      {registerFinishModal && (
        <Modal
          title={"등록완료"}
          content={"입력한 정보로 등록이 완료되었습니다."}
          button={[
            {
              title: "확인",
              type: "active",
            },
          ]}
          onPress={() => {
            setRegisterFinishMdodal(false);
          }}
          onClose={(value) => {
            setRegisterFinishMdodal(value);
          }}
        />
      )}
      {finishModal && (
        <Modal
          title={"탈퇴완료"}
          content={"탈퇴처리가 완료되었습니다. 감사합니다."}
          button={[
            {
              title: "확인",
              type: "active",
            },
          ]}
          onPress={() => {
            navigate("/");
          }}
          onClose={(value) => {
            setFinishModal(value);
          }}
        />
      )}
      {modifyModal && (
        <PopupBackground>
          <PopupContainer>
            <CloseButtonWrap
              onClick={() => {
                setModifyModal(false);
              }}
            >
              <CloseIcon />
            </CloseButtonWrap>
            <PopupTitle style={{ marginBottom: 16 }}>학력 수정하기</PopupTitle>
            <div>
              <PopupContent>
                학력 수정은 대학교 <PopupContent yellow>재학 상태</PopupContent>
                만 수정 가능합니다.
              </PopupContent>
            </div>
            <InfoCarWrap
              height={"60px"}
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "20px 18px",
                marginBottom: "32px",
              }}
            >
              <InfoCardTitle style={{ fontSize: 16 }}>
                {educationText}
              </InfoCardTitle>
              <CertifiedBox>인증완료</CertifiedBox>
            </InfoCarWrap>
            <PopupContent>재학 상태</PopupContent>
            <Select
              width={"100%"}
              option={educationOptions}
              value={educationOption}
              onChange={(value) => {
                setEducationOption(value);
              }}
            />
            <PopupContent style={{ marginTop: 32, marginBottom: 4 }}>
              증명서류를 첨부해주세요.
            </PopupContent>
            <PopupContent
              style={{ marginBottom: 20, color: "#868E96", fontSize: "12px" }}
            >
              첨부파일은 20MB 이하의 JPG, PNG, PDF 파일로만 업로드 가능합니다.
            </PopupContent>
            <FileUploadBox
              onChange={(value) => {
                setEducationCertificate(value);
              }}
            />
            <div style={{ padding: "0px 114px", marginTop: 32 }}>
              <Button
                active
                style={{ borderRadius: 12 }}
                onClick={modifyEducation}
              >
                신청하기
              </Button>
            </div>
          </PopupContainer>
        </PopupBackground>
      )}
      {registerModal && (
        <PopupBackground>
          <PopupContainer>
            <CloseButtonWrap
              onClick={() => {
                setRegisterModal(false);
              }}
            >
              <CloseIcon />
            </CloseButtonWrap>
            <PopupTitle>이력등록</PopupTitle>
            <div>
              <PopupContent>이력 제목을 입력해주세요.</PopupContent>
            </div>
            <TextInput
              placeholder={"제목 입력"}
              value={careerName}
              onChange={setCareerName}
              marginBottom={"32"}
            />
            <PopupContent style={{ marginBottom: 4 }}>
              이력 기간을 입력해주세요.
            </PopupContent>
            <div>
              <PopupContent
                style={{ marginBottom: 20, color: "#868E96", fontSize: "12px" }}
              >
                수상이력이라면 수상 날짜만 입력해주세요.
              </PopupContent>
            </div>
            <RowWrap style={{ alignItems: "center" }}>
              <TextInput
                placeholder={"YYYYMM"}
                value={careerStartDate}
                onChange={setCareerStartDate}
                marginBottom={"0"}
              />
              <PopupContent style={{ fontSize: 20, margin: "0px 16px" }}>
                ~
              </PopupContent>
              <TextInput
                placeholder={"YYYYMM"}
                value={careerEndDate}
                onChange={setCareerEndDate}
                marginBottom={"0"}
              />
            </RowWrap>
            <PopupContent style={{ marginTop: 32, marginBottom: 4 }}>
              파일을 첨부해주세요.
            </PopupContent>
            <PopupContent
              style={{ marginBottom: 20, color: "#868E96", fontSize: "12px" }}
            >
              첨부파일은 20MB 이하의 JPG, PNG, PDF 파일로만 업로드 가능합니다.
            </PopupContent>
            <FileUploadBox
              onChange={(value) => {
                setCareerCertificates(value);
              }}
            />
            <div style={{ padding: "0px 114px", marginTop: 32 }}>
              <Button active style={{ borderRadius: 12 }} onClick={addCareer}>
                신청하기
              </Button>
            </div>
          </PopupContainer>
        </PopupBackground>
      )}
    </>
  );
};

const Introduce = ({ data, getData }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [introduceModal, setIntroduceModal] = useState(false);
  const [subjectModal, setSubjectModal] = useState(false);
  const [descriptionText, setDescriptionText] = useState(
    data.mentopick.description
  );
  const [subjects, setSubjects] = useState(data.mentopick.subjects);

  const modifyIntroduce = async () => {
    if (!descriptionText) {
      alert("내용을 입력해주세요.");
      return;
    }

    const res = await axios({
      method: "POST",
      url: `${API_URL}/mypage/mentopick/introduce`,
      data: {
        description: descriptionText,
      },
    });

    if (res.data.success) {
      getData();
      alert("수정되었습니다.");
      setIntroduceModal(false);
    }
  };

  const modifySubjects = async () => {
    const res = await axios({
      method: "POST",
      url: `${API_URL}/mypage/mentopick/subjects`,
      data: {
        subjects,
      },
    });

    if (res.data.success) {
      getData();
      alert("수정되었습니다.");
      setSubjectModal(false);
    }
  };

  return (
    <div style={{ marginTop: 60 }}>
      <InfoTitleWrap>
        <InfoTitle>소개하기</InfoTitle>
        <LinkText
          onClick={() => {
            setIntroduceModal(true);
            setDescriptionText(data.mentopick.description);
          }}
        >
          등록하기
        </LinkText>
      </InfoTitleWrap>
      <IntroduceBox>
        {data.mentopick.description || "등록된 소개글이 없습니다."}
      </IntroduceBox>
      <InfoTitleWrap style={{ marginBottom: 20 }}>
        <InfoTitle>과목별 수업방식</InfoTitle>
        <LinkText
          onClick={() => {
            setSubjects(data.mentopick.subjects);
            setSubjectModal(true);
          }}
        >
          등록하기
        </LinkText>
      </InfoTitleWrap>
      {data.mentopick.subjects.length > 0 ? (
        <>
          {data.mentopick.subjects.map((item, idx) => (
            <SubjectBox
              onClick={() => {
                setActiveTab(idx);
              }}
              active={activeTab === idx}
              key={idx}
            >
              {formatSubject(item.subject)}
            </SubjectBox>
          ))}
          <IntroduceBox small>
            {data.mentopick.subjects[activeTab].contents ||
              "등록된 소개글이 없습니다."}
          </IntroduceBox>
        </>
      ) : (
        <IntroduceBox>등록된 과목이 없습니다.</IntroduceBox>
      )}
      {introduceModal && (
        <PopupBackground>
          <PopupContainer width="812px">
            <CloseButtonWrap
              onClick={() => {
                setIntroduceModal(false);
              }}
            >
              <CloseIcon />
            </CloseButtonWrap>
            <PopupTitle>소개하기 등록</PopupTitle>
            <Textarea
              height="200px"
              value={descriptionText}
              onChange={setDescriptionText}
              placeholder="내용 입력"
            />
            <div style={{ padding: "0px 245px", marginTop: 32 }}>
              <Button active onClick={modifyIntroduce}>
                등록하기
              </Button>
            </div>
          </PopupContainer>
        </PopupBackground>
      )}
      {subjectModal && (
        <PopupBackground>
          <PopupContainer width="812px">
            <CloseButtonWrap
              onClick={() => {
                setSubjectModal(false);
              }}
            >
              <CloseIcon />
            </CloseButtonWrap>
            <PopupTitle>수업방식 등록</PopupTitle>
            <div style={{ marginBottom: 16 }}>
              {subjects.map((item, idx) => (
                <SubjectBox
                  onClick={() => {
                    setActiveTab(idx);
                  }}
                  active={activeTab === idx}
                  key={idx}
                >
                  {formatSubject(item.subject)}
                </SubjectBox>
              ))}
            </div>
            <Textarea
              height="200px"
              value={subjects[activeTab].contents}
              onChange={(value) => {
                setSubjects(
                  subjects.map((item, idx) => {
                    if (idx === activeTab) {
                      return {
                        ...item,
                        contents: value,
                      };
                    }

                    return item;
                  })
                );
              }}
              placeholder="내용 입력"
            />
            <div style={{ padding: "0px 245px", marginTop: 32 }}>
              <Button active onClick={modifySubjects}>
                등록하기
              </Button>
            </div>
          </PopupContainer>
        </PopupBackground>
      )}
    </div>
  );
};

const Information = ({ data, getData }) => {
  const { width } = useWindowSize();
  const isMobile = width < 1080;
  console.log(data);

  return (
    <div style={{ marginTop: isMobile ? 24 : 80 }}>
      <CategoryTab
        array={
          data.type === "TEACHER" ? ["회원정보", "소개하기"] : ["회원정보"]
        }
        contents={
          data.type === "TEACHER"
            ? [
                <UserInfo data={data} getData={getData} />,
                <Introduce data={data} getData={getData} />,
              ]
            : [<UserInfo data={data} />]
        }
      />
    </div>
  );
};

export default Information;
