import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import regexifyString from "regexify-string";

const Container = styled.div`
  width: 100%;
  height: ${(props) => (!props.open ? "60px" : "auto")};
  border: 1px solid
    ${(props) =>
      props.focus ? "#ffad2e" : props.value === "" ? "#dde2ea" : "#021633"};
  border-radius: 8px;
  overflow: hidden;
  box-sizing: border-box;
  margin-bottom: ${(props) =>
    props.marginBottom ? props.marginBottom : "80px"};
`;

const InputContainer = styled.div`
  width: 100%;
  height: 60px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 0px 18px;
`;

const Input = styled.input`
  width: 100%;
  height: 100%;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  color: #212529;
  background-color: #ffffff;
  box-sizing: border-box;
  border: none;

  &::placeholder {
    color: #adb5bd;
    font-family: "Noto Sans KR";
  }

  &:focus {
    outline: none;
  }
`;

const SearchIconWrap = styled.div`
  width: 24px;
  height: 24px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='24' height='24' viewBox='0 0 24 24'%3E%3Cdefs%3E%3CclipPath id='clip-path'%3E%3Crect id='사각형_3519' data-name='사각형 3519' width='24' height='24' transform='translate(850 2703)' fill='%23adb5bd'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg id='마스크_그룹_121' data-name='마스크 그룹 121' transform='translate(-850 -2703)' clip-path='url(%23clip-path)'%3E%3Cg id='b' transform='translate(850 2703)'%3E%3Cg id='c'%3E%3Cpath id='패스_463' data-name='패스 463' d='M0,0H24V24H0Z' fill='%23adb5bd' opacity='0' style='isolation: isolate'/%3E%3Cg id='그룹_1017' data-name='그룹 1017'%3E%3Ccircle id='타원_539' data-name='타원 539' cx='6.5' cy='6.5' r='6.5' transform='translate(4.5 4.5)' fill='none' stroke='%23212529' stroke-miterlimit='10' stroke-width='2'/%3E%3Cline id='선_22' data-name='선 22' x2='3.5' y2='3.5' transform='translate(16 16)' fill='none' stroke='%23212529' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
`;

const ListResultWrap = styled.div`
  max-height: 210px;
  overflow-y: scroll;
  border-top: ${(props) => (props.focus ? "1px" : "0px")} solid #ffad2e;
  cursor: pointer;
`;

const ListWrap = styled.div`
  padding: 18px 20px;
  background-color: ${(props) =>
    props.isSelected ? "rgba(255,173,46,0.1)" : "#ffffff"};
  border-bottom: ${(props) =>
    props.isSelected ? "1px solid #FFAD2E" : "1px solid #E9ECEF"};
`;

const ItemTitle = styled.div`
  font-size: 16px;
  color: #212529;
  line-height: 32px;
  display: inline-block;
  font-weight: 500;
`;

const BoldTitle = styled.div`
  font-size: 16px;
  color: #ffad2e;
  line-height: 32px;
  display: inline-block;
  font-weight: 500;
`;

const SearchBar = ({
  placeholder,
  onChange,
  value,
  marginBottom,
  maxLength,
  style,
  list,
  onClick,
}) => {
  const [focus, setFocus] = useState(false);
  const [open, setOpen] = useState(false);

  const SearchResult = () => {
    if (list && list.length > 0) {
      return list.map((item, idx) => {
        const title = value
          ? item.title.replace(value, `{${value}}`)
          : item.title.replace(/(\d{3})(\d{2})(\d{5})/, "$1-$2-$3");

        const titleResult = regexifyString({
          pattern: /{.*}/gim,
          decorator: (match, index) => {
            const textToDisplay = match.slice(1, -1);
            return <BoldTitle key={index}>{textToDisplay}</BoldTitle>;
          },
          input: title,
        });
        return { titleResult, item };
      });
    }
    return null;
  };

  return (
    <Container
      focus={focus}
      open={open}
      style={style}
      marginBottom={marginBottom}
      value={value}
    >
      <InputContainer>
        <Input
          placeholder={placeholder}
          value={value}
          onChange={(e) => {
            setFocus(true);
            setOpen(true);
            onChange(e.target.value);
          }}
          onBlur={(e) => {
            setFocus(false);
          }}
          maxLength={maxLength}
        />
        <SearchIconWrap />
      </InputContainer>
      {list && open && SearchResult() !== null && (
        <ListResultWrap focus={focus}>
          {SearchResult().map((item, idx) => {
            if (item.titleResult.length > 1) {
              return (
                <ListWrap
                  key={idx}
                  onClick={(e) => {
                    onClick(item.item);
                    setOpen(false);
                  }}
                >
                  <ItemTitle>{item.titleResult}</ItemTitle>
                </ListWrap>
              );
            }
          })}
        </ListResultWrap>
      )}
    </Container>
  );
};

export default SearchBar;
