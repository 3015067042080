import React, {useEffect, useState} from "react";
import styled from "styled-components";

const Main = styled("div")`
  font-family: sans-serif;
`;

const DropDownContainer = styled("div")`
  width: 100%;
  margin: 0.6rem auto;
`;

const DropDownHeader = styled("div")`
  margin-bottom: 0.1em;
  padding: 0.4em 2em 0.4em 1em;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
  border-radius: 0.3rem;
  color: #000000;
  background: #dde2ea;
  position: relative;
  height: 2.6rem;
  font-size: 0.8rem;
  width: 105%;

  @media (max-width: 721px) {
    height: 2rem;
  }
`;

const DropDownListContainer = styled("div")`
  position: absolute;
  z-index: 999;
  width: 41.7%;
  @media (max-width: 721px) {
    width: 28.5%;
  }
`;

const DropDownList = styled("ul")`
  height: fit-content;
  padding: 0.3rem 0;
  margin: 0;
  background: #dde2ea;
  border: 0.7px solid gray;
  border-radius: 0.3rem;
  color: #000000;
  box-sizing: border-box;
  overflow-y: auto;
`;

const ListItem = styled("li")`
  padding: 0.4em 1em;
  font-size: small;
  list-style: none;

  :active {
    outline: none;
    opacity: 0.1;
  }
`;

export default function DropDown(props) {
  const options = props.list;
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  const toggling = () => setIsOpen(!isOpen);

  const onOptionClicked = (value) => () => {
    setSelectedOption(value);
    setIsOpen(false);
    props.handleClick(value);
  };

  return (
    <DropDownContainer
      tabIndex={0}
      onBlur={() => {
        setIsOpen(false)
      }}
    >
      <DropDownHeader
        onClick={toggling}
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingRight: "0.5rem",
          cursor: "pointer",
        }}
      >
        <span>{selectedOption || "선택"}</span>
        <span style={{fontSize: "10px"}}>▾</span>
      </DropDownHeader>
      {isOpen && (
        <DropDownListContainer>
          <DropDownList>
            {options.map((option) => (
              <ListItem onClick={onOptionClicked(option)} key={Math.random()}>
                {option}
              </ListItem>
            ))}
          </DropDownList>
        </DropDownListContainer>
      )}
    </DropDownContainer>
  );
}
