import React, {useLayoutEffect, useEffect, useRef, useState} from "react";
import styled from "styled-components";
import "./style.scss"

import mainLogo from "../images/mainLogo.png"
import mainTitleLogo from "../images/mainTitleLogo.png"
import {Desktop, Mobile} from "../../../utils/mediaQuery";

const HeaderContainer = styled.div`
  margin: 0 0 0 0;
  padding-left: 4vw;
  height: 4vh;
  //min-height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const MainLogo = styled.button`
  margin: 0 0 0 0;
  background: none;
  border: none;
  animation: fadeInUp 0.3s;
  @keyframes fadeInUp {
    0% {
      opacity: 0;
      transform: translate3d(0, 100%, 0);
    }
    to {
      opacity: 1;
      transform: translateZ(0);
    }
  }
`;

const MainLogoSmall = styled.button`
  margin: 0 0 0 0;
  background: none;
  border: none;
  animation: fadeIn 1s;
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

const MenuItemContainer = styled.div`
  margin: 0 0 0 0;
  padding: 0 4vw 0 0;
  height: 100%;
  //min-height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const MenuItem = styled.button`
  margin: 0 0 0 0;
  padding-left: 3vw;
  //min-height: 100vh;
  background: none;
  border: none;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: flex-start;
  text-align: center;
  font-size: 1rem;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  word-wrap: break-word;
  letter-spacing: 0.1rem;
  color: #FFFFFF;
`;

const MenuItemContainerMobile = styled.div`
  margin: 0 0 0 0;
  padding: 0 0 0 0;
  height: 100%;
  //min-height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const MenuItemMobile = styled.button`
  margin: 0 0 0 0;
  padding-left: 3vw;
  width: 14.5vw;
  //min-height: 100vh;
  background: none;
  border: none;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: flex-start;
  text-align: center;
  font-size: 0.6rem;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  word-wrap: break-word;
  letter-spacing: 0.1rem;
  color: #FFFFFF;
  white-space: nowrap; /* 텍스트를 한 줄에 표시합니다. */
  overflow: hidden; /* 넘치는 부분은 숨깁니다. */
  text-overflow: ellipsis; /* 넘치는 부분에 "..."을 추가합니다. */
`;

const handleClickScroll = (index) => {
  let element = document.getElementById(`desktop_image${index}`);

  if (element) {
    // 👇 Will scroll smoothly to the top of the next section
    element.scrollIntoView({behavior: 'smooth'});
  }
};

export default function Header(params) {
  const ref = useRef();
  let [check, setCheck] = useState(true);
  const sticky = useStickyHeader(50);
  const headerClasses = `header ${(sticky && check) ? 'sticky' : ''}`
  const headerClassesMobile = `header_mobile ${(sticky && check) ? 'sticky_mobile' : ''}`
  useRef()

  return (
    <>
      <Desktop>
        <header ref={ref} className={headerClasses}>
          <HeaderContainer>
            {!sticky && <MainLogo onClick={() => handleClickScroll(0)}>
              <img
                style={{
                  width: "10rem",
                  margin: 0,
                  padding: 0,
                }}
                src={mainLogo}
                id='main_logo'
                alt="logo"
              />
            </MainLogo>}
            {sticky && <MainLogoSmall onClick={() => handleClickScroll(0)}>
              <img
                style={{
                  width: "6rem",
                  margin: 0,
                  padding: 0,
                }}
                src={mainLogo}
                id='main_logo'
                alt="logo"
              />
            </MainLogoSmall>}
            <MenuItemContainer>
              <MenuItem onClick={() => handleClickScroll(1)}>ABOUT</MenuItem>
              <MenuItem onClick={() => handleClickScroll(2)}>MENTORING</MenuItem>
              <MenuItem onClick={() => handleClickScroll(3)}>SCHEDULE</MenuItem>
              <MenuItem onClick={() => handleClickScroll(4)}>AWARDS</MenuItem>
              <MenuItem onClick={() => handleClickScroll(5)}>APPLICATION</MenuItem>
              <MenuItem onClick={() => handleClickScroll(6)}>PARTNERSHIP</MenuItem>
            </MenuItemContainer>
          </HeaderContainer>
        </header>
      </Desktop>
      <Mobile>
        <header ref={ref} className={headerClassesMobile}>
          <HeaderContainer>
            {/*{!sticky && <MainLogo onClick={() => handleClickScroll(0)}>*/}
            {/*  <img*/}
            {/*    style={{*/}
            {/*      width: "3rem",*/}
            {/*      margin: 0,*/}
            {/*      padding: 0,*/}
            {/*    }}*/}
            {/*    src={mainTitleLogo}*/}
            {/*    id='main_logo'*/}
            {/*    alt="logo"*/}
            {/*  />*/}
            {/*</MainLogo>}*/}
            {/*{sticky && <MainLogoSmall onClick={() => handleClickScroll(0)}>*/}
            {/*  <img*/}
            {/*    style={{*/}
            {/*      width: "1.5rem",*/}
            {/*      margin: 0,*/}
            {/*      padding: 0,*/}
            {/*    }}*/}
            {/*    src={mainTitleLogo}*/}
            {/*    id='main_logo'*/}
            {/*    alt="logo"*/}
            {/*  />*/}
            {/*</MainLogoSmall>}*/}
            <MenuItemContainerMobile>
              <MenuItemMobile onClick={() => handleClickScroll(1)}>ABOUT</MenuItemMobile>
              <MenuItemMobile onClick={() => handleClickScroll(2)}>MENTORING</MenuItemMobile>
              <MenuItemMobile onClick={() => handleClickScroll(3)}>SCHEDULE</MenuItemMobile>
              <MenuItemMobile onClick={() => handleClickScroll(4)}>AWARDS</MenuItemMobile>
              <MenuItemMobile onClick={() => handleClickScroll(5)}>APPLICATION</MenuItemMobile>
              <MenuItemMobile onClick={() => handleClickScroll(6)}>PARTNERSHIP</MenuItemMobile>
            </MenuItemContainerMobile>
          </HeaderContainer>
        </header>
      </Mobile>
    </>


  );
}

function useStickyHeader(offset = 0) {
  const [stick, setStick] = useState(false);

  const handleScroll = () => {
    setStick(window.scrollY > offset);
  };

  useLayoutEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return (() => {
      window.removeEventListener('scroll', handleScroll);
    });
  });

  return stick;
}
