import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import updateLocale from "dayjs/plugin/updateLocale";

import StarBig from "../../../assets/icons/star_46px.svg";
import StarSmall from "../../../assets/icons/star_24px.svg";
import Pagination from "../../../components/Pagination";

import { API_URL } from "../../../API/index";
import { useParams } from "react-router-dom";
import { Desktop, Mobile } from "../../../utils/mediaQuery";
import { InView } from "react-intersection-observer";

const Container = styled.div`
  padding-top: 20px;
  width: 100%;
`;

const ReputationBlock = styled.div`
  width: 100%;
  height: 184px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f6f9ff;
  border-radius: 12px;
  margin-bottom: 80px;
`;
const ContentsTitle = styled.h4`
  font-size: 20px;
  font-weight: ${(props) => props.theme.fontWeight.medium};
  color: ${(props) => props.theme.color.primary};
`;
const ReputationWrap = styled.div`
  display: flex;
  align-items: center;
  margin-top: 12px;
`;
const ReputationTextWrap = styled.div`
  display: flex;
  align-items: flex-end;
`;
const ReputationText = styled.span`
  font-size: 32px;
  font-weight: ${(props) => props.theme.fontWeight.medium};
  color: ${(props) => props.theme.color.primary};
`;
const ReputationStar = styled.img.attrs({
  src: `${StarBig}`,
})``;

const ReviewBlock = styled.div`
  width: 100%;
  height: 307px;
  padding: 32px;
  border: 1px solid #dde2ea;
  border-radius: 12px;
  margin-bottom: 32px;
`;
const ReviewProfileWrap = styled.div`
  display: flex;
  align-items: center;
`;
const ReviewProfileIconWrap = styled.div`
  width: 60px;
  height: 60px;
  overflow: hidden;
  border-radius: 60px;
`;
const ReviewProfileIcon = styled.img`
  width: 60px;
  height: 60px;
`;
const ReviewProfileNameWrap = styled.div`
  margin-left: 16px;
  display: flex;
  flex-direction: column;
`;
const ReviewProfileName = styled.span`
  font-size: 20px;
  font-weight: ${(props) => props.theme.fontWeight.medium};
  color: ${(props) => props.theme.color.textColor};
  line-height: 29px;
  margin-bottom: 4px;
`;
const ReviewProfileDate = styled.span`
  font-size: 16px;
  font-weight: ${(props) => props.theme.fontWeight.medium};
  color: #adb5bd;
`;
const ReviewDescWrap = styled.div`
  padding-left: 76px;
  margin-top: 32px;
`;
const ReviewTitleWrap = styled.div`
  font-size: 16px;
  font-weight: ${(props) => props.theme.fontWeight.medium};
  color: ${(props) => props.theme.color.primary};
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;
const ReviewStar = styled.img.attrs({
  src: `${StarSmall}`,
})`
  margin-right: 4px;
`;
const ReviewDesc = styled.p`
  font-size: 16px;
  font-weight: ${(props) => props.theme.fontWeight.regular};
  color: ${(props) => props.theme.color.textColor};
  line-height: 28px;
  overflow: hidden;
  height: 108px;
  word-break: keep-all;
`;
const NoneDataWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const NoneData = styled.span`
  font-size: 20px;
  font-weight: ${(props) => props.theme.fontWeight.medium};
  color: #adb5bd;
  margin: 0 auto;
  text-align: center;
`;

/***********모바일스타일************* */

const MobileContainer = styled.div`
  display: block !important;
  padding-bottom: 120px;
`;
const MobileTotalReputationBox = styled.div`
  width: 342px;
  height: 88px;
  background: #f6f9ff;
  border-radius: 4px;
  padding: 16px;
  h2 {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #212529;
    > figure {
      display: flex;
      margin-top: 10px;
      align-items: center;
      height: 27px;
      > p {
        font-weight: 700;
        font-size: 18px;
        line-height: 26px;
        span {
          font-weight: 500;
          font-size: 14px;
        }
      }
      > img {
        margin-right: 4px;
      }
    }
  }
`;

const MobileReputationList = styled.ul`
  margin-top: 24px;
  > div {
    padding: 32px 0px;
    box-sizing: border-box;
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
    text-align: center;
    width: 100% !important;
    color: #868e96;
  }
  > li {
    margin-bottom: 21px;
    width: 342px;
    padding: 16px;
    box-sizing: border-box;
    background: #ffffff;
    border: 1px solid #dde2ea;
    > figure {
      :nth-of-type(1) {
        display: flex;
        div {
          :first-of-type {
            width: 44px;
            height: 44px;
            border-radius: 50%;
            background-color: black;
            margin-right: 12px;
          }
          :last-of-type {
            width: max-content;
            > h3 {
              font-weight: 700;
              font-size: 16px;
              line-height: 23px;
              color: #212529;
            }
            > p {
              font-weight: 400;
              font-size: 12px;
              line-height: 17px;
              color: #868e96;
            }
          }
        }
      }
      :nth-of-type(2) {
        display: flex;
        margin-top: 17px;
        > p {
          font-weight: 700;
          font-size: 18px;
          line-height: 26px;
          color: #212529;
          margin-left: 4px;
        }
      }
    }
    > p {
      font-weight: 500;
      font-size: 16px;
      line-height: 23px;
      color: #212529;
      margin-top: 8px;
      white-space: pre-line;
    }
  }
`;

/********************************* */

const Reputation = () => {
  const { profileId } = useParams();
  const [data, setData] = useState(null);
  const [showPage, setShowPage] = useState(1);

  useEffect(() => {
    axios({
      url: `${API_URL}/mentopick/${profileId}/score?take=5&page=${showPage}`,
      method: "GET",
    })
      .then((result) => {
        setData(result.data.result);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [showPage]);

  dayjs.extend(updateLocale);
  dayjs.extend(relativeTime);
  dayjs.updateLocale("en", {
    relativeTime: {
      future: "in %s",
      past: "%s 전",
      s: "1초",
      m: "1분",
      mm: "%d분",
      h: "1시간",
      hh: "%d시간",
      d: "1일",
      dd: "%d일",
      M: "1달",
      MM: "%d개월",
      y: "1년",
      yy: "%d년",
    },
  });

  const dummyData = {
    name: "string",
    score: 8.5,
    totalPage: 1,
    reviews: [
      {
        createdAt: "2022-10-02T03:39:41.289Z",
        score: 8.4,
        contents: `Lorem ipsum dolor sit amet, consectetur 
                    adipiscing elit, sed do eiusmod tempor 
                    incididunt ut labore et dolore magna 
                    aliqua. Ut`,
        writer: {
          profileImage: "",
          nickname: "알바트로스",
        },
      },
      {
        createdAt: "2022-10-02T03:39:41.289Z",
        score: 8.4,
        contents: `Lorem ipsum dolor sit amet, consectetur 
                    adipiscing elit, sed do eiusmod tempor 
                    incididunt ut labore et dolore magna 
                    aliqua. Ut`,
        writer: {
          profileImage: "",
          nickname: "알바트로스",
        },
      },
      {
        createdAt: "2022-10-02T03:39:41.289Z",
        score: 8.4,
        contents: `Lorem ipsum dolor sit amet, consectetur 
                    adipiscing elit, sed do eiusmod tempor 
                    incididunt ut labore et dolore magna 
                    aliqua. Ut`,
        writer: {
          profileImage: "",
          nickname: "알바트로스",
        },
      },
      {
        createdAt: "2022-10-02T03:39:41.289Z",
        score: 8.4,
        contents: `Lorem ipsum dolor sit amet, consectetur 
                    adipiscing elit, sed do eiusmod tempor 
                    incididunt ut labore et dolore magna 
                    aliqua. Ut`,
        writer: {
          profileImage: "",
          nickname: "알바트로스",
        },
      },
      {
        createdAt: "2022-10-02T03:39:41.289Z",
        score: 8.4,
        contents: `Lorem ipsum dolor sit amet, consectetur 
                    adipiscing elit, sed do eiusmod tempor 
                    incididunt ut labore et dolore magna 
                    aliqua. Ut`,
        writer: {
          profileImage: "",
          nickname: "알바트로스",
        },
      },
      {
        createdAt: "2022-10-02T03:39:41.289Z",
        score: 8.4,
        contents: `Lorem ipsum dolor sit amet, consectetur 
                    adipiscing elit, sed do eiusmod tempor 
                    incididunt ut labore et dolore magna 
                    aliqua. Ut`,
        writer: {
          profileImage: "",
          nickname: "알바트로스",
        },
      },
      {
        createdAt: "2022-10-02T03:39:41.289Z",
        score: 8.4,
        contents: `Lorem ipsum dolor sit amet, consectetur 
                    adipiscing elit, sed do eiusmod tempor 
                    incididunt ut labore et dolore magna 
                    aliqua. Ut`,
        writer: {
          profileImage: "",
          nickname: "알바트로스",
        },
      },
      {
        createdAt: "2022-10-02T03:39:41.289Z",
        score: 8.4,
        contents: `Lorem ipsum dolor sit amet, consectetur 
                    adipiscing elit, sed do eiusmod tempor 
                    incididunt ut labore et dolore magna 
                    aliqua. Ut`,
        writer: {
          profileImage: "",
          nickname: "알바트로스",
        },
      },
    ],
  };

  useEffect(() => {
    setShowPage(1);
  }, []);

  return (
    <>
      <Desktop>
        <Container>
          <ReputationBlock>
            <ContentsTitle>
              {data !== null && data.name} 선생님의 평판 점수는
            </ContentsTitle>
            <ReputationWrap>
              <ReputationStar />
              <ReputationTextWrap>
                <ReputationText>{data !== null && data.score}점</ReputationText>
                <ContentsTitle>입니다.</ContentsTitle>
              </ReputationTextWrap>
            </ReputationWrap>
          </ReputationBlock>
          {data !== null &&
            (data.reviews.length < 1 ? (
              <NoneDataWrap>
                <NoneData>등록된 후기가 없습니다.</NoneData>
              </NoneDataWrap>
            ) : (
              data.reviews.map((item) => (
                <ReviewBlock key={item.id}>
                  <ReviewProfileWrap>
                    <ReviewProfileIconWrap>
                      <ReviewProfileIcon
                        src={
                          item.writer.profileImage
                            ? item.writer.profileImage
                            : "/images/default_profile.png"
                        }
                      />
                    </ReviewProfileIconWrap>
                    <ReviewProfileNameWrap>
                      <ReviewProfileName>
                        {item.writer.nickname}
                      </ReviewProfileName>
                      <ReviewProfileDate>
                        {dayjs(item.createdAt).fromNow()}
                      </ReviewProfileDate>
                    </ReviewProfileNameWrap>
                  </ReviewProfileWrap>

                  <ReviewDescWrap>
                    <ReviewTitleWrap>
                      <ReviewStar />
                      {item.score}
                    </ReviewTitleWrap>

                    <ReviewDesc>{item.contents}</ReviewDesc>
                  </ReviewDescWrap>
                </ReviewBlock>
              ))
            ))}
          {data !== null &&
            (data.reviews.length > 0 ? (
              <Pagination
                totalItemsCount={data !== null ? data.totalPage * 5 : 0}
                showItemsCount={5}
                page={showPage}
                nowPage={(page) => {
                  window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: "smooth",
                  });
                  setShowPage(page);
                }}
              />
            ) : null)}
        </Container>
      </Desktop>
      <Mobile>
        <MobileContainer>
          <MobileTotalReputationBox>
            <h2>
              {dummyData.name} 선생님의 평판은
              <br />
              <figure>
                <ReputationStar style={{ width: 24 }} />
                <p>
                  {dummyData.score}점<span> 입니다</span>
                </p>
              </figure>
            </h2>
          </MobileTotalReputationBox>
          <MobileReputationList>
            {dummyData !== null && dummyData.reviews.length < 1 ? (
              <div>등록된 후기가 없습니다.</div>
            ) : (
              dummyData !== null &&
              dummyData.reviews?.map((item, id) => (
                //스크롤 이벤트 걸어서 페이지 수 증가시키기
                <InView
                  triggerOnce
                  threshold={0.1}
                  onChange={(inview, entry) => {
                    if (inview && id === dummyData.length - 5) {
                      if (showPage !== dummyData.totalPage)
                        setShowPage((prev) => prev + 1);
                    }
                  }}
                  as={"li"}
                >
                  <figure>
                    <div>
                      <img />
                    </div>
                    <div>
                      <h3>{item.writer.nickname}</h3>
                      <p> {dayjs(item.createdAt).fromNow()}</p>
                    </div>
                  </figure>
                  <figure>
                    <ReputationStar style={{ width: 24 }} />
                    <p>{item.score}점</p>
                  </figure>
                  <p>{item.contents}</p>
                </InView>
              ))
            )}
          </MobileReputationList>
        </MobileContainer>
      </Mobile>
    </>
  );
};

export default Reputation;
