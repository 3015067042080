import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useRecoilValue } from "recoil";

import { API_URL } from "../../../API";
import TextInput from "../../../components/TextInput";
import Textarea from "../../../components/Textarea";
import Button from "../../../components/Button";
import RadioBox from "../../../components/RadioBox";
import Modal from "../../../components/Modal";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import userInfoState from "../../../states/userInfoState";
import runBootPay from "../../../components/BootPay";

const Container = styled.div`
  width: 100%;
  padding: 200px 254px 260px 254px;
`;

const PageTitleWrap = styled.div`
  border-bottom: 2px solid #e9ecef;
  padding-bottom: 31px;
  margin-bottom: 80px;
`;

const PageTitle = styled.h2`
  font-size: 40px;
  font-weight: ${(props) => props.theme.fontWeight.bold};
  color: ${(props) => props.theme.color.textColor};
  margin-bottom: 11px;
  height: 59px;
`;

const PageSubTitle = styled.span`
  font-size: 24px;
  font-weight: ${(props) => props.theme.fontWeight.regular};
  color: ${(props) =>
    props.orange ? props.theme.color.orange : props.theme.color.textColor};
`;

const ContentsWrap = styled.div`
  margin-bottom: ${(props) => (props.marginBottom ? "60px" : "80px")};
  display: flex;
  flex-direction: column;
`;

const Title = styled.span`
  width: fit-content;
  font-size: 28px;
  font-weight: ${(props) => props.theme.fontWeight.bold};
  line-height: 50px;
  border-bottom: 2px solid ${(props) => props.theme.color.textColor};
  padding-bottom: 7px;
  margin-bottom: 32px;
`;

const ContentsText = styled.span`
  font-size: 20px;
  font-weight: ${(props) => props.theme.fontWeight.medium};
  color: ${(props) => props.theme.color.textColor};
  margin-bottom: ${(props) =>
    props.marginBottom ? props.marginBottom + "px" : null};
  height: 29px;
`;

const ContentsSubText = styled.span`
  display: inline-block;
  font-size: 16px;
  font-weight: ${(props) => props.theme.fontWeight.medium};
  min-height: 24px;
  color: #868e96;
  margin-bottom: ${(props) =>
    props.marginBottom ? props.marginBottom + "px" : null};
`;

const StudentInputWrap = styled.div`
  display: flex;
`;

const ButtonWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Divide = styled.div`
  width: 100%;
  height: 2px;
  background-color: #e9ecef;
  margin-top: 12px;
  margin-bottom: 32px;
`;

const EtcWrap = styled.div`
  width: 100%;
`;

const TextIcon = styled.div`
  width: 16px;
  height: 16px;
  background-color: #ffffff;
  border: 4px solid ${(props) => props.theme.color.primary};
  border-radius: 16px;
  margin-bottom: 1px;
  margin-right: 8px;
`;

const ContentsTextWrap = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${(props) =>
    props.marginBottom ? props.marginBottom + "px" : "20px"};
`;

const BtnWrap = styled.div`
  display: flex;
  justify-content: center;
`;

const grades = [
  {
    label: "초등",
    name: "ELEMENTARY",
    value: "ELEMENTARY",
    id: "ELEMENTARY",
  },
  {
    label: "중등",
    name: "MIDDLE_SCHOOL",
    value: "MIDDLE_SCHOOL",
    id: "MIDDLE_SCHOOL",
  },
  {
    label: "고등",
    name: "HIGH_SCHOOL",
    value: "HIGH_SCHOOL",
    id: "HIGH_SCHOOL",
  }
];

const elementaryGrades = [
  {
    label: '4-1',
    name: 'ELEMENTARY_1',
    value: 'ELEMENTARY_1',
    id: 'ELEMENTARY_1'
  },
  {
    label: '4-2',
    name: 'ELEMENTARY_2',
    value: 'ELEMENTARY_2',
    id: 'ELEMENTARY_2'
  },
  {
    label: '5-1',
    name: 'ELEMENTARY_3',
    value: 'ELEMENTARY_3',
    id: 'ELEMENTARY_3'
  },
  {
    label: '5-2',
    name: 'ELEMENTARY_4',
    value: 'ELEMENTARY_4',
    id: 'ELEMENTARY_4'
  },
  {
    label: '6-1',
    name: 'ELEMENTARY_5',
    value: 'ELEMENTARY_5',
    id: 'ELEMENTARY_5'
  },
  {
    label: '6-2',
    name: 'ELEMENTARY_6',
    value: 'ELEMENTARY_6',
    id: 'ELEMENTARY_6'
  },
];

const middleGrades = [
  {
    label: '1-1',
    name: 'MIDDLE_1',
    value: 'MIDDLE_1',
    id: 'MIDDLE_1'
  },
  {
    label: '1-2',
    name: 'MIDDLE_2',
    value: 'MIDDLE_2',
    id: 'MIDDLE_2'
  },
  {
    label: '2-1',
    name: 'MIDDLE_3',
    value: 'MIDDLE_3',
    id: 'MIDDLE_3'
  },
  {
    label: '2-2',
    name: 'MIDDLE_4',
    value: 'MIDDLE_4',
    id: 'MIDDLE_4'
  },
  {
    label: '3-1',
    name: 'MIDDLE_5',
    value: 'MIDDLE_5',
    id: 'MIDDLE_5'
  },
  {
    label: '3-2',
    name: 'MIDDLE_6',
    value: 'MIDDLE_6',
    id: 'MIDDLE_6'
  },
];

const highGrades = [
  {
    label: '수학 상',
    name: 'HIGH_1',
    value: 'HIGH_1',
    id: 'HIGH_1'
  },
  {
    label: '수학 하',
    name: 'HIGH_2',
    value: 'HIGH_2',
    id: 'HIGH_2'
  },
  {
    label: '수학1',
    name: 'HIGH_3',
    value: 'HIGH_3',
    id: 'HIGH_3'
  },
  {
    label: '수학2',
    name: 'HIGH_4',
    value: 'HIGH_4',
    id: 'HIGH_4'
  },
  {
    label: '확통',
    name: 'HIGH_5',
    value: 'HIGH_5',
    id: 'HIGH_5'
  },
  {
    label: '미적분',
    name: 'HIGH_6',
    value: 'HIGH_6',
    id: 'HIGH_6'
  },
];

const careerHopes = [
  {
    label: "유학 (초, 중, 예중)",
    name: "OVERSEA",
    value: "OVERSEA",
    id: "OVERSEA",
  },
  {
    label: "국제중",
    name: "INTL_MIDDLE",
    value: "INTL_MIDDLE",
    id: "INTL_MIDDLE",
  },
  {
    label: "영재 / 과고",
    name: "SCIENCE_HIGH_SCHOOL",
    value: "SCIENCE_HIGH_SCHOOL",
    id: "SCIENCE_HIGH_SCHOOL",
  },
  {
    label: "자사 / 과중",
    name: "SCIENCE_MIDDLE_SCHOOL",
    value: "SCIENCE_MIDDLE_SCHOOL",
    id: "SCIENCE_MIDDLE_SCHOOL",
  },
  {
    label: "자사 / 의대",
    name: "MEDICAL",
    value: "MEDICAL",
    id: "MEDICAL",
  },
  {
    label: "기타",
    name: "ETC",
    value: "ETC",
    id: "ETC",
  }
]

const CareerSearch = () => {
  const navigate = useNavigate();
  const [schoolName, setSchoolName] = useState("");
  const [school, setSchool] = useState("");
  const [grade, setGrade] = useState("");
  const [gradeDetail, setGradeDetail] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [learningHistory1, setLearningHistory1] = useState("");
  const [learningHistory2, setLearningHistory2] = useState("");
  const [activeHistory, setActiveHistory] = useState("");
  const [disalbedBtn, setDisabledBtn] = useState(false);
  const [visibleEtcInput, setVisibleEtcInput] = useState(false);
  const [etcText, setEtcText] = useState("");
  const [etcComplete, setEtcComplete] = useState(true);
  const [levels, setLevels] = useState("");
  const [receiptId, setReceiptId] = useState('');
  const userInfo = useRecoilValue(userInfoState);

  useEffect(() => {
    if (
      school &&
      schoolName &&
      grade &&
      levels &&
      learningHistory1 &&
      learningHistory2 &&
      activeHistory &&
      gradeDetail
    ) {
      setDisabledBtn(true);
    } else {
      setDisabledBtn(false);
    }
  }, [
    school,
    schoolName,
    grade,
    levels,
    learningHistory1,
    learningHistory2,
    activeHistory,
    gradeDetail
  ]);

  const careerExploration = () => {
    if (userInfo.isPaid) {
      axios({
        url: `${API_URL}/careerExplore`,
        method: "POST",
        data: {
          school: schoolName,
          careerHope: school,
          grade: grade,
          status: gradeDetail,
          mathDescription: learningHistory1,
          scienceDescription: learningHistory2,
          description: activeHistory
        },
      })
        .then((result) => {
          if (result.data.success) {
            setOpenModal(true);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      runBootPay(
        10000,
        "영재멘토 진로탐색",
        "영재멘토 진로탐색",
        userInfo.nickname,
        userInfo.email,
        "",
        userInfo.phoneNumber,
        () => {},
        (receiptId) => {
          axios({
            url: `${API_URL}/careerExplore`,
            method: "POST",
            data: {
              school: schoolName,
              careerHope: school,
              grade: grade,
              status: gradeDetail,
              mathDescription: learningHistory1,
              scienceDescription: learningHistory2,
              description: activeHistory,
              receiptId
            },
          })
            .then((result) => {
              if (result.data.success) {
                setOpenModal(true);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      );
    }
  };

  useEffect(() => {
    if (school === 'ETC') {
      setVisibleEtcInput(true);
      setEtcComplete(false);

      if (etcText.length > 0) {
        setEtcComplete(true);
      } else {
        setEtcComplete(false);
      }
    }
  }, [school, etcText]);

  return (
    <div>
      <Header />
      <Container>
        <PageTitleWrap>
          <PageTitle>학습로드맵 컨설팅</PageTitle>
          <PageSubTitle>
            알바 에듀의 <PageSubTitle orange>진로탐색</PageSubTitle>을 통해서
            여러분의 진로를 알아보세요!
          </PageSubTitle>
        </PageTitleWrap>

        <ContentsWrap>
          <Title>학생정보입력</Title>
          <ContentsText marginBottom={20}>
            학생정보를 입력해주세요.
          </ContentsText>
          <StudentInputWrap>
            <TextInput
              placeholder={"학교 입력"}
              value={schoolName}
              onChange={(value) => {
                setSchoolName(value);
              }}
              style={{
                width: "398px",
                marginRight: "8px",
              }}
            />
            <TextInput
              type="number"
              focusUnit={"학년"}
              placeholder={"학년 입력"}
              value={grade}
              onChange={(value) => {
                console.log(value.slice(-1));
                if (value.length < 2) {
                  setGrade(value.replace(/[^0-6]/g, ""));
                }
              }}
              style={{ width: "398px", marginLeft: "8px" }}
            />
          </StudentInputWrap>
        </ContentsWrap>

        <ContentsWrap
          marginBottom
        >
          <Title>학습진도</Title>
          <ContentsText marginBottom={32}>
            현재 학습진도상황을 선택해주세요.
          </ContentsText>
          <RadioBox
            option={grades}
            showLine={3}
            onChange={(value) => {
              setLevels(value);
            }}
          />
          <Divide />
          {
            levels === 'ELEMENTARY' && (
              <RadioBox
                option={elementaryGrades}
                showLine={3}
                onChange={(value) => {
                  setGradeDetail(value);
                }}
              />
            )
          }
          {
            levels === 'MIDDLE_SCHOOL' && (
              <RadioBox
                option={middleGrades}
                showLine={3}
                onChange={(value) => {
                  setGradeDetail(value);
                }}
              />
            )
          }
          {
            levels === 'HIGH_SCHOOL' && (
              <RadioBox
                option={highGrades}
                showLine={3}
                onChange={(value) => {
                  setGradeDetail(value);
                }}
              />
            )
          }
        </ContentsWrap>

        <ContentsWrap>
          <Title>진로희망사항</Title>
          <ContentsText marginBottom={8}>
            진로희망사항을 선택해주세요.
          </ContentsText>
          <ContentsSubText marginBottom={32}>
            *복수 선택이 가능합니다.
          </ContentsSubText>

          <ButtonWrap>
            <RadioBox
              option={careerHopes}
              showLine={3}
              onChange={(value) => {
                setSchool(value);
              }}
            />
          </ButtonWrap>
          <Divide />
          {visibleEtcInput ? (
            <EtcWrap>
              <ContentsSubText marginBottom={16}>
                *기타진로희망사항을 입력해주세요.
              </ContentsSubText>
              <TextInput
                style={{ width: "100%", fontSize: "14px", marginBottom: "0px" }}
                value={etcText}
                onChange={(value) => {
                  setEtcText(value);
                }}
              />
            </EtcWrap>
          ) : null}
        </ContentsWrap>

        <ContentsWrap>
          <Title>학습 이력사항</Title>
          <ContentsTextWrap>
            <TextIcon />
            <ContentsText>
              현재 수학과목에 대한 이력사항을 입력해주세요.
            </ContentsText>
          </ContentsTextWrap>
          <Textarea
            height="200px"
            style={{ marginBottom: "80px" }}
            value={learningHistory1}
            onChange={(value) => {
              setLearningHistory1(value);
            }}
          />

          <ContentsTextWrap>
            <TextIcon />
            <ContentsText>
              현재 과학과목에 대한 이력사항을 입력해주세요.
            </ContentsText>
          </ContentsTextWrap>
          <Textarea
            height="200px"
            value={learningHistory2}
            onChange={(value) => {
              setLearningHistory2(value);
            }}
          />
        </ContentsWrap>

        <ContentsWrap>
          <Title>활동 이력사항</Title>
          <ContentsTextWrap marginBottom="0">
            <TextIcon />
            <ContentsText>
              활동 이력에 대하여 적어주세요.
            </ContentsText>
          </ContentsTextWrap>
          <ContentsSubText marginBottom={32}>
            *SW관련, 영재원, 교내대회, 교외대회
          </ContentsSubText>
          <Textarea
            height="200px"
            style={{ marginBottom: "80px" }}
            value={activeHistory}
            onChange={(value) => {
              setActiveHistory(value);
            }}
          />
        </ContentsWrap>

        <BtnWrap>
          <Button
            active={disalbedBtn}
            disabled={!disalbedBtn}
            style={{ width: "260px" }}
            onClick={() => {
              careerExploration();
            }}
          >
            { userInfo.isPaid ? '완료' : '10,000원 결제하기' }
          </Button>
        </BtnWrap>
      </Container>
      <Footer />

      {openModal ? (
        <Modal
          title={"진로탐색 완료"}
          content={"세부탐색은 채팅을 통해 진행될 예정입니다."}
          onPress={() => {
            setOpenModal(false);
            navigate("/");
          }}
          button={[
            {
              title: "확인",
              type: "active",
            },
          ]}
        />
      ) : null}
    </div>
  );
};

export default CareerSearch;
