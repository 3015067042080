import React from "react";
import styled from "styled-components";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

import { useParams } from "react-router-dom";

import Benefit from "../../assets/images/page/benefit.png";
import ConferenceRoom from "../../assets/images/page/conferenceRoom.png";
import ContentsMarket from "../../assets/images/page/contentsMarket.png";
import QnA from "../../assets/images/page/qna.png";

const Container = styled.div`
  width: 100%;
  overflow: auto;
`;

const MainWrap = styled.div``;

const Image = styled.img`
  width: 100%;
  height: 100%;
`;

const Page = () => {
  const { type } = useParams();

  return (
    <>
      <Header activeTab={type} />
      <Container>
        <MainWrap>
          {/* {type === "conferenceRoom" && <Image src={ConferenceRoom} />}
          {type === "Benefit" && <Image src={Benefit} />}
          {type === "QnA" && <Image src={QnA} />}
          {type === "contentsMarket" && <Image src={ContentsMarket} />} */}
          <div style={{ display: 'flex', width: '100%', height: 800, flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <h1 style={{ fontSize: 32, fontWeight: '500' }}>준비중입니다.</h1>
          </div>
        </MainWrap>
        <Footer />
      </Container>
    </>
  );
};

export default Page;
