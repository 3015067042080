import React, { useState, useEffect } from "react";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
`;
const TabMenuWrap = styled.ul`
  display: flex;
`;
const TabMenu = styled.button`
  font-size: 20px;
  font-weight: ${(props) => props.theme.fontWeight.medium};
  line-height: 50px;
  color: ${(props) => (props.activeColor ? "#FFFFFF" : "#021633")};
  background-color: ${(props) => (props.activeColor ? "#021633" : "#ffffff")};
  margin-right: 20px;
  border: 1px solid #021633;
  border-radius: 30px;
  padding: 0px 40px;
  box-sizing: border-box;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.5s;

  @media (max-width: 1080px) {
    font-size: 16px;
    height: auto;
    padding: 0px 24px;
    line-height: 40px;
  }
`;
const Contents = styled.div`
  background-color: #ffffff;
`;

const CategoryTab = (props) => {
  const [activeTab, setActiveTab] = useState(0);

  const array = props.array;
  const contents = props.contents;

  const tabClickHandler = (idx) => {
    setActiveTab(idx);
  };

  return (
    <Container>
      <TabMenuWrap>
        {array.map((arr, idx) => {
          return (
            <TabMenu
              key={idx}
              onClick={() => {
                tabClickHandler(idx);
              }}
              activeColor={idx === activeTab ? true : false}
            >
              {arr}
            </TabMenu>
          );
        })}
      </TabMenuWrap>
      <Contents>{contents[activeTab]}</Contents>
    </Container>
  );
};

export default CategoryTab;
