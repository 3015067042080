import styled from "styled-components";

export const MobileMypageContainer = styled.div`
  padding: 80px 0px 100px 0px;
  width: 390px;
  margin: 0 auto;
`;

export const MobileMypageProfileBox = styled.div`
  padding: 0 24px;

  > div {
    :first-of-type {
      display: flex;
      padding-bottom: 16px;
      > figure {
        margin-right: 24px;
        div {
          width: 72px;
          height: 72px;
          background: #dee2e6;
          border-radius: 4px;
        }
        button {
          background-color: transparent;
          border: none;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          text-align: center;
          color: #868e96;
          margin-top: 4px;
        }
      }
      > div {
        > div {
          display: flex;
          span {
            display: block;
            :nth-of-type(1) {
              padding: 4px 12px;
              background: #021633;
              border-radius: 4px;
              color: white;
              font-weight: 500;
              font-size: 14px;
              line-height: 20px;
              text-align: center;
            }
            :nth-of-type(2) {
              margin-left: 8px;
              padding: 4px 12px;
              background: #f6f9ff;
              border-radius: 4px;
              font-weight: 500;
              font-size: 14px;
              line-height: 20px;
              color: #0081f5;
            }
          }
        }
        > h2 {
          font-weight: 700;
          font-size: 20px;
          line-height: 29px;
          color: #212529;
          margin-top: 8px;
        }
        > p {
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: #212529;
          margin-top: 12px;
        }
      }
    }

    :last-of-type {
      margin-top: 17px;
      margin-bottom: 24px;
      > button {
        padding: 16.5px 0px;

        width: 342px;
        background-color: transparent;
        border: none;
        justify-content: space-between;
        display: flex;
        p {
          font-weight: 500;
          font-size: 16px;
          line-height: 23px;
          color: #212529;
        }
        img {
          width: 24px;
          height: 24px;
        }
      }
    }
  }

  > button {
    background-color: transparent;
    border: none;
    width: 342px;
    height: 45px;
    background: #021633;
    border-radius: 4px;
    color: white;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }
`;
