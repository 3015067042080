import React, { useState } from "react";
import styled from "styled-components";

import Close from "../assets/icons/cancle_24px.svg";

const Container = styled.div`
  width: 398px;
  height: 620px;
  background-color: ${(props) => props.theme.color.white};
  border-radius: 12px;
  cursor: default;
  padding: 20px;
  position: absolute;
  right: 0px;
  top: 47px;
  z-index: 99;
  overflow: auto;
  box-shadow: 0px 4px 20px #021633;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  ::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }

  @media (max-width: 1080px) {
    width: 100%;
    height: auto;
    min-height: 300px;
    box-shadow: none;
    top: 58px;
  }
`;
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
`;
const HeaderText = styled.span`
  display: inline-block;
  position: relative;
  font-size: 16px;
  font-weight: ${(props) => props.theme.fontWeight.medium};
`;
const CloseImg = styled.img.attrs({
  src: `${Close}`,
})`
  cursor: pointer;
`;
const ContentsWrap = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;
const NoneData = styled.div`
  font-size: 16px;
  font-weight: ${(props) => props.theme.fontWeight.medium};
  color: #adb5bd;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;
const Contents = styled.div`
  width: 100%;
  height: 107px;
  border-radius: 12px;
  background-color: ${(props) =>
    props.alarm ? props.theme.color.primary : props.theme.color.white};
  border: solid ${(props) => props.theme.color.primary}
    ${(props) => (props.alarm ? "0px" : "1px")};
  padding: 20px;
  margin-bottom: 20px;
  color: ${(props) =>
    props.alarm ? props.theme.color.white : props.theme.color.primary};
`;
const TitleWrap = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 7px;
`;
const Title = styled.span`
  font-size: 12px;
  font-weight: ${(props) => props.theme.fontWeight.medium};
`;
const Desc = styled.p`
  font-size: 14px;
  font-weight: ${(props) => props.theme.fontWeight.medium};
  height: 42px;
  overflow: hidden;
  text-align: left;
`;

const NewAlarm = styled.div`
  display: ${(props) => (props.newAlarm ? "block" : "none")};
  width: 4px;
  height: 4px;
  border-radius: 4px;
  background-color: #ff383b;
  position: absolute;
  right: -6px;
  top: -4px;
`;

const Notification = (props) => {
  const [alarmVisible, setAlarmVisible] = useState(true);
  
  return (
    <Container>
      <Header>
        <HeaderText>
          알림
          <NewAlarm newAlarm={alarmVisible} />
        </HeaderText>
        <CloseImg onClick={props.onClick} />
      </Header>
      <NoneData>알림이 없습니다.</NoneData>
      {/* <ContentsWrap>
        <Contents>
          <TitleWrap>
            <Title>제목</Title>
            <Title>시간</Title>
          </TitleWrap>
          <Desc>
            안녕하세요, 이곳은 알림 내용 영역입니다. 안 녕하세요, 이곳은 알림
            내용 영역입니다...
          </Desc>
        </Contents>

        <Contents alarm>
          <TitleWrap>
            <Title>제목</Title>
            <Title>시간</Title>
          </TitleWrap>
          <Desc>
            안녕하세요, 이곳은 알림 내용 영역입니다. 안 녕하세요, 이곳은 알림
            내용 영역입니다...
          </Desc>
        </Contents>

        <Contents alarm>
          <TitleWrap>
            <Title>제목</Title>
            <Title>시간</Title>
          </TitleWrap>
          <Desc>
            안녕하세요, 이곳은 알림 내용 영역입니다. 안 녕하세요, 이곳은 알림
            내용 영역입니다...
          </Desc>
        </Contents>

        <Contents alarm>
          <TitleWrap>
            <Title>제목</Title>
            <Title>시간</Title>
          </TitleWrap>
          <Desc>
            안녕하세요, 이곳은 알림 내용 영역입니다. 안 녕하세요, 이곳은 알림
            내용 영역입니다...
          </Desc>
        </Contents>

        <Contents alarm>
          <TitleWrap>
            <Title>제목</Title>
            <Title>시간</Title>
          </TitleWrap>
          <Desc>
            안녕하세요, 이곳은 알림 내용 영역입니다. 안 녕하세요, 이곳은 알림
            내용 영역입니다...
          </Desc>
        </Contents>

        <Contents alarm>
          <TitleWrap>
            <Title>제목</Title>
            <Title>시간</Title>
          </TitleWrap>
          <Desc>
            안녕하세요, 이곳은 알림 내용 영역입니다. 안 녕하세요, 이곳은 알림
            내용 영역입니다...
          </Desc>
        </Contents>
      </ContentsWrap> */}
    </Container>
  );
};

export default Notification;
