import { useEffect, useLayoutEffect, useState } from "react";
import KakaoLogin from "react-kakao-login";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilState, useSetRecoilState } from "recoil";
import Button from "../../../components/Button";
import mobile_home_logo from "../../../assets/images/mobile_main_logo.png";
import TextInput from "../../../components/TextInput";
import close from "../../../assets/images/close.png";
import snsTokenState from "../../../states/snsTokenState";
import { ReactComponent as KaKaoIcon } from "../../../assets/icons/kakao.svg";
import tokenState from "../../../states/tokenState";
import next from "../../../assets/images/next.png";
import { ButtonText, GoogleButtonWrap, KakaoButtonWrap } from "../style";
import {
  EasyLoginWrapper,
  ErrorModal,
  HeadLine,
  JoinContainer,
  JoinModalHeader,
  LoginWrapper,
  MobileContainer,
  MobileHomeLogo,
} from "./login_mobile.style";
import axios from "axios";
import { API_URL } from "../../../API";
import { useGoogleLogin } from "@react-oauth/google";
import GoogleIcon from "../../../assets/icons/google.svg";
import joinModalState from "../../../states/joinModalState";

const LoginMobile = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [joinModal, setJoinModal] = useRecoilState(joinModalState);
  const [errorModal, setErrorModal] = useState(false);
  const setSNSTokenValue = useSetRecoilState(snsTokenState);
  const setToken = useSetRecoilState(tokenState);
  const [errorMessage, setErrorMessage] = useState("");

  const JoinMessage = [
    {
      title: "학생 / 학부모",
      sub: `영재멘토에서 학생 혹은 학부모 자
      격으로 회원가입을 진행합니다.`,
    },
    {
      title: "강사",
      sub: `영재멘토에서 강사 자격으로 회원
      가입을 진행합니다..`,
    },
  ];

  const googleLogin = useGoogleLogin({
    flow: "auth-code",
    onSuccess: (response) => {
      console.log(response);
      // socialLogin("GOOGLE", response.);
    },
  });

  const socialLogin = (type, token) => {
    if (type !== "" && token !== "") {
      axios({
        method: "POST",
        url: `${API_URL}/user/login`,
        data: {
          socialType: type,
          socialToken: token,
        },
      })
        .then((result) => {
          if (result.data.success === true) {
            if (result.data.result.signuped === true) {
              setToken(result.data.result.token);
              navigate("/");
            } else {
              setJoinModal(true);
              setSNSTokenValue({
                id: token,
                type: type,
              });
            }
          }

          if (result.data.success === false) {
            setErrorMessage(result.data.error.message);
            setErrorModal(true);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleLogin = () => {
    if (email !== "" && password !== "") {
      axios({
        method: "POST",
        url: `${API_URL}/user/login`,
        data: {
          email: email,
          password: password,
        },
      })
        .then((result) => {
          if (result.data.success === true) {
            setToken(result.data.result.token);
            navigate("/");
          }

          if (result.data.success === false) {
            setErrorMessage(result.data.error.message);
            setErrorModal(true);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  return (
    <>
      {joinModal ? (
        <>
          <JoinModalHeader>
            <button
              onClick={() => {
                setJoinModal(false);
              }}
            >
              <img src={close} alt="close" />
            </button>
          </JoinModalHeader>
          <JoinContainer>
            <h1>회원가입</h1>
            <p>가입하고자 하는 회원 유형을 선택해주세요</p>
            <ul>
              {JoinMessage.map((el, id) => (
                <li>
                  <button
                    onClick={() => {
                      if (id === 0) navigate("/signUp/student");
                      if (id === 1) navigate("/signUp/teacher/notice");
                    }}
                  >
                    <figure></figure>
                    <div>
                      <h2>{el.title}</h2>
                      <p>{el.sub}</p>
                    </div>
                    <img src={next} alt="next" />
                  </button>
                </li>
              ))}
            </ul>
          </JoinContainer>
        </>
      ) : (
        <MobileContainer>
          <MobileHomeLogo>
            <img src={mobile_home_logo} alt="logo" />
          </MobileHomeLogo>
          <LoginWrapper>
            <h2>로그인</h2>
            <div>
              <TextInput
                value={email}
                onChange={(value) => {
                  setEmail(value);
                }}
                placeholder={"이메일 입력"}
                style={{ width: "342px", height: "56px" }}
              />
              <TextInput
                value={password}
                onChange={(value) => {
                  setPassword(value);
                }}
                placeholder={"비밀번호 입력"}
                style={{ width: "342px", height: "56px" }}
                type={"password"}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    handleLogin();
                  }
                }}
              />
            </div>
            <Button
              style={{ width: "342px" }}
              active={email !== "" && password !== ""}
              disabled={email === "" || password === ""}
              onClick={handleLogin}
            >
              로그인
            </Button>
            <div>
              <button
                onClick={() => {
                  navigate("/findpwid");
                }}
              >
                아이디/ 비밀번호 찾기
              </button>
              <button
                onClick={() => {
                  setJoinModal(true);
                  setSNSTokenValue({
                    id: "",
                    type: "",
                  });
                }}
              >
                회원가입
              </button>
            </div>
          </LoginWrapper>
          <HeadLine />
          <EasyLoginWrapper>
            <h2>간편 로그인</h2>
            <KakaoLogin
              token={process.env.REACT_APP_KAKAO_CLIENT_ID}
              onSuccess={(value) => {
                socialLogin("KAKAO", value.profile.id);
              }}
              onFail={console.error}
              onLogout={console.info}
              render={({ onClick }) => {
                return (
                  <KakaoButtonWrap
                    style={{ width: "342px" }}
                    onClick={() => {
                      onClick();
                    }}
                  >
                    <KaKaoIcon />
                    <ButtonText>카카오 아이디로 시작하기</ButtonText>
                  </KakaoButtonWrap>
                );
              }}
            />
            <GoogleButtonWrap style={{ width: "342px" }} onClick={googleLogin}>
              <img src={GoogleIcon} alt="googleIcon" />
              <ButtonText>구글 아이디로 시작하기</ButtonText>
            </GoogleButtonWrap>
          </EasyLoginWrapper>
          {errorModal && (
            <ErrorModal>
              <div>
                <h2>로그인 실패</h2>
                <p>{errorMessage}</p>
                <button onClick={() => setErrorModal(false)}>확인</button>
              </div>
            </ErrorModal>
          )}
        </MobileContainer>
      )}
    </>
  );
};

export default LoginMobile;
