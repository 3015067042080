import BootPay from "bootpay-js";
// //실제 복사하여 사용시에는 모든 주석을 지운 후 사용하세요

// 부트페이 가이드

// runBootPay(
//   500,
//   "영재멘토",
//   "영재멘토 유료회원",
//   "ㅇㅇ",
//   "dd@dd.com",
//   "ㅇㅇ",
//   "01050477437",
//   () => {},
//   () => {}
// );

const runBootPay = (
  price,
  name,
  itemName,
  username,
  email,
  addr,
  phone,
  onClose,
  onFinish
) => {
  BootPay.request({
    price: price, //실제 결제되는 가격
    application_id: "62d10dffe38c3000245b01a1",
    name: name ? name : "영재멘토", //결제창에서 보여질 이름
    pg: "nicepay",
    method: "", //결제수단, 입력하지 않으면 결제수단 선택부터 화면이 시작합니다.
    show_agree_window: 0, // 부트페이 정보 동의 창 보이기 여부
    items: [
      {
        item_name: itemName ? itemName : "영재멘토", //상품명
        qty: 1, //수량
        unique: "123", //해당 상품을 구분짓는 primary key
        price: 1000, //상품 단가
        cat1: "TOP", // 대표 상품의 카테고리 상, 50글자 이내
        cat2: "티셔츠", // 대표 상품의 카테고리 중, 50글자 이내
        cat3: "라운드 티", // 대표상품의 카테고리 하, 50글자 이내
      },
    ],
    user_info: {
      username: username,
      email: email,
      addr: addr,
      phone: phone,
    },
    order_id: "고유order_id_1234", //고유 주문번호로, 생성하신 값을 보내주셔야 합니다.
    extra: {
      theme: "purple", // [ red, purple(기본), custom ]
      custom_background: "#00a086", // [ theme가 custom 일 때 background 색상 지정 가능 ]
      custom_font_color: "#ffffff", // [ theme가 custom 일 때 font color 색상 지정 가능 ]
      raw_data: true,
    },
  })
    .error((data) => {
      console.log(data);
    })
    .cancel(function (data) {
      console.log(data);
    })
    .confirm(function (data) {
      BootPay.transactionConfirm(data);
    })
    .close(function (data) {
      onClose(true);
    })
    .done(function (data) {
      onFinish(data.receipt_id);
    });
};

export const runBootpaySubscription = (
  order_name,
  price,
  username,
  phone,
  email,
  onClose,
  onFinish
) => {
  BootPay.request({
    application_id: "62d10dffe38c3000245b01a1",
    pg: 'nicepay',
    method: 'card_rebill',
    name: order_name,
    show_agree_window: 0,
    user_info: {
      username,
      phone,
      email
    },
    order_id: new Date().getTime()
  })
    .close(function (data) {
      onClose(true);
    })
    .done(function (data) {
      onFinish(data.billing_key);
    });
};

export default runBootPay;
