import React, { useState, useEffect } from "react";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
`;

const TabMenuWrap = styled.ul`
  border-bottom: 2px solid #e9ecef;
  display: flex;
  margin: 0 ${(props) => (props.margin ? "278px" : "0px")};
`;

const TabMenu = styled.button`
  font-size: 32px;
  font-weight: ${(props) => props.theme.fontWeight.medium};
  color: ${(props) => (props.activeColor ? "#FFAD2E" : "#adb5bd")};
  margin-right: ${(props) => (props.noMarign ? "0px" : "40px")};
  background-color: #ffffff;
  border: none;
  border-bottom: 4px solid
    ${(props) => (props.activeColor ? "#FFAD2E" : "#ffffff")};
  padding-bottom: 19px;

  @media (max-width: 1080px) {
    font-size: 16px;
    padding-bottom: 8px;
    border-bottom-width: 2px;
    margin-right: 8px;
  }
`;

const Contents = styled.div`
  background-color: #ffffff;
`;

const Tab = (props) => {
  const [activeTab, setActiveTab] = useState(0);

  const array = props.array;
  const contents = props.contents;

  const tabClickHandler = (idx) => {
    setActiveTab(idx);
  };

  useEffect(() => {
    if (props.onActiveTabChanged) {
      props.onActiveTabChanged(activeTab);
    }
  }, [activeTab]);

  return (
    <Container>
      <TabMenuWrap margin={props.margin}>
        {array.map((arr, idx) => {
          return (
            <TabMenu
              key={idx}
              onClick={() => {
                tabClickHandler(idx);
              }}
              activeColor={idx === activeTab ? true : false}
            >
              {arr}
            </TabMenu>
          );
        })}
      </TabMenuWrap>

      <Contents>{contents[activeTab]}</Contents>
    </Container>
  );
};

export default Tab;
