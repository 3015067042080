import React from "react";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  overflow: auto;
`;

const MainWrap = styled.div`
  width: 536px;
  padding: 240px 0px 0px;
  margin: 0 auto;
`;

const NoticeTitle = styled.div`
  color: ${(props) => (props.purple ? "#6337FC" : "#212529")};
  font-size: 32px;
  font-weight: ${(props) => (props.purple ? 700 : 500)};
  line-height: 44px;
`;

const JoinNoticeWrap = styled.div`
  width: 100%;
  margin: 32px 0px 260px;
  padding: 48px;
  background-color: #f6f9ff;
  border-radius: 12px;
`;

const CountText = styled.span`
  background-color: #021633;
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  padding: 4px 18px;
  box-sizing: border-box;
  border-radius: 16px;
`;

const NoticeContent = styled.div`
  color: ${(props) =>
    props.purple ? "#6337FC" : props.red ? "#FF383B" : "#212529"};
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
`;

const SchoolTitle = styled.div`
  box-sizing: border-box;
  background-color: #dde2ea;
  border-radius: 8px;
  padding: 0px 20px;
  display: inline-block;
  color: #021633;
  font-size: 16px;
  font-weight: 500;
  line-height: 32px;
  margin-bottom: 20px;
  height: 32px;
`;

const DocumentInfoWrap = styled.div`
  width: 100%;
  border: 1px solid #dde2ea;
  border-radius: 8px;
  padding: 48px;
  margin-bottom: 80px;
`;

const DocumentNotice = styled.div`
  color: #212529;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  margin-bottom: 32px;
`;

const DocumentInfoNotice = styled.div`
  color: #212529;
  font-size: 14px;
  font-weight: 400;
  line-height: 28px;
  margin-bottom: 32px;
`;

const DocumentKindWrap = styled.div`
  width: 100%;
  max-width: 349px;
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 20px;
  min-height: 237px;
`;

const DocumentKindTitle = styled.div`
  color: #021633;
  font-size: 16px;
  font-weight: 500;
  line-height: 32px;
  border-bottom: 1px solid #e9ecef;
  padding-bottom: 12px;
  margin-bottom: 20px;
`;

const DocumnetKindContent = styled.li`
  color: #212529;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  list-style-position: inside;
  text-indent: -20px;
  padding-left: 20px;
`;

const LevelType = styled.div`
  color: #0081f5;
  font-size: 12px;
  font-weight: 400;
  background-color: rgba(0, 129, 245, 0.1);
  width: 53px;
  height: 20px;
  line-height: 24px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
`;

const LevelInfoWrap = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;

const LevelInfoContent = styled.div`
  color: #212529;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  margin-left: 7px;
`;

const ExampleBox = styled.div`
  background-color: rgba(255, 56, 59, 0.1);
  width: 39px;
  height: 18px;
  color: #ff383b;
  font-size: 10px;
  font-weight: 400;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  margin-right: 9px;
`;

const ExampleWrap = styled.div`
  display: flex;
  white-space: nowrap;
  margin-top: 16px;
`;

const ExampleContent = styled.div`
  color: #868e96;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
`;

const UrlText = styled.a`
  color: #ffad2e;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  cursor: pointer;
`;

const CancelButton = styled.div`
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='24' height='24' viewBox='0 0 24 24'%3E%3Cdefs%3E%3CclipPath id='clip-path'%3E%3Crect id='사각형_3803' data-name='사각형 3803' width='24' height='24' transform='translate(1050 1250)' fill='%23fff'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg id='마스크_그룹_175' data-name='마스크 그룹 175' transform='translate(-1050 -1250)' clip-path='url(%23clip-path)'%3E%3Cg id='b' transform='translate(1050 1250)'%3E%3Cg id='c'%3E%3Cpath id='패스_598' data-name='패스 598' d='M0,0H24V24H0Z' fill='%23adb5bd' opacity='0' style='isolation: isolate'/%3E%3Cpath id='선_23' data-name='선 23' d='M14,15a1,1,0,0,1-.707-.293l-14-14a1,1,0,0,1,0-1.414,1,1,0,0,1,1.414,0l14,14A1,1,0,0,1,14,15Z' transform='translate(5 5)' fill='%23212529'/%3E%3Cpath id='선_24' data-name='선 24' d='M0,15a1,1,0,0,1-.707-.293,1,1,0,0,1,0-1.414l14-14a1,1,0,0,1,1.414,0,1,1,0,0,1,0,1.414l-14,14A1,1,0,0,1,0,15Z' transform='translate(5 5)' fill='%23212529'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
  width: 24px;
  height: 24px;
`;

const RadioBoxBorder = styled.div`
  background-color: #e9ecef;
  width: 100%;
  height: 2px;
  margin: 12px 0px 32px;
`;

export {
  Container,
  MainWrap,
  NoticeTitle,
  JoinNoticeWrap,
  CountText,
  NoticeContent,
  SchoolTitle,
  DocumentInfoWrap,
  DocumentNotice,
  DocumentInfoNotice,
  DocumentKindWrap,
  DocumentKindTitle,
  DocumnetKindContent,
  LevelType,
  LevelInfoWrap,
  LevelInfoContent,
  ExampleBox,
  ExampleWrap,
  ExampleContent,
  UrlText,
  RadioBoxBorder,
};
