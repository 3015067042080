import React from "react";
import { useNavigate } from "react-router-dom";

import {
  Container,
  MainWrap,
  NoticeTitle,
  JoinNoticeWrap,
  CountText,
  NoticeContent,
} from "./style";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import Button from "../../../components/Button";
import { Desktop, Mobile } from "../../../utils/mediaQuery";
import {
  MobileBtnWrapper,
  MobileSignUpHeader,
  MobileTeacherNoticeContainer,
} from "./mobile.style";
import prev_btn from "../../../assets/images/prev.png";
import { MobileContainer } from "../../login/mobile/login_mobile.style";
import { ButtonWrap } from "../student/style";
const TeacherJoinNotice = () => {
  const navigate = useNavigate();

  return (
    <>
      <Desktop>
        <Header activeTab={"Login"} />
        <Container>
          <MainWrap>
            <NoticeTitle>회원가입 전</NoticeTitle>
            <div style={{ display: "flex" }}>
              <NoticeTitle purple>안내사항</NoticeTitle>
              <NoticeTitle>을 확인해주세요!</NoticeTitle>
            </div>
            <JoinNoticeWrap>
              <CountText>첫번쨰</CountText>
              <NoticeContent style={{ marginTop: 20 }}>
                과외가 진행될 때마다
              </NoticeContent>
              <div style={{ display: "flex", marginBottom: 80 }}>
                <NoticeContent purple>건당 수수료 25%</NoticeContent>
                <NoticeContent>의 이용요금이 발생합니다.</NoticeContent>
              </div>
              <CountText>두번째</CountText>
              <NoticeContent style={{ marginTop: 20 }}>
                {"학생/학부모와의 대화내역은 엑티버스 내에서\n"}
              </NoticeContent>
              <div style={{ display: "flex" }}>
                <NoticeContent>확인가능하며,&nbsp;</NoticeContent>
                <NoticeContent red>
                  {"계약과 위반된 내용이 오간 경우,\n"}
                </NoticeContent>
              </div>
              <div style={{ display: "flex", marginBottom: 80 }}>
                <NoticeContent red>불이익</NoticeContent>
                <NoticeContent>이 생길수 있습니다.</NoticeContent>
              </div>
              <Button active onClick={() => navigate("/signUp/teacher")}>
                동의하고 가입을 진행하겠습니다.
              </Button>
            </JoinNoticeWrap>
          </MainWrap>
          <Footer />
        </Container>
      </Desktop>
      <Mobile>
        <MobileSignUpHeader>
          <button
            onClick={() => {
              navigate("/login");
            }}
          >
            <img src={prev_btn} alt="prev" />
          </button>
          <h2>선생님 회원가입</h2>
        </MobileSignUpHeader>
        <MobileTeacherNoticeContainer>
          <h2>
            회원가입 전<br />
            <span style={{ color: "#6337FC" }}>안내사항</span>을 확인해주세요!
          </h2>
          <ul>
            <li>
              <div>첫번째</div>
              <p>
                과외가 진행될 때마다
                <br /> <span style={{ color: "#6337FC" }}>건당 수수료 25%</span>
                의 이용요금이 발생합니다.
              </p>
            </li>
            <li>
              <div>두번째</div>
              <p>
                학생/학부모와의 대화내역은 액티버스 내에서
                <br /> 확인가능하며,{" "}
                <span style={{ color: "#FF383B" }}>
                  계약과 위반된 내용이 오간 경우,
                </span>
                <br /> 불이익이 생길 수 있습니다.
              </p>
            </li>
          </ul>
        </MobileTeacherNoticeContainer>
        <MobileBtnWrapper>
          <button
            onClick={() => {
              navigate("/signUp/teacher");
            }}
          >
            동의하고 가입을 진행하겠습니다.
          </button>
        </MobileBtnWrapper>
      </Mobile>
    </>
  );
};

export default TeacherJoinNotice;
