import React, { useState } from "react";

import TextInput from "../../../../components/TextInput";
import Button from "../../../../components/Button";
import {
  Container,
  MainWrap,
  Border,
  JoinTypeText,
  PageTypeText,
} from "../../../signup/student/style";
import { UploadNoticeTitle, UploadTitle } from "./style";
import Header from "../../../../components/Header";
import Footer from "../../../../components/Footer";
import axios from "axios";
import { API_URL } from "../../../../API";
import { useNavigate } from "react-router-dom";

const VideoUpload = () => {
  const [videoTitle, setVideoTitle] = useState("");
  const [videoURL, setVideoURL] = useState("");
  const navigate = useNavigate();

  const upload = () => {
    axios({
      method: 'POST',
      url: `${API_URL}/mypage/mentopick/video`,
      data: {
        title: videoTitle,
        video: videoURL
      }
    }).then((res) => {
      if (res.data.success) {
        navigate(-1);
      } else {
        alert(res.data.error.message);
      }
    });
  };

  return (
    <>
      <Header />
      <Container>
        <MainWrap>
          <PageTypeText>영상자료관련</PageTypeText>
          <JoinTypeText style={{ marginBottom: 20 }}>업로드하기</JoinTypeText>
          <Border />
          <UploadTitle>올리실 영상의 제목을 입력해주세요.</UploadTitle>
          <TextInput
            style={{ width: "100%" }}
            value={videoTitle}
            onChange={(value) => {
              setVideoTitle(value);
            }}
          />
          <UploadTitle style={{ marginBottom: 8 }}>
            업로드할 영상의 URL을 등록해주세요.
          </UploadTitle>
          <UploadNoticeTitle>유튜브 URL만 등록 가능합니다.</UploadNoticeTitle>
          <TextInput
            style={{ width: "100%" }}
            value={videoURL}
            onChange={(value) => {
              setVideoURL(value);
            }}
          />
          <div style={{ padding: "0px 276px", marginTop: 64 }}>
            <Button active onClick={upload}>등록하기</Button>
          </div>
        </MainWrap>
        <Footer />
      </Container>
    </>
  );
};

export default VideoUpload;
