const formatSubject = (subject) => {
  if (subject.name && subject.progress) {
    return `${subject.level} ${subject.name} (${subject.progress})`;
  }

  if (subject.name) {
    return `${subject.level} ${subject.name}`;
  }

  return `${subject.level}`;
};

export default formatSubject;
