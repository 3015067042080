import styled from "styled-components";
import {
  ContentMarketWriter,
  DetailContainer,
  DetailImageWrap,
  DetailTitle,
  DetailContent,
} from "./style";

export const MobileMarketTabContainer = styled.ul`
  list-style: none;
  display: flex;
  margin-bottom: 17px;
  > li {
    button {
      padding: 0;
      font-weight: 700;
      font-size: 16px;
      line-height: 23px;
      color: #021633;
      background-color: transparent;
      border: none;
      margin-right: 12px;
    }
  }
`;

export const MobileMarketListContainer = styled.div`
  padding: 0px 24px 100px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  margin: 0 auto;
`;

export const MobileMarketListBox = styled.ul`
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  column-gap: 16px;
  row-gap: 18px;

  > li {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 40vw;
    > button {
      border: none;
      width: 35vw;
      height: 35vw;
      border-radius: 4px;
      overflow: hidden;
      margin-bottom: 12px;
    }
    > h2 {
      font-weight: 700;
      font-size: 16px;
      line-height: 23px;
      color: #212529;
      margin: 0 0.5rem;
      height: 46px;
      width: 100%;
      text-overflow: ellipsis;
      text-align: center;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }
  }
`;

export const IsPaid = styled.div`
  display: flex;
  p {
    :nth-of-type(1) {
      height: 26px;
      background: ${(props) => (props.price !== 0 ? " #EBFBED" : "#f6f9ff;")};
      border-radius: 13px;
      font-weight: 500;
      font-size: 12px;
      padding: 4px 12px;
      box-sizing: border-box;
      line-height: 17px;
      margin-right: 8px;
      color: ${(props) => (props.price !== 0 ? " #33D84E" : "#0081f5")};
    }
    :nth-of-type(2) {
      height: 26px;
      border: 1px solid #021633;
      border-radius: 13px;
      padding: 4px 12px;
      box-sizing: border-box;
      font-weight: 500;
      font-size: 12px;
      line-height: 17px;
      color: #021633;
    }
  }
`;

export const MobileDetailContainer = styled(DetailContainer)`
  width: 100%;
  padding: 24px;
  padding-top: calc(56px + 24px);
`;

export const MobileDetailTitle = styled(DetailTitle)`
  font-size: 18px;
  margin-bottom: 9px;
  line-height: 26px;
`;

export const MobileContentsMarketWriter = styled(ContentMarketWriter)`
  font-size: 14px;
  margin-bottom: 16px;
  line-height: 21px;
`;

export const MobileDetailImageWrap = styled(DetailImageWrap)`
  width: 100%;
  height: 252px;

  img {
    max-height: 252px;
  }
`;

export const MobileDetailContent = styled.div`
  width: auto;
  height: auto;
  margin: 1rem 2rem 3rem 2rem;
`;
