import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "./style/slick.css";
import "./style/slick-theme.css";

import Footer from "../../components/Footer";
import Header from "../../components/Header";

import TestImage from "../../assets/images/content_marke_image.png";

import {
  DetailContainer,
  DetailWrap,
  MarketItemType,
  MarketItemPrice,
  RowWrap,
  DetailTitle,
  DetailBody,
  ContentMarketWriter,
  DetailImageWrap,
  DetailContent,
  NoticeWrap,
  NoticeText,
  Button,
} from "./style";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../../API";
import runBootPay from "../../components/BootPay";
import { useRecoilValue } from "recoil";
import userInfoState from "../../states/userInfoState";
import { Desktop, Mobile } from "../../utils/mediaQuery";
import MobileHeader from "../../components/MobileHeader";
import {
  MobileContentsMarketWriter,
  MobileDetailContainer,
  MobileDetailImageWrap,
  MobileDetailTitle,
  MobileDetailContent,
} from "./mobile.style";

const ContentsMarketDetail = () => {
  const [type, setType] = useState("유료");
  const [show, setShow] = useState(true);
  const [item, setItem] = useState(null);
  const [buyed, setBuyed] = useState(false);
  const params = useParams();
  const navigate = useNavigate();
  const userInfo = useRecoilValue(userInfoState);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    autoplay: false,
    autoplaySpeed: 3000,
    variableWidth: true,
    pauseOnFocus: true,
  };

  const getData = () => {
    axios({
      method: "GET",
      url: `${API_URL}/contentsMarket/${params.id}`,
    }).then((res) => {
      if (res.data.success) {
        setItem(res.data.result.item);
        setBuyed(res.data.result.isBuyed);
      } else {
        alert(res.data.error.message);
        navigate(-1);
      }
    });
  };

  useEffect(() => {
    if (params.id) {
      getData();
    }
  }, [params.id]);

  const downloadFile = (url) => {
    axios({
      url,
      baseURL: "",
      method: "GET",
      responseType: "blob",
      headers: {
        Authorization: "",
      },
    }).then((res) => {
      const url = window.URL.createObjectURL(res.data);
      const a = document.createElement("a");
      const filename = url.split("/")[url.split("/").length - 1];

      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();

      setTimeout(() => {
        window.URL.revokeObjectURL(url);
      }, 60000);

      a.remove();
    });

    axios({
      method: "POST",
      url: `${API_URL}/contentsMarket/${params.id}/download`,
    });
  };

  return (
    <>
      <Desktop>
        <Header activeTab={"contentsMarket"} />
        {item !== null && (
          <DetailContainer>
            <DetailWrap>
              <RowWrap>
                <MarketItemPrice
                  price={item.price === 0 ? "무료" : "유료"}
                  style={{ height: 32, padding: "0px 15px" }}
                >
                  {item.price === 0 ? "무료" : `${item.price}원`}
                </MarketItemPrice>
                <MarketItemType style={{ height: 32, padding: "0px 18px" }}>
                  {item.category.name}
                </MarketItemType>
              </RowWrap>
              <DetailTitle>{item.title}</DetailTitle>
              <ContentMarketWriter>{item.user.nickname}님</ContentMarketWriter>
              <DetailBody>
                <DetailImageWrap>
                  <img
                    alt=""
                    src={
                      item.previewImage
                        ? item.previewImage
                        : require("../../assets/logos/logo_symbol_type.png")
                    }
                    style={{
                      maxWidth: "100%",
                      maxHeight: "100%",
                    }}
                  />
                </DetailImageWrap>
                <DetailContent>{item.contents}</DetailContent>
              </DetailBody>
              <NoticeWrap>
                <NoticeText>
                  {
                    "영재멘토에서 제공하는 모든 자료는 저작권 보호를 받고 있습니다.\n상업적인 용도, 무단 복제, 배포 및 도용을 엄격히 금지하고 있으니 유의바랍니다."
                  }
                </NoticeText>
              </NoticeWrap>
              <RowWrap style={{ justifyContent: "center" }}>
                <Button
                  onClick={() => {
                    navigate("/contentsMarket");
                  }}
                  gray
                >
                  목록으로
                </Button>
                {item.price === 0 || buyed ? (
                  <Button
                    onClick={() => {
                      item.attachments.forEach((attachment) => {
                        downloadFile(attachment);
                      });
                    }}
                  >
                    다운받기
                  </Button>
                ) : (
                  <Button
                    onClick={() => {
                      runBootPay(
                        item.price,
                        `영재멘토 자료 구매 (${item.title})`,
                        `영재멘토 자료 구매 (${item.title})`,
                        userInfo.nickname,
                        userInfo.email,
                        "",
                        userInfo.phoneNumber,
                        () => {},
                        (receiptId) => {
                          axios({
                            method: "POST",
                            url: `${API_URL}/contentsMarket/${item.id}/buy?receiptId=${receiptId}`,
                          }).then((res) => {
                            if (res.data.success) {
                              alert("구매가 완료되었습니다.");
                              getData();
                            } else {
                              alert(res.data.error.message);
                            }
                          });
                        }
                      );
                    }}
                  >
                    {item.price}원 결제하기
                  </Button>
                )}
              </RowWrap>
            </DetailWrap>
          </DetailContainer>
        )}
        <Footer />
      </Desktop>
      <Mobile>
        <MobileHeader name={"컨텐츠마켓"} type={"sub"} />
        {item !== null && (
          <MobileDetailContainer>
            <DetailWrap>
              <RowWrap>
                <MarketItemPrice
                  price={item.price === 0 ? "무료" : "유료"}
                  style={{ height: 32, padding: "0px 15px" }}
                >
                  {item.price === 0 ? "무료" : `${item.price}원`}
                </MarketItemPrice>
                <MarketItemType style={{ height: 32, padding: "0px 18px" }}>
                  {item.category.name}
                </MarketItemType>
              </RowWrap>
            </DetailWrap>
            <MobileDetailTitle>{item.title}</MobileDetailTitle>
            <MobileContentsMarketWriter>
              {item.user.nickname}님
            </MobileContentsMarketWriter>
            <MobileDetailImageWrap>
              <img
                alt=""
                src={
                  item.previewImage
                    ? item.previewImage
                    : require("../../assets/logos/logo_symbol_type.png")
                }
                style={{ maxWidth: "100%", maxHeight: "100%" }}
              />
            </MobileDetailImageWrap>
            <MobileDetailContent>{item.contents}</MobileDetailContent>
            {item.price === 0 || buyed ? (
              <Button
                onClick={() => {
                  item.attachments.forEach((attachment) => {
                    downloadFile(attachment);
                  });
                }}
              >
                다운받기
              </Button>
            ) : (
              <Button
                onClick={() => {
                  runBootPay(
                    item.price,
                    `영재멘토 자료 구매 (${item.title})`,
                    `영재멘토 자료 구매 (${item.title})`,
                    userInfo.nickname,
                    userInfo.email,
                    "",
                    userInfo.phoneNumber,
                    () => {},
                    (receiptId) => {
                      axios({
                        method: "POST",
                        url: `${API_URL}/contentsMarket/${item.id}/buy?receiptId=${receiptId}`,
                      }).then((res) => {
                        if (res.data.success) {
                          alert("구매가 완료되었습니다.");
                          getData();
                        } else {
                          alert(res.data.error.message);
                        }
                      });
                    }
                  );
                }}
              >
                {item.price}원 결제하기
              </Button>
            )}
          </MobileDetailContainer>
        )}
      </Mobile>
    </>
  );
};

export default ContentsMarketDetail;
