import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import { API_URL } from "../../API";
import TextInput from "../../components/TextInput";
import Textarea from "../../components/Textarea";
import Button from "../../components/Button";
import RadioBox from "../../components/RadioBox";
import Modal from "../../components/Modal";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import MobileHeader from "../../components/MobileHeader";

import { Desktop, Mobile } from "../../utils/mediaQuery";

const Container = styled.div`
  width: 80%;
  padding: 200px 254px 260px 254px;
  margin: 0 auto;
`;

const MobileContainer = styled.div`
  width: 90%;
  margin: 0 auto;
  padding-top: 150px;
  padding-bottom: 60px;
`;

const PageTitleWrap = styled.div`
  border-bottom: 2px solid #e9ecef;
  padding-bottom: 31px;
  margin-bottom: 80px;
`;

const PageTitle = styled.h2`
  font-size: 40px;
  font-weight: ${(props) => props.theme.fontWeight.bold};
  color: ${(props) => props.theme.color.textColor};
  margin-bottom: 11px;
  height: 59px;
`;

const PageSubTitle = styled.span`
  font-size: 24px;
  line-height: 35px;
  font-weight: ${(props) => props.theme.fontWeight.regular};
  color: ${(props) =>
    props.orange ? props.theme.color.orange : props.theme.color.textColor};
`;

const ContentsWrap = styled.div`
  margin-bottom: ${(props) => (props.marginBottom ? "60px" : "80px")};
  display: flex;
  flex-direction: column;
`;

const Title = styled.span`
  width: fit-content;
  font-size: 28px;
  font-weight: ${(props) => props.theme.fontWeight.bold};
  line-height: 50px;
  border-bottom: 2px solid ${(props) => props.theme.color.textColor};
  padding-bottom: 7px;
  margin-bottom: 32px;
`;

const ContentsText = styled.span`
  font-size: 20px;
  font-weight: ${(props) => props.theme.fontWeight.medium};
  color: ${(props) => props.theme.color.textColor};
  margin-bottom: ${(props) =>
    props.marginBottom ? props.marginBottom + "px" : null};
  height: 29px;
`;

const GuideContentsText = styled.span`
  font-size: 17px;
  line-height: 24px;
  font-weight: ${(props) => props.theme.fontWeight.normal};
  color: #666666;
`;

const ContentsSubText = styled.span`
  display: inline-block;
  font-size: 16px;
  font-weight: ${(props) => props.theme.fontWeight.medium};
  min-height: 24px;
  color: #868e96;
  margin-bottom: ${(props) =>
    props.marginBottom ? props.marginBottom + "px" : null};
`;

const ContentsTextWrap = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${(props) =>
    props.marginBottom ? props.marginBottom + "px" : "20px"};
`;

const BtnWrap = styled.div`
  display: flex;
  justify-content: center;
`;

const inboundValues = [
  {
    label: "검색",
    name: "SEARCH",
    value: "SEARCH",
    id: "SEARCH",
  },
  {
    label: "카페",
    name: "CAFE",
    value: "CAFE",
    id: "CAFE",
  },
  {
    label: "밴드",
    name: "BAND",
    value: "BAND",
    id: "BAND",
  },
  {
    label: "SNS",
    name: "SNS",
    value: "SNS",
    id: "SNS",
  },
  {
    label: "주변 추천",
    name: "FRIEND",
    value: "FRIEND",
    id: "FRIEND",
  },
];

const timeValues = [
  "09:00",
  "10:00",
  "11:00",
  "12:00",
  "13:00",
  "14:00",
  "15:00",
  "16:00",
  "17:00",
  "18:00",
  "19:00",
  "20:00",
].map((i) => {
  return {
    label: i,
    name: i,
    value: i,
    id: i,
  };
});

const Inquery = () => {
  const navigate = useNavigate();
  const [school, setSchool] = useState("");
  const [grade, setGrade] = useState("");
  const [time, setTime] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [inbound, setInbound] = useState("");
  const [inboundEtc, setInboundEtc] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [disalbedBtn, setDisabledBtn] = useState(false);
  const [description, setDescription] = useState("");

  useEffect(() => {
    if (school && grade && inbound && time && name && phone && description) {
      setDisabledBtn(true);
    } else {
      setDisabledBtn(false);
    }
  }, [school, grade, inbound, time, name, phone, description]);

  const addInquery = () => {
    axios({
      url: `${API_URL}/inquery`,
      method: "POST",
      data: {
        school,
        grade,
        time,
        name,
        phone,
        description,
        inbound: inbound === "FRIEND" ? inboundEtc : inbound,
      },
    })
      .then((result) => {
        if (result.data.success) {
          setOpenModal(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      <Desktop>
        <Header />
        <Container>
          <PageTitleWrap>
            <PageTitle>문의하기</PageTitle>
            <PageSubTitle>
              우리아이에게 꼭 맞는 컨설팅을 위해 필요한 정보를 입력해주세요.
            </PageSubTitle>
          </PageTitleWrap>

          <ContentsWrap>
            <Title>학생정보입력</Title>
            <ContentsText marginBottom={20}>
              학생정보를 입력해주세요.
            </ContentsText>

            <ContentsSubText>학생 이름</ContentsSubText>
            <TextInput
              placeholder={"학생 이름을 입력해주세요."}
              value={name}
              onChange={(value) => {
                setName(value);
              }}
              style={{
                width: "300px",
                marginRight: "8px",
              }}
            />

            <ContentsSubText>학년(2024년도 기준 학년)</ContentsSubText>
            <TextInput
              type="text"
              placeholder={"학년을 입력 입력해주세요."}
              value={grade}
              onChange={(value) => {
                setGrade(value);
              }}
              style={{
                width: "300px",
                marginRight: "8px",
              }}
            />

            <ContentsSubText>학교</ContentsSubText>
            <TextInput
              placeholder={"학교를 입력해주세요."}
              value={school}
              onChange={(value) => {
                setSchool(value);
              }}
              style={{
                width: "300px",
                marginRight: "8px",
              }}
            />
          </ContentsWrap>

          <ContentsWrap>
            <Title>상담 예약</Title>
            <ContentsText marginBottom={20}>
              연락처 정보를 입력해주세요.
            </ContentsText>
            <ContentsSubText>휴대폰 번호</ContentsSubText>
            <TextInput
              placeholder={"상담을 받으실 휴대전화 번호를 입력해주세요."}
              value={phone}
              onChange={(value) => {
                setPhone(value);
              }}
              style={{
                width: "500px",
                marginRight: "8px",
              }}
            />
            <ContentsSubText
              style={{
                marginTop: "20px",
                marginBottom: "10px",
              }}
            >
              원하는 상담시간을 선택해주세요.
            </ContentsSubText>
            <RadioBox
              option={timeValues}
              showLine={6}
              name={time}
              onChange={(value) => {
                setTime(value);
              }}
            />
          </ContentsWrap>

          <ContentsWrap marginBottom>
            <Title>유입경로</Title>
            <ContentsText marginBottom={32}>
              영재멘토를 알게된 경로를 선택해주세요.
            </ContentsText>
            <RadioBox
              option={inboundValues}
              showLine={3}
              name={inbound}
              onChange={(value) => {
                setInbound(value);
              }}
            />
            {inbound === "FRIEND" && (
              <TextInput
                placeholder={"추천인 입력하기"}
                value={inboundEtc}
                onChange={(value) => {
                  setInboundEtc(value);
                }}
                style={{
                  width: "100%",
                  marginRight: "8px",
                }}
              />
            )}
          </ContentsWrap>

          <ContentsWrap>
            <Title>고민사항</Title>
            <ContentsTextWrap marginBottom="0">
              <GuideContentsText>
                과목별 부족한 부분이나 학습 계획 세우기, 우리아이 장단점 분석 등
                어렵고 고민되는 부분에 대해서 자세히 작성해주세요. 해당 내용을
                바탕으로 전문 컨설턴트가 보다 철저하게 도와드립니다.
              </GuideContentsText>
            </ContentsTextWrap>
            <Textarea
              height="200px"
              style={{ marginBottom: "80px", marginTop: "20px" }}
              value={description}
              onChange={(value) => {
                setDescription(value);
              }}
            />
          </ContentsWrap>

          <BtnWrap>
            <Button
              active={disalbedBtn}
              disabled={!disalbedBtn}
              style={{ width: "260px" }}
              onClick={() => {
                addInquery();
              }}
            >
              완료
            </Button>
          </BtnWrap>
        </Container>
        <Footer />
      </Desktop>
      <Mobile>
        <MobileHeader name={"문의하기"} type={"main"} hideIcon={true} />
        <MobileContainer>
          <PageTitleWrap>
            <PageTitle>문의하기</PageTitle>
            <PageSubTitle>
              우리아이에게 꼭 맞는 컨설팅을 위해 필요한 정보를 입력해주세요.
            </PageSubTitle>
          </PageTitleWrap>

          <ContentsWrap>
            <Title>학생정보입력</Title>
            <ContentsText marginBottom={20}>
              학생정보를 입력해주세요.
            </ContentsText>

            <ContentsSubText>학생 이름</ContentsSubText>
            <TextInput
              placeholder={"학생 이름을 입력해주세요."}
              value={name}
              onChange={(value) => {
                setName(value);
              }}
              style={{
                width: "100%",
              }}
            />

            <ContentsSubText>학년(2024년도 기준 학년)</ContentsSubText>
            <TextInput
              type="text"
              placeholder={"학년을 입력 입력해주세요."}
              value={grade}
              onChange={(value) => {
                setGrade(value);
              }}
              style={{
                width: "100%",
              }}
            />

            <ContentsSubText>학교</ContentsSubText>
            <TextInput
              placeholder={"학교를 입력해주세요."}
              value={school}
              onChange={(value) => {
                setSchool(value);
              }}
              style={{
                width: "100%",
              }}
            />
          </ContentsWrap>

          <ContentsWrap>
            <Title>상담 예약</Title>
            <ContentsText marginBottom={20}>
              연락처 정보를 입력해주세요.
            </ContentsText>
            <ContentsSubText>휴대폰 번호</ContentsSubText>
            <TextInput
              placeholder={"상담을 받으실 휴대전화 번호를 입력해주세요."}
              value={phone}
              onChange={(value) => {
                setPhone(value);
              }}
              style={{
                width: "100%",
              }}
            />
            <ContentsSubText
              style={{
                marginTop: "20px",
                marginBottom: "10px",
              }}
            >
              원하는 상담시간을 선택해주세요.
            </ContentsSubText>
            <RadioBox
              option={timeValues}
              showLine={4}
              name={time}
              onChange={(value) => {
                setTime(value);
              }}
            />
          </ContentsWrap>

          <ContentsWrap marginBottom>
            <Title>유입경로</Title>
            <ContentsText marginBottom={32}>
              영재멘토를 알게된 경로를 선택해주세요.
            </ContentsText>
            <RadioBox
              option={inboundValues}
              showLine={3}
              name={inbound}
              onChange={(value) => {
                setInbound(value);
              }}
            />
            {inbound === "FRIEND" && (
              <TextInput
                placeholder={"추천인 입력하기"}
                value={inboundEtc}
                onChange={(value) => {
                  setInboundEtc(value);
                }}
                style={{
                  width: "100%",
                  marginRight: "8px",
                }}
              />
            )}
          </ContentsWrap>

          <ContentsWrap>
            <Title>고민사항</Title>
            <ContentsTextWrap marginBottom="0">
              <GuideContentsText>
                과목별 부족한 부분이나 학습 계획 세우기, 우리아이 장단점 분석 등
                어렵고 고민되는 부분에 대해서 자세히 작성해주세요. 해당 내용을
                바탕으로 전문 컨설턴트가 보다 철저하게 도와드립니다.
              </GuideContentsText>
            </ContentsTextWrap>
            <Textarea
              height="200px"
              style={{ marginBottom: "80px", marginTop: "20px" }}
              value={description}
              onChange={(value) => {
                setDescription(value);
              }}
            />
          </ContentsWrap>

          <BtnWrap>
            <Button
              active={disalbedBtn}
              disabled={!disalbedBtn}
              style={{ width: "260px" }}
              onClick={() => {
                addInquery();
              }}
            >
              완료
            </Button>
          </BtnWrap>
        </MobileContainer>
      </Mobile>
      {openModal ? (
        <Modal
          title={"문의하기 완료"}
          content={"영재멘토에 궁금증을 해결해드릴게요!"}
          onPress={() => {
            setOpenModal(false);
            navigate("/");
          }}
          button={[
            {
              title: "확인",
              type: "active",
            },
          ]}
        />
      ) : null}
    </div>
  );
};

export default Inquery;
