import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import ChatCancel from "../assets/icons/cancle_24px.svg";
import ChatIcon44 from "../assets/icons/chat_44px.svg";
import NoneProfile from "../assets/icons/profile_image.svg";

import { useRecoilValue } from "recoil";
import tokenState from "../states/tokenState";

import ChatRoom from "./ChatRoom";
import axios from "axios";
import { API_URL } from "../API";
import dayjs from "dayjs";

const ChatIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  background-color: #ffffff;
  border-radius: 100px;
  position: ${(props) =>
    props.fixedScroll
      ? "absolute"
      : props.defaultScroll
      ? "absolute"
      : "fixed"};
  right: 140px;
  bottom: ${(props) =>
    props.fixedScroll ? null : props.defaultScroll ? null : "60px"};
  top: ${(props) =>
    props.fixedScroll
      ? `${document.body.clientHeight - 80 - 330 - 60}px`
      : props.defaultScroll
      ? `${document.body.clientHeight - 80 - 330 - 60}px`
      : null};
  cursor: pointer;
  box-shadow: 0 4px 20px rgba(2, 22, 51, 0.3);
  z-index: 11;
`;

const Container = styled.div`
  width: 398px;
  height: 620px;
  background-color: #ffffff;
  border-radius: 12px;
  position: ${(props) => (props.fixedScroll ? "absolute" : "fixed")};
  right: 140px;
  bottom: ${(props) => (props.fixedScroll ? null : "60px")};
  top: ${(props) =>
    props.fixedScroll
      ? `${document.body.clientHeight - 620 - 330 - 60}px`
      : props.defaultScroll
      ? `${document.body.clientHeight - 80 - 330 - 60}px`
      : null};
  box-shadow: 0px 4px 20px rgba(2, 22, 51, 0.3);
  padding: 23px 20px;
  z-index: 11;
  overflow: hidden;

  @media (max-width: 1080px) {
    width: 100%;
    right: 0;
    top: 58px;
    border-radius: 0;
    box-shadow: none;
  }
`;
const ChatHeader = styled.div`
  position: relative;
`;
const ChatTab = styled.button`
  position: relative;
  font-size: 16px;
  font-weight: ${(props) => props.theme.fontWeight.medium};
  color: ${(props) => (props.activeColor ? "#021633" : "#ADB5BD")};
  margin-right: 20px;
  background-color: #ffffff;
  border: none;
  border-bottom: 1px solid
    ${(props) => (props.activeColor ? props.theme.color.textColor : "#ffffff")};
  padding-bottom: 4px;
`;
const CloseIcon = styled.img.attrs({
  src: `${ChatCancel}`,
})`
  position: absolute;
  right: 0px;
  top: 0px;
  cursor: pointer;
`;
const ChatTeachersContents = styled.div`
  padding-top: 32px;
`;
const ChatContents = styled.div`
  padding-top: 38px;
`;
const ChatTitleWrap = styled.div`
  width: 100%;
  border-bottom: 1px solid #e9ecef;
  padding-bottom: 12px;
  padding-top: 40px;
`;
const Title = styled.h3`
  font-size: 16px;
  font-weight: ${(props) => props.theme.fontWeight.medium};
  color: ${(props) => props.theme.color.textColor};
`;
const TeacherInfoWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
`;
const TeacherProfile = styled(TeacherInfoWrap)`
  margin-bottom: 0px;
`;
const TeacherIcon = styled.img`
  width: 48px;
  height: 48px;
  object-fit: cover;
  border-radius: 48px;
  background-color: #dde2ea;
  border: none;
  margin-right: 12px;
`;
const TeacherNameWrap = styled.div`
  display: flex;
  flex-direction: column;
`;
const TeacherName = styled.span`
  font-size: 14px;
  font-weight: ${(props) => props.theme.fontWeight.medium};
  color: ${(props) => props.theme.color.textColor};
  line-height: 20px;
  margin-bottom: 6px;
`;
const BadgeWrap = styled.div`
  display: flex;
`;
const TeacherBadge = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 38px;
  height: 18px;
  border-radius: 4px;
  background-color: #dde2ea;
  color: ${(props) => props.theme.color.primary};
  margin-left: 8px;
  &:nth-child(1) {
    margin-left: 0;
  }
`;
const BadgeText = styled.span`
  font-size: 12px;
  font-weight: ${(props) => props.theme.fontWeight.meidum};
`;
const ChatBtn = styled.button`
  width: 84px;
  height: 32px;
  background-color: #6337fc;
  color: ${(props) => props.theme.color.white};
  border-radius: 16px;
  border: none;
`;
const ChatListContainer = styled.div`
  width: 358px;
  height: 105px;
  padding: 20px;
  background-color: ${(props) =>
    props.alarm ? props.theme.color.primary : props.theme.color.white};
  border: ${(props) => (props.alarm ? "0px" : "1px")} solid
    ${(props) => props.theme.color.primary};
  border-radius: 10px;
  margin-bottom: 20px;
  cursor: pointer;
`;
const ChatListWrap = styled.div`
  display: flex;
`;
const ChatListTitleWrap = styled.div`
  margin-bottom: 8px;
`;
const ChatListTitle = styled.span`
  width: calc(100% - 64px);
  font-size: 14px;
  font-weight: ${(props) => props.theme.fontWeight.medium};
  color: ${(props) =>
    props.alarm ? props.theme.color.white : props.theme.color.textColor};
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
`;
const ChatListPreview = styled.span`
  display: inline-block;
  height: 40px;
  font-size: 12px;
  font-weight: ${(props) => props.theme.fontWeight.regular};
  color: ${(props) =>
    props.alarm ? props.theme.color.white : props.theme.color.textColor};
  overflow: hidden;
  line-height: 20px;
`;
const ChatDate = styled.span`
  width: 64px;
  font-size: 14px;
  font-weight: ${(props) => props.theme.fontWeight.medium};
  color: #adb5bd;
  display: inline-block;
`;
const ChatIconImg = styled.img.attrs({
  src: `${ChatIcon44}`,
})`
  width: 44px;
  height: 44px;
`;
const NoneData = styled.div`
  font-size: 16px;
  font-weight: ${(props) => props.theme.fontWeight.medium};
  color: #adb5bd;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;
const NotifyNumber = styled.div`
  font-size: 16px;
  font-weight: ${(props) => props.theme.fontWeight.medium};
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ff383b;
  color: #ffffff;
  min-width: 28px;
  height: 28px;
  border-radius: 28px;
  position: absolute;
  right: 0px;
  top: 0px;
  padding: 0 10px;
`;
const ListNotifyNumber = styled.div`
  font-size: 12px;
  font-weight: ${(props) => props.theme.fontWeight.medium};
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ff383b;
  color: #ffffff;
  min-width: 18px;
  height: 18px;
  border-radius: 18px;
  padding: 0 5px;
  margin-left: 8px;
`;
const NewAlarm = styled.div`
  display: ${(props) => (props.newAlarm ? "block" : "none")};
  width: 4px;
  height: 4px;
  border-radius: 4px;
  background-color: #ff383b;
  position: absolute;
  right: -4px;
  top: -2px;
`;

const Chat = (props) => {
  const [scroll, setScroll] = useState(false);
  const [defaultScroll, setDefalutScroll] = useState(true);
  const [activeTab, setActiveTab] = useState(0);
  const [chatVisible, setChatVisible] = useState(props.isMobile ? true : false);
  const [chatRoomVisible, setChatRoomVisible] = useState(false);
  const [chatIconVisible, setChatIconVisible] = useState(true);
  const [alarmVisible, setAlarmVisible] = useState(true);
  const [chatRooms, setChatRooms] = useState([]);
  const [chatRoomInfo, setChatRoomInfo] = useState(null);
  const [unreadCount, setUnreadCount] = useState(0);
  const [userType, setUserType] = useState("");
  const token = useRecoilValue(tokenState);
  const scoo = document.body.scrollHeight;
  const intervalRef = useRef(null);
  const teachers = chatRooms.filter(
    (chatRoom) => chatRoom.title !== "영재멘토"
  );

  useEffect(() => {
    if (document.body.scrollHeight < 2500) {
      setDefalutScroll(true);
    } else {
      setDefalutScroll(false);
    }
  }, [scroll, defaultScroll, scoo]);

  useEffect(() => {
    axios({
      url: `${API_URL}/mypage`,
      method: "GET",
    }).then((result) => {
      if (result.data.success === true) {
        setUserType(result.data.result.type);
      }
    });
  }, []);

  useEffect(() => {
    if (token) {
      axios({
        method: "GET",
        url: `${API_URL}/chat`,
      }).then((res) => {
        if (res.data.success) {
          setChatRooms(res.data.result.chatRooms);
        }
      });
    }
  }, [chatVisible, token]);

  // useEffect(() => {
  //   if (!token) {
  //     return;
  //   }

  //   intervalRef.current = setInterval(() => {
  //     axios({
  //       method: "GET",
  //       url: `${API_URL}/chat/unreadCount`,
  //     }).then((res) => {
  //       if (res.data.success) {
  //         setUnreadCount(res.data.result.count);
  //       }
  //     });
  //   }, 2000);

  //   return () => {
  //     clearInterval(intervalRef.current);
  //     intervalRef.current = null;
  //   };
  // }, [token]);

  useEffect(() => {
    if (chatVisible === false && chatRoomVisible === false) {
      if (props.onMobileClosed) {
        props.onMobileClosed();
      }
    }
  }, [chatVisible, chatRoomVisible]);

  const updateScroll = () => {
    let scrollHeight = Math.max(
      document.body.scrollHeight,
      document.documentElement.scrollHeight,
      document.body.offsetHeight,
      document.documentElement.offsetHeight,
      document.body.clientHeight,
      document.documentElement.clientHeight
    );
    // 멈추는 기준이 되어야함.
    if (window.scrollY + window.innerHeight >= scrollHeight - 330) {
      setScroll(true);
    } else {
      setScroll(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", updateScroll);
    return () => {
      window.removeEventListener("scroll", updateScroll);
    };
  });

  const tabClickHandler = (idx) => {
    setActiveTab(idx);
  };

  const array = [userType === "TEACHER" ? "학생" : "선생님", "대화 목록"];

  const contents = {
    0: (
      <ChatTeachersContents>
        {teachers.length ? (
          teachers.map((teacher) => (
            <TeacherInfoWrap key={teacher.id}>
              <TeacherProfile>
                <TeacherIcon
                  src={teacher.opponent?.profileImage || NoneProfile}
                />
                <TeacherNameWrap>
                  <TeacherName>{teacher.title}</TeacherName>
                  {/* <BadgeWrap>
                    <TeacherBadge>
                      <BadgeText>수학</BadgeText>
                    </TeacherBadge>
                    <TeacherBadge>
                      <BadgeText>물리</BadgeText>
                    </TeacherBadge>
                  </BadgeWrap> */}
                </TeacherNameWrap>
              </TeacherProfile>
              <ChatBtn
                onClick={() => {
                  setChatRoomVisible(true);
                  setChatRoomInfo(teacher);
                  setChatIconVisible(false);
                  setChatVisible(false);
                }}
              >
                채팅하기
              </ChatBtn>
            </TeacherInfoWrap>
          ))
        ) : (
          <NoneData>목록이 없습니다.</NoneData>
        )}
      </ChatTeachersContents>
    ),

    1: (
      <ChatContents>
        {chatRooms.length ? (
          chatRooms.map((chatRoom) => (
            <ChatListContainer>
              <ChatListWrap
                onClick={() => {
                  setChatRoomVisible(true);
                  setChatRoomInfo(chatRoom);
                  setChatIconVisible(false);
                  setChatVisible(false);
                }}
              >
                <TeacherIcon
                  src={chatRoom.opponent?.profileImage || NoneProfile}
                />
                <div style={{ width: 256 }}>
                  <ChatListTitleWrap>
                    <ChatListTitle>{chatRoom.title}</ChatListTitle>
                    {/* <ListNotifyNumber>1</ListNotifyNumber> */}
                    {chatRoom.lastMessagedAt !== null && (
                      <ChatDate>
                        {dayjs(chatRoom.lastMessagedAt).format("MM월 DD일")}
                      </ChatDate>
                    )}
                  </ChatListTitleWrap>
                  {chatRoom.lastMessage !== null && (
                    <ChatListPreview>{chatRoom.lastMessage}</ChatListPreview>
                  )}
                </div>
              </ChatListWrap>
            </ChatListContainer>
          ))
        ) : (
          <NoneData>목록이 없습니다.</NoneData>
        )}
      </ChatContents>
    ),
  };

  return (
    <>
      {token !== null ? (
        <>
          {chatVisible ? (
            <Container fixedScroll={scroll}>
              <ChatHeader>
                <ul>
                  {array.map((arr, idx) => {
                    return (
                      <ChatTab
                        key={idx}
                        onClick={() => {
                          tabClickHandler(idx);
                        }}
                        activeColor={idx === activeTab ? true : false}
                      >
                        {arr}
                        {idx === 1 && unreadCount > 0 ? (
                          <NewAlarm newAlarm={alarmVisible} />
                        ) : null}
                      </ChatTab>
                    );
                  })}
                </ul>
                <CloseIcon
                  onClick={() => {
                    setChatVisible(false);
                  }}
                />
              </ChatHeader>
              {activeTab === 0 ? (
                <ChatTitleWrap>
                  <Title>
                    {userType === "TEACHER" ? "학생" : "선생님"} (
                    {teachers.length})
                  </Title>
                </ChatTitleWrap>
              ) : null}
              <div>{contents[activeTab]}</div>
            </Container>
          ) : chatIconVisible ? (
            <>
              <ChatIcon
                fixedScroll={scroll}
                onClick={() => {
                  setChatVisible(true);
                }}
              >
                <ChatIconImg />
                {unreadCount > 0 && <NotifyNumber>{unreadCount}</NotifyNumber>}
              </ChatIcon>
            </>
          ) : null}
        </>
      ) : null}
      {chatRoomVisible && chatRoomInfo !== null ? (
        <ChatRoom
          chatRoomInfo={chatRoomInfo}
          fixedScroll={scroll}
          defaultScroll={defaultScroll}
          onClick={() => {
            setChatRoomVisible(false);
            setChatVisible(true);
            setChatIconVisible(true);
          }}
          onCancel={() => {
            setChatRoomVisible(false);
            setChatVisible(false);
            setChatIconVisible(true);
            setActiveTab(0);
          }}
          backChatRoom={(status) => {
            setChatRoomVisible(status);
            setChatVisible(true);
            setActiveTab(1);
          }}
        />
      ) : null}
    </>
  );
};

export default Chat;
