import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Container } from "./style";
import { useRecoilState } from "recoil";
import "swiper/css";
import Header from "../../components/Header";
import SideBar from "../../components/SideBar";
import Footer from "../../components/Footer";
import MentoList from "./components/MentoList";
import searchValueState from "../../states/searchValueState";
import axios from "axios";
import { API_URL } from "../../API";
import { Desktop, Mobile } from "../../utils/mediaQuery";
import MobileBottomBar from "../../components/MobileBottomBar";
import MobileHeader from "../../components/MobileHeader";

const BannerTitle = styled.span`
  font-size: 28px;
  font-weight: ${(props) => props.theme.fontWeight.medium};
  color: ${(props) =>
    props.orange ? props.theme.color.orange : props.theme.color.primary};
  line-height: 41px;
`;
const BannerDesc = styled.p`
  font-size: 16px;
  font-weight: ${(props) => props.theme.fontWeight.regular};
  color: ${(props) => props.theme.color.primary};
  margin-top: 7px;
`;

const MentoPick = (props) => {
  const [searchValue, setSearchValue] = useRecoilState(searchValueState);
  const [mentopickTypes, setMentopickTypes] = useState([]);
  const [currentTab, setCurrentTab] = useState("");
  const [currentTabName, setCurrentTabName] = useState([]);
  const [tabName, setTabName] = useState(0);
  const [searchResult, setSearchResult] = useState("");
  const contents = <MentoList tabName={tabName} searchResult={searchResult} />;
  const ar = [
    {
      id: 0,
      name: "전체",
    },
  ];

  useEffect(() => {
    axios({
      url: `${API_URL}/mentopick/types`,
      method: "GET",
    })
      .then((result) => {
        if (result.data.success) {
          setMentopickTypes(ar.concat(result.data.result.mentopickTypes));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    setCurrentTabName(
      mentopickTypes.filter((item, index) => {
        return currentTab === index;
      })
    );
  }, [currentTab]);

  const types = ["전체", "교과", "비교과"];

  return (
    <>
      <Desktop>
        <Header activeTab={"myMentoPick"} />
        <Container>
          <SideBar
            tabName={(state) => {
              setTabName(state);
              setSearchResult("");
              setSearchValue("");
            }}
            tabIndex={(state) => {
              setCurrentTab(state);
              setSearchResult("");
              setSearchValue("");
            }}
            onClick={() => {
              setSearchResult(searchValue);
              setSearchValue("");
            }}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                setSearchResult(searchValue);
                setSearchValue("");
              }
            }}
            contents={contents}
            array={mentopickTypes}
            bannerTitle={
              <BannerTitle>
                분야별 <BannerTitle orange>상위 1% 전문강사</BannerTitle>를
                만나보세요.
              </BannerTitle>
            }
            bannerDesc={
              <BannerDesc>
                멘토진은 본사 규정에따라 신분 (입졸업 및 재학) 확인과 분야별
                전문성 증빙자료제출 후 면접을 거쳐 엄선된 강사진입니다.
              </BannerDesc>
            }
          >
            나의 멘토픽
          </SideBar>
        </Container>
        <Footer />
      </Desktop>
      <Mobile>
        <MobileHeader name={"나의 멘토픽"} type={"main"} />
        <SideBar
          setTabName={setTabName}
          page={"mentoPick"}
          array={mentopickTypes}
          bannerTitle={
            <h2>
              분야별 <span>상위 1% 전문강사</span>를 만나보세요.
            </h2>
          }
          bannerDesc={
            <p>
              {`멘토진은 본사 규정에따라 신분 (입졸업 및 재학) 확인
              과 분야별 전문성 증빙자료제출 후 면접을 거쳐 엄선된 
              강사진입니다.`}
            </p>
          }
        />

        {contents}
        <MobileBottomBar activeTab={"mentoPick"} />
      </Mobile>
    </>
  );
};

export default MentoPick;
