import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Desktop, Mobile } from "../../utils/mediaQuery";
import {
  Container,
  MainWrap,
  RowWrap,
  Title,
  FormWrap,
  PopupTitle,
  PopupContent,
  RegexCheckText,
  PopupButton,
  FindIdText,
} from "./style";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import TextInput from "../../components/TextInput";
import Button from "../../components/Button";
import Modal from "../../components/Modal";
import { PopupBackground, PopupContainer } from "../../components/Popup";
import prev_btn from "../../assets/images/prev.png";
import { ReactComponent as CheckIcon } from "../../assets/icons/checkbox.svg";

import { API_URL } from "../../API";
import { MobileSignUpHeader } from "../signup/teacher/mobile.style";
import { MobileContainer } from "../login/mobile/login_mobile.style";
import { FindIdPwContainer, MobileFormWrap, MobileTitle } from "./mobile.style";
import { InfoTitle } from "../signup/student/style";
import MobileModal from "../../components/MobileModal";

const FindIDPW = () => {
  const navigate = useNavigate();
  const [idPhoneNumber, setIdPhoneNumber] = useState("");
  const [id, setId] = useState("");
  const [pwPhoneNumber, setPwPhoneNumber] = useState("");
  const [numbersCheck, setNumbersCheck] = useState(null);
  const [firstEnglishCheck, setFirstEnglishCheck] = useState(null);
  const [englishCheck, setEnglishCheck] = useState(null);
  const [specialCharCheck, setSpeialCharCheck] = useState(null);
  const [password, setPassword] = useState("");
  const [passwordCheck, setPasswordCheck] = useState("");
  const [passwordChangeModal, setPasswordChangeModal] = useState(false);
  const [findPasswordFailModal, setFindPasswordFailModal] = useState(false);
  const [findPasswordSuccessModal, setFindPasswordSuccessModal] =
    useState(false);
  const [findPasswordResult, setFindPasswordResult] = useState("");
  // 휴대폰인증
  const [authCompleteModal, setAuthCompleteModal] = useState(false);
  const [authErrorModal, setAuthErrorModal] = useState(false);
  const [authComplete, setAuthComplete] = useState(false);
  const [passwordAuthComplete, setPasswordAuthComplete] = useState(false);
  const [isDuplicateNumber, setIsDuplicateNumber] = useState(false);
  const [wrongPhoneNumber, setWrongPhoneNumber] = useState(false);
  const [phoneNumberAuth, setPhoneNumberAuth] = useState(false);
  const [passwordPhoneNumberAuth, setPasswordPhoneNumberAuth] = useState(false);
  const [phoneNumberAuthCheck, setPhoneNumberAuthCheck] = useState("");
  const [passwordPhoneNumberAuthCheck, setPasswordPhoneNumberAuthCheck] =
    useState("");
  const [unitFocus, setUnitFocus] = useState(false);
  // 인증번호 타이머
  const [min, setMin] = useState(4);
  const [sec, setSec] = useState(0);
  const time = useRef(240);
  const timerId = useRef(null);
  const [countDown, setCountDown] = useState(false);
  const [authModal, setAuthModal] = useState(false);
  const [findIdSuccessModal, setFindIdSuccessModal] = useState(false);
  const [findIdResult, setFindIdResult] = useState("");
  const [findIdFailModal, setFindIdFailModal] = useState(false);

  // 휴대폰인증 타이머 스타트
  const start = () => {
    timerId.current = setInterval(() => {
      time.current -= 1;
      setMin(parseInt(time.current / 60));
      setSec(time.current % 60);
      if (time.current < 61) {
        setCountDown(true);
      } else {
        setCountDown(false);
      }
    }, 1000);
  };

  useEffect(() => {
    if (time.current === 0) {
      clearInterval(timerId.current);
      setCountDown(false);
      time.current = 240;
      setAuthModal(true);
    }
  }, [sec]);

  // 휴대폰 인증하기
  const phoneNumberAuthHandler = (phoneNumber, type) => {
    if (phoneNumber !== "") {
      axios({
        url: `${API_URL}/user/sms/send`,
        method: "POST",
        data: {
          phoneNumber: phoneNumber,
          type: "FIND",
        },
      })
        .then((result) => {
          if (result.data.success) {
            if (type === "password") {
              setPasswordPhoneNumberAuth(true);
            } else {
              setPhoneNumberAuth(true);
            }
            if (time.current < 240) {
              clearInterval(timerId.current);
              time.current = 240;
              start();
            } else {
              clearInterval(timerId.current);
              start();
            }
          } else if (
            result.data.error.message === "이미 가입된 휴대전화번호입니다."
          ) {
            setIsDuplicateNumber(true);
          } else {
            setWrongPhoneNumber(true);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const phoneNumberAuthCheckHandler = (phoneNumber, type, authCode) => {
    if (time.current < 240) {
      clearInterval(timerId.current);
      time.current = 240;
    } else {
      clearInterval(timerId.current);
    }

    axios({
      url: `${API_URL}/user/sms/verify`,
      method: "POST",
      data: {
        phoneNumber: phoneNumber,
        authCode: authCode,
      },
    })
      .then((result) => {
        if (result.data.success) {
          if (type === "password") {
            setPasswordAuthComplete(true);
            findPasswrod();
          } else {
            setAuthComplete(true);
            findId();
          }
          setAuthCompleteModal(true);
        } else {
          setAuthErrorModal(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const findId = () => {
    axios({
      url: `${API_URL}/user/find/id`,
      method: "POST",
      data: {
        phoneNumber: idPhoneNumber,
        authCode: phoneNumberAuthCheck,
      },
    })
      .then((result) => {
        if (result.data.success) {
          setFindIdResult(result.data.result.email);
          setFindIdSuccessModal(true);
        } else {
          setFindIdResult(result.data.error.message);
          setFindIdFailModal(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const findPasswrod = () => {
    axios({
      url: `${API_URL}/user/find/password`,
      method: "POST",
      data: {
        phoneNumber: pwPhoneNumber,
        authCode: passwordPhoneNumberAuthCheck,
        email: id,
      },
    })
      .then((result) => {
        if (result.data.success) {
          setPasswordChangeModal(true);
        } else {
          setFindPasswordFailModal(true);
          setFindPasswordResult(result.data.error.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const changePassword = () => {
    axios({
      url: `${API_URL}/user/find/password/change`,
      method: "POST",
      data: {
        phoneNumber: pwPhoneNumber,
        authCode: passwordPhoneNumberAuthCheck,
        email: id,
        password: password,
      },
    })
      .then((result) => {
        if (result.data.success) {
          setFindPasswordSuccessModal(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    const numbersCheck = /[0-9]/.test(password);
    const englishCheck = /^[a-zA-Z]/.test(password);
    const firstEnglishCheck = /^[a-zA-Z]/.test(password.at(0));
    const specialCharCheck = /(?=.*[$@$!%*#?&])/.test(password);

    if (password) {
      if (numbersCheck === true) {
        setNumbersCheck(true);
      } else {
        setNumbersCheck(false);
      }

      if (englishCheck === true) {
        setEnglishCheck(true);
      } else {
        setEnglishCheck(false);
      }

      if (specialCharCheck === true) {
        setSpeialCharCheck(true);
      } else {
        setSpeialCharCheck(false);
      }

      setFirstEnglishCheck(firstEnglishCheck);
    } else {
      setNumbersCheck(null);
      setEnglishCheck(null);
      setSpeialCharCheck(null);
      setFirstEnglishCheck(null);
    }
  }, [
    password,
    firstEnglishCheck,
    numbersCheck,
    englishCheck,
    specialCharCheck,
  ]);

  return (
    <>
      <Desktop>
        <Header activeTab={"Login"} />
        <Container>
          <MainWrap>
            <FormWrap>
              <Title>아이디 찾기</Title>
              <RowWrap marginBottom={phoneNumberAuth ? "20px" : "80px"}>
                <TextInput
                  disabled={authComplete}
                  type="number"
                  style={{ width: 422 }}
                  value={idPhoneNumber}
                  onChange={(value) => {
                    setIdPhoneNumber(value);
                  }}
                  placeholder={"(-) 제외한 숫자만 입력"}
                />
                <Button
                  grey={phoneNumberAuth}
                  active={!phoneNumberAuth}
                  style={{ marginLeft: 16, width: 160 }}
                  onClick={() => {
                    phoneNumberAuthHandler(idPhoneNumber);
                  }}
                  disabled={authComplete}
                >
                  {phoneNumberAuth ? "재요청" : "인증요청"}
                </Button>
              </RowWrap>
              {phoneNumberAuth ? (
                <RowWrap>
                  <TextInput
                    type="number"
                    style={{ width: 422 }}
                    unitAuth={
                      <div>
                        {min}:{sec < 10 ? `0${sec}` : sec}
                      </div>
                    }
                    countDown={countDown}
                    unitFocus={unitFocus}
                    onFocus={() => {
                      setUnitFocus(true);
                    }}
                    onBlur={() => {
                      setUnitFocus(false);
                    }}
                    value={phoneNumberAuthCheck}
                    onChange={(value) => {
                      setPhoneNumberAuthCheck(value);
                    }}
                  />
                  <Button
                    active={!authComplete}
                    disabled={authComplete}
                    style={{ marginLeft: 16, width: 160 }}
                    onClick={() => {
                      phoneNumberAuthCheckHandler(
                        idPhoneNumber,
                        "id",
                        phoneNumberAuthCheck
                      );
                    }}
                  >
                    인증확인
                  </Button>
                </RowWrap>
              ) : null}
              <Button
                active={authComplete}
                grey={!authComplete}
                onClick={findId}
              >
                아이디 찾기
              </Button>
            </FormWrap>
            <Title>비밀번호 찾기</Title>
            <TextInput
              style={{ width: "100%" }}
              value={id}
              onChange={(value) => {
                setId(value);
              }}
              placeholder={"아이디 입력"}
            />
            <RowWrap marginBottom={passwordPhoneNumberAuth ? "20px" : "80px"}>
              <TextInput
                disabled={passwordAuthComplete}
                type="number"
                style={{ width: 422 }}
                value={pwPhoneNumber}
                onChange={(value) => {
                  setPwPhoneNumber(value);
                }}
                placeholder={"(-) 제외한 숫자만 입력"}
              />
              <Button
                grey={passwordPhoneNumberAuth}
                active={!passwordPhoneNumberAuth}
                style={{ marginLeft: 16, width: 160 }}
                onClick={() => {
                  phoneNumberAuthHandler(pwPhoneNumber, "password");
                }}
                disabled={passwordAuthComplete}
              >
                {passwordPhoneNumberAuth ? "재요청" : "인증요청"}
              </Button>
            </RowWrap>
            {passwordPhoneNumberAuth ? (
              <RowWrap>
                <TextInput
                  type="number"
                  style={{ width: 422 }}
                  unitAuth={
                    <div>
                      {min}:{sec < 10 ? `0${sec}` : sec}
                    </div>
                  }
                  countDown={countDown}
                  unitFocus={unitFocus}
                  onFocus={() => {
                    setUnitFocus(true);
                  }}
                  onBlur={() => {
                    setUnitFocus(false);
                  }}
                  value={passwordPhoneNumberAuthCheck}
                  onChange={(value) => {
                    setPasswordPhoneNumberAuthCheck(value);
                  }}
                />
                <Button
                  active={!passwordAuthComplete}
                  disabled={passwordAuthComplete}
                  style={{ marginLeft: 16, width: 160 }}
                  onClick={() => {
                    phoneNumberAuthCheckHandler(
                      pwPhoneNumber,
                      "password",
                      passwordPhoneNumberAuthCheck
                    );
                  }}
                >
                  인증확인
                </Button>
              </RowWrap>
            ) : null}
            <Button
              active={passwordAuthComplete}
              grey={!passwordAuthComplete}
              onClick={() => {
                // setPasswordChangeModal(true);
                findPasswrod();
              }}
            >
              비밀번호 찾기
            </Button>
          </MainWrap>
          <Footer />
        </Container>
        {passwordChangeModal && (
          <PopupBackground>
            <PopupContainer style={{ textAlign: "center", padding: 0 }}>
              <div style={{ padding: "80px 48px 0px" }}>
                <PopupTitle>비밀번호 변경하기</PopupTitle>
                <PopupContent>새로운 비밀번호를 입력해주세요.</PopupContent>
                <TextInput
                  style={{ width: "100%", marginBottom: 8 }}
                  value={password}
                  onChange={(value) => {
                    setPassword(value);
                  }}
                  placeholder={"8~20자 입력"}
                  maxLength={20}
                  type={"password"}
                />
                <RowWrap style={{ marginBottom: 16 }}>
                  <CheckIcon
                    style={{ width: 18, heigth: 18 }}
                    color={
                      firstEnglishCheck === null
                        ? "#ADB5BD"
                        : firstEnglishCheck === true
                        ? "#0081F5"
                        : "#FF383B"
                    }
                  />
                  <RegexCheckText error={firstEnglishCheck}>
                    첫 글자 영문
                  </RegexCheckText>
                  <CheckIcon
                    style={{ width: 18, heigth: 18 }}
                    color={
                      englishCheck === null
                        ? "#ADB5BD"
                        : englishCheck === true
                        ? "#0081F5"
                        : "#FF383B"
                    }
                  />
                  <RegexCheckText error={englishCheck}>영문</RegexCheckText>
                  <CheckIcon
                    style={{ width: 18, heigth: 18 }}
                    color={
                      numbersCheck === null
                        ? "#ADB5BD"
                        : numbersCheck === true
                        ? "#0081F5"
                        : "#FF383B"
                    }
                  />
                  <RegexCheckText error={numbersCheck}>숫자</RegexCheckText>
                  <CheckIcon
                    style={{ width: 18, heigth: 18 }}
                    color={
                      specialCharCheck === null
                        ? "#ADB5BD"
                        : specialCharCheck === true
                        ? "#0081F5"
                        : "#FF383B"
                    }
                  />
                  <RegexCheckText error={specialCharCheck}>
                    특수문자
                  </RegexCheckText>
                </RowWrap>
                <div style={{ textAlign: "left" }}>
                  <TextInput
                    style={{ width: "100%" }}
                    value={passwordCheck}
                    onChange={(value) => {
                      setPasswordCheck(value);
                    }}
                    placeholder={"비밀번호 확인"}
                    maxLength={20}
                    type={"password"}
                    label={
                      passwordCheck !== ""
                        ? password === passwordCheck
                          ? "비밀번호가 일치합니다."
                          : "비밀번호가 일치하지 않습니다."
                        : undefined
                    }
                    error={password !== passwordCheck}
                    marginBottom={passwordCheck !== "" ? 52 : 80}
                  />
                </div>
              </div>
              <PopupButton
                onClick={changePassword}
                active={
                  password === passwordCheck &&
                  englishCheck &&
                  specialCharCheck &&
                  numbersCheck
                }
              >
                비밀번호 변경하기
              </PopupButton>
            </PopupContainer>
          </PopupBackground>
        )}
        {authModal ? (
          <Modal
            padding
            title={"입력시간 초과"}
            content={"인증번호 입력시간이 초과되었습니다. 다시 입력해주세요."}
            button={[
              {
                title: "재전송",
                type: "active",
              },
            ]}
            onPress={() => {
              setAuthModal(false);
              start();
            }}
          />
        ) : null}
        {authCompleteModal ? (
          <Modal
            padding
            title={"인증완료"}
            content={"인증이 완료되었습니다"}
            button={[
              {
                title: "확인",
                type: "active",
              },
            ]}
            onPress={() => {
              setAuthCompleteModal(false);
            }}
          />
        ) : null}
        {authErrorModal ? (
          <Modal
            padding
            title={"인증 실패"}
            content={"입력한 인증번호가 일치하지 않습니다. 다시 입력해주세요."}
            button={[
              {
                title: "확인",
                type: "active",
              },
            ]}
            onPress={() => {
              setAuthErrorModal(false);
            }}
          />
        ) : null}
        {wrongPhoneNumber ? (
          <Modal
            padding
            title={"오류"}
            content={"잘못된 휴대전화번호입니다."}
            button={[
              {
                title: "확인",
                type: "active",
              },
            ]}
            onPress={() => {
              setWrongPhoneNumber(false);
            }}
          />
        ) : null}
        {findIdSuccessModal ? (
          <Modal
            padding
            title={"아이디 찾기 완료"}
            htmlContnet={
              <FindIdText>
                회원님의 아이디는 <FindIdText yellow>{findIdResult}</FindIdText>
                입니다.
              </FindIdText>
            }
            button={[
              {
                title: "확인",
                type: "active",
              },
            ]}
            onPress={() => {
              setFindIdSuccessModal(false);
            }}
          />
        ) : null}
        {findIdFailModal ? (
          <Modal
            padding
            title={"아이디 찾기 실패"}
            content={findIdResult}
            button={[
              {
                title: "확인",
                type: "active",
              },
            ]}
            onPress={() => {
              setFindIdFailModal(false);
            }}
          />
        ) : null}
        {findPasswordFailModal ? (
          <Modal
            padding
            title={"비밀번호 찾기 실패"}
            content={findPasswordResult}
            button={[
              {
                title: "확인",
                type: "active",
              },
            ]}
            onPress={() => {
              setFindPasswordFailModal(false);
            }}
          />
        ) : null}
        {findPasswordSuccessModal ? (
          <Modal
            padding
            title={"비밀번호 변경완료"}
            content={"비밀번호 변경이 완료되었습니다."}
            button={[
              {
                title: "로그인하기",
                type: "active",
              },
            ]}
            onPress={() => {
              setPasswordChangeModal(false);
              setFindPasswordSuccessModal(false);
              navigate("/login");
            }}
          />
        ) : null}
      </Desktop>
      <Mobile>
        {!passwordChangeModal ? (
          <>
            <MobileSignUpHeader>
              <button
                onClick={() => {
                  navigate("/login");
                }}
              >
                <img src={prev_btn} alt="prev" />
              </button>
              <h2>아이디/비밀번호 찾기</h2>
            </MobileSignUpHeader>
            <FindIdPwContainer>
              <MobileFormWrap>
                <MobileTitle>아이디 찾기</MobileTitle>
                <TextInput
                  style={{ width: 342 }}
                  value={idPhoneNumber}
                  onChange={(value) => {
                    setIdPhoneNumber(value);
                  }}
                  placeholder={"(-) 제외한 숫자만 입력"}
                  maxLength={11}
                  disabled={authComplete}
                />
                <Button
                  grey={phoneNumberAuth}
                  active={!phoneNumberAuth}
                  style={{ width: 342 }}
                  onClick={() => {
                    phoneNumberAuthHandler(idPhoneNumber, "FIND");
                  }}
                  disabled={authComplete}
                >
                  {phoneNumberAuth ? "재요청" : "인증요청"}
                </Button>
                {phoneNumberAuth && (
                  <>
                    <TextInput
                      type="numberic"
                      style={{ marginBottom: 37, marginTop: 16, width: 342 }}
                      unitAuth={
                        <div style={{ color: "#FF383B", marginTop: 60 }}>
                          남은시간 : {min}:{sec < 10 ? `0${sec}` : sec}
                        </div>
                      }
                      countDown={countDown}
                      unitFocus={unitFocus}
                      onFocus={() => {
                        setUnitFocus(true);
                      }}
                      onBlur={() => {
                        setUnitFocus(false);
                      }}
                      value={phoneNumberAuthCheck}
                      onChange={(value) => {
                        setPhoneNumberAuthCheck(value);
                      }}
                    />
                    <Button
                      active={!authComplete}
                      style={{ width: 342 }}
                      onClick={() => {
                        phoneNumberAuthCheckHandler(
                          idPhoneNumber,
                          "FIND",
                          phoneNumberAuthCheck
                        );
                      }}
                      disabled={authComplete}
                    >
                      인증확인
                    </Button>
                  </>
                )}
              </MobileFormWrap>
              <MobileFormWrap>
                <MobileTitle>비밀번호 찾기</MobileTitle>
                <TextInput
                  style={{ width: "100%" }}
                  value={id}
                  onChange={(value) => {
                    setId(value);
                  }}
                  placeholder={"아이디 입력"}
                />
                <TextInput
                  style={{ width: 342 }}
                  value={pwPhoneNumber}
                  onChange={(value) => {
                    setPwPhoneNumber(value);
                  }}
                  placeholder={"(-) 제외한 숫자만 입력"}
                  maxLength={11}
                  disabled={authComplete}
                />
                <Button
                  grey={passwordPhoneNumberAuth}
                  active={!passwordPhoneNumberAuth}
                  style={{ width: 342 }}
                  onClick={() => {
                    phoneNumberAuthHandler(pwPhoneNumber, "password");
                  }}
                  disabled={authComplete}
                >
                  {passwordPhoneNumberAuth ? "재요청" : "인증요청"}
                </Button>
                {passwordPhoneNumberAuth && (
                  <>
                    <TextInput
                      type="numberic"
                      style={{ marginBottom: 37, marginTop: 16, width: 342 }}
                      unitAuth={
                        <div style={{ color: "#FF383B", marginTop: 60 }}>
                          남은시간 : {min}:{sec < 10 ? `0${sec}` : sec}
                        </div>
                      }
                      countDown={countDown}
                      unitFocus={unitFocus}
                      onFocus={() => {
                        setUnitFocus(true);
                      }}
                      onBlur={() => {
                        setUnitFocus(false);
                      }}
                      value={passwordPhoneNumberAuthCheck}
                      onChange={(value) => {
                        setPasswordPhoneNumberAuthCheck(value);
                      }}
                    />
                    <Button
                      active={!authComplete}
                      style={{ width: 342 }}
                      onClick={() => {
                        phoneNumberAuthCheckHandler(
                          pwPhoneNumber,
                          "password",
                          passwordPhoneNumberAuthCheck
                        );
                      }}
                      disabled={authComplete}
                    >
                      인증확인
                    </Button>
                  </>
                )}
              </MobileFormWrap>
            </FindIdPwContainer>
          </>
        ) : (
          <>
            <MobileSignUpHeader>
              <button
                onClick={() => {
                  navigate("/login");
                }}
              >
                <img src={prev_btn} alt="prev" />
              </button>
              <h2>비밀번호 변경</h2>
            </MobileSignUpHeader>
            <FindIdPwContainer>
              <div style={{ marginTop: 24 }}>
                <PopupTitle>비밀번호 변경하기</PopupTitle>
                <PopupContent>새로운 비밀번호를 입력해주세요.</PopupContent>
                <TextInput
                  style={{ width: "100%", marginBottom: 8 }}
                  value={password}
                  onChange={(value) => {
                    setPassword(value);
                  }}
                  placeholder={"8~20자 입력"}
                  maxLength={20}
                  type={"password"}
                />
                <RowWrap style={{ marginBottom: 16 }}>
                  <CheckIcon
                    style={{ width: 18, heigth: 18 }}
                    color={
                      firstEnglishCheck === null
                        ? "#ADB5BD"
                        : firstEnglishCheck === true
                        ? "#0081F5"
                        : "#FF383B"
                    }
                  />
                  <RegexCheckText error={firstEnglishCheck}>
                    첫 글자 영문
                  </RegexCheckText>
                  <CheckIcon
                    style={{ width: 18, heigth: 18 }}
                    color={
                      englishCheck === null
                        ? "#ADB5BD"
                        : englishCheck === true
                        ? "#0081F5"
                        : "#FF383B"
                    }
                  />
                  <RegexCheckText error={englishCheck}>영문</RegexCheckText>
                  <CheckIcon
                    style={{ width: 18, heigth: 18 }}
                    color={
                      numbersCheck === null
                        ? "#ADB5BD"
                        : numbersCheck === true
                        ? "#0081F5"
                        : "#FF383B"
                    }
                  />
                  <RegexCheckText error={numbersCheck}>숫자</RegexCheckText>
                  <CheckIcon
                    style={{ width: 18, heigth: 18 }}
                    color={
                      specialCharCheck === null
                        ? "#ADB5BD"
                        : specialCharCheck === true
                        ? "#0081F5"
                        : "#FF383B"
                    }
                  />
                  <RegexCheckText error={specialCharCheck}>
                    특수문자
                  </RegexCheckText>
                </RowWrap>
                <div style={{ textAlign: "left" }}>
                  <TextInput
                    style={{ width: "100%" }}
                    value={passwordCheck}
                    onChange={(value) => {
                      setPasswordCheck(value);
                    }}
                    placeholder={"비밀번호 확인"}
                    maxLength={20}
                    type={"password"}
                    label={
                      passwordCheck !== ""
                        ? password === passwordCheck
                          ? "비밀번호가 일치합니다."
                          : "비밀번호가 일치하지 않습니다."
                        : undefined
                    }
                    error={password !== passwordCheck}
                    marginBottom={0}
                  />
                </div>
              </div>
              <PopupButton
                style={{
                  fontSize: "16px",
                  width: "342px",
                  height: "56px",
                  borderRadius: "4px",
                }}
                onClick={() => {
                  changePassword();
                }}
                active={
                  password === passwordCheck &&
                  englishCheck &&
                  specialCharCheck &&
                  numbersCheck
                }
              >
                비밀번호 변경하기
              </PopupButton>
            </FindIdPwContainer>
          </>
        )}

        {authModal && (
          <MobileModal
            padding
            title={"입력시간 초과"}
            content={`인증번호 입력시간이 초과되었습니다.
            다시 입력해주세요.`}
            button={[
              {
                title: "재전송",
                type: "active",
              },
            ]}
            onPress={() => {
              setAuthModal(false);
              start();
            }}
          />
        )}
        {authErrorModal && (
          <MobileModal
            padding
            title={"인증 실패"}
            content={`입력한 인증번호가 일치하지 않습니다.
            다시 입력해주세요.`}
            button={[
              {
                title: "확인",
                type: "active",
              },
            ]}
            onPress={() => {
              setAuthErrorModal(false);
            }}
          />
        )}
        {wrongPhoneNumber && (
          <MobileModal
            padding
            title={"오류"}
            content={"잘못된 휴대전화번호입니다."}
            button={[
              {
                title: "확인",
                type: "active",
              },
            ]}
            onPress={() => {
              setWrongPhoneNumber(false);
            }}
          />
        )}
        {findIdSuccessModal && (
          <MobileModal
            padding
            title={"아이디 찾기 완료"}
            htmlContnet={
              <FindIdText>
                회원님의 아이디는 <FindIdText yellow>{findIdResult}</FindIdText>
                입니다.
              </FindIdText>
            }
            button={[
              {
                title: "확인",
                type: "active",
              },
            ]}
            onPress={() => {
              setFindIdSuccessModal(false);
            }}
          />
        )}
        {findIdFailModal && (
          <MobileModal
            padding
            title={"아이디 찾기 실패"}
            content={findIdResult}
            button={[
              {
                title: "확인",
                type: "active",
              },
            ]}
            onPress={() => {
              setFindIdFailModal(false);
            }}
          />
        )}
        {findPasswordFailModal && (
          <MobileModal
            padding
            title={"비밀번호 찾기 실패"}
            content={findPasswordResult}
            button={[
              {
                title: "확인",
                type: "active",
              },
            ]}
            onPress={() => {
              setFindPasswordFailModal(false);
            }}
          />
        )}
        {findPasswordSuccessModal && (
          <MobileModal
            padding
            title={"비밀번호 변경완료"}
            content={"비밀번호 변경이 완료되었습니다."}
            button={[
              {
                title: "확인",
                type: "active",
              },
            ]}
            onPress={() => {
              setPasswordChangeModal(false);
              setFindPasswordSuccessModal(false);
              navigate("/login");
            }}
          />
        )}
      </Mobile>
    </>
  );
};

export default FindIDPW;
