import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import axios from "axios";
import { Desktop, Mobile } from "../../utils/mediaQuery";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Tab from "../../components/Tab";
import Button from "../../components/Button";
import DatePicker from "../../components/DatePicker";
import Modal from "../../components/Modal";
import runBootPay from "../../components/BootPay";
import ProfileDetail from "./tabs/ProfileDetail";
import Reputation from "./tabs/Reputation";
import LectureVideo from "./tabs/LectureVideo";
import { ReactComponent as Cancel } from "../../assets/icons/cancle_44px.svg";
import { API_URL } from "../../API";
import formatSubject from "../../utils/formatSubject";
import MobileHeader from "../../components/MobileHeader";
import {
  MobileOtContainer,
  MobileProfileContainer,
  MobileProfileDetailRecoder,
  MobileProfileImgWrapper,
  MobileProfileInfo,
  MobileProfileTab,
} from "./mobile.style";
import MobileModal from "../../components/MobileModal";
import { FreeMode, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
const Container = styled.div`
  width: 100%;
  padding: 200px 278px 260px 278px;
  display: flex;
  @media screen and (max-width: 1280px) {
    padding: 200px 40px 260px 40px;
    width: 1200px;
  }
`;
const ProfileWrap = styled.div`
  min-width: 398px;
  min-height: 723px;
  height: 100%;
  border: 1px solid #dee2e6;
  border-radius: 10px;
  padding: 32px;
  position: sticky;
  top: 100px;
`;
const ProfileImgWrap = styled.div`
  width: 334px;
  height: 334px;
  border-radius: 10px;
  overflow: hidden;
`;
const ProfileImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
const ProfileTitle = styled.h3`
  font-size: 28px;
  font-weight: ${(props) => props.theme.fontWeight.bold};
  color: ${(props) => props.theme.color.textColor};
  line-height: 32px;
  margin-top: 32px;
  margin-bottom: 20px;
`;
const ProfileBadgeWrap = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: ${(props) => (props.marginBottom ? "16px" : "0px")};
  width: 332px;
`;
const ProfileBadge = styled.div`
  min-width: 58px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.color.primary};
  color: ${(props) => props.theme.color.white};
  font-size: 14px;
  font-weight: ${(props) => props.theme.fontWeight.medium};
  border-radius: 4px;
  margin-right: 12px;
`;
const ProfileContentsTextWrap = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  span {
    :nth-child(n + 2) {
      margin-top: 12px;
    }
  }
`;
const EllipsisWrap = styled.span`
  flex-direction: column;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 262px;
`;
const ProfileContentsText = styled.span`
  font-size: 16px;
  font-weight: ${(props) => props.theme.fontWeight.medium};
  color: ${(props) => props.theme.color.textColor};
  line-height: 24px;
  margin-top: 0px;
  width: 274px;
`;
const TabWrap = styled.div`
  min-width: 812px;
  margin-right: 154px;
  width: 100%;
  @media screen and (max-width: 1280px) {
    margin-right: 54px;
    min-width: 748px;
  }
`;
const ButtonWrap = styled.div`
  width: 100%;
  margin-top: 32px;
  padding-top: 32px;
  border-top: 2px solid #e9ecef;
`;
const PopupBackground = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 900;
  background-color: rgba(33, 37, 41, 0.8);
`;
const PopupContainer = styled.div`
  width: 812px;
  min-height: 798px;
  border-radius: 16px;
  background-color: #ffffff;
  z-index: 901;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 48px 48px 44px 48px;
  @media screen and (max-width: 1280px) {
    overflow-y: scroll;
    min-height: 700px;
    max-height: 700px;
  }
`;
const PopupTitle = styled.span`
  display: inline-block;
  font-size: 24px;
  font-weight: ${(props) => props.theme.fontWeight.bold};
  color: ${(props) => props.theme.color.primary};
  padding-bottom: 7px;
  line-height: 36px;
  border-bottom: 1px solid ${(props) => props.theme.color.primary};
  margin-bottom: 32px;
  -ms-user-select: none;
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
`;
const PopupBadge = styled.div`
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: ${(props) => props.theme.fontWeight.medium};
  color: ${(props) => props.theme.color.primary};
  background-color: #dde2ea;
  border-radius: 8px;
  padding: 6px 20px;
  height: 32px;
  margin-bottom: 20px;
`;
const PopupDesc = styled.span`
  display: inline-block;
  font-size: 16px;
  font-weight: ${(props) => props.theme.fontWeight.medium};
  color: ${(props) =>
    props.orange ? props.theme.color.orange : props.theme.color.textColor};
  margin-bottom: 48px;
  height: 24px;
  -ms-user-select: none;
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
`;
const PopupBtnWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 32px auto 0;
`;

export const OtBtn = styled.div`
  position: fixed;
  bottom: 0;
  z-index: 999;
  margin: 0 !important;
  width: 100%;
  text-align: center;
  height: 87px;
  left: 50%;
  background-color: white;
  transform: translateX(-50%);
  padding: 16px 24px;

  button {
    border: none;
    border-radius: 4px;
    background: #021633;
    border-radius: 4px;
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
    text-align: center;
    color: white;
    width: 342px;
    height: 55px;
  }
`;

const MentoProfile = () => {
  const { profileId } = useParams();
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const locationName = location.pathname;
  const [openOTApply, setOpenOTApply] = useState(false);
  const [freeMember, setFreeMember] = useState(false);
  const [teacherModal, setTeacherModal] = useState(false);
  const [modal, setModal] = useState(false);
  const [data, setData] = useState([]);
  const [myData, setMyData] = useState(null);
  const [selectDate, setSelectDate] = useState({
    date: "",
    time: "",
  });
  const [possibleDates, setPossibleDates] = useState([]);

  const array = ["프로필", "평판", "강의영상"];
  const arrayMobile = ["프로필", "평판", "강의영상", "등록한 파일 "];
  const [currentArray, setCurrentArray] = useState("프로필");
  const contents = {
    0: <ProfileDetail />,
    1: <Reputation />,
    2: (
      <>
        {locationName === `/mentoPick/profile/${params.profileId}` ? (
          <LectureVideo profileId={profileId} />
        ) : (
          <Outlet />
        )}
      </>
    ),
  };

  useEffect(() => {
    axios({
      url: `${API_URL}/mentopick/${profileId}/profile`,
      method: "GET",
    })
      .then((result) => {
        if (result.data.success === true) {
          setData(result.data.result);
          setPossibleDates(result.data.result.dates);
        }
      })
      .catch((error) => {
        console.log(error);
      });

    axios({
      url: `${API_URL}/mypage`,
      method: "GET",
    })
      .then((result) => {
        if (result.data.success === true) {
          setMyData(result.data.result);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const paymentOT = () => {
    runBootPay(
      data.otPrice,
      `영재멘토 OT 신청 (${data.name})`,
      `OT 신청 (${data.name})`,
      myData.nickname,
      "",
      myData.userInfo.area,
      myData.userInfo.phoneNumber,
      () => {},
      (onFinish) => {
        sendOT(onFinish);
      }
    );
  };

  const paidmemberPayment = () => {
    runBootPay(
      110000,
      "영재멘토",
      "영재멘토 유료회원",
      myData.nickname,
      "",
      myData.userInfo.area,
      myData.userInfo.phoneNumber,
      () => {},
      (onFinish) => {
        console.log(onFinish);
      }
    );
  };

  const sendOT = (receipt_id) => {
    let date = `${selectDate.date} ${selectDate.time}:00`;
    if (selectDate.date !== "" && selectDate.time !== "")
      axios({
        url: `${API_URL}/mentopick/${profileId}/ot`,
        method: "POST",
        data: {
          date: date,
          receiptId: receipt_id,
        },
      })
        .then((result) => {
          if (result.data.success === true) {
            setModal(true);
            setOpenOTApply(false);
          } else if (result.data.success === false) {
            console.log("Error: ", result.data.error?.message);
          }
        })
        .catch((error) => {
          console.log("ERROR message: ", error);
        });
  };

  return (
    <>
      <Desktop>
        <div>
          {myData && openOTApply ? (
            freeMember ? null : (
              <PopupBackground />
            )
          ) : null}
          <Header activeTab={"myMentoPick"} />
          <Container>
            <TabWrap>
              <Tab array={array} contents={contents} />
            </TabWrap>
            <ProfileWrap>
              <ProfileImgWrap>
                <ProfileImg
                  src={
                    data.profile ? data.profile : "/images/default_profile.png"
                  }
                />
              </ProfileImgWrap>

              <ProfileTitle>{data.name}</ProfileTitle>

              <ProfileBadgeWrap marginBottom>
                <ProfileBadge>과목</ProfileBadge>
                {data.subjects && (
                  <ProfileContentsText>
                    {data.subjects
                      .map((subjectItem) => formatSubject(subjectItem.subject))
                      .join(", ")}
                  </ProfileContentsText>
                )}
              </ProfileBadgeWrap>

              <ProfileBadgeWrap>
                <ProfileBadge>학력</ProfileBadge>
                <ProfileContentsTextWrap>
                  {data.educations &&
                    data.educations.map((item) =>
                      item.institute.type === "UNIVERSITY" ? (
                        <EllipsisWrap>
                          <ProfileContentsText key={item}>
                            {`${item.institute.name} ${item.institute.major}`}
                          </ProfileContentsText>
                        </EllipsisWrap>
                      ) : (
                        <EllipsisWrap>
                          <ProfileContentsText key={item.id}>
                            {item.institute.name}
                          </ProfileContentsText>
                        </EllipsisWrap>
                      )
                    )}
                </ProfileContentsTextWrap>
              </ProfileBadgeWrap>

              <ButtonWrap>
                <Button
                  active
                  onClick={() => {
                    document.body.style.overflow = "hidden";
                    setOpenOTApply(true);
                  }}
                >
                  OT 신청하기
                </Button>
              </ButtonWrap>
            </ProfileWrap>
          </Container>
          {openOTApply
            ? myData &&
              (myData.type === "TEACHER" ? (
                <Modal
                  title={"죄송합니다."}
                  content={"해당 서비스는 학생/학부모 회원만 이용가능합니다."}
                  button={[
                    {
                      title: "닫기",
                      type: "active",
                    },
                  ]}
                  onClose={() => {
                    setOpenOTApply(false);
                  }}
                  onPress={() => {
                    setOpenOTApply(false);
                    document.body.style.overflow = "auto";
                  }}
                />
              ) : myData.isPaid === false ? (
                <Modal
                  title={"죄송합니다."}
                  content={"해당 서비스는 유료회원만 이용 가능합니다."}
                  button={[
                    {
                      title: "닫기",
                      type: "cancel",
                    },
                    {
                      title: "유료회원 결제하기",
                      type: "active",
                    },
                  ]}
                  onClose={() => {
                    setOpenOTApply(false);
                  }}
                  onPress={() => {
                    paidmemberPayment();
                  }}
                />
              ) : (
                <PopupContainer>
                  <Cancel
                    style={{
                      position: "absolute",
                      top: 24,
                      right: 24,
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      document.body.style.overflow = "auto";
                      setOpenOTApply(false);
                    }}
                  />
                  <PopupTitle>OT 신청하기</PopupTitle>
                  <PopupBadge>안내사항</PopupBadge>
                  <PopupDesc>
                    OT 신청의 경우{" "}
                    <PopupDesc orange style={{ marginBottom: 0 }}>
                      {`${
                        data.otPrice &&
                        data.otPrice
                          .toString()
                          .replace(/(\d)(?=(?:\d{3})+(?!\d))/g, "$1,")
                      }원`}
                    </PopupDesc>
                    의 신청금이 청구됩니다.
                    <br />
                    OT 신청은{" "}
                    <span style={{ color: "red" }}>OT 진행일 1일 전</span>까지
                    취소 가능합니다.
                  </PopupDesc>
                  <PopupBadge>날짜 / 시간 선택</PopupBadge>
                  <DatePicker
                    possibleDate={possibleDates}
                    onClickDay={(value) => {
                      setSelectDate({
                        ...selectDate,
                        date: value,
                      });
                    }}
                    onClickTime={(value) => {
                      setSelectDate({
                        ...selectDate,
                        time: value,
                      });
                    }}
                    teacherId={data.id}
                  />
                  <PopupBtnWrap>
                    <Button
                      disabled={
                        !(selectDate.date !== "" && selectDate.time !== "")
                      }
                      style={{ width: 226 }}
                      onClick={() => {
                        document.body.style.overflow = "auto";
                        paymentOT();
                      }}
                    >
                      등록하기
                    </Button>
                  </PopupBtnWrap>
                </PopupContainer>
              ))
            : null}
          {modal && (
            <Modal
              title={"신청 완료"}
              content={"OT 신청이 완료되었습니다."}
              button={[
                {
                  title: "닫기",
                  type: "active",
                },
              ]}
              onClose={() => {
                setModal(false);
              }}
              onPress={() => {
                setModal(false);
                document.body.style.overflow = "auto";
              }}
            />
          )}
          <Footer />
        </div>
      </Desktop>
      <Mobile>
        <div>
          <MobileHeader
            name={
              openOTApply &&
              myData?.type !== "TEACHER" &&
              myData?.isPaid === true
                ? "OT 신청하기"
                : "나의 멘토픽"
            }
            type={"sub"}
            isModal={openOTApply}
            setModal={setOpenOTApply}
          />
          {!openOTApply ? (
            <>
              <MobileProfileContainer>
                <MobileProfileImgWrapper>
                  <img
                    alt="프로필"
                    src={
                      data.profile
                        ? data.profile
                        : "/images/default_profile.png"
                    }
                  />
                </MobileProfileImgWrapper>
                <MobileProfileInfo>
                  <div>
                    <h2>{data.name}</h2>
                    <Swiper
                      style={{
                        visibility: data.subjects !== "" ? "visible" : "hidden",
                      }}
                      spaceBetween={8}
                      modules={[FreeMode, Pagination]}
                      slidesPerView={"auto"}
                      freeMode={true}
                    >
                      {data.subjects?.map((el, id) => {
                        return (
                          <SwiperSlide>{formatSubject(el.subject)}</SwiperSlide>
                        );
                      })}
                    </Swiper>
                  </div>
                  <MobileProfileDetailRecoder>
                    <li>
                      <div>학력</div>
                      <p>
                        {data.educations &&
                          `${data.educations[0]?.institute.name || ""}  ${
                            data.educations[0]?.institute.major || ""
                          } `}
                      </p>
                    </li>
                    <li>
                      <div>이력</div>
                      <p>
                        {data.careers &&
                          data.careers
                            ?.map((el) => {
                              return el.name;
                            })
                            .join(", ")}
                      </p>
                    </li>
                  </MobileProfileDetailRecoder>

                  <MobileProfileTab>
                    <ul>
                      {arrayMobile.map((el) => (
                        <li>
                          <button
                            style={{
                              borderBottom:
                                el === currentArray ? "2px solid #FFAD2E" : "",
                              color: el === currentArray ? " #FFAD2E" : "",
                            }}
                            type="button"
                            onClick={() => {
                              setCurrentArray(el);
                            }}
                          >
                            {el}
                          </button>
                        </li>
                      ))}
                    </ul>
                    <hr />
                  </MobileProfileTab>

                  {currentArray === "프로필" && <ProfileDetail />}
                  {currentArray === "평판" && <Reputation />}
                  {currentArray === "강의영상" && (
                    <LectureVideo profileId={profileId} />
                  )}
                </MobileProfileInfo>
              </MobileProfileContainer>
              <OtBtn>
                <button
                  active
                  onClick={() => {
                    setOpenOTApply(true);
                  }}
                >
                  OT신청하기
                </button>
              </OtBtn>
            </>
          ) : (
            myData.type !== "TEACHER" &&
            myData.isPaid === true && (
              <MobileOtContainer>
                <PopupBadge
                  style={{
                    borderRadius: 13,
                    height: 26,
                    width: 69,
                    fontSize: 12,
                    padding: 0,
                  }}
                >
                  안내사항
                </PopupBadge>
                <PopupDesc>
                  OT 신청의 경우{" "}
                  <PopupDesc orange style={{ marginBottom: 0 }}>
                    {`${
                      data.otPrice &&
                      data.otPrice
                        .toString()
                        .replace(/(\d)(?=(?:\d{3})+(?!\d))/g, "$1,")
                    }원`}
                  </PopupDesc>
                  의 신청금이 청구됩니다.
                </PopupDesc>
                <PopupBadge
                  style={{
                    borderRadius: 13,
                    height: 26,
                    width: 103,
                    fontSize: 12,
                    padding: 0,
                  }}
                >
                  날짜 / 시간 선택
                </PopupBadge>
                <DatePicker
                  className={"selectTime"}
                  style={{ flexDirection: "column" }}
                  possibleDate={possibleDates}
                  onClickDay={(value) => {
                    setSelectDate({
                      ...selectDate,
                      date: value,
                    });
                  }}
                  onClickTime={(value) => {
                    setSelectDate({
                      ...selectDate,
                      time: value,
                    });
                  }}
                  teacherId={data.id}
                />
                <OtBtn>
                  <button
                    disabled={
                      selectDate?.date === "" || selectDate?.time === ""
                    }
                    active={selectDate?.date !== "" && selectDate?.time !== ""}
                    onClick={() => {
                      paymentOT();
                    }}
                  >
                    신청하기
                  </button>
                </OtBtn>
              </MobileOtContainer>
            )
          )}
          {/**학생이 아니거나 결제를 하지 않았을 경우 모달창 띄우기 */}
          {openOTApply && myData.type === "TEACHER" && (
            <MobileModal
              title={"죄송합니다."}
              content={"해당 서비스는 학생/학부모 회원만 이용가능합니다."}
              button={[
                {
                  title: "닫기",
                  type: "active",
                },
              ]}
              onClose={() => {
                setOpenOTApply(false);
              }}
              onPress={() => {
                setOpenOTApply(false);
              }}
            />
          )}
          {openOTApply && myData.type !== "TEACHER" && myData.isPaid !== true && (
            <MobileModal
              btncnt={2}
              title={"죄송합니다."}
              content={"해당 서비스는 유료회원만 이용 가능합니다."}
              button={[
                {
                  title: "닫기",
                  type: "cancel",
                },
                {
                  title: "결제하기",
                  type: "active",
                },
              ]}
              onClose={() => {
                setOpenOTApply(false);
              }}
              onPress={() => {
                setOpenOTApply(false);
              }}
            />
          )}
          {modal && (
            <MobileModal
              title={"신청 완료"}
              content={"OT 신청이 완료되었습니다."}
              button={[
                {
                  title: "닫기",
                  type: "active",
                },
              ]}
              onClose={() => {
                setModal(false);
              }}
              onPress={() => {
                setModal(false);
              }}
            />
          )}
        </div>
      </Mobile>
    </>
  );
};

export default MentoProfile;
