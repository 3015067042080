import styled from "styled-components";
import { MobileSignUpHeader } from "../../signup/student/mobile.style";

export const MobileContainer = styled.div`
  margin: 0 auto;
  padding: 0 24px;
  height: 844px;
  width: 390px;
`;

export const MobileHomeLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 225px;
  img {
    width: 116px;
    height: 99px;
  }
`;

export const LoginWrapper = styled.div`
  > h2 {
    width: 342px;
    font-size: 20px;
    font-weight: 700;
    line-height: 29px;
    margin-bottom: 24px;
  }

  > div {
    :last-of-type {
      display: flex;
      justify-content: space-between;
      margin-top: 16px;
      button {
        border: none;
        background-color: transparent;
        font-size: 14px;
        font-weight: 500;
        color: #212529;
      }
    }
  }
`;

export const ErrorModal = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(33, 37, 41, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  > div {
    text-align: center;
    background-color: white;
    padding: 24px 16px;
    width: 342px;
    height: 179px;
    border-radius: 12px;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    color: #212529;
    line-height: 29px;
    h2 {
      font-size: 20px;
      font-weight: 700;
    }
    p {
      margin-top: 12px;
      font-weight: 400;
      font-size: 16px;
      line-height: 23px;
    }
    button {
      margin-top: 20px;
      width: 310px;
      height: 45px;
      border-radius: 4px;
      border: none;
      background-color: #021633;
      color: white;
    }
  }
`;

export const EasyLoginWrapper = styled.div`
  h2 {
    width: 342px;
    font-size: 20px;
    font-weight: 700;
    line-height: 29px;
    margin-bottom: 24px;
  }
`;

export const HeadLine = styled.hr`
  border: none;
  background-color: #e9ecef;
  width: 342px;
  height: 1px;
  margin: 24px 0;
`;

export const JoinModalHeader = styled(MobileSignUpHeader)`
  justify-content: flex-end;
`;

export const JoinContainer = styled.div`
  padding: 56px 20px 20px 20px;
  width: 390px;
  margin: 0 auto;
  > h1 {
    margin-top: 24px;
    color: #212529;
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    margin-bottom: 12px;
  }
  > p {
    color: #868e96;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 16px;
  }

  > ul {
    li {
      width: 342px;
      height: 123px;
      margin-bottom: 19px;
      > button {
        width: 100%;
        height: 100%;
        padding: 24px 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 1px solid #dde2ea;
        background-color: white;
        figure {
          width: 56px;
          height: 56px;
          border-radius: 50%;
          background: rgba(2, 22, 51, 0.1);
        }
        div {
          text-align: left;
          h2 {
            color: #212529;
            font-size: 18px;
            font-weight: 700;
            line-height: 26.06px;
          }
          p {
            margin-top: 8px;
            white-space: pre-line;
            line-height: 20.27px;
            color: #868e96;
          }
        }
        img {
          padding: 0;
          width: 24px;
          height: 24px;
        }
      }
    }
  }
`;
