import { createGlobalStyle } from "styled-components";
import reset from "styled-reset";

const GlobalStyle = createGlobalStyle`

  ${reset}

  @font-face {
      font-family: 'Noto Sans KR';
      font-weight: 900;
      src: local('Noto Sans KR Black'),
      url('/fonts/NotoSansKR-Black.otf') format('otf'),
      url('/fonts/NotoSansKR-Black.otf') format('woff'),
      url('/fonts/NotoSansKR-Black.otf') format('woff2'),
  }

  @font-face {
      font-family: 'Noto Sans KR';
      font-weight: 700;
      src: local('Noto Sans KR Bold'),
      url('/fonts/NotoSansKR-Bold.otf') format('otf'),
      url('/fonts/NotoSansKR-Bold.otf') format('woff'),
      url('/fonts/NotoSansKR-Bold.otf') format('woff2'),
  }

  @font-face {
      font-family: 'Noto Sans KR';
      font-weight: 500;
      src: local('Noto Sans KR Medium'),
      url('/fonts/NotoSansKR-Medium.otf') format('otf'),
      url('/fonts/NotoSansKR-Medium.otf') format('woff'),
      url('/fonts/NotoSansKR-Medium.otf') format('woff2'),
  }

  @font-face {
      font-family: 'Noto Sans KR';
      font-weight: 400;
      src: local('Noto Sans KR Regular'),
      url('/fonts/NotoSansKR-Regular.otf') format('otf'),
      url('/fonts/NotoSansKR-Regular.otf') format('woff'),
      url('/fonts/NotoSansKR-Regular.otf') format('woff2'),
  }

  @font-face {
      font-family: 'Noto Sans KR';
      font-weight: 300;
      src: local('Noto Sans KR Light'),
      url('/fonts/NotoSansKR-Light.otf') format('otf'),
      url('/fonts/NotoSansKR-Light.otf') format('woff'),
      url('/fonts/NotoSansKR-Light.otf') format('woff2'),
  }

  @font-face {
      font-family: 'Noto Sans KR';
      font-weight: 100;
      src: local('Noto Sans KR Thin'),
      url('/fonts/NotoSansKR-Thin.otf') format('otf'),
      url('/fonts/NotoSansKR-Thin.otf') format('woff'),
      url('/fonts/NotoSansKR-Thin.otf') format('woff2'),
  }

  html, body {
    margin: 0;
    padding: 0;
    font-family: 'Noto Sans KR';
  }
  div {
    box-sizing: border-box;
  }

  * {
    font-family: 'Noto Sans KR';
  }

  input {
        -webkit-appearance: none;
        font-family: 'Noto Sans KR';
    }
    button { 
        outline: none;
        cursor: pointer;
    }


input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
`;

export default GlobalStyle;
