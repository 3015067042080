import { useState, useEffect } from "react";
import { useRecoilState } from "recoil";
import axios from "axios";

import Header from "../../components/Header";
import Footer from "../../components/Footer";
import SideBar from "../../components/SideBar";
import searchValueState from "../../states/searchValueState";
import QnAList from "./components/QnAList";
import { BannerTitle, Container } from "./style";
import { API_URL } from "../../API";
import { Desktop, Mobile } from "../../utils/mediaQuery";
import MobileHeader from "../../components/MobileHeader";
import MobileBottomBar from "../../components/MobileBottomBar";

const QnA = () => {
  const [searchValue, setSearchValue] = useRecoilState(searchValueState);
  const [qnaCategory, setQNACategory] = useState(null);
  const [qnaCategories, setQNACategories] = useState([]);
  const contents = [<QnAList tabName={qnaCategory} />];

  const getCategories = () => {
    axios({
      method: "GET",
      url: `${API_URL}/qna/categories`,
    }).then((res) => {
      if (res.data.success) {
        setQNACategories([{ name: "전체" }, ...res.data.result.qnaCategories]);
      }
    });
  };

  useEffect(() => {
    getCategories();
  }, []);

  return (
    <>
      <Desktop>
        <Header activeTab={"QnA"} />
        <Container>
          <SideBar
            qna={true}
            tabName={(state) => {
              setQNACategory(state);
            }}
            onClick={() => {
              setSearchValue("");
            }}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                setSearchValue("");
              }
            }}
            contents={contents}
            array={qnaCategories}
            bannerTitle={
              <BannerTitle>
                누구나 질문하고, 누구나 답하는
                <BannerTitle orange> 지식창고</BannerTitle>
              </BannerTitle>
            }
          >
            질의응답
          </SideBar>
        </Container>
        <Footer />
      </Desktop>
      <Mobile>
        <MobileHeader type={"main"} name="질의응답" />
        <SideBar
          page="QnA"
          setTabName={setQNACategory}
          array={qnaCategories}
          bannerTitle={
            <h2>
              누구나 질문하고, 누구나 답하는<span>지식창고</span>
            </h2>
          }
        >
          질의응답
        </SideBar>
        <QnAList tabName={qnaCategory} />
        <MobileBottomBar activeTab={"QnA"} />
      </Mobile>
    </>
  );
};

export default QnA;
