import styled from "styled-components";
import { OtBtn } from "../mentoPick/MentoProfile";
export const MobileConferenceContainer = styled.div`
  padding-bottom: 100px;
  > div {
    :nth-of-type(3) {
      > p {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #505860;
        margin-bottom: 3px;
      }
      > h2 {
        margin-bottom: 8px;
      }

      > div {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #212529;
        a {
          background-color: transparent;
          border: none;
          margin-left: 8px;
          text-decoration: none;
          color: #6337fc;
        }
      }
    }
  }
`;

export const MobileConferenceList = styled.div`
  padding: 0 24px;
  width: 390px;
  margin: 0 auto;
`;
