import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { useRecoilValue, useSetRecoilState } from "recoil";

import prev_btn from "../../../assets/images/prev.png";
import { API_URL } from "../../../API";
import {
  Container,
  MainWrap,
  PageTypeText,
  JoinTypeText,
  JoinNoticeText,
  Title,
  RowWrap,
  InfoTitle,
  RegexText,
  Border,
  SubjectChoiceWrap,
  ChoiceBox,
  ChoiceText,
  CancleWrap,
  CheckBoxWrap,
  CheckBoxBorder,
  ButtonWrap,
  RegexCheckText,
} from "../student/style";
import {
  SchoolTitle,
  DocumentInfoWrap,
  DocumentNotice,
  DocumentInfoNotice,
  DocumentKindWrap,
  DocumentKindTitle,
  DocumnetKindContent,
  LevelType,
  LevelInfoWrap,
  LevelInfoContent,
  ExampleBox,
  ExampleWrap,
  ExampleContent,
  UrlText,
  RadioBoxBorder,
} from "./style";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import TextInput from "../../../components/TextInput";
import Button from "../../../components/Button";
import Select from "../../../components/Select";
import RadioBox from "../../../components/RadioBox";
import CheckBox from "../../../components/CheckBox";
import Modal from "../../../components/Modal";
import SearchBar from "../../../components/SearchBar";
import FileUploadBox from "../../../components/FileUploadBox";

import { ReactComponent as DotLineIcon } from "../../../assets/icons/dot_line.svg";
import { ReactComponent as CheckIcon } from "../../../assets/icons/checkbox.svg";

import snsTokenState from "../../../states/snsTokenState";
import { Desktop, Mobile } from "../../../utils/mediaQuery";
import {
  HeadLine,
  MobileContainer,
} from "../../login/mobile/login_mobile.style";
import {
  HighScoolSearchText,
  MobileDocumentIdentity,
  MobileDontKnowBtn,
  MobileShoolTilte,
  MobileSignUpContainer,
  MobileSignUpHeader,
  UniverSearchText,
} from "./mobile.style";
import joinModalState from "../../../states/joinModalState";
import MobileModal from "../../../components/MobileModal";
import styled from "styled-components";

const ValidationWarning = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: #ff3838;
  margin: 10px 0px;
`;

const TeacherSignUp = () => {
  const navigate = useNavigate();
  const snstokenValue = useRecoilValue(snsTokenState);
  const [modal, setModal] = useState(false);
  const [nickName, setNickName] = useState("");
  const [email, setEmail] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordCheck, setPasswordCheck] = useState("");
  const [emailLabel, setEmailLabel] = useState("");
  const [numbersCheck, setNumbersCheck] = useState(null);
  const [firstEnglishCheck, setFirstEnglishCheck] = useState(null);
  const [englishCheck, setEnglishCheck] = useState(null);
  const [specialCharCheck, setSpeialCharCheck] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [birthDay, setBirthDay] = useState("");
  const [province, setProvince] = useState("");
  const [city, setCity] = useState("");
  const [dong, setDong] = useState("");
  const [allAgreed, setAllAgreed] = useState(false);
  const [checkedTerms, setCheckedTerms] = useState([]);
  const [academy, setAcademy] = useState({
    name: "",
    id: "",
  });
  const [gender, setGender] = useState("");
  const [phoneNumberAuth, setPhoneNumberAuth] = useState(false);
  const [phoneNumberAuthCheck, setPhoneNumberAuthCheck] = useState("");
  const [phoenNumberEditDiasbled, setPhoneNumberEditDisabled] = useState(false);
  const [isSmsNumberAuth, setIsSmsNumberAuth] = useState(false);
  const [unitFocus, setUnitFocus] = useState(false);
  const [min, setMin] = useState(4);
  const [sec, setSec] = useState(0);
  const time = useRef(240);
  const timerId = useRef(null);
  const [countDown, setCountDown] = useState(false);
  const [authModal, setAuthModal] = useState(false);
  const [authSuccessModal, setAuthSuccessMoal] = useState(false);
  const [authFailModal, setAuthFailModal] = useState(false);
  const [isDuplicateNumber, setIsDuplicateNumber] = useState(false);
  const [wrongPhoneNumber, setWrongPhoneNumber] = useState(false);
  const [cities, setCities] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [towns, setTowns] = useState([]);
  const [nicknameLabel, setNicknameLabel] = useState(null);
  const [types, setTypes] = useState([]);
  const [mobileTypes, setMobileTypes] = useState([]);
  const [type, setType] = useState("");
  const [levels, setLevels] = useState([]);
  const [majors, setMajors] = useState([]);
  const [items, setItems] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [selectSubjects, setSelectSubjects] = useState([]);
  const [level, setLevel] = useState("");
  const [major, setMajor] = useState("");
  const [isDontKnowBtn, setIsDontKnowBtn] = useState(false);
  const [endStep, setEndStep] = useState(false);
  const [instituteCities, setInstituteCities] = useState([]);
  const [highSchool, setHighSchool] = useState({
    id: "",
    name: "",
    city: "",
  });
  const [highSchoolSelfInput, setHighSchoolSelfInput] = useState(false);
  const [universitySelfInput, setUniversitySelfInput] = useState(false);
  const [highSchoolText, setHighSchoolText] = useState("");
  const [universityNameText, setUniversityNameText] = useState("");
  const [universityMajorText, setUniversityMajorText] = useState("");
  const [universityName, setUniversityName] = useState({
    id: "",
    name: "",
  });
  const [universityMajor, setUniversityMajor] = useState({
    id: "",
    name: "",
  });
  const [universityClass, setUniversityCkass] = useState("");
  const [universityState, setUniversityState] = useState("");
  const [states, setStates] = useState([
    {
      label: "졸업",
      value: "졸업",
    },
    {
      label: "재학",
      value: "재학",
    },
    {
      label: "휴학",
      value: "휴학",
    },
    {
      label: "퇴학",
      value: "퇴학",
    },
  ]);
  const [searchResult, setSearchResult] = useState([]);
  const [files, setFiles] = useState([]);
  const [schoolIds, setSchoolIds] = useState([]);
  const setJoinModal = useSetRecoilState(joinModalState);
  useEffect(() => {
    setAllAgreed(
      checkedTerms.filter((term) => term.value).length === checkedTerms.length
    );
  }, [checkedTerms]);

  const agreedAll = () => {
    if (allAgreed) {
      setCheckedTerms((currentCheckedItems) =>
        currentCheckedItems.map((term) => ({ ...term, value: false }))
      );
    } else {
      setCheckedTerms((currentCheckedItems) =>
        currentCheckedItems.map((term) => ({ ...term, value: true }))
      );
    }
  };

  //이메일 중복 체크
  const emailCheck = () => {
    axios({
      method: "POST",
      url: `${API_URL}/user/check/email?email=${email}`,
    })
      .then((result) => {
        if (result.data.success === true) {
          if (result.data.result.isDuplicate === true) {
            setEmailLabel("이미 가입되어있는 이메일 입니다.");
            setIsEmailValid(false);
          } else {
            setEmailLabel("사용가능한 이메일입니다.");
            setIsEmailValid(true);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //닉네임 중복 체크
  const nicknameCheck = () => {
    axios({
      method: "POST",
      url: `${API_URL}/user/check/nickname?nickname=${nickName}`,
    })
      .then((result) => {
        if (result.data.success === true) {
          if (result.data.result.isDuplicate === true) {
            setNicknameLabel("이미 사용 중인 닉네임 입니다.");
          } else {
            setNicknameLabel("사용 가능한 닉네임 입니다.");
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //닉네임 특수문자 확인
  useEffect(() => {
    const regex = /[~!@#$%^&*()_+|<>?:{}]/;
    if (nickName) {
      setNickName(nickName.replace(regex, ""));
    }
    setNicknameLabel(null);
  }, [nickName]);

  useEffect(() => {
    setEmailLabel(null);
    setIsEmailValid(false);
  }, [email]);

  useEffect(() => {
    if (phoneNumber) {
      setPhoneNumber(phoneNumber.replace(/[^0-9]/g, ""));
    }
  }, [phoneNumber]);

  useEffect(() => {
    if (birthDay) {
      setBirthDay(birthDay.replace(/[^0-9]/g, ""));
    }
  }, [birthDay]);

  //문자 전송
  const sendSMS = () => {
    axios({
      method: "POST",
      url: `${API_URL}/user/sms/send`,
      data: {
        phoneNumber: phoneNumber,
      },
    })
      .then((result) => {
        if (result.data.error?.message) {
          if (
            result.data.error?.message === "이미 가입된 휴대전화번호입니다."
          ) {
            setWrongPhoneNumber(false);
            setIsDuplicateNumber(true);
          } else {
            setIsDuplicateNumber(false);
            setWrongPhoneNumber(true);
          }
        } else if (result.data.success === true) {
          window.alert("인증번호가 전송되었습니다.");
          setWrongPhoneNumber(false);
          setIsDuplicateNumber(false);
          setPhoneNumberEditDisabled(true);
          setPhoneNumberAuth(true);
          if (time.current < 240) {
            clearInterval(timerId.current);
            time.current = 240;
            start();
          } else {
            clearInterval(timerId.current);
            start();
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //인증번호 확인
  const authentication = () => {
    axios({
      method: "POST",
      url: `${API_URL}/user/sms/verify`,
      data: {
        phoneNumber: phoneNumber,
        authCode: phoneNumberAuthCheck,
      },
    })
      .then((result) => {
        if (result.data.success === true) {
          setCountDown(false);
          clearInterval(timerId.current);
          setAuthSuccessMoal(true);
          setPhoneNumberEditDisabled(true);
          setIsSmsNumberAuth(true);
        } else {
          setAuthFailModal(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const signUp = () => {
    axios({
      method: "POST",
      url: `${API_URL}/user`,
      data: {
        userType: "TEACHER",
        email: email,
        password: password,
        socialType: snstokenValue.type,
        socialToken: snstokenValue.id.toString(),
        nickname: nickName,
        phoneNumber: phoneNumber,
        phoneAuthCode: phoneNumberAuthCheck,
        gender: gender,
        birthday: moment(birthDay).format("YYYY-MM-DD"),
        areaId: dong.id,
        subjectIds: selectSubjects
          .filter((a, b) => selectSubjects.indexOf(a) === b)
          .map((item) => {
            return item.id;
          }),
        agreeTerms: checkedTerms
          .filter((item) => item.value === true)
          .map((checkItem) => {
            return checkItem.id;
          }),
        receiptId: "",
        instituteIds: schoolIds,
        certifications: files.map((item) => {
          return item.url;
        }),
        educationStatus: universityState.value,
        undergrad: parseInt(universityClass),
      },
    })
      .then((result) => {
        if (result.data.success) {
          setModal(true);
        } else {
          alert(result.data.error.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    const signupCheck =
      nicknameLabel === "사용 가능한 닉네임 입니다." &&
      phoenNumberEditDiasbled &&
      gender !== "" &&
      birthDay.length === 8 &&
      dong.id !== "" &&
      (type === "아직 잘 모르겠어요" ||
        isDontKnowBtn ||
        selectSubjects.length > 0) &&
      files.length > 0 &&
      checkedTerms
        .filter((term) => term.mandatory)
        .filter((checkTerm) => checkTerm.value).length ===
        checkedTerms.filter((term) => term.mandatory).length;
    const emailSignupCheck =
      isEmailValid &&
      firstEnglishCheck &&
      englishCheck &&
      numbersCheck &&
      specialCharCheck &&
      (password !== "" || password !== null) &&
      password === passwordCheck;

    if (isDontKnowBtn) {
      setType("");
      setLevel("");
      setMajor("");
    }
    if (snstokenValue.id === "" && snstokenValue.type === "") {
      setEndStep(signupCheck && emailSignupCheck);
    } else {
      setEndStep(signupCheck);
    }
  }, [
    isDontKnowBtn,
    nicknameLabel,
    phoenNumberEditDiasbled,
    gender,
    birthDay,
    dong,
    type,
    selectSubjects,
    files,
    checkedTerms,
    schoolIds,
    email,
    password,
    emailLabel,
    firstEnglishCheck,
    englishCheck,
    numbersCheck,
    specialCharCheck,
    passwordCheck,
    snstokenValue.id,
    snstokenValue.type,
  ]);

  const start = () => {
    timerId.current = setInterval(() => {
      time.current -= 1;
      setMin(parseInt(time.current / 60));
      setSec(time.current % 60);
      if (time.current < 61) {
        setCountDown(true);
      } else {
        setCountDown(false);
      }
    }, 1000);
  };

  useEffect(() => {
    if (time.current === 0) {
      clearInterval(timerId.current);
      setCountDown(false);
      time.current = 240;
      setAuthModal(true);
    }
  }, [sec]);

  const phoneNumberAuthHandler = () => {
    setPhoneNumberAuth(true);
    if (time.current < 240) {
      clearInterval(timerId.current);
      time.current = 240;
      start();
    } else {
      clearInterval(timerId.current);
      start();
    }
  };

  useEffect(() => {
    axios({
      method: "GET",
      url: `${API_URL}/area/cities`,
    })
      .then((result) => {
        setCities(
          result.data.result.cities.map((item) => {
            return {
              label: item,
              value: item,
            };
          })
        );
      })
      .catch((err) => {
        console.log(err);
      });

    axios({
      method: "GET",
      url: `${API_URL}/subject/types`,
    })
      .then((result) => {
        if (result.data.success === true) {
          setMobileTypes([
            ...result.data.result.types.map((item) => {
              return {
                label: item.type,
                name: "kind",
                value: item.type,
                id: item.id,
              };
            }),
          ]);
          setTypes([
            ...result.data.result.types.map((item) => {
              return {
                label: item.type,
                name: "kind",
                value: item.type,
                id: item.id,
              };
            }),
            {
              label: "아직 잘 모르겠어요",
              name: "kind",
              value: "아직 잘 모르겠어요",
              id: "아직 잘 모르겠어요",
            },
          ]);
        }
      })
      .catch((err) => {
        console.log(err);
      });

    axios({
      method: "GET",
      url: `${API_URL}/terms`,
    })
      .then((result) => {
        if (result.data.success === true) {
          setCheckedTerms(
            result.data.result.terms.map((item) => {
              return {
                id: item.id,
                value: false,
                label: item.title,
                mandatory: item.required,
                content: item.url,
              };
            })
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });

    axios({
      method: "GET",
      url: `${API_URL}/institute/cities`,
    })
      .then((result) => {
        setInstituteCities(
          result.data.result.cities.map((item) => {
            return {
              label: item,
              value: item,
            };
          })
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    axios({
      method: "GET",
      url: `${API_URL}/area/districts?cityName=${province.value}`,
    })
      .then((result) => {
        setDistricts(
          result.data.result.districts.map((item) => {
            return {
              label: item,
              value: item,
            };
          })
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }, [province]);

  useEffect(() => {
    axios({
      method: "GET",
      url: `${API_URL}/area/towns?cityName=${province.value}&districtName=${city.value}`,
    })
      .then((result) => {
        setTowns(
          result.data.result.towns.map((item) => {
            return {
              label: item.town,
              value: item.town,
              id: item.id,
            };
          })
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }, [city]);

  const getSubjectLevels = (type) => {
    axios({
      method: "GET",
      url: `${API_URL}/subject/levels?type=${type}`,
    })
      .then((result) => {
        if (result.data.success === true) {
          setLevels([
            ...result.data.result.levels.map((item) => {
              return {
                label: item.level,
                name: "level",
                value: item.level,
                id: item.id,
              };
            }),
          ]);
          setSubjects([
            ...subjects,
            ...result.data.result.levels.map((item) => {
              return item;
            }),
          ]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getSubjectMajors = (type, level) => {
    axios({
      method: "GET",
      url: `${API_URL}/subject/majors?type=${type}&level=${level}`,
    })
      .then((result) => {
        if (result.data.success === true) {
          setMajors([
            ...result.data.result.majors.map((item) => {
              return {
                label: item.name,
                name: "major",
                value: item.name,
                id: item.id,
              };
            }),
          ]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getSubjectItems = (type, level, major) => {
    axios({
      method: "GET",
      url: `${API_URL}/subject/items?type=${type}&level=${level}&major=${major}`,
    })
      .then((result) => {
        if (result.data.success === true) {
          setItems([
            ...result.data.result.items.map((item) => {
              return {
                label: item.progress,
                name: "major",
                value: item.progress,
                id: item.id,
              };
            }),
          ]);
          setSubjects([
            ...subjects,
            ...result.data.result.items.map((item) => {
              return item;
            }),
          ]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    setLevels([]);
    setMajors([]);
    setItems([]);
  }, [type]);

  useEffect(() => {
    setMajors([]);
    setItems([]);
  }, [level]);

  useEffect(() => {
    setItems([]);
  }, [major]);

  const searchInstitute = (name, type, city) => {
    axios({
      method: "GET",
      url: `${API_URL}/institute?name=${name}&type=${type}&city=${city}`,
    })
      .then((result) => {
        setSearchResult(
          result.data.result.institutes.map((item) => {
            return {
              title: item.name,
              id: item.id,
            };
          })
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const searchMajor = (name) => {
    axios({
      method: "GET",
      url: `${API_URL}/institute/majors?universityName=${name}`,
    })
      .then((result) => {
        setSearchResult(
          result.data.result.majors.map((item) => {
            return {
              title: item.major,
              id: item.id,
            };
          })
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (academy.id) {
      setSchoolIds([academy.id, highSchool.id, universityMajor.id]);
    } else {
      setSchoolIds([highSchool.id, universityMajor.id]);
    }
  }, [academy, highSchool, universityMajor]);

  useEffect(() => {
    const numbersCheck = /[0-9]/.test(password);
    const firstEnglishCheck = /^[a-zA-Z]/.test(password.at(0));
    const englishCheck = /^[a-zA-Z]/.test(password);
    const specialCharCheck = /(?=.*[$@$!%*#?&])/.test(password);

    if (password) {
      if (numbersCheck === true) {
        setNumbersCheck(true);
      } else {
        setNumbersCheck(false);
      }

      if (englishCheck === true) {
        setEnglishCheck(true);
      } else {
        setEnglishCheck(false);
      }

      if (specialCharCheck === true) {
        setSpeialCharCheck(true);
      } else {
        setSpeialCharCheck(false);
      }

      setFirstEnglishCheck(firstEnglishCheck);
    } else {
      setNumbersCheck(null);
      setEnglishCheck(null);
      setSpeialCharCheck(null);
      setFirstEnglishCheck(null);
    }
  }, [
    password,
    firstEnglishCheck,
    numbersCheck,
    englishCheck,
    specialCharCheck,
  ]);

  useEffect(() => {
    if (isDontKnowBtn) {
      setSelectSubjects([]);
      setLevel("");
    }
  }, [isDontKnowBtn]);

  return (
    <>
      <Desktop>
        <Header activeTab={"Login"} />
        <Container>
          <MainWrap>
            <PageTypeText>회원가입</PageTypeText>
            <JoinTypeText>선생님</JoinTypeText>
            <JoinNoticeText>
              회원가입을 위해 몇가지의 정보가 필요합니다.
            </JoinNoticeText>
            <Title>가입 정보</Title>
            {snstokenValue.id === "" && snstokenValue.type === "" && (
              <>
                <InfoTitle>이메일을 입력해주세요.</InfoTitle>
                <RowWrap
                  style={{
                    marginBottom: emailLabel === null ? "80px" : "48px",
                  }}
                >
                  <TextInput
                    style={{
                      width: 636,
                    }}
                    value={email}
                    placeholder={"이메일 입력"}
                    onChange={(value) => {
                      setEmail(value);
                    }}
                    marginBottom={"0px"}
                    label={emailLabel}
                    error={
                      emailLabel === "사용가능한 이메일입니다." ? false : true
                    }
                  />
                  <Button
                    active
                    style={{ width: 160, marginLeft: 16 }}
                    onClick={emailCheck}
                  >
                    중복확인
                  </Button>
                </RowWrap>
                <InfoTitle>비밀번호를 입력해주세요.</InfoTitle>
                <RowWrap>
                  <div>
                    <TextInput
                      style={{
                        width: 398,
                        marginRight: 16,
                        marginBottom: 8,
                      }}
                      value={password}
                      placeholder={"8~20자 입력"}
                      onChange={(value) => {
                        setPassword(value);
                      }}
                      maxLength={20}
                      marginBottom={"0px"}
                      type={"password"}
                    />
                    <RowWrap marginBottom={"0px"}>
                      <CheckIcon
                        style={{ width: 18, heigth: 18 }}
                        color={
                          firstEnglishCheck === null
                            ? "#ADB5BD"
                            : firstEnglishCheck === true
                            ? "#0081F5"
                            : "#FF383B"
                        }
                      />
                      <RegexCheckText error={firstEnglishCheck}>
                        첫 글자 영문
                      </RegexCheckText>
                      <CheckIcon
                        style={{ width: 18, heigth: 18 }}
                        color={
                          englishCheck === null
                            ? "#ADB5BD"
                            : englishCheck === true
                            ? "#0081F5"
                            : "#FF383B"
                        }
                      />
                      <RegexCheckText error={englishCheck}>영문</RegexCheckText>
                      <CheckIcon
                        style={{ width: 18, heigth: 18 }}
                        color={
                          numbersCheck === null
                            ? "#ADB5BD"
                            : numbersCheck === true
                            ? "#0081F5"
                            : "#FF383B"
                        }
                      />
                      <RegexCheckText error={numbersCheck}>숫자</RegexCheckText>
                      <CheckIcon
                        style={{ width: 18, heigth: 18 }}
                        color={
                          specialCharCheck === null
                            ? "#ADB5BD"
                            : specialCharCheck === true
                            ? "#0081F5"
                            : "#FF383B"
                        }
                      />
                      <RegexCheckText error={specialCharCheck}>
                        특수문자
                      </RegexCheckText>
                    </RowWrap>
                  </div>
                  <TextInput
                    style={{
                      width: 398,
                    }}
                    value={passwordCheck}
                    placeholder={"비밀번호 확인"}
                    onChange={(value) => {
                      setPasswordCheck(value);
                    }}
                    maxLength={20}
                    marginBottom={"0px"}
                    type={"password"}
                    label={
                      passwordCheck !== ""
                        ? password === passwordCheck
                          ? "비밀번호가 일치합니다."
                          : "비밀번호가 일치하지 않습니다."
                        : undefined
                    }
                    error={password !== passwordCheck}
                  />
                </RowWrap>
              </>
            )}
            <InfoTitle>닉네임 또는 실명을 입력해주세요.</InfoTitle>
            <RegexText>특수문자 제외, 10글자 이하로 입력해주세요.</RegexText>
            <RowWrap
              style={{ marginBottom: nicknameLabel === null ? "80px" : "48px" }}
            >
              <TextInput
                style={{ width: 636 }}
                value={nickName}
                placeholder={"닉네임 또는 실명 입력"}
                onChange={(value) => {
                  setNickName(value);
                }}
                maxLength={10}
                marginBottom={"0px"}
                label={nicknameLabel}
                error={
                  nicknameLabel === "사용 가능한 닉네임 입니다." ? false : true
                }
              />
              <Button
                active
                style={{ width: 160, marginLeft: 16 }}
                onClick={() => {
                  nicknameCheck();
                }}
              >
                중복확인
              </Button>
            </RowWrap>
            <InfoTitle>회원님의 휴대폰 인증이 필요합니다.</InfoTitle>
            <RowWrap marginBottom={phoneNumberAuth ? "20px" : "80px"}>
              {/* <Select
              value={telType}
              placeholder={"통신사 선택"}
              width={260}
              option={telOption}
              onChange={(value) => {
                setTelType(value);
              }}
            /> */}
              <TextInput
                style={{
                  width: 636,
                  border: phoenNumberEditDiasbled && "1px solid #dde2ea",
                }}
                value={phoneNumber}
                onChange={(value) => {
                  setPhoneNumber(value);
                }}
                placeholder={"(-) 제외한 숫자만 입력하세요."}
                maxLength={11}
                disabled={isSmsNumberAuth}
              />
              <Button
                grey={phoneNumberAuth}
                active={!phoneNumberAuth}
                style={{
                  marginLeft: 16,
                  width: 160,
                  pointerEvents: isSmsNumberAuth && "none",
                }}
                onClick={async () => {
                  sendSMS();
                  // !wrongPhoneNumber && !isDuplicateNumber && phoneNumberAuthHandler();
                  // if (phoneNumberAuth) {
                  //   setMin(4);
                  //   setSec(0);
                  // }
                }}
                disabled={isSmsNumberAuth}
              >
                {phoneNumberAuth ? "재요청" : "인증요청"}
              </Button>
            </RowWrap>
            {phoneNumberAuth ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "start",
                  alignItems: "start",
                  marginBottom: "80px",
                }}
              >
                <RowWrap style={{ marginBottom: "0" }}>
                  <TextInput
                    type="numberic"
                    style={{
                      width: 636,
                      border: isSmsNumberAuth && "1px solid #dde2ea",
                      marginBottom: "8px",
                    }}
                    unitAuth={
                      isSmsNumberAuth ? null : (
                        <div>
                          {min}:{sec < 10 ? `0${sec}` : sec}
                        </div>
                      )
                    }
                    countDown={countDown}
                    unitFocus={unitFocus}
                    onFocus={() => {
                      setUnitFocus(true);
                    }}
                    onBlur={() => {
                      setUnitFocus(false);
                    }}
                    value={phoneNumberAuthCheck}
                    onChange={(value) => {
                      setPhoneNumberAuthCheck(value);
                    }}
                    disabled={isSmsNumberAuth}
                  />
                  <Button
                    active
                    style={{
                      marginLeft: 16,
                      width: 160,
                      pointerEvents: isSmsNumberAuth && "none",
                    }}
                    onClick={() => {
                      phoneNumberAuthHandler();
                      authentication();
                    }}
                    disabled={isSmsNumberAuth}
                  >
                    인증확인
                  </Button>
                </RowWrap>
                {isSmsNumberAuth && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: "16px",
                      color: "#0081F5",
                    }}
                  >
                    <CheckIcon
                      style={{ width: 18, heigth: 18 }}
                      color={
                        firstEnglishCheck === null
                          ? "#ADB5BD"
                          : firstEnglishCheck === true
                          ? "#0081F5"
                          : "#FF383B"
                      }
                    />
                    <p style={{ marginLeft: "4px" }}>인증되었습니다.</p>
                  </div>
                )}
              </div>
            ) : null}
            <Border />
            <Title>세부 정보</Title>
            <InfoTitle>회원님의 성별을 선택해주세요.</InfoTitle>
            <RowWrap>
              <RadioBox
                option={[
                  {
                    label: "남자입니다.",
                    name: "gender",
                    value: "MALE",
                    id: "MALE",
                  },
                  {
                    label: "여자입니다",
                    name: "gender",
                    value: "FEMALE",
                    id: "FEMALE",
                  },
                ]}
                showLine={2}
                onChange={(value) => {
                  setGender(value);
                }}
              />
            </RowWrap>
            <InfoTitle>회원님의 생년월일을 입력해주세요.</InfoTitle>
            <TextInput
              placeholder={"YYYYMMDD"}
              value={birthDay}
              onChange={(value) => {
                setBirthDay(value);
              }}
              maxLength={8}
              style={{ width: "100%", marginBottom: "48px" }}
            />
            <InfoTitle>회원님의 거주지를 선택해주세요.</InfoTitle>
            <RowWrap>
              <Select
                placeholder={"시/도"}
                value={province}
                onChange={(value) => {
                  setProvince(value);
                }}
                option={cities}
              />
              <Select
                placeholder={"시/군/구"}
                value={city}
                option={districts}
                onChange={(value) => {
                  setCity(value);
                }}
              />
              <Select
                placeholder={"읍/면/동"}
                value={dong}
                option={towns}
                onChange={(value) => {
                  setDong(value);
                }}
                marginRight={"0px"}
              />
            </RowWrap>
            <RowWrap
              style={{ justifyContent: "space-between" }}
              marginBottom={"0px"}
            >
              <InfoTitle marginBottom={"20px"} marginTop={"0px"}>
                회원님의 필요한 과목을 선택해주세요.
              </InfoTitle>
              <RegexText>*복수 선택이 가능합니다.</RegexText>
            </RowWrap>
            <SubjectChoiceWrap>
              {selectSubjects.length > 0 &&
                selectSubjects.map((item, idx) => {
                  if (item.type === "국내") {
                    return (
                      <ChoiceBox key={idx}>
                        <ChoiceText>{`${item.type}(${item.level}) - ${item.name}(${item.progress})`}</ChoiceText>
                        <CancleWrap
                          onClick={() => {
                            setSelectSubjects(
                              selectSubjects.filter(
                                (selectItem) => item !== selectItem
                              )
                            );
                          }}
                        />
                      </ChoiceBox>
                    );
                  } else {
                    return (
                      <ChoiceBox key={idx}>
                        <ChoiceText>{`${item.type} - ${item.level}`}</ChoiceText>
                        <CancleWrap
                          onClick={() => {
                            setSelectSubjects(
                              selectSubjects.filter(
                                (selectItem) => item !== selectItem
                              )
                            );
                          }}
                        />
                      </ChoiceBox>
                    );
                  }
                })}
            </SubjectChoiceWrap>
            <RowWrap marginBottom={"0px"}>
              <RadioBox
                option={types}
                showLine={4}
                onChange={(value) => {
                  getSubjectLevels(value);
                  setType(value);
                }}
                disabled={selectSubjects.length > 0}
              />
            </RowWrap>
            <RadioBoxBorder />
            {levels.length > 0 && (
              <>
                <RowWrap marginBottom={"0px"}>
                  <RadioBox
                    option={levels}
                    showLine={type === "국내" ? 2 : 4}
                    onChange={(value) => {
                      if (type === "국내") {
                        getSubjectMajors(type, value);
                      } else {
                        subjects.map((item) => {
                          if (item.level === value && item.type === type) {
                            if (
                              selectSubjects.filter(
                                (subItem) => item.id === subItem.id
                              ).length < 1
                            ) {
                              setSelectSubjects([...selectSubjects, item]);
                            }
                          }
                        });
                      }
                      setLevel(value);
                    }}
                  />
                </RowWrap>
                <RadioBoxBorder />
              </>
            )}
            {majors.length > 0 && (
              <>
                <RowWrap marginBottom={"0px"}>
                  <RadioBox
                    option={majors}
                    showLine={4}
                    onChange={(value) => {
                      getSubjectItems(type, level, value);
                      setMajor(value);
                    }}
                  />
                </RowWrap>
                <RadioBoxBorder />
              </>
            )}
            {items.length > 0 && (
              <>
                <RowWrap marginBottom={"0px"}>
                  <RadioBox
                    option={items}
                    showLine={4}
                    onChange={(value) => {
                      if (value) {
                        subjects.map((item) => {
                          if (item.progress === value) {
                            if (
                              selectSubjects.filter(
                                (subItem) => item.id === subItem.id
                              ).length < 1
                            ) {
                              setSelectSubjects([...selectSubjects, item]);
                            }
                          }
                        });
                      }
                    }}
                  />
                </RowWrap>
              </>
            )}
            <InfoTitle>회원님의 출신 학교를 입력해주세요.</InfoTitle>
            <SchoolTitle>영재원 (선택 입력)</SchoolTitle>
            <SearchBar
              list={searchResult}
              value={academy.name}
              onChange={(value) => {
                setAcademy({
                  ...academy,
                  name: value,
                });
                searchInstitute(value, "ACADEMY", "");
              }}
              onClick={(value) => {
                setAcademy({
                  id: value.id,
                  name: value.title,
                });
              }}
            />
            <SchoolTitle>고등학교</SchoolTitle>
            {highSchoolSelfInput ? (
              <TextInput
                style={{ width: "100%", marginBottom: 80 }}
                value={highSchoolText}
                placeholder={"고등학교 명"}
                onChange={(value) => {
                  setHighSchoolText(value);
                }}
                marginBottom={"0px"}
              />
            ) : (
              <RowWrap marginBottom={"0px"}>
                <Select
                  placeholder={"시/도"}
                  value={highSchool.city}
                  onChange={(value) => {
                    setHighSchool({
                      ...highSchool,
                      city: value,
                    });
                  }}
                  option={instituteCities}
                />
                <SearchBar
                  list={searchResult}
                  marginBottom={"0px"}
                  placeholder={"학교 검색"}
                  value={highSchool.name}
                  onChange={(value) => {
                    setHighSchool({
                      ...highSchool,
                      name: value,
                    });
                    searchInstitute(value, "HIGHSCHOOL", highSchool.city.value);
                  }}
                  onClick={(value) => {
                    setHighSchool({
                      ...highSchool,
                      id: value.id,
                      name: value.title,
                    });
                  }}
                />
              </RowWrap>
            )}
            {highSchoolSelfInput === false && (
              <p style={{ marginBottom: 80, marginTop: 20 }}>
                찾으시는 학교가 리스트에 없으십니까?{" "}
                <span
                  style={{ textDecoration: "underline", cursor: "pointer" }}
                  onClick={() => {
                    setHighSchoolSelfInput(true);
                  }}
                >
                  직접 입력하기
                </span>
              </p>
            )}
            <SchoolTitle>대학교</SchoolTitle>
            {universitySelfInput ? (
              <div style={{ marginBottom: 16 }}>
                <TextInput
                  style={{ width: 396, float: "left" }}
                  value={universityNameText}
                  placeholder={"대학교명"}
                  onChange={(value) => {
                    setUniversityNameText(value);
                  }}
                  marginBottom={"0px"}
                />
                <TextInput
                  style={{ width: 396, float: "right" }}
                  value={universityMajorText}
                  placeholder="학과명"
                  onChange={(value) => {
                    setUniversityMajorText(value);
                  }}
                  marginBottom={"0px"}
                />
                <div style={{ clear: "both" }} />
              </div>
            ) : (
              <RowWrap marginBottom={"16px"}>
                <SearchBar
                  style={{ marginRight: 16 }}
                  marginBottom={"0px"}
                  placeholder={"학교 검색"}
                  list={searchResult}
                  value={universityName.name}
                  onChange={(value) => {
                    setUniversityName({
                      ...universityName,
                      name: value,
                    });
                    searchInstitute(value, "UNIVERSITY", "");
                  }}
                  onClick={(value) => {
                    setUniversityName({
                      id: value.id,
                      name: value.title,
                    });
                  }}
                />
                <SearchBar
                  marginBottom={"0px"}
                  placeholder={"학과 검색"}
                  list={searchResult}
                  value={universityMajor.name}
                  onChange={(value) => {
                    setUniversityMajor({
                      ...universityMajor,
                      name: value,
                    });
                    searchMajor(universityName.name);
                  }}
                  onClick={(value) => {
                    setUniversityMajor({
                      ...universityMajor,
                      id: value.id,
                      name: value.title,
                    });
                  }}
                />
              </RowWrap>
            )}
            {universitySelfInput === false && (
              <p style={{ marginBottom: 20, marginTop: 20 }}>
                찾으시는 학교가 리스트에 없으십니까?{" "}
                <span
                  style={{ textDecoration: "underline", cursor: "pointer" }}
                  onClick={() => {
                    setUniversitySelfInput(true);
                  }}
                >
                  직접 입력하기
                </span>
              </p>
            )}
            <div style={{ marginBottom: 80 }}>
              <TextInput
                style={{ width: 396, float: "left" }}
                containerStyle={{ width: 396 }}
                placeholder={"YY"}
                marginBottom={"0px"}
                value={universityClass}
                onChange={(value) => {
                  setUniversityCkass(value);
                }}
                maxLength={2}
                unit={"학번"}
              />
              <Select
                placeholder={"재학 상태"}
                width={"396px"}
                option={states}
                value={universityState}
                onChange={(value) => {
                  setUniversityState(value);
                }}
                marginRight={"0px"}
                float={"right"}
              />
            </div>
            <div style={{ clear: "both" }} />
            <InfoTitle>인증 서류를 등록해주세요.</InfoTitle>
            <DocumentInfoWrap>
              <DocumentNotice>
                신원 인증, 학력 인증, 과외 가능 과목 인증, 청소년 범죄 관련
                인증을 하여야만 선생님 회원으로 가입이
                <br />
                가능합니다.
              </DocumentNotice>
              <SchoolTitle>필수서류</SchoolTitle>
              <DocumentInfoNotice>
                각 인증 서류들은 영재멘토 인증팀에서만 확인하며 어디에도
                공개되지 않고 안정하게 보관됩니다.
              </DocumentInfoNotice>
              <RowWrap marginBottom={"16px"}>
                <DocumentKindWrap style={{ marginRight: 16 }}>
                  <DocumentKindTitle>신원 인증</DocumentKindTitle>
                  <DocumnetKindContent>
                    • 신분증 또는 여권 또는 운전면허증 3종 중 하나를 선택하여
                    등록해주세요.
                  </DocumnetKindContent>
                  <DocumnetKindContent>
                    신분증 앞면 사진을 찍어서 올려주세요.
                  </DocumnetKindContent>
                  <DocumnetKindContent>
                    주민등록번호 뒷 6자리와 주소를 꼭 가려주세요.
                  </DocumnetKindContent>
                  <DocumnetKindContent>
                    운전면허번호, 여권번호를 꼭 가려주세요.
                  </DocumnetKindContent>
                  <DocumnetKindContent>
                    정보 확인이 어렵거나 훼손/ 유효하지 않은 신분증은
                    <br />
                    승인처리가 늦어질 수 있습니다.
                  </DocumnetKindContent>
                </DocumentKindWrap>
                <DocumentKindWrap>
                  <DocumentKindTitle>학력 인증</DocumentKindTitle>
                  <LevelInfoWrap>
                    <LevelType>재학생</LevelType>
                    <DotLineIcon />
                    <LevelInfoContent>학생증 또는 재학증명서</LevelInfoContent>
                  </LevelInfoWrap>
                  <LevelInfoWrap>
                    <LevelType>휴학생</LevelType>
                    <DotLineIcon />
                    <LevelInfoContent>학생증 또는 휴학증명서</LevelInfoContent>
                  </LevelInfoWrap>
                  <LevelInfoWrap>
                    <LevelType>졸업생</LevelType>
                    <DotLineIcon />
                    <LevelInfoContent>졸업증명서 또는 학위증</LevelInfoContent>
                  </LevelInfoWrap>
                </DocumentKindWrap>
              </RowWrap>
              <SchoolTitle>선택서류</SchoolTitle>
              <RowWrap>
                <DocumentKindWrap style={{ marginRight: 16 }}>
                  <DocumentKindTitle>과외 가능 과목 인증</DocumentKindTitle>
                  <DocumnetKindContent>
                    과외 가능 과목에 대한 증빙서류가 필요합니다.
                  </DocumnetKindContent>
                  <ExampleWrap>
                    <ExampleBox>예시</ExampleBox>
                    <ExampleContent>
                      내신 과목의 경우 성적증명서가 필요하며, 대회 과목의
                      <br /> 경우 대회활동기록과 대학교 증명서가 필요합니다.
                    </ExampleContent>
                  </ExampleWrap>
                </DocumentKindWrap>
                <DocumentKindWrap>
                  <DocumentKindTitle>청소년 범죄</DocumentKindTitle>
                  <DocumnetKindContent>
                    해당 인증서는
                    <UrlText
                      href="https://crims.police.go.kr/main.do"
                      target="_blank"
                    >
                      {" [https://crims.police.go.kr/main.do] "}
                    </UrlText>
                    에서 조회 가능합니다.
                  </DocumnetKindContent>
                </DocumentKindWrap>
              </RowWrap>
              <SchoolTitle>첨부하기</SchoolTitle>
              <DocumentInfoNotice>
                첨부파일은 20MB 이하의 JPG, PNG, PDF 파일로만 업로드 가능합니다.
              </DocumentInfoNotice>
              <FileUploadBox
                onChange={(value) => {
                  setFiles(value);
                }}
              />
            </DocumentInfoWrap>
            <CheckBoxWrap>
              <CheckBox
                all
                label={"전체 동의"}
                value={allAgreed}
                onChange={(value) => {
                  agreedAll();
                }}
              />
              <CheckBoxBorder />
              {checkedTerms.map((item, idx) => (
                <CheckBox
                  key={idx}
                  label={item.label}
                  mandatory={item.mandatory}
                  value={item.value}
                  onChange={(value) => {
                    setCheckedTerms(
                      checkedTerms.map((term) => {
                        if (item.id === term.id) {
                          return {
                            ...term,
                            value: value,
                          };
                        }
                        return term;
                      })
                    );
                  }}
                  showDetailOnPress={() => {
                    window.open(`${item.content}`, "_blank");
                  }}
                />
              ))}
            </CheckBoxWrap>
            <ButtonWrap>
              {!endStep && (
                <ValidationWarning>
                  필수 입력 정보 및 중복확인/인증 여부를 확인해주세요.
                </ValidationWarning>
              )}
              <Button
                style={{ width: "300px" }}
                disabled={!endStep}
                onClick={() => {
                  if (!endStep) {
                    if (nicknameLabel !== "사용 가능한 닉네임 입니다.") {
                      alert("닉네임 또는 실명을 입력해주세요!");
                      return;
                    }

                    if (!phoenNumberEditDiasbled) {
                      alert("휴대폰 인증이 완료되지 않았습니다!");
                      return;
                    }

                    if (gender === "") {
                      alert("성별이 선택되지 않았습니다!");
                      return;
                    }

                    if (birthDay.length !== 8) {
                      alert("생년월일이 입력되지 않았습니다!");
                      return;
                    }

                    if (dong.id === "") {
                      alert("지역이 선택되지 않았습니다!");
                      return;
                    }

                    if (
                      type !== "아직 잘 모르겠어요" &&
                      selectSubjects.length === 0
                    ) {
                      alert("과목이 선택되지 않았습니다!");
                      return;
                    }

                    if (!files.length) {
                      alert("서류가 첨부되지 않았습니다!");
                      return;
                    }
                  } else {
                    signUp();
                  }
                }}
              >
                가입하기
              </Button>
            </ButtonWrap>
          </MainWrap>
          <Footer />
        </Container>
        {modal && (
          <Modal
            title={"회원가입 신청이 완료되었습니다."}
            content={"승인완료까지는 2~3일 정도 소요됩니다."}
            button={[
              {
                title: "홈으로 이동하기",
                type: "active",
              },
            ]}
            onPress={() => {
              navigate("/");
            }}
          />
        )}
        {authModal ? (
          <Modal
            padding
            title={"입력시간 초과"}
            content={"인증번호 입력시간이 초과되었습니다. 다시 입력해주세요."}
            button={[
              {
                title: "재전송",
                type: "active",
              },
            ]}
            onPress={() => {
              setAuthModal(false);
              start();
            }}
          />
        ) : null}
        {authFailModal && (
          <Modal
            padding
            title={"인증실패"}
            content={"입력한 인증번호가 일치하지 않습니다. 다시 입력해주세요."}
            button={[
              {
                title: "확인",
                type: "active",
              },
            ]}
            onPress={() => {
              setAuthFailModal(false);
            }}
          />
        )}
        {authSuccessModal && (
          <Modal
            padding
            title={"인증완료"}
            content={"인증이 완료되었습니다."}
            button={[
              {
                title: "확인",
                type: "active",
              },
            ]}
            onPress={() => {
              setAuthSuccessMoal(false);
            }}
          />
        )}
        {isDuplicateNumber ? (
          <Modal
            padding
            title={"오류"}
            content={"이미 가입된 휴대전화번호입니다."}
            button={[
              {
                title: "확인",
                type: "active",
              },
            ]}
            onPress={() => {
              setIsDuplicateNumber(false);
            }}
          />
        ) : null}
        {wrongPhoneNumber ? (
          <Modal
            padding
            title={"오류"}
            content={"잘못된 휴대전화번호입니다."}
            button={[
              {
                title: "확인",
                type: "active",
              },
            ]}
            onPress={() => {
              setWrongPhoneNumber(false);
            }}
          />
        ) : null}
      </Desktop>
      <Mobile>
        <MobileContainer>
          <MobileSignUpHeader>
            <button
              onClick={() => {
                setJoinModal(true);
                navigate("/login");
              }}
            >
              <img src={prev_btn} alt="prev" />
            </button>
            <h2>선생님 회원가입</h2>
          </MobileSignUpHeader>
          <MobileSignUpContainer>
            <h1>가입 정보</h1>
            {snstokenValue.id === "" && snstokenValue.type === "" && (
              <>
                <label>
                  <InfoTitle
                    style={{
                      fontSize: 16,
                      fontWeight: 700,
                      lineHeight: "23px",
                    }}
                  >
                    이메일을 입력해주세요.
                  </InfoTitle>
                  <TextInput
                    style={{
                      width: 342,
                      height: 56,
                    }}
                    value={email}
                    placeholder={"이메일 입력"}
                    onChange={(value) => {
                      setEmail(value);
                    }}
                    marginBottom={"0px"}
                    label={emailLabel}
                    error={
                      emailLabel === "사용가능한 이메일입니다." ? false : true
                    }
                  />
                  <Button
                    active
                    style={{ width: 342, marginTop: 16 }}
                    onClick={emailCheck}
                  >
                    중복확인
                  </Button>
                </label>
                <label>
                  <InfoTitle
                    style={{
                      fontSize: 16,
                      fontWeight: 700,
                      lineHeight: "23px",
                    }}
                  >
                    비밀번호를 입력해주세요
                  </InfoTitle>
                  <TextInput
                    style={{
                      width: 342,
                      marginBottom: 6,
                    }}
                    value={password}
                    placeholder={"8~20자 입력"}
                    onChange={(value) => {
                      setPassword(value);
                    }}
                    maxLength={20}
                    marginBottom={"0px"}
                    type={"password"}
                  />
                  <RowWrap marginBottom={"16px"}>
                    <CheckIcon
                      style={{ width: 18, heigth: 18 }}
                      color={
                        firstEnglishCheck === null
                          ? "#ADB5BD"
                          : firstEnglishCheck === true
                          ? "#0081F5"
                          : "#FF383B"
                      }
                    />
                    <RegexCheckText error={firstEnglishCheck}>
                      첫 글자 영문
                    </RegexCheckText>
                    <CheckIcon
                      style={{ width: 18, heigth: 18 }}
                      color={
                        firstEnglishCheck === null
                          ? "#ADB5BD"
                          : firstEnglishCheck === true
                          ? "#0081F5"
                          : "#FF383B"
                      }
                    />
                    <RegexCheckText error={englishCheck}>영문</RegexCheckText>
                    <CheckIcon
                      style={{ width: 18, heigth: 18 }}
                      color={
                        numbersCheck === null
                          ? "#ADB5BD"
                          : numbersCheck === true
                          ? "#0081F5"
                          : "#FF383B"
                      }
                    />
                    <RegexCheckText error={numbersCheck}>숫자</RegexCheckText>
                    <CheckIcon
                      style={{ width: 18, heigth: 18 }}
                      color={
                        specialCharCheck === null
                          ? "#ADB5BD"
                          : specialCharCheck === true
                          ? "#0081F5"
                          : "#FF383B"
                      }
                    />
                    <RegexCheckText error={specialCharCheck}>
                      특수문자
                    </RegexCheckText>
                  </RowWrap>
                  <TextInput
                    style={{
                      width: 342,
                    }}
                    value={passwordCheck}
                    placeholder={"비밀번호 확인"}
                    onChange={(value) => {
                      setPasswordCheck(value);
                    }}
                    maxLength={20}
                    marginBottom={"0px"}
                    type={"password"}
                    label={
                      passwordCheck !== ""
                        ? password === passwordCheck
                          ? "비밀번호가 일치합니다."
                          : "비밀번호가 일치하지 않습니다."
                        : undefined
                    }
                    error={password !== passwordCheck}
                  />
                </label>
              </>
            )}
            <label>
              <InfoTitle
                style={{
                  marginBottom: 8,
                  fontSize: 16,
                  fontWeight: 700,
                  lineHeight: "23px",
                }}
              >
                닉네임을 입력해주세요
              </InfoTitle>
              <RegexText
                style={{ lineHeight: "20px", fontSize: 14, marginBottom: 16 }}
              >
                특수문자 제외, 10글자 이하로 입력해주세요.
              </RegexText>
              <TextInput
                style={{ width: 342 }}
                value={nickName}
                placeholder={"닉네임 또는 실명 입력"}
                onChange={(value) => {
                  setNickName(value);
                }}
                maxLength={10}
                marginBottom={"0px"}
                label={nicknameLabel}
                error={
                  nicknameLabel === "사용 가능한 닉네임 입니다." ? false : true
                }
              />
              <Button
                active
                style={{ width: 342, marginTop: 16 }}
                onClick={() => {
                  nicknameCheck();
                }}
              >
                중복확인
              </Button>
            </label>
            <label>
              <InfoTitle
                style={{
                  marginBottom: 8,
                  fontSize: 16,
                  fontWeight: 700,
                  lineHeight: "23px",
                }}
              >
                휴대전화 인증이 필요합니다.
              </InfoTitle>

              <TextInput
                style={{
                  width: 342,
                  border: isSmsNumberAuth && "1px solid #dde2ea",
                }}
                value={phoneNumber}
                onChange={(value) => {
                  setPhoneNumber(value);
                }}
                placeholder={"(-) 제외한 숫자만 입력하세요."}
                maxLength={11}
                disabled={isSmsNumberAuth}
              />
              <Button
                grey={phoneNumberAuth}
                active={!phoneNumberAuth}
                style={{ width: 342, pointerEvents: isSmsNumberAuth && "none" }}
                onClick={async () => {
                  // if (phoneNumberAuth) {
                  //   setMin(4);
                  //   setSec(0);
                  // }
                  // phoneNumberAuthHandler();
                  sendSMS();
                }}
                disabled={isSmsNumberAuth}
              >
                {phoneNumberAuth ? "재요청" : "인증요청"}
              </Button>
              {phoneNumberAuth ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "start",
                    alignItems: "start",
                    marginBottom: "40px",
                  }}
                >
                  <TextInput
                    type="numberic"
                    style={{
                      marginBottom: 16,
                      marginTop: 30,
                      width: 342,
                      border: isSmsNumberAuth && "1px solid #dde2ea",
                    }}
                    unitAuth={
                      isSmsNumberAuth ? null : (
                        <div style={{ color: "#FF383B", marginTop: 15 }}>
                          남은시간 : {min}:{sec < 10 ? `0${sec}` : sec}
                        </div>
                      )
                    }
                    countDown={countDown}
                    unitFocus={unitFocus}
                    onFocus={() => {
                      setUnitFocus(true);
                    }}
                    onBlur={() => {
                      setUnitFocus(false);
                    }}
                    value={phoneNumberAuthCheck}
                    onChange={(value) => {
                      setPhoneNumberAuthCheck(value);
                    }}
                    disabled={isSmsNumberAuth}
                  />
                  <Button
                    active
                    style={{
                      width: 342,
                      pointerEvents: isSmsNumberAuth && "none",
                    }}
                    onClick={() => {
                      phoneNumberAuthHandler();
                      authentication();
                    }}
                    disabled={isSmsNumberAuth}
                  >
                    인증확인
                  </Button>
                  {isSmsNumberAuth && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "16px",
                        color: "#0081F5",
                      }}
                    >
                      <CheckIcon
                        style={{ width: 18, heigth: 18 }}
                        color={
                          firstEnglishCheck === null
                            ? "#ADB5BD"
                            : firstEnglishCheck === true
                            ? "#0081F5"
                            : "#FF383B"
                        }
                      />
                      <p style={{ marginLeft: "4px" }}>인증되었습니다.</p>
                    </div>
                  )}
                </div>
              ) : null}
            </label>
            <HeadLine />
            <h1>세부 정보</h1>
            <label>
              <InfoTitle
                style={{
                  marginBottom: 17,
                  fontSize: 16,
                  fontWeight: 700,
                  lineHeight: "23px",
                }}
              >
                회원님의 성별을 선택해주세요.
              </InfoTitle>
              <RowWrap style={{ width: 342, height: 56, marginBottom: 24 }}>
                <RadioBox
                  option={[
                    {
                      label: "남자입니다.",
                      name: "gender",
                      value: "MALE",
                      id: "MALE",
                    },
                    {
                      label: "여자입니다",
                      name: "gender",
                      value: "FEMALE",
                      id: "FEMALE",
                    },
                  ]}
                  showLine={2}
                  onChange={(value) => {
                    setGender(value);
                  }}
                />
              </RowWrap>
            </label>
            <label>
              <InfoTitle
                style={{
                  marginBottom: 17,
                  fontSize: 16,
                  fontWeight: 700,
                  lineHeight: "23px",
                }}
              >
                생년월일을 입력해주세요.
              </InfoTitle>

              <TextInput
                placeholder={"YYYYMMDD"}
                value={birthDay}
                onChange={(value) => {
                  setBirthDay(value);
                }}
                maxLength={8}
                style={{ width: 342, marginBottom: 25 }}
              />
            </label>
            <div>
              <InfoTitle
                style={{
                  marginTop: 0,
                  marginBottom: 17,
                  fontSize: 16,
                  fontWeight: 700,
                  lineHeight: "23px",
                }}
              >
                회원님의 거주지를 선택해주세요.
              </InfoTitle>

              <RowWrap style={{ marginBottom: 16 }}>
                <Select
                  type={1}
                  width={"163px"}
                  float={true}
                  placeholder={"시/도"}
                  value={province}
                  onChange={(value) => {
                    setProvince(value);
                  }}
                  option={cities}
                />

                <Select
                  type={1}
                  width={"163px"}
                  float={true}
                  placeholder={"시/군/구"}
                  value={city}
                  option={districts}
                  onChange={(value) => {
                    setCity(value);
                  }}
                />
              </RowWrap>

              <Select
                type={2}
                width={"342px"}
                float={true}
                placeholder={"읍/면/동"}
                value={dong}
                option={towns}
                onChange={(value) => {
                  setDong(value);
                }}
                marginRight={"0px"}
              />
            </div>
            <div>
              <InfoTitle
                style={{
                  marginBottom: 9,
                  fontSize: 16,
                  fontWeight: 700,
                  lineHeight: "23px",
                }}
              >
                회원님의 필요한 과목을 선택해주세요.
              </InfoTitle>
              <RegexText
                style={{ lineHeight: "20px", fontSize: 14, marginBottom: 16 }}
              >
                * 복수 선택이 가능합니다.
              </RegexText>
              <SubjectChoiceWrap>
                {selectSubjects.length > 0 &&
                  selectSubjects.map((item, idx) => {
                    if (item.type === "국내") {
                      return (
                        <ChoiceBox key={idx}>
                          <ChoiceText>{`${item.type}(${item.level}) - ${item.name}(${item.progress})`}</ChoiceText>
                          <CancleWrap
                            onClick={() => {
                              setSelectSubjects(
                                selectSubjects.filter(
                                  (selectItem) => item !== selectItem
                                )
                              );
                            }}
                          />
                        </ChoiceBox>
                      );
                    } else {
                      return (
                        <ChoiceBox key={idx}>
                          <ChoiceText>{`${item.type} - ${item.level}`}</ChoiceText>
                          <CancleWrap
                            onClick={() => {
                              setSelectSubjects(
                                selectSubjects.filter(
                                  (selectItem) => item !== selectItem
                                )
                              );
                            }}
                          />
                        </ChoiceBox>
                      );
                    }
                  })}
              </SubjectChoiceWrap>

              <div>
                <MobileDontKnowBtn
                  style={{
                    backgroundColor: isDontKnowBtn ? "#021633" : "",
                    color: isDontKnowBtn ? "white" : "",
                  }}
                  onClick={() => {
                    if (selectSubjects.length > 0) setIsDontKnowBtn(false);
                    else setIsDontKnowBtn(true);
                  }}
                >
                  아직 잘 모르겠어요
                </MobileDontKnowBtn>
                <RowWrap style={{ marginBottom: 16 }}>
                  <Select
                    type={1}
                    width={"163px"}
                    float={true}
                    placeholder={"과목 형태"}
                    value={type}
                    onChange={(value) => {
                      getSubjectLevels(Object.values(value)[0]);
                      setLevel("");
                      setMajor("");
                      setType(value);
                      setIsDontKnowBtn(false);
                    }}
                    option={mobileTypes}
                  />

                  <Select
                    type={1}
                    width={"163px"}
                    float={true}
                    placeholder={"과목 레벨"}
                    value={level}
                    option={levels}
                    onChange={(value) => {
                      setLevel(value);
                      if (Object.values(type)[0] === "국내") {
                        getSubjectMajors(
                          Object.values(type)[0],
                          Object.values(value)[0]
                        );
                      } else {
                        subjects.map((item) => {
                          if (
                            item.level === Object.values(value)[0] &&
                            item.type === Object.values(type)[0]
                          ) {
                            if (
                              selectSubjects.filter(
                                (subItem) => item.id === subItem.id
                              ).length < 1
                            ) {
                              setSelectSubjects([...selectSubjects, item]);
                            }
                          }
                        });
                      }
                    }}
                  />
                </RowWrap>
                <Select
                  type={2}
                  width={"342px"}
                  float={true}
                  placeholder={"과목명"}
                  value={major}
                  option={majors}
                  onChange={(value) => {
                    getSubjectItems(
                      Object.values(type)[0],
                      Object.values(level)[0],
                      Object.values(value)[0]
                    );
                    setMajor(value);
                  }}
                  marginRight={"0px"}
                />
                {items.length > 0 && (
                  <>
                    <RowWrap style={{ marginTop: "16px", width: "342px" }}>
                      <RadioBox
                        option={items}
                        showLine={2}
                        onChange={(value) => {
                          if (value) {
                            subjects.map((item) => {
                              if (item.progress === value) {
                                if (
                                  selectSubjects.filter(
                                    (subItem) => item.id === subItem.id
                                  ).length < 1
                                ) {
                                  setSelectSubjects([...selectSubjects, item]);
                                }
                              }
                            });
                          }
                        }}
                      />
                    </RowWrap>
                  </>
                )}
              </div>
            </div>
            <div>
              <InfoTitle
                style={{
                  marginBottom: 17,
                  fontSize: 16,
                  fontWeight: 700,
                  lineHeight: "23px",
                }}
              >
                회원님의 출신 학교를 입력해주세요.
              </InfoTitle>
              <MobileShoolTilte>영재교육원</MobileShoolTilte>
              <SearchBar
                style={{ width: "342px" }}
                list={searchResult}
                value={academy.name}
                onChange={(value) => {
                  setAcademy({
                    ...academy,
                    name: value,
                  });
                  searchInstitute(value, "ACADEMY", "");
                }}
                onClick={(value) => {
                  setAcademy({
                    id: value.id,
                    name: value.title,
                  });
                }}
              />
              <MobileShoolTilte>고등학교</MobileShoolTilte>
              {highSchoolSelfInput ? (
                <TextInput
                  style={{ width: "100%", marginBottom: 0 }}
                  value={highSchoolText}
                  placeholder={"고등학교 명"}
                  onChange={(value) => {
                    setHighSchoolText(value);
                  }}
                  marginBottom={"0px"}
                />
              ) : (
                <RowWrap
                  style={{
                    marginBottom: 0,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Select
                    width={"342px"}
                    placeholder={"시/도"}
                    value={highSchool.city}
                    onChange={(value) => {
                      setHighSchool({
                        ...highSchool,
                        city: value,
                      });
                    }}
                    option={instituteCities}
                  />
                  <SearchBar
                    style={{ width: "342px", marginTop: "16px" }}
                    list={searchResult}
                    marginBottom={"0px"}
                    placeholder={"학교 검색"}
                    value={highSchool.name}
                    onChange={(value) => {
                      setHighSchool({
                        ...highSchool,
                        name: value,
                      });
                      searchInstitute(
                        value,
                        "HIGHSCHOOL",
                        highSchool.city.value
                      );
                    }}
                    onClick={(value) => {
                      setHighSchool({
                        ...highSchool,
                        id: value.id,
                        name: value.title,
                      });
                    }}
                  />
                </RowWrap>
              )}
              {!highSchoolSelfInput ? (
                <HighScoolSearchText>
                  찾으시는 학교가 리스트에 없으십니까?{"  "}
                  <span
                    style={{ textDecoration: "underline", cursor: "pointer" }}
                    onClick={() => {
                      setHighSchoolSelfInput(true);
                    }}
                  >
                    직접 입력하기
                  </span>
                </HighScoolSearchText>
              ) : (
                <HighScoolSearchText style={{ marginTop: 20 }}>
                  <span
                    style={{ textDecoration: "underline", cursor: "pointer" }}
                    onClick={() => {
                      setHighSchoolSelfInput(false);
                    }}
                  >
                    학교 검색하기
                  </span>
                </HighScoolSearchText>
              )}
              <MobileShoolTilte>대학교</MobileShoolTilte>
              {universitySelfInput ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <TextInput
                    style={{ width: 342, marginBottom: 16 }}
                    value={universityNameText}
                    placeholder={"대학교명"}
                    onChange={(value) => {
                      setUniversityNameText(value);
                    }}
                    marginBottom={"0px"}
                  />
                  <TextInput
                    style={{ width: 342 }}
                    value={universityMajorText}
                    placeholder="학과명"
                    onChange={(value) => {
                      setUniversityMajorText(value);
                    }}
                    marginBottom={"0px"}
                  />
                </div>
              ) : (
                <RowWrap
                  style={{
                    marginBottom: "0",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <SearchBar
                    style={{ marginRight: 16 }}
                    marginBottom={"16px"}
                    placeholder={"학교 검색"}
                    list={searchResult}
                    value={universityName.name}
                    onChange={(value) => {
                      setUniversityName({
                        ...universityName,
                        name: value,
                      });
                      searchInstitute(value, "UNIVERSITY", "");
                    }}
                    onClick={(value) => {
                      setUniversityName({
                        id: value.id,
                        name: value.title,
                      });
                    }}
                  />
                  <SearchBar
                    marginBottom={"0px"}
                    placeholder={"학과 검색"}
                    list={searchResult}
                    value={universityMajor.name}
                    onChange={(value) => {
                      setUniversityMajor({
                        ...universityMajor,
                        name: value,
                      });
                      searchMajor(universityName.name);
                    }}
                    onClick={(value) => {
                      setUniversityMajor({
                        ...universityMajor,
                        id: value.id,
                        name: value.title,
                      });
                    }}
                  />
                </RowWrap>
              )}
              {!universitySelfInput ? (
                <UniverSearchText style={{ marginBottom: 20, marginTop: 20 }}>
                  찾으시는 학교가 리스트에 없으십니까?{" "}
                  <span
                    style={{ textDecoration: "underline", cursor: "pointer" }}
                    onClick={() => {
                      setUniversitySelfInput(true);
                    }}
                  >
                    직접 입력하기
                  </span>
                </UniverSearchText>
              ) : (
                <HighScoolSearchText style={{ marginTop: 20 }}>
                  <span
                    style={{ textDecoration: "underline", cursor: "pointer" }}
                    onClick={() => {
                      setUniversitySelfInput(false);
                    }}
                  >
                    학교 검색하기
                  </span>
                </HighScoolSearchText>
              )}
              <div style={{ marginBottom: 80 }}>
                <TextInput
                  unitstyle={{ right: "320px" }}
                  style={{ width: 165, float: "left" }}
                  containerStyle={{ width: 396 }}
                  placeholder={"YY"}
                  marginBottom={"0px"}
                  value={universityClass}
                  onChange={(value) => {
                    setUniversityCkass(value);
                  }}
                  maxLength={2}
                  unit={"학번"}
                />
                <Select
                  placeholder={"재학 상태"}
                  width={"165px"}
                  option={states}
                  value={universityState}
                  onChange={(value) => {
                    setUniversityState(value);
                  }}
                  marginRight={"0px"}
                  float={"right"}
                />
              </div>
              <div style={{ clear: "both" }} />
            </div>
            <div style={{ width: "342px", margin: "0 auto" }}>
              <InfoTitle
                style={{
                  marginBottom: 12,
                  fontSize: 16,
                  fontWeight: 700,
                  lineHeight: "23px",
                }}
              >
                인증 서류를 등록해주세요
              </InfoTitle>
              <p style={{ fontSize: 14, lineHeight: "20px", fontWeight: 500 }}>
                신원 인증, 학력 인증, 과외 가능 과목 인증, 청소년 범죄 관련
                <br /> 인증을 하여야만 선생님 회원으로 가입이 가능합니다.
              </p>
              <MobileDocumentIdentity>
                <h2>신원인증</h2>
                <hr />
                <ul>
                  <li> • 신분증 앞면 사진을 찍어서 올려주세요.</li>
                  <li> • 주민등록번호 뒷 6자리와 주소를 꼭 가려주세요.</li>
                  <li> • 운전면허번호, 여권번호를 꼭 가려주세요.</li>
                  <li>
                    {" "}
                    • 정보 확인이 어렵거나 훼손/ 유효하지 않은 신분증
                    <br />은 승인처리가 늦어질 수 있습니다.
                  </li>
                </ul>
              </MobileDocumentIdentity>
              <MobileDocumentIdentity>
                <h2>학력 인증</h2>
                <hr />
                <div>
                  <LevelInfoWrap>
                    <LevelType>재학생</LevelType>
                    <DotLineIcon style={{ width: 120 }} />
                    <LevelInfoContent>학생증 또는 재학증명서</LevelInfoContent>
                  </LevelInfoWrap>
                  <LevelInfoWrap>
                    <LevelType>휴학생</LevelType>
                    <DotLineIcon style={{ width: 120 }} />
                    <LevelInfoContent>학생증 또는 휴학증명서</LevelInfoContent>
                  </LevelInfoWrap>
                  <LevelInfoWrap style={{ marginBottom: 0 }}>
                    <LevelType>졸업생</LevelType>
                    <DotLineIcon style={{ width: 120 }} />
                    <LevelInfoContent>졸업증명서 또는 학위증</LevelInfoContent>
                  </LevelInfoWrap>
                </div>
              </MobileDocumentIdentity>
              <MobileDocumentIdentity>
                <h2>과외 가능 과목 인증</h2>
                <hr />
                <DocumnetKindContent style={{ listStyle: "none" }}>
                  과외 가능 과목에 대한 증빙서류가 필요합니다.
                </DocumnetKindContent>
                <ExampleWrap>
                  <ExampleBox
                    style={{
                      fontWeight: 500,
                      fontSize: "12px",
                      borderRadius: "13px",
                      width: 47,
                      height: 26,
                      border: "1px solid #FF383B",
                    }}
                  >
                    예시
                  </ExampleBox>
                  <ExampleContent style={{ lineHeight: "17px" }}>
                    내신 과목의 경우 성적증명서가 필요하며, 대회 과 <br /> 목의
                    경우 대회활동기록과 대학교 증명서가 필요합 <br /> 니다.
                  </ExampleContent>
                </ExampleWrap>
              </MobileDocumentIdentity>
              <MobileDocumentIdentity>
                <h2>청소년 범죄</h2>
                <hr />
                <DocumnetKindContent
                  style={{ textIndent: "0", padding: 0, listStyle: "none" }}
                >
                  해당 인증서는
                  <UrlText
                    href="https://crims.police.go.kr/main.do"
                    target="_blank"
                  >
                    {" [https://crims.police.go.kr/main.do] "}
                  </UrlText>
                  에서 조회 가능합니다.
                </DocumnetKindContent>
              </MobileDocumentIdentity>

              <MobileShoolTilte
                style={{
                  marginTop: 16,
                  marginBottom: 12,
                }}
              >
                첨부하기
              </MobileShoolTilte>
              <DocumentInfoNotice
                style={{
                  color: "#868E96",
                  lineHeight: "20px",
                  marginBottom: 16,
                }}
              >
                첨부파일은 20MB 이하의 JPG, PNG, PDF 파일로만 업로드 가능합니다.
              </DocumentInfoNotice>
              <FileUploadBox
                style={{
                  color: "#212529",
                  fontSize: 14,
                  fontWeight: 400,
                  whiteSpace: "nowrap",
                  width: "250px",
                  textOverflow: "ellipsis",
                  overflowX: "hidden",
                }}
                onChange={(value) => {
                  setFiles(value);
                }}
              />
            </div>
            <CheckBoxWrap style={{ width: "342px" }}>
              <CheckBox
                all
                label={"전체 동의"}
                value={allAgreed}
                onChange={(value) => {
                  agreedAll();
                }}
              />
              <CheckBoxBorder />
              {checkedTerms.map((item, idx) => (
                <CheckBox
                  key={idx}
                  label={item.label}
                  mandatory={item.mandatory}
                  value={item.value}
                  onChange={(value) => {
                    setCheckedTerms(
                      checkedTerms.map((term) => {
                        if (item.id === term.id) {
                          return {
                            ...term,
                            value: value,
                          };
                        }
                        return term;
                      })
                    );
                  }}
                  showDetailOnPress={() => {
                    window.open(`${item.content}`, "_blank");
                  }}
                />
              ))}
            </CheckBoxWrap>
            <ButtonWrap style={{ width: "342px", padding: "0px 0px" }}>
              {!endStep && (
                <ValidationWarning>
                  필수 입력 정보 및 중복확인/인증 여부를 확인해주세요.
                </ValidationWarning>
              )}
              <Button
                disabled={!endStep}
                onClick={() => {
                  if (!endStep) {
                    if (nicknameLabel !== "사용 가능한 닉네임 입니다.") {
                      alert("닉네임 또는 실명을 입력해주세요!");
                      return;
                    }

                    if (!phoenNumberEditDiasbled) {
                      alert("휴대폰 인증이 완료되지 않았습니다!");
                      return;
                    }

                    if (gender === "") {
                      alert("성별이 선택되지 않았습니다!");
                      return;
                    }

                    if (birthDay.length !== 8) {
                      alert("생년월일이 입력되지 않았습니다!");
                      return;
                    }

                    if (dong.id === "") {
                      alert("지역이 선택되지 않았습니다!");
                      return;
                    }

                    if (
                      type !== "아직 잘 모르겠어요" &&
                      selectSubjects.length === 0
                    ) {
                      alert("과목이 선택되지 않았습니다!");
                      return;
                    }

                    if (!files.length) {
                      alert("서류가 첨부되지 않았습니다!");
                      return;
                    }
                  } else {
                    signUp();
                  }
                }}
              >
                가입하기
              </Button>
            </ButtonWrap>
          </MobileSignUpContainer>
        </MobileContainer>
        {modal && (
          <MobileModal
            title={"회원가입 신청이 완료되었습니다."}
            content={"승인완료까지는 2~3일 정도 소요됩니다."}
            button={[
              {
                title: "홈으로 이동하기",
                type: "active",
              },
            ]}
            onPress={() => {
              navigate("/");
            }}
          />
        )}
        {authModal && (
          <MobileModal
            padding
            title={"입력시간 초과"}
            content={`인증번호 입력시간이 초과되었습니다.
            다시 입력해주세요.`}
            button={[
              {
                title: "재전송",
                type: "active",
              },
            ]}
            onPress={() => {
              setAuthModal(false);
              start();
            }}
          />
        )}
        {authFailModal && (
          <MobileModal
            padding
            title={"인증실패"}
            content={`입력한 인증번호가 일치하지 않습니다.
            다시 입력해주세요.`}
            button={[
              {
                title: "확인",
                type: "active",
              },
            ]}
            onPress={() => {
              setAuthFailModal(false);
            }}
          />
        )}
        {authSuccessModal && (
          <MobileModal
            padding
            title={"인증완료"}
            content={"인증이 완료되었습니다."}
            button={[
              {
                title: "확인",
                type: "active",
              },
            ]}
            onPress={() => {
              setAuthSuccessMoal(false);
            }}
          />
        )}
        {isDuplicateNumber && (
          <MobileModal
            padding
            title={"오류"}
            content={"이미 가입된 휴대전화번호입니다."}
            button={[
              {
                title: "확인",
                type: "active",
              },
            ]}
            onPress={() => {
              setIsDuplicateNumber(false);
            }}
          />
        )}
        {wrongPhoneNumber && (
          <MobileModal
            padding
            title={"오류"}
            content={"잘못된 휴대전화번호입니다."}
            button={[
              {
                title: "확인",
                type: "active",
              },
            ]}
            onPress={() => {
              setWrongPhoneNumber(false);
            }}
          />
        )}
      </Mobile>
    </>
  );
};

export default TeacherSignUp;
