import React from "react";
import styled from "styled-components";

const Container = styled.div`
  position: relative;
`;

const Input = styled.input`
  width: calc(100% - 36px);
  border: 1px solid ${(props) => (props.value !== "" ? "#021633" : "#dde2ea")};
  border-radius: 8px;
  padding: 20px 18px;
  font-size: 16px;
  font-weight: 500;
  color: #212529;
  font-family: "Noto Sans KR";
  background-color: #ffffff;
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : 16)}px;
  height: 60px;
  box-sizing: border-box;
  line-height: 28px;

  &::placeholder {
    color: #adb5bd;
    font-family: "Noto Sans KR";
  }

  &:focus {
    outline: none;
    border: 1px solid #ffad2e;
  }

  &:disabled {
    background-color: #e9ecef;
    color: #868e96;
  }
`;

const UnitText = styled.div`
  position: absolute;
  top: 16px;
  right: 20px;
  color: #021633;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
`;

const FocusUnit = styled.div`
  position: absolute;
  top: 18px;
  right: 20px;
  color: ${(props) => (props.value !== "" ? "#021633" : "#ADB5BD")};
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
`;

const UnitAuth = styled.div`
  position: absolute;
  top: 18px;
  right: 20px;
  color: ${(props) =>
    props.countDown
      ? "#FF383B"
      : props.value !== ""
      ? "#FFAD2E"
      : props.unitFocus
      ? "#FFAD2E"
      : "#ADB5BD"};
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  &:focus {
    color: #111111;
  }
`;

const PointUnit = styled.div`
  position: absolute;
  top: 18px;
  right: 20px;
  color: ${(props) => (props.usePoint ? "#212529" : "#0081f5")};
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  cursor: pointer;
`;

const TextInputLabel = styled.div`
  color: ${(props) => (props.error ? "#FF383B" : "#0081F5")};
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : 16)}px;
`;

const TextInput = ({
  placeholder,
  onChange,
  value,
  marginBottom,
  maxLength,
  style,
  disabled,
  unit,
  onKeyPress,
  type,
  label,
  error,
  unitAuth,
  unitFocus,
  onFocus,
  onBlur,
  countDown,
  focusUnit,
  pattern,
  min,
  max,
  containerStyle,
  pointUnit,
  usePoint,
  onClick,
  unitstyle,
}) => {
  return (
    <div>
      <Container style={containerStyle}>
        <Input
          pattern={pattern}
          min={min}
          max={max}
          placeholder={placeholder}
          value={value}
          onChange={(e) => {
            onChange(e.target.value);
          }}
          marginBottom={label !== undefined ? "8px" : marginBottom}
          maxLength={maxLength}
          style={style}
          disabled={disabled}
          onKeyPress={onKeyPress}
          type={type ? type : "text"}
          onFocus={onFocus}
          onBlur={onBlur}
        />
        {unit && <UnitText style={unitstyle}>{unit}</UnitText>}
        {focusUnit && <FocusUnit value={value}>{focusUnit}</FocusUnit>}
        {unitAuth && (
          <UnitAuth value={value} unitFocus={unitFocus} countDown={countDown}>
            {unitAuth}
          </UnitAuth>
        )}
        {pointUnit && (
          <PointUnit usePoint={usePoint} onClick={onClick}>
            {pointUnit}
          </PointUnit>
        )}
      </Container>
      {label && (
        <TextInputLabel marginBottom={marginBottom} error={error}>
          {label}
        </TextInputLabel>
      )}
    </div>
  );
};

export default TextInput;
