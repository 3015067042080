import { useMediaQuery } from "react-responsive";

const Desktop = ({ children }) => {
  const isDestop = useMediaQuery({ minWidth: "721px" }, undefined, () => {
    window.location.reload();
  });
  return isDestop ? children : null;
};

const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: "720px" }, undefined, () => {
    window.location.reload();
  });
  return isMobile ? children : null;
};

export { Desktop, Mobile };
