import React, { useState } from "react";
import styled from "styled-components";

import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Modal from "../../components/Modal";

import VIPImage from "../../assets/images/main_subimage_3.jpeg";

const Container = styled.div`
  width: 100%;
`;
const VipWrap = styled.div`
  padding: 200px 416px 260px 416px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: 1280px) {
    padding: 200px 216px 260px 216px;
  }
`;
const TitleWrap = styled.div`
  text-align: center;
  margin-bottom: 47px;
`;
const Title = styled.h2`
  font-size: 40px;
  font-weight: ${(props) => props.theme.fontWeight.bold};
  color: ${(props) => props.theme.color.textColor};
  margin-bottom: 11px;
`;
const SubTitle = styled.span`
  font-size: 24px;
  color: ${(props) => props.theme.color.textColor};
  line-height: 32px;
`;
const VipImage = styled.img`
  width: 1088px;
  height: 612px;
  border-radius: 12px;
  margin-bottom: 80px;
  object-fit: cover;
  @media screen and (max-width: 1280px) {
    width: 845px;
    height: 480px;
  }
`;
const DescWrap = styled.div`
  margin-bottom: 120px;
`;
const VipDescTitle = styled.span`
  display: inline-block;
  font-size: 24px;
  font-weight: ${(props) => props.theme.fontWeight.medium};
  color: ${(props) => props.theme.color.textColor};
  border-bottom: 2px solid ${(props) => props.theme.color.textColor};
  padding-bottom: 11px;
  margin-bottom: 32px;
`;
const VipDesc = styled.p`
  font-size: 20px;
  font-weight: ${(props) => props.theme.fontWeight.regular};
  line-height: 32px;
  color: ${(props) => props.theme.color.textColor};
`;
const VipButtonWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const VipButton = styled.button`
  width: 260px;
  height: 60px;
  color: ${(props) => props.theme.color.white};
  background-color: ${(props) => props.theme.color.orange};
  border: none;
  border-radius: 10px;
`;
const ModalContent = styled.span`
  text-align: center;
  color: ${(props) =>
    props.orange ? props.theme.color.orange : props.theme.color.textColor};
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  white-space: pre-line;
`;

const VipApplication = () => {
  const [openModal, setOpenModal] = useState(false);

  return (
    <div>
      <Header />
      <Container>
        <VipWrap>
          <TitleWrap>
            <Title>신혜인 소장과 함께하는</Title>
            <SubTitle>A to Z 스폐셜 관리</SubTitle>
          </TitleWrap>
          <VipImage src={VIPImage} />
          <DescWrap>
            {/* <VipDescTitle>많이 평화스러운 긴지라 대한 것이다.</VipDescTitle> */}
            <VipDesc>
              진로탐색 / GPA관리 / 비교과(교내외대회준비/다양한 경험 및 경력관리) /국내,국외 진학 및 지원서류관리<br />
              성공을 위한 모든 것을 지원합니다. <br />
              -	학생컨설팅 및 학부모 면담을 통해 철저한 심의 후 회원가입 <br />
              -	국내외 명문대(고등)진학을 목표로 장기프랜으로 진행합니다 <br />
              -	교과/비교과 분야별 전문가(해외대학 및 석박과정멘토/전직교원/글로벌단체/학회/기업등)와 함께 체계적이고 전문적으로 진행됩니다. <br />
            </VipDesc>
          </DescWrap>
          <VipButtonWrap>
            <VipButton
              onClick={() => {
                setOpenModal(true);
              }}
            >
              문의하기
            </VipButton>
          </VipButtonWrap>
        </VipWrap>
        <Footer />
      </Container>
      {openModal ? (
        <Modal
          title={"전화상담"}
          htmlContnet={
            <ModalContent>
              {" 고객센터 "}
              <ModalContent orange>02-536-5862</ModalContent>로 문의
              가능합니다.
            </ModalContent>
          }
          button={[
            {
              title: "확인",
              type: "active",
            },
          ]}
          onPress={() => {
            setOpenModal(false);
          }}
        />
      ) : null}
    </div>
  );
};

export default VipApplication;
