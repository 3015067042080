import React, { useEffect, useState } from "react";
import "../contentsMarket/style/slick.css";
import "../contentsMarket/style/slick-theme.css";

import Footer from "../../components/Footer";
import Header from "../../components/Header";

import dayjs from "dayjs";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../../API";
import { useRecoilValue } from "recoil";
import userInfoState from "../../states/userInfoState";
import { Desktop, Mobile } from "../../utils/mediaQuery";
import MobileHeader from "../../components/MobileHeader";
import {
  MobileDetailContainer,
  MobileDetailImageWrap,
  MobileDetailTitle,
  MobileDetailContent,
} from "./mobile.style";
import {
  DetailContainer,
  DetailWrap,
  MarketItemPrice,
  RowWrap,
  DetailTitle,
  DetailBody,
  DetailImageWrap,
  DetailContent,
  Button,
} from "./style";

const BenefitDetail = () => {
  const [show, setShow] = useState(true);
  const [item, setItem] = useState(null);
  const params = useParams();
  const navigate = useNavigate();
  const userInfo = useRecoilValue(userInfoState);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    autoplay: false,
    autoplaySpeed: 3000,
    variableWidth: true,
    pauseOnFocus: true,
  };

  const getData = () => {
    axios({
      method: "GET",
      url: `${API_URL}/benefit/${params.id}`,
    }).then((res) => {
      if (res.data.success) {
        setItem(res.data.result.item);
      } else {
        alert(res.data.error.message);
        navigate(-1);
      }
    });
  };

  useEffect(() => {
    if (params.id) {
      getData();
    }
  }, [params.id]);

  const downloadFile = (url) => {
    axios({
      url,
      baseURL: "",
      method: "GET",
      responseType: "blob",
      headers: {
        Authorization: "",
      },
    }).then((res) => {
      const url = window.URL.createObjectURL(res.data);
      const a = document.createElement("a");
      const filename = url.split("/")[url.split("/").length - 1];

      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();

      setTimeout(() => {
        window.URL.revokeObjectURL(url);
      }, 60000);

      a.remove();
    });

    axios({
      method: "POST",
      url: `${API_URL}/contentsMarket/${params.id}/download`,
    });
  };

  return (
    <>
      <Desktop>
        <Header activeTab={"Benefit"} />
        {item !== null && (
          <DetailContainer>
            <DetailWrap>
              <DetailTitle>{item.title}</DetailTitle>
              <MarketItemPrice>
                { dayjs(item.createdAt).format('YYYY-MM-DD HH:mm:ss') }
              </MarketItemPrice>
              <DetailBody>
                <DetailImageWrap>
                  <img
                    alt=""
                    src={
                      item.thumbnail
                        ? item.thumbnail
                        : require("../../assets/logos/logo_symbol_type.png")
                    }
                    style={{
                      maxWidth: "100%",
                      marginBottom: "1rem",
                      maxHeight: "100%",
                    }}
                  />
                  {item.attachments.length > 0 && (
                  <Button
                    onClick={() => {
                      item.attachments.forEach((attachment) => {
                        downloadFile(attachment);
                      });
                    }}
                  >
                    첨부파일 다운받기
                  </Button>
                )}
                </DetailImageWrap>
                <DetailContent>{item.content}</DetailContent>
              </DetailBody>
              <RowWrap style={{ justifyContent: "center" }}>
                <Button
                  onClick={() => {
                    navigate("/benefit");
                  }}
                  gray
                >
                  목록으로
                </Button>
              </RowWrap>
            </DetailWrap>
          </DetailContainer>
        )}
        <Footer />
      </Desktop>
      <Mobile>
        <MobileHeader name={"컨텐츠마켓"} type={"sub"} />
        {item !== null && (
          <MobileDetailContainer>
            <MobileDetailTitle>{item.title}</MobileDetailTitle>
            <MarketItemPrice>
              { dayjs(item.createdAt).format('YYYY-MM-DD HH:mm:ss') }
            </MarketItemPrice>
            <MobileDetailImageWrap>
              <img
                alt=""
                src={
                  item.thumbnail
                    ? item.thumbnail
                    : require("../../assets/logos/logo_symbol_type.png")
                }
                style={{ maxWidth: "100%", maxHeight: "100%" }}
              />
            </MobileDetailImageWrap>
            <MobileDetailContent>{item.content}</MobileDetailContent>
            {item.attachments.length > 0 && (
              <Button
                onClick={() => {
                  item.attachments.forEach((attachment) => {
                    downloadFile(attachment);
                  });
                }}
              >
                첨부파일 다운받기
              </Button>
            )}
          </MobileDetailContainer>
        )}
      </Mobile>
    </>
  );
};

export default BenefitDetail;
