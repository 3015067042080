import react, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import { API_URL } from "../../../API";

import Header from "../../../components/Header";
import Footer from "../../../components/Footer";

import { ReactComponent as Plus } from "../../../assets/icons/plus.svg";
import Close from "../../../assets/icons/cancle_18px.svg";
import Close44 from "../../../assets/icons/cancle_44px.svg";
import NoneProfile from "../../../assets/icons/profile_image.svg";
import { useNavigate, useParams } from "react-router-dom";
import formatTimeFromNow from "../../../utils/formatTimeFromNow";
import { Desktop, Mobile } from "../../../utils/mediaQuery";
import MobileHeader from "../../../components/MobileHeader";

const Container = styled.div`
  width: 812px;
  margin: 0 auto;
  padding: 200px 0 260px 0;

  @media (max-width: 1080px) {
    width: 100%;
    padding: 24px;
    padding-top: 84px;
  }
`;

const BadgeWrap = styled.div`
  display: flex;
`;
const QnaBadge = styled.div`
  width: fit-content;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eef0f4;
  border-radius: 8px;
  font-size: 16px;
  font-weight: ${(props) => props.theme.fontWeight.medium};
  color: #021633;
  padding: 4px 20px;
  margin-bottom: 12px;
  margin-right: 12px;
`;
const PointBadge = styled.div`
  width: fit-content;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background-color: rgba(51, 216, 78, 0.1);
  color: #33d84e;
  font-size: 16px;
  font-weight: ${(props) => props.theme.fontWeight.medium};
  padding: 4px 16px;
`;
const Title = styled.h3`
  font-size: 32px;
  font-weight: ${(props) => props.theme.fontWeight.bold};
  line-height: 47px;
  color: #212529;
  margin-bottom: 11px;

  @media (max-width: 1080px) {
    font-size: 18px;
    line-height: 27px;
  }
`;
const SubTitle = styled.span`
  display: inline-block;
  font-size: 20px;
  font-weight: ${(props) => props.theme.fontWeight.medium};
  line-height: 29px;
  color: ${(props) => (props.grey ? "#ADB5BD" : "#212529")};
  margin-bottom: 32px;
  margin-right: ${(props) => (props.marginRight ? "16px" : "0px")};

  @media (max-width: 1080px) {
    font-size: 14px;
    line-height: 20px;
  }
`;
const Content = styled.p`
  font-size: 16px;
  line-height: 28px;
  color: #212529;
  margin-bottom: 60px;
  white-space: pre-wrap;
`;
const CommentLength = styled.div`
  width: 812px;
  font-size: 20px;
  line-height: 29px;
  color: #212529;
  padding-bottom: 32px;
  border-bottom: 2px solid #e9ecef;
`;
const NoComment = styled.div`
  width: 812px;
  text-align: center;
  font-size: 20px;
  font-weight: ${(props) => props.theme.fontWeight.medium};
  color: #adb5bd;
  line-height: 29px;
  margin-bottom: 50px;
  margin-top: 48px;
`;
const WriteCommentWrap = styled.div`
  width: 812px;
  height: 100%;
  border-radius: 8px;
  border: 1px solid #adb5bd;
  padding: 20px;
`;
const TextArea = styled.textarea`
  width: 772px;
  min-height: 98px;
  border: none;
  border-bottom: 2px solid #e9ecef;
  resize: none;
  box-sizing: border-box;
  outline: none;
  font-size: 14px;
  line-height: 26px;
  color: #212529;
  margin-bottom: 20px;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  ::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }

  ::placeholder {
    color: #adb5bd;
    font-size: 14px;
  }
`;
const ImgText = styled.div`
  color: #adb5bd;
  font-size: 14px;
  margin-bottom: 8px;
  line-height: 20px;
`;

const AddImage = styled.div`
  width: 133px;
  height: 100px;
  background-color: #ffffff;
  border-radius: 8px;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23333' stroke-width='4' stroke-dasharray='4%2c10 ' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e");
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-bottom: 16px;
  margin-right: 13px;
`;
const FileInput = styled.input`
  position: absolute;
  width: 0;
  height: 0;
  padding: 0;
  overflow: hidden;
  border: 0;
`;

const FileLabel = styled.label`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
`;
const ImagePreviewWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
const ImagePreviewContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  :nth-child(5n) {
    margin-right: 20px;
  }
`;
const ImagePreviewBlock = styled.div`
  width: 133px;
  height: 100px;
  position: relative;
  margin-bottom: 16px;
  margin-right: 13px;
`;
const ImagePreview = styled.img`
  width: 133px;
  height: 100px;
  border-radius: 8px;
  background-image: ${(props) => (props.image ? props.image : null)};
`;
const CloseBtn = styled.img`
  width: 18px;
  height: 18px;
  position: absolute;
  right: 8px;
  top: 8px;
  cursor: pointer;
`;
const SubmitButtonWrap = styled.div`
  width: 812px;
  display: flex;
  justify-content: flex-end;
`;
const SubmitButton = styled.button`
  width: 100px;
  height: 40px;
  border: none;
  outline: none;
  background-color: ${(props) => (props.complete ? "#021633" : "#F4F5F7")};
  color: ${(props) => (props.complete ? "#ffffff" : "#ADB5BD")};
  border-radius: 8px;
  margin-top: 20px;
  transition: 0.7s;
`;
const CommentContainer = styled.div``;
const CommentWrap = styled.div`
  width: 812px;
  padding-bottom: 48px;
  border-bottom: 2px solid #e9ecef;
  margin-top: 48px;

  &:last-child {
    padding-bottom: 0px;
    margin-bottom: 60px;
    border-bottom: none;
  }
`;
const ProfileContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
const ProfileWrap = styled.div`
  display: flex;
  margin-bottom: 32px;
`;
const ProfileImg = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 60px;
  overflow: hidden;
  margin-right: 16px;
`;
const ProfileNameWrap = styled.div`
  display: flex;
  flex-direction: column;
`;
const ProfileName = styled.span`
  font-size: 20px;
  font-weight: ${(props) => props.theme.fontWeight.medium};
  color: #212529;
  margin-bottom: 4px;
  line-height: 29px;
`;
const ProfileDate = styled.span`
  font-size: 16px;
  font-weight: ${(props) => props.theme.fontWeight.medium};
  color: #adb5bd;
  line-height: 24px;
`;
const SelectionBadge = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(99, 55, 252, 0.1);
  color: #6337fc;
  border-radius: 4px;
  padding: 4px 14px;
  height: 28px;
  margin-top: 3px;
  margin-left: 12px;
  font-size: 14px;
  font-weight: ${(props) => props.theme.fontWeight.medium};
  line-height: 20px;
`;
const Comment = styled.p`
  font-size: 16px;
  line-height: 28px;
  color: #212529;
  margin-bottom: 32px;
  margin-left: 76px;
  width: 736px;
  white-space: pre-wrap;
`;
const SelectionButton = styled.button`
  width: 100px;
  height: 40px;
  border: none;
  outline: none;
  font-size: 14px;
  font-weight: ${(props) => props.theme.fontWeight.medium};
  background-color: #021633;
  color: #ffffff;
  border-radius: 8px;
  margin-left: 76px;
  margin-top: ${(props) => (props.array.length > 0 ? "16px" : "0px")};
`;
const MyCommentBtnWrap = styled.div`
  display: flex;
`;
const MyCommentBtn = styled.div`
  font-size: 16px;
  line-height: 24px;
  height: 24px;
  font-weight: ${(props) => props.theme.fontWeight.medium};
  text-decoration: underline;
  color: ${(props) => (props.delete ? "#FF383B" : "#212529")};
  margin-left: ${(props) => (props.delete ? "20px" : "0px")};
  cursor: pointer;
`;
const CommentImgWrap = styled.div`
  width: 812px;
  display: flex;
  flex-wrap: wrap;
  margin-left: 76px;
`;
const CommentImg = styled.img`
  width: 360px;
  height: 270px;
  margin-right: 16px;
  margin-bottom: 16px;
  cursor: pointer;

  &:nth-child(2n) {
    margin-right: 0px;
  }
`;
const ModalBackground = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(33, 37, 41, 0.8);
  z-index: 33;
`;
const ImgModal = styled.div`
  width: 812px;
  height: auto;
  border-radius: 12px;
  background: url(${(props) => props.src});
  background-size: cover;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 34;
`;
const CancelBtn = styled.img`
  width: 44px;
  height: 44px;
  position: absolute;
  top: 24px;
  right: 24px;
  cursor: pointer;
`;

const ImageContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 16px;
  grid-auto-rows: minmax(100px, auto);
  margin-bottom: 30px;

  img {
    width: 100%;
    display: block;
    height: auto;
    border-radius: 16px;
  }
`;

const array = [{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }, { id: 5 }];

const QnaDetail = () => {
  const [commentValue, setCommentValue] = useState("");
  const [files, setFiles] = useState([]);
  const [originalFiles, setOriginalFiles] = useState([]);
  const [complete, setComplete] = useState(false);
  const [commentVisible, setCommentVisible] = useState(true);
  const [commentImgModal, setCommentImgModal] = useState(false);
  const [commentImg, setCommentImg] = useState("");
  const [modify, setModify] = useState("");
  const [qna, setQNA] = useState(null);
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (originalFiles && originalFiles.length) {
      Promise.all(
        Array.from(originalFiles).map(async (item) => {
          const formData = new FormData();
          console.log(item.size);
          if (item.size <= 20 * 1024 * 1024) {
            formData.append("file", item);
          }
          const result = await axios.post(`${API_URL}/file/upload`, formData);
          if (result) {
            return {
              url: result.data.result.url,
              name: item.name,
            };
          }
        })
      ).then((res) => {
        setFiles([...files, ...res]);
      });
    }
  }, [originalFiles]);

  useEffect(() => {
    const fileArray = files.map((item) => item.url);
    console.log(fileArray);
  }, [files]);

  const createComment = () => {
    axios({
      url: `${API_URL}/qna/${params.id}/answer`,
      method: "POST",
      data: {
        contents: commentValue,
        attachmentUrls: files.map((file) => file.url),
      },
    })
      .then((res) => {
        if (res.data.success) {
          alert("답변이 등록되었습니다!");
          getData();
        } else {
          alert(res.data.error.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const modifyComment = () => {
    axios({
      url: `${API_URL}/comment/patch/`,
    });
  };

  const getData = () => {
    axios({
      method: "GET",
      url: `${API_URL}/qna/${params.id}`,
    }).then((res) => {
      if (res.data.success) {
        setQNA(res.data.result.qna);
        setCommentVisible(!res.data.result.qna.isWriter);
      } else {
        alert(res.data.error.message);
        navigate(-1);
      }
    });
  };

  useEffect(() => {
    if (params.id) {
      getData();
    }
  }, [params.id]);

  return (
    <>
      <Desktop>
        {commentImgModal ? <ModalBackground /> : null}
        <Header activeTab={"QnA"} />
        {qna !== null && (
          <Container>
            <BadgeWrap>
              <QnaBadge>{qna.category.name}</QnaBadge>
              {qna.point !== 0 && <PointBadge>{qna.point} 포인트</PointBadge>}
            </BadgeWrap>
            <Title>{qna.title}</Title>
            <SubTitle marginRight>{qna.writer.nickname}</SubTitle>
            <SubTitle grey>{formatTimeFromNow(qna.createdAt)}</SubTitle>
            <Content>{qna.contents}</Content>
            <ImageContainer>
              {qna.attachments.map((attachment) => (
                <img
                  key={attachment.id}
                  src={attachment.url}
                  alt={attachment.id}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setCommentImgModal(true);
                    setCommentImg(attachment.url);
                  }}
                />
              ))}
            </ImageContainer>
            <CommentLength>댓글 {qna.answers.length}개</CommentLength>
            <CommentContainer>
              {qna.answers.map((item) => {
                return (
                  <CommentWrap key={item.id}>
                    <ProfileContainer>
                      <ProfileWrap>
                        <ProfileImg
                          src={item.writer.profileImage || NoneProfile}
                        ></ProfileImg>
                        <ProfileNameWrap
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            if (item.writer.type === "TEACHER") {
                              navigate(
                                `/mentoPick/profile/${item.writer.mentopick.id}`
                              );
                            }
                          }}
                        >
                          <ProfileName>{item.writer.nickname}</ProfileName>
                          <ProfileDate>
                            {formatTimeFromNow(item.createdAt)}
                          </ProfileDate>
                        </ProfileNameWrap>
                        {item.writer.type === "TEACHER" && (
                          <SelectionBadge>강사</SelectionBadge>
                        )}
                      </ProfileWrap>

                      {/* {modify !== item.id ? (
                        <MyCommentBtnWrap>
                          <MyCommentBtn
                            onClick={() => {
                              setModify(item.id);
                              console.log(item.id);
                            }}
                          >
                            수정
                          </MyCommentBtn>
                          <MyCommentBtn delete>삭제</MyCommentBtn>
                        </MyCommentBtnWrap>
                      ) : null} */}
                    </ProfileContainer>
                    {modify === item.id ? (
                      <>
                        <WriteCommentWrap>
                          <TextArea
                            placeholder="최대 500자까지 입력가능합니다."
                            maxLength={500}
                            value={commentValue}
                            onChange={(e) => {
                              setCommentValue(e.target.value);
                              if (e.target.value !== "") {
                                setComplete(true);
                              } else {
                                setComplete(false);
                              }
                            }}
                          />
                          <ImgText>
                            첨부파일은 20MB 이하의 JPG, PNG 파일로만 업로드
                            가능합니다.
                          </ImgText>
                          <ImagePreviewWrap>
                            <AddImage>
                              <FileLabel htmlFor="file" />
                              <FileInput
                                type="file"
                                id="file"
                                multiple="multiple"
                                accept=".jpg, .jpeg, .png"
                                onChange={(e) => {
                                  setOriginalFiles(e.target.files);
                                }}
                              />
                              <Plus />
                            </AddImage>
                            {/* <ImagePreviewContainer> */}
                            {files.map((image) => {
                              return (
                                <ImagePreviewBlock key={image.url}>
                                  <ImagePreview src={image.url} />
                                  <CloseBtn
                                    src={Close}
                                    onClick={() => {
                                      setFiles(
                                        files.filter((item) => {
                                          return item.url !== image.url;
                                        })
                                      );
                                    }}
                                  />
                                </ImagePreviewBlock>
                              );
                            })}
                            {/* </ImagePreviewContainer> */}
                          </ImagePreviewWrap>
                        </WriteCommentWrap>
                        <SubmitButtonWrap>
                          <SubmitButton
                            disabled={!complete}
                            complete={complete}
                            onClick={() => {
                              setModify(false);
                              modifyComment();
                              window.location.reload();
                            }}
                          >
                            수정하기
                          </SubmitButton>
                        </SubmitButtonWrap>
                      </>
                    ) : null}
                    {modify !== item.id ? (
                      <>
                        <Comment>{item.contents}</Comment>
                        <CommentImgWrap>
                          {item.attachments.map((item) => {
                            return (
                              <CommentImg
                                src={item.url}
                                key={item.id}
                                onClick={() => {
                                  setCommentImgModal(true);
                                  setCommentImg(item.url);
                                }}
                              />
                            );
                          })}
                        </CommentImgWrap>
                        {/* <SelectionButton array={array}>채택하기</SelectionButton> */}
                      </>
                    ) : null}
                  </CommentWrap>
                );
              })}
            </CommentContainer>
            {qna.answers.length === 0 ? (
              <NoComment>작성된 댓글이 없습니다.</NoComment>
            ) : null}

            {commentVisible ? (
              <>
                <WriteCommentWrap>
                  <TextArea
                    placeholder="최대 500자까지 입력가능합니다."
                    maxLength={500}
                    value={commentValue}
                    onChange={(e) => {
                      setCommentValue(e.target.value);

                      if (e.target.value !== "") {
                        setComplete(true);
                      } else {
                        setComplete(false);
                      }
                    }}
                  />
                  <ImgText>
                    첨부파일은 20MB 이하의 JPG, PNG 파일로만 업로드 가능합니다.
                  </ImgText>
                  <ImagePreviewWrap>
                    <AddImage>
                      <FileLabel htmlFor="file" />
                      <FileInput
                        type="file"
                        id="file"
                        multiple="multiple"
                        accept=".jpg, .jpeg, .png"
                        onChange={(e) => {
                          setOriginalFiles(e.target.files);
                        }}
                      />
                      <Plus />
                    </AddImage>
                    {files.map((image) => {
                      return (
                        <ImagePreviewBlock key={image.url}>
                          <ImagePreview src={image.url} />
                          <CloseBtn
                            src={Close}
                            onClick={() => {
                              setFiles(
                                files.filter((item) => {
                                  return item.url !== image.url;
                                })
                              );
                            }}
                          />
                        </ImagePreviewBlock>
                      );
                    })}
                  </ImagePreviewWrap>
                </WriteCommentWrap>
                <SubmitButtonWrap>
                  <SubmitButton
                    disabled={!complete}
                    complete={complete}
                    onClick={() => {
                      createComment();
                    }}
                  >
                    작성하기
                  </SubmitButton>
                </SubmitButtonWrap>
              </>
            ) : null}
            {commentImgModal ? (
              <ImgModal>
                <img
                  alt=""
                  src={commentImg}
                  width={"100%"}
                  height={"auto"}
                  style={{ borderRadius: 12 }}
                />
                <CancelBtn
                  src={Close44}
                  onClick={() => {
                    setCommentImgModal(false);
                  }}
                />
              </ImgModal>
            ) : null}
          </Container>
        )}
      </Desktop>
      <Mobile>
        <MobileHeader name={"질의응답"} type={"sub"} />
        {qna !== null && (
          <Container>
            <BadgeWrap>
              <QnaBadge>{qna.category.name}</QnaBadge>
              {qna.point !== 0 && <PointBadge>{qna.point} 포인트</PointBadge>}
            </BadgeWrap>
            <Title>{qna.title}</Title>
            <SubTitle marginRight>{qna.writer.nickname}</SubTitle>
            <SubTitle grey>{formatTimeFromNow(qna.createdAt)}</SubTitle>
            <Content>{qna.contents}</Content>
            <ImageContainer>
              {qna.attachments.map((attachment) => (
                <img
                  key={attachment.id}
                  src={attachment.url}
                  alt={attachment.id}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setCommentImgModal(true);
                    setCommentImg(attachment.url);
                  }}
                />
              ))}
            </ImageContainer>
            {/* <CommentLength>댓글 {qna.answers.length}개</CommentLength>
            <CommentContainer>
              {qna.answers.map((item) => {
                return (
                  <CommentWrap key={item.id}>
                    <ProfileContainer>
                      <ProfileWrap>
                        <ProfileImg
                          src={item.writer.profileImage || NoneProfile}
                        ></ProfileImg>
                        <ProfileNameWrap
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            if (item.writer.type === "TEACHER") {
                              navigate(
                                `/mentoPick/profile/${item.writer.mentopick.id}`
                              );
                            }
                          }}
                        >
                          <ProfileName>{item.writer.nickname}</ProfileName>
                          <ProfileDate>
                            {formatTimeFromNow(item.createdAt)}
                          </ProfileDate>
                        </ProfileNameWrap>
                        {item.writer.type === "TEACHER" && (
                          <SelectionBadge>강사</SelectionBadge>
                        )}
                      </ProfileWrap>

                      {modify !== item.id ? (
                        <MyCommentBtnWrap>
                          <MyCommentBtn
                            onClick={() => {
                              setModify(item.id);
                              console.log(item.id);
                            }}
                          >
                            수정
                          </MyCommentBtn>
                          <MyCommentBtn delete>삭제</MyCommentBtn>
                        </MyCommentBtnWrap>
                      ) : null}
                    </ProfileContainer>
                    {modify === item.id ? (
                      <>
                        <WriteCommentWrap>
                          <TextArea
                            placeholder="최대 500자까지 입력가능합니다."
                            maxLength={500}
                            value={commentValue}
                            onChange={(e) => {
                              setCommentValue(e.target.value);
                              if (e.target.value !== "") {
                                setComplete(true);
                              } else {
                                setComplete(false);
                              }
                            }}
                          />
                          <ImgText>
                            첨부파일은 20MB 이하의 JPG, PNG 파일로만 업로드
                            가능합니다.
                          </ImgText>
                          <ImagePreviewWrap>
                            <AddImage>
                              <FileLabel htmlFor="file" />
                              <FileInput
                                type="file"
                                id="file"
                                multiple="multiple"
                                accept=".jpg, .jpeg, .png"
                                onChange={(e) => {
                                  setOriginalFiles(e.target.files);
                                }}
                              />
                              <Plus />
                            </AddImage>
                            <ImagePreviewContainer>
                            {files.map((image) => {
                              return (
                                <ImagePreviewBlock key={image.url}>
                                  <ImagePreview src={image.url} />
                                  <CloseBtn
                                    src={Close}
                                    onClick={() => {
                                      setFiles(
                                        files.filter((item) => {
                                          return item.url !== image.url;
                                        })
                                      );
                                    }}
                                  />
                                </ImagePreviewBlock>
                              );
                            })}
                            </ImagePreviewContainer>
                          </ImagePreviewWrap>
                        </WriteCommentWrap>
                        <SubmitButtonWrap>
                          <SubmitButton
                            disabled={!complete}
                            complete={complete}
                            onClick={() => {
                              setModify(false);
                              modifyComment();
                              window.location.reload();
                            }}
                          >
                            수정하기
                          </SubmitButton>
                        </SubmitButtonWrap>
                      </>
                    ) : null}
                    {modify !== item.id ? (
                      <>
                        <Comment>{item.contents}</Comment>
                        <CommentImgWrap>
                          {item.attachments.map((item) => {
                            return (
                              <CommentImg
                                src={item.url}
                                key={item.id}
                                onClick={() => {
                                  setCommentImgModal(true);
                                  setCommentImg(item.url);
                                }}
                              />
                            );
                          })}
                        </CommentImgWrap>
                        <SelectionButton array={array}>채택하기</SelectionButton>
                      </>
                    ) : null}
                  </CommentWrap>
                );
              })}
            </CommentContainer> */}
            {/* {qna.answers.length === 0 ? (
              <NoComment>작성된 댓글이 없습니다.</NoComment>
            ) : null}

            {commentVisible ? (
              <>
                <WriteCommentWrap>
                  <TextArea
                    placeholder="최대 500자까지 입력가능합니다."
                    maxLength={500}
                    value={commentValue}
                    onChange={(e) => {
                      setCommentValue(e.target.value);

                      if (e.target.value !== "") {
                        setComplete(true);
                      } else {
                        setComplete(false);
                      }
                    }}
                  />
                  <ImgText>
                    첨부파일은 20MB 이하의 JPG, PNG 파일로만 업로드 가능합니다.
                  </ImgText>
                  <ImagePreviewWrap>
                    <AddImage>
                      <FileLabel htmlFor="file" />
                      <FileInput
                        type="file"
                        id="file"
                        multiple="multiple"
                        accept=".jpg, .jpeg, .png"
                        onChange={(e) => {
                          setOriginalFiles(e.target.files);
                        }}
                      />
                      <Plus />
                    </AddImage>
                    {files.map((image) => {
                      return (
                        <ImagePreviewBlock key={image.url}>
                          <ImagePreview src={image.url} />
                          <CloseBtn
                            src={Close}
                            onClick={() => {
                              setFiles(
                                files.filter((item) => {
                                  return item.url !== image.url;
                                })
                              );
                            }}
                          />
                        </ImagePreviewBlock>
                      );
                    })}
                  </ImagePreviewWrap>
                </WriteCommentWrap>
                <SubmitButtonWrap>
                  <SubmitButton
                    disabled={!complete}
                    complete={complete}
                    onClick={() => {
                      createComment();
                    }}
                  >
                    작성하기
                  </SubmitButton>
                </SubmitButtonWrap>
              </>
            ) : null}
            {commentImgModal ? (
              <ImgModal>
                <img
                  alt=""
                  src={commentImg}
                  width={"100%"}
                  height={"auto"}
                  style={{ borderRadius: 12 }}
                />
                <CancelBtn
                  src={Close44}
                  onClick={() => {
                    setCommentImgModal(false);
                  }}
                />
              </ImgModal>
            ) : null} */}
          </Container>
        )}
      </Mobile>
    </>
  );
};

export default QnaDetail;
