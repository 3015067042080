import React, { useEffect } from "react";
import styled from "styled-components";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import YouTube from "react-youtube";

import ArrowLeft from "../../../assets/icons/arrow_left.svg";

const Container = styled.div`
  padding-top: 20px;
`;
const BackArrow = styled.img.attrs({
  src: `${ArrowLeft}`,
})`
  width: 24px;
  height: 24px;
  cursor: pointer;
`;
const TitleWrap = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 7px;
`;
const Title = styled.h5`
  font-size: 24px;
  font-weight: ${(props) => props.theme.fontWeight.bold};
  color: ${(props) => props.theme.color.textColor};
  line-height: 36px;
  max-width: 390px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-left: 4px;
`;
const TitleDate = styled.span`
  font-size: 20px;
  font-weight: ${(props) => props.theme.fontWeight.medium};
  color: #adb5bd;
  height: 29px;
  margin-left: 28px;
`;
const VideoWrap = styled.div`
  width: 812px;
  height: 457px;
  overflow: hidden;
  border-radius: 10px;
  margin-top: 32px;
`;

const opts = {
  height: "457",
  width: "812",
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 0,
  },
};

const LectureVideoDetail = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const video = location.state.video;
  const videoId = video.split("v=");
  const createAt = location.state.create;

  return (
    <Container>
      <TitleWrap>
        <BackArrow
          onClick={() => {
            navigate(-1);
          }}
        />
        <Title>{ location.state.title }</Title>
      </TitleWrap>
      <TitleDate>{createAt}</TitleDate>
      <VideoWrap>
        <YouTube videoId={videoId[1]} opts={opts} />
      </VideoWrap>
    </Container>
  );
};

export default LectureVideoDetail;
