import React from "react";
import styled from "styled-components";
import "./style.scss"
import {Desktop, Mobile} from "../../../utils/mediaQuery";

const YoutubeIframe = styled.iframe`
  width: 70vw;
  height: 70.7vh;
  top: -10vh;
  overflow: hidden;
`;
const YoutubeIframMobile = styled.iframe`
  width: 85vw;
  height: 22vh;
  top: -10vh;
  overflow: hidden;
`;
export default function YoutubeEmbed() {
  return (
    <>
      <Desktop>
        <YoutubeIframe src="https://www.youtube.com/embed/jHWKtQHXVJg?autoplay=1&mute=1"
                       title="YouTube video player"
                       allow="accelerometer; autoplay; clipboard-write; gyroscope; picture-in-picture; web-share"
                       allowFullScreen></YoutubeIframe>
      </Desktop>
      <Mobile>
        <YoutubeIframMobile src="https://www.youtube.com/embed/jHWKtQHXVJg?autoplay=1&mute=1"
                            title="YouTube video player"
                            allow="accelerometer; autoplay; clipboard-write; gyroscope; picture-in-picture; web-share"
                            allowFullScreen></YoutubeIframMobile>
      </Mobile>
    </>
  )
};
