import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";

import Pagination from "../../../components/Pagination";
import lectureVideoState from "../../../states/lectureVideoState";
import axios from "axios";
import { API_URL } from "../../../API";

import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import updateLocale from "dayjs/plugin/updateLocale";
import { Desktop, Mobile } from "../../../utils/mediaQuery";

dayjs.extend(updateLocale);
dayjs.extend(relativeTime);
dayjs.updateLocale("en", {
  relativeTime: {
    future: "in %s",
    past: "%s 전",
    s: "1초",
    m: "1분",
    mm: "%d분",
    h: "한 시간",
    hh: "%d시간",
    d: "하루",
    dd: "%d일",
    M: "한 달",
    MM: "%d개월",
    y: "1년",
    yy: "%d년",
  },
});

const Container = styled.div`
  padding-top: 20px;
`;

const NoneLecture = styled.div`
  font-size: 20px;
  font-weight: ${(props) => props.theme.fontWeight.medium};
  color: #adb5bd;
  text-align: center;
`;
const ContentsWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
const ContentsBlock = styled.div`
  margin-bottom: 80px;
  margin-right: 16px;
  cursor: pointer;
  :nth-child(2n) {
    margin-right: 0px;
  }
`;
const ThumbnailWrap = styled.div`
  width: 398px;
  height: 224px;
  border-radius: 10px;
  overflow: hidden;
  background-color: pink;
  margin-bottom: 20px;
`;
const Thumbnail = styled.img`
  width: 100%;
  height: auto;
`;
const ThumbnailTitle = styled.h5`
  font-size: 24px;
  font-weight: ${(props) => props.theme.fontWeight.bold};
  color: ${(props) => props.theme.color.textColor};
  height: 36px;
  max-width: 390px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
const ThumbnailDate = styled.span`
  font-size: 20px;
  font-weight: ${(props) => props.theme.fontWeight.medium};
  color: #adb5bd;
  height: 29px;
`;
const PaginationWrap = styled.div`
  margin-top: 40px;
`;

/************모바일스타일***********/

const MobileContainer = styled.div`
  padding-bottom: 100px;
`;
const MobileVideoBox = styled.div`
  cursor: pointer;
`;
const MobileVideoWrapper = styled.figure`
  width: 342px;
  height: 193px;
  border-radius: 4px;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
const MobileVideoInfo = styled.div`
  margin-top: 12px;
  h2 {
    font-weight: 700;
    font-size: 16px;
    line-height: 23px;
    color: #212529;
  }
  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #868e96;
  }
`;
/********************************/

const LectureVideo = (props) => {
  const navigate = useNavigate();
  const [none, setNone] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState("");
  const [totalPage, setTotalPage] = useState("");
  const profileId = props.profileId;

  useEffect(() => {
    axios({
      url: `${API_URL}/mentopick/${profileId}/video?page=${page}`,
      method: "GET",
    })
      .then((result) => {
        if (result.data.success) {
          setData(result.data.result.videos);
          setTotalPage(result.data.result.totalPage);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [profileId, page]);

  return (
    <>
      <Desktop>
        <Container>
          {data.length === 0 ? (
            <NoneLecture>등록된 강의영상이 없습니다.</NoneLecture>
          ) : (
            <ContentsWrap>
              {data.map((data) => {
                return (
                  <ContentsBlock
                    key={data.id}
                    onClick={() => {
                      navigate(`lecture/${data.id}`, {
                        state: {
                          video: data.video,
                          title: data.title,
                          create: `${dayjs(data.createdAt).fromNow()}`,
                        },
                      });
                      // setLectureVideo(true);  `${dayjs(data.createAt)}`
                    }}
                  >
                    <ThumbnailWrap>
                      {/* <Thumbnail /> */}
                      <Thumbnail src={data.thumbnail} />
                    </ThumbnailWrap>
                    <ThumbnailTitle>{data.title}</ThumbnailTitle>
                    <ThumbnailDate>
                      {dayjs(data.createdAt).fromNow()}
                    </ThumbnailDate>
                  </ContentsBlock>
                );
              })}
            </ContentsWrap>
          )}

          {data.length > 0 ? (
            <PaginationWrap>
              <Pagination
                totalItemsCount={data.length > 0 ? totalPage * 10 : 0}
                showItemsCount={10}
                page={page}
                nowPage={(page) => {
                  setPage(page);
                  window.scrollTo(0, 0);
                }}
              />
            </PaginationWrap>
          ) : null}
        </Container>
      </Desktop>
      <Mobile>
        <MobileContainer>
          {data.length === 0 ? (
            <NoneLecture style={{ fontSize: 16, marginTop: 8 }}>
              등록된 강의영상이 없습니다.
            </NoneLecture>
          ) : (
            <ul>
              {data.map((data) => {
                return (
                  <MobileVideoBox
                    onClick={() => {
                      // setLectureVideo(true);  `${dayjs(data.createAt)}`
                    }}
                  >
                    <MobileVideoWrapper>
                      <img src={data.thumbnail} alt="thumbnail" />
                    </MobileVideoWrapper>
                    <MobileVideoInfo>
                      <h2>{data.title}</h2>
                      <p> {dayjs(data.createdAt).fromNow()}</p>
                    </MobileVideoInfo>
                  </MobileVideoBox>
                );
              })}
            </ul>
          )}
        </MobileContainer>
      </Mobile>
    </>
  );
};

export default LectureVideo;
