import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import Header from "../../../components/Header";
import { API_URL } from "../../../API";
import { Border, ButtonWrap, CancleWrap, ChoiceBox, ChoiceText, Container, InfoTitle, JoinTypeText, MainWrap, PageTypeText, RegexText, RowWrap, SubjectChoiceWrap, Title } from "../../signup/student/style";
import Footer from "../../../components/Footer";
import TextInput from "../../../components/TextInput";
import Button from "../../../components/Button";
import Modal from "../../../components/Modal";
import RadioBox from "../../../components/RadioBox";
import Select from "../../../components/Select";

const UserInfo = ({ data, editCalled, onEdit }) => {
  // 닉네임
  const [nickname, setNickname] = useState(data.nickname);
  const [nicknameEdit, setNicknameEdit] = useState(false);
  const [nicknameCheck, setNicknameCheck] = useState(null);
  const [nicknameChecked, setNicknameChecked] = useState(false);

  // 휴대전화번호
  const [phoneNumber, setPhoneNumber] = useState(data.phoneNumber);
  const [phoneNumberEdit, setPhoneNumberEdit] = useState(false);
  const [phoneNumberAuthSended, setPhoneNumberAuthSended] = useState(false);
  const [phoneNumberAuthed, setPhoneNumberAuthed] = useState(false);
  const [min, setMin] = useState(4);
  const [sec, setSec] = useState(0);
  const time = useRef(240);
  const timerId = useRef(null);
  const [countDown, setCountDown] = useState(false);
  const [authModal, setAuthModal] = useState(false);
  const [isDuplicateNumber, setIsDuplicateNumber] = useState(false);
  const [unitFocus, setUnitFocus] = useState(false);
  const [authCode, setAuthCode] = useState('');
  const [authCompleteModal, setAuthCompleteModal] = useState(false);
  const [authErrorModal, setAuthErrorModal] = useState(false);

  const [userRole, setUserRole] = useState(data.role);
  const [gender, setGender] = useState(data.gender);
  const [birthday, setBirthday] = useState(data.birthday);
  const [cities, setCities] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [towns, setTowns] = useState([]);
  const [province, setProvince] = useState({ value: data.area?.city, label: data.area?.city });
  const [city, setCity] = useState({ value: data.area?.district, label: data.area?.district });
  const [dong, setDong] = useState({ value: data.area?.town, label: data.area?.town });
  const [subjectType, setSubjectType] = useState([]);
  const [selectSubjectType, setSelectSubjectType] = useState("");
  const [subjectLevel, setSubjectLevel] = useState([]);
  const [selectSubjectLevel, setSelectSubjectLevel] = useState("");
  const [subjectMajors, setSubjectMajors] = useState([]);
  const [selectSubjectMajor, setSelectSubjectMajor] = useState(null);
  const [subjectItems, setSubjectItems] = useState([]);
  const [subject, setSubject] = useState([]);
  const [selectSubject, setSelectSubject] = useState(data.subjects ? data.subjects : []);
  const [subjectDisabled, setSubjectDisabled] = useState(false);
  const [subjectId, setSubjectId] = useState(data.subjects.map(subject => subject.id));
  const [error, setError] = useState(false);
  const [editEnabled, setEditEnabled] = useState(false);

  const checkNicknameDuplicate = () => {
    if (nickname) {
      axios({
        url: `${API_URL}/user/check/nickname?nickname=${nickname}`,
        method: "POST",
      })
        .then((result) => {
          if (result.data.success) {
            if (result.data.result.isDuplicate) {
              setNicknameCheck("이미 사용 중인 닉네임 입니다.");
              setError(true);
            } else {
              setNicknameCheck("사용가능한 닉네임 입니다.");
              setError(false);
              setNicknameChecked(true);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const sendPhoneAuth = () => {
    if (phoneNumber !== "") {
      axios({
        url: `${API_URL}/user/sms/send`,
        method: "POST",
        data: {
          phoneNumber: phoneNumber,
        },
      })
        .then((result) => {
          if (result.data.success) {
            setPhoneNumberAuthSended(true);

            if (time.current < 240) {
              clearInterval(timerId.current);
              time.current = 240;
              start();
            } else {
              clearInterval(timerId.current);
              start();
            }
          } else if (
            result.data.error.message === "이미 가입된 휴대전화번호입니다."
          ) {
            setIsDuplicateNumber(true);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const checkPhoneAuth = () => {
    if (time.current < 240) {
      clearInterval(timerId.current);
      time.current = 240;
    } else {
      clearInterval(timerId.current);
    }

    axios({
      url: `${API_URL}/user/sms/verify`,
      method: "POST",
      data: {
        phoneNumber: phoneNumber,
        authCode,
      },
    })
      .then((result) => {
        if (result.data.success) {
          setAuthCompleteModal(true);
          setPhoneNumberAuthed(true);
        } else {
          setAuthErrorModal(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    const regex = /[~!@#$%^&*()_+|<>?:{}]/;

    if (nickname) {
      setNickname(nickname.replace(regex, ""));
    } else {
      setNicknameCheck(null);
    }
  }, [nickname]);

  const start = () => {
    timerId.current = setInterval(() => {
      time.current -= 1;
      setMin(parseInt(time.current / 60));
      setSec(time.current % 60);
      if (time.current < 61) {
        setCountDown(true);
      } else {
        setCountDown(false);
      }
    }, 1000);
  };

  useEffect(() => {
    if (time.current === 0) {
      clearInterval(timerId.current);
      setCountDown(false);
      time.current = 240;
      setAuthModal(true);
    }
  }, [sec]);

  useEffect(() => {
    setEditEnabled(false);

    if (error) {
      return;
    }

    if (nicknameEdit) {
      if (!nicknameChecked) {
        return;
      }
    }

    if (phoneNumberEdit) {
      if (!phoneNumberAuthed) {
        return;
      }
    }

    setEditEnabled(true);
  }, [nicknameChecked, error, nicknameEdit, phoneNumberEdit, phoneNumberAuthed]);

  useEffect(() => {
    axios({
      url: `${API_URL}/area/cities`,
      method: "GET",
    })
      .then((result) => {
        if (result.data.success) {
          setCities(
            result.data.result.cities.map((item) => {
              return {
                label: item,
                value: item,
              };
            })
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });

    axios({
      url: `${API_URL}/subject/types`,
      method: "GET",
    })
      .then((result) => {
        setSubjectType(
          result.data.result.types
            .map((item) => {
              return {
                label: item.type,
                name: "kind",
                value: item.type,
                id: item.type,
              };
            })
        );
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    axios({
      url: `${API_URL}/area/districts?cityName=${province.value}`,
      method: "GET",
    })
      .then((result) => {
        if (result.data.success) {
          setDistricts(
            result.data.result.districts.map((item) => {
              return {
                label: item,
                value: item,
              };
            })
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [province]);

  useEffect(() => {
    axios({
      url: `${API_URL}/area/towns?cityName=${province.value}&districtName=${city.value}`,
      method: "GET",
    })
      .then((result) => {
        if (result.data.success) {
          setTowns(
            result.data.result.towns.map((item) => {
              return {
                label: item.town,
                value: item.town,
                id: item.id,
              };
            })
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [province, city]);

  const subjectTypeHandler = (value) => {
    selectSubject.length > 0
      ? setSubjectDisabled(true)
      : setSubjectDisabled(false);
    if (value !== "아직 잘 모르겠어요") {
      axios({
        url: `${API_URL}/subject/levels?type=${value}`,
        method: "GET",
      })
        .then((result) => {
          setSubjectLevel(
            result.data.result.levels.map((item) => {
              return {
                label: item.level,
                name: "level",
                value: item.level,
                id: item.level,
              };
            })
          );
          setSubject([
            ...subject,
            ...result.data.result.levels.map((item) => {
              return item;
            }),
          ]);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setSubjectLevel([]);
      // setSubjectId(value); 아직 잘 모르겠어요 클릭 시
    }
  };

  const subjectLevelHandler = (value) => {
    axios({
      url: `${API_URL}/subject/majors?type=${selectSubjectType}&level=${value}`,
      method: "GET",
    })
      .then((result) => {
        setSubjectMajors(
          result.data.result.majors.map((item) => {
            return {
              label: item.name,
              name: "major",
              value: item.name,
              id: item.name,
            };
          })
        );
        setSubject([
          ...subject,
          ...result.data.result.majors.map((item) => {
            return item;
          }),
        ]);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const subjectMajorsHandler = (value) => {
    axios({
      url: `${API_URL}/subject/items?type=${selectSubjectType}&level=${selectSubjectLevel}&major=${value}`,
      method: "GET",
    })
      .then((result) => {
        setSubjectItems(
          result.data.result.items.map((item) => {
            return {
              label: item.progress,
              name: "items",
              value: item.progress,
              id: item.progress,
            };
          })
        );
        setSubject([
          ...subject,
          ...result.data.result.items.map((item) => {
            return item;
          }),
        ]);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (editCalled) {
      let data = {};

      if (nickname && nicknameChecked && nicknameEdit) {
        data = {
          nickname
        };
      }

      if (phoneNumber && authCode && phoneNumberAuthed) {
        data = {
          ...data,
          phoneNumber,
          authCode
        };
      }

      if (userRole) {
        data = {
          ...data,
          role: userRole
        };
      }

      if (gender) {
        data = {
          ...data,
          gender
        };
      }

      if (dong) {
        data = {
          ...data,
          areaId: dong
        };
      }
      
      if (subjectId) {
        data = {
          ...data,
          subjectIds: subjectId
        };
      }

      onEdit(data);
    }
  }, [editCalled]);

  useEffect(() => {
    console.log(selectSubject);
  }, [selectSubject]);

  return (
    <>
      <Title>가입 정보</Title>
      <InfoTitle>닉네임 또는 실명</InfoTitle>
      <RegexText>한글, 숫자 포함 10글자 이하로 입력해주세요.</RegexText>
      <RowWrap>
        <TextInput
          disabled={!nicknameEdit}
          style={{ width: 636 }}
          value={nickname}
          placeholder="닉네임 또는 실명 입력"
          maxLength={10}
          marginBottom={"0px"}
          label={nicknameCheck}
          error={error}
          onChange={(value) => {
            setNickname(value);
          }}
        />
        {
          nicknameEdit === false && (
            <Button
              active
              style={{ width: 160, marginLeft: 16 }}
              onClick={() => {
                setNicknameEdit(true);
              }}
            >
              수정하기
            </Button>
          )
        }
        {
          nicknameEdit === true && (
            <Button
              active
              style={{ width: 160, marginLeft: 16 }}
              onClick={checkNicknameDuplicate}
            >
              중복확인
            </Button>
          )
        }
      </RowWrap>
      <InfoTitle>휴대폰 번호</InfoTitle>
      <RowWrap>
        <TextInput
          disabled={!phoneNumberEdit}
          type="number"
          style={{ width: 636 }}
          value={phoneNumber}
          onChange={(value) => {
            setPhoneNumber(value);
          }}
          placeholder={"(-) 제외한 숫자만 입력"}
        />
        {
          phoneNumberEdit === false && (
            <Button
              active
              style={{ width: 160, marginLeft: 16 }}
              onClick={() => {
                setPhoneNumberEdit(true);
              }}
            >
              수정하기
            </Button>
          )
        }
        {
          phoneNumberEdit && (
            <Button
              grey={phoneNumberAuthSended}
              active={!phoneNumberAuthSended}
              style={{ marginLeft: 16, width: 160 }}
              onClick={sendPhoneAuth}
              disabled={phoneNumberAuthed}
            >
              {phoneNumberAuthSended ? "재요청" : "인증요청"}
            </Button>
          )
        }
      </RowWrap>
      {phoneNumberAuthSended && (
        <RowWrap>
          <TextInput
            type="number"
            style={{ width: 636 }}
            unitAuth={
              <div>
                {min}:{sec < 10 ? `0${sec}` : sec}
              </div>
            }
            countDown={countDown}
            unitFocus={unitFocus}
            onFocus={() => {
              setUnitFocus(true);
            }}
            onBlur={() => {
              setUnitFocus(false);
            }}
            value={authCode}
            onChange={(value) => {
              setAuthCode(value);
            }}
          />
          <Button
            active={!phoneNumberAuthed}
            disabled={phoneNumberAuthed}
            style={{ marginLeft: 16, width: 160 }}
            onClick={checkPhoneAuth}
          >
            인증확인
          </Button>
        </RowWrap>
      )}
      <Border />
      <Title>세부 정보</Title>
      {
        data.type !== 'TEACHER' && (
          <>
            <InfoTitle>회원님은 학생이신가요?</InfoTitle>
            <RowWrap>
              <RadioBox
                value={userRole}
                option={[
                  {
                    label: "네 학생입니다",
                    name: "type",
                    value: "STUDENT",
                    id: "student",
                  },
                  {
                    label: "아니요 학부모입니다",
                    name: "type",
                    value: "PARENTS",
                    id: "parent",
                  },
                ]}
                showLine={2}
                onChange={(value) => {
                  setUserRole(value);
                }}
              />
            </RowWrap>
          </>
        )
      }
      <InfoTitle>회원님의 성별을 선택해주세요.</InfoTitle>
      <RowWrap>
        <RadioBox
          value={gender}
          option={[
            {
              label: "남자입니다.",
              name: "gender",
              value: "MALE",
              id: "man",
            },
            {
              label: "여자입니다",
              name: "gender",
              value: "FEMALE",
              id: "woman",
            },
          ]}
          showLine={2}
          onChange={(value) => {
            setGender(value);
          }}
        />
      </RowWrap>
      <InfoTitle>생년월일</InfoTitle>
      <TextInput
        disabled
        placeholder={"YYYYMMDD"}
        value={birthday}
        onChange={(value) => {
          setBirthday(value);
        }}
        maxLength={8}
        style={{ width: "100%", marginBottom: "48px" }}
      />
      <InfoTitle>회원님의 거주지를 선택해주세요.</InfoTitle>
      <RowWrap>
        <Select
          option={cities}
          placeholder={"시/도"}
          value={province}
          onChange={(value) => {
            setProvince(value);
            setDistricts([]);
            setTowns([]);
            setCity("");
            setDong("");
          }}
        />
        <Select
          option={districts}
          placeholder={"시/군/구"}
          value={city}
          onChange={(value) => {
            setCity(value);
            setTowns([]);
            setDong("");
          }}
        />
        <Select
          option={towns}
          placeholder={"읍/면/동"}
          value={dong}
          onChange={(value) => {
            setDong(value);
          }}
          marginRight={"0px"}
        />
      </RowWrap>
      <RowWrap
        style={{ justifyContent: "space-between" }}
        marginBottom={"0px"}
      >
        <InfoTitle marginBottom={"20px"} marginTop={"0px"}>
          { data.type !== 'TEACHER' ? '회원님의 필요한 과목을 선택해주세요.' : '회원님의 과목을 선택해주세요.' }
        </InfoTitle>
        <RegexText>*복수 선택이 가능합니다.</RegexText>
      </RowWrap>
      <SubjectChoiceWrap>
        {selectSubject.length > 0 &&
          selectSubject.map((item, idx) => {
            if (item.type === "국내") {
              return (
                <ChoiceBox key={idx}>
                  <ChoiceText>{`${item.type}(${item.level}) - ${item.name}(${item.progress})`}</ChoiceText>
                  <CancleWrap
                    onClick={() => {
                      setSelectSubject(
                        selectSubject.filter(
                          (selectItem) => item !== selectItem
                        )
                      );
                      setSubjectId(
                        subjectId.filter((itemId) => itemId !== item.id)
                      );
                    }}
                  />
                </ChoiceBox>
              );
            } else {
              return (
                <ChoiceBox key={idx}>
                  <ChoiceText>{`${item.type} - ${item.level}`}</ChoiceText>
                  <CancleWrap
                    onClick={() => {
                      setSelectSubject(
                        selectSubject.filter(
                          (selectItem) => item !== selectItem
                        )
                      );
                      setSubjectId(
                        subjectId.filter((itemId) => itemId !== item.id)
                      );
                    }}
                  />
                </ChoiceBox>
              );
            }
          })}
      </SubjectChoiceWrap>
      <RowWrap marginBottom={subjectLevel.length > 0 ? "12px" : "80px"}>
        <RadioBox
          disabled={selectSubject.length > 0}
          marginBottom="0"
          option={subjectType}
          showLine={3}
          onChange={(value) => {
            setSubjectLevel([]);
            setSubjectMajors([]);
            setSubjectItems([]);
            setSelectSubjectType(value);
            subjectTypeHandler(value);
          }}
        />
      </RowWrap>
      {subjectLevel.length > 0 ? (
        <>
          <Border
            marginBottom={subjectLevel.length > 0 ? "32px" : "80px"}
          />
          <RowWrap marginBottom={"12px"}>
            <RadioBox
              marginBottom="0"
              option={subjectLevel}
              showLine={4}
              onChange={(value) => {
                if (selectSubjectType === '국내') {
                  setSubjectMajors([]);
                  setSubjectItems([]);
                  subjectLevelHandler(value);
                } else {
                  subject.forEach((item) => {
                    if (item.level === value && item.type === selectSubjectType) {
                      if (
                        selectSubject.filter(
                          (subItem) => item.id === subItem.id
                        ).length < 1
                      ) {
                        setSelectSubject([...selectSubject, item]);
                        setSubjectId([...subjectId, item.id]);
                      }
                    }
                  });
                }

                setSelectSubjectLevel(value);
              }}
            />
          </RowWrap>
        </>
      ) : null}
      
      {subjectMajors.length > 0 ? (
        <>
          <Border
            marginBottom={subjectMajors.length > 0 ? "32px" : "80px"}
          />
          <RowWrap marginBottom={"12px"}>
            <RadioBox
              marginBottom="0"
              option={subjectMajors}
              showLine={4}
              onChange={(value) => {
                if (selectSubjectType === "국내") {
                  subjectMajorsHandler(value);
                } else {
                  subject.forEach((item) => {
                    if (item.level === value && item.type === selectSubjectType) {
                      if (
                        selectSubject.filter(
                          (subItem) => item.id === subItem.id
                        ).length < 1
                      ) {
                        setSelectSubject([...selectSubject, item]);
                        setSubjectId([...subjectId, item.id]);
                      }
                    }
                  });
                }

                setSelectSubjectMajor(value);
              }}
            />
          </RowWrap>
        </>
      ) : null}

      {subjectItems.length > 0 ? (
        <>
          <Border
            marginBottom={subjectItems.length > 0 ? "32px" : "80px"}
          />
          <RowWrap marginBottom={"80px"}>
            <RadioBox
              marginBottom="0"
              option={subjectItems}
              showLine={4}
              onChange={(value) => {
                console.log(value);

                if (value) {
                  subject.forEach((item) => {
                    if (item.progress === value) {
                      setSelectSubject([...selectSubject, item]);
                      setSubjectId([...subjectId, item.id]);
                    }
                  });
                }
              }}
            />
          </RowWrap>
        </>
      ) : null}
      {authModal ? (
        <Modal
          padding
          title={"입력시간 초과"}
          content={"인증번호 입력시간이 초과되었습니다. 다시 입력해주세요."}
          button={[
            {
              title: "재전송",
              type: "active",
            },
          ]}
          onPress={() => {
            setAuthModal(false);
            start();
          }}
        />
      ) : null}
      {isDuplicateNumber ? (
        <Modal
          padding
          title={"오류"}
          content={"이미 가입된 휴대전화번호입니다."}
          button={[
            {
              title: "확인",
              type: "active",
            },
          ]}
          onPress={() => {
            setIsDuplicateNumber(false);
          }}
        />
      ) : null}
      {authModal === true && (
        <Modal
          padding
          title={"입력시간 초과"}
          content={"인증번호 입력시간이 초과되었습니다. 다시 입력해주세요."}
          button={[
            {
              title: "재전송",
              type: "active",
            },
          ]}
          onPress={() => {
            setAuthModal(false);
            start();
          }}
        />
      )}
      {authCompleteModal === true && (
        <Modal
          padding
          title={"인증 완료"}
          content={"인증이 완료되었습니다"}
          button={[
            {
              title: "확인",
              type: "active",
            },
          ]}
          onPress={() => {
            setAuthCompleteModal(false);
          }}
        />
      )}
    </>
  )
};

const ModifyInfo = () => {
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [editCalled, setEditCalled] = useState(false);
  const [type, setType] = useState('');

  const getData = () => {
    axios({
      method: 'GET',
      url: `${API_URL}/mypage/userInfo`
    }).then((res) => {
      if (res.data.success) {
        const data = res.data.result;

        setType(data.type);
        setData(data);
      } else {
        alert(res.data.error.message);
        navigate(-1);
      }
    })
  };

  const modify = (data) => {
    axios({
      method: 'POST',
      url: `${API_URL}/mypage/userInfo`,
      data
    }).then((res) => {
      if (res.data.success) {
        alert('회원정보가 수정되었습니다!');
        navigate(-1);
      } 
    });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <Header activeTab="MyPage" />
      <Container>
        <MainWrap>
          <PageTypeText>회원정보수정</PageTypeText>
          <JoinTypeText>{ type === 'TEACHER' ? '선생님' : '학생 / 학부모' }</JoinTypeText>
          {
            data !== null && (
              <UserInfo data={data} editCalled={editCalled} onEdit={modify}/>
            )
          }
          <ButtonWrap>
            <Button
              active
              onClick={() => {
                setEditCalled(true);
              }}
            >
              수정하기
            </Button>
          </ButtonWrap>
        </MainWrap>
        <Footer />
      </Container>
    </>
  );
};

export default ModifyInfo;