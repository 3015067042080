import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Slider from "react-slick";
import "./style/slick.css";
import "./style/slick-theme.css";
import YouTube from "react-youtube";

const CarouselContentsWrap = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin: 0 8px;
`;
const Contents = styled.div`
  width: 812px;
  height: 457px;
  background-color: #111111;
  border-radius: 16px;
  overflow: hidden;
`;

const opts = {
  height: "457",
  width: "812",
  playerVars: {
    origin: "https://localhost:3000",
    autoplay: 0,
  },
};

const Carousel = (props) => {
  const [pause, setPause] = useState(true);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    autoplay: pause,
    autoplaySpeed: 3000,
    variableWidth: true,
    pauseOnFocus: true,
  };

  return (
    <Slider {...settings} className="carousel">
      {props.videos.map((arr, index) => {
        return (
          <CarouselContentsWrap key={arr.id}>
            <Contents>
              <YouTube
                videoId={arr.videoId}
                id={arr.id}
                opts={opts}
                onPlay={() => {
                  setPause(false);
                }}
                onPause={() => {
                  setPause(true);
                }}
              />
            </Contents>
          </CarouselContentsWrap>
        );
      })}
    </Slider>
  );
};

export default Carousel;
