import styled from "styled-components";

const BannerTitle = styled.span`
  font-size: 28px;
  font-weight: ${(props) => props.theme.fontWeight.medium};
  color: ${(props) =>
    props.orange ? props.theme.color.orange : props.theme.color.primary};
  line-height: 41px;
`;
const BannerDesc = styled.p`
  font-size: 16px;
  font-weight: ${(props) => props.theme.fontWeight.regular};
  color: ${(props) => props.theme.color.primary};
  margin-top: 7px;
`;
const Container = styled.div`
  width: 1364px;
  padding: 200px 0px 278px 0px;
  margin: 0 auto;

  @media screen and (max-width: 1280px) {
    width: 1280px;
  }
`;

export { BannerTitle, BannerDesc, Container };
