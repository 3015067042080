import styled from "styled-components";

import mainImg from "../../assets/images/main_image.jpeg";
import subImg01 from "../../assets/images/main_subimage_1.jpeg";
import subImg02 from "../../assets/images/main_subimage_2.jpeg";
import subImg03 from "../../assets/images/main_subimage_3.jpeg";

const Container = styled.div`
  width: 100%;
`;

const MainImgWrap = styled.div`
  width: 100%;
  height: 720px;
  background-image: url(${mainImg});
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 260px;
  background-size: cover;
  background-position: center 80%;
`;

const MainTextWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const MainImgTitle = styled.span`
  font-size: 40px;
  font-weight: ${(props) => props.theme.fontWeight.bold};
  line-height: 60px;
  color: ${(props) =>
    props.orange ? props.theme.color.orange : props.theme.color.white};
  text-align: center;
  margin-bottom: 31px;
`;
const MainPageBtn = styled.button`
  width: 189px;
  height: 60px;
  background-color: ${(props) => props.theme.color.orange};
  color: ${(props) => props.theme.color.white};
  font-size: 20px;
  font-weight: ${(props) => props.theme.fontWeight.medium};
  text-align: center;
  border-radius: 12px;
  border: none;
`;

const MainContentsWrap = styled.div`
  padding: 0;
  margin: 0 auto;
  margin-bottom: ${(props) => (props.marginBottom ? "182px" : "260px")};
  background-color: ${(props) =>
    props.backgroundColor
      ? props.theme.color.primary
      : props.theme.color.white};
  @media screen and (max-width: 1280px) {
    max-width: ${(props) => (props.widthAuto ? "100%" : "880px")};
  }
`;
const MainContents01 = styled.div`
  background-image: url(${subImg01});
  background-repeat: no-repeat;
  width: 1364px;
  height: 674px;
  border-radius: 16px;
  padding-left: 48px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  background-size: cover;

  @media screen and (max-width: 1280px) {
    width: 880px;
  }
`;
const ContentsTitle = styled.h3`
  font-size: 40px;
  font-weight: ${(props) => props.theme.fontWeight.bold};
  color: ${(props) =>
    props.white ? props.theme.color.white : props.theme.color.textColor};
  margin-bottom: 25px;
  @media screen and (max-width: 1280px) {
    font-size: 30px;
  }
`;
const ContentsSubTitle = styled.span`
  font-size: 20px;
  font-weight: ${(props) => props.theme.fontWeight.medium};
  color: ${(props) => props.theme.color.orange};
  line-height: 32px;
  margin-bottom: 4px;
`;
const ContentsDesc = styled.p`
  font-size: 20px;
  font-weight: ${(props) => props.theme.fontWeight.regular};
  color: ${(props) =>
    props.white ? props.theme.color.white : props.theme.color.textColor};
  line-height: 32px;
  margin-bottom: ${(props) => (props.marginBottom ? "48px" : "0px")};
  @media screen and (max-width: 1280px) {
    font-size: 14px;
  }
`;
const MainContents02 = styled.div`
  height: 674px;
  display: flex;
  justify-content: center;
`;
const SubTextWrap = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: ${(props) => (props.padding ? "120px" : "0px")};
  padding-bottom: ${(props) => (props.padding ? "120px" : "0px")};
  text-align: ${(props) => (props.textAlign ? "center" : "left")};
`;
const SubImage01 = styled.div`
  background-image: url(${subImg02});
  background-repeat: no-repeat;
  width: 690px;
  height: 690px;
  margin-left: 196px;
  background-size: cover;
  background-position: center;
  border-radius: 10px;

  @media screen and (max-width: 1280px) {
    width: 460px;
    height: 460px;
    margin-left: 60px;
  }
`;
const SubImage02 = styled.div`
  background-image: url(${subImg03});
  background-repeat: no-repeat;
  width: 690px;
  height: 690px;
  margin-right: 152px;
  background-size: 120%;
  background-position: center 0;
  border-radius: 10px;

  @media screen and (max-width: 1280px) {
    width: 460px;
    height: 460px;
    margin-right: 135px;
  }
`;
const MainContents03 = styled.div`
  display: flex;
  text-align: center;
  margin: 0 auto;
`;
const ContentsWrap = styled.div`
  width: 674px;
  padding: 48px;
  margin-bottom: ${(props) => (props.marginBottom ? "16px" : "0px")};
  background-color: ${(props) => props.theme.color.white};
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  // align-items: flex-start;
  @media screen and (max-width: 1280px) {
    max-width: 450px;
    padding: 40px;
  }
`;
const ContentsBlock = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  margin-bottom: ${(props) => (props.noMargin ? "0px" : "20px")};
`;
const DottedBorder = styled.div`
  flex: 1;
  border-bottom: 5px dotted #212529;
  margin: 0 15px 0 14px;
`;
const AbledBadge = styled.div`
  min-width: 100px;
  height: 40px;
  border-radius: 20px;
  background-color: rgba(51, 216, 78, 0.1);
  color: #33d84e;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 1280px) {
    min-width: 70px;
    height: 30px;
    font-size: 14px;
  }
`;
const DisabledBadge = styled(AbledBadge)`
  background-color: #eef0f4;
  color: ${(props) => props.theme.color.primary};
`;
const AccountText = styled.span`
  font-size: 24px;
  font-weight: ${(props) => props.theme.fontWeight.medium};
  color: ${(props) => props.theme.color.primary};
  margin-bottom: 10px;
`;
const AccountPriceText = styled.span`
  font-size: 32px;
  font-weight: ${(props) => props.theme.fontWeight.bold};
  color: ${(props) => props.theme.color.orange};
`;
const ContentsContainer = styled.div`
  margin-right: ${(props) => (props.marginRight ? "16px" : "0px")};
  margin-top: 80px;
`;
const TabBarWrap = styled.div`
  height: auto;
  margin-bottom: 331px;
`;
const TabContents = styled.div`
  padding-top: 32px;
`;
const TabContentsTitle = styled.span`
  display: block;
  font-size: 20px;
  font-weight: ${(props) => props.theme.fontWeight.regular};
  color: ${(props) => props.theme.color.textColor};
  margin: 0 278px;
`;
const SliderWrap = styled.div`
  margin-top: 80px;
`;
const MainContents04 = styled.div`
  width: 100%;
  min-height: 1101px;
  padding: 104px 278px 120px 262px;
  background-color: #f6f9ff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: -180px;
  @media screen and (max-width: 1280px) {
    padding: 104px 120px;
  }
`;
const PartnerWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 80px;
`;
const PartnerBlock = styled.div`
  width: 329px;
  height: 329px;
  border-radius: 12px;
  background-color: #ffffff;
  margin-right: 16px;
  margin-top: 16px;
  :nth-child(4n) {
    margin-right: 0px;
  }
`;
const CareerSearchBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 400px;
  height: 100px;
  border-radius: 12px;
  box-shadow: 0px 2px 12px rgba(2, 22, 51, 0.3);
  position: sticky;
  margin-bottom: 80px;
  left: 50%;
  transform: translate(-50%);
  bottom: 80px;
  background-color: #ffffff;
  padding: 20px;
`;
const CareerSearchTextWrap = styled.div`
  display: flex;
  flex-direction: column;
`;
const CareerTitle = styled.div`
  font-size: 20px;
  font-weight: ${(props) => props.theme.fontWeight.bold};
  color: #212529;
  height: 29px;
  margin-bottom: 7px;
  display: flex;
  align-items: center;
`;
const CareerSubTitle = styled.div`
  font-size: 16px;
  font-weight: ${(props) => props.theme.fontWeight.medium};
  color: #adb5bd;
  height: 24px;
  display: flex;
  align-items: center;
`;
const CareerBtn = styled.button`
  width: 121px;
  height: 40px;
  border-radius: 8px;
  background-color: #ffad2e;
  border: none;
  color: #ffffff;
  font-size: 16px;
  font-weight: ${(props) => props.theme.fontWeight.medium};
`;

const SeminarContainer = styled.div`
  display: flex;
  flex-wrap: no-wrap;
  width: 1364px;
  margin: 0 auto;
  @media screen and (max-width: 1280px) {
    width: 880px;
  }
`;
const SeminarBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 79px;
  margin-right: 16px;
  :nth-child(3n) {
    margin-right: 0px;
  }
`;
const SeminarWrap = styled.div`
  width: 444px;
  overflow: hidden;
  border-radius: 12px;
  position: relative;
  @media screen and (max-width: 1280px) {
    width: 277px;
  }
`;
const SeminarThumbnail = styled.div`
  width: 444px;
  height: 625px;
  background-image: url(${(props) =>
    props.seminarImg ? props.seminarImg : null});
  background-size: cover;
  background-color: ${(props) => (props.isShown ? "#dee2e6" : null)};
  filter: ${(props) => (props.isShown ? "blur(8px) brightness(0.5)" : null)};
  opacity: ${(props) => (props.isShown ? "90%" : null)};
  @media screen and (max-width: 1280px) {
    width: 277px;
    height: 348px;
    background-size: cover;
  }
`;
const ButtonWrap = styled.div`
  width: 300px;
  position: absolute;
  top: 246px;
  left: 50%;
  transform: translateX(-50%);

  @media screen and (max-width: 1280px) {
    width: 200px;
    position: absolute;
    top: 174px;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;
const BadgeWrap = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${(props) =>
    props.marginBottom ? props.marginBottom + "px" : "16px"};
  margin-top: ${(props) => (props.marginTop ? props.marginTop + "px" : "20px")};
`;
const Badge = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  min-width: ${(props) => (props.minWidth ? "121px" : "0px")};
  height: ${(props) => (props.height ? "40px" : "32px")};
  border-radius: ${(props) => (props.borderRadius ? "12px" : "8px")};
  padding: 6px 20px;
  background-color: ${(props) =>
    props.red
      ? "#FF383B"
      : props.black
      ? "#021633"
      : props.pink
      ? "rgba(255, 56, 59, 0.1)"
      : props.grey
      ? "#DDE2EA"
      : "#ffffff"};
  border: 1px solid
    ${(props) =>
      props.red
        ? "#FF383B"
        : props.black
        ? "#021633"
        : props.pink
        ? "none"
        : props.white
        ? "#021633"
        : props.purple
        ? "#6337FC"
        : props.grey
        ? "#DDE2EA"
        : "#ffffff"};
  color: ${(props) =>
    props.white
      ? "#021633"
      : props.purple
      ? "#6337FC"
      : props.grey
      ? "#021633"
      : props.pink
      ? "#FF383B"
      : "#ffffff"};
  font-size: 14px;
  font-weight: ${(props) => props.theme.fontWeight.medium};
  margin-right: 12px;
  :last-child {
    margin-right: 0px;
  }

  @media screen and (max-width: 1280px) {
    padding: 4px 6px;
    font-size: 12px;
    min-width: ${(props) => (props.minWidth ? "90px" : "0px")};
    height: ${(props) => (props.height ? "32px" : "28px")};
  }
`;

const SeminarTitle = styled.div`
  font-size: 24px;
  font-weight: ${(props) => props.theme.fontWeight.bold};
  color: #212529;
  height: 36px;
  margin-bottom: 7px;
  @media screen and (max-width: 1280px) {
    font-size: 20px;
    height: 24px;
  }
`;
const SeminarSubTitle = styled.div`
  font-size: 20px;
  font-weight: ${(props) => props.theme.fontWeight.medium};
  color: #868e96;
  height: 29px;
  margin-bottom: 8px;
  @media screen and (max-width: 1280px) {
    font-size: 18px;
    height: 24px;
  }
`;
const SeminarStatus = styled.div`
  font-size: 24px;
  font-weight: ${(props) => props.theme.fontWeight.bold};
  height: 36px;
  color: ${(props) =>
    props.status === "예약완료"
      ? "#0081F5"
      : props.status === "예약마감"
      ? "#868E96"
      : "#212529"};
  @media screen and (max-width: 1280px) {
    font-size: 20px;
    height: 29px;
  }
`;
const SeminarTitleWrap = styled.div`
  width: 1364px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto 31px;
  @media screen and (max-width: 1280px) {
    width: 880px;
  }
`;
const SeminarMainTitle = styled.div`
  font-size: 40px;
  font-weight: ${(props) => props.theme.fontWeight.bold};
  color: #343a40;
`;
const SeminarMainSubTitle = styled.div`
  font-size: 24px;
  font-weight: ${(props) => props.theme.fontWeight.medium};
  color: #343a40;
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 36px;
`;

const PopupContent = styled.div`
  cursor: pointer;
`;

const PopupGoneButton = styled.button`
  background-color: transparent;
  border: none;
  color: #666666;
  text-decoration: underline;
  cursor: pointer;
`;

const ModalBackground = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(33, 37, 41, 0.8);
  z-index: 10;
  position: fixed;
  left: 0;
  top: 0;
`;
const InfoModal = styled.div`
  width: 812px;
  height: 798px;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  z-index: 11;
  border-radius: 16px;
  padding: 48px;
`;
const ModalTitle = styled.div`
  font-size: 28px;
  font-weight: ${(props) => props.theme.fontWeight.bold};
  height: 41px;
  color: #212529;
  margin-bottom: 7px;
`;
const ModalSubTitle = styled.span`
  display: inline-block;
  width: 100%;
  font-size: 20px;
  font-weight: ${(props) => props.theme.fontWeight.medium};
  height: 29px;
  color: #868e96;
  padding-bottom: 32px;
  margin-bottom: 32px;
  border-bottom: 2px solid #e9ecef;
`;
const ModalContentsWrap = styled.div`
  display: flex;
  margin-bottom: 32px;
`;
const ModalContentsBlock = styled.div`
  :first-child {
    margin-right: 16px;
  }
`;
const ModalContents = styled.div`
  background-color: #f8f9fa;
  border-radius: 12px;
  width: 350px;
  height: 355px;
  margin-top: 20px;
  overflow-y: auto;
  font-size: 14px;
  color: #212529;
  line-height: 28px;
  padding: 20px;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  ::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
`;
const Dot = styled.div`
  min-width: 4px;
  height: 4px;
  border-radius: 4px;
  background-color: #212529;
  margin-top: 14px;
  margin-right: 8px;
`;
const ModalTextWrap = styled.div`
  display: flex;
  margin-bottom: 20px;
`;
const ModalText = styled.span`
  font-size: 14px;
  color: #212529;
  line-height: 28px;
`;

const PopupTitle = styled.div`
  color: #212529;
  font-size: 24px;
  font-weight: 600;
  padding-bottom: 8px;
  display: inline-block;
  margin-bottom: 32px;
`;

const PopupBackground = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(33, 37, 41, 0.8);
  z-index: 900;
  position: fixed;
  top: 0px;
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PopupContainer = styled.div`
  width: ${(props) => (props.width ? props.width : "536px")};
  height: ${(props) => (props.height ? props.height : "auto")};
  background-color: #ffffff;
  overflow-x: hidden;
  border-radius: 30px;
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  @media screen and (max-width: 1280px) {
    overflow-y: scroll;
    max-height: 700px;
  }

  @media (max-width: 1080px) {
    overflow-y: scroll;
    height: 100vh;
    max-height: 100vh;
    border-radius: 0;
  }
`;

const CloseButtonWrap = styled.div`
  position: absolute;
  top: 28px;
  right: 28px;
  cursor: pointer;
`;

export const MobileContainer = styled.div`
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const MobileHomeLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 130px;
  img {
    width: 96px;
    height: 79px;
  }
`;

export const MobileImageWrap = styled.div`
  width: 100%;
  margin-top: 56px;
  margin-bottom: 30px;
  background-image: url(${mainImg});
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center 80%;
  padding: 30px;
`;

export const MobileMainImgTitle = styled.span`
  font-size: 25px;
  font-weight: ${(props) => props.theme.fontWeight.bold};
  line-height: 40px;
  color: ${(props) =>
    props.orange ? props.theme.color.orange : props.theme.color.white};
  text-align: center;
  margin-bottom: 20px;
`;

export const MobileMainPageBtn = styled.button`
  width: 140px;
  height: 40px;
  background-color: ${(props) => props.theme.color.orange};
  color: ${(props) => props.theme.color.white};
  font-size: 15px;
  font-weight: ${(props) => props.theme.fontWeight.medium};
  text-align: center;
  border-radius: 12px;
  border: none;
`;

export const MobileMainContentsWrap = styled.div`
  width: 100%;
  margin-bottom: 30px;
`;

export const MobileMainHeader = styled.div`
  display: flex;
  position: fixed;
  justify-content: space-between;
  top: 0;
  width: 100%;
  height: 56px;
  background-color: white;
  z-index: 10;
  padding: 12px 24px;
  border-bottom: 1px solid #dde2ea;

  > figure {
    display: flex;

    img {
      width: 24px;
      height: 24px;
      margin-right: 12px;
      align-self: center;
    }
    h2 {
      width: 174px;
      font-weight: 700;
      font-size: 18px;
      line-height: 30px;

      color: #212529;
    }
  }

  > ul {
    display: flex;
    li {
      > button {
        background-color: transparent;
        border: none;
        padding: 0;
        width: 32px;
        height: 32px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      :not(:last-of-type) {
        margin-right: 12px;
      }
    }
  }
`;

export const MobileMainContents01 = styled.div`
  background-image: url(${subImg01});
  background-repeat: no-repeat;
  width: 100%;
  padding: 20px 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  background-size: cover;
`;

export const MobileSubImage01 = styled.div`
  background-image: url(${subImg02});
  background-repeat: no-repeat;
  height: 200px;
  width: 100%;
  background-size: 100%;
`;

export const MobileSubImage02 = styled.div`
  background-image: url(${subImg03});
  background-repeat: no-repeat;
  height: 200px;
  width: 100%;
  background-size: 100%;
`;

export const MobileContentsTitle = styled.h3`
  font-size: 26px;
  font-weight: ${(props) => props.theme.fontWeight.bold};
  color: ${(props) =>
    props.white ? props.theme.color.white : props.theme.color.textColor};
  margin-bottom: 13px;
`;
export const MobileContentsSubTitle = styled.span`
  font-size: 17px;
  font-weight: ${(props) => props.theme.fontWeight.medium};
  color: ${(props) => props.theme.color.orange};
  line-height: 32px;
  margin-bottom: 4px;
`;
export const MobileContentsDesc = styled.p`
  font-size: 12px;
  color: ${(props) =>
    props.white ? props.theme.color.white : props.theme.color.textColor};
  line-height: 20px;
  margin-bottom: 14px;
`;
export const MobileSubTextWrap = styled.div`
  display: flex;
  flex-direction: column;
  margin: 15px 20px;
`;

export {
  Container,
  MainImgWrap,
  MainImgTitle,
  MainPageBtn,
  MainTextWrap,
  MainContentsWrap,
  MainContents01,
  ContentsTitle,
  ContentsSubTitle,
  ContentsDesc,
  MainContents02,
  SubImage01,
  SubTextWrap,
  SubImage02,
  MainContents03,
  ContentsWrap,
  ContentsBlock,
  DottedBorder,
  AbledBadge,
  DisabledBadge,
  AccountText,
  AccountPriceText,
  ContentsContainer,
  TabBarWrap,
  TabContents,
  TabContentsTitle,
  SliderWrap,
  MainContents04,
  PartnerWrap,
  PartnerBlock,
  CareerSearchBlock,
  CareerSearchTextWrap,
  CareerTitle,
  CareerSubTitle,
  CareerBtn,
  SeminarContainer,
  SeminarBlock,
  SeminarWrap,
  SeminarThumbnail,
  ButtonWrap,
  BadgeWrap,
  Badge,
  SeminarTitle,
  SeminarSubTitle,
  SeminarStatus,
  ModalBackground,
  InfoModal,
  ModalTitle,
  ModalSubTitle,
  ModalContentsWrap,
  ModalContentsBlock,
  ModalContents,
  Dot,
  ModalTextWrap,
  ModalText,
  SeminarTitleWrap,
  SeminarMainTitle,
  SeminarMainSubTitle,
  PopupTitle,
  PopupContent,
  PopupGoneButton,
  CloseButtonWrap,
  PopupBackground,
  PopupContainer,
};
